import { LocationSyncStatusEnum } from '@goparrot/giftcard-orchestrator-sdk';
import { BADGE_STATUSES } from '../components/sync-status-badge';

export const getGiftCardLocationBadgeStatus = (status?: LocationSyncStatusEnum): BADGE_STATUSES => {
  switch (status) {
    case LocationSyncStatusEnum.FAILED:
      return BADGE_STATUSES.INACTIVE;
    case LocationSyncStatusEnum.COMPLETED:
      return BADGE_STATUSES.ACTIVE;
    default:
      return BADGE_STATUSES.PENDING;
  }
};
