import { createAction } from 'helpers/create-action';

const c = {
  API__LOCATION__GET__REQUEST: 'API__LOCATION__GET__REQUEST',
  API__LOCATION__GET__SUCCESS: 'API__LOCATION__GET__SUCCESS',
  API__LOCATION__GET__ERROR: 'API__LOCATION__GET__ERROR',

  API__LOCATION__UPDATE__REQUEST: 'API__LOCATION__UPDATE__REQUEST',
  API__LOCATION__UPDATE__SUCCESS: 'API__LOCATION__UPDATE__SUCCESS',
  API__LOCATION__UPDATE__ERROR: 'API__LOCATION__UPDATE__ERROR',

  /**
   * @deprecated
   */
  API__LOCATION__CREATE__REQUEST: 'API__LOCATION__CREATE__REQUEST',
  /**
   * @deprecated
   */
  API__LOCATION__CREATE__SUCCESS: 'API__LOCATION__CREATE__SUCCESS',
  /**
   * @deprecated
   */
  API__LOCATION__CREATE__ERROR: 'API__LOCATION__CREATE__ERROR',

  /**
   * @deprecated
   */
  API__LOCATION__DELETE__REQUEST: 'API__LOCATION__DELETE__REQUEST',
  API__LOCATION__DELETE__SUCCESS: 'API__LOCATION__DELETE__SUCCESS',
  API__LOCATION__DELETE__ERROR: 'API__LOCATION__DELETE__ERROR',
};

export const a = {
  requestGet: createAction(c.API__LOCATION__GET__REQUEST),
  successGet: createAction(c.API__LOCATION__GET__SUCCESS),
  errorGet: createAction(c.API__LOCATION__GET__ERROR),

  /**
   * @deprecated
   */
  requestCreate: createAction(c.API__LOCATION__CREATE__REQUEST),
  /**
   * @deprecated
   */
  successCreate: createAction(c.API__LOCATION__CREATE__SUCCESS),
  /**
   * @deprecated
   */
  errorCreate: createAction(c.API__LOCATION__CREATE__ERROR),

  requestCreateDefault: createAction(c.API__LOCATION__CREATE__DEFAULT__REQUEST),
  successCreateDefault: createAction(c.API__LOCATION__CREATE__DEFAULT__SUCCESS),
  errorCreateDefault: createAction(c.API__LOCATION__CREATE__DEFAULT__ERROR),

  requestUpdate: createAction(c.API__LOCATION__UPDATE__REQUEST),
  successUpdate: createAction(c.API__LOCATION__UPDATE__SUCCESS),
  errorUpdate: createAction(c.API__LOCATION__UPDATE__ERROR),

  /**
   * @deprecated
   */
  requestDelete: createAction(c.API__LOCATION__DELETE__REQUEST),
  successDelete: createAction(c.API__LOCATION__DELETE__SUCCESS),
  errorDelete: createAction(c.API__LOCATION__DELETE__ERROR),
};

export default { ...c, ...a };
