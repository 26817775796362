import { PatchStoreItemDto, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationUpdateStoreItemParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchStoreItemDto;
};

export const useLocationUpdateStoreItem = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, LocationUpdateStoreItemParams>,
): UseMutationResult<ReadStoreItemDto, unknown, LocationUpdateStoreItemParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemFacade.patch(merchantId, storeId, uniqueName, data), options);
};
