import { IRewriteRelationsRequest, IStoreItemGroupModifierGroup, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdateStoreItemGroupOptionsParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemGroupModifierGroup>;
};
export type MerchantUpdateStoreItemGroupOptionsResult = UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupOptionsParams>;

export const useMerchantStoreItemGroupUpdateOptions = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupOptionsParams>,
): MerchantUpdateStoreItemGroupOptionsResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.updateItemOptions(merchantId, uniqueName, data), options);
};
