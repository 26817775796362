import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { merchantStoreItemGroupFacade } from '../../../facades';
import { GetRelativesQueryBaseParams, StoreItemGroupChildrenType } from '../../../interface';

export const useMerchantGetStoreItemGroupChildrenQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<StoreItemGroupChildrenType[]>,
): UseQueryResult<StoreItemGroupChildrenType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GROUP_GET_CHILDREN, merchantId, uniqueName],
    async () => await merchantStoreItemGroupFacade.getChildren(merchantId, uniqueName),
    options,
  );
};
