import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { Button, IconCloseThin, IconCopyOutlined, ImageUploadMenu, useNotifications } from '@goparrot-dashboard/shared-ui';
import { getOptimizedImageUrl, getRectImageConfig } from '@goparrot-dashboard/shared-utils';
import { useRef } from 'react';
import { CanCopyAndShowTooltip, CopyToClipboard } from 'components/commons/utility/CopyToClipboard';
import { onDefaultImageUpload } from '../../../../../client/src/helpers/utility';
import { useMenuFormContext } from '../../../context';
import { ReturnValue } from '../interface';
import './ImageUploader.css';

const imageParams = getRectImageConfig(2000, 2000, 37);

type Props = {
  field: string;
  folder: string;
  disabled?: boolean;
  title?: string;
  info?: string | React.ReactNode;
};

export const ImageUploader = <T,>({ field, folder, disabled = false, title, info }: Props): ReturnValue => {
  const [isLoadingUpload, toggleLoading] = React.useState<boolean>(false);
  const { form, merchantId, storeId } = useMenuFormContext<T>();
  const { notifyError, notifySuccess } = useNotifications();
  const copyToClipboardRef = useRef<CanCopyAndShowTooltip>();

  const bindInputProps = useInputProps(form);
  const bindFormFieldProps = useFormFieldProps(form);
  const { value: imgSrc, onChange } = bindInputProps(field);
  const handleDelete = () => onChange(undefined);

  const handleUpload = async () => {
    toggleLoading(true);
    try {
      // @ts-ignore cloudinary widget types
      const { files, widget, isCloseEvent } = await onDefaultImageUpload(imageParams, folder, merchantId, storeId);
      if (isCloseEvent && !files) {
        return;
      }
      notifySuccess({ text: 'Image successfully uploaded' });
      widget.destroy();

      const [{ uploadInfo }] = files;
      const optimizedImageUrl = getOptimizedImageUrl(uploadInfo?.secure_url);
      onChange(optimizedImageUrl);
    } catch {
      notifyError({ text: 'Some error occured while uploading the image' });
    } finally {
      toggleLoading(false);
    }
  };

  const handleCopy = (content: string) => copyToClipboardRef?.current?.copyAndShowTooltip(content);

  return (
    <div className="tw-space-y-2">
      {title && <div className="tw-text-blue-gray-900 tw-text-sm tw-font-bold">{title}</div>}
      {imgSrc ? (
        <div className="tw-relative chess-background">
          <img className="tw-w-40 tw-h-40 tw-object-contain" src={imgSrc} alt={title || 'menu image'} />
          <div className="tw-flex tw-space-x-1 tw-absolute tw-top-0 tw-right-0">
            <CopyToClipboard ref={copyToClipboardRef}>
              <Button
                color="link"
                rounded="full"
                kind="clean"
                className="tw-p-2 tw-shadow-sm tw-bg-gray-100 focus:tw-ring-opacity-0"
                onClick={() => handleCopy(imgSrc)}
              >
                <IconCopyOutlined className="tw-text-blue-gray-900" />
              </Button>
            </CopyToClipboard>
            <Button
              color="link"
              rounded="full"
              kind="clean"
              className="tw-p-2 tw-shadow-sm tw-bg-gray-100 focus:tw-ring-opacity-0"
              onClick={handleDelete}
              disabled={disabled}
            >
              <IconCloseThin className="tw-text-blue-gray-900 tw-w-3 tw-h-3" />
            </Button>
          </div>
        </div>
      ) : (
        <ImageUploadMenu
          className="tw-w-40"
          {...bindFormFieldProps(field)}
          disabled={disabled}
          isLoading={isLoadingUpload}
          onClick={handleUpload}
          info={info}
        />
      )}
    </div>
  );
};
