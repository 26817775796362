import { UserServiceContext } from '@goparrot-dashboard-core/user-service';
import { hasSomePermission } from '@goparrot/users-v2-sdk';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { checkForPermissions } from '@goparrot-dashboard/app';
import { RouteProps } from 'react-router';
import { PermissionsV2ValueType } from '@goparrot/users-v2-sdk/types/role-v2/permission';
import { ErrorBoundary } from '../../components/commons/ErrorBoundary';
import { InsufficientPermissions } from '../../components/commons/utility';

type PermissionType = PermissionsV2ValueType;

type Props = RouteProps & {
  allowed?: PermissionsV2ValueType[] | PermissionsV2ValueType[][];
  restricted?: PermissionType[];
  disabled?: boolean;
};

export const RoleRoute: React.FC<React.PropsWithChildren<Props>> = ({ allowed, restricted, disabled, ...props }) => {
  if (disabled) {
    return null;
  }

  if (!allowed) {
    return (
      <UserServiceContext.Consumer>
        {({ user }) => (
          <ErrorBoundary user={user}>
            <Route {...props} />
          </ErrorBoundary>
        )}
      </UserServiceContext.Consumer>
    );
  }

  return (
    <UserServiceContext.Consumer>
      {({ roleV2, roleV2QueryResult, user }) => {
        if (roleV2QueryResult.isLoading) {
          return null;
        }
        if (!roleV2) {
          return <InsufficientPermissions />;
        }
        const isRestricted = !!restricted?.length && hasSomePermission(roleV2, restricted);
        const hasAccess = allowed.some((permissionGroup) => checkForPermissions(permissionGroup, roleV2));

        if (hasAccess && !isRestricted) {
          return (
            <ErrorBoundary user={user}>
              <Route {...props} />
            </ErrorBoundary>
          );
        }
        return null;
      }}
    </UserServiceContext.Consumer>
  );
};
