import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';

/* eslint-disable no-param-reassign */
export const diff = (gObject: Record<string, any>, gBase: Record<string, any>, options = { ignore: [] as any[] }): Record<string, any> => {
  const changes = (lObject: any, lBase?: any): Record<string, any> =>
    transform(lObject, (result, value, key) => {
      if (!isEqual(value, lBase?.[key]) || options.ignore.includes(key)) {
        if (Array.isArray(value) && lBase?.[key]) {
          (result as any)[key] = value;
        } else {
          (result as any)[key] = isObject(value) && isObject(lBase?.[key]) ? changes(value, lBase?.[key]) : value;
        }
      }
    });
  return changes(gObject, gBase);
};
/* eslint-enable no-param-reassign */
