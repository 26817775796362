import type { RootState } from '@goparrot-dashboard/shared-utils';
import { createSelector } from 'reselect';

export const merchantIdSelector = createSelector(
  (state: RootState) => state.root,
  (root) => root.user?.info?.data?.merchantId,
);

export const storesSelector = createSelector(
  (state: RootState) => state.root,
  (root) => root.location?.data,
);

export const franchisesSelector = createSelector(
  (state: RootState) => state.root,
  (root) => root.franchise?.locations,
);
