import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.LOCAL__ORDER__SET__SELECTED:
      return assign(initialState, { data: action.payload });

    case a.API__ORDER__SELECTED__UPDATE__REQUEST:
      return assign(state, { fetching: true });

    case a.API__ORDER__SELECTED__UPDATE__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.API__ORDER__SELECTED__UPDATE__ERROR:
      return state;

    case a.LOCAL__ORDER__RESET__SELECTED:
      return initialState;

    default:
      return state;
  }
}
