import React from 'react';
import { ItemToggle } from './ItemToggle';
import { ReturnValue, ToggleInputProps } from './interface';
import { getMenuFormIntlString } from './utils';

export const ItemStatus = <T extends { isEnabled: boolean }>({ formLabel, checkedLabel, uncheckedLabel }: ToggleInputProps): ReturnValue => (
  <ItemToggle<T>
    field="isEnabled"
    formLabel={formLabel || getMenuFormIntlString('enabled')}
    checkedLabel={checkedLabel || getMenuFormIntlString('enabled.active')}
    uncheckedLabel={uncheckedLabel || getMenuFormIntlString('enabled.inactive')}
    franchiseEditPermissionField="canEditArchived"
  />
);
