import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { PatchDefaultStoreDto, ReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { defaultStoreByMerchantFacade } from '../facades';

type UpdateDefaultStoreParams = {
  merchantId: string;
  storeId: string;
  data: PatchDefaultStoreDto;
};

export const useUpdateDefaultStore = (
  options?: UseMutationOptions<ReadDefaultStoreDto, unknown, UpdateDefaultStoreParams, unknown>,
): UseMutationResult<ReadDefaultStoreDto, unknown, UpdateDefaultStoreParams, unknown> => {
  return useMutation(({ merchantId, storeId, data }) => defaultStoreByMerchantFacade.patch(merchantId, storeId, data), options);
};
