import * as React from 'react';
import classNames from 'classnames';
import { getIntlString } from '@goparrot-dashboard/i18n';

type Props = {
  shouldWrap: boolean;
};

export const MainFormWrapper: React.FC<React.PropsWithChildren<Props>> = ({ children, shouldWrap }) => {
  const [collapsed, toggleCollapsed] = React.useState(true);
  return shouldWrap ? (
    <div className="tw-space-y-6">
      <a onClick={() => toggleCollapsed(!collapsed)}>{getIntlString('page.merchant.menu.merchantLevel')} Setting</a>
      <div className={classNames(['tw-overflow-hidden', collapsed ? 'tw-max-h-0' : 'tw-max-h-full'])}>{children}</div>
    </div>
  ) : (
    <>{children}</>
  );
};
