import { ICloneRequest, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationCloneStoreItemGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};
export type LocationCloneStoreItemGroupResult = UseMutationResult<ReadStoreItemGroupDto, unknown, LocationCloneStoreItemGroupParams>;

export const useLocationCloneStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, LocationCloneStoreItemGroupParams>,
): LocationCloneStoreItemGroupResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemGroupFacade.clone(merchantId, storeId, uniqueName, data),
    options,
  );
};
