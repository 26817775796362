import { ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { IS_AVAILABLE } from '../constants';
import { Item } from '../interface';
import { filterActions } from './filterActions';

type FilterOptionsType = Partial<Record<'isArchived' | 'isAvailable' | 'isOrderable' | 'isEnabled', boolean>>;
type Props = { item: Item | ReadMenuConceptDto; filterOptions: FilterOptionsType; isMerchant?: boolean; selectedStoreId?: string };

export const filterByCriterias = ({ item, filterOptions, isMerchant, selectedStoreId }: Props): boolean => {
  if (item && filterOptions) {
    let passCriterias = true;

    Object.keys(filterOptions).forEach((criteria) => {
      if (null !== filterOptions[criteria]) {
        if (IS_AVAILABLE === criteria) {
          passCriterias = passCriterias && filterActions[criteria](item as Item, filterOptions[criteria], isMerchant, selectedStoreId);
        } else {
          passCriterias = passCriterias && filterActions[criteria](item, filterOptions[criteria]);
        }
      }
    });

    return passCriterias;
  }

  return true;
};
