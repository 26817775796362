import React, { FC, useCallback } from 'react';
import { useI18n } from '@goparrot-dashboard/i18n';
import { List, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized';
import { ConnectionItem } from '../../../interface';
import { useConnectionsContext } from '../context';
import { ModalItem } from './ModalItem';

type ModalItemListProps = {
  items: ConnectionItem[];
  previewItem: ConnectionItem;
  setPreviewItem: React.Dispatch<React.SetStateAction<ConnectionItem>>;
};

const defaultRowHeight = 75;
const extendedRowHeight = 90;
const getCache = (isCellExtended: boolean) =>
  new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: isCellExtended ? extendedRowHeight : defaultRowHeight,
    minHeight: isCellExtended ? extendedRowHeight : defaultRowHeight,
  });

export const ModalItemList: FC<React.PropsWithChildren<ModalItemListProps>> = ({ items, previewItem, setPreviewItem }) => {
  const { getIntlString } = useI18n();
  const { selectedItems, showType } = useConnectionsContext();

  const isViewed = useCallback((uniqueName: string) => previewItem?.uniqueName === uniqueName, [previewItem]);
  const isSelected = useCallback((uniqueName: string) => selectedItems.find((selected) => selected.uniqueName === uniqueName), [selectedItems]);
  const rows = items.length;
  const cache = React.useMemo(() => getCache(showType), [showType]);

  const rowRenderer = ({ index, key, parent, style }) => {
    const item = items[index];

    return (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div key={key} style={style}>
          <ModalItem isViewed={isViewed(item.uniqueName)} checked={!!isSelected(item.uniqueName)} item={item} setPreviewItem={setPreviewItem} />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div className="tw-h-full tw-pl-8">
      {rows ? (
        <AutoSizer>
          {({ height, width }) => <List rowHeight={cache.rowHeight} width={width} height={height} rowRenderer={rowRenderer} rowCount={rows} />}
        </AutoSizer>
      ) : (
        <div className="tw-pr-2 tw-py-2 tw-text-sm tw-text-blue-gray-700">{getIntlString('modal.items.list.empty')}</div>
      )}
    </div>
  );
};
