import { UpdateMenuConceptItemSetsDto, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantMenuConceptsFacade } from '../../../facades';

type UpdateMenuConceptItemSetsParams = {
  merchantId: string;
  uniqueName: string;
  data: UpdateMenuConceptItemSetsDto[];
};

export const useUpdateMenuConceptItemSets = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, UpdateMenuConceptItemSetsParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, UpdateMenuConceptItemSetsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantMenuConceptsFacade.updateItemSets(merchantId, uniqueName, data), options);
};
