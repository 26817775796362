import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { MerchantDto as MerchantModel, ReadMerchantDto, CreateMerchantDto } from '@goparrot/store-v2-sdk';
import { merchantFacade } from '../facades';

type CreateMerchantParams = {
  data: CreateMerchantDto;
};

export const useCreateMerchant = (
  options?: UseMutationOptions<MerchantModel, unknown, CreateMerchantParams, unknown>,
): UseMutationResult<ReadMerchantDto, unknown, CreateMerchantParams, unknown> => {
  return useMutation(({ data }) => merchantFacade.create(data), options);
};
