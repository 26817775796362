import * as React from 'react';
import Icon, { ThemeType } from 'antd/es/icon';
import CommonTooltip, { TooltipPropsWithTitle as CommonTooltipProps } from 'antd/es/tooltip';

type TooltipProps = CommonTooltipProps & {
  withIcon?: boolean;
  iconType?: string;
  iconTheme?: ThemeType;
  title?: React.ReactNode;
};

const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  withIcon,
  title = 'Your title here',
  iconType = 'question-circle',
  iconTheme = 'outlined',
  overlayStyle = {},
}) => (
  <CommonTooltip title={title} overlayStyle={overlayStyle}>
    {children}
    {/* @ts-ignore-next-line */}
    {withIcon && <Icon type={iconType} theme={iconTheme} />}
  </CommonTooltip>
);

export default Tooltip;
