import React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { IconTrash } from '@goparrot-dashboard/shared-ui';
import { useInputProps } from '@goparrot-dashboard/entity-form';
import { IAbstractItem } from '@goparrot/storeitems-sdk';
import { DialogStateReturn } from 'reakit/Dialog';
import { ButtonVariant } from '@goparrot-dashboard/shared-ui/src/components';
import { useMenuFormContext } from '../../context';
import { getArchivedStatus } from '../../utils';
import { ActionButton } from '../../components/Actions';

type ReturnType = {
  (isItemReplica: boolean, handleArchiveItem: () => void, isLoading: boolean): ActionButton;
};

export const useArchiveButtonActions = <T,>(dialog: DialogStateReturn): ReturnType => {
  const { isMerchant, form, selectedStoreId, isMerchantLevelItem, franchisePermissions } = useMenuFormContext<T>();
  const isModsMode = !isMerchant && isMerchantLevelItem;
  const bindInputProps = useInputProps(form);
  const archivedProps = bindInputProps('isArchived');
  const isItemArchived = getArchivedStatus(form.state as IAbstractItem, selectedStoreId, isMerchant);

  return (isItemReplica: boolean, handleArchiveItem: () => void, isLoading: boolean) => {
    const disableActionButton = isModsMode ? archivedProps.value : isItemReplica ? !franchisePermissions?.canEditArchived : archivedProps.disabled;

    return isItemArchived
      ? {
          title: getIntlString('menu.form.actions.restore'),
          handler: handleArchiveItem,
          disabled: disableActionButton,
          loading: isLoading,
        }
      : {
          disabled: disableActionButton,
          handler: dialog.show,
          buttonOptions: {
            variant: ButtonVariant.DESTRUCTIVE,
          },
          icon: <IconTrash width={18} height={18} />,
        };
  };
};
