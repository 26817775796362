import type { ReadPlacePickerStoreDto } from '@goparrot/webstore-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { WEBSTORE_QUERIES_ENUM } from '../enums';
import { placePickerLegacyStoreFacade } from '../facades';

export const useGetPlacePickerLegacyStoreList = (
  merchantId: string,
  options?: UseQueryOptions<ReadPlacePickerStoreDto[]>,
): UseQueryResult<ReadPlacePickerStoreDto[]> => {
  return useQuery([WEBSTORE_QUERIES_ENUM.PLACEPICKER_LEGACY_STORE_LIST, merchantId], () => placePickerLegacyStoreFacade.list(merchantId), options);
};
