import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { setLocaleMiddleware } from '@goparrot-dashboard/i18n';
import rootReducer from './reducers';
import rootSaga from './sagas';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware, setLocaleMiddleware];

const store = createStore(
  combineReducers({
    root: rootReducer,
    router: connectRouter(history),
  }),
  composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares)),
);
sagaMiddleware.run(rootSaga);

export { store, history };
