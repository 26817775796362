import { useLocation } from 'react-router-dom';
import React from 'react';
import { SettlementConfigurationStatusEnum } from '@goparrot/giftcard-orchestrator-sdk';
import { REDIRECT_FROM, RETURN_TO_URL_PARAMS } from '../constants';
import { isSettlementActive } from '../utils';

export type Params = {
  callback(): void;
  status?: SettlementConfigurationStatusEnum;
  isLoading: boolean;
};

export const useHandleRedirectFromSquare = ({ callback, status, isLoading }: Params): void => {
  const location = useLocation();
  const wasHandledRef = React.useRef(false);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (isLoading || params.get(REDIRECT_FROM) !== RETURN_TO_URL_PARAMS[REDIRECT_FROM]) {
      return;
    }

    if (status && isSettlementActive(status)) {
      wasHandledRef.current = true;
      return;
    }

    if (!wasHandledRef.current) {
      callback();
      wasHandledRef.current = true;
    }
  }, [callback, location.search, status, isLoading]);
};
