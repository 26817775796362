import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadTemplateDto } from '@goparrot/store-v2-sdk';
import { STORE_QUERIES_ENUM } from '../enums';
import { saasFacade } from '../facades';

export const useGetTemplates = (
  merchantId: string,
  options?: UseQueryOptions<ReadTemplateDto[], unknown, ReadTemplateDto[]>,
): UseQueryResult<ReadTemplateDto[]> => {
  return useQuery([STORE_QUERIES_ENUM.GET_TEMPLATES_BY_MERCHANT_ID, merchantId], () => saasFacade.getOrganizationTemplates(merchantId), options);
};
