/* eslint-disable no-underscore-dangle */
import { SubscribeMessageEnum } from '@goparrot/websocket-sdk';
import io from 'socket.io-client';

const BASE = 'http://localhost:8082';

export const createSocketConnection = (merchantId: string, token: string) => {
  const options = {
    reconnectionDelay: 10000,
    reconnectionAttempts: 5,
    auth: { token },
  };
  const ws = io(`${window._env_.GOPARROT_PLATFORM_WEBSOCKET || BASE}/menuSync`, options);
  return new Promise((resolve) => {
    if (merchantId) {
      ws.on('connect', () => {
        resolve(ws.emit(SubscribeMessageEnum.JOIN_MERCHANT_ROOM, `merchant:${merchantId}`));
      });
    }
    resolve(ws.off('disconnect'));
  });
};
