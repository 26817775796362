import type { Item } from '../interface';
import { filterByTitleAndPosGuid } from './filterByTitleAndPosGuid';

export const filterListBySearchQuery = <T extends Item>(list: T[] = [], searchQuery: string): T[] => {
  let filteredItems = list;
  if (searchQuery) {
    filteredItems = list.filter((item) => {
      return filterByTitleAndPosGuid({ searchQuery, item });
    });
  }

  return filteredItems;
};
