import { UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationUpdateCategoryChildrenOrderParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export const useLocationUpdateCategoryChildrenOrder = (
  options: UseMutationOptions<UpdateShowOrderResponseDto, unknown, LocationUpdateCategoryChildrenOrderParams>,
): UseMutationResult<UpdateShowOrderResponseDto, unknown, LocationUpdateCategoryChildrenOrderParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationCategoryFacade.updateChildrenShowOrder(merchantId, storeId, uniqueName, data),
    options,
  );
};
