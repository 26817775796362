import React, { useCallback, useRef } from 'react';
import { Button, GenericTable, IconCloseThin, IconDragAndDrop, IconDragAndDropVertical, Spinner, TableCellText } from '@goparrot-dashboard/shared-ui';
import { useTable } from 'react-table';
import { useI18n, I18nService } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { IReadStoreItemGroupDto, ItemGroupingTypeEnum } from '@goparrot/storeitems-sdk';
import findKey from 'lodash/findKey';
import classNames from 'classnames';
import { useDialogState } from 'reakit';
import { ConnectionItem, Item } from '../../interface';
import { MENU_ELEMENTS } from '../../constants';
import { isArchived, isMenuConcept, getConnectionUrl, canLinkMenuItem, getStoreTitle } from '../../utils';
import { TableTitleWrapper } from '../TableTitleWrapper';
import { useConnectionsContext } from './context';
import { RemoveConnectionConfirm } from './ConfirmRemoveConnection';
import { useConnectionAvailability } from './hooks';

type Props = {
  isLoadingTable: boolean;
  isItemReplicaConnections: boolean;
  canEditFranchiseConnections: boolean;
  hideButtonDelete?: boolean;
};

const ConnectionsTableComponent: React.FC<React.PropsWithChildren<Props>> = ({
  isLoadingTable = false,
  isItemReplicaConnections,
  canEditFranchiseConnections,
  hideButtonDelete,
}) => {
  const { getIntlString } = useI18n();
  const { stores, userStores, selectedStoreId, isVirtualMenu } = useStoreService();
  const dialog = useDialogState({ animated: true });
  const { show: dialogShow, hide: dialogHide } = dialog;
  const removeConnectionItemRef = useRef<{ uniqueName: string; title: string } | null>(null);
  const {
    currentConnections,
    isUpdatingConnection,
    onUpdateConnections,
    selectedItems,
    disabled,
    selectedLevelStoreId,
    merchantId,
    isDraggable,
    emptyTablePlaceholder,
    handleDragAndDrop,
    title,
    showArchived,
  } = useConnectionsContext();
  const availabilityColumns = useConnectionAvailability();
  const isMerchant = !selectedLevelStoreId || selectedLevelStoreId === merchantId;
  const hasAvailabilityColumns = availabilityColumns !== null;
  const data = React.useMemo(() => {
    const archived = [];
    // availabilityColumns condition should be removed once all mm entities will have columns of availability
    const filtered = currentConnections.filter((c) => {
      if (isMenuConcept(c)) return true;

      const isItemArchived = isArchived(c, isMerchant, selectedLevelStoreId);

      if (hasAvailabilityColumns && isItemArchived && showArchived) {
        archived.push(c);
        return false;
      }

      return !isItemArchived;
    });

    const preFilteredItems = [...filtered, ...archived];

    if (!userStores?.length) {
      return preFilteredItems;
    }
    return preFilteredItems.filter((item) => item.storeId === merchantId || userStores.includes(item.storeId));
  }, [currentConnections, userStores, isMerchant, selectedLevelStoreId, hasAvailabilityColumns, showArchived, merchantId]);

  const showDeleteButton = hideButtonDelete ? false : isItemReplicaConnections ? canEditFranchiseConnections : isMerchant ? true : !disabled;

  const getItemLink = React.useCallback((item: ConnectionItem) => getConnectionUrl(item, selectedLevelStoreId), [selectedLevelStoreId]);
  const canLinkItem = React.useCallback((item: ConnectionItem): boolean => canLinkMenuItem({ item, isMerchant, selectedStoreId: selectedLevelStoreId }), [
    isMerchant,
    selectedLevelStoreId,
  ]);

  const getStoreName = React.useCallback((storeId: string) => getStoreTitle({ locations: stores, storeId, merchantId, isVirtualMenu }), [
    stores,
    merchantId,
    isVirtualMenu,
  ]);

  const getItemType = useCallback((value: StoreItemTypeEnum | undefined, item: ConnectionItem) => {
    if (isMenuConcept(item)) {
      return MENU_ELEMENTS.ITEM_SET_CONCEPT;
    }

    const itemTypeKey = findKey(StoreItemTypeEnum, (val) => val === value);
    if (StoreItemTypeEnum.STORE_ITEM_GROUP === item.type) {
      if ((item as IReadStoreItemGroupDto).groupingType === ItemGroupingTypeEnum.VARIATION) {
        return MENU_ELEMENTS.STORE_ITEM;
      }
    }
    return MENU_ELEMENTS[itemTypeKey];
  }, []);

  const onRemoveConnection = useCallback(
    (uniqueName: string) => {
      const updatedConnections = [];
      selectedItems.forEach((s) => {
        if (s.uniqueName !== uniqueName) {
          updatedConnections.push(s.uniqueName);
        }
      });
      onUpdateConnections(updatedConnections);
      dialogHide();
    },
    [onUpdateConnections, selectedItems, dialogHide],
  );

  const isDragDisabled = React.useCallback((origin: Item) => isArchived(origin, isMerchant, selectedStoreId), [isMerchant, selectedStoreId]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (isDraggable ? <IconDragAndDropVertical className="tw-ml-1.5" /> : ''),
        accessor: 'dragDrop',
        Cell: ({ row: { original } }: { row: { original: Item } }) =>
          isDraggable && !isDragDisabled(original) ? <IconDragAndDrop className="tw-text-gray-500 tw-mt-1.5" /> : <div />,
        className: 'tw-w-10',
      },
      {
        Header: '#',
        accessor: (row: ConnectionItem) => row.uniqueName,
        Cell: ({ row: { index } }: { row: { index: number } }) => {
          return <TableCellText>{index + 1}</TableCellText>;
        },
        className: 'tw-w-10',
      },
      {
        Header: getIntlString('columns.title'),
        accessor: 'title',
        Cell: ({ value, row: { original } }: { value: string; row: { original: ConnectionItem } }) => {
          const internalNameNode = (
            <span>
              {(original as Item).internalName}
              {isArchived(original as Item, isMerchant, selectedStoreId) && (
                <span className="tw-ml-1">({getIntlString('menu.form.archived', { isRootPath: true })})</span>
              )}
            </span>
          );

          return (
            <TableCellText className="tw-max-w-64">
              <TableTitleWrapper openNewTab linkTo={canLinkItem(original) ? getItemLink(original) : ''} title={value} internalName={internalNameNode} />
            </TableCellText>
          );
        },
      },
      {
        Header: getIntlString('columns.store'),
        accessor: 'storeId',
        className: 'tw-w-3/12',
        Cell: ({ value }: { value: string }) => <TableCellText>{getStoreName(value)}</TableCellText>,
      },
      {
        Header: getIntlString('columns.type'),
        accessor: 'type',
        className: 'tw-w-2/12',
        Cell: ({ value, row: { original } }: { value: StoreItemTypeEnum | undefined; row: { original: ConnectionItem } }) => (
          <TableCellText>{getItemType(value, original)}</TableCellText>
        ),
      },
      ...(availabilityColumns ?? []),
      {
        key: 'more',
        accessor: (row: ConnectionItem) => row.uniqueName,
        id: 'settings',
        Cell: ({ value, row: { original } }: { value: string; row: { original: ConnectionItem } }) =>
          showDeleteButton && (
            <Button
              kind="clean"
              color="clean"
              className="tw-bg-cool-gray-100 tw-p-1.5 tw-justify-center"
              size="clean"
              rounded="full"
              onClick={() => {
                dialogShow();
                removeConnectionItemRef.current = { uniqueName: value, title: original.title };
              }}
            >
              <IconCloseThin className="tw-w-3 tw-h-3" />
            </Button>
          ),

        className: 'tw-w-8',
      },
    ],
    [
      getIntlString,
      availabilityColumns,
      isDraggable,
      isDragDisabled,
      isMerchant,
      selectedStoreId,
      canLinkItem,
      getItemLink,
      getStoreName,
      getItemType,
      showDeleteButton,
      dialogShow,
    ],
  );

  const tableProps = useTable({ columns, data });

  return (
    <Spinner spinning={isLoadingTable}>
      <GenericTable
        {...tableProps}
        placeholder={emptyTablePlaceholder}
        fixedHeader
        tableClassName={classNames([!isItemReplicaConnections && disabled && 'tw-bg-gray-100'])}
        onDragEnd={isDraggable && handleDragAndDrop}
        checkRowIsDraggable={isDragDisabled}
      />
      <RemoveConnectionConfirm
        key={title}
        dialog={dialog}
        onConfirm={() => onRemoveConnection(removeConnectionItemRef.current?.uniqueName)}
        connectionType={title}
        itemName={removeConnectionItemRef.current?.title}
        isLoading={isUpdatingConnection}
      />
    </Spinner>
  );
};

export const ConnectionsTable: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <I18nService prefix="menu.connections.table">
    <ConnectionsTableComponent {...props} />
  </I18nService>
);
