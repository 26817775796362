import type { ConnectionItem, Item } from '../interface';
import { POS_GUID_LENGTH } from '../constants';
import { filterActions } from './filterActions';

export const filterAddConnectionsListBySearchQuery = <T extends ConnectionItem>(list: T[] = [], searchQuery: string): T[] => {
  let filteredList = list;
  if (searchQuery) {
    filteredList = filteredList.filter((item) => {
      if (searchQuery.length === POS_GUID_LENGTH) {
        return (
          filterActions.byTitleEqual(item, searchQuery) ||
          filterActions.byPosGuidEqual(item as Item, searchQuery) ||
          filterActions.byInternalNameEqual(item as Item, searchQuery)
        );
      }

      return filterActions.byTitle(item, searchQuery) || filterActions.byInternalName(item as Item, searchQuery);
    });
  }

  return filteredList;
};
