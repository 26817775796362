import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { UpdateRelationsResponseDto, IRewriteRelationsRequest, IStoreItemGroupProperties } from '@goparrot/storeitems-sdk';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdateStoreItemGroupChildrenParams = {
  merchantId: string;
  uniqueName: string;
  payload: IRewriteRelationsRequest<IStoreItemGroupProperties>;
};

export const useMerchantUpdateStoreItemGroupChildren = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupChildrenParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupChildrenParams> => {
  return useMutation(({ merchantId, uniqueName, payload }) => merchantStoreItemGroupFacade.updateProperties(merchantId, uniqueName, payload), options);
};
