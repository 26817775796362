import { FormField, Toggle } from '@goparrot-dashboard/shared-ui';
import { useI18n, getIntlString as getIntlStringBase } from '@goparrot-dashboard/i18n';
import React, { FC } from 'react';

import { SynchronizationLabel } from '../synchronization-label/SynchronizationLabel';

type GiftCardManagementProps = {
  showSynchronizationStatus?: boolean;
};

export const GiftCardManagement: FC<React.PropsWithChildren<GiftCardManagementProps>> = ({ showSynchronizationStatus }) => {
  const { getIntlString } = useI18n();

  return (
    <FormField
      label={
        <div className="tw-flex tw-flex-col">
          <span>{getIntlString('giftCards.title')}</span>
          {showSynchronizationStatus && <SynchronizationLabel value />}
        </div>
      }
      input={
        <Toggle
          onChange={() => null}
          checkedLabel={getIntlStringBase('variant.enabled')}
          uncheckedLabel={getIntlStringBase('variant.disabled')}
          checked
          disabled
        />
      }
    />
  );
};
