import React, { Dispatch, FC, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PatchLocationDto, PatchNotificationDto } from '@goparrot/menu-orchestrator-sdk';
import { useI18n } from '@goparrot-dashboard/i18n';
import { PanelTitle, Spinner } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import franchiseActions from 'store/franchise/actions';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { diff } from '@goparrot-dashboard/shared-utils';
import { UseQueryResult } from 'react-query';
import { ReadSyncRecordDto } from '@goparrot/square-etl-sdk';
import { MenuManagementWrapper } from '../menu-management-wrapper/MenuManagementWrapper';
import { useUpdateLocation } from '../../hooks';
import { locationConfigToExplicitFormat, menuManagementToLocationConfig } from '../../utils';
import { ExplicitFranchiseLocationOptions, StoreWithLocation } from '../../types';

const HIERARCHY_RESET_MAPPING: { [p in keyof ExplicitFranchiseLocationOptions]?: string[] } = {
  menuReplica: [
    'menuUpdateLabels',
    'menuConnections',
    'menuUpdatePrice',
    'menuUpdateArchived',
    'menuUpdateAvailability',
    'menuUpdateOrderable',
    'menuUpdateSku',
    'menuUpdateExternalImageUrl',
  ],
};
const DEFAULT_NOTIFICATION_STATE = { isEnabled: false, emails: [] };

type FranchiseManagementSettingsProps = {
  ref: Ref<{ submitSettings: () => void | null }>;
  setCanSaveSettings: Dispatch<React.SetStateAction<boolean>>;
  storeId?: string;
  location: StoreWithLocation | null;
  showSynchronizationStatus?: boolean;
  refetch: () => void;
  loading: boolean;
  menuNotification?: PatchNotificationDto;
  itemSets?: string[];
  setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
  syncRecordResult?: UseQueryResult<ReadSyncRecordDto>;
  isMultiMenu?: boolean;
  isMenuSettingsPageEnabled?: boolean;
};

export const FranchiseManagementSettings: FC<React.PropsWithChildren<FranchiseManagementSettingsProps>> = forwardRef((props, ref) => {
  const [menuOptions, setMenuOptions] = useState<ExplicitFranchiseLocationOptions | null>(null);
  const [changedMenuOptions, setChangedMenuOptions] = useState<ExplicitFranchiseLocationOptions | null>(null);
  const {
    merchant: { merchantId },
  } = useStoreService();
  const { setIsLoading } = props;
  const dispatch = useDispatch();
  const { getIntlMessage } = useI18n();
  const notificationChanged = React.useMemo(
    () => !isEmpty(diff(props.location?.franchiseLocation?.notification ?? DEFAULT_NOTIFICATION_STATE, props.menuNotification ?? DEFAULT_NOTIFICATION_STATE)),
    [props.location?.franchiseLocation?.notification, props.menuNotification],
  );
  const menuOptionsChanged = React.useMemo(() => !isEqual(menuOptions, changedMenuOptions), [changedMenuOptions, menuOptions]);
  const itemSetsChanged = React.useMemo(() => !isEqual(props.location?.franchiseLocation?.itemSets, props.itemSets), [
    props.itemSets,
    props.location?.franchiseLocation?.itemSets,
  ]);

  const canSaveSettings = useMemo(() => {
    if (notificationChanged || menuOptionsChanged || itemSetsChanged) {
      return false;
    }
    return true;
  }, [itemSetsChanged, menuOptionsChanged, notificationChanged]);

  useEffect(() => {
    props.setCanSaveSettings(canSaveSettings);
  }, [canSaveSettings, props]);

  const changeOption = (key: keyof ExplicitFranchiseLocationOptions, value: boolean) => {
    if (!changedMenuOptions) {
      return;
    }

    const newState = cloneDeep(changedMenuOptions);
    newState[key] = value;

    if (HIERARCHY_RESET_MAPPING[key] !== undefined && !value) {
      HIERARCHY_RESET_MAPPING[key]?.forEach((mappedKey) => {
        newState[mappedKey as keyof ExplicitFranchiseLocationOptions] = false;
      });
    }
    setChangedMenuOptions(newState);
  };

  const { updateLocation, isLoading } = useUpdateLocation(() => {
    props.refetch();
    dispatch(franchiseActions.requestLocationsListGet({ merchantId }));
  });

  useEffect(() => {
    setIsLoading && setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const disabledIfEmailsEmpty = useCallback(() => {
    if (!props.menuNotification?.emails?.length) {
      return {
        isEnabled: false,
        emails: [],
      };
    }
    return props.menuNotification;
  }, [props.menuNotification]);

  const isPatchItemSets = !menuOptionsChanged && !notificationChanged && itemSetsChanged && props.isMultiMenu;

  const submitSettings = useCallback(() => {
    const storeId = props.storeId || props.location?.franchiseLocation?.storeId;

    if (!storeId) {
      return;
    }

    const data = menuManagementToLocationConfig(changedMenuOptions);
    const body: PatchLocationDto = isPatchItemSets
      ? { itemSets: props.itemSets }
      : {
          ...(!props.storeId && { isCentralLocation: true }),
          ...data,
          notification: disabledIfEmailsEmpty(),
          itemSets: props.itemSets,
        };

    updateLocation(merchantId, storeId, body);
  }, [changedMenuOptions, disabledIfEmailsEmpty, isPatchItemSets, merchantId, props.itemSets, props.location, props.storeId, updateLocation]);

  useImperativeHandle(ref, () => ({
    submitSettings,
  }));

  useEffect(() => {
    if (props.location?.franchiseLocation) {
      const options: ExplicitFranchiseLocationOptions = locationConfigToExplicitFormat(props.location.franchiseLocation);
      setMenuOptions(options);
      setChangedMenuOptions(options);
    }
  }, [props.location]);

  return (
    <Spinner isMarketComponent spinning={props.loading}>
      {!props.isMenuSettingsPageEnabled && <PanelTitle testId="franchise-management-settings-header">{getIntlMessage('central.settingsTitle')}</PanelTitle>}
      <MenuManagementWrapper isMenuSettingsPageEnabled={props.isMenuSettingsPageEnabled} changedMenuOptions={changedMenuOptions} changeOption={changeOption} />
    </Spinner>
  );
});
