import { createAction } from 'helpers/create-action';

const c = {
  ITEM__SYNC__JOB__SUBSCRIBE: 'ITEM__SYNC__SUBSCRIBE',
  ITEM__SYNC__JOB__EMIT: 'ITEM__SYNC__EMIT',
  ITEM__SYNC__JOB__SUCCESS: 'ITEM__SYNC__SUCCESS',
  ITEM__SYNC__JOB__ERROR: 'ITEM__SYNC__ERROR',
};

const a = {
  subscribeItemSyncJob: createAction(c.ITEM__SYNC__JOB__SUBSCRIBE),
  emitItemSyncJob: createAction(c.ITEM__SYNC__JOB__EMIT),
  successItemSyncJob: createAction(c.ITEM__SYNC__JOB__SUCCESS),
  errorItemSyncJob: createAction(c.ITEM__SYNC__JOB__ERROR),
};

export default { ...c, ...a };
