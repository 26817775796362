import { UseQueryOptions, UseQueryResult } from 'react-query';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { LocationItemBaseReplicasGetParams } from '@goparrot-dashboard-api/menu-management';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';

export type GetItemBaseReplicasProps = {
  uniqueName: string;
  enabled: boolean;
  useLocationItemBaseReplicasGet: (props: LocationItemBaseReplicasGetParams, options?: UseQueryOptions<ReadItemBaseDto[]>) => UseQueryResult<ReadItemBaseDto[]>;
};

export const useGetItemBaseReplicas = ({
  uniqueName,
  enabled,
  useLocationItemBaseReplicasGet,
}: GetItemBaseReplicasProps): UseQueryResult<ReadItemBaseDto[]> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.get.error', {
          itemType: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.item-base.loading'),
        }),
      });
    },
  };

  const query = useLocationItemBaseReplicasGet(
    { uniqueName },
    {
      ...options,
      enabled: enabled,
      retry: 2,
    },
  );

  return query;
};
