import type { ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemComboFacade } from '../../../facades';
import { ComboCreateMutationParams } from '../../../interface';

export type MerchantComboCreateMutationResult = UseMutationResult<ReadStoreItemComboDto, unknown, ComboCreateMutationParams>;

export const useCreateMerchantCombo = (
  options?: UseMutationOptions<ReadStoreItemComboDto, unknown, ComboCreateMutationParams>,
): MerchantComboCreateMutationResult => {
  return useMutation(({ merchantId, data }) => merchantStoreItemComboFacade.create(merchantId, data), options);
};
