import { useRef, useState, useCallback } from 'react';
import { DialogStateReturn } from '@goparrot-dashboard/shared-ui';

export const useDialogState = (): DialogStateReturn => {
  const dialogRef = useRef<HTMLMarketDialogElement & HTMLMarketModalFullElement>(null);
  const [visible, setVisible] = useState(false);

  const show = useCallback(() => setVisible(true), []);

  const dismiss = () => setVisible(false);

  return { dialogRef, visible, show, dismiss };
};
