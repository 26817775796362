import { ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { locationStoreItemGroupFacade } from '../../../facades';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useLocationStoreItemGroupGetQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadStoreItemGroupDto>,
): UseQueryResult<ReadStoreItemGroupDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GROUP_GET, merchantId, storeId, uniqueName],
    async () => await locationStoreItemGroupFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
