import { takeEvery, put, call } from 'redux-saga/effects';

import { ACTION_TYPES, ACTION_CREATORS } from './actions';
import * as api from './api';

function* handleGet({ payload }: ReturnType<typeof ACTION_CREATORS.requestLocationsListGet>) {
  try {
    const result = yield call(api.getLocations(payload.merchantId));
    yield put(ACTION_CREATORS.successLocationsListGet(result));
  } catch (e) {
    yield put(ACTION_CREATORS.errorLocationsListGet(e));
  }
}

export default function* () {
  yield takeEvery(ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_REQUEST, handleGet);
}
