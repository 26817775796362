import React, { FC } from 'react';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { LocationGetRelativesQueryParams } from '@goparrot-dashboard-api/menu-management';
import { useI18n } from '@goparrot-dashboard/i18n';
import { isMerchantLevel } from '../../../../utils/isMerchantLevel';
import { ConnectionItem, Item } from '../../../../interface';
import { PreviewConnectionProps, QueryType } from './interface';
import { ModalPreviewConnectionsTable } from './ModalPreviewConnectionsTable';
import { ModalPreviewConnectionHeader } from './ModalPreviewConnectionHeader';

function getQueryByItemType<T = unknown>(query: QueryType<T> | Record<StoreItemTypeEnum, QueryType<T>>, type?: StoreItemTypeEnum) {
  if (typeof query === 'object') {
    const value = type || Object.keys(query)[0];
    return query[value];
  }
  return query;
}

export const ModalPreviewConnection: FC<React.PropsWithChildren<PreviewConnectionProps>> = ({ connection, item, merchantId }) => {
  const { getIntlString } = useI18n();
  const { notifyError } = useNotifications();
  const { locationQuery, merchantQuery, merchantQueryParams, locationQueryParams } = connection;
  const { uniqueName, title } = item;
  const isMerchantItem = isMerchantLevel<ConnectionItem>(item);

  const locationConnectionQuery = getQueryByItemType<LocationGetRelativesQueryParams>(locationQuery, (item as Item).type);
  const merchantConnectionQuery = getQueryByItemType(merchantQuery, (item as Item).type);

  const queryLocation =
    locationQuery &&
    locationConnectionQuery(
      { merchantId, storeId: item.storeId, uniqueName, ...(locationQueryParams && { params: locationQueryParams }) },
      {
        onError: () => {
          notifyError({
            text: getIntlString('menu.notifications.connections.modal.preview.get.error', { values: { itemName: title }, isRootPath: true }),
          });
        },
        enabled: !isMerchantItem,
      },
    );

  const queryMerchant =
    merchantQuery &&
    merchantConnectionQuery(
      { merchantId, uniqueName, ...(merchantQueryParams && { params: merchantQueryParams }) },
      {
        onError: () => {
          notifyError({
            text: getIntlString('menu.notifications.connections.modal.preview.get.error', { values: { itemName: title }, isRootPath: true }),
          });
        },
        enabled: isMerchantItem,
      },
    );

  const data = isMerchantItem ? queryMerchant?.data : queryLocation?.data;
  const isLoading = isMerchantItem ? queryMerchant?.isFetching : queryLocation?.isFetching;

  return (
    <ModalPreviewConnectionHeader
      title={
        connection.fullName ?? (
          <>
            <span>{getIntlString('menu.connections.modal.preview.connectedTo', { isRootPath: true })}</span>
            <span>{connection.name}</span>
          </>
        )
      }
    >
      <ModalPreviewConnectionsTable data={data || []} isLoadingTable={!!isLoading} />
    </ModalPreviewConnectionHeader>
  );
};
