import React, { useCallback } from 'react';
import { useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { GenericTable, Spinner, TableCellText } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useI18n } from '@goparrot-dashboard/i18n';
import { canLinkMenuItem, getConnectionUrl } from '../../../../utils';
import { ConnectionItem } from '../../../../interface';
import { PreviewTableProps } from './interface';

export const ModalPreviewConnectionsTable: React.FC<React.PropsWithChildren<PreviewTableProps>> = ({ isLoadingTable = false, data, columns }) => {
  const { getIntlString } = useI18n();
  const {
    selectedStoreId,
    isMerchant,
    userStores,
    merchant: { merchantId },
  } = useStoreService();
  const canLinkItem = useCallback((item: ConnectionItem): boolean => canLinkMenuItem({ item, isMerchant, selectedStoreId }), [isMerchant, selectedStoreId]);
  const getItemLink = useCallback((item: ConnectionItem) => getConnectionUrl(item, selectedStoreId), [selectedStoreId]);
  const dataSource = React.useMemo(() => {
    const filteredData = data.filter((item) => !item.isArchived);

    if (!userStores?.length) {
      return filteredData;
    }

    return filteredData.filter((item) => item.storeId === merchantId || userStores.includes(item.storeId));
  }, [data, merchantId, userStores]);

  const defaultColumns = React.useMemo(
    () => [
      {
        Header: getIntlString('menu.connections.modal.preview.table.columns.name', { isRootPath: true }),
        accessor: 'title',
        sorting: true,
        className: 'tw-w-6/12',
        Cell: ({ value, row: { original } }: { value: string; row: { original: ConnectionItem } }) => (
          <TableCellText className="tw-max-w-64">
            {canLinkItem(original) ? (
              <Link to={getItemLink(original)} className="tw-text-light-blue-400 hover:tw-text-blue-gray-900" target="_blank">
                <span className="tw-w-max">{value}</span>
              </Link>
            ) : (
              <div>{value}</div>
            )}
          </TableCellText>
        ),
      },
      {
        Header: getIntlString('menu.connections.modal.preview.table.columns.nameInPos', { isRootPath: true }),
        accessor: 'metadata.pos.pos_guid',
        disableSortBy: true,
        className: 'tw-w-6/12',
        Cell: ({ value }: { value: string }) => <TableCellText>{value || 'n/a'}</TableCellText>,
      },
    ],
    [canLinkItem, getIntlString, getItemLink],
  );

  const tableProps = useTable({ columns: columns ?? defaultColumns, data: dataSource }, useSortBy);

  return (
    <Spinner spinning={isLoadingTable}>
      <GenericTable {...tableProps} />
    </Spinner>
  );
};
