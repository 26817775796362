import React, { FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { UseCheckboxPropsResult } from '@goparrot-dashboard/entity-form';
import { Toggle, Tooltip, Button, IconQuestion } from '@goparrot-dashboard/shared-ui';

type ItemModProps = {
  title: string;
  tooltipTitle?: string;
  bindProps: UseCheckboxPropsResult;
  disabled?: boolean;
  isDisabledOnMerchant?: boolean;
};

export const ItemMod: FC<React.PropsWithChildren<ItemModProps>> = ({ title, tooltipTitle, bindProps, disabled, isDisabledOnMerchant }) => {
  return (
    <div className="tw-mr-9 last:tw-mr-0">
      {tooltipTitle ? (
        <div className="tw-flex tw-items-center tw-mb-0.5">
          <h4 className="tw-blue-gray-900 tw-mb-0 tw-font-bold">{title}</h4>
          <Tooltip tooltipProps={{ placement: 'top' }} tooltip={<div className="tw-max-w-xs">{tooltipTitle}</div>}>
            <Button kind="clean" color="clean" className="tw-w-4 tw-h-4 tw-outline-none tw-ring-transparent tw-ml-2">
              <IconQuestion />
            </Button>
          </Tooltip>
        </div>
      ) : (
        <h4 className="tw-blue-gray-900 tw-mb-0.5 tw-font-bold">{title}</h4>
      )}
      <Tooltip tooltip={isDisabledOnMerchant ? getIntlString('page.merchant.menu.table.disabledOnMerchant.tooltip', { isRootPath: true }) : null}>
        <div className="tw-w-0">
          <Toggle
            {...bindProps}
            isCritical
            disabled={disabled}
            checked={bindProps.checked}
            checkedLabel={getIntlString('variant.yes')}
            uncheckedLabel={getIntlString('variant.no')}
          />
        </div>
      </Tooltip>
    </div>
  );
};
