import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { InviteEmailConfirmFranchiseUserDto, ReadUserDto } from '@goparrot/users-v2-sdk';
import { userFacadeConfig } from '../facades';

type FranchiseConfirmOrganizationParams = {
  uuid: string;
  data: InviteEmailConfirmFranchiseUserDto;
};

export const useFranchiseConfirmOrganization = (
  options?: UseMutationOptions<ReadUserDto, unknown, FranchiseConfirmOrganizationParams>,
): UseMutationResult<ReadUserDto, unknown, FranchiseConfirmOrganizationParams> => {
  return useMutation(({ uuid, data }) => userFacadeConfig.franchiseConfirmOrganization(uuid, data), options);
};
