import { IUpdateRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type MerchantUpdateItemBaseGroupSelectionsParams = {
  merchantId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};
export const useMerchantUpdateItemBaseGroupSelections = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateItemBaseGroupSelectionsParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateItemBaseGroupSelectionsParams> => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantItemBaseGroupFacade.updateItemOptionSelections(merchantId, uniqueName, data),
    options,
  );
};
