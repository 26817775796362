import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationStoreItemReplicaFacade } from '@goparrot-dashboard-api/shared';
import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type LocationStoreItemReplicasGetParams = { uniqueName: string };

export const useLocationStoreItemReplicasGet = (
  { uniqueName }: LocationStoreItemReplicasGetParams,
  options?: UseQueryOptions<ReadStoreItemDto[]>,
): UseQueryResult<ReadStoreItemDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_REPLICAS_GET, uniqueName],
    async () => await locationStoreItemReplicaFacade.list(uniqueName),
    options,
  );
};
