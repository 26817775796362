import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ChildrenEntitiesDtoType } from '@goparrot/storeitems-sdk';
import { merchantCategoryFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useMerchantGetCategoryChildrenQuery = (
  { merchantId, uniqueName, params }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ChildrenEntitiesDtoType[]>,
): UseQueryResult<ChildrenEntitiesDtoType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_CATEGORY_GET_CHILDREN, merchantId, uniqueName, params],
    async () => await merchantCategoryFacade.getChildren(merchantId, uniqueName, params),
    options,
  );
};
