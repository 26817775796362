import React, { FC, Fragment } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketCheckbox, MarketList, MarketRow, MarketSelect } from '@market/react-bindings';
import { GroupedOption } from './helpers';

type Props = {
  priceUpdateOptions: GroupedOption[];
  storeIds: string[];
};

export const ActionInputReplicaLocations: FC<React.PropsWithChildren<Props>> = React.memo(({ priceUpdateOptions, storeIds }) => {
  return (
    <MarketSelect name="locations" multiselect value={storeIds}>
      <label>{getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions.form.locations', { isRootPath: true })}</label>
      <MarketList slot="list">
        {priceUpdateOptions.map((option, index) => {
          if (option.label) {
            return (
              <Fragment key={`${option.label}-group-${index}`}>
                <h5 className="tw-mb-0">{option.label}</h5>
                {option.options.map((item) => (
                  <MarketRow key={item.value} className="tw-pl-8" value={item.value} disabled>
                    {item.label}
                    <MarketCheckbox slot="control" disabled />
                  </MarketRow>
                ))}
              </Fragment>
            );
          }
          return option.options.map((item, index) => (
            <Fragment key={`${item.value}-location-${index}`}>
              <MarketRow value={item.value} disabled>
                {item.label}
                <MarketCheckbox slot="control" disabled />
              </MarketRow>
            </Fragment>
          ));
        })}
      </MarketList>
    </MarketSelect>
  );
});
