import { useDispatch } from 'react-redux';
import aUser from '../../../../modules/client/src/store/user/actions';

type UseAfterRegisterResult = {
  (email: string): void;
};
export const useAfterRegister = (): UseAfterRegisterResult => {
  const dispatch = useDispatch();
  const afterRegister = (email: string) => {
    dispatch(aUser.successCreate({ email }));
  };

  return afterRegister;
};
