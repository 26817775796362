import {
  usePatchCategoryMods,
  useLocationUpdateCategory,
  useMerchantUpdateCategory,
  PatchCategoryModsParams,
  MerchantUpdateCategoryParams,
  LocationUpdateCategoryParams,
} from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { UseMutationResult } from 'react-query';
import { MENU_ELEMENTS } from '../../constants';

type Props = {
  successCallback?: (result: ReadCategoryDto) => void;
};

export type UpdateCategoryActionsType = {
  updateMods: UseMutationResult<ReadCategoryDto, unknown, PatchCategoryModsParams>;
  merchantLevelUpdate: UseMutationResult<ReadCategoryDto, unknown, MerchantUpdateCategoryParams>;
  storeLevelUpdate: UseMutationResult<ReadCategoryDto, unknown, LocationUpdateCategoryParams>;
  isLoading: boolean;
};

export const useUpdateCategoryActions = ({ successCallback }: Props): UpdateCategoryActionsType => {
  const { notifyError, notifySuccess } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.update.error'),
      });
    },
    onSuccess: (result) => {
      notifySuccess({
        text: getIntlString('menu.notifications.update.success', { itemType: MENU_ELEMENTS.CATEGORY }),
      });
      successCallback && successCallback(result);
    },
  };
  const updateMods = usePatchCategoryMods(options);
  const merchantLevelUpdate = useMerchantUpdateCategory(options);
  const storeLevelUpdate = useLocationUpdateCategory(options);

  return {
    updateMods,
    merchantLevelUpdate,
    storeLevelUpdate,
    isLoading: updateMods.isLoading || merchantLevelUpdate.isLoading || storeLevelUpdate.isLoading,
  };
};
