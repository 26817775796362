import { createGlobalStyle } from 'styled-components';

import { palette } from './constants';

/* ********* Add Your Global CSS Here ********* */
export const GlobalStyle = createGlobalStyle`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    p,
    li,
    input,
    textarea,
    span,
    div,
    html,
    body,
    html a {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    }

    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow:after {
        left: 0;
        margin-left: -4px;
    }

    input {

      &.market {
        border-color: ${palette.grayscale[28]};
        -webkit-box-shadow: 0 0 0 30px ${palette.color[20]} inset;
        -moz-box-shadow: 0 0 0 30px ${palette.color[20]} inset;
        -o-box-shadow: 0 0 0 30px ${palette.color[20]} inset;
        box-shadow: 0 0 0 30px ${palette.color[20]} inset;
        -webkit-text-fill-color: ${palette.primary[26]};
        -o-text-fill-color: ${palette.primary[26]};
        -moz-text-fill-color: ${palette.primary[26]};

        &:focus-within {
          outline: none;
        }

        &::selection {
          background-color: ${palette.secondary[14]};
        }
      }
      
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: ${palette.grayscale[0]};
      }
    }

    .gu-mirror {
        background-color: #e6f7ff;
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        text-align: center;
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      white-space:pre-line;  
      position:relative; 
    }
    ::-moz-placeholder { /* Firefox 19+ */
         white-space:pre-line;  
      position:relative;
    }
    :-ms-input-placeholder { /* IE 10+ */
        white-space:pre-line;  
      position:relative;
    }
    :-moz-placeholder { /* Firefox 18- */
         white-space:pre-line;  
      position:relative;
    }

    @media only print {
        html, body {
            width: 100%;
            height: auto;
        }
        .ant-modal-mask {
            display: none;
        }
        .ant-modal {
            width: 100%;
        }
        .ant-modal-wrap {
            position: relative!important;
            display: block;
        }
    }
`;
