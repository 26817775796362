import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { UseQueryResult } from 'react-query';
import { useMemo } from 'react';
import { IResultsListResponse, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { FilterSettingsType } from '@goparrot-dashboard/shared-utils';
import { LOCATIONS_FILTER, MENU_FILTER } from '../constants';
import { useListInitialFilters } from './useListInitialFilters';

export const useDefaultFilters = ({
  itemSetListQuery,
  itemSets,
  useExternalItemSets,
  excludeInitialFilters = false,
  excludeMenuFilter = false,
  hasRemainingItemSets = false,
}: {
  itemSetListQuery: UseQueryResult<IResultsListResponse<ReadItemSetDto>>;
  itemSets?: ReadItemSetDto[];
  useExternalItemSets?: boolean;
  excludeInitialFilters?: boolean;
  excludeMenuFilter?: boolean;
  hasRemainingItemSets?: boolean;
}): FilterSettingsType[] => {
  const { isMerchant, stores, franchiseLocations, isFranchise, isVirtualMenu } = useStoreService();
  const centralLocationStoreId = franchiseLocations?.find((location) => location.isCentralLocation)?.storeId;
  const centralLocationStore = franchiseLocations?.length && stores ? stores.find((store) => store.storeId === centralLocationStoreId) : null;
  const listInitialFilters = useListInitialFilters();
  const itemSetsData = useMemo(() => {
    if (useExternalItemSets) {
      return !hasRemainingItemSets ? itemSets : [];
    }
    return itemSetListQuery.isFetched && itemSetListQuery.data ? itemSetListQuery.data?.data : [];
  }, [hasRemainingItemSets, itemSetListQuery.data, itemSetListQuery.isFetched, itemSets, useExternalItemSets]);

  const filters = useMemo(
    () => [
      ...(excludeInitialFilters ? [] : listInitialFilters),
      ...(!excludeMenuFilter
        ? [
            {
              ...MENU_FILTER,
              items: itemSetsData.map((itemSet) => ({ value: itemSet.uniqueName, label: itemSet.title })),
            },
          ]
        : []),
      ...(isFranchise && !isVirtualMenu && isMerchant
        ? [
            {
              ...LOCATIONS_FILTER,
              items: stores && stores.length ? [...stores.map((location) => ({ value: location.storeId, label: location.title }))] : [],
              selectedItems: centralLocationStore ? [{ value: centralLocationStore.storeId, label: centralLocationStore.title }] : [],
            },
          ]
        : []),
    ],
    [centralLocationStore, excludeInitialFilters, excludeMenuFilter, isFranchise, isMerchant, isVirtualMenu, itemSetsData, listInitialFilters, stores],
  );
  return filters;
};
