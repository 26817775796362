import React, { FC } from 'react';
import classNames from 'classnames';

export type TextBadgeProps = {
  backgroundClass?: string;
  textClass?: string;
  style?: React.CSSProperties;
  className?: string;
};

export const TextBadge: FC<React.PropsWithChildren<TextBadgeProps>> = ({
  backgroundClass = ' tw-bg-orange-500',
  textClass = 'tw-text-white',
  className = '',
  children,
}) => (
  <div className={classNames(['tw-text-sm tw-font-normal tw-leading-4 tw-rounded-sm tw-py-px tw-px-1 tw-inline-block', backgroundClass, className, textClass])}>
    {children}
  </div>
);
