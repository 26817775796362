import type { UpdateRelationsResponseDto, IStoreItemBundleProperty } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

type UpdateMerchantComboGroupPropertiesParams = UpdateConnectionMutationBaseParams<IStoreItemBundleProperty>;

export const useUpdateMerchantComboGroupProperties = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, UpdateMerchantComboGroupPropertiesParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, UpdateMerchantComboGroupPropertiesParams> => {
  return useMutation(({ merchantId, uniqueName, payload }) => merchantStoreItemBundleFacade.updateProperties(merchantId, uniqueName, payload), options);
};
