import { MarketRow, MarketToggle } from '@market/react-bindings';
import React from 'react';
import { PanelTitle, Spinner, useNotifications } from '@goparrot-dashboard/shared-ui';
import { useDialogState } from '@goparrot-dashboard/shared-utils';
import { useI18n } from '@goparrot-dashboard/i18n';
import { ORCHESTRATION_QUERIES_ENUM, useGetGiftSettlementConfig } from '@goparrot-dashboard-api/orchestration';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ReadSettlementConfigurationDto, SettlementConfigurationStatusEnum } from '@goparrot/giftcard-orchestrator-sdk';
import { useQueryClient } from 'react-query';
import { ActionModal } from './components';
import { getEncodedCheckingAccountUrl, isSettlementActive } from './utils';
import { useHandleRedirectFromSquare, useSettlementConfigMutation, useGetModalConfig } from './hooks';

const setupAccountUrl = getEncodedCheckingAccountUrl();

export const GiftCardFundPooling: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { merchant } = useStoreService();
  const { notifySuccess } = useNotifications();
  const queryClient = useQueryClient();
  const { getIntlString } = useI18n();
  const dialog = useDialogState();
  const { show, visible, dismiss } = dialog;
  const { data: settlement, isLoading: isLoadingConfig } = useGetGiftSettlementConfig(merchant.merchantId);
  const {
    isSQCheckingAccountEnabled = false,
    settlementConfiguration,
  }: { settlementConfiguration?: ReadSettlementConfigurationDto; isSQCheckingAccountEnabled?: boolean } = settlement ?? {};

  const handleSuccessAdjustment = (config: ReadSettlementConfigurationDto) => {
    queryClient.setQueryData([ORCHESTRATION_QUERIES_ENUM.GIFT_CARD_SETTLEMENT_CONFIG, merchant.merchantId], {
      isSQCheckingAccountEnabled,
      settlementConfiguration: config,
    });
    const statusStr = getIntlString(isSettlementActive(config.status) ? 'variant.enabled' : 'variant.disabled', { isRootPath: true });
    notifySuccess({
      text: getIntlString('giftCards.pooling.notification.update.success', {
        values: {
          status: statusStr.toLowerCase(),
        },
      }),
    });
  };
  const { settlementConfigMutation, mutationIsLoading } = useSettlementConfigMutation({
    onSuccess: handleSuccessAdjustment,
    hasSettlementConfiguration: !!settlementConfiguration,
  });

  useHandleRedirectFromSquare({ status: settlementConfiguration?.status, callback: show, isLoading: isLoadingConfig });

  const handleSetupAccount = () => {
    window.location.assign(setupAccountUrl);
  };

  const handleTogglePooling = async () => {
    await settlementConfigMutation.mutateAsync({
      merchantId: merchant.merchantId,
      data: {
        status: isSettlementActive(settlementConfiguration?.status) ? SettlementConfigurationStatusEnum.INACTIVE : SettlementConfigurationStatusEnum.ACTIVE,
      },
    });
    dismiss();
  };

  const getModalConfig = useGetModalConfig({
    isLoading: mutationIsLoading,
    status: settlementConfiguration?.status,
    isSQCheckingAccountEnabled,
    onClick: isSQCheckingAccountEnabled ? handleTogglePooling : handleSetupAccount,
  });

  return (
    <Spinner isMarketComponent spinning={isLoadingConfig}>
      <section className="tw-mt-8">
        <PanelTitle hasMargin={false}>{getIntlString('giftCards.pooling.section')}</PanelTitle>
        <MarketRow
          role="option"
          onClick={show}
          selected={isSQCheckingAccountEnabled && !!settlementConfiguration?.status && isSettlementActive(settlementConfiguration.status)}
        >
          <label slot="label">{getIntlString('giftCards.pooling.label')}</label>
          <p slot="subtext">{getIntlString('giftCards.pooling.subtext')}</p>
          <MarketToggle slot="control" />
        </MarketRow>
        {visible && <ActionModal dialog={dialog} {...getModalConfig()} />}
      </section>
    </Spinner>
  );
};
