import React from 'react';
import { DialogStateReturn, InModal } from '@goparrot-dashboard/shared-ui';
import { MarketActivityIndicator, MarketButton, MarketButtonGroup, MarketDialog, MarketFooter } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';

export type Props = {
  dialog: DialogStateReturn;
  title: string;
  text: string;
  actionConfig: {
    onClick(): void;
    text: string;
    variant?: 'regular' | 'destructive';
    isLoading: boolean;
  };
  showLoading?: boolean;
};

export const ActionModal: React.FC<React.PropsWithChildren<Props>> = ({ dialog, title, text, actionConfig, showLoading }) => {
  const { dismiss, dialogRef } = dialog;
  const { text: buttonText, variant, onClick, isLoading } = actionConfig;

  return (
    <InModal>
      <MarketDialog onMarketDialogDismissed={dismiss} ref={dialogRef}>
        {showLoading ? (
          <div className="tw-text-center">
            <MarketActivityIndicator size="large" data-testid="activity-indicator" />
          </div>
        ) : (
          <>
            <main role="dialog">
              <h2>{title}</h2>
              <p className="paragraph-30">{text}</p>
            </main>
            <MarketFooter>
              <MarketButtonGroup alignment="fill">
                <MarketButton rank="primary" variant={variant} onClick={onClick} isLoading={isLoading || undefined}>
                  {buttonText}
                </MarketButton>
                <MarketButton rank="secondary" onClick={dismiss} disabled={isLoading || undefined}>
                  {getIntlString('button.cancel')}
                </MarketButton>
              </MarketButtonGroup>
            </MarketFooter>
          </>
        )}
      </MarketDialog>
    </InModal>
  );
};
