import { CreateStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantCreateStoreItemGroupParams = {
  merchantId: string;
  data: CreateStoreItemGroupDto;
};

export const useMerchantCreateStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantCreateStoreItemGroupParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantCreateStoreItemGroupParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantStoreItemGroupFacade.create(merchantId, data), options);
};
