import { Button, IconConnections, IconMoreHorizontal, Menu } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import React from 'react';
import { MenuProps as RMenuProps } from 'reakit/Menu';

type Props = {
  id: string;
  menuProps: Partial<RMenuProps>;
  disabledDuplicate: boolean;
  onConnectionsPreview?(): void;
  onEdit(): void;
  onDuplicate?(): void;
  onDelete(): void;
  isArchiveButtonHidden: boolean;
  isItemArchived: boolean;
};

export const MoreActions: React.FC<React.PropsWithChildren<Props>> = ({
  onConnectionsPreview,
  onEdit,
  onDelete,
  id,
  disabledDuplicate,
  isArchiveButtonHidden,
  onDuplicate,
  isItemArchived,
  menuProps,
}) => {
  return (
    <div className="tw-flex tw-space-x-1 tw-justify-end">
      {onConnectionsPreview ? (
        <Button color="secondary" kind="icon" onClick={onConnectionsPreview}>
          <IconConnections />
        </Button>
      ) : null}
      <Menu
        className="tw-w-32"
        menuProps={menuProps}
        disclosure={
          <Button kind="icon" color="clean" rounded="full">
            <IconMoreHorizontal />
          </Button>
        }
        menuItems={[
          <Menu.MenuItem onClick={onEdit} key={`${id}-edit`}>
            {getIntlString('menu.list.table.settings.edit')}
          </Menu.MenuItem>,
          ...(disabledDuplicate && onDuplicate
            ? []
            : [
                <Menu.MenuItem onClick={onDuplicate} key={`${id}-duplicate`}>
                  {getIntlString('menu.list.table.settings.duplicate')}
                </Menu.MenuItem>,
              ]),
          ...(!isArchiveButtonHidden
            ? [
                <Menu.MenuItem onClick={onDelete} key={'delete'} textColor={`${isItemArchived ? 'tw-text-light-blue-400' : 'tw-text-red-600'}`}>
                  {getIntlString(`${isItemArchived ? 'button.restore' : 'button.delete'}`, { isRootPath: true })}
                </Menu.MenuItem>,
              ]
            : []),
        ]}
      />
    </div>
  );
};
