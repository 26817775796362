import { assign } from 'lodash/fp';

import c from './actions';

const initialState = {
  initialFetchMade: false, // we need this field to differentiate between the the fetch call not having started and no locations existing.
  data: [],
  fetching: false,
  errors: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API__LOCATION__GET__REQUEST:
      return assign(initialState, {
        fetching: true,
        initialFetchMade: true,
      });

    case c.API__LOCATION__CREATE__REQUEST:
    case c.API__LOCATION__UPDATE__REQUEST:
    case c.API__LOCATION__DELETE__REQUEST:
      return assign(state, { fetching: true });

    case c.API__LOCATION__GET__SUCCESS:
      return assign(initialState, {
        data: action.payload,
        fetching: false,
        initialFetchMade: true,
      });

    case c.API__LOCATION__CREATE__SUCCESS:
      return assign(initialState, {
        data: state.data.concat(action.payload),
        initialFetchMade: state.initialFetchMade,
      });

    case c.API__LOCATION__UPDATE__SUCCESS:
      return assign(initialState, {
        data: state.data.map((item) => {
          if (item.storeId === action.payload.storeId) {
            return action.payload;
          }
          return item;
        }),
        initialFetchMade: state.initialFetchMade,
      });

    case c.API__LOCATION__DELETE__SUCCESS:
      return assign(initialState, {
        data: state.data.filter((item) => item.storeId !== action.payload),
        initialFetchMade: state.initialFetchMade,
      });

    case c.API__LOCATION__CREATE__ERROR:
    case c.API__LOCATION__UPDATE__ERROR:
    case c.API__LOCATION__DELETE__ERROR:
      return assign(state, { fetching: false });

    case c.API__LOCATION__GET__ERROR:
      return { ...initialState, initialFetchMade: state.initialFetchMade };

    default:
      return state;
  }
}
