import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IStoreFilterParams, IPagination, ItemListResponseDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '@goparrot-dashboard-api/shared';
import { locationItemFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<ItemListResponseDto> & {
  merchantId: string;
  storeId: string;
  pagination?: IPagination;
  params?: IStoreFilterParams;
};

export const useLocationStoreCombinedItemList = (
  { merchantId, storeId, pagination, params }: QueryParams,
  options?: UseQueryOptions<ItemListResponseDto>,
): UseQueryResult<ItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.LOCATION_STORE_ITEM_LIST, merchantId, storeId, pagination, params],
    async () => await locationItemFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
