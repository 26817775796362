import { Pagination } from 'antd';

import WithDirection from 'styles/config/withDirection';

import AntPagination from './styles/pagination.style';

const Paginations = AntPagination(Pagination);
const isoPagination = WithDirection(Paginations);

export default isoPagination;
