import { useCallback, useState } from 'react';

import { SetBooleanFieldValue, MethodWithPathOrField, UseDirtyFormResult } from '../../types';

import { useBooleanFormState } from './useFormBooleanState';

export function useFormDirty<T>(): UseDirtyFormResult<T> {
  const [isDirtyForm, setIsDirtyForm] = useState<boolean>(false);
  const [dirtyState, setDirtyState, getDirtyFieldValue, setDirtyFieldValue] = useBooleanFormState<T>();

  const setDirtyField: SetBooleanFieldValue<T> = useCallback((path, dirtyState = true) => setDirtyFieldValue(path, dirtyState), [setDirtyFieldValue]);

  const isDirtyField: MethodWithPathOrField<T, boolean> = useCallback(
    (path) => {
      if (isDirtyForm) {
        return true;
      }
      return getDirtyFieldValue(path);
    },
    [getDirtyFieldValue, isDirtyForm],
  );

  return {
    isDirtyForm,
    dirtyFieldsState: dirtyState,
    setIsDirtyForm,
    setDirtyField,
    isDirtyField,
    setDirtyState,
  };
}
