import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { ReadLocationDto, EditablePropertiesEnum } from '@goparrot/menu-orchestrator-sdk';
import { ExplicitFranchiseLocationOptions, FranchisePermissions, StoreWithLocation } from '../types';
import { franchisePermissionCheck } from './franchisePermissionCheck';

export const getStoreFranchiseLocation = <T extends { storeId: string } = ReadLocationDto>(store: ReadStoreDto, locations: T[]): T | undefined =>
  locations.find((location) => location.storeId === store.storeId);

export const assignStoresToLocations = <T extends { storeId: string } = ReadLocationDto>(stores: ReadStoreDto[], locations: T[]): StoreWithLocation<T>[] =>
  stores.map((store) => ({ ...store, franchiseLocation: getStoreFranchiseLocation<T>(store, locations) }));

const itemExists = (arr: EditablePropertiesEnum[] = [], item: EditablePropertiesEnum) => arr.includes(item);

export const locationConfigToExplicitFormat = (location: ReadLocationDto): ExplicitFranchiseLocationOptions => {
  const result: ExplicitFranchiseLocationOptions = {
    menu: true,
    menuSpecific: location.canCreateNewItems ?? false,
    menuReplica: location.canEditMenu ?? false,
    menuConnections: location.canEditConnections ?? false,
    menuUpdateLabels:
      itemExists(location.editableProperties, EditablePropertiesEnum.TITLE) || itemExists(location.editableProperties, EditablePropertiesEnum.SUBTITLE),
    menuUpdatePrice: itemExists(location.editableProperties, EditablePropertiesEnum.PRICE),
    menuUpdateSku: itemExists(location.editableProperties, EditablePropertiesEnum.SKU),
    menuUpdateExternalImageUrl: itemExists(location.editableProperties, EditablePropertiesEnum.EXTERNAL_IMAGE_URL),
    menuUpdateArchived: itemExists(location.editableProperties, EditablePropertiesEnum.IS_ARCHIVED),
    menuUpdateAvailability: itemExists(location.editableProperties, EditablePropertiesEnum.IS_AVAILABLE),
    menuUpdateOrderable: itemExists(location.editableProperties, EditablePropertiesEnum.IS_ORDERABLE),
    isCentralLocation: !!location.isCentralLocation,
  };
  return result;
};

export const menuManagementToLocationConfig = (options?: ExplicitFranchiseLocationOptions | null): Partial<ReadLocationDto> => {
  const result: Partial<ReadLocationDto> = {
    canCreateNewItems: options?.menuSpecific,
    canEditMenu: options?.menuReplica,
  };
  if (options?.menuReplica) {
    result.canEditConnections = options.menuConnections;
    result.editableProperties = [
      ...(options.menuUpdateLabels ? [EditablePropertiesEnum.TITLE, EditablePropertiesEnum.SUBTITLE] : [null]),
      options.menuUpdatePrice ? EditablePropertiesEnum.PRICE : null,
      options.menuUpdateSku ? EditablePropertiesEnum.SKU : null,
      options.menuUpdateExternalImageUrl ? EditablePropertiesEnum.EXTERNAL_IMAGE_URL : null,
      options.menuUpdateArchived ? EditablePropertiesEnum.IS_ARCHIVED : null,
      options.menuUpdateAvailability ? EditablePropertiesEnum.IS_AVAILABLE : null,
      options.menuUpdateOrderable ? EditablePropertiesEnum.IS_ORDERABLE : null,
    ].filter((i): i is EditablePropertiesEnum => i !== null);
  } else {
    result.editableProperties = [];
    result.canEditConnections = false;
  }
  return result;
};

export const getCentralLocation = (locations?: ReadLocationDto[]): ReadLocationDto | undefined => locations?.find((location) => location.isCentralLocation);

export const getStoreFranchiseConfig = (locations?: ReadLocationDto[], storeId?: string): ReadLocationDto | null => {
  const { editableProperties, canCreateNewItems, canEditMenu, canEditConnections }: ReadLocationDto | Record<string, any> = getCentralLocation(locations) || {};
  const currentLocation: ReadLocationDto | undefined = locations?.find((location) => location.storeId === storeId);
  return currentLocation
    ? {
        ...currentLocation,
        // if not defined, these fields are inherited from the central location
        editableProperties: currentLocation.editableProperties ?? editableProperties,
        canCreateNewItems: currentLocation.canCreateNewItems ?? canCreateNewItems,
        canEditMenu: currentLocation.canEditMenu ?? canEditMenu,
        canEditConnections: currentLocation.canEditConnections ?? canEditConnections,
      }
    : null;
};

export const getFranchisePermissions: (location: ReadLocationDto) => FranchisePermissions | null = (location) => {
  if (location) {
    const config = locationConfigToExplicitFormat(location);
    return Object.keys(franchisePermissionCheck).reduce<FranchisePermissions>(
      (acc, key) => ({
        ...acc,
        [key]: config ? !!franchisePermissionCheck[key](config) : true,
      }),
      {},
    );
  }
  return null;
};
