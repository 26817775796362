import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';

import { userFacade } from '../facades';

type DeleteUserParams = {
  uuid: string;
};

export const useDeleteUser = (options?: UseMutationOptions<void, unknown, DeleteUserParams>): UseMutationResult<void, unknown, DeleteUserParams> => {
  return useMutation(({ uuid }) => userFacade.delete(uuid), options);
};
