import { TokenUtility } from '@goparrot-dashboard-api/shared';
import { push } from 'connected-react-router';
import Cookies from 'js-cookie';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { getMerchantLevelRedirectUrl } from '@goparrot-dashboard/app';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { openURL } from '@goparrot-dashboard/shared-utils';
import aFranchise from 'store/franchise/actions';
import aLocationDefault from 'store/location-default/actions';
import aLocation from 'store/location/actions';
import { handleGet as getMerchant } from 'store/merchant/saga';
import aMerchant from 'store/merchant/actions';
import { getLocation } from 'store/router/selector';
import aUser from 'store/user/actions';
import { getByUUID, getRoleV2 } from 'store/user/api';
import a from './actions';
import * as api from './api';

function* handleLoginSuccess(payload, data) {
  const { uuid, token } = data;
  TokenUtility.set(token, payload.remember);

  const user = yield call(getByUUID(uuid));
  let roleV2;

  if (user.merchantId && user.roleV2Uuid) {
    roleV2 = yield call(getRoleV2({ merchantId: user.merchantId, roleV2Uuid: user.roleV2Uuid }));
    yield put(aUser.successRoleV2(roleV2));
  }

  yield put(a.successLogin(user));
  yield sessionStorage.setItem('merchantId', user.merchantId);

  const location = yield select(getLocation);

  if (user.merchantId) {
    yield put(aMerchant.requestGet()); // need to be aware about merchant fetching status
    const merchant = yield call(getMerchant);

    // fetch the franchise locations only if the merchant is a franchise, in order to add the central location tag in store selector
    if (merchant && MultiEntityTypeEnum.FRANCHISE === merchant.featuresFlags?.multiEntityType) {
      yield put(aFranchise.requestLocationsListGet({ merchantId: user.merchantId }));
    }

    if (!user.stores) {
      yield put(aLocation.requestGet());
    } else {
      yield put(aLocation.requestGet({ stores: user.stores }));
    }

    yield all([put(aLocationDefault.requestGet()), put(aUser.requestListByMerchant())]);
  }

  const redirectCookie = Cookies.get('x-redirect-url');
  if (redirectCookie) {
    Cookies.remove('x-redirect-url');
    return openURL(`${window.location.origin}/${redirectCookie}?wiki_token=${token}`, '_self');
  }

  if (location.pathname?.startsWith('/auth') || location.pathname === '/' || !user.merchantId) {
    if (user.merchantId && roleV2) {
      const merchantRedirectUrl = getMerchantLevelRedirectUrl(user.merchantId, roleV2);
      yield put(push(merchantRedirectUrl ?? '/dashboard'));
    } else {
      yield put(push('/dashboard'));
    }
  } else {
    yield put(push(`${location.pathname}${location.search ?? ''}`));
  }
}
function* handleLogin({ payload }) {
  try {
    const result = yield call(api.login(payload.data));
    yield handleLoginSuccess(payload, result);
  } catch (e) {
    yield put(a.errorLogin({ status: e.response?.status }));
  }
}
function* handleLoginByToken() {
  try {
    const tokenObj = TokenUtility.get();
    if (null === tokenObj) {
      throw Error();
    } else {
      const result = yield call(api.loginByToken());
      yield handleLoginSuccess(tokenObj, result);
    }
  } catch (e) {
    TokenUtility.clear();
    yield put(push('/auth'));
    yield put(a.errorLoginByToken());
  }
}

function* handleLogout() {
  TokenUtility.clear();
  yield put(a.clearState());
  yield put(push('/auth'));
}

function* handleAfterSuccessLogin({ payload }) {
  yield handleLoginSuccess({ remember: true }, payload);
}

export default function* watcherAuth() {
  yield takeEvery(a.API__AUTH__LOGIN__REQUEST, handleLogin);
  yield takeEvery(a.API__AUTH__LOGIN_BY_TOKEN__REQUEST, handleLoginByToken);
  yield takeEvery(a.LOCAL__AUTH__LOGOUT, handleLogout);
  yield takeEvery(a.API__AUTH__AFTER_SUCCESS_LOGIN, handleAfterSuccessLogin);
}
