import { IPagination, IMerchantFilterParams, ItemSetListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemSetFacade } from '../../facades';

type ItemSetListParams = { merchantId: string; pagination?: IPagination; params?: IMerchantFilterParams };

export const useItemSetList = (
  { merchantId, pagination, params }: ItemSetListParams,
  options?: UseQueryOptions<ItemSetListResponseDto>,
): UseQueryResult<ItemSetListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_SETS_LIST, merchantId, pagination, params],
    async () => await merchantItemSetFacade.list(merchantId, pagination, params),
    options,
  );
};
