import { PatchStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdateStoreItemGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchStoreItemGroupDto;
};
export type MerchantUpdateStoreItemGroupResult = UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantUpdateStoreItemGroupParams>;

export const useMerchantUpdateStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantUpdateStoreItemGroupParams>,
): MerchantUpdateStoreItemGroupResult => {
  return useMutation(({ merchantId, uniqueName, data }) => merchantStoreItemGroupFacade.patch(merchantId, uniqueName, data), options);
};
