import * as React from 'react';
import classNames from 'classnames';
import { ToggleVerticalProps } from './interface';
import { ItemVerticalToggle } from './ItemVerticalToggle';

type ItemDisplaySettingsWrapperProps = {
  items: (ToggleVerticalProps | React.ReactElement)[];
};

export const ItemDisplaySettingsWrapper: React.FC<React.PropsWithChildren<ItemDisplaySettingsWrapperProps>> = ({ items, children }) => {
  return (
    <div>
      <div
        className={classNames([
          'tw-flex-1 tw-flex tw-items-start tw-px-4 tw-py-6 tw-border tw-border-blue-gray-200',
          children ? 'tw-rounded-t tw-border-b-0' : 'tw-rounded',
        ])}
      >
        {items.map((item, index) => {
          if (React.isValidElement(item)) {
            return <React.Fragment key={String(index)}>{item}</React.Fragment>;
          }
          const typedItem = item as ToggleVerticalProps;
          return (
            <ItemVerticalToggle
              key={typedItem.title}
              isCritical={typedItem.isCritical}
              disabled={typedItem.disabled}
              title={typedItem.title}
              field={typedItem.field}
              tooltipTitle={typedItem.tooltipTitle}
            />
          );
        })}
      </div>
      {children}
    </div>
  );
};
