import type { ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { ComboGroupCreateMutationParams } from '../../../interface';

export const useCreateMerchantComboGroup = (
  options?: UseMutationOptions<ReadStoreItemBundleDto, unknown, ComboGroupCreateMutationParams>,
): UseMutationResult<ReadStoreItemBundleDto, unknown, ComboGroupCreateMutationParams> => {
  return useMutation(({ merchantId, data }) => merchantStoreItemBundleFacade.create(merchantId, data), options);
};
