import { ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type LocationStoreItemGetGroupsParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationStoreItemGroupsGet = (
  { merchantId, storeId, uniqueName }: LocationStoreItemGetGroupsParams,
  options?: UseQueryOptions<ReadStoreItemGroupDto[]>,
): UseQueryResult<ReadStoreItemGroupDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GROUPS_GET, merchantId, storeId, uniqueName],
    async () => await locationStoreItemFacade.getStoreItemGroups(merchantId, storeId, uniqueName),
    options,
  );
};
