import { IPagination, IResultsListResponse, IStoreFilterParams, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { locationItemBaseFacade } from '../../../facades';

type ItemBaseListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IStoreFilterParams };

export const useLocationsItemBaseList = (
  { merchantId, storeId, pagination, params }: ItemBaseListParams,
  options?: UseQueryOptions<IResultsListResponse<ReadItemBaseDto>>,
): UseQueryResult<IResultsListResponse<ReadItemBaseDto>> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_LIST, merchantId, storeId, pagination, params],
    async () => await locationItemBaseFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
