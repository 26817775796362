import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, InputNumber } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../../utils';
import { Item } from '../../../interface';
import { useMenuFormContext } from '../../../context';
import { getMenuFormIntlString, preventMinusKey } from '../utils';
import { getDefaultTaxValue, onChangeTaxValue } from './helpers';

export const ItemTax = <T extends { tax: number }>(): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const inputProps = bindInputProps('tax_percentage');
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditLabels
    : franchisePermissions?.canEditLabels;

  const [taxVal, setTaxVal] = React.useState<number | null>(() => getDefaultTaxValue(inputProps.value));
  const onTaxChange = (value) => onChangeTaxValue(value, inputProps, setTaxVal);

  return (
    <FormField
      {...bindFormFieldProps('tax_percentage')}
      withValuePadding={false}
      label={getMenuFormIntlString('tax-percentage')}
      disabled={isItemReplica ? !hasFranchisePermission : inputProps.disabled}
      input={
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full tw-px-4">
          <InputNumber
            {...inputProps}
            value={taxVal}
            aria-label="tax-input"
            onChange={onTaxChange}
            disabled={isItemReplica ? !hasFranchisePermission : inputProps.disabled}
            name="tax"
            className="tw-pr-2"
            onKeyDown={preventMinusKey}
          />
          <span className="tw-leading-none">%</span>
        </div>
      }
    />
  );
};
