import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import type { PatchMerchantDto, ReadMerchantDto } from '@goparrot/store-v2-sdk';
import { merchantFacade } from '../facades';

type UpdateMerchantParams = {
  merchantId: string;
  data: PatchMerchantDto;
};

export const useUpdateMerchant = (
  options?: UseMutationOptions<ReadMerchantDto, unknown, UpdateMerchantParams, unknown>,
): UseMutationResult<ReadMerchantDto, unknown, UpdateMerchantParams, unknown> => {
  return useMutation(({ merchantId, data }) => merchantFacade.patch(merchantId, data), options);
};
