import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IPagination, IStoreFilterParams, StoreItemBundleListResponseDto } from '@goparrot/storeitems-sdk';
import { locationStoreItemBundleFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  storeId: string;
  pagination?: IPagination;
  params?: IStoreFilterParams;
};

export const useLocationComboGroupListQuery = (
  { merchantId, storeId, pagination, params }: GetStoreItemsParams,
  options?: UseQueryOptions<StoreItemBundleListResponseDto, Error>,
): UseQueryResult<StoreItemBundleListResponseDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_COMBO_GROUP_LIST, merchantId, storeId, pagination, params],
    () => locationStoreItemBundleFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
