import { takeEvery, put, call, select } from 'redux-saga/effects';

import a from './actions';
import * as api from './api';
import { getMerchantId, getStores } from './selector';

function* handleUpdate({ payload }) {
  try {
    const user = yield call(api.update(payload.uuid, payload.data));
    yield put(a.successUpdate(user));
  } catch (e) {
    yield put(a.errorUpdate({ message: e.response.data.message }));
  }
}

function* handleListByMerchant() {
  try {
    const params = {
      merchantId: yield select(getMerchantId),
      stores: yield select(getStores),
    };
    const users = yield call(api.list(params));
    yield put(a.successListByMerchant(users));
  } catch (e) {
    yield put(a.errorListByMerchant());
  }
}

function* handleGetRoleV2({ payload }) {
  try {
    const roleV2 = yield call(api.getRoleV2(payload));
    yield put(a.successRoleV2(roleV2));
  } catch (e) {
    yield put(a.errorRoleV2(e.response.data));
  }
}

export default function* watcherUser() {
  yield takeEvery(a.API__USER__UPDATE__REQUEST, handleUpdate);
  yield takeEvery(a.API__USER__LIST_BY_MERCHANT__REQUEST, handleListByMerchant);
  yield takeEvery(a.API__USER__GET__ROLE__V2__REQUEST, handleGetRoleV2);
}
