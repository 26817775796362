import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import type { ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  uniqueName: string;
};

export const useMerchantGetComboGroupQuery = (
  { merchantId, uniqueName }: GetStoreItemsParams,
  options?: UseQueryOptions<ReadStoreItemBundleDto, Error>,
): UseQueryResult<ReadStoreItemBundleDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_COMBO_GROUP_GET, merchantId, uniqueName],
    () => merchantStoreItemBundleFacade.get(merchantId, uniqueName),
    options,
  );
};
