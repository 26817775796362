import React from 'react';
import { FormField, Input, InputNumber } from '@goparrot-dashboard/shared-ui';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { useMenuFormContext } from '../../context';
import { InputProps, ReturnValue } from './interface';
import { getMenuFormIntlString, preventMinusKey } from './utils';

type ShowOrderFieldType = 'showOrder' | 'metadata.show_order';

type Props = InputProps & {
  field?: ShowOrderFieldType;
};

type ItemEntity =
  | {
      showOrder: number;
    }
  | {
      metadata: {
        show_order?: number;
      };
    };

export const ItemShowOrder = <T extends ItemEntity>({ field = 'showOrder', label, placeholder }: Props): ReturnValue => {
  const { form } = useMenuFormContext<T>();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);

  return (
    <FormField
      {...bindFormFieldProps(field)}
      label={label || getMenuFormIntlString('showOrder')}
      input={
        <InputNumber
          {...bindInputProps(field)}
          name="number of items in row"
          customInput={Input}
          decimalScale={0}
          allowNegative={false}
          placeholder={placeholder || getMenuFormIntlString('showOrder.placeholder')}
          onKeyDown={preventMinusKey}
        />
      }
    />
  );
};
