import { select } from 'redux-saga/effects';
import { MerchantFacade } from '@goparrot/store-v2-sdk';

import { getMerchantId } from 'store/user/selector';
import { getConfig, getInterceptors } from 'helpers/request';

const facade = new MerchantFacade(getConfig('api/v2/store-v2'), getInterceptors());

export const get = () =>
  function* () {
    return yield facade.get(yield select(getMerchantId));
  };
export const create = (params) =>
  function* () {
    return yield facade.create(params);
  };
export const update = (params) =>
  function* () {
    return yield facade.patch(yield select(getMerchantId), params);
  };
