import { Item } from '../interface';

export const isArchived = (item: Item, isMerchantLevel: boolean, storeId: string): boolean => {
  const isArchived = item.isArchived;

  if (isMerchantLevel || isArchived) {
    return isArchived;
  }

  const mod = item.mods?.find((m) => m.storeId === storeId);

  return !!mod?.isArchived;
};
