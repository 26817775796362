import React, { createContext, useContext } from 'react';
import { IntlMessageType, IntlNumberType, IntlStringType } from './interface';

export type I18nPageContextType = {
  getIntlString: IntlStringType;
  getIntlMessage: IntlMessageType;
  getIntlNumber: IntlNumberType;
};

export const I18nPageContext = createContext<I18nPageContextType>({
  getIntlString: () => '',
  getIntlMessage: () => <span />,
  getIntlNumber: () => '',
});

export const useI18n: () => I18nPageContextType = () => {
  const context = useContext(I18nPageContext);

  if (context === undefined) {
    throw new Error('useI18n must be used within a I18nService');
  }

  return context;
};
