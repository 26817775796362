import React, { FC, useState } from 'react';

type GlobalAppProviderProps = {
  isSidebarVisibleOnMobile: boolean;
  setIsSidebarVisibleOnMobile: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarVisible: boolean;
  setIsSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isNavSelectWrapperVisible: boolean;
  setIsNavSelectWrapperVisible: React.Dispatch<React.SetStateAction<boolean>>;
  canFranchiseeLocationsImport: boolean;
  setCanFranchiseeLocationsImport: React.Dispatch<React.SetStateAction<boolean>>;
  isImportFranchiseeAuthorizationCodeClaimed: boolean;
  setIsImportFranchiseeAuthorizationCodeClaimed: React.Dispatch<React.SetStateAction<boolean>>;
};

const _dummyContext: GlobalAppProviderProps = {
  isSidebarVisibleOnMobile: false,
  setIsSidebarVisibleOnMobile: () => undefined,
  isSidebarVisible: false,
  setIsSidebarVisible: () => undefined,
  isNavSelectWrapperVisible: false,
  setIsNavSelectWrapperVisible: () => undefined,
  canFranchiseeLocationsImport: false,
  setCanFranchiseeLocationsImport: () => undefined,
  isImportFranchiseeAuthorizationCodeClaimed: false,
  setIsImportFranchiseeAuthorizationCodeClaimed: () => undefined,
};

export const GlobalAppContext = React.createContext<GlobalAppProviderProps | undefined>(undefined);

export const GlobalAppProvider: FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = ({ children }) => {
  const [isSidebarVisibleOnMobile, setIsSidebarVisibleOnMobile] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isNavSelectWrapperVisible, setIsNavSelectWrapperVisible] = useState(true);
  // canFranchiseeLocationsImport need to allow websocket to work on create merchant name because websocket are working
  // by features flags and when creating mechant name we can't change features flags
  const [canFranchiseeLocationsImport, setCanFranchiseeLocationsImport] = useState(false);
  // on connect page from onboarding module if user refresh the page we get error from backend that
  // Authorization code is already claimed and in this situation on Location module need to show error message to the user
  const [isImportFranchiseeAuthorizationCodeClaimed, setIsImportFranchiseeAuthorizationCodeClaimed] = useState(false);

  return (
    <GlobalAppContext.Provider
      value={{
        isSidebarVisibleOnMobile,
        isSidebarVisible,
        setIsSidebarVisible,
        setIsSidebarVisibleOnMobile,
        isNavSelectWrapperVisible,
        setIsNavSelectWrapperVisible,
        canFranchiseeLocationsImport,
        setCanFranchiseeLocationsImport,
        isImportFranchiseeAuthorizationCodeClaimed,
        setIsImportFranchiseeAuthorizationCodeClaimed,
      }}
    >
      {children}
    </GlobalAppContext.Provider>
  );
};

export const useGlobalAppContext = (): GlobalAppProviderProps => {
  const context = React.useContext(GlobalAppContext);

  if (undefined === context) {
    console.error('useGlobalAppContext must be used within a GlobalAppContext.');
    // in case we are using this hook in an unauthorized component
    return _dummyContext;
  }
  return context;
};
