import { PatchItemBaseDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseFacade } from '../../../facades';

export type MerchantUpdateItemBaseParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchItemBaseDto;
};

export const useMerchantUpdateItemBase = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, MerchantUpdateItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, MerchantUpdateItemBaseParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseFacade.patch(merchantId, uniqueName, data), options);
};
