import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { IMerchantFilterParams, IStoreFilterParams } from '@goparrot/storeitems-sdk';

const VIRTUAL_MENU_MERCHANT_FILTERS = { displayStoreSpecific: false, isGroupedList: true };
const VIRTUAL_MENU_STORE_FILTERS = { skipMerchantLevel: true };

export const useVirtualMenuFilters = (useMerchantParams: boolean): IStoreFilterParams | IMerchantFilterParams => {
  const { isVirtualMenu } = useStoreService();

  if (!isVirtualMenu) {
    return;
  }

  return useMerchantParams ? VIRTUAL_MENU_MERCHANT_FILTERS : VIRTUAL_MENU_STORE_FILTERS;
};
