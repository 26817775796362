import { UpdateItemOptionSelectionsRequestDto, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

export type MerchantItemOptionsUpdateItemBasesParams = {
  merchantId: string;
  uniqueName: string;
  data: UpdateItemOptionSelectionsRequestDto;
};

export const useMerchantItemOptionsUpdateItemBases = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, MerchantItemOptionsUpdateItemBasesParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, MerchantItemOptionsUpdateItemBasesParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.updateItemBases(merchantId, uniqueName, data), options);
};
