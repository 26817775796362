import { PAGINATION_TAKE } from '@goparrot-dashboard/shared-utils';
import { IResultsListResponse } from '@goparrot/storeitems-sdk';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { UseQueryResult } from 'react-query';

export const useMultipleRequests = <T>({
  query,
  setSkip,
  take = PAGINATION_TAKE,
  enabled,
}: {
  query: UseQueryResult<IResultsListResponse<T>>;
  setSkip: Dispatch<SetStateAction<number>>;
  take?: number;
  enabled?: boolean;
}): {
  items: T[];
  setItems: Dispatch<SetStateAction<T[]>>;
  hasRemainingItems: boolean;
  resetRequestState: () => void;
} => {
  // Ref to track ongoing requests
  const isFetchingRef = useRef(false);
  const location = useLocation();

  const [items, setItems] = useState<T[]>([]);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [remainingItems, setRemainingItems] = useState<number>(take);
  const isFetchingDisabled = useMemo(() => enabled !== undefined && !enabled, [enabled]);
  const hasRemainingItems: boolean = isFetchingDisabled ? false : remainingItems > 0;

  const handleFetchMoreItems = useCallback(async () => {
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;
    try {
      const { data } = await query.refetch();
      if (data) {
        // if there is no data - stop fetching, otherwise add new data to the list
        if (!data.data?.length) {
          setRemainingItems(0);
        } else {
          setItems((prev) => [...prev, ...data.data]);
        }

        if (data.pagination) {
          setTotalItems(data.pagination.total);
          // If totalItems is null, it means that we don't know the total number of items is, so we are initializing the number of remaining items
          if (totalItems === null) {
            setRemainingItems(data.pagination.total - take);
          } else {
            setRemainingItems((prev) => prev - take);
          }
        }
        setSkip((prev) => prev + take);
      } else {
        // if there is no data - stop fetching
        setRemainingItems(0);
      }
    } finally {
      isFetchingRef.current = false;
    }
  }, [query, setSkip, take, totalItems]);

  useEffect(() => {
    if (remainingItems > 0 && !isFetchingRef.current && !isFetchingDisabled) {
      handleFetchMoreItems();
    }
  }, [handleFetchMoreItems, remainingItems, isFetchingDisabled]);

  const resetRequestState = () => {
    setSkip(0);
    setItems([]);
    setTotalItems(null);
    setRemainingItems(take);
  };

  // this use Effect is needed for when user click on store level
  // the requestState need to be clear in order to be able to fetch new data
  useEffect(() => {
    return () => {
      if (location.pathname) {
        resetRequestState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { items, setItems, hasRemainingItems, resetRequestState };
};
