import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import type { IReadCategoryDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { GetRelativesQueryBaseParams } from '../../../interface';

export const useGetMerchantComboGroupChildrenQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<[], Error>,
): UseQueryResult<IReadCategoryDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_COMBO_GROUP_GET_CHILDREN, merchantId, uniqueName],
    () => merchantStoreItemBundleFacade.getChildren(merchantId, uniqueName),
    options,
  );
};
