import { assign } from 'lodash/fp';
import a from '../actions';

const initialState = {
  data: {},
  fetching: false,
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__USER__GET__ROLE__V2__REQUEST:
      return assign(state, { fetching: true });
    case a.API__USER__GET__ROLE__V2__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.LOCAL__USER__CLEAR:
      return initialState;

    default:
      return state;
  }
}
