import { useCallback, useEffect, useState } from 'react';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { GiftCardOrchestratorEventEnum, IGiftCardOrchestratorResponse } from '@goparrot/websocket-sdk';
import cloneDeep from 'lodash/cloneDeep';
import { LocationSyncStatusEnum, ReadLocationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { giftCardMerchantLocationFacade } from '../api';
import { assignStoresToLocations } from '../utils';
import { StoreWithLocation } from '../types';

type ReturnType = {
  locations: StoreWithLocation<ReadLocationDto>[];
  fetchLocations(): void;
  updateLocationStatus(eventType: GiftCardOrchestratorEventEnum, status: IGiftCardOrchestratorResponse): void;
};

export const useGiftCardLocations = (merchantId: string, stores: ReadStoreDto[]): ReturnType => {
  const [locations, setLocations] = useState<StoreWithLocation<ReadLocationDto>[]>([]);
  const updateLocationStatus = useCallback((eventType: GiftCardOrchestratorEventEnum, status: IGiftCardOrchestratorResponse): void => {
    setLocations((prev) => {
      const hasSyncStatus = prev.some((s) => s.franchiseLocation?.syncStatus);

      if (!hasSyncStatus) return prev;

      const cloned = cloneDeep(prev);
      return cloned.map((store) => {
        // 1st condition is needed to handle case when WS event comes before locations refetch
        // on add location action
        if (store.franchiseLocation?.syncStatus && status.storeIds.includes(store.storeId)) {
          if (eventType === GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT) {
            store.franchiseLocation.syncStatus.import = (status.state as unknown) as LocationSyncStatusEnum;
          } else {
            store.franchiseLocation.syncStatus.replication = (status.state as unknown) as LocationSyncStatusEnum;
          }
        }

        return store;
      });
    });
  }, []);
  const { notifyError } = useNotifications();

  const fetchLocations = useCallback(async () => {
    try {
      const data = await giftCardMerchantLocationFacade.listLocations(merchantId);
      if (data.length) {
        setLocations(assignStoresToLocations<ReadLocationDto>(stores, data));
      }
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.location.fetch.error.message'),
      });
    }
  }, [merchantId, stores]);

  useEffect(() => {
    if (merchantId && stores?.length) {
      fetchLocations();
    }
  }, [merchantId, stores, fetchLocations]);

  return { locations, fetchLocations, updateLocationStatus };
};
