import * as React from 'react';
import { IReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useI18n } from '@goparrot-dashboard/i18n';
import { isMerchantLevel } from '../../utils';
import { useItemSetsListQuery } from '../../hooks';
import { ConnectionItem } from '../../interface';
import { ModalPreviewConnectionHeader, ModalPreviewConnectionsTable } from './AddConnectionsModal';

export const ItemSetPreviewConnections = ({ item }: { item: IReadStoreItemDto }) => {
  const isMerchant = isMerchantLevel<ConnectionItem>(item);
  const { getIntlString } = useI18n();
  const { merchantId, storeId: itemStoreId, itemSet } = item;
  const menuQuery = useItemSetsListQuery({ merchantId, storeId: itemStoreId, isMerchant });

  const filteredMenu = React.useMemo(() => {
    if (menuQuery.data) {
      return menuQuery.data.data.filter((m) => itemSet.includes(m.uniqueName));
    }
  }, [itemSet, menuQuery.data]);

  return (
    <>
      <ModalPreviewConnectionHeader
        title={
          <>
            <span>{getIntlString('menu.connections.modal.preview.connectedTo', { isRootPath: true })}</span>
            <span>{getIntlString('menu.connections.type.menu', { isRootPath: true })}</span>
          </>
        }
      >
        <ModalPreviewConnectionsTable data={filteredMenu || []} isLoadingTable={!!menuQuery.isLoading} />
      </ModalPreviewConnectionHeader>
    </>
  );
};
