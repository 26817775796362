import { useDispatch } from 'react-redux';
import type { ReadStoreDto } from '@goparrot/store-v2-sdk';
import aLocation from '../../../../modules/client/src/store/location/actions';

type UseAfterUpdateStoreResult = {
  (store: ReadStoreDto): void;
};

export const useAfterStoreUpdate = (): UseAfterUpdateStoreResult => {
  const dispatch = useDispatch();
  const onAfterUpdate = (storeData: ReadStoreDto) => {
    dispatch(aLocation.successUpdate(storeData));
  };

  return onAfterUpdate;
};
