import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Tooltip } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import copy from 'clipboard-copy';

import { intl } from '@goparrot-dashboard/i18n';

interface Props {
  position?: TooltipPlacement;
  delay?: number;
  children?: React.ReactNode;
  title?: React.ReactElement | string;
}

export interface CanCopyAndShowTooltip {
  copyAndShowTooltip(content: string): void;
}

export const CopyToClipboard = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({
    copyAndShowTooltip: (content: string) => {
      copy(content);
      setShowTooltip(true);
      // workaround if we use with reacKit tooltip the message not disappear
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    },
  }));

  const { position = 'left', delay = 2, title = intl.formatMessage({ id: 'image.link.copied' }), children }: Props = props;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleVisibleChange: (visible: boolean) => void = (visible: boolean) => {
    if (!visible) {
      setShowTooltip(false);
    }
  };

  return (
    <Tooltip
      title={title}
      placement={position}
      visible={showTooltip}
      onVisibleChange={handleVisibleChange}
      mouseLeaveDelay={delay}
      getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
    >
      {children}
    </Tooltip>
  );
});
