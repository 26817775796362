import * as React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MarketNotificationProvider } from '@goparrot-dashboard/shared-ui';
import { I18nProvider } from '@goparrot-dashboard/i18n';

import runBoot from 'store/boot';
import themes from 'styles/config/themes';
import { GlobalStyle } from 'styles/global.style';
import { store, history } from '../../store';
import { Router } from '../../router';
import { themeConfig } from '../../config';
import { ReactQueryContainer } from './ReactQueryContainer';

const renderDashApp = () => (
  <ThemeProvider theme={themes[themeConfig.theme]}>
    <MarketNotificationProvider />
    <ReactQueryContainer>
      <GlobalStyle />
      {/* @ts-ignore-next-line */}
      <Provider store={store}>
        <I18nProvider locale={store.getState().root.app.locale}>
          <Router history={history} />
        </I18nProvider>
      </Provider>
    </ReactQueryContainer>
  </ThemeProvider>
);
runBoot()
  .then(() => renderDashApp())
  .catch((error) => console.error(error));

export default renderDashApp;
