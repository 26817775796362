import React, { FC } from 'react';
import {
  ReadCategoryDto,
  ReadItemBaseGroupDto,
  ReadItemBaseDto as ItemBaseModel,
  ReadItemOptionsDto as ItemOptionsModel,
  ReadStoreItemDto as StoreItemModel,
  ReadStoreItemGroupDto,
} from '@goparrot/storeitems-sdk';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { availableAtDefaultDateAndTime } from '@goparrot-dashboard/shared-utils';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { Toggle, Tooltip } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { findMod, getFranchisePermissionForItem, hasFranchiseTag, isMerchantLevel } from '../utils';
import { IsAvailableAt } from './IsAvailableAt';

type OriginalType = ItemOptionsModel | ItemBaseModel | ReadCategoryDto | StoreItemModel | ReadStoreItemGroupDto | ReadItemBaseGroupDto;

type IsAvailableAtToggleProps = {
  original: OriginalType;
  value: boolean;
  franchisePermissions: FranchisePermissions | null;
  onUpdate(value: Partial<OriginalType>, item: OriginalType): void;
  isAvailableAtEnabled?: boolean;
  customDisableToggle?: boolean;
};

export const IsAvailableAtToggle: FC<React.PropsWithChildren<IsAvailableAtToggleProps>> = ({
  value,
  original,
  franchisePermissions,
  onUpdate,
  isAvailableAtEnabled = false,
  customDisableToggle = false,
}) => {
  const {
    isMerchant,
    selectedStoreId,
    isFranchise,
    franchiseLocations,
    timeZone,
    merchant: { featuresFlags },
  } = useStoreService();
  const { roleV2 } = useUserService();
  const { getIntlString } = useI18n();
  const isFranchiseEntityType = featuresFlags.multiEntityType === MultiEntityTypeEnum.FRANCHISE;
  const isModsMode = !isMerchant && isMerchantLevel(original);
  const isDisabledOnMerchant = isModsMode && !value;
  const isDisabledByFranchise =
    isFranchise &&
    hasFranchiseTag(original) &&
    !getFranchisePermissionForItem({
      item: original,
      key: 'canEditAvailability',
      isMerchant,
      franchiseLocations,
      franchisePermissions,
      userRole: roleV2,
    });
  const isDisabled = isFranchiseEntityType || isDisabledByFranchise || isDisabledOnMerchant || customDisableToggle;
  const checked = isModsMode ? findMod(selectedStoreId, original.mods)?.isAvailable : value;

  const isAvailableAt = isModsMode ? findMod(selectedStoreId, original.mods)?.isAvailableAt || original.isAvailableAt : original.isAvailableAt;

  return (
    <div className="tw-relative tw-h-4.5">
      <Tooltip tooltip={isDisabledOnMerchant ? getIntlString('page.merchant.menu.table.disabledOnMerchant.tooltip', { isRootPath: true }) : null}>
        <div>
          <Toggle
            checked={checked ?? value}
            isCritical={true}
            onChange={(isAvailable) => {
              const data = {
                isAvailable,

                ...(!isAvailable && isAvailableAtEnabled && { isAvailableAt: availableAtDefaultDateAndTime(timeZone) }),
              };

              onUpdate({ ...data }, original);
            }}
            disabled={isDisabled}
          />
        </div>
      </Tooltip>

      {isAvailableAtEnabled && !(checked ?? value) && (
        <IsAvailableAt
          centerMode={true}
          disabled={isDisabled}
          isAvailableAt={isAvailableAt}
          timeZone={timeZone}
          handleUpdate={(isAvailableAtNewVal: string) => {
            onUpdate({ isAvailableAt: isAvailableAtNewVal }, original);
          }}
        />
      )}
    </div>
  );
};
