import { ReadCategoryDto, ReadItemOptionsDto, ReadItemBaseGroupDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { getMenuFranchisePermissions, hasFranchiseTag } from './franchiseUtils';
import { isMerchantLevel } from './isMerchantLevel';

type Props = {
  original: ReadCategoryDto | ReadItemOptionsDto | ReadItemBaseDto | ReadItemBaseGroupDto;
  isMerchant: boolean;
  isFranchise: boolean;
  franchiseLocations: ReadLocationDto[];
  roleV2: IReadRoleV2Dto;
  franchisePermissions: FranchisePermissions | null;
  isItemArchived: boolean;
};

export const showArchiveButtonOnTable = ({
  original,
  isMerchant,
  isFranchise,
  franchiseLocations,
  roleV2,
  franchisePermissions,
  isItemArchived,
}: Props): boolean => {
  const isMerchantLevelItem = isMerchantLevel(original);
  const isModsMode = !isMerchant && isMerchantLevelItem;
  const isItemReplica = isFranchise && hasFranchiseTag(original);
  const isArchivedDisabled = isModsMode;
  const canUpdateArchived = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: original.storeId, franchiseLocations, userRole: roleV2 })?.canEditArchived
    : franchisePermissions?.canEditArchived;
  const isRestoreButtonDisabled = isItemReplica ? !canUpdateArchived : isArchivedDisabled || (!isMerchant && isMerchantLevelItem && original.isArchived);

  const archivedItemDisabled = isModsMode ? isMerchantLevelItem && original.isArchived : isRestoreButtonDisabled;
  const notArchivedItemDisabled = isModsMode ? false : isItemReplica ? !canUpdateArchived : isArchivedDisabled;
  const showArchiveButton = isItemArchived ? archivedItemDisabled : notArchivedItemDisabled;

  return showArchiveButton;
};
