import { PAGINATION_TAKE } from '@goparrot-dashboard/shared-utils';
import { ListLocationGroupsDto, ReadLocationGroupDto } from '@goparrot/location-group-sdk';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';

export const useLocationGroupsMultipleRequests = ({
  query,
  setSkip,
  take = PAGINATION_TAKE,
  enabled = true,
}: {
  query: UseQueryResult<ListLocationGroupsDto>;
  setSkip: Dispatch<SetStateAction<number>>;
  take?: number;
  enabled?: boolean;
}): {
  items: ReadLocationGroupDto[];
  setItems: Dispatch<SetStateAction<ReadLocationGroupDto[]>>;
  hasRemainingItems: boolean;
  resetRequestState: () => void;
} => {
  // Ref to track ongoing requests
  const isFetchingRef = useRef(false);

  const [items, setItems] = useState<ReadLocationGroupDto[]>([]);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const hasRemainingItems: boolean = useMemo(() => totalItems === null || items.length < totalItems, [items.length, totalItems]);

  const handleFetchMoreItems = useCallback(async () => {
    if (isFetchingRef.current || !enabled) return;
    isFetchingRef.current = true;
    try {
      const { data } = await query.refetch();
      if (data) {
        setItems((prev) => [...prev, ...data.rows]);
        if (data.pagination) {
          setTotalItems(data.pagination.total);
        }
        setSkip((prev) => prev + take);
      }
    } finally {
      isFetchingRef.current = false;
    }
  }, [enabled, query, setSkip, take]);

  useEffect(() => {
    if (hasRemainingItems && !isFetchingRef.current) {
      handleFetchMoreItems();
    }
  }, [handleFetchMoreItems, hasRemainingItems, enabled]);

  const resetRequestState = () => {
    setItems([]);
    setTotalItems(null);
    setSkip(0);
  };

  return { items, setItems, hasRemainingItems, resetRequestState };
};
