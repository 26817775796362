import { IsNotEmpty, IsString, IsLowercase, Length, Matches } from 'class-validator';

export class WebMerchantSlugModel {
  @IsNotEmpty()
  @IsString()
  @IsLowercase()
  @Length(1, 255)
  @Matches(/[a-z-]+/)
  slug: string;

  init(options: Partial<this> = {}): this {
    Object.assign(this, options);
    return this;
  }
}
