import { useInfiniteQuery } from 'react-query';
import { IStoreFilterParams, StoreItemComboListResponseDto } from '@goparrot/storeitems-sdk';
import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { locationStoreItemComboFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';
import { getNextPage } from '../../utils';

type QueryParams = ListQueryBaseParams<IStoreFilterParams> & {
  storeId: string;
  pageLimit: number;
};

export const useLocationComboListInfiniteQuery = (
  { merchantId, storeId, pageLimit, params }: QueryParams,
  options?: UseInfiniteQueryOptions<StoreItemComboListResponseDto>,
): UseInfiniteQueryResult<StoreItemComboListResponseDto> => {
  return useInfiniteQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.LOCATION_COMBOS_LIST_INFINITE, merchantId, storeId, params],
    ({ pageParam: offset = 0 }) => locationStoreItemComboFacade.list(merchantId, storeId, { skip: offset, take: pageLimit }, params),
    {
      getNextPageParam: (lastPage) => getNextPage(lastPage, pageLimit),
      ...options,
    },
  );
};
