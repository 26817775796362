import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { UseMutationResult } from 'react-query';
import {
  MerchantUpdateItemBaseParams,
  LocationUpdateItemBaseParams,
  useMerchantUpdateItemBase,
  useLocationUpdateItemBase,
} from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS } from '../constants';

type Props = {
  successCallback?: (result: ReadItemBaseDto) => void;
};

type Result = {
  merchantQuery: UseMutationResult<ReadItemBaseDto, unknown, MerchantUpdateItemBaseParams>;
  locationQuery: UseMutationResult<ReadItemBaseDto, unknown, LocationUpdateItemBaseParams>;
};

export const useUpdateItemBaseQuery = ({ successCallback }: Props): Result => {
  const { notifySuccess, notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.update.error'),
      });
    },
    onSuccess: (result) => {
      notifySuccess({
        text: getIntlString('menu.notifications.update.success', { itemType: MENU_ELEMENTS.ITEM_BASE }),
      });
      successCallback && successCallback(result);
    },
  };

  const locationQuery = useLocationUpdateItemBase(options);

  const merchantQuery = useMerchantUpdateItemBase(options);

  return { merchantQuery, locationQuery };
};
