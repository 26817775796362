import React, { FC, Fragment, ReactElement } from 'react';
import { ButtonOptions } from '@goparrot-dashboard/shared-ui';
import { MarketButton, MarketButtonGroup } from '@market/react-bindings';
import { ButtonRank, ButtonVariant } from '../../../shared-ui/src/components';

type ActionConfig = {
  title?: string | React.ReactNode;
  handler?: (e: React.BaseSyntheticEvent) => void;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  buttonOptions?: ButtonOptions;
  className?: string;
  icon?: React.ReactNode;
};
export type ActionButton = ActionConfig | ReactElement;

type Props = {
  actions?: ActionButton[];
};

export const Actions: FC<React.PropsWithChildren<Props>> = ({ actions = [] }) => (
  <MarketButtonGroup className="tw-flex tw-flex-row" slot="actions">
    {actions.map((action, index) => {
      if (React.isValidElement(action)) {
        return <Fragment key={String(index)}>{action}</Fragment>;
      }
      if (!action || (action as ActionConfig).hidden) return null;
      const typedAction = action as ActionConfig;
      const {
        buttonOptions: { rank = ButtonRank.SECONDARY, variant = ButtonVariant.REGULAR } = { rank: ButtonRank.SECONDARY, variant: ButtonVariant.REGULAR },
        className,
      } = typedAction;

      return (
        <MarketButton
          className={`${className} tw-ml-4`}
          key={`${typedAction.title}-${index}`}
          rank={rank}
          variant={variant}
          disabled={typedAction.disabled || undefined}
          onClick={typedAction.handler}
          isLoading={typedAction.loading || undefined}
        >
          {typedAction.icon}
          <span>{typedAction.title}</span>
        </MarketButton>
      );
    })}
  </MarketButtonGroup>
);
