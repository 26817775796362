import { getConfig, getInterceptors } from '@goparrot-dashboard-api/shared';
import { StoreByMerchantFacade, DefaultStoreByMerchantFacade, SaaSFacade, MerchantFacade } from '@goparrot/store-v2-sdk';
import { StoreFranchiseFacade } from '@goparrot/dashboard-gateway-sdk';

export const storeByMerchantFacade = new StoreByMerchantFacade(getConfig('api/v2/store-v2'), getInterceptors());
export const defaultStoreByMerchantFacade = new DefaultStoreByMerchantFacade(getConfig('api/v2/store-v2'), getInterceptors());

export const merchantFacade = new MerchantFacade(getConfig('api/v2/store-v2'), getInterceptors());

export const storeFranchiseFacade = new StoreFranchiseFacade(getConfig(), getInterceptors());

export const saasFacade = new SaaSFacade(getConfig(), getInterceptors());
