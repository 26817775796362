import { ICloneRequest, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantCloneStoreItemParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneStoreItem = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, MerchantCloneStoreItemParams>,
): UseMutationResult<ReadStoreItemDto, unknown, MerchantCloneStoreItemParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemFacade.clone(merchantId, uniqueName, data), options);
};
