const c = {
  API__AUTH__LOGIN__REQUEST: 'API__AUTH__LOGIN__REQUEST',
  API__AUTH__LOGIN__SUCCESS: 'API__AUTH__LOGIN__SUCCESS',
  API__AUTH__LOGIN__ERROR: 'API__AUTH__LOGIN__ERROR',

  API__AUTH__LOGIN_BY_TOKEN__REQUEST: 'API__AUTH__LOGIN_BY_TOKEN__REQUEST',
  API__AUTH__LOGIN_BY_TOKEN__ERROR: 'API__AUTH__LOGIN_BY_TOKEN__ERROR',

  API__AUTH__AFTER_SUCCESS_LOGIN: 'API__AUTH__AFTER_SUCCESS_LOGIN',

  LOCAL__AUTH__LOGOUT: 'LOCAL__AUTH__LOGOUT',
  LOCAL__AUTH__CLEAR_STATE: 'LOCAL__AUTH__CLEAR_STATE',
};
const a = {
  requestLogin: (data, remember) => ({ type: c.API__AUTH__LOGIN__REQUEST, payload: { data, remember } }),
  successLogin: (payload) => ({ type: c.API__AUTH__LOGIN__SUCCESS, payload }),
  errorLogin: (payload) => ({ type: c.API__AUTH__LOGIN__ERROR, payload }),

  requestLoginByToken: () => ({ type: c.API__AUTH__LOGIN_BY_TOKEN__REQUEST }),
  errorLoginByToken: () => ({ type: c.API__AUTH__LOGIN_BY_TOKEN__ERROR, payload: {} }),

  logout: () => ({ type: c.LOCAL__AUTH__LOGOUT }),
  clearState: () => ({ type: c.LOCAL__AUTH__CLEAR_STATE }),
  afterSuccessLogin: (payload) => ({ type: c.API__AUTH__AFTER_SUCCESS_LOGIN, payload }),
};
export default { ...a, ...c };
