import { datadogLogs } from '@datadog/browser-logs';

export const DD_LOGS = () => {
  return datadogLogs.init({
    clientToken: window._env_.DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'dashboard-v2-client',
    forwardErrorsToLogs: false,
    forwardConsoleLogs: ['info'],
    sampleRate: 100,
  });
};
