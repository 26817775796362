import { ICloneRequest, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantCloneStoreItemGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};
export type MerchantCloneStoreItemGroupResult = UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantCloneStoreItemGroupParams>;

export const useMerchantCloneStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantCloneStoreItemGroupParams>,
): MerchantCloneStoreItemGroupResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.clone(merchantId, uniqueName, data), options);
};
