import { assign } from 'lodash/fp';

import a from './actions';

const initialState = {
  fetching: false,
  authenticated: false,
  success: false,
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__AUTH__LOGIN__REQUEST:
    case a.API__AUTH__LOGIN_BY_TOKEN__REQUEST:
      return assign(initialState, { fetching: true });

    case a.API__AUTH__LOGIN__SUCCESS:
      return assign(initialState, { authenticated: true });

    case a.API__AUTH__LOGIN__ERROR:
    case a.API__AUTH__LOGIN_BY_TOKEN__ERROR:
      return assign(initialState, { error: action.payload });

    case a.LOCAL__AUTH__CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
}
