import * as React from 'react';
import classNames from 'classnames';
import { Checkbox } from 'antd';
import { CheckboxProps as AntCheckboxProps, CheckboxChangeEvent } from 'antd/es/checkbox';

import styles from './styles/checkbox.module.scss';

export type CheckboxProps = {
  onChange: (value: boolean, event?: CheckboxChangeEvent) => any | void;
  className?: string;
} & Omit<AntCheckboxProps, 'onChange'>;

const AntCheckbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({ className = '', onChange, ...props }) => (
  <Checkbox
    className={classNames({
      [styles.container]: true,
      [className]: !!className,
    })}
    {...props}
    onChange={(event: CheckboxChangeEvent) => onChange(event.target.checked, event)}
  />
);

const CheckboxGroup = Checkbox.Group;

export default AntCheckbox;

export { CheckboxGroup };
