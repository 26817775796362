import { ChildrenEntitiesDtoType } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemOptionsFacade } from '../../facades';

type MerchantItemOptionParentParams = { merchantId: string; uniqueName: string };

export const useMerchantItemOptionParent = (
  { merchantId, uniqueName }: MerchantItemOptionParentParams,
  options?: UseQueryOptions<ChildrenEntitiesDtoType[]>,
): UseQueryResult<ChildrenEntitiesDtoType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_OPTION_PARENTS_LIST, merchantId, uniqueName],
    async () => await merchantItemOptionsFacade.getParents(merchantId, uniqueName),
    options,
  );
};
