import { UseQueryResult } from 'react-query';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { ListQueryBaseParams, useLocationComboGroupListQuery, useMerchantComboGroupListQuery } from '@goparrot-dashboard-api/menu-management';
import { IMerchantFilterParams, IStoreFilterParams, StoreItemBundleListResponseDto } from '@goparrot/storeitems-sdk';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';

export type GetComboGroupListResult = UseQueryResult<StoreItemBundleListResponseDto, Error>;

type Params = ListQueryBaseParams<IStoreFilterParams | IMerchantFilterParams> & {
  merchantId: string;
  storeId: string;
  isMerchant: boolean;
  queryEnabled?: boolean;
};

export const useGetComboGroupList = ({ merchantId, storeId, isMerchant, params, pagination, queryEnabled = true }: Params): GetComboGroupListResult => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.COMBO_GROUP }),
      });
    },
  };
  const locationConceptsListQuery = useLocationComboGroupListQuery(
    { merchantId, storeId, params: params as IStoreFilterParams, pagination },
    {
      ...options,
      enabled: !isMerchant && queryEnabled,
    },
  );

  const merchantConceptsListQuery = useMerchantComboGroupListQuery(
    {
      merchantId,
      pagination,
      params: params as IMerchantFilterParams,
    },
    {
      ...options,
      enabled: isMerchant && queryEnabled,
    },
  );

  return isMerchant ? merchantConceptsListQuery : locationConceptsListQuery;
};
