import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { EntityFormResult, RecursivePartial } from '@goparrot-dashboard/entity-form';
import { IAbstractItem } from '@goparrot/storeitems-sdk';
import { DialogStateReturn } from 'reakit/Dialog';
import { UseQueryOptions } from 'react-query';
import { findModIndex, getArchivedStatus, getErrorArchiveOrRestoreMessage, getSuccessArchiveOrRestoreMessage } from '../../utils';
import { useMenuFormContext } from '../../context';
import { AbstractItemType } from './interface';

type ReturnType<T> = {
  updateOptions: UseQueryOptions<T>;
  updateModsOptions: UseQueryOptions<T>;
  isItemArchived: boolean;
};

export const useArchiveRequestOptions = <T, TUpdate extends AbstractItemType>(dialog: DialogStateReturn, model: T, itemType: string): ReturnType<TUpdate> => {
  const { isMerchant, form, selectedStoreId, updateQueryData } = useMenuFormContext<T>();
  const { notifyError, notifySuccess } = useNotifications();
  const isItemArchived = getArchivedStatus(form.state as IAbstractItem, selectedStoreId, isMerchant);

  const updateModsOptions = {
    onSuccess: (data: TUpdate) => {
      updateQueryData(data);
      const handleFormReset = resetForm<T>(form);
      notifySuccess({
        text: getSuccessArchiveOrRestoreMessage(isItemArchived, itemType),
      });

      if (form.isFormTouched) {
        const foundModIndex = findModIndex(selectedStoreId, (form.state as AbstractItemType).mods);
        form.setFieldValue(`mods[${foundModIndex}].isArchived`, !isItemArchived);
        return;
      }
      handleFormReset(data, model);
    },
    onError: () =>
      notifyError({
        text: getErrorArchiveOrRestoreMessage(isItemArchived),
      }),
    onSettled: () => {
      if (!isItemArchived) {
        dialog.hide();
      }
    },
  };

  const updateOptions = {
    onError: () => {
      notifyError({
        text: getErrorArchiveOrRestoreMessage(isItemArchived),
      });
    },
    onSuccess: (data: TUpdate) => {
      updateQueryData(data);
      notifySuccess({
        text: getSuccessArchiveOrRestoreMessage(!data.isArchived),
      });
      form.setFieldValue('isArchived', data.isArchived);
    },
    onSettled: () => {
      if (!isItemArchived) {
        dialog.hide();
      }
    },
  };
  return { updateOptions, updateModsOptions, isItemArchived };
};

function resetForm<T>(form: EntityFormResult<T>) {
  const { setState, setIsDirtyForm, setDirtyState, getTransformed } = form;

  return (data: RecursivePartial<T>, model: T) => {
    setState(() => getTransformed(data, model));
    setIsDirtyForm(false);
    setDirtyState({});
  };
}
