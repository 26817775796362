import { DomainRegistrationRequestDto, ReadDomainRegistrationDto } from '@goparrot/payment-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { domainRegistrationFacade } from '../facades';

type RegisterDomainParams = {
  storeId: string;
  merchantId: string;
  payload: DomainRegistrationRequestDto;
};
type RegisterDomainResult = ReadDomainRegistrationDto;

export const useRegisterPaymentDomain = (
  options?: UseMutationOptions<RegisterDomainResult, unknown, RegisterDomainParams>,
): UseMutationResult<RegisterDomainResult, unknown, RegisterDomainParams> => {
  return useMutation(({ storeId, merchantId, payload }) => domainRegistrationFacade.register(merchantId, storeId, payload), options);
};
