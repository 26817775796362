import styled from 'styled-components';
import { Switch } from 'antd';

const StyledToggle = styled(Switch)`
  height: 20px !important;
  ${(props) => (props.checkedChildren ? 'min-width: 50px !important' : '')}
  .ant-switch-inner {
    line-height: 18px !important;
    font-size: 10px !important;
  }
  &::after {
    top: 0px !important;
  }
  &:not(.ant-switch-checked)::after {
    left: 0px !important;
  }
  &:not(.ant-switch-checked) .ant-switch-inner {
    margin-left: 22px !important;
  }
  &.ant-switch-checked .ant-switch-inner {
    margin-left: 9px !important;
    margin-right: 23px !important;
  }
  &.ant-switch-checked::after {
    margin-left: 0px;
  }
`;
export default StyledToggle;
