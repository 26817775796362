import { IUpdateRelationsRequest } from '@goparrot/storeitems-sdk';

export const getChangedConnections = (oldItems: string[], newItems: string[]): IUpdateRelationsRequest => {
  const itemsToAdd = newItems.filter((newItem) => !oldItems.find((oldItem) => oldItem === newItem));
  const itemsToRemove = oldItems.filter((oldItem) => !newItems.find((newItem) => newItem === oldItem));

  return {
    itemsToAdd,
    itemsToRemove,
  };
};
