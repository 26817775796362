import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { locationItemBaseFacade } from '../../../facades';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useLocationItemBaseParents = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemOptionsDto[]>,
): UseQueryResult<ReadItemOptionsDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_GET_PARENTS, merchantId, storeId, uniqueName],
    async () => await locationItemBaseFacade.getParents(merchantId, storeId, uniqueName),
    options,
  );
};
