import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ItemListResponseDto, IPagination, IMerchantFilterParams } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '@goparrot-dashboard-api/shared';
import { merchantItemFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<ItemListResponseDto> & {
  merchantId: string;
  pagination?: IPagination;
  params?: IMerchantFilterParams;
};

export const useMerchantStoreCombinedItemList = (
  { merchantId, pagination, params }: QueryParams,
  options?: UseQueryOptions<ItemListResponseDto>,
): UseQueryResult<ItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.MERCHANT_STORE_ITEM_LIST, merchantId, pagination, params],
    async () => await merchantItemFacade.list(merchantId, pagination, params),
    options,
  );
};
