import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import type { ReadUserDto } from '@goparrot/users-v2-sdk';
import type { ReadMerchantDto } from '@goparrot/store-v2-sdk';

type Props = {
  user: ReadUserDto;
  merchant: Partial<ReadMerchantDto>;
  isMerchant: boolean;
};

const USER_CONTEXT = 'user';
const FRANCHISE_MERCHANT_CONTEXT = 'franchise-merchant';

// Initializes Launch Darkly client with user/merchant context
export function useLaunchDarkly({ user, merchant, isMerchant }: Props): void {
  const ldClient = useLDClient();

  const { roleV2Uuid, uuid, merchantId, type } = user;
  const { name } = merchant;

  useEffect(() => {
    const userContext = { kind: USER_CONTEXT, key: uuid, merchantId, role: roleV2Uuid, type };
    const merchantContext = { kind: FRANCHISE_MERCHANT_CONTEXT, key: merchantId, name };

    if (!ldClient || !uuid) {
      return;
    } else if (isMerchant && name) {
      ldClient.identify({
        kind: 'multi',
        user: userContext,
        merchant: merchantContext,
      });
    } else {
      ldClient.identify(userContext);
    }
  }, [isMerchant, ldClient, merchantId, name, roleV2Uuid, type, uuid]);
}
