import { IAbstractItem } from '@goparrot/storeitems-sdk';
import { findMod } from './findMod';

export const getArchivedStatus = <T extends IAbstractItem>(state: T, storeId: string | null, isMerchant: boolean): boolean => {
  if (isMerchant) {
    return state.isArchived;
  }
  const mod = findMod(storeId, state.mods);
  return mod?.isArchived ?? state.isArchived;
};
