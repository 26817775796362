import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadFranchiseStoreDto } from '@goparrot/store-v2-sdk';
import { ORCHESTRATION_QUERIES_ENUM } from '../enums';
import { franchiseStoreFacade } from '../facades';

export const useGetLocationExternalReferenceList = (
  merchantId: string,
  options?: UseQueryOptions<ReadFranchiseStoreDto[]>,
): UseQueryResult<ReadFranchiseStoreDto[]> => {
  return useQuery([ORCHESTRATION_QUERIES_ENUM.LOCATION_EXTERNAL_REFERENCE_LIST, merchantId], () => franchiseStoreFacade.list(merchantId), options);
};
