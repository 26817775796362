import React from 'react';
import { IconWarning } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';

export const InvalidSettingsInfoSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { getIntlString } = useI18n();

  return (
    <div className="tw-flex tw-text-red-500 tw-items-center tw-border tw-border-red-300 tw-p-4 tw-rounded">
      <div className="tw-mr-2 tw-pt-0.5">
        <IconWarning className="tw-min-w-max" />
      </div>
      <span>{getIntlString('invalid-settings-info')}</span>
    </div>
  );
};
