import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import type { ReadSettlementConfigurationDto, UpdateSettlementConfigurationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { giftCardSettlementConfigurationFacade } from '../facades';

export type UpdateGiftCardSettlementConfigParams = {
  merchantId: string;
  data: UpdateSettlementConfigurationDto;
};

export const useUpdateGiftCardSettlementConfig = (
  options?: UseMutationOptions<ReadSettlementConfigurationDto, unknown, UpdateGiftCardSettlementConfigParams, unknown>,
): UseMutationResult<ReadSettlementConfigurationDto, unknown, UpdateGiftCardSettlementConfigParams, unknown> => {
  return useMutation(({ merchantId, data }) => giftCardSettlementConfigurationFacade.update(merchantId, data), options);
};
