import { CreateMerchantDto as CreateMerchantModel } from '@goparrot/store-v2-sdk';

(CreateMerchantModel as any).prototype.init = function (options: Partial<CreateMerchantModel> = {}) {
  this.subtitle = options.subtitle;
  this.shortName = options.subtitle;
  this.title = options.title;
  this.name = options.title;
  return this;
};

export { CreateMerchantModel };
