import { IRewriteRelationsRequest, IItemBaseGroupMetadataSelection, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type MerchantUpdateSelectionsItemBaseGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IItemBaseGroupMetadataSelection>;
};
export const useMerchantUpdateSelectionsItemBaseGroup = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateSelectionsItemBaseGroupParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateSelectionsItemBaseGroupParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseGroupFacade.updateSelections(merchantId, uniqueName, data), options);
};
