import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ReadWebStoreDto } from '@goparrot/webstore-sdk';
import { JsonPatchOperationDto } from '@goparrot/common';
import { webStoreFacade } from '../facades';

type UpdateWebstoreParams = {
  storeId: string;
  data: JsonPatchOperationDto[];
};

export const useUpdateWebstore = (
  options?: UseMutationOptions<ReadWebStoreDto, unknown, UpdateWebstoreParams>,
): UseMutationResult<ReadWebStoreDto, unknown, UpdateWebstoreParams> => {
  return useMutation(({ storeId, data }) => webStoreFacade.patch(storeId, data), options);
};
