import { ISearchItemResult } from '@goparrot/menu-sync-sdk';

type Props = { data: ISearchItemResult[]; posGuidChanged: boolean; itemGuid: string };

export const hasChildren = ({ data, posGuidChanged, itemGuid }: Props): boolean => {
  if (posGuidChanged) {
    const foundItem = data.find((item) => itemGuid === item.guid);
    return Boolean(foundItem && foundItem.hasChildren);
  }
  return false;
};
