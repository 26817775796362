import React, { FC } from 'react';
import { MarketRow, MarketAccessory, MarketCheckbox, MarketList, MarketInputSearch } from '@market/react-bindings';
import { IconStore } from '@goparrot-dashboard/shared-ui';
import sortBy from 'lodash/sortBy';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import { IReadStoreDto, IReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { SelectOptionType, SelectValueType } from '@goparrot-dashboard/shared-ui/src/components/select/types';
import { MIN_LOCATION_FOR_SEARCH } from '../../constants';
import { getLocationOptions } from './utils';
import { ALL_OPTION } from './constants';

type MarketStoreSelectProps = {
  locations: (IReadStoreDto | IReadDefaultStoreDto)[];
  value: SelectValueType[] | undefined;
  onChange: (value: SelectValueType[] | null | string[]) => void;
  isFranchiseSelect?: boolean;
  squareVendorList?: ReadVendorDto[];
  posAuthorizedStores?: Record<string, ReadPaymentStoreDto>;
};

export const MarketStoreSelect: FC<React.PropsWithChildren<MarketStoreSelectProps>> = ({
  value,
  onChange,
  locations,
  isFranchiseSelect,
  squareVendorList,
  posAuthorizedStores,
}) => {
  const sortedLocations: (IReadStoreDto | IReadDefaultStoreDto)[] = React.useMemo(() => sortBy(locations, ['type']), [locations]);

  const handleSelectChange: (selectedLocations: string[]) => void = (selectedLocations: string[]) => {
    if (selectedLocations.includes(ALL_OPTION)) {
      return onChange(sortedLocations?.map(({ storeId }) => storeId) as SelectValueType[]);
    }
    return onChange(selectedLocations as SelectValueType[]);
  };

  const locationOptions = React.useMemo(
    () => getLocationOptions(Boolean(isFranchiseSelect), sortedLocations, squareVendorList, posAuthorizedStores as Record<string, ReadPaymentStoreDto>),
    [posAuthorizedStores, sortedLocations, squareVendorList, isFranchiseSelect],
  );

  const disableSelectAll = isFranchiseSelect && locationOptions.some((option) => !!(option as { disabled?: boolean }).disabled);

  const options: SelectOptionType[] = [
    {
      value: ALL_OPTION,
      label: ALL_OPTION,
      disabled: disableSelectAll,
    },
    ...locationOptions,
  ];

  return (
    <MarketList
      data-testid="assign-locations-modal-franchise-locations"
      filter-strategy="textcontent"
      interactive
      multiselect
      value={value}
      onMarketListSelectionsDidChange={(value) => handleSelectChange(value.detail.currentSelectionValues)}
    >
      {options.length >= MIN_LOCATION_FOR_SEARCH && <MarketInputSearch slot="search"></MarketInputSearch>}
      <MarketRow value="all" slot="control-row" disabled={options[0]?.disabled || undefined}>
        <label slot="label">{options[0]?.value}</label>
        <MarketCheckbox slot="control"></MarketCheckbox>
      </MarketRow>
      {options.length &&
        options.map((option, index) => {
          if (option.value === ALL_OPTION) {
            return null;
          }

          return (
            <MarketRow key={`${option.value}-group-${index}`} value={String(option.value)} disabled={option.disabled || undefined}>
              <label slot="label">{option.label}</label>
              <MarketAccessory size="icon" slot="leading-accessory">
                <IconStore width={20} height={16} />
              </MarketAccessory>

              <MarketCheckbox slot="control"></MarketCheckbox>
            </MarketRow>
          );
        })}
    </MarketList>
  );
};
