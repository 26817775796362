import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { paymentStoreFacade } from '../facades';

type DeletePaymentStoreParams = {
  storeId: string;
};
type DeletePaymentStoreResult = void;

export const useDeletePaymentStore = (
  options?: UseMutationOptions<DeletePaymentStoreResult, unknown, DeletePaymentStoreParams>,
): UseMutationResult<DeletePaymentStoreResult, unknown, DeletePaymentStoreParams> => {
  return useMutation(({ storeId }) => paymentStoreFacade.delete(storeId), options);
};
