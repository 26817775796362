import { IsEmail } from 'class-validator';
import { PasswordWithConfirmationModel } from './password-with-confirmation';

export class RegisterModel extends PasswordWithConfirmationModel {
  @IsEmail(
    {},
    {
      message: 'Email is not valid',
    },
  )
  email: string;

  init(options: Partial<this>): this {
    Object.assign(this, options);
    return this;
  }
}
