import * as React from 'react';

type Props = {
  title: string | React.ReactNode;
};

export const ModalPreviewConnectionHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, children }) => (
  <div className="tw-space-y-4">
    <div className="tw-flex tw-space-x-1 tw-font-bold tw-text-lg tw-text-blue-gray-900">{title}</div>
    {children}
  </div>
);
