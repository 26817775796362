import {
  axios as baseAxios,
  requestProvideTokenInterceptor as baseRequestProvideTokenInterceptor,
  getConfig as baseGetConfig,
  getInterceptors as baseGetInterceptors,
} from '@goparrot-dashboard-api/shared';

/**
 * @deprecated
 * @see `@goparrot-dashboard-api/shared` moddule instead
 */
const axios = baseAxios;
/**
 * @deprecated
 * @see `@goparrot-dashboard-api/shared module` instead
 */
const requestProvideTokenInterceptor = baseRequestProvideTokenInterceptor;
/**
 * @deprecated
 * @see `@goparrot-dashboard-api/shared module` instead
 */
const getConfig = baseGetConfig;
/**
 * @deprecated
 * @see `@goparrot-dashboard-api/shared module` instead
 */
const getInterceptors = baseGetInterceptors;

export { requestProvideTokenInterceptor, getConfig, getInterceptors };
export default axios;
