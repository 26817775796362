import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: [],
  configurations: [],
  error: false,
  fetching: false,
  isChangedGuid: false,
  canSaveAndSync: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__CATEGORY__GET__SYNC__CONFIG__REQUEST:
    case a.API__VENDOR__SYNC__ITEM__FROM__POS__REQUEST:
    case a.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__REQUEST:
      return assign(state, { fetching: true, error: false });

    case a.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__SUCCESS:
      return assign(state, {
        data: action.payload,
        fetching: false,
      });

    case a.API__VENDOR__SYNC__ITEM__FROM__POS__SUCCESS:
    case a.API__VENDOR__SYNC__ITEM__FROM__POS__ERROR:
    case a.API__CATEGORY__PATCH__SYNC__CONFIG__ERROR:
    case a.API__CATEGORY__GET__SYNC__CONFIG__ERROR:
      return assign(state, { fetching: false });

    case a.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__ERROR:
      return assign(state, { error: true, fetching: false });

    case a.POS__GUID__IS__CHANGED:
      return assign(state, { isChangedGuid: action.payload });

    case a.CAN__SAVE__AND__SYNC:
      return assign(state, { canSaveAndSync: action.payload });

    case a.API__CATEGORY__PATCH__SYNC__CONFIG__SUCCESS:
    case a.API__CATEGORY__GET__SYNC__CONFIG__SUCCESS:
      return assign(state, {
        configurations: action.payload,
        fetching: false,
      });

    default:
      return state;
  }
}
