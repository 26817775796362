import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { locationFacade } from '../api/location';
import { StoreWithLocation } from '../types';

export const useFranchiseStoreWithLocation = (
  merchantId?: string,
  storeId?: string,
  stores?: ReadStoreDto[],
): {
  location: StoreWithLocation | null;
  error: AxiosError | null;
  refetch(): void;
  loading: boolean;
} => {
  const [location, setLocation] = useState<StoreWithLocation | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchLocation = useCallback(async () => {
    if (!merchantId || !storeId) {
      return;
    }

    setLoading(true);
    try {
      const data = await locationFacade.get(merchantId, storeId);

      const foundStore = stores?.find((store) => store.storeId === data.storeId);

      if (foundStore) {
        setLocation({
          ...foundStore,
          franchiseLocation: data,
        });
      }
    } catch (e) {
      setError(e as AxiosError);
    } finally {
      setLoading(false);
    }
  }, [merchantId, storeId, stores]);
  useEffect(() => {
    if (storeId && merchantId && stores?.length) {
      fetchLocation();
    }
  }, [storeId, merchantId, stores, fetchLocation]);

  return { location, error, refetch: fetchLocation, loading };
};
