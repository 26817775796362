import { ICloneRequest, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type MerchantCloneItemBaseGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneItemBaseGroup = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, MerchantCloneItemBaseGroupParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, MerchantCloneItemBaseGroupParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseGroupFacade.clone(merchantId, uniqueName, data), options);
};
