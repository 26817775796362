export const removeUndefinedObjectProperties = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((acc: any, current: string) => {
    if (obj[current] === Object(obj[current])) {
      acc[current] = removeUndefinedNestedObjectProperties(obj[current]);
    } else if (obj[current] !== undefined) {
      return { ...acc, [current]: obj[current] };
    }
    return acc;
  }, {});
};

export const removeUndefinedNestedObjectProperties = (obj: Record<string, any>): Record<string, any> => {
  const newObj: any = {};
  Object.keys(obj).forEach((key: string) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeUndefinedNestedObjectProperties(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const clearUndefinedFields = <T>(object: T): T => JSON.parse(JSON.stringify(object));
