import React from 'react';
import { PermissionsV2 } from '@goparrot/users-v2-sdk';
import { MultiEntityTypeEnum } from '@goparrot/common';
import {
  IconReports,
  IconLocation,
  IconEmployees,
  IconOrders,
  IconCustomers,
  IconCohorts,
  IconPromotions,
  IconLoyalty,
  IconMerchant,
  IconGeneralSettings,
  IconMenuManagement,
  IconDiningOptions,
  IconDeliverySettings,
  IconPickupSettings,
  IconBusinessHours,
  IconPayment,
  IconNotifications,
  IconServiceCharges,
  IconFranchiseManagement,
  IconFranchiseReport,
  IconCampaigns,
  IconGiftCard,
  IconHours,
  FeatureSetup,
  IconHome,
  IconPosSettings,
  IconPrintTags,
  IconOrdersThrottling,
  IconLocationGroup,
  IconDiagram,
} from '@goparrot-dashboard/shared-ui';
import { MenuList } from './interface';

const PromoDefaultsPermissions = [PermissionsV2.PROMOTION.API_READ, PermissionsV2.CUSTOMER.API_READ, PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_READ];
export const TRANSLATION_MENU_MANAGEMENT = 'sidebar.merchant.menu.management';
export const MENU_MANAGEMENT_MATCH_URL = '/dashboard/menu-management/:id?/:itemType?/:itemStoreId?/:uniqueName?/:storeItemType?/:variationId?';

export const MENU_ITEMS: MenuList = {
  MERCHANT: [
    {
      categoryName: 'Merchant Level',
      items: [
        {
          label: 'sidebar.merchant.home',
          exact: true,
          link: '/dashboard',
          icon: <IconHome />,
          permissions: [PermissionsV2.MERCHANT.HOME_PAGE_VIEW],
        },
      ],
    },
    {
      categoryName: 'General Settings',
      items: [
        {
          label: 'sidebar.merchant.merchant',
          link: '/dashboard/merchant',
          icon: <IconMerchant />,
          permissions: [[PermissionsV2.MERCHANT.SETTINGS_PAGE_VIEW, PermissionsV2.MERCHANT.API_READ]],
        },
        {
          label: 'sidebar.merchant.features.setup',
          link: '/dashboard/features-setup',
          icon: <FeatureSetup />,
          permissions: [[PermissionsV2.MERCHANT.FEATURES_SETUP_PAGE_VIEW, PermissionsV2.MERCHANT.API_UPDATE]],
        },
        {
          label: 'sidebar.merchant.locations',
          link: '/dashboard/locations',
          links: ['/dashboard/locations', '/dashboard/locations/new', '/dashboard/locations/edit/:id'],
          icon: <IconLocation />,
          permissions: [[PermissionsV2.MERCHANT.LOCATIONS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
        },
        {
          label: 'sidebar.merchant.location.group',
          link: '/dashboard/location-group',
          links: ['/dashboard/location-group', '/dashboard/location-group/new', '/dashboard/location-group/:id/edit'],
          icon: <IconLocationGroup />,
          featuresFlagsCheck: (flags) => Boolean(flags.isLocationGroupsEnabled),
          permissions: [[PermissionsV2.MERCHANT.LOCATIONS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
        },
        {
          label: 'sidebar.merchant.users',
          link: '/dashboard/users',
          links: ['/dashboard/users', '/dashboard/users/new', '/dashboard/users/:id/edit'],
          icon: <IconEmployees />,
          permissions: [[PermissionsV2.MERCHANT.TEAMS_PAGE_VIEW, PermissionsV2.USER_V2.API_READ]],
        },
      ],
    },
    {
      categoryName: 'Franchise Management',
      items: [
        {
          label: 'sidebar.settings',
          link: '/dashboard/franchise',
          links: ['/dashboard/franchise', '/dashboard/franchise/location/:storeId'],
          icon: <IconFranchiseManagement />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FRANCHISE === flags.multiEntityType,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_READ,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_CREATE,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_UPDATE,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_DELETE,
          ],
        },
        {
          label: 'sidebar.franchiseManagement.menu.settings',
          link: '/dashboard/franchise-menu',
          links: ['/dashboard/franchise-menu', '/dashboard/franchise-menu/location/:storeId'],
          icon: <IconDiagram />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FRANCHISE === flags.multiEntityType && flags.isMenuSettingsPageEnabled,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_READ,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_CREATE,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_UPDATE,
            PermissionsV2.FRANCHISE_MANAGEMENT.WEB_DELETE,
          ],
        },
      ],
    },
    {
      categoryName: 'Operations',
      items: [
        {
          label: TRANSLATION_MENU_MANAGEMENT,
          link: '/dashboard/menu-management/:id?/:itemType?/:itemStoreId?/:uniqueName?/:storeItemType?/:variationId?',
          icon: <IconMenuManagement />,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_CREATE,
            PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_READ,
            PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_UPDATE,
            PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_DELETE,
          ],
        },
        {
          label: 'sidebar.location.orders',
          link: '/dashboard/orders',
          icon: <IconOrders />,
          permissions: [[PermissionsV2.MERCHANT_ORDER.API_READ, PermissionsV2.MERCHANT_ORDER.API_UPDATE]],
        },
      ],
    },
    {
      categoryName: 'Reports',
      items: [
        {
          label: 'sidebar.merchant.reports',
          link: '/dashboard/reports/:reportName?',
          icon: <IconReports />,
          permissions: [PermissionsV2.MERCHANT.REPORTS_PAGE_VIEW],
        },
        {
          label: 'sidebar.merchant.franchise.reports',
          link: '/dashboard/franchise-reports/grafana-reports/:reportName?',
          icon: <IconFranchiseReport />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FRANCHISE === flags.multiEntityType,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.FRANCHISE_REPORTS.WEB_READ,
          ],
        },
      ],
    },
    {
      categoryName: 'Customers',
      items: [
        {
          label: 'sidebar.franchiseManagement.customers.settings',
          link: '/dashboard/sq-directory',
          icon: <IconCustomers />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FRANCHISE === flags.multiEntityType && !!flags.isFranchiseDirectoryEnabled,
          permissions: [[PermissionsV2.MERCHANT.CUSTOMER_PAGE_VIEW, PermissionsV2.CUSTOMER.API_READ]],
        },
      ],
    },
    {
      categoryName: 'CRM',
      items: [
        {
          label: 'sidebar.merchant.customers',
          link: '/dashboard/customers',
          links: ['/dashboard/customers', '/dashboard/customers/:id'],
          icon: <IconCustomers />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FRANCHISE !== flags.multiEntityType || !flags.isFranchiseDirectoryEnabled,
          permissions: [[PermissionsV2.MERCHANT.CUSTOMER_PAGE_VIEW, PermissionsV2.CUSTOMER.API_READ]],
        },
      ],
    },
    {
      categoryName: 'Payment',
      items: [
        {
          label: 'sidebar.merchant.giftCards',
          link: '/dashboard/gift-cards',
          icon: <IconGiftCard />,
          permissions: [PermissionsV2.GIFT_CARDS.API_READ],
          featuresFlagsCheck: ({ giftcardsPurchaseEnabled, isGiftCardImportEnabled }) => Boolean(giftcardsPurchaseEnabled || isGiftCardImportEnabled),
        },
      ],
    },
    {
      categoryName: 'Marketing',
      items: [
        {
          label: 'sidebar.merchant.promotions',
          link: '/dashboard/promotions',
          links: ['/dashboard/promotions', '/dashboard/promotions/add', '/dashboard/promotions/:uuid/edit'],
          icon: <IconPromotions />,
          permissions: [[...PromoDefaultsPermissions, PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW]],
        },
        {
          label: 'sidebar.merchant.loyalty',
          link: '/dashboard/loyalty-programs',
          links: ['/dashboard/loyalty-programs', '/dashboard/loyalty-programs/:programUuid/edit', '/dashboard/loyalty-programs/new'],
          icon: <IconLoyalty />,
          permissions: [[PermissionsV2.MERCHANT.LOYALTY_PAGE_VIEW, PermissionsV2.PROMOTION.API_READ, PermissionsV2.LOYALTY.API_READ]],
        },
        {
          label: 'sidebar.merchant.cohorts',
          link: '/dashboard/audiences',
          links: ['/dashboard/audiences', '/dashboard/audiences/new', '/dashboard/audiences/:id'],
          icon: <IconCohorts />,
          permissions: [PermissionsV2.COHORT.WEB_READ],
        },
        {
          label: 'sidebar.merchant.campaigns',
          link: '/dashboard/campaigns',
          links: ['/dashboard/campaigns', '/dashboard/campaigns/new', '/dashboard/campaigns/:id', '/dashboard/campaigns/:id/report'],
          icon: <IconCampaigns />,
          permissions: [PermissionsV2.CAMPAIGN.WEB_READ],
          featuresFlagsCheck: (flags) => Boolean(flags.isCampaignEnabled),
        },
        {
          label: 'sidebar.merchant.franchiseMarketing',
          link: '/dashboard/sq-campaigns',
          links: ['/dashboard/sq-campaigns'],
          icon: <IconCampaigns />,
          permissions: [PermissionsV2.CAMPAIGN.WEB_READ],
          featuresFlagsCheck: (flags) => Boolean(flags.isFranchiseMarketingEnabled),
        },
      ],
    },
    {
      categoryName: 'Multi Brand Management',
      items: [
        {
          label: 'sidebar.settings',
          link: '/dashboard/multi-brand',
          links: ['/dashboard/multi-brand', '/dashboard/multi-brand/location/:storeId'],
          icon: <IconFranchiseManagement />,
          featuresFlagsCheck: (flags) => MultiEntityTypeEnum.FOOD_HALL === flags.multiEntityType,
          permissions: [PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN, PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN],
        },
      ],
    },
  ],
  LOCATION: [
    {
      categoryName: 'Store Level',
      items: [
        {
          label: 'sidebar.merchant.home',
          exact: true,
          defaultStore: false,
          link: '/dashboard/:id/home',
          icon: <IconHome />,
          permissions: [PermissionsV2.STORE.HOME_PAGE_VIEW],
        },
      ],
    },
    {
      categoryName: 'General',
      items: [
        {
          label: 'sidebar.location.general',
          link: '/dashboard/:id/general/:tab?',
          defaultStore: true,
          icon: <IconGeneralSettings />,
          permissions: [[PermissionsV2.STORE.GENERAL_SETTINGS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
          exact: false,
        },
        {
          label: 'sidebar.location.pos',
          link: '/dashboard/:id/pos',
          defaultStore: false,
          icon: <IconPosSettings />,
          permissions: [[PermissionsV2.STORE.POS_SETTINGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ]],
        },
        {
          label: 'sidebar.merchant.users',
          link: '/dashboard/:id/users',
          defaultStore: false,
          icon: <IconEmployees />,
          permissions: [PermissionsV2.STORE.TEAMS_PAGE_VIEW],
        },
        {
          label: 'sidebar.merchant.print-tags',
          defaultStore: false,
          isSquareOrToastPOS: true,
          link: '/dashboard/:id/print-tags',
          icon: <IconPrintTags />,
          permissions: [[PermissionsV2.STORE.PRINT_TAGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ, PermissionsV2.STORE.API_READ]],
          featuresFlagsCheck: (flags) => Boolean(flags.showPrintTagManagement),
        },
      ],
    },
    {
      categoryName: 'Operations',
      items: [
        {
          label: 'sidebar.merchant.menu.management',
          defaultStore: false,
          link: MENU_MANAGEMENT_MATCH_URL,
          icon: <IconMenuManagement />,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_OPERATIONS,
            PermissionsV2.STORE_MENU_MANAGEMENT.WEB_CREATE,
            PermissionsV2.STORE_MENU_MANAGEMENT.WEB_READ,
            PermissionsV2.STORE_MENU_MANAGEMENT.WEB_UPDATE,
            PermissionsV2.STORE_MENU_MANAGEMENT.WEB_DELETE,
          ],
        },
        {
          label: 'sidebar.location.orders',
          link: '/dashboard/:id/orders',
          defaultStore: false,
          icon: <IconOrders />,
          permissions: [[PermissionsV2.STORE_ORDER.API_READ, PermissionsV2.STORE_ORDER.API_UPDATE]],
        },
        {
          label: 'sidebar.location.orders-throttling',
          link: '/dashboard/:id/orders-throttling',
          defaultStore: false,
          icon: <IconOrdersThrottling />,
          permissions: [
            [
              PermissionsV2.STORE_ORDER.THROTTLING_PAGE_VIEW,
              PermissionsV2.STORE_ORDER_CONFIG.API_READ,
              PermissionsV2.STORE_ORDER_CONFIG.API_CREATE,
              PermissionsV2.STORE_ORDER_CONFIG.API_UPDATE,
            ],
          ],
          featuresFlagsCheck: (flags) => Boolean(flags.isOrderThrottlingEnabled),
        },
      ],
    },
    {
      categoryName: 'Online Sales Reports',
      items: [
        {
          label: 'sidebar.location.reports',
          link: '/dashboard/:id/reports/:reportName?',
          icon: <IconReports />,
          defaultStore: false,
          permissions: [PermissionsV2.STORE_REPORTS.PAGE_VIEW],
        },
      ],
    },
    {
      categoryName: 'Settings',
      items: [
        {
          label: 'sidebar.location.dining-options',
          defaultStore: false,
          link: '/dashboard/:id/dining-options',
          icon: <IconDiningOptions />,
          permissions: [[PermissionsV2.STORE_DINING_OPTIONS.API_READ, PermissionsV2.STORE.API_READ]],
        },
        {
          label: 'sidebar.location.prep-time',
          link: '/dashboard/:id/order-config/:tab?',
          icon: <IconHours />,
          defaultStore: false,
          permissions: [
            [
              PermissionsV2.STORE.PREPARATION_TIME_PAGE_VIEW,
              PermissionsV2.VENDOR.API_READ,
              PermissionsV2.STORE.API_READ,
              PermissionsV2.STORE_ORDER_CONFIG.API_READ,
            ],
          ],
        },
        {
          label: 'sidebar.location.delivery',
          link: '/dashboard/:id/delivery/settings/:tab?',
          icon: <IconDeliverySettings />,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
          ],
          exact: false,
        },
        {
          label: 'sidebar.location.pickup',
          link: '/dashboard/:id/pickup',
          icon: <IconPickupSettings />,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
          ],
        },
        {
          label: 'sidebar.location.business-hours',
          link: '/dashboard/:id/business-hours',
          icon: <IconBusinessHours />,
          permissions: [[PermissionsV2.STORE.BUSINESS_HOURS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
        },
        {
          label: 'sidebar.location.notifications',
          link: '/dashboard/:id/notifications',
          defaultStore: false,
          icon: <IconNotifications />,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
          ],
        },
      ],
    },
    {
      categoryName: 'Payment',
      items: [
        {
          label: 'sidebar.location.payment',
          link: '/dashboard/:id/payment',
          icon: <IconPayment />,
          defaultStore: false,
          exact: false,
          permissions: [
            PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN,
            PermissionsV2.ROLE_PERMISSION.STORE_ADMIN,
            PermissionsV2.STORE_PAYMENT.WEB_CREATE,
            PermissionsV2.STORE_PAYMENT.WEB_READ,
            PermissionsV2.STORE_PAYMENT.WEB_UPDATE,
            PermissionsV2.STORE_PAYMENT.WEB_DELETE,
          ],
        },
        {
          label: 'sidebar.location.service-charges',
          link: '/dashboard/:id/service-charges',
          defaultStore: false,
          icon: <IconServiceCharges />,
          permissions: [
            [
              PermissionsV2.STORE_SERVICE_CHARGES.API_READ,
              PermissionsV2.VENDOR.API_READ,
              PermissionsV2.STORE.API_READ,
              PermissionsV2.STORE_MENU_MANAGEMENT.API_READ,
            ],
          ],
        },
      ],
    },
    {
      categoryName: 'Marketing',
      items: [
        {
          label: 'sidebar.merchant.promotions',
          link: '/dashboard/:id/promotions',
          exact: false,
          icon: <IconPromotions />,
          permissions: [
            [...PromoDefaultsPermissions, PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW],
            [...PromoDefaultsPermissions, PermissionsV2.STORE_PROMOTION.PAGE_VIEW],
          ],
        },
      ],
    },
  ],
};
