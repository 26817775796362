import {
  IInsightsFiltersDto,
  IInsightsSubFiltersDto,
  InsightsOrderDisplayTypeEnum,
  IInsightsOrderSubFiltersPagerDto,
  IOrderAction,
  CreateOrderConfigDto,
  OrderConfigFacade,
  OrderFacade,
  OrderV3Facade,
} from '@goparrot/order-sdk';
import type { ListOrderRequestDto, ListOrderResponseDto } from '@goparrot/order-sdk';
import { UserFacade } from '@goparrot/core-sdk/platform/user';
import type { RequestPaginationDto, JsonPatchOperationDto } from '@goparrot/common';

import axios, { getConfig, getInterceptors } from 'helpers/request';

const ORDERS = '/orders';
const BASE__PAYMENT = '/payment';
const BASE__CUSTOMER = '/customers';
const BASE__MERCHANT = '/delivery/api/v1/merchants';

// @ts-ignore: to do
const orderFacade = new OrderFacade(getConfig(ORDERS), getInterceptors());
const orderV3Facade = new OrderV3Facade(getConfig(ORDERS), getInterceptors());
// @ts-ignore: to do
const orderConfigFacade = new OrderConfigFacade(getConfig(ORDERS), getInterceptors());
const userFacade = new UserFacade(getConfig(BASE__CUSTOMER), getInterceptors());

export const createOrderThrottling = (storeId: string, payload: CreateOrderConfigDto) =>
  function* () {
    return yield orderConfigFacade.create(storeId, payload);
  };
export const getOrderThrottling = (storeId: string) =>
  function* () {
    return yield orderConfigFacade.get(storeId);
  };

export const updateOrderThrottling = ({ storeId, operations }: { storeId: string; operations: JsonPatchOperationDto[] }) =>
  function* () {
    return yield orderConfigFacade.patch(storeId, operations);
  };

export const getCustomerInfo = (userId: string) =>
  function* () {
    return yield userFacade.get(userId);
  };

export const getTransactions = (storeId: string, search: string) => async () =>
  (await axios.get(`${BASE__PAYMENT}/app/transactions/${storeId}?search=${search}`)).data;

export const getTransaction = (uid: string) => async () => (await axios.get(`${BASE__PAYMENT}/app/transaction?uid=${uid}`)).data;

export const getDeliveryProvider = (merchantId: string, storeId: string, orderId: string) => async () =>
  (await axios.get(`${BASE__MERCHANT}/${merchantId}/stores/${storeId}/transactions/${orderId}`)).data;

export const getOrdersByMultipleStatuses = (storeId: string, queryFilter: IInsightsFiltersDto) =>
  function* () {
    const { statuses, filters }: IInsightsFiltersDto = queryFilter;
    const { pager, orderDisplayType, orderIds, startDate, endDate, customer, orderNumber }: IInsightsSubFiltersDto = filters;
    const { pageSize = 10, page = 1 }: IInsightsOrderSubFiltersPagerDto = pager;

    const body: ListOrderRequestDto = {
      storeIds: [storeId],
      statuses,
      startDate,
      endDate,
      sort: { 'dateInfo.startPrepAt': 'DESC' },
    };

    if (orderNumber) {
      body.orderNumbers = [orderNumber.toString()];
    }

    if (customer) {
      body.recipientName = customer;
    }

    if (orderIds) {
      body.ids = orderIds;
    }

    if (InsightsOrderDisplayTypeEnum.ALL !== orderDisplayType) {
      body.isASAP = orderDisplayType === InsightsOrderDisplayTypeEnum.ASAP_ONLY;
    }

    const pagination: RequestPaginationDto = {
      limit: pageSize,
      offset: (page - 1) * pageSize,
    };

    return yield orderV3Facade.list(body, pagination).then((res: ListOrderResponseDto) => {
      return {
        orders: res.rows,
        total: res.pagination.total,
      };
    });
  };

export const handleActions = (uuid: string, actions: IOrderAction[]) =>
  function* () {
    return yield orderFacade.handleActions(uuid, actions);
  };

export const getOrder = (uuid: string) =>
  function* () {
    return yield orderFacade.get(uuid);
  };
