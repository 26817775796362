import { IRewriteRelationsRequest, IStoreItemProperties, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantUpdateStoreItemPropertiesParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemProperties>;
};
export type MerchantUpdateStoreItemPropertiesResult = UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemPropertiesParams>;

export const useMerchantStoreItemUpdateProperties = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemPropertiesParams>,
): MerchantUpdateStoreItemPropertiesResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemFacade.updateProperties(merchantId, uniqueName, data), options);
};
