import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

export const MODULE_BASE_URL = '/dashboard/merchant/setup';

type MerchantSetupProviderProps = {
  isNewCreatedMerchant: boolean;
  setIsNewCreatedMerchant: React.Dispatch<React.SetStateAction<boolean>>;
  isWizardPage: boolean;
};

const WIZARD_LOCATION_PATH = `${MODULE_BASE_URL}/locations/new`;
const routes = [MODULE_BASE_URL, WIZARD_LOCATION_PATH];

export const MerchantSetupContext = React.createContext<MerchantSetupProviderProps | undefined>(undefined);

export const MerchantSetupProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation();
  const [isNewCreatedMerchant, setIsNewCreatedMerchant] = useState<boolean>(false);
  const isWizardPage = routes.some((route) => matchPath(location.pathname, { path: route, exact: true, strict: false }));

  useEffect(() => {
    if (isWizardPage) {
      setIsNewCreatedMerchant(!isNewCreatedMerchant);
    }
  }, [isWizardPage]);

  return <MerchantSetupContext.Provider value={{ isNewCreatedMerchant, setIsNewCreatedMerchant, isWizardPage }}>{children}</MerchantSetupContext.Provider>;
};

export const useMerchantSetupContext = (): MerchantSetupProviderProps => {
  const context = React.useContext(MerchantSetupContext);

  if (undefined === context) {
    console.error('useMerchantSetupContext must be used within a MerchantSetupProvider');
  }
  return context;
};
