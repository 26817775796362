import { DialogStateReturn } from 'reakit/src/Dialog/DialogState';
import * as React from 'react';
import { ConfirmModal } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

type Props = {
  dialog: DialogStateReturn;
  onConfirm(): void;
  type: string;
  isLoading?: boolean;
};

export const ArchiveConfirmationModal: React.FC<React.PropsWithChildren<Props>> = ({ dialog, onConfirm, type, isLoading }) => (
  <ConfirmModal
    key={type}
    dialog={dialog}
    confirmColor="danger"
    confirmText={getIntlString('menu.form.actions.archive')}
    header={getIntlString('menu.form.archive.modal.title', { itemType: type })}
    content={<div>{getIntlString('menu.form.archive.modal.text')}</div>}
    onConfirm={onConfirm}
    confirmDisabled={isLoading}
  />
);
