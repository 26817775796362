import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { merchantItemBaseGroupFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useMerchantGetItemBaseGroupQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadItemBaseGroupDto>,
): UseQueryResult<ReadItemBaseGroupDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_GROUP_GET, merchantId, uniqueName],
    async () => await merchantItemBaseGroupFacade.get(merchantId, uniqueName),
    options,
  );
};
