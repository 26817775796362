import * as React from 'react';
import { getNotificationProvider } from '@goparrot-dashboard/shared-ui';

type NotificationProps = {
  message?: string | JSX.Element;
  description?: string | JSX.Element;
  placement?: string; // will be ignored, market is always at bottom
};

const generateBody = ({ message, description }: NotificationProps) => {
  if (message && description) {
    return (
      <>
        <h3 className="tw-text-white">{message}</h3>
        <span>{description}</span>
      </>
    );
  } else if (description) {
    return <>{description}</>;
  } else {
    return <>{message}</>;
  }
};

const createMarketNotification = (type: 'success' | 'error' | 'warning' | 'info', props: NotificationProps) => {
  const { notifyError, notifySuccess, notifyWarning, notifyInfo } = getNotificationProvider();
  const text = generateBody(props);

  switch (type) {
    case 'success':
      notifySuccess({ text });
      break;
    case 'error':
      notifyError({ text });
      break;
    case 'warning':
      notifyWarning({ text });
      break;
    case 'info':
    default:
      notifyInfo({ text });
  }
};

const marketNotificationHandler = {
  success: (props: NotificationProps) => createMarketNotification('success', props),
  error: (props: NotificationProps) => createMarketNotification('error', props),
  info: (props: NotificationProps) => createMarketNotification('info', props),
  warning: (props: NotificationProps) => createMarketNotification('warning', props),
  warn: (props: NotificationProps) => createMarketNotification('warning', props),
};

export default marketNotificationHandler;
