import io, { Socket } from 'socket.io-client';

const BASE = 'http://localhost:8082';

export const createWSConnection = (token: string, namespace: string): Socket => {
  const options = {
    reconnectionDelay: 10000,
    reconnectionAttempts: 5,
    auth: { token },
  };
  return io(`${window._env_.GOPARROT_PLATFORM_WEBSOCKET || BASE}/${namespace}`, options);
};
