import { DateTime } from 'luxon';
import { RouteProps } from 'react-router';

export interface RootState {
  root: Record<string, any>;
  router: RouteProps;
}

export enum StatusTagEnum {
  ACTIVE = 'active',
  CREATED = 'created',
}

export enum CloudinaryPresetNameEnum {
  RECTANGLE = 'rectangle',
  SQUARE = 'square',
  CONSTRUCTOR = 'constructor',
  RAW_FILES = 'raw_files',
  FAVICONS = 'favicons',
}
export interface ImageConfig {
  upload_preset: CloudinaryPresetNameEnum;
  max_image_width: number;
  max_image_height: number;
  min_image_height?: number | null;
  maxFileSize?: number;
}

export type DateTimeRangeType = { from: DateTime; to: DateTime };
