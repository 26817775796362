import * as React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketButton, MarketInputSearch } from '@market/react-bindings';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useDebounce } from '../../hooks';

type SearchProps = {
  withButton?: boolean;
  disabled?: boolean;
  value?: string;
  onSearch?(searchValue: string): void;
  placeholder?: string;
  delay?: number;
  widthClass?: string;
};

const MAX_CHARS = 250;

export const Search: React.FC<React.PropsWithChildren<SearchProps>> = ({
  withButton,
  disabled = false,
  value,
  onSearch,
  placeholder,
  delay = 0,
  widthClass = '',
}) => {
  const [searchValue, setSearchValue] = React.useState<string>(value ?? '');
  const debouncedSearchValue = useDebounce(searchValue.toLowerCase(), delay);
  const handleChange = (e: React.BaseSyntheticEvent) => {
    const { value } = e.target;

    if (MAX_CHARS >= value?.length) {
      setSearchValue(value);
    }
  };

  const handleReset = () => setSearchValue('');
  const handleSearch = () => {
    onSearch?.(debouncedSearchValue);
  };

  useEffect(() => {
    if (withButton) {
      return;
    }

    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, value]);

  const node = (
    <MarketInputSearch
      data-testid="search"
      className={classNames(widthClass)}
      slot="search"
      value={searchValue}
      placeholder={placeholder || getIntlString('search.placeholder')}
      disabled={disabled || undefined}
      onInput={(e) => handleChange(e)}
      onMarketInputSearchCleared={handleReset}
    />
  );

  return withButton ? (
    <div className="tw-flex tw-gap-4">
      {node}
      <MarketButton disabled={disabled || undefined} rank="primary" onClick={handleSearch}>
        {getIntlString('button.search')}
      </MarketButton>
    </div>
  ) : (
    node
  );
};
