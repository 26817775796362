import { RootState } from '@goparrot-dashboard/shared-utils';
import { createSelector } from 'reselect';
import { WebsocketJobStore } from './interface';

interface PosSyncDataProps {
  syncJob: WebsocketJobStore;
}

export const getPosSyncData = createSelector<RootState, WebsocketJobStore, PosSyncDataProps>(
  (state) => state.root.websocket.job,
  (syncJob) => ({
    syncJob,
  }),
);
