import { ItemGroupingTypeEnum } from '@goparrot/storeitems-sdk';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { encodeUrl } from '@goparrot-dashboard/shared-utils/src/utils/encodeUrl';
import { MENU_SHORTCUTS } from '../constants';
import { ConnectionItem, VariationStoreItemTypeEnum } from '../interface';
import { isMenuConcept } from './isMenuConceptGuard';

export const getConnectionUrl = (item: ConnectionItem, selectedStoreId: string): string => {
  if (isMenuConcept(item)) return null;
  const { uniqueName, storeId, type } = item;

  switch (item.type) {
    case StoreItemTypeEnum.ITEM_SET: {
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.ITEM_BASE: {
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.STORE_ITEM: {
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.ITEM_OPTIONS: {
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.CATEGORY: {
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.STORE_ITEM_BUNDLE: {
      return `/dashboard/menu-management/${selectedStoreId}/${MENU_SHORTCUTS.COMBO_GROUP}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.COMBO: {
      return `/dashboard/menu-management/${selectedStoreId}/${MENU_SHORTCUTS.COMBO}/${storeId}/edit/${encodeUrl(uniqueName)}`;
    }
    case StoreItemTypeEnum.STORE_ITEM_GROUP: {
      if (ItemGroupingTypeEnum.VARIATION === item.groupingType) {
        return `/dashboard/menu-management/${selectedStoreId}/storeItem/${item.storeId}/edit/${encodeUrl(uniqueName)}/${
          VariationStoreItemTypeEnum.STORE_ITEM_VARIATION_GROUP
        }`;
      }
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(item.uniqueName)}`;
    }
    default:
      return `/dashboard/menu-management/${selectedStoreId}/${type}/${storeId}/edit/${encodeUrl(item.uniqueName)}`;
  }
};
