import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { PatchMenuSyncConfigDto, ReadMenuSyncConfigDto } from '@goparrot/menu-sync-sdk';
import { menuSyncConfigFacade } from '../facades';

type SyncConfigPatchParams = {
  merchantId: string;
  uuid: string;
  data: PatchMenuSyncConfigDto;
};

export const usePatchSyncConfig = (
  options?: UseMutationOptions<ReadMenuSyncConfigDto, unknown, SyncConfigPatchParams>,
): UseMutationResult<ReadMenuSyncConfigDto, unknown, SyncConfigPatchParams> =>
  useMutation(({ merchantId, uuid, data }: SyncConfigPatchParams) => menuSyncConfigFacade.patch(merchantId, uuid, data), options);
