import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { locationItemBaseGroupFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useLocationGetItemBaseGroupQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemBaseGroupDto>,
): UseQueryResult<ReadItemBaseGroupDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_GROUP_GET, merchantId, storeId, uniqueName],
    async () => await locationItemBaseGroupFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
