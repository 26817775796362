import { PatchItemBaseModsDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseFacade } from '../../../facades';

export type PatchItemBaseModParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchItemBaseModsDto;
};

export const usePatchItemBaseMod = (
  options?: UseMutationOptions<ReadItemBaseDto, unknown, PatchItemBaseModParams>,
): UseMutationResult<ReadItemBaseDto, unknown, PatchItemBaseModParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseFacade.patchMod(merchantId, uniqueName, data), options);
};
