import * as React from 'react';
import { getIntlString, IntlMessage } from '@goparrot-dashboard/i18n';
import { ReadUserDto } from '@goparrot/users-v2-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { isProductionEnv } from 'helpers/isProductionEnv';
import { Alert } from './styled';

interface State {
  hasError: boolean;
  error?: Error;
}
interface Props {
  user: ReadUserDto;
  children?: React.ReactNode;
}
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError) {
      if (error.name === 'ChunkLoadError') {
        window.location.reload();
        return;
      }
      const errorData = {
        stack: error.stack,
        user: this.props.user,
      };
      if (isProductionEnv() && !!window._env_.DD_CLIENT_TOKEN) {
        datadogLogs.logger.error(error.name, { errorData });
      }

      return (
        <Alert
          message={getIntlString('component.warning.title')}
          description={
            <IntlMessage
              id={'component.warning.message'}
              values={{
                link: (chunks: string) => <a href={'https://squareup.com/help/us/en/contact'}>{chunks}</a>,
              }}
            />
          }
          type="warning"
          showIcon
        />
      );
    }

    return this.props.children;
  }
}
