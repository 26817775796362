export function getSsoUrlParams(): { code: string; state: string; clearParams: () => void } {
  const searchParams = new URL(window.location.href)?.searchParams;

  return {
    code: searchParams?.get('code') ?? '',
    state: searchParams?.get('state') ?? '',
    clearParams: () => {
      history.replaceState({}, '', location.pathname);
    },
  };
}
