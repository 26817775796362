import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { InviteEmailUserDto, ReadUserDto } from '@goparrot/users-v2-sdk';

import { userFacade } from '../facades';

type InviteFranchiseParams = {
  data: InviteEmailUserDto[];
};

export const useInviteFranchise = (
  options?: UseMutationOptions<ReadUserDto[], unknown, InviteFranchiseParams>,
): UseMutationResult<ReadUserDto[], unknown, InviteFranchiseParams> => {
  return useMutation(({ data }) => userFacade.inviteFranchise(data), options);
};
