import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemGroupFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { MerchantGetParentsStoreItemGroupQueryParams } from '../../../interface';

export const useMerchantGetStoreItemGroupOptionsQuery = (
  { merchantId, uniqueName }: MerchantGetParentsStoreItemGroupQueryParams,
  options?: UseQueryOptions<ReadItemOptionsDto[]>,
): UseQueryResult<ReadItemOptionsDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GROUP_GET_ITEM_OPTIONS, merchantId, uniqueName],
    async () => await merchantStoreItemGroupFacade.getItemOptions(merchantId, uniqueName),
    options,
  );
};
