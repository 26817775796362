import { IRewriteRelationsRequest, IItemBaseGroupMetadataSelection, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseGroupFacade } from '../../../facades';

export type LocationUpdateSelectionsItemBaseGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IItemBaseGroupMetadataSelection>;
};
export const useLocationUpdateSelectionsItemBaseGroup = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateSelectionsItemBaseGroupParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateSelectionsItemBaseGroupParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseGroupFacade.updateSelections(merchantId, storeId, uniqueName, data),
    options,
  );
};
