import { useI18n } from '@goparrot-dashboard/i18n';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { useHistory } from 'react-router-dom';

import { useStoreListRefetch } from './useStoreListRefetch';

type UseAfterCreateStoreResult = {
  (url?: string): void;
};

export const useAfterStoreCreate = (): UseAfterCreateStoreResult => {
  const router = useHistory();
  const { getIntlString } = useI18n();
  const getStoreList = useStoreListRefetch();
  const { notifySuccess } = useNotifications();

  return (url?: string) => {
    notifySuccess({
      text: getIntlString('notification.location.create.success.description', { isRootPath: true }),
    });

    getStoreList();

    if (url) {
      router.push(url);
    }
  };
};
