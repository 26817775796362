import { useDispatch } from 'react-redux';
import type { ReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import aLocationDefault from '../../../../modules/client/src/store/location-default/actions';

type UseAfterUpdateStoreResult = {
  (defaultStore: ReadDefaultStoreDto): void;
};

export const useAfterDefaultStoreUpdate = (): UseAfterUpdateStoreResult => {
  const dispatch = useDispatch();
  const onAfterUpdate = (defaultStoreData: ReadDefaultStoreDto) => {
    dispatch(aLocationDefault.successUpdate(defaultStoreData));
  };

  return onAfterUpdate;
};
