import { useStoreService } from '@goparrot-dashboard-core/store-service';
import React, { createContext, FC, useCallback, useContext, useEffect } from 'react';

import { GiftCardOrchestratorEventEnum, IGiftCardOrchestratorResponse } from '@goparrot/websocket-sdk';
import { ReadLocationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { useDeleteGiftCardLocations, useGiftCardLocations } from '../../hooks';
import { StoreWithLocation } from '../../types';

const defaultContext: {
  locations: StoreWithLocation<ReadLocationDto>[];
  deleteAllLocations(): void;
  fetchLocations(): void;
  updateLocationStatus(eventType: GiftCardOrchestratorEventEnum, status: IGiftCardOrchestratorResponse): void;
} = {
  locations: [],
  fetchLocations: () => null,
  deleteAllLocations: () => null,
  updateLocationStatus: () => null,
};

export type GiftCardOrchestrationContextType = typeof defaultContext;

export const GiftCardOrchestrationContext = createContext(defaultContext);

export const useGiftCardOrchestrationContext = (): GiftCardOrchestrationContextType => {
  const context = useContext(GiftCardOrchestrationContext);

  if (context === undefined) {
    console.error('useGiftCardOrchestrationContext must be used within a GiftCardOrchestrationService');
  }

  return context;
};

export const GiftCardOrchestrationService: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    stores,
    merchant: { merchantId },
  } = useStoreService();
  const { locations, fetchLocations, updateLocationStatus } = useGiftCardLocations(merchantId, stores);
  const { deleteLocations } = useDeleteGiftCardLocations(fetchLocations);

  const deleteAllLocations = useCallback(() => {
    const storeIds = locations.map(({ storeId }) => storeId);
    deleteLocations(merchantId, storeIds);
    fetchLocations();
  }, [deleteLocations, fetchLocations, locations, merchantId]);

  const contextValue: GiftCardOrchestrationContextType = {
    updateLocationStatus,
    locations,
    fetchLocations,
    deleteAllLocations,
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  return <GiftCardOrchestrationContext.Provider value={contextValue}>{children}</GiftCardOrchestrationContext.Provider>;
};
