import React, { Dispatch, SetStateAction, FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketInputText } from '@market/react-bindings';
import { InputNumber } from '@goparrot-dashboard/shared-ui';
import { preventMinusKey } from '../form';
import { ActionData } from './constants';

type Props = {
  actionData: ActionData;
  setActionData: Dispatch<SetStateAction<ActionData>>;
  priceIsEmpty: boolean;
  disableInputs: boolean;
};

export const ActionInputReplicaPrice: FC<React.PropsWithChildren<Props>> = React.memo(({ actionData, setActionData, priceIsEmpty, disableInputs }) => {
  return (
    <MarketInputText
      value={actionData.price ? actionData.price.toString() : undefined}
      invalid={priceIsEmpty || undefined}
      disabled={disableInputs || undefined}
    >
      <label>{getIntlString('menu.form.price', { isRootPath: true })}</label>
      <InputNumber
        slot="input"
        value={actionData.price}
        onChange={(value) => {
          setActionData({ ...actionData, price: value });
        }}
        disabled={disableInputs}
        prefix="$"
        name="price"
        decimalScale={2}
        onKeyDown={preventMinusKey}
      />
    </MarketInputText>
  );
});
