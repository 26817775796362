import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { ISearchItemResult, ISearchTaxResult } from '@goparrot/menu-sync-sdk';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { menuSyncTaxFacade, menuSyncItemFacade } from '@goparrot-dashboard-api/menu-sync';
import { Select, IconSearch, Button, OutlineSelectComponents } from '@goparrot-dashboard/shared-ui';
import { components, SingleValueProps } from 'react-select';
import { getIntlString } from '@goparrot-dashboard/i18n';
import aMenuSync from 'store/pos/actions';
import classNames from 'classnames';
import { getCentralLocation } from '@goparrot-dashboard/orchestration';

import aWs from '../../../../client/src/store/websocket/actions';
import { TAX } from '../../constants';
import { Item } from '../../interface';
import { Card } from './Card';

type PosGuidInputPropsType = {
  selectOnly?: boolean;
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
  disabledSync?: boolean;
  isSyncing?: boolean;
  itemType: StoreItemTypeEnum | typeof TAX;
  uniqueName?: string;
  itemData?: Item;
  callbackSearchResult?: (searchResult: SearchResultType[]) => void;
};

type OptionsType = {
  value: string;
  label: JSX.Element;
};

type SearchResultType = ISearchItemResult | ISearchTaxResult;

const QUERY_MIN_LENGTH = 3;

const SingleValue: React.FC<React.PropsWithChildren<unknown>> = ({ children, ...props }: SingleValueProps<any>) => (
  <components.SingleValue
    {...props}
    className={classNames([props.isDisabled ? 'tw-text-gray-600' : props?.selectProps?.selectedValueColor ?? 'tw-text-light-blue-400'])}
  >
    {props.data?.value}
  </components.SingleValue>
);

export const GuidInput: React.FC<React.PropsWithChildren<PosGuidInputPropsType>> = ({
  uniqueName,
  itemType,
  value,
  onChange,
  disabled,
  disabledSync,
  callbackSearchResult,
  isSyncing = false,
  selectOnly = false,
  itemData,
}) => {
  const [input, setInput] = useState<string>(value);
  const isItemSetType = StoreItemTypeEnum.ITEM_SET === itemType;
  const [searchResult, setSearchResult] = useState<SearchResultType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const {
    selectedStoreId,
    merchant: { merchantId },
    franchiseLocations,
    isMerchant,
  } = useStoreService();
  const centralLocation = getCentralLocation(franchiseLocations);

  const storeId = isMerchant ? centralLocation?.linkedStoreId || merchantId : selectedStoreId;

  useEffect(() => {
    const SyncJobId = sessionStorage.getItem(uniqueName);
    if (!SyncJobId) return;
    const { jobId } = JSON.parse(SyncJobId);
    reduxDispatch(aWs.emitItemSyncJob({ jobId }));
  }, [reduxDispatch, uniqueName]);

  // @ts-ignore-next-line
  useEffect(() => {
    reduxDispatch(aWs.subscribeItemSyncJob({ connect: true }));
    return () => reduxDispatch(aWs.subscribeItemSyncJob({ connect: false }));
  }, [reduxDispatch]);

  useEffect(() => {
    if (callbackSearchResult && searchResult) {
      callbackSearchResult(searchResult);
    }
  }, [callbackSearchResult, searchResult]);
  const memoizedValue = useMemo(() => (value?.length ? value : null), [value]);

  const handleChange = (v: string) => {
    const nextValue = null === v ? undefined : v;
    setInput(nextValue);
    onChange(nextValue);
  };

  const handleInputChange = (val: string, { action }) => {
    if (action === 'input-change') {
      setInput(val);
    }
  };

  const handleOnFocus = () => {
    loadOptions(input);
  };

  const options = (data: SearchResultType[]): OptionsType[] =>
    (data || []).map((item) => ({
      value: item?.guid as string,
      ...(!(TAX === itemType) && { title: (item as ISearchItemResult)?.title }),
      label: <Card item={item} itemType={itemType} />,
    }));

  const optionsList = useMemo(() => options(searchResult), [searchResult]);

  useEffect(() => {
    if (isItemSetType) {
      menuSyncItemFacade
        .search(merchantId, storeId, undefined, StoreItemTypeEnum.ITEM_SET)
        .then((res) => {
          setSearchResult(res);
        })
        .catch(() => {
          setSearchResult([]);
        });
    }
  }, [isItemSetType, itemType, merchantId, storeId]);

  const loadOptions = debounce(async (query: string, callback?: (options: OptionsType[]) => void): Promise<any> => {
    if (QUERY_MIN_LENGTH <= query?.length) {
      setIsLoading(true);
      try {
        const data = await (TAX === itemType ? menuSyncTaxFacade.search(storeId, query) : menuSyncItemFacade.search(merchantId, storeId, query, itemType));
        if (data) {
          setSearchResult(data);
          callback && callback(options(data));
        }
      } catch (e) {
        console.error(e);
        callback && callback([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      return callback && callback([]);
    }
  }, 300);

  const syncItem = () => {
    itemData &&
      reduxDispatch(
        aMenuSync.requestSyncItemsFromPOS({
          merchantId: itemData.merchantId,
          storeId: itemData.storeId,
          type: itemData.type,
          uniqueName: itemData.uniqueName,
          title: itemData.title,
        }),
      );
  };
  const filterOptions = (candidate: { label: any; value: string; title: string; data: any }, input: string) => {
    const { title }: { title: string } = candidate.label?.props?.item || {};
    const titleMatch = title ? title.trim().toLowerCase().includes(input.trim().toLowerCase()) : false;
    return candidate.value.trim().toLowerCase().includes(input.trim().toLowerCase()) || titleMatch;
  };

  return selectOnly ? (
    <Select
      key={memoizedValue}
      isAsync
      disabled={disabled}
      inputId="guidInput"
      inputValue={input}
      placeholder={getIntlString('page.merchant.menu.form.posGuid.placeholder')}
      value={memoizedValue}
      className="tw-w-full"
      onInputChange={handleInputChange}
      onChange={handleChange}
      loadOptions={loadOptions}
      onFocus={handleOnFocus}
      isClearable
      blurInputOnSelect
      placeholderColor="tw-blue-gray-300"
      selectedValueColor="tw-text-blue-gray-900"
      options={optionsList}
      isLoading={isLoading}
    />
  ) : (
    <div className="tw-w-full tw-space-y-2 tw-text-blue-gray-900 tw-font-normal">
      <div className="tw-text-sm">{getIntlString('menu.form.pos-guid.title')}</div>
      <div className="tw-flex tw-w-full tw-items-center tw-space-x-3">
        {isItemSetType ? (
          <Select
            key={memoizedValue}
            overrideComponents={{
              ...OutlineSelectComponents,
              SingleValue,
            }}
            variant="outline"
            inputId="guidInput"
            cacheOptions
            inputValue={input}
            value={memoizedValue}
            className="tw-w-full"
            onInputChange={handleInputChange}
            onChange={handleChange}
            placeholder={getIntlString('menu.form.pos-guid.placeholder')}
            isClearable
            isSearchable
            blurInputOnSelect
            placeholderColor="tw-blue-gray-300"
            selectedValueColor="tw-text-blue-gray-900"
            disabled={disabled}
            options={optionsList}
            filterOption={filterOptions}
            appendIcon={<IconSearch className="tw-h-4.5" />}
          />
        ) : (
          <Select
            key={memoizedValue}
            overrideComponents={{
              ...OutlineSelectComponents,
              SingleValue,
            }}
            isAsync
            disabled={disabled}
            variant="outline"
            inputId="guidInput"
            inputValue={input}
            placeholder={getIntlString('menu.form.pos-guid.placeholder')}
            value={memoizedValue}
            className="tw-w-full"
            onInputChange={handleInputChange}
            onChange={handleChange}
            onFocus={handleOnFocus}
            loadOptions={loadOptions}
            isClearable
            isSearchable
            blurInputOnSelect
            placeholderColor="tw-blue-gray-300"
            selectedValueColor="tw-text-blue-gray-900"
            appendIcon={<IconSearch className="tw-h-4.5" />}
            defaultOptions={optionsList}
            isLoading={isLoading}
          />
        )}
        <Button
          loading={isSyncing}
          color="secondary"
          onClick={syncItem}
          className="tw-space-x-1 tw-flex-shrink-0"
          disabled={disabled || disabledSync || isSyncing}
        >
          <div>{getIntlString('menu.form.pos-guid.resync')}</div>
        </Button>
      </div>
      <div className="tw-text-xs">{getIntlString('menu.form.pos-guid.info')}</div>
    </div>
  );
};
