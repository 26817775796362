import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { LocationsItemSetsUpdateRequestDto, ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { locationFacade } from '../facades';

export type UpdateCatalogByItemSetParams = {
  merchantId: string;
  data: LocationsItemSetsUpdateRequestDto;
};

export const useUpdateCatalogByItemSet = (
  options?: UseMutationOptions<ReadLocationDto[], unknown, UpdateCatalogByItemSetParams, unknown>,
): UseMutationResult<ReadLocationDto[], unknown, UpdateCatalogByItemSetParams, unknown> => {
  return useMutation(({ merchantId, data }) => locationFacade.updateCatalogByItemSets(merchantId, data), options);
};
