import { useState } from 'react';
import { PatchLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { locationFacade } from '../api/location';

export function useUpdateLocation(
  onSuccess: () => void = () => null,
): {
  updateLocation(merchantId: string, storeId: string, body: PatchLocationDto): void;
  isLoading: boolean;
} {
  const { notifyError, notifySuccess } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const updateLocation = async (merchantId: string, storeId: string, body: PatchLocationDto) => {
    try {
      setIsLoading(true);
      await locationFacade.patch(merchantId, storeId, body);
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.location.update.success.message'),
      });
      onSuccess();
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.location.update.error.message'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { updateLocation, isLoading };
}
