import { createAction } from '../../helpers/create-action';

export enum WebStoreActions {
  API__GET__WEB__STORE__URLS__REQUEST = 'API__GET__WEB__STORE__URLS__REQUEST',
  API__GET__WEB__STORE__URLS__SUCCESS = 'API__GET__WEB__STORE__URLS__SUCCESS',
  API__GET__WEB__STORE__URLS__ERROR = 'API__GET__WEB__STORE__URLS__ERROR',
}

export const getWebStoreActions = {
  requestGetWebStoreUrls: createAction<{ storeId: string }>(WebStoreActions.API__GET__WEB__STORE__URLS__REQUEST),
  successGetWebStoreUrls: createAction(WebStoreActions.API__GET__WEB__STORE__URLS__SUCCESS),
  errorGetWebStoreUrls: createAction(WebStoreActions.API__GET__WEB__STORE__URLS__ERROR),
};
