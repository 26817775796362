import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { locationItemBaseGroupFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useGetLocationItemBaseGroupParentsQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemOptionsDto[], Error>,
): UseQueryResult<ReadItemOptionsDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_GROUP_GET_PARENTS, merchantId, storeId, uniqueName],
    () => locationItemBaseGroupFacade.getParents(merchantId, storeId, uniqueName),
    options,
  );
};
