import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { ReadItemBaseMetadataDto } from '@goparrot/storeitems-sdk';
import { FormField, InputNumber, LabelWithTooltip } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import moment from 'moment';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { Item } from '../../interface';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString, preventMinusKey } from './utils';

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;

type Props = {
  label?: string | React.ReactNode;
  tooltipLabel?: string;
};

export const ItemPrepTime = <T extends { metadata: ReadItemBaseMetadataDto }>({ label, tooltipLabel }: Props): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const { value, onChange, disabled } = bindInputProps('metadata.prepTime');
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditLabels
    : franchisePermissions?.canEditLabels;

  const [canInitializeValue, setCanInitializeValue] = React.useState<boolean>(value !== undefined);
  const [days, setDays] = React.useState<number>(0);
  const [hours, setHours] = React.useState<number>(0);
  const [minutes, setMinutes] = React.useState<number>(0);

  React.useEffect(() => {
    if (value) {
      const duration = moment.duration(value, 'minutes');
      const durationDays = Math.floor(duration.asDays());
      const durationHours = Math.floor(duration.asHours() - durationDays * HOURS_PER_DAY);
      const durationMinutes = value - durationDays * HOURS_PER_DAY * MINUTES_PER_HOUR - durationHours * MINUTES_PER_HOUR;

      setDays(durationDays);
      setHours(durationHours);
      setMinutes(durationMinutes);
    }
  }, []);

  React.useEffect(() => {
    if (canInitializeValue) {
      const prepTime = days * HOURS_PER_DAY * MINUTES_PER_HOUR + hours * MINUTES_PER_HOUR + minutes;
      onChange(prepTime);
    } else {
      setCanInitializeValue(true);
    }
  }, [days, hours, minutes]);

  const prepTimedata = [
    {
      name: 'days',
      value: days,
      onChange: setDays,
      max: 999,
    },
    {
      name: 'hours',
      value: hours,
      onChange: setHours,
      max: 23,
    },
    {
      name: 'min',
      value: minutes,
      onChange: setMinutes,
      max: 59,
    },
  ];

  return (
    <FormField
      {...bindFormFieldProps('metadata.prepTime')}
      withValuePadding={false}
      label={
        tooltipLabel ? (
          <LabelWithTooltip tooltip={tooltipLabel}>{label || getMenuFormIntlString('preptime')}</LabelWithTooltip>
        ) : (
          label || getMenuFormIntlString('preptime')
        )
      }
      disabled={isItemReplica ? !hasFranchisePermission : disabled}
      input={
        <div className="tw-flex tw-items-center tw-py-2 tw-h-full">
          {prepTimedata?.map((item, index) => {
            return (
              <div
                key={index}
                className="tw-flex tw-justify-between tw-items-center tw-h-full tw-w-28 tw-px-4 tw-border-r tw-border-cool-gray-200 last:tw-border-r-0"
              >
                <InputNumber
                  value={item.value || 0}
                  onChange={item.onChange}
                  disabled={isItemReplica ? !hasFranchisePermission : disabled}
                  name={item.name}
                  min={0}
                  max={item.max}
                  className="tw-pr-2"
                  decimalScale={2}
                  onKeyDown={preventMinusKey}
                />
                <span className="tw-leading-none tw-text-cool-gray-400">{getMenuFormIntlString(`${item.name}`)}</span>
              </div>
            );
          })}
        </div>
      }
    />
  );
};
