import {
  PatchVendorDto as PatchVendorModel,
  CreateVendorDto as CreateVendorModel,
  CreateSquareMetadataDto as CreateSquareMetadataModel,
  CreateGoParrotMetadataDto as CreateGoParrotMetadataModel,
  VendorExposeGroup,
} from '@goparrot/vendor-sdk';
import { plainToClass } from 'class-transformer';
import { removeUndefinedNestedObjectProperties } from '@goparrot-dashboard/shared-utils';

(PatchVendorModel as any).prototype.init = function (options: Partial<PatchVendorModel>) {
  Object.assign(
    this,
    plainToClass(PatchVendorModel, options, {
      excludeExtraneousValues: true,
      groups: [VendorExposeGroup.PATCH],
    }),
  );

  return removeUndefinedNestedObjectProperties(this);
};

(CreateVendorModel as any).prototype.init = function (options: Partial<CreateVendorModel>) {
  Object.assign(
    this,
    plainToClass(CreateVendorModel, options, {
      excludeExtraneousValues: true,
      groups: [VendorExposeGroup.CREATE],
    }),
  );

  /**
   * Metadata Square Model
   */
  (CreateSquareMetadataModel as any).prototype.init = function (options: Partial<CreateSquareMetadataModel> = {}) {
    this.notesSeparator = options?.notesSeparator ?? '';
    this.showTip = options?.showTip;
    this.showReadyTime = options?.showReadyTime;
    this.sendPreparationTime = options?.sendPreparationTime;
    this.checkTitleTemplate = options?.checkTitleTemplate;
    this.isShipmentEnabled = options?.isShipmentEnabled;
    this.showUserTags = options?.showUserTags;
    this.tagTitle = options?.tagTitle;
    this.autoCompleteDuration = options?.autoCompleteDuration;
    this.markOrderInProgress = options?.markOrderInProgress;
    this.isNoteSentDisabled = options?.isNoteSentDisabled;
    this.tryToUseDefinedLineItemTax = options?.tryToUseDefinedLineItemTax;
    this.sendReadyPrepAtAsPosPickupAt = options?.sendReadyPrepAtAsPosPickupAt;

    return this;
  };

  /**
   * Metadata GoParrot Model
   */
  (CreateGoParrotMetadataModel as any).prototype.init = function (options: Partial<CreateGoParrotMetadataModel> = {}) {
    this.reason = options?.reason;
    return this;
  };

  return this;
};

export { PatchVendorModel, CreateVendorModel, CreateSquareMetadataModel, CreateGoParrotMetadataModel };
