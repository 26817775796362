import type { CreateUserDto, ReadUserDto } from '@goparrot/users-v2-sdk';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { userFacade } from '../facades';

export const useCreateUser = (
  options?: UseMutationOptions<ReadUserDto, AxiosError, CreateUserDto>,
): UseMutationResult<ReadUserDto, AxiosError, CreateUserDto> => {
  return useMutation((data) => userFacade.create(data), options);
};
