import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/commons/uielements/tooltip';

type LabelWithTooltipProps = {
  label: React.ReactElement;
  tooltip: React.ReactElement;
};

const LabelWithTooltip: React.FC<React.PropsWithChildren<LabelWithTooltipProps>> = ({ label, tooltip }) => (
  <span>
    <SpanMargin>{label}</SpanMargin>
    <Tooltip withIcon title={tooltip} iconType="question-circle" />
  </span>
);

export default LabelWithTooltip;

const SpanMargin = styled.span`
  margin: 0 5px 0 0;
`;
