import { combineReducers } from 'redux';

import info from './reducers/info';
import permissions from './reducers/permissions';
import roleV2 from './reducers/role-v2';

export default combineReducers({
  info,
  permissions,
  roleV2,
});
