import { PatchItemBaseGroupDto, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type MerchantUpdateItemBaseGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchItemBaseGroupDto;
};
export const useMerchantUpdateItemBaseGroup = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, MerchantUpdateItemBaseGroupParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, MerchantUpdateItemBaseGroupParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseGroupFacade.patch(merchantId, uniqueName, data), options);
};
