import { useEffect, useRef } from 'react';

/**
 * @deprecated moved to @goparrot-dashboard/shared-utils hooks
 */
export function usePrevious<T>(prop: T) {
  const ref = useRef(prop);

  useEffect(() => {
    ref.current = prop;
  });

  return ref.current;
}
