import { useI18n } from '@goparrot-dashboard/i18n';
import { ButtonLinkToStore } from '@goparrot-dashboard/shared-utils';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { useDialogState } from 'reakit/Dialog';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MarketButton } from '@market/react-bindings';
import { ConfirmModal, GenericTable, Spinner, IconTrash, IconSettingsLarge } from '@goparrot-dashboard/shared-ui';
import type { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { useMenuOrchestrationContext } from '../../services';
import { SyncStatusBadge } from '../sync-status-badge';
import { useDeleteLocation, useItemSetsListQuery } from '../../hooks';
import { getMenuOrchestratorLocationBadgeStatus } from '../../utils';

type Props = {
  hideSyncButton: boolean;
  searchFilter?: string;
  deleteSyncingLocations?: string[];
  setDeleteSyncingLocations: React.Dispatch<React.SetStateAction<string[]>>;
};

export const MenuLocationsTable: FC<React.PropsWithChildren<Props>> = ({ hideSyncButton, searchFilter, deleteSyncingLocations, setDeleteSyncingLocations }) => {
  const {
    merchant: { merchantId },
    locationsFetching,
    locationDefaultFetching,
    defaultStores,
    selectedStoreId,
  } = useStoreService();

  const { getIntlString } = useI18n();
  const { fetchLocations, locations } = useMenuOrchestrationContext();
  const { data: itemSetData } = useItemSetsListQuery({ merchantId });

  useEffect(() => {
    fetchLocations();
    // we need to fetch locations when coming back from store specific settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // delete location area
  const [storeToDelete, setStoreToDelete] = useState<string | null>(null);
  const deleteDialogState = useDialogState({ animated: true });
  const { deleteLocation } = useDeleteLocation(fetchLocations);

  const onDelete = useCallback(
    async (storeId: string | null) => {
      if (storeId) {
        setDeleteSyncingLocations([...(deleteSyncingLocations ?? []), storeId]);
        await deleteLocation(merchantId, storeId);
        deleteDialogState.hide();
        setTimeout(() => setStoreToDelete(null), 200);
      }
    },
    [setDeleteSyncingLocations, deleteSyncingLocations, deleteLocation, merchantId, deleteDialogState],
  );

  const confirmDelete = useCallback((storeId: string) => {
    setStoreToDelete(storeId);
    deleteDialogState.show();
    // dialog state causes infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: () => (
          <HeaderWrapper className="tw-pl-3">
            {getIntlString('page.merchant.orchestration.locations.table.nameHeader', { isRootPath: true }).toUpperCase()}
          </HeaderWrapper>
        ),
        maxWidth: 180,
        Cell: ({ value }: { value: string }) => <span className="tw-pl-3 tw-text-light-blue-500 tw-max-w-200px xl:tw-max-w-full tw-inline-block">{value}</span>,
      },
      {
        className: 'tw-text-center',
        Header: () => <HeaderWrapper>{getIntlString('page.merchant.orchestration.locations.table.menuStatusHeader', { isRootPath: true })}</HeaderWrapper>,
        accessor: 'franchiseLocation',
        maxWidth: 200,
        Cell: ({ value: franchiseLocation }: { value: ReadLocationDto }) => {
          const [firstMenuTitle, ...restMenuTitles] = (franchiseLocation.itemSets ?? []).map((itemSetId: string) => {
            return (itemSetData?.data ?? []).find((itemSet) => itemSet.uniqueName === itemSetId)?.title;
          });

          const itemSetString = firstMenuTitle ? `${firstMenuTitle} ${restMenuTitles.length ? `+ ${restMenuTitles.length} more` : ''}` : '';

          return (
            <div className="tw-flex tw-items-center tw-justify-center tw-break-all">
              <SyncStatusBadge status={getMenuOrchestratorLocationBadgeStatus(franchiseLocation.status)} />
              {itemSetString && <div className="tw-ml-2">{itemSetString}</div>}
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'storeId',
        Cell: ({ value: storeId }: { value: string }) => {
          return (
            <Spinner spinning={false}>
              <div className="tw-flex tw-items-center tw-justify-end">
                <ButtonLinkToStore
                  className="tw-inline-block tw-ml-2"
                  storeId={storeId}
                  locationsLoading={locationsFetching || locationDefaultFetching}
                  defaultStores={defaultStores}
                  selectedStoreId={selectedStoreId}
                ></ButtonLinkToStore>
                {!hideSyncButton && (
                  <Link to={`franchise/location/${storeId}`}>
                    <MarketButton size="small" rank="secondary" className="tw-inline-block tw-ml-2">
                      {getIntlString('page.merchant.orchestration.locations.table.optionsText', { isRootPath: true })}
                      <IconSettingsLarge width={20} height={20} />
                    </MarketButton>
                  </Link>
                )}
                <MarketButton className="tw-ml-2" size="small" rank="tertiary" variant="destructive" onClick={() => confirmDelete(storeId)}>
                  <IconTrash width={18} height={18} />
                </MarketButton>
              </div>
            </Spinner>
          );
        },
      },
    ],
    [getIntlString, itemSetData?.data, locationsFetching, locationDefaultFetching, defaultStores, selectedStoreId, hideSyncButton, confirmDelete],
  );
  const data = useMemo(
    () =>
      locations.filter(
        (store) =>
          store.franchiseLocation &&
          !store.franchiseLocation.isCentralLocation &&
          (!searchFilter || store.name?.toLowerCase().includes(searchFilter.toLowerCase())),
      ),
    [locations, searchFilter],
  );

  const table = useTable({
    data,
    columns,
  });

  return (
    <>
      <GenericTable placeholder="Currently there are no locations. Get started by adding one" rowClassName="odd:tw-bg-cool-gray-50 tw-border-none" {...table} />
      <ConfirmModal
        dialog={deleteDialogState}
        header={getIntlString('page.merchant.orchestration.locations.deleteOneHeader', { isRootPath: true })}
        content={
          <p className="tw-text-lg">
            {getIntlString('page.merchant.orchestration.locations.deleteOnePrefix', { isRootPath: true })}{' '}
            <b>{locations?.find((l) => l.storeId === storeToDelete)?.name}</b>{' '}
            {getIntlString('page.merchant.orchestration.locations.deleteOneSuffix', { isRootPath: true })}
            <br />
            {getIntlString('page.merchant.orchestration.locations.deleteOneConfirm', { isRootPath: true })}
          </p>
        }
        onConfirm={() => onDelete(storeToDelete)}
      />
    </>
  );
};

const HeaderWrapper: FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => (
  <span className={`tw-text-sm tw-font-bold tw-w-full tw-uppercase tw-text-cool-gray-900 ${className}`}>{children}</span>
);
