import React from 'react';
import { TableReplicas } from './TableReplicas';
import { LocationGroupItem } from './utils';
import { ReplicaUpdateDataFields, StoreSpecificItem } from './constants';
import { ReplicasUpdateData } from './StoreSpecificDisplaySettingsModal';

type Props = {
  original: LocationGroupItem;
  isOpened: boolean;
  replicasUpdateData: React.MutableRefObject<ReplicasUpdateData>;
  selectedReplicas: StoreSpecificItem[];
  toggleSelectReplica: (replica: StoreSpecificItem) => void;
  toggleReplicaArchived: (replica: StoreSpecificItem) => void;
  handleUpdateReplicaData: ({ uniqueName, data }: { uniqueName: string; data: ReplicaUpdateDataFields }) => void;
};

export const DropdownItem: React.FC<React.PropsWithChildren<Props>> = ({
  original,
  isOpened,
  selectedReplicas,
  replicasUpdateData,
  toggleReplicaArchived,
  toggleSelectReplica,
  handleUpdateReplicaData,
}) => {
  if (!isOpened) {
    return null;
  }

  return (
    <>
      {
        <div key={original.locationGroupData.uuid} className="tw-w-full tw-border-collapse tw-bg-cool-gray-50 tw-border-b-px tw-border-b">
          <TableReplicas
            replicasUpdateData={replicasUpdateData}
            dataSource={original.replicas as StoreSpecificItem[]}
            selectedReplicas={selectedReplicas}
            toggleSelectReplica={toggleSelectReplica}
            toggleReplicaArchived={toggleReplicaArchived}
            handleUpdateReplicaData={handleUpdateReplicaData}
          />
        </div>
      }
    </>
  );
};
