import { useStoreService } from '@goparrot-dashboard-core/store-service';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useI18n } from '@goparrot-dashboard/i18n';
import { PermissionsV2 } from '@goparrot/users-v2-sdk';
import { isDefaultStore, MM_FILTERS_CACHE_KEY, MM_SEARCH_CACHE_KEY } from '@goparrot-dashboard/shared-utils';
import { usePermission } from '@goparrot-dashboard-core/user-service';
import { useVendorByStoreId } from '@goparrot-dashboard-api/vendor';
import { TRANSLATION_MENU_MANAGEMENT } from '../../constants';
import aLevel from '../../../../client/src/store/level/actions';
import { useGlobalAppContext } from '../../GlobalAppProvider';
import { BackToMerchantLevel } from './BackToMerchantLevel';
import { SidebarGroup } from './SidebarGroup';
import { ListElements } from './ListElements';

export const SidebarItemList: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getIntlString } = useI18n();
  const {
    stores,
    selectedStoreId,
    defaultStores,
    merchant: { merchantId },
    isMerchant,
  } = useStoreService();
  const { setIsSidebarVisibleOnMobile } = useGlobalAppContext();
  const selectedStore = selectedStoreId
    ? stores.find((item) => item.storeId === selectedStoreId) || defaultStores.find((item) => item.storeId === selectedStoreId)
    : undefined;
  const isDefaultStoreSelected = isDefaultStore(selectedStore);
  const getVendorById = useVendorByStoreId(selectedStoreId ?? '', { enabled: !!selectedStoreId });
  const canAccessMerchantStore = usePermission([PermissionsV2.MERCHANT.NAVBAR_VIEW]);
  const hasAccessToHomePage = usePermission([PermissionsV2.STORE.HOME_PAGE_VIEW]);
  const showBackButton = (!isMerchant && isDefaultStoreSelected) || (!isMerchant && canAccessMerchantStore && !hasAccessToHomePage);

  const handleBackAction = React.useCallback(() => {
    dispatch(aLevel.select(merchantId, true));
    sessionStorage.setItem('selected', JSON.stringify({ storeId: merchantId, isMerchantSelected: true }));
  }, [dispatch, merchantId]);

  const handleNavLinkChange = React.useCallback(
    (navLinkName: string) => {
      if (navLinkName !== TRANSLATION_MENU_MANAGEMENT) {
        sessionStorage.removeItem(MM_FILTERS_CACHE_KEY);
        sessionStorage.removeItem(MM_SEARCH_CACHE_KEY);
      }
      setIsSidebarVisibleOnMobile(false);
    },
    [setIsSidebarVisibleOnMobile],
  );

  useEffect(() => {
    if (isDefaultStoreSelected) {
      history.push(`/dashboard/${selectedStoreId}/general/store-info`);
    }
  }, [isDefaultStoreSelected, selectedStoreId]);

  return (
    <>
      {showBackButton && (
        <li key={getIntlString('button.backToMerchant', { isRootPath: true })} className="tw-mb-5">
          <SidebarGroup title="Store Level">
            <BackToMerchantLevel onClick={handleBackAction} />
          </SidebarGroup>
        </li>
      )}
      <ListElements
        onBack={handleBackAction}
        onChangeNavLink={handleNavLinkChange}
        canAccessMerchantStore={canAccessMerchantStore}
        selectedStore={selectedStore}
        vendor={getVendorById?.data}
      />
    </>
  );
};
