import { PatchLocationGroupDto as PatchLocationGroupModel } from '@goparrot/location-group-sdk';

(PatchLocationGroupModel as any).prototype.init = function (options: Partial<PatchLocationGroupModel> = {}) {
  this.name = options.name ?? '';
  this.description = options.description ?? '';
  this.parent = options.parent;
  this.stores = options.stores ?? null;
  return this;
};

export { PatchLocationGroupModel };
