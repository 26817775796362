import { IRewriteRelationsRequest, IStoreItemProperties, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationUpdateStoreItemPropertiesParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemProperties>;
};
export type LocationUpdateStoreItemPropertiesResult = UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemPropertiesParams>;

export const useLocationStoreItemUpdateProperties = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemPropertiesParams>,
): LocationUpdateStoreItemPropertiesResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemFacade.updateProperties(merchantId, storeId, uniqueName, data),
    options,
  );
};
