import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { I18nService, useI18n } from '@goparrot-dashboard/i18n';
import { MarketCheckbox, MarketFilter, MarketInputSearch, MarketDivider, MarketList, MarketRow } from '@market/react-bindings';
import { FilterSettingsType, FilterSettingsItem, FiltersRef } from './interface';
import { MIN_FILTERS_FOR_SEARCH } from './constants';

type Props = {
  filter: FilterSettingsType;
  disabled: boolean;
  selectAllOption: (filter: FilterSettingsType) => void;
  toggleFilterOption: ({ filterToUpdate, itemToUpdate }: { filterToUpdate: FilterSettingsType; itemToUpdate: FilterSettingsItem }) => void;
  setFiltersRefs?: React.Dispatch<React.SetStateAction<FiltersRef>>;
};

const FiltersItemComponent: FC<React.PropsWithChildren<Props>> = ({ filter, disabled, selectAllOption, toggleFilterOption, setFiltersRefs }) => {
  const { getIntlString } = useI18n();
  const [expanded, setExpanded] = useState<boolean | undefined>(undefined);
  const filterRef = useRef<typeof setExpanded | null>(null);

  useEffect(() => {
    filterRef.current = setExpanded;
    setFiltersRefs && setFiltersRefs((prev) => ({ ...prev, [filter.field]: filterRef }));
    // this sholud run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterKey = useMemo(() => {
    return `${filter.field}-${filter.selectedItems.length}}-${Math.random()}`;
  }, [filter]);

  return (
    <MarketFilter
      data-testid="filter-button"
      onClick={() => setExpanded(true)}
      onMarketFilterExpandedChanged={(e) => {
        if (!e.detail) {
          setExpanded(undefined);
        }
      }}
      expanded={expanded}
      name={filter.field}
      key={filterKey}
      disabled={disabled || undefined}
    >
      <label slot="label">{filter.label}</label>
      <MarketList value={filter.selectedItems.map((item) => String(item.value)).join(',')} multiselect interactive>
        {filter.items.length >= MIN_FILTERS_FOR_SEARCH && <MarketInputSearch slot="search"></MarketInputSearch>}
        <MarketRow data-testid="filter-option" slot="control-row" value="all" onClick={() => selectAllOption(filter)}>
          <label slot="label">{filter.selectAllLabel ?? getIntlString('selectAll')}</label>
          <MarketCheckbox slot="control"></MarketCheckbox>
        </MarketRow>
        {filter.items.map((item) => {
          return (
            <React.Fragment key={`${item.value}`}>
              <MarketRow
                data-testid="filter-option"
                value={String(item.value)}
                onClick={() => toggleFilterOption({ filterToUpdate: filter, itemToUpdate: item })}
              >
                <label slot="label">{item.label}</label>
                <MarketCheckbox slot="control"></MarketCheckbox>
              </MarketRow>
              {item.hasSeparator && <MarketDivider className="tw-mb-0" />}
            </React.Fragment>
          );
        })}
      </MarketList>
    </MarketFilter>
  );
};

export const FilterItem: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <I18nService prefix="menu.list.filters">
    <FiltersItemComponent {...props} />
  </I18nService>
);
