import { IUpdateRelationsResponse, IItemOptionsMetadataSelections, IRewriteRelationsRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type LocationUpdateItemOptionSelectionsResults = UseMutationResult<IUpdateRelationsResponse, unknown, locationUpdateItemOptionSelectionsParams, unknown>;

export type locationUpdateItemOptionSelectionsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IItemOptionsMetadataSelections>;
};

export const useLocationUpdateItemOptionSelections = (
  options: UseMutationOptions<IUpdateRelationsResponse, unknown, locationUpdateItemOptionSelectionsParams, unknown>,
): LocationUpdateItemOptionSelectionsResults => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemOptionsFacade.updateSelections(merchantId, storeId, uniqueName, data),
    options,
  );
};
