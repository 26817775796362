import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemOptionsFacade } from '../../facades';

type ItemOptionsGetParams = { merchantId: string; uniqueName: string };

export const useMerchantItemOptionsGet = (
  { merchantId, uniqueName }: ItemOptionsGetParams,
  options?: UseQueryOptions<ReadItemOptionsDto>,
): UseQueryResult<ReadItemOptionsDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_OPTION_GET, merchantId, uniqueName],
    async () => await merchantItemOptionsFacade.get(merchantId, uniqueName),
    options,
  );
};
