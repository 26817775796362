import { IRewriteRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationUpdateCategoriesStoreItemParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<string>;
};

export const useLocationUpdateCategoriesStoreItem = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateCategoriesStoreItemParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateCategoriesStoreItemParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemFacade.updateStoreItemCategories(merchantId, storeId, uniqueName, data),
    options,
  );
};
