import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, Select } from '@goparrot-dashboard/shared-ui';
import { currencyOptions } from '@goparrot-dashboard/shared-utils';
import { SupportedCurrencyEnum } from '@goparrot/common';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

export const ItemCurrency = <T extends { currency: SupportedCurrencyEnum }>(): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);

  return (
    <FormField
      {...bindFormFieldProps('currency')}
      label={getMenuFormIntlString('currency')}
      withValuePadding={false}
      input={<Select {...bindInputProps('currency')} isSearchable={false} options={currencyOptions} />}
    />
  );
};
