export enum MENU_MANAGEMENT_QUERIES_ENUM {
  MERCHANT_ITEM_SETS_LIST = 'MERCHANT_ITEM_SETS_LIST',
  MERCHANT_ITEM_SET_GET = 'MERCHANT_ITEM_SET_GET',
  MERCHANT_ITEM_SET_CHILDREN_LIST = 'MERCHANT_ITEM_SET_CHILDREN_LIST',
  MERCHANT_ITEM_OPTION_CHILDREN_LIST = 'MERCHANT_ITEM_OPTION_CHILDREN_LIST',
  MERCHANT_ITEM_OPTION_PARENTS_LIST = 'MERCHANT_ITEM_OPTION_PARENTS_LIST',
  MERCHANT_ITEM_OPTION_LIST = 'MERCHANT_ITEM_OPTION_LIST',
  MERCHANT_ITEM_OPTION_GET = 'MERCHANT_ITEM_OPTION_GET',
  MERCHANT_MENU_CONCEPTS_LIST = 'MERCHANT_MENU_CONCEPTS_LIST',
  MERCHANT_MENU_CONCEPT_GET = 'MERCHANT_MENU_CONCEPT_GET',
  MERCHANT_COMBO_GROUP_LIST = 'MERCHANT_COMBO_GROUP_LIST',
  MERCHANT_COMBO_GROUP_GET = 'MERCHANT_COMBO_GROUP_GET',
  MERCHANT_COMBO_GROUP_GET_PARENTS = 'MERCHANT_COMBO_GROUP_GET_PARENTS',
  MERCHANT_COMBO_GROUP_GET_CHILDREN = 'MERCHANT_COMBO_GROUP_GET_CHILDREN',
  MERCHANT_COMBOS_LIST = 'MERCHANT_COMBOS_LIST',
  MERCHANT_COMBOS_GET = 'MERCHANT_COMBOS_GET',
  MERCHANT_COMBOS_GET_PARENTS = 'MERCHANT_COMBOS_GET_PARENTS',
  MERCHANT_COMBOS_GET_CHILDREN = 'MERCHANT_COMBOS_GET_CHILDREN',
  MERCHANT_CATEGORY_GET = 'MERCHANT_CATEGORY_GET',
  MERCHANT_CATEGORY_GET_CHILDREN = 'MERCHANT_CATEGORY_GET_CHILDREN',
  MERCHANT_CATEGORY_GET_PARENTS = 'MERCHANT_CATEGORY_GET_PARENTS',
  MERCHANT_STORE_ITEM_LIST = 'MERCHANT_STORE_ITEM_LIST',
  MERCHANT_STORE_ITEM_GROUPS_GET = 'MERCHANT_STORE_ITEM_GROUPS_GET',
  MERCHANT_STORE_ITEM_GET = 'MERCHANT_STORE_ITEM_GET',
  MERCHANT_STORE_ITEM_GET_CHILDREN = 'MERCHANT_STORE_ITEM_GET_CHILDREN',
  MERCHANT_STORE_ITEM_GET_PARENTS = 'MERCHANT_STORE_ITEM_GET_PARENTS',
  MERCHANT_STORE_ITEM_GROUP_GET = 'MERCHANT_STORE_ITEM_GROUP_GET',
  MERCHANT_STORE_ITEM_GROUP_GET_PARENTS = 'MERCHANT_STORE_ITEM_GROUP_GET_PARENTS',
  MERCHANT_STORE_ITEM_GROUP_GET_CHILDREN = 'MERCHANT_STORE_ITEM_GROUP_GET_CHILDREN',
  MERCHANT_STORE_ITEM_GROUP_GET_ITEM_OPTIONS = 'MERCHANT_STORE_ITEM_GROUP_GET_ITEM_OPTIONS',
  MERCHANT_STORE_ITEM_GROUP_LIST = 'MERCHANT_STORE_ITEM_GROUP_LIST',
  MERCHANT_ITEM_BASE_LIST = 'MERCHANT_ITEM_BASE_LIST',
  MERCHANT_ITEM_BASE_GET_PARENTS = 'MERCHANT_ITEM_BASE_GET_PARENTS',
  MERCHANT_ITEM_BASE_GET = 'MERCHANT_ITEM_BASE_GET',
  MERCHANT_ITEM_BASE_GROUP_GET_PARENTS = 'MERCHANT_ITEM_BASE_GROUP_GET_PARENTS',
  MERCHANT_ITEM_BASE_GROUP_GET_CHILDREN = 'MERCHANT_ITEM_BASE_GROUP_GET_CHILDREN',
  MERCHANT_ITEM_BASE_GROUP_GET = 'MERCHANT_ITEM_BASE_GROUP_GET',
  MERCHANT_GET_SELECTION_TYPE = 'MERCHANT_GET_SELECTION_TYPE',

  LOCATION_ITEM_SETS_LIST = 'LOCATION_ITEM_SETS_LIST',
  LOCATION_ITEM_SET_GET = 'LOCATION_ITEM_SET_GET',
  LOCATION_ITEM_SET_CHILDREN_LIST = 'LOCATION_ITEM_SET_CHILDREN_LIST',
  LOCATION_ITEM_OPTION_CHILDREN_LIST = 'LOCATION_ITEM_OPTION_CHILDREN_LIST',
  LOCATION_ITEM_OPTION_PARENTS_LIST = 'LOCATION_ITEM_OPTION_PARENTS_LIST',
  LOCATION_ITEM_OPTION_LIST = 'LOCATION_ITEM_OPTION_LIST',
  LOCATION_ITEM_OPTION_GET = 'LOCATION_ITEM_OPTION_GET',
  LOCATION_MENU_CONCEPTS_LIST = 'LOCATION_MENU_CONCEPTS_LIST',
  LOCATION_MENU_CONCEPT_GET = 'LOCATION_MENU_CONCEPT_GET',
  LOCATION_COMBO_GROUP_LIST = 'LOCATION_COMBO_GROUP_LIST',
  LOCATION_COMBO_GROUP_GET = 'LOCATION_COMBO_GROUP_GET',
  LOCATION_COMBO_GROUP_GET_PARENTS = 'LOCATION_COMBO_GROUP_GET_PARENTS',
  LOCATION_COMBO_GROUP_GET_CHILDREN = 'LOCATION_COMBO_GROUP_GET_CHILDREN',
  LOCATION_COMBOS_LIST = 'LOCATION_COMBOS_LIST',
  LOCATION_COMBOS_GET = 'LOCATION_COMBOS_GET',
  LOCATION_COMBOS_GET_PARENTS = 'LOCATION_COMBOS_GET_PARENTS',
  LOCATION_COMBOS_GET_CHILDREN = 'LOCATION_COMBOS_GET_CHILDREN',
  LOCATION_CATEGORY_GET = 'LOCATION_CATEGORY_GET',
  LOCATION_CATEGORY_GET_PARENTS = 'LOCATION_CATEGORY_GET_PARENTS',
  LOCATION_CATEGORY_GET_CHILDREN = 'LOCATION_CATEGORY_GET_CHILDREN',
  LOCATION_STORE_ITEM_LIST = 'LOCATION_STORE_ITEM_LIST',
  LOCATION_STORE_ITEM_GET = 'LOCATION_STORE_ITEM_GET',
  LOCATION_STORE_ITEM_REPLICAS_GET = 'LOCATION_STORE_ITEM_REPLICAS_GET',
  LOCATION_STORE_ITEM_GROUPS_GET = 'LOCATION_STORE_ITEM_GROUPS_GET',
  LOCATION_STORE_ITEM_GET_CHILDREN = 'LOCATION_STORE_ITEM_GET_CHILDREN',
  LOCATION_STORE_ITEM_GET_PARENTS = 'LOCATION_STORE_ITEM_GET_PARENTS',
  LOCATION_STORE_ITEM_GROUP_GET = 'LOCATION_STORE_ITEM_GROUP_GET',
  LOCATION_STORE_ITEM_GROUP_GET_PARENTS = 'LOCATION_STORE_ITEM_GROUP_GET_PARENTS',
  LOCATION_STORE_ITEM_GROUP_GET_CHILDREN = 'LOCATION_STORE_ITEM_GROUP_GET_CHILDREN',
  LOCATION_STORE_ITEM_GROUP_GET_ITEM_OPTIONS = 'LOCATION_STORE_ITEM_GROUP_GET_ITEM_OPTIONS',
  LOCATION_STORE_ITEM_GROUP_LIST = 'LOCATION_STORE_ITEM_GROUP_LIST',
  LOCATION_ITEM_BASE_LIST = 'LOCATION_ITEM_BASE_LIST',
  LOCATION_ITEM_BASE_GET_PARENTS = 'LOCATION_ITEM_BASE_GET_PARENTS',
  LOCATION_ITEM_BASE_GET = 'LOCATION_ITEM_BASE_GET',
  LOCATION_ITEM_BASE_GROUP_LIST = 'LOCATION_ITEM_BASE_GROUP_LIST',
  LOCATION_ITEM_BASE_GROUP_GET_PARENTS = 'LOCATION_ITEM_BASE_GROUP_GET_PARENTS',
  LOCATION_ITEM_BASE_GROUP_GET_CHILDREN = 'LOCATION_ITEM_BASE_GROUP_GET_CHILDREN',
  LOCATION_ITEM_BASE_GROUP_GET = 'LOCATION_ITEM_BASE_GROUP_GET',
  LOCATION_GET_SELECTION_TYPE = 'LOCATION_GET_SELECTION_TYPE',

  STORES_BY_MERCHANT_LIST = 'STORES_BY_MERCHANT_LIST',
  STORE_GET = 'STORE_GET',

  CENTRAL_LOCATION_ITEM_BY_REPLICA_GET = 'CENTRAL_LOCATION_ITEM_BY_REPLICA_GET',
  MENU_SYNC_CONFIG_LIST = 'MENU_SYNC_CONFIG_LIST',
}
