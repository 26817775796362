import React, { FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { ISearchItemResult, ISearchTaxResult } from '@goparrot/menu-sync-sdk';
import { TAX } from '../../constants';

type CardPropsType = {
  item: ISearchItemResult | ISearchTaxResult;
  itemType: StoreItemTypeEnum | typeof TAX;
};

export const Card: FC<React.PropsWithChildren<CardPropsType>> = ({ item, itemType }) => {
  const price =
    0 <= (item as ISearchItemResult)?.price && (item as ISearchItemResult)?.currency
      ? `${(item as ISearchItemResult)?.price} ${(item as ISearchItemResult)?.currency}`
      : null;
  const percentage = (item as ISearchTaxResult)?.percentage && `${(item as ISearchTaxResult).percentage} %`;
  const parents = (item as ISearchItemResult)?.parentTitles?.length ? `${(item as ISearchItemResult)?.parentTitles.join(' / ')}` : 'N/A';
  return (
    <div className="tw-min-h-16 tw-w-full tw-py-2.5 tw-px-5 tw-flex tw-flex-col tw-bg-white tw-border-2 tw-border-gray-200 tw-rounded">
      <div className="tw-text-blue-gray-900 tw-border-b-2 tw-border-gray-200 tw-pb-1.5 tw-mb-1.5 tw-text-base tw-font-semibold">{item?.guid}</div>
      <div className="tw-flex tw-justify-between">
        <div className="tw-flex tw-flex-col tw-max-w-full">
          <span className="tw-text-sm tw-text-blue-gray-900 tw-mb-1.5">{(item as ISearchItemResult)?.title ?? (item as ISearchTaxResult)?.name}</span>
          {TAX !== itemType && (
            <span className="tw-text-xs tw-text-blue-gray-700 tw-mb-1.5">
              {getIntlString('page.merchant.menu.form.posGuid.parents')}: {parents || 'N/A'}
            </span>
          )}
        </div>
        <div className="tw-flex tw-items-center tw-text-blue-gray-700">{price ?? percentage}</div>
      </div>
    </div>
  );
};
