import type { UpdateRelationsResponseDto, IUpdateRelationsRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemSetFacade } from '../../../facades';

type MerchantUpdateItemSetCategoriesParams = {
  merchantId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest;
};

export const useMerchantUpdateItemSetCategories = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateItemSetCategoriesParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateItemSetCategoriesParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemSetFacade.updateCategories(merchantId, uniqueName, data), options);
};
