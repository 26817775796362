import type { IReadStoreDto } from '@goparrot/store-v2-sdk';
import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { components, ControlProps, IndicatorProps, PlaceholderProps, createFilter, SingleValueProps } from 'react-select';
import { StoreTypeEnum } from '@goparrot/store-v2-sdk';
import { getReactNodeTextContent, getStoreTime } from '@goparrot-dashboard/shared-utils';
import { Select, OutlineSelectComponents, IconCentralLocation } from '@goparrot-dashboard/shared-ui';
import type { SelectOptionType as BaseSelectOptionType, SelectValueType } from '@goparrot-dashboard/shared-ui/src/components/select/types';

type SelectOptionType = BaseSelectOptionType & {
  value: IReadStoreDto;
};

const DropdownIndicator: FC<React.PropsWithChildren<IndicatorProps<SelectOptionType, true | false>>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg className="tw-text-gray-700" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" d="M1 1L4.49138 4L7.75 1" stroke="currentColor" strokeWidth="1.5" />
      </svg>
    </components.DropdownIndicator>
  );
};

const SingleValue: FC<React.PropsWithChildren<SingleValueProps<SelectOptionType>>> = ({ children, ...props }) => (
  <components.SingleValue {...props} className="tw-text-blue-gray-900 tw-h-full tw-flex tw-items-center">
    {children}
  </components.SingleValue>
);

const Control: FC<React.PropsWithChildren<ControlProps<SelectOptionType, true | false>>> = ({ children, isFocused, isDisabled, ...props }) => {
  return (
    <components.Control
      {...props}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={classNames(['tw-w-full tw-shadow-none tw-h-2 tw-border-0 tw-bg-cool-gray-100'])}
    >
      {children}
    </components.Control>
  );
};

const Placeholder: FC<React.PropsWithChildren<PlaceholderProps<SelectOptionType, true | false>>> = (props) => (
  <components.Placeholder {...props} className="tw-text-cool-gray-700" />
);

export type NavBarSelectProps = {
  stores: IReadStoreDto[];
  onChange(value: SelectValueType): void;
  placeholder?: string;
  centralLocationStoreId?: string;
  currentTime: string;
};

const storeTypeStyle = 'tw-w-5 tw-inline-block tw-text-center tw-font-normal tw-mr-1.5 tw-rounded-sm tw-text-white';
const uniqueId = 'select_' + Math.random().toFixed(5).slice(2);

export const NavBarSelect: FC<React.PropsWithChildren<NavBarSelectProps>> = ({ stores, onChange, placeholder, centralLocationStoreId, currentTime }) => {
  const { merchant, selectedStoreId } = useStoreService();

  const options = useMemo<BaseSelectOptionType[]>(
    () =>
      stores.map((store) => {
        const isCentralLocation = centralLocationStoreId === store.storeId && merchant?.featuresFlags?.multiEntityType !== undefined;

        return {
          label: (
            <div className="tw-flex tw-items-center tw-whitespace-pre-wrap tw-overflow-ellipsis tw-overflow-hidden">
              {(store.type as string) === StoreTypeEnum.MERCHANT && <span className={classNames([storeTypeStyle, 'tw-bg-light-blue-400'])}>M</span>}
              {(store.type as string) === StoreTypeEnum.STORE && (
                <span className={isCentralLocation ? 'tw-flex tw-mr-1.5' : classNames([storeTypeStyle, 'tw-bg-green-400'])}>
                  {isCentralLocation ? (
                    <span className="tw-flex">
                      <IconCentralLocation />
                    </span>
                  ) : (
                    'S'
                  )}
                </span>
              )}
              {(store.type as string) === StoreTypeEnum.DEFAULT && <span className={classNames([storeTypeStyle, 'tw-bg-blue-500'])}>D</span>}
              <div className="tw-flex tw-items-baseline tw-space-x-2">
                <div className="tw-block tw-leading-5 tw-flex-1 tw-text-sm tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap">
                  {store.title}
                  {isCentralLocation && getIntlString('topbar.centralLocation')}
                </div>
                <div className="tw-leading-4 tw-text-cool-gray-400 tw-text-xs">{getStoreTime(store, currentTime)}</div>
              </div>
            </div>
          ),
          value: store.storeId,
        };
      }),
    [stores, centralLocationStoreId, merchant?.featuresFlags?.multiEntityType, currentTime],
  );

  const handleMenuClose = () => {
    const menuEl = document.querySelector(`#${uniqueId} .menu`);
    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true);

    if (!clonedMenuEl) return; // safeguard

    (clonedMenuEl as Element).classList.add('menu--close');
    clonedMenuEl.addEventListener('animationend', () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl!);
  };

  const value = useMemo(() => (options.length > 0 ? (selectedStoreId ? selectedStoreId : merchant.merchantId) : ''), [
    merchant.merchantId,
    options.length,
    selectedStoreId,
  ]);

  return (
    <Select
      overrideComponents={{
        ...OutlineSelectComponents,
        Control,
        Placeholder,
        DropdownIndicator,
        SingleValue,
      }}
      menuPosition="absolute"
      id={uniqueId}
      onMenuClose={handleMenuClose}
      defaultValue={options[2]}
      options={options}
      value={value}
      onChange={onChange}
      filterOption={createFilter({ stringify: (option) => `${getReactNodeTextContent(option.label)} ${option.value}` })}
      placeholder={placeholder}
      className={classNames(['tw-flex-1 tw-w-77 lg:tw-w-110 tw-font-medium tw-text-gray-700'])}
    />
  );
};
