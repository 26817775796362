import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
  fetched: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDER__CUSTOMER__GET__REQUEST:
      return assign(state, { fetching: true });

    case a.API__ORDER__CUSTOMER__GET__SUCCESS:
      return assign(initialState, { data: action.payload, fetched: true });

    case a.API__ORDER__CUSTOMER__GET__ERROR:
      return assign(initialState, { fetching: false, fetched: true });

    case a.LOCAL__ORDER__RESET__SELECTED:
      return initialState;

    default:
      return state;
  }
}
