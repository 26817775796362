import { IReadStoreDto, IReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';

export const getLocationOptions = (
  isFranchiseSelect: boolean,
  sortedLocations: (IReadStoreDto | IReadDefaultStoreDto)[],
  squareVendorList: ReadVendorDto[] | undefined,
  posAuthorizedStores: Record<string, ReadPaymentStoreDto>,
) => {
  if (isFranchiseSelect) {
    return sortedLocations.map((item) => {
      const defaultValue = {
        value: item.storeId,
        label: item.title,
      };

      const isSquareProvider = squareVendorList?.some((vendor) => vendor.storeId === item.storeId);
      if (!isSquareProvider) {
        return defaultValue;
      }

      const authStore = posAuthorizedStores?.[item.storeId];
      const isPosAuthorized = authStore?.isSquarePaymentStore() && !!authStore.metadata.locationId;

      return {
        value: item.storeId,
        label: isPosAuthorized ? item.title : `${item.title} - (${getIntlString('page.locations.columns.posAuthorization.isNotAuthorized')})`,
        disabled: !isPosAuthorized,
      };
    });
  }
  return sortedLocations.map((item) => ({
    value: item.storeId,
    label: item.title,
  }));
};
