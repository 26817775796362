import { ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { merchantStoreItemGroupFacade } from '../../../facades';
import { GetRelativesQueryBaseParams } from '../../../interface';

export const useMerchantStoreItemGroupGetQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadStoreItemGroupDto>,
): UseQueryResult<ReadStoreItemGroupDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GROUP_GET, merchantId, uniqueName],
    async () => await merchantStoreItemGroupFacade.get(merchantId, uniqueName),
    options,
  );
};
