import React, { FC } from 'react';

export type InputLabeledProps = {
  label: string | React.ReactNode;
  input: React.ReactNode;
  rightBlock?: React.ReactNode;
};

export const InputLabeled: FC<React.PropsWithChildren<InputLabeledProps>> = ({ label, input, rightBlock, children }) => (
  <div className="tw-mb-2">
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-items-center">
        {input}
        <div className="tw-mx-2">
          <label className="tw-text-sm tw-leading-4 tw-text-cool-gray-900">{label}</label>
        </div>
      </div>
      {rightBlock && <div>{rightBlock}</div>}
    </div>
    {children}
  </div>
);
