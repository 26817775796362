import { useDialogState } from 'reakit';
import { ConfirmModal, Select, FormField, Spinner, PanelTitle } from '@goparrot-dashboard/shared-ui';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useDispatch } from 'react-redux';
import { useGetVendorList } from '@goparrot-dashboard-api/vendor';
import { VendorProviderEnum } from '@goparrot/common';

import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import franchiseActions from '../../../../client/src/store/franchise/actions';
import { useMenuOrchestrationContext } from '../../services';
import { useCentralLocation, useLinkCentralMenu, usePosAuthorizedStores } from '../../hooks';

type CentralMenuLocationProps = {
  disabled?: boolean;
};

export const CentralMenuLocation: FC<CentralMenuLocationProps> = ({ disabled = false }) => {
  const dispatch = useDispatch();
  const {
    merchant: { merchantId },
    stores,
  } = useStoreService();

  const { fetchLocations } = useMenuOrchestrationContext();
  const { getIntlString } = useI18n();
  // fetch central menu location
  const { centralLocation, refetch, loading } = useCentralLocation(merchantId, stores);
  const confirmDialog = useDialogState({ animated: true });
  // create link central menu location
  const onSuccessLinkLocation = useCallback(() => {
    refetch();
    fetchLocations();
    setConfirmedCentralMenuLocation(false);
    confirmDialog.hide();
    dispatch(franchiseActions.requestLocationsListGet({ merchantId }));
  }, [dispatch, merchantId, fetchLocations, refetch, confirmDialog]);
  const [confirmedCentralMenuLocation, setConfirmedCentralMenuLocation] = useState<boolean>(false);
  const [centralMenuLocationCandidate, setCentralMenuLocationCandidate] = useState<string | null>(null);
  const linkQuery = useLinkCentralMenu({
    merchantId,
    storeId: centralMenuLocationCandidate,
    onSuccess: onSuccessLinkLocation,
    enabled: confirmedCentralMenuLocation,
  });

  const confirmSetupCentralLocation = (storeId: string) => {
    setCentralMenuLocationCandidate(storeId);
    confirmDialog.show();
  };

  const dismissCentralMenuLocation = () => {
    confirmDialog.hide();
    setTimeout(() => setCentralMenuLocationCandidate(null), 200);
  };

  const vendorList = useGetVendorList({ merchantId }, { retry: 3 });
  const posAuthorizedStoresQuery = usePosAuthorizedStores();

  const posAuthorizedStores = useMemo(() => {
    if (posAuthorizedStoresQuery.data) {
      return posAuthorizedStoresQuery.data.reduce<Record<string, ReadPaymentStoreDto>>((acc, item) => {
        acc[item.storeId] = item;

        return acc;
      }, {});
    }
    return {};
  }, [posAuthorizedStoresQuery]);

  const squareVendorList = React.useMemo(
    () =>
      vendorList.data?.rows?.filter((vendor) => vendor.provider === VendorProviderEnum.SQUARE && stores.some((item) => item.storeId === vendor.storeId)) || [],
    [stores, vendorList.data?.rows],
  );

  const options = useMemo(() => {
    return stores.map((item) => {
      const defaultValue = {
        value: item.storeId,
        label: item.title,
      };

      const isSquareProvider = squareVendorList.some((vendor) => vendor.storeId === item.storeId);
      if (!isSquareProvider) {
        return defaultValue;
      }

      const authStore = posAuthorizedStores[item.storeId];
      const isPosAuthorized = authStore?.isSquarePaymentStore() && !!authStore.metadata.locationId;

      return {
        value: item.storeId,
        label: isPosAuthorized
          ? item.title
          : `${item.title} - (${getIntlString('page.locations.columns.posAuthorization.isNotAuthorized', { isRootPath: true })})`,
        disabled: !isPosAuthorized,
      };
    });
  }, [getIntlString, posAuthorizedStores, squareVendorList, stores]);

  return (
    <Spinner spinning={loading}>
      <PanelTitle hasMargin={false}>{getIntlString('page.merchant.orchestration.virtual.central.title', { isRootPath: true })}</PanelTitle>
      <div className="tw-mb-4">{getIntlString('page.merchant.orchestration.virtual.central.subtitle', { isRootPath: true })}</div>
      <div>
        <Spinner spinning={posAuthorizedStoresQuery.isLoading || vendorList.isLoading}>
          <FormField
            withValuePadding={false}
            disabled={disabled || !!centralLocation?.franchiseLocation?.linkedStoreId}
            label={getIntlString('page.merchant.orchestration.central-menu.selectLabel', { isRootPath: true })}
            input={
              <Select
                variant="base"
                disabled={disabled || !!centralLocation?.franchiseLocation?.linkedStoreId}
                placeholder={getIntlString('page.merchant.orchestration.central.selectPlaceholder', { isRootPath: true })}
                value={centralMenuLocationCandidate || centralLocation?.franchiseLocation?.linkedStoreId}
                onChange={confirmSetupCentralLocation}
                options={options}
                isOptionDisabled={(option) => !!(option as { disabled?: boolean })?.disabled}
              />
            }
          />
        </Spinner>
      </div>
      {centralMenuLocationCandidate && (
        <ConfirmModal
          dialog={confirmDialog}
          confirmDisabled={linkQuery.isLoading}
          header={getIntlString('page.merchant.orchestration.central-menu.selectModalTitle', { isRootPath: true })}
          content={
            <div>
              <p className="tw-text-lg tw-text-cool-gray-900">
                {getIntlString('page.merchant.orchestration.central-menu.selectModalChoosedLocation', { isRootPath: true })}{' '}
                <b>{stores.find((l) => l.storeId === centralMenuLocationCandidate)?.name}</b>.<br />
                {getIntlString('page.merchant.orchestration.central.selectModalConfirmation', { isRootPath: true })}
              </p>
            </div>
          }
          onConfirm={() => {
            setConfirmedCentralMenuLocation(true);
          }}
          onCancel={dismissCentralMenuLocation}
          confirmText={getIntlString('page.merchant.orchestration.central.selectModalYes', { isRootPath: true })}
          cancelText={getIntlString('page.merchant.orchestration.central.selectModalNo', { isRootPath: true })}
        />
      )}
    </Spinner>
  );
};
