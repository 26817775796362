import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { CreateLocationGroupDto, ReadLocationGroupDto } from '@goparrot/location-group-sdk';
import { locationGroupFacade } from '../facades';

export type CreateLocationGroupParams = {
  merchantId: string;
  locationGroup: CreateLocationGroupDto;
};

export const useCreateLocationGroup = (
  options?: UseMutationOptions<ReadLocationGroupDto, unknown, CreateLocationGroupParams, unknown>,
): UseMutationResult<ReadLocationGroupDto, unknown, CreateLocationGroupParams, unknown> => {
  return useMutation(({ merchantId, locationGroup }) => locationGroupFacade.create(merchantId, locationGroup), options);
};
