import { ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type MerchantStoreItemGetGroupsParams = { merchantId: string; uniqueName: string };

export const useMerchantStoreItemGroupsGet = (
  { merchantId, uniqueName }: MerchantStoreItemGetGroupsParams,
  options?: UseQueryOptions<ReadStoreItemGroupDto[]>,
): UseQueryResult<ReadStoreItemGroupDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GROUPS_GET, merchantId, uniqueName],
    async () => await merchantStoreItemFacade.getStoreItemGroups(merchantId, uniqueName),
    options,
  );
};
