import {
  CreateGiftCardSettlementConfigParams,
  UpdateGiftCardSettlementConfigParams,
  useCreateGiftCardSettlementConfig,
  useUpdateGiftCardSettlementConfig,
} from '@goparrot-dashboard-api/orchestration';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { ReadSettlementConfigurationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { UseMutationResult } from 'react-query';

export const useSettlementConfigMutation = ({
  onSuccess,
  hasSettlementConfiguration,
}: {
  onSuccess(config: ReadSettlementConfigurationDto): void;
  hasSettlementConfiguration: boolean;
}): {
  mutationIsLoading: boolean;
  settlementConfigMutation: UseMutationResult<
    ReadSettlementConfigurationDto,
    unknown,
    UpdateGiftCardSettlementConfigParams | CreateGiftCardSettlementConfigParams,
    unknown
  >;
} => {
  const { notifyError } = useNotifications();
  const { getIntlString } = useI18n();
  const onError = () => notifyError({ text: getIntlString('giftCards.pooling.notification.update.error') });
  const updateSettlementConfig = useUpdateGiftCardSettlementConfig({
    onSuccess,
    onError,
  });
  const createSettlementConfig = useCreateGiftCardSettlementConfig({
    onSuccess,
    onError,
  });

  return {
    settlementConfigMutation: hasSettlementConfiguration ? updateSettlementConfig : createSettlementConfig,
    mutationIsLoading: updateSettlementConfig.isLoading || createSettlementConfig.isLoading,
  };
};
