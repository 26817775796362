import { CreateItemSetDto, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemSetFacade } from '../../../facades';

export type MerchantCreateItemSetParams = {
  merchantId: string;
  data: CreateItemSetDto;
};

export const useMerchantCreateItemSet = (
  options: UseMutationOptions<ReadItemSetDto, unknown, MerchantCreateItemSetParams>,
): UseMutationResult<ReadItemSetDto, unknown, MerchantCreateItemSetParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantItemSetFacade.create(merchantId, data), options);
};
