import React from 'react';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { isStoreCentralLocation } from '../utils';
import { MM_DEFAULT_FILTERS, IS_REPLICA_FILTER } from '../constants';

export const useListInitialFilters = () => {
  const {
    selectedStoreId,
    merchant: { featuresFlags },
    isMerchant,
    franchiseLocations,
  } = useStoreService();
  const isCentralLocation = isStoreCentralLocation(franchiseLocations, selectedStoreId) || featuresFlags?.multiEntityType !== MultiEntityTypeEnum.FRANCHISE;

  return React.useMemo(() => [...MM_DEFAULT_FILTERS, ...(!isMerchant && !isCentralLocation ? [IS_REPLICA_FILTER] : [])], [isCentralLocation, isMerchant]);
};
