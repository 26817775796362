import React, { FC, useMemo, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { IconArrowDownThin } from '@goparrot-dashboard/shared-ui';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { MenuItem } from '../../interface';

type SidebarGroupProps = {
  title: string;
  hideDropdownEffect?: boolean;
  className?: string;
  menuItems?: MenuItem[];
};

export const SidebarGroup: FC<React.PropsWithChildren<SidebarGroupProps>> = ({ children, title, menuItems, hideDropdownEffect = false, className }) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();

  const isMenuItemSelected = useMemo(() => {
    const dashboardPath = history.location.pathname.split('/')[2];
    return menuItems?.some((subItem) => subItem.link.split('/')[2] === dashboardPath) ?? false;
  }, [history.location.pathname, menuItems]);

  const onTriggerDropdownEffect = () => {
    !hideDropdownEffect ? setIsOpen(!isOpen) : null;
  };

  return (
    <div className={className}>
      <div
        data-testid={`${title.replace(/ /, '-').toLowerCase()}-sidebar`}
        onClick={onTriggerDropdownEffect}
        className={classNames(['tw-cursor-pointer semibold-30 tw-pl-1.5 tw-tracking-tight tw-mb-2', isMenuItemSelected && 'tw-text-blue-500'])}
      >
        {title}
        {!hideDropdownEffect && (
          <IconArrowDownThin className={classNames(['tw-ml-1.5 tw-mb-0.5 tw-transition-all tw-ease-in-out tw-transform', !isOpen && 'tw--rotate-90'])} />
        )}
      </div>
      <CSSTransition
        timeout={200}
        in={isOpen}
        unmountOnExit
        appear
        classNames={{
          enter: 'tw-max-h-0 tw-overflow-hidden tw-transition-all tw-ease-in-out',
          enterActive: 'tw-max-h-96 tw-overflow-hidden tw-transition-all tw-ease-in-out',
          enterDone: 'tw-max-h-96 tw-overflow-hidden tw-transition-all tw-ease-in-out',
          exit: 'tw-max-h-0 tw-overflow-hidden tw-transition-all tw-ease-in-out',
          exitActive: 'tw-max-h-0 tw-overflow-hidden tw-transition-all tw-ease-in-out',
          exitDone: 'tw-max-h-0 tw-overflow-hidden tw-transition-all tw-ease-in-out',
        }}
      >
        <div>{children}</div>
      </CSSTransition>
    </div>
  );
};
