import { ChangeEvent } from 'react';

import { getValueTransformers } from '../../utils/valueTransformers';
import { EntityFormResult, BaseInputBindingsOptions, InputBindingsMethod } from '../../types';

export type UseNativeInputPropsResult = {
  value: any;
  disabled: boolean;
  onChange(event: ChangeEvent<any>): void;
  onBlur(): void;
  hasError: boolean;
};

export type UseNativeInputPropsOptions = BaseInputBindingsOptions & {
  // trim field value on blur event. Default: true
  trimOnBlur?: boolean;
};

/**
 * @description Use this hook only when `onChange` is called with an event
 */
export function useNativeInputProps<T>(
  hook: EntityFormResult<T>,
  options?: UseNativeInputPropsOptions,
): InputBindingsMethod<T, BaseInputBindingsOptions, UseNativeInputPropsResult> {
  const useNativeInputPropsForPath: InputBindingsMethod<T, BaseInputBindingsOptions, UseNativeInputPropsResult> = (path, inputOptions) => {
    const valueTransformers = getValueTransformers<T>(hook, inputOptions ?? options);
    return {
      // '' (empty string) is required to avoid input being uncontrolled
      value: valueTransformers.toInput(path) ?? '',
      hasError: hook.isDirtyField(path) && hook.hasError(path),
      onChange: (event: ChangeEvent<{ value: any }>) => valueTransformers.toState(path, event.target.value),
      onBlur: () => {
        if (options?.trimOnBlur ?? true) {
          const fieldValue = hook.getFieldValue(path);
          if (typeof fieldValue === 'string' && fieldValue !== fieldValue.trim()) {
            hook.setFieldValue(path, fieldValue.trim());
          }
        }
        hook.onFieldBlur(path);
      },
      disabled: hook.isDisabledField(path),
    };
  };

  return useNativeInputPropsForPath;
}
