import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: [],
  error: false,
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__GET__TAX__FROM__POS__BY__QUERY__REQUEST:
      return assign(state, { fetching: true, error: false });

    case a.API__GET__TAX__FROM__POS__BY__QUERY__SUCCESS:
      return assign(state, {
        data: action.payload.data,
        fetching: false,
      });

    case a.API__GET__TAX__FROM__POS__BY__QUERY__ERROR:
      return assign(state, { error: true, fetching: false });

    default:
      return state;
  }
}
