import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { StoreItemGroupListResponseDto, IStoreFilterParams } from '@goparrot/storeitems-sdk';
import { locationStoreItemGroupFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { ListQueryBaseParams } from '../../../interface';

type Params = ListQueryBaseParams<IStoreFilterParams> & {
  storeId: string;
};

export const useLocationGetStoreItemGroupListQuery = (
  { merchantId, storeId, pagination, params }: Params,
  options?: UseQueryOptions<StoreItemGroupListResponseDto>,
): UseQueryResult<StoreItemGroupListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GROUP_LIST, merchantId, storeId, pagination, params],
    () => locationStoreItemGroupFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
