import { IStoreItemPropertiesItemBaseOverrides, IStoreItemProperties, IStoreItemGroupModifierGroup } from '@goparrot/storeitems-sdk';

export enum OverrideFieldToUpdate {
  SELECTED = 'SELECTED',
  HIDDEN = 'HIDDEN',
}

export type SubmitParams = {
  newOverrides: IStoreItemPropertiesItemBaseOverrides[];
  selectionsMax: number;
  selectionsMin: number;
};

export type ListItemType = IStoreItemProperties | IStoreItemGroupModifierGroup;
