import { WebRedirectFacade } from '@goparrot/webstore-sdk';
import { WebMerchantFacade, WebStoreFacade, PlacePickerTemplateFacade, PlacePickerLegacyStoreFacade } from '@goparrot/webstore-sdk';
import { getConfig, getInterceptors } from '@goparrot-dashboard-api/shared';

const BASE_URL = 'webstore';

export const webMerchantFacade = new WebMerchantFacade(getConfig(BASE_URL), getInterceptors());
export const webStoreFacade = new WebStoreFacade(getConfig(BASE_URL), getInterceptors());
export const webstoreRedirectFacade = new WebRedirectFacade(getConfig(BASE_URL), getInterceptors());
export const placePickerTemplateFacade = new PlacePickerTemplateFacade(getConfig(BASE_URL), getInterceptors());
export const placePickerLegacyStoreFacade = new PlacePickerLegacyStoreFacade(getConfig(BASE_URL), getInterceptors());
