import { ICloneRequest, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationCloneCategoryParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useLocationCloneCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, LocationCloneCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, LocationCloneCategoryParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationCategoryFacade.clone(merchantId, storeId, uniqueName, data), options);
};
