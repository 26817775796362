import { IRewriteRelationsRequest, IStoreItemGroupModifierGroup, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdateStoreItemGroupOptionsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemGroupModifierGroup>;
};
export type LocationUpdateStoreItemGroupOptionsResult = UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupOptionsParams>;

export const useLocationStoreItemGroupUpdateOptions = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupOptionsParams>,
): LocationUpdateStoreItemGroupOptionsResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemGroupFacade.updateItemOptions(merchantId, storeId, uniqueName, data),
    options,
  );
};
