import { PatchItemSetDto, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemSetFacade } from '../../../facades';

export type LocationUpdateItemSetParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchItemSetDto;
};
export const useUpdateLocationItemSet = (
  options: UseMutationOptions<ReadItemSetDto, unknown, LocationUpdateItemSetParams>,
): UseMutationResult<ReadItemSetDto, unknown, LocationUpdateItemSetParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationItemSetFacade.patch(merchantId, storeId, uniqueName, data), options);
};
