import { PatchStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationPatchStoreItemGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchStoreItemGroupDto;
};

export const useLocationPatchStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, LocationPatchStoreItemGroupParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, LocationPatchStoreItemGroupParams> => {
  return useMutation(
    async ({ merchantId, uniqueName, storeId, data }) => await locationStoreItemGroupFacade.patch(merchantId, storeId, uniqueName, data),
    options,
  );
};
