import { ValidatorOptions } from 'class-validator';
import { ClassTransformOptions } from 'class-transformer';

export type TransformerValidationOptions = {
  validator: ValidatorOptions;
  transformer: ClassTransformOptions;
};

export const getValidationOptions = (groups?: string[]): TransformerValidationOptions => {
  return {
    validator: {
      groups,
      whitelist: true,
      forbidNonWhitelisted: true,
      forbidUnknownValues: true,
      validationError: { target: false, value: false },
      // skipMissingProperties: true,
    },
    ...(groups?.length && {
      transformer: {
        groups,
        ...(groups.includes('patch') && { excludeExtraneousValues: true }),
      },
    }),
  };
};
