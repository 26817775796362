import React, { ReactElement } from 'react';

export type FormSectionProps = {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  subtitleClassName?: string;
  children: ReactElement | ReactElement[];
  dataTestId?: string;
};

export const FormSection: React.FC<React.PropsWithChildren<FormSectionProps>> = ({ children, title, subtitle, subtitleClassName, dataTestId }) => {
  return (
    <div data-testid={dataTestId} className="tw-mb-8 tw-space-y-4">
      <div>
        <div className="tw-text-blue-gray-900 tw-font-bold tw-text-lg">{title}</div>
        {subtitle && <p className={subtitleClassName ?? 'tw-w-2/3 tw-mt-1 tw-text-xs'}>{subtitle}</p>}
      </div>
      {children}
    </div>
  );
};
