import {
  IntlMessage as baseIntlMessage,
  getIntlMessage as baseGetIntlMessage,
  getIntlString as baseGetIntlString,
  I18nService as baseI18nService,
  useI18n as baseUseI18n,
} from '@goparrot-dashboard/i18n';

/**
 * @deprecated
 * @see `@goparrot-dashboard/i18n` module instead
 */
export const IntlMessage = baseIntlMessage;

/**
 * @deprecated
 * @see `@goparrot-dashboard/i18n` module instead
 */
export const getIntlMessage = baseGetIntlMessage;

/**
 * @deprecated
 * @see `@goparrot-dashboard/i18n` module instead
 */
export const getIntlString = baseGetIntlString;

/**
 * @deprecated
 * @see `@goparrot-dashboard/i18n` module instead
 */
export const I18nService = baseI18nService;

/**
 * @deprecated
 * @see `@goparrot-dashboard/i18n` module instead
 */
export const useI18n = baseUseI18n;
