import { ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemSetFacade } from '../../facades';
import { LocationGetRelativesQueryParams } from '../../interface';

export const useLocationItemSetChildren = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadCategoryDto[]>,
): UseQueryResult<ReadCategoryDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_SET_CHILDREN_LIST, merchantId, storeId, uniqueName],
    async () => await locationItemSetFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
