import { useCallback } from 'react';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { locationFacade } from '../api/location';

export function useDeleteLocation(
  onSuccess: () => void = () => null,
): {
  deleteLocation(merchantId: string, storeId: string): void;
} {
  const { notifyError, notifySuccess } = useNotifications();
  const deleteLocation = useCallback(
    async (merchantId: string, storeId: string) => {
      try {
        await locationFacade.delete(merchantId, storeId);
        notifySuccess({
          text: getIntlString('page.merchant.franchise.notification.location.delete.success.message'),
        });
        onSuccess();
      } catch (e) {
        notifyError({
          text: getIntlString('page.merchant.franchise.notification.location.delete.error.message'),
        });
      }
    },
    [onSuccess],
  );
  return { deleteLocation };
}
