import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import { defineMessages } from 'react-intl';
import { assign } from 'lodash/fp';
import { OrderStatusEnum, OrderActionTypeEnum } from '@goparrot/order-sdk';
import { PaymentRefundSourceEnum, OrderStatusChangeSourceEnum } from '@goparrot/common';

import { intl } from '@goparrot-dashboard/i18n';
import aApp from 'store/app/actions';

import a from './actions';
import * as api from './api';

const intlString = defineMessages({
  errorMessage: {
    id: 'page.location.orders.errorAccured',
  },
});

function* handleThrottlingCreate({ payload }) {
  const { storeId } = payload;
  try {
    const result = yield call(api.createOrderThrottling(storeId, payload));
    yield put(a.successThrottlingCreate(result));
    yield put(aApp.pushNotificationWithSuccess('order.throttling.create.success.message', 'order.throttling.create.success.description'));
  } catch (e) {
    yield put(a.errorThrottlingCreate(e));
    yield put(aApp.pushNotificationWithError('order.throttling.create.error.message', 'order.throttling.create.error.description'));
  }
}

function* handleThrottlingGet({ payload }) {
  try {
    const result = yield call(api.getOrderThrottling(payload));
    yield put(a.successGetThrottlingConfig(result));
  } catch (e) {
    yield put(a.errorGetThrottlingConfig(e));
  }
}

function* handleThrottlingUpdate({ payload }) {
  try {
    const result = yield call(api.updateOrderThrottling(payload));
    yield put(a.successThrottlingUpdate(result));
    yield put(aApp.pushNotificationWithSuccess('order.throttling.update.success.message', 'order.throttling.update.success.description'));
  } catch (e) {
    yield put(a.errorThrottlingUpdate(e));
    yield put(aApp.pushNotificationWithError('order.throttling.update.error.message', 'order.throttling.update.error.description'));
  }
}

function* handleOrderActions({ payload }) {
  const { uuid, actions } = payload;
  const confirmOrderType = actions.find((action) => action.type === OrderActionTypeEnum.CONFIRM_ORDER);
  const confirmArrival = actions.find((action) => action.type === OrderActionTypeEnum.CONFIRM_ARRIVAL);
  try {
    const result = yield call(api.handleActions(uuid, actions));
    yield put(a.successSelectedUpdate(result));
    yield put(a.successRunActions(result));
    if (confirmArrival) {
      yield put(aApp.pushNotificationWithSuccess('order.arrivalConfirm.success.message', 'order.arrivalConfirm.success.description'));
    } else if (!confirmOrderType) {
      yield put(aApp.pushNotificationWithSuccess('order.canceled.success.message', 'order.canceled.success.description'));
    }
  } catch (e) {
    yield put(a.errorRunActions());
    if (!confirmOrderType) {
      yield put(aApp.pushNotificationWithError('order.canceled.error.message', 'order.canceled.error.description'));
    }
  }
}

function* handleGet({ type, payload }) {
  try {
    const { storeId, statuses, filters, last4Digits } = payload;
    const filter = {
      statuses,
      filters,
    };
    if (last4Digits) {
      const orderIds = yield call(api.getTransactions(storeId, last4Digits));
      filter.filters.orderIds = orderIds.value.map((order) => order.orderId);
      if (!orderIds.value.length) {
        yield put(a.successGet([]));
        return;
      }
    }

    const result = yield call(api.getOrdersByMultipleStatuses(storeId, filter));

    if (a.API__NEW_ORDERS__GET__REQUEST === type) {
      yield put(a.successGetNew(result));
      return;
    }

    if (a.API__ORDERS__POLL__REQUEST === type) {
      yield put(a.successPoll(result));
      return;
    }
    yield put(a.successGet(result));
  } catch (e) {
    yield put(a.errorGet());
  }
}

function* handleUpdateStatus({ payload }) {
  try {
    const { uuid, status, updateSelected, storeId, statuses, filters, last4Digits } = payload;
    const filter = {
      statuses,
      filters,
    };

    const actions = [
      {
        type: OrderActionTypeEnum.CHANGE_STATUS,
        payload: {
          toStatus: status.key,
          statusChangeSource: OrderStatusChangeSourceEnum.DASHBOARD,
        },
      },
    ];
    if (updateSelected) {
      const updatedOrder = yield call(api.handleActions(uuid, actions));
      yield put(a.successSelectedUpdate(updatedOrder));
    } else {
      yield call(api.handleActions(uuid, actions));
    }

    if (last4Digits) {
      const orderIds = yield call(api.getTransactions(storeId, last4Digits));
      filter.filters.orderIds = orderIds.value.map((order) => order.orderId);
    }
    const result = yield call(api.getOrdersByMultipleStatuses(storeId, filter));

    yield put(a.successGet(result));
  } catch (e) {
    if (e.response.data.description) {
      yield put(aApp.pushNotificationWithError(e.response.data.description));
    } else {
      yield put(aApp.pushNotificationWithError('order.status.error.message', 'order.status.error.description'));
    }
    yield put(a.errorUpdate());
  }
}

function* handleGetTransaction({ payload }) {
  try {
    const { uid } = payload;
    const result = yield call(api.getTransaction(uid));
    yield put(a.successGetTransaction(result.transaction || {}));
  } catch (e) {
    yield put(a.errorGetTransaction());
  }
}

function* handleGetDeliveryProvider({ payload }) {
  try {
    const { merchantId, storeId, orderId } = payload;
    const result = yield call(api.getDeliveryProvider(merchantId, storeId, orderId));
    yield put(a.successGetDelivery(result || {}));
  } catch (e) {
    yield put(a.errorGetDelivery());
  }
}

function* handleGetCustomerInformation({ payload }) {
  try {
    const { userId } = payload;
    const result = yield call(api.getCustomerInfo(userId));
    yield put(a.successGetCustomer(result));
  } catch (e) {
    yield put(a.errorGetCustomer());
  }
}

function* handleRefund({ payload }) {
  try {
    const { uuid, amount, filters, statuses, storeId, transaction, selectedOrder, user } = payload;
    const actions = [
      {
        type: OrderActionTypeEnum.REFUND_ORDER,
        payload: {
          amount,
          statusChangeSource: OrderStatusChangeSourceEnum.DASHBOARD,
          refundByUserId: user.uuid,
          source: PaymentRefundSourceEnum.DASHBOARD,
        },
      },
    ];
    const getPayload = {
      payload: {
        storeId,
        filters,
        statuses,
      },
    };
    const result = yield call(api.handleActions(uuid, actions));

    const { topStatus, refundedAmount } = result;
    const selected = assign(selectedOrder, { status: topStatus });
    const transactionResult = assign(transaction, { refundedAmount });
    if (207 === result.status) {
      throw new Error(intl.formatMessage(intlString.errorMessage));
    }
    if (OrderStatusEnum.REFUNDED === result?.topStatus) {
      // handleGet
      yield handleGet(getPayload);
      // handleGetById
      // handleGetTransaction
    }
    yield put(a.successSelectedUpdate(selected));
    yield put(a.successGetTransaction(transactionResult));
    yield put(aApp.pushNotificationWithSuccess('order.refund.success.message', 'order.refund.success.description'));
  } catch (e) {
    let errorsPayload = [];
    if (e.message) {
      errorsPayload = [{ description: e.message }];
    }
    const { errors, err, message } = e.response?.data;
    if (errors && errors.length) {
      if (Array.isArray(errors)) {
        errorsPayload = errors.map((error) => error);
      } else {
        errorsPayload = [{ description: errors }];
      }
    }
    if (message) {
      errorsPayload = [{ description: message }];
    }
    if (err) {
      errorsPayload = [{ description: err }];
    }
    yield put(a.errorGetRefund(errorsPayload));
  }
}

function* handleGetOrder({ payload }) {
  try {
    const { uuid } = payload;
    const result = yield call(api.getOrder(uuid));
    const { _id: orderId, merchantId, storeId, paymentInformation } = result;
    yield put(a.setSelected(result || {}));
    if (paymentInformation?.uid) {
      yield put(a.errorGetTransaction({ uid: paymentInformation.uid }));
    }
    yield put(a.requestGetDelivery({ orderId, merchantId, storeId }));
  } catch (e) {
    yield put(a.errorGetOrder());
  }
}

export default function* () {
  yield takeEvery(a.API__ORDER__GET__REQUEST, handleGetOrder);
  yield takeEvery(a.API__ORDERS__GET__REQUEST, handleGet);
  yield takeEvery(a.API__ORDERS__POLL__REQUEST, handleGet);
  yield takeEvery(a.API__NEW_ORDERS__GET__REQUEST, handleGet);
  yield takeLatest(a.API__ORDER__UPDATE__REQUEST, handleUpdateStatus);
  yield takeEvery(a.API__ORDER__RUN__ACTIONS__REQUEST, handleOrderActions);
  yield takeEvery(a.API__ORDER__TRANSACTION__GET__REQUEST, handleGetTransaction);
  yield takeEvery(a.API__ORDER__CUSTOMER__GET__REQUEST, handleGetCustomerInformation);
  yield takeEvery(a.API__ORDER__REFUND__GET__REQUEST, handleRefund);
  yield takeEvery(a.API__ORDER__GET__DELIVERY__REQUEST, handleGetDeliveryProvider);
  yield takeEvery(a.API__ORDER__THROTTLING__CREATE__REQUEST, handleThrottlingCreate);
  yield takeEvery(a.API__GET__THROTTLING__CONFIG__REQUEST, handleThrottlingGet);
  yield takeEvery(a.API__UPDATE__THROTTLING__CONFIG__REQUEST, handleThrottlingUpdate);
}
