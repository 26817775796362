import { createAction } from 'helpers/create-action';

const a = {
  API__MERCHANT__GET__REQUEST: 'API__MERCHANT__GET__REQUEST',
  API__MERCHANT__GET__SUCCESS: 'API__MERCHANT__GET__SUCCESS',
  API__MERCHANT__GET__ERROR: 'API__MERCHANT__GET__ERROR',

  API__MERCHANT__CREATE__SUCCESS: 'API__MERCHANT__CREATE__SUCCESS',

  /**
   * Still in orders.
   * To be refactored
   */
  API__MERCHANT__UPDATE__REQUEST: 'API__MERCHANT__UPDATE__REQUEST',
  API__MERCHANT__UPDATE__SUCCESS: 'API__MERCHANT__UPDATE__SUCCESS',
  API__MERCHANT__UPDATE__ERROR: 'API__MERCHANT__UPDATE__ERROR',
};
const c = {
  requestGet: createAction(a.API__MERCHANT__GET__REQUEST),
  successGet: createAction(a.API__MERCHANT__GET__SUCCESS),
  errorGet: createAction(a.API__MERCHANT__GET__ERROR),

  requestUpdate: createAction(a.API__MERCHANT__UPDATE__REQUEST),
  successUpdate: createAction(a.API__MERCHANT__UPDATE__SUCCESS),
  errorUpdate: createAction(a.API__MERCHANT__UPDATE__ERROR),
  successCreate: createAction(a.API__MERCHANT__CREATE__SUCCESS),
};

export default { ...a, ...c };
