const TOKEN_KEY = 'token';

export class TokenUtility {
  static set(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }
  static get(): { token: string; remember: boolean } | null {
    const local = localStorage.getItem(TOKEN_KEY);
    if (local) {
      return { token: local, remember: true };
    }
    return null;
  }
  static clear(): void {
    localStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem('selected');
    sessionStorage.removeItem('filters');
    sessionStorage.removeItem('merchantId');
  }
}
