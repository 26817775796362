import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IPromotionFilterDto, ListPromotionDto } from '@goparrot/promotions-sdk';
import { IRequestPaginationDto } from '@goparrot/common';
import { SharedPromotionQueryEnum } from '../enum';
import { merchantPromotionV3Facade } from '../facades';

type PromotionListParams = {
  merchantId: string;
  filters?: IPromotionFilterDto;
  pagination?: IRequestPaginationDto;
};

export const usePromotionList = (
  { merchantId, filters, pagination }: PromotionListParams,
  options?: UseQueryOptions<ListPromotionDto>,
): UseQueryResult<ListPromotionDto> => {
  return useQuery(
    [SharedPromotionQueryEnum.PROMOTIONS_LIST, merchantId, filters, pagination],
    () => merchantPromotionV3Facade.list(merchantId, filters, pagination),
    options,
  );
};
