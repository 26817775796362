import { IMerchantFilterParams, ItemListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { merchantStoreCombinedItemsFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<IMerchantFilterParams>;

export const useMerchantStoreCombinedItemsList = (
  { merchantId, pagination, params }: QueryParams,
  options?: UseQueryOptions<ItemListResponseDto>,
): UseQueryResult<ItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.MERCHANT_STORE_COMBINED_ITEMS_LIST, merchantId, pagination, params],
    async () => await merchantStoreCombinedItemsFacade.list(merchantId, pagination, params),
    options,
  );
};
