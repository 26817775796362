import { IUpdateRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type MerchantUpdateCategoryChildrensParams = {
  merchantId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest;
};

export const useMerchantUpdateCategoryChildrens = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateCategoryChildrensParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateCategoryChildrensParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantCategoryFacade.updateChildren(merchantId, uniqueName, data), options);
};
