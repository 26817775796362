import styled from 'styled-components';

import { palette } from 'styles/constants';

const AntRadiobox = (ComponentName) => styled(ComponentName)`
  &.ant-radio-group-solid {
    display: flex;

    .ant-radio-button-wrapper {
      background-color: ${palette.secondary[12]};
      color: ${palette.primary[25]};
      border: none;
      border-radius: 4px;
    }

    .ant-radio-button-wrapper:not(:first-child) {
      margin-left: 4px;

      &::before {
        display: none;
      }
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
      background-color: ${palette.secondary[14]};
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):active {
      background-color: ${palette.secondary[15]};
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${palette.primary[26]};
      color: ${palette.color[20]};
      box-shadow: none;
    }
  }

  &.ant-radio-wrapper,
  .ant-radio-wrapper {
    font-size: 13px;
    color: ${palette.text[1]};

    .ant-radio-inner {
      &:after {
        width: 6px;
        height: 6px;
        top: 4px;
        left: 4px;
        background-color: ${palette.primary[0]};
      }
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio-indeterminate .ant-radio-inner {
      border-color: ${palette.primary[0]};
    }

    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: ${palette.primary[0]};
    }

    .ant-radio-disabled .ant-radio-inner:after {
      background-color: #ccc;
    }

    &:hover {
      .ant-radio-inner {
        border-color: ${palette.primary[0]};
      }
    }

    .ant-radio-checked {
      .ant-radio-inner {
        &:after {
          transform: scale(1);
        }
      }
    }
  }
`;

export default AntRadiobox;
