import { IPagination, ItemBaseGroupListResponseDto, IResultRequestParams } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { locationItemBaseGroupFacade } from '../../facades';

type ItemBaseGroupListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IResultRequestParams };

export const useLocationItemBaseGroupList = (
  { merchantId, storeId, pagination, params }: ItemBaseGroupListParams,
  options?: UseQueryOptions<ItemBaseGroupListResponseDto>,
): UseQueryResult<ItemBaseGroupListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.LOCATION_ITEM_BASE_GROUP_LIST, merchantId, storeId, pagination, params],
    async () => await locationItemBaseGroupFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
