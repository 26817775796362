import { JsonPatchOperationDto } from '@goparrot/common';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { vendorFacade } from '../facades';

type UpdateVendorParams = {
  storeId: string;
  data: JsonPatchOperationDto[];
};

export const useUpdateVendor = (
  options?: UseMutationOptions<ReadVendorDto, unknown, UpdateVendorParams, unknown>,
): UseMutationResult<ReadVendorDto, unknown, UpdateVendorParams, unknown> => {
  return useMutation(({ storeId, data }) => vendorFacade.patch(storeId, data), options);
};
