import React, { FC, useEffect, useState } from 'react';
import { TokenUtility } from '@goparrot-dashboard-api/shared';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { IReadStoreDto, StoreTypeEnum } from '@goparrot/store-v2-sdk';
import { getIntlString } from '@goparrot-dashboard/i18n';

import {
  isDefaultStore,
  ButtonLinkToStore,
  openURL,
  sortLocationsByName,
  usePrevious,
  resetDeliveryArea,
  MM_FILTERS_CACHE_KEY,
  MM_SEARCH_CACHE_KEY,
} from '@goparrot-dashboard/shared-utils';
import { useDispatch } from 'react-redux';
import { useHistory, matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { PermissionsV2, hasSomePermission, hasPermission, hasAllPermissions, IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import sortBy from 'lodash/sortBy';
import { IconQuestion, IconCloseMedium, IconBurger, IconLogoSquareGoparrot, Button } from '@goparrot-dashboard/shared-ui';

import aLevel from '../../../client/src/store/level/actions';
import aApp from '../../../client/src/store/app/actions';

import { MENU_ITEMS } from '../constants';

import { useGlobalAppContext } from '../GlobalAppProvider';
import { LocationItem } from '../interface';
import { useResetMenuManagementState } from './hooks';
import { NavBarSelect, NavBarWrapper, NavBarSidebar, NavBarContent, Logo, NavbarUser, ButtonLinkToSite } from './components';
import { getStoreLevelRedirectUrl, getMerchantLevelRedirectUrl, getNextRoute, redirectToMerchantLevel, redirectToStoreLevel } from './utils';
import { RouteParams, SessionStorageSelectedProp } from './interface';

export const NavBar: FC = () => {
  const { isSidebarVisibleOnMobile, setIsSidebarVisibleOnMobile, isNavSelectWrapperVisible } = useGlobalAppContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = history?.location.pathname;
  const { roleV2, user } = useUserService();
  const {
    isMerchant,
    merchant,
    stores,
    defaultStores,
    franchiseLocations,
    selectedStore,
    selectedStoreId,
    locationsFetching,
    locationDefaultFetching,
  } = useStoreService();

  const defaultStoresList = hasAllPermissions(roleV2 as IReadRoleV2Dto, [PermissionsV2.DEFAULT_STORE.VIEW, PermissionsV2.DEFAULT_STORE.API_READ])
    ? sortLocationsByName(defaultStores)
    : [];
  const sortedStoresList = React.useMemo(() => (hasPermission(roleV2 as IReadRoleV2Dto, PermissionsV2.STORE.NAVBAR_VIEW) ? sortLocationsByName(stores) : []), [
    stores,
  ]);
  const availableStores = sortedStoresList.concat(defaultStoresList);

  const hideNavSelectWrapper =
    (isNavSelectWrapperVisible && hasSomePermission(roleV2 as IReadRoleV2Dto, [PermissionsV2.MERCHANT.NAVBAR_VIEW, PermissionsV2.STORE.NAVBAR_VIEW])) ||
    hasAllPermissions(roleV2 as IReadRoleV2Dto, [PermissionsV2.DEFAULT_STORE.VIEW, PermissionsV2.DEFAULT_STORE.API_READ]);

  const [trigger] = useResetMenuManagementState();
  const selected = selectedStore ? selectedStore : merchant;
  const availableStoresSorted = sortBy(availableStores, ['type']);
  const merchantDTO = hasPermission(roleV2 as IReadRoleV2Dto, PermissionsV2.MERCHANT.NAVBAR_VIEW) && merchant;
  const selectedData = [merchantDTO, ...availableStoresSorted].filter(Boolean);
  const isStore = selected?.type === StoreTypeEnum.STORE;
  const getSelectedIdTitle = selectedData.find((item) => item.storeId === selected?.storeId)?.title ?? '';
  const centralLocationStoreId = franchiseLocations?.find((store) => store.isCentralLocation)?.storeId;
  const prevStores = usePrevious(sortedStoresList);
  const prevMerchant = usePrevious(merchant);
  const [currentTime, setCurrentTime] = useState<string>(new Date().toISOString());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toISOString());
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const selectedItem = sessionStorage.getItem('selected');
  const getSelectedLevel = (): SessionStorageSelectedProp | null => (selectedItem ? JSON.parse(selectedItem) : null);

  useEffect(() => {
    const { storeId, isMerchantSelected } = getSelectedLevel() || {};
    if (!storeId) return;

    const isMerchantLevelUrl = MENU_ITEMS.MERCHANT.some((item) => {
      const match = matchPath<RouteParams>(pathname, { path: item.items.map((item) => item.link), exact: true, strict: false });
      if (null !== match) {
        const { id } = match.params;
        return id ? id === user.merchantId : false;
      }
      return false;
    });
    if (!hasPermission(roleV2 as IReadRoleV2Dto, PermissionsV2.MERCHANT.NAVBAR_VIEW) && (storeId === user.merchantId || isMerchantLevelUrl)) {
      sessionStorage.removeItem('selected');
      return;
    }
    dispatch(aLevel.select(storeId, isMerchantSelected));
  }, []);

  useEffect(() => {
    const selectedLevel = getSelectedLevel();
    const noMerchantLevelAccess = !hasPermission(roleV2 as IReadRoleV2Dto, PermissionsV2.MERCHANT.NAVBAR_VIEW);

    /**
     * for store level only roles we should set store id of the first location right after success login.
     * Because of menu management which can have merchant level item(problem with refreshing such pages)
     */
    if (!prevStores?.length && sortedStoresList?.length && null === selectedLevel) {
      if (selectedStoreId) {
        sessionStorage.setItem('selected', JSON.stringify({ storeId: selectedStoreId, isMerchantSelected: !!isMerchant }));
        return;
      }

      const { storeId: storeIdOfFirstAvailableStore } = selectedData[0];
      const storeIdFromUrl = MENU_ITEMS.LOCATION.map((item) => {
        const match = matchPath<RouteParams>(pathname, { path: item.items.map((item) => item.link), exact: true, strict: false });
        if (null !== match) {
          const { id } = match.params;
          return id;
        }
        return false;
      }).filter(Boolean)[0];
      // selectedData.includes(storeIdFromUrl) always falsy as selectedData: (ReadStoreDto | ReadDefaultStoreDto | ReadMerchantDto)[]
      const storeId = selectedData.includes(storeIdFromUrl) ? storeIdFromUrl : storeIdOfFirstAvailableStore;
      const isMerchantFirstStore = storeId === merchant?.merchantId;
      sessionStorage.setItem('selected', JSON.stringify({ storeId: storeId, isMerchantSelected: isMerchantFirstStore }));
      dispatch(aLevel.select(storeId, isMerchantFirstStore));
      return;
    }

    if (merchant?.merchantId && selectedStoreId) {
      if (pathname === '/dashboard' && noMerchantLevelAccess) {
        const shouldRedirectToUrl: string | undefined = getStoreLevelRedirectUrl(selectedStoreId, roleV2 as IReadRoleV2Dto);
        if (shouldRedirectToUrl) {
          dispatch(aApp.changeCurrent(shouldRedirectToUrl, true));
        }
      }
      const locationLinkFound = MENU_ITEMS.LOCATION.some((menuItem) => {
        return menuItem.items.some((subItem) => {
          const match = matchPath<RouteParams>(pathname, { path: subItem.link, exact: subItem.exact ?? true, strict: false });
          if (null !== match) {
            const { id } = match.params;
            const isMerchantId = id === merchant?.merchantId;
            if (isMerchantId && noMerchantLevelAccess) {
              const locationUrl: string | undefined = getStoreLevelRedirectUrl(selectedStoreId, roleV2 as IReadRoleV2Dto);
              dispatch(aApp.changeCurrent(locationUrl, true));
            } else {
              dispatch(aLevel.select(id, isMerchantId, pathname));
            }

            return true;
          }
          return false;
        });
      });

      if (!locationLinkFound && !noMerchantLevelAccess) {
        MENU_ITEMS.MERCHANT.some((merchantItem) => {
          const matchedChild = merchantItem.items.find((item) => {
            return matchPath(pathname, { path: item.link, strict: false }) !== null;
          });
          if (!matchedChild) {
            return false;
          }

          if (
            !matchedChild.permissions ||
            (!Array.isArray(matchedChild.permissions) && !hasSomePermission(roleV2 as IReadRoleV2Dto, matchedChild.permissions))
          ) {
            const shouldRedirectToUrl: string | undefined = getMerchantLevelRedirectUrl(merchant?.merchantId, roleV2 as IReadRoleV2Dto);

            if (shouldRedirectToUrl) {
              dispatch(aApp.changeCurrent(shouldRedirectToUrl, true));
            }
          }
          return true;
        });
      }
    }
  }, [prevStores, prevMerchant, merchant, sortedStoresList, selectedStoreId, roleV2, selectedData, dispatch, isMerchant, pathname]);

  const onHandleChange = (value: string) => {
    handleLocationSelect({ key: value });
    setIsSidebarVisibleOnMobile(false);
    sessionStorage.removeItem(MM_FILTERS_CACHE_KEY);
    sessionStorage.removeItem(MM_SEARCH_CACHE_KEY);
  };

  const location = useLocation();

  const handleLocationSelect = ({ key }: { key: string }) => {
    trigger();
    resetDeliveryArea();
    const isMerchantSelected = key === merchant.storeId;
    sessionStorage.setItem('selected', JSON.stringify({ storeId: key, isMerchantSelected }));
    if (isMerchantSelected) {
      dispatch(aLevel.select(key, isMerchantSelected));
      history.push(getNextRoute(null, '', true));
    }
    const pathname = history.location.pathname;
    const res = MENU_ITEMS.LOCATION.reduce((acc: LocationItem[], { items }) => acc.concat(...items), []).some((item) => {
      const match = matchPath<RouteParams>(pathname, { path: item.link, exact: item?.exact ?? true, strict: false });

      if (match && match.params) {
        dispatch(aLevel.select(key, isMerchantSelected));
        redirectToStoreLevel(item.link, key, match, history);

        return true;
      }
      return false;
    });
    const isDefaultStoreSelected = isDefaultStore(selectedStore);

    if (!res) {
      dispatch(aLevel.select(key, isMerchantSelected, pathname));

      const storeLevelRedirectUrl = getStoreLevelRedirectUrl(key, roleV2 as IReadRoleV2Dto);

      if (!isDefaultStoreSelected && !isMerchantSelected && storeLevelRedirectUrl) {
        history.push(storeLevelRedirectUrl);
      } else if (isMerchantSelected && getMerchantLevelRedirectUrl(key, roleV2 as IReadRoleV2Dto)) {
        redirectToMerchantLevel(key, roleV2 as IReadRoleV2Dto, isMerchantSelected, history, location.pathname);
      } else {
        history.push(getNextRoute(key, 'general', false));
      }
    }
    if (isDefaultStoreSelected && pathname.includes('/dashboard/menu-management/')) {
      history.push('/dashboard/');
    }
  };

  const handleOpenWiki = () => {
    const { token } = TokenUtility.get() || {};
    openURL(`${window.location.origin}/wiki?wiki_token=${token}`);
  };

  return (
    <NavBarWrapper>
      <NavBarSidebar>
        <Logo />
      </NavBarSidebar>
      <Button
        kind="icon"
        color="clean"
        data-testid="mobile-logo"
        noFocusOutline={true}
        className="tw-block xl:tw-hidden tw-relative tw-z-1 tw-bg-white tw-ring-opacity-0 focus:tw-ring-opacity-0"
        onClick={() => setIsSidebarVisibleOnMobile(!isSidebarVisibleOnMobile)}
      >
        {isSidebarVisibleOnMobile ? <IconCloseMedium /> : <IconBurger />}
      </Button>
      {!isSidebarVisibleOnMobile && (
        <Button
          kind="icon"
          color="clean"
          data-testid="mobile-logo"
          noFocusOutline={true}
          className="tw-hidden tw-mb-1 tw-pl-2 sm:tw-block xl:tw-hidden tw-relative tw-z-1 tw-bg-white tw-ring-opacity-0 focus:tw-ring-opacity-0"
          onClick={() => setIsSidebarVisibleOnMobile(!isSidebarVisibleOnMobile)}
        >
          <IconLogoSquareGoparrot />
        </Button>
      )}

      <NavBarContent>
        {hideNavSelectWrapper && (
          <div
            data-testid="choose-location-wrapper"
            className={classNames([
              isSidebarVisibleOnMobile ? 'tw-translate-x-0 tw-opacity-100' : 'tw--translate-x-full tw-opacity-0',
              'tw-absolute tw-top-1 xl:tw-top-0 tw-left-20 xl:tw-left-0 tw-right-20 sm:tw-right-28 xl:tw-right-0',
              'tw-flex tw-items-strech xl:tw-mr-5 xl:tw-ml-3.5',
              'xl:tw-opacity-100 tw-transform xl:tw-translate-x-0 tw-transition-all tw-mt-px xl:tw-mt-0 xl:tw-relative',
            ])}
          >
            <label className="tw-mr-2.5 tw-hidden tw-items-center tw-text-cool-gray-700 xl:tw-flex">{getIntlString('topbar.choose.location')}</label>
            <NavBarSelect
              stores={selectedData as IReadStoreDto[]}
              centralLocationStoreId={centralLocationStoreId}
              onChange={(value) => onHandleChange(String(value))}
              currentTime={currentTime}
            />
            {!isMerchant && isStore && (
              <ButtonLinkToStore
                locationsLoading={locationsFetching || locationDefaultFetching}
                defaultStores={defaultStores}
                selectedStoreId={selectedStoreId}
                className="tw-hidden xl:tw-flex"
              />
            )}
            {isMerchant && <ButtonLinkToSite className="tw-hidden xl:tw-flex" merchantId={merchant.merchantId} />}
          </div>
        )}
        <div className="tw-flex xl:tw-ml-auto tw-overflow-hidden xl:tw-overflow-visible tw-flex-1 xl:tw-flex-none">
          {'true' === String(window._env_.HELP_SECTION_ENABLED).toLowerCase() ? (
            <div
              className={classNames([
                isSidebarVisibleOnMobile ? 'tw-ml-0' : 'tw-ml-10',
                'tw-flex tw-items-center xl:tw-ml-0 tw-order-2 xl:tw-order-1 tw-bg-white tw-relative tw-z-1',
              ])}
            >
              <Button rounded="clean" kind="clean" color="clean" className="xl:tw-mr-5" onClick={handleOpenWiki}>
                <IconQuestion />
                <span className="tw-text-cool-gray-700 tw-ml-2 tw-hidden sm:tw-block">{getIntlString('topbar.help')}</span>
              </Button>
            </div>
          ) : null}
          <NavbarUser
            centralLocationStoreId={centralLocationStoreId}
            selectedStore={selected}
            selectedItemTitlte={getSelectedIdTitle}
            isSidebarVisibleOnMobile={isSidebarVisibleOnMobile}
            locationsLoading={locationsFetching || locationDefaultFetching}
            showButtonLinkToStore={!isMerchant && isStore}
            currentTime={currentTime}
          />
        </div>
      </NavBarContent>
    </NavBarWrapper>
  );
};
