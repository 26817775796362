import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { appLocale } from '../appLocale';

interface Props {
  locale: string;
}

export const I18nProvider: React.FC<React.PropsWithChildren<Props>> = ({ locale, children }) => {
  const currentAppLocale = appLocale[locale];

  return (
    <IntlProvider locale={locale} messages={currentAppLocale.messages}>
      {children}
    </IntlProvider>
  );
};
