import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { GetRelativesQueryBaseParams, useLocationGetCategoryParentsQuery, useMerchantGetCategoryParentsQuery } from '@goparrot-dashboard-api/menu-management';
import { UseQueryResult } from 'react-query';
import { MENU_ELEMENTS } from '../constants';

type Props = GetRelativesQueryBaseParams & {
  storeId: string;
  isMerchant: boolean;
};

export const useGetCategoryParentsQuery = ({ merchantId, storeId, uniqueName, isMerchant }: Props): UseQueryResult<ReadItemSetDto[]> => {
  const { notifyError } = useNotifications();
  const { getIntlString } = useI18n();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.connections.modal.preview.get.error', { values: { itemName: MENU_ELEMENTS.CATEGORY }, isRootPath: true }),
      });
    },
  };

  const locationQuery = useLocationGetCategoryParentsQuery(
    { merchantId, storeId, uniqueName },
    {
      ...options,
      enabled: !isMerchant,
    },
  );

  const merchantQuery = useMerchantGetCategoryParentsQuery(
    { merchantId, uniqueName },
    {
      ...options,
      enabled: isMerchant,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
