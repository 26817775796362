import { IResultsListResponse, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { UseQueryResult } from 'react-query';
import { useItemSetList, useLocationItemSetList } from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';
import { useStoreService } from '@goparrot-dashboard-core/store-service';

type Props = {
  merchantId: string;
};

export const useItemSetsListQuery = ({ merchantId }: Props): UseQueryResult<IResultsListResponse<ReadItemSetDto>> => {
  const { franchiseLocations, isVirtualMenu } = useStoreService();
  const centralLocationStoreId = franchiseLocations?.find((store) => store.isCentralLocation)?.storeId;
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.ITEM_SET }),
      });
    },
  };

  const merchantQuery = useItemSetList(
    { merchantId, params: { displayStoreSpecific: false, isGroupedList: true } },
    {
      ...options,
      enabled: isVirtualMenu,
    },
  );

  const locationQuery = useLocationItemSetList(
    { merchantId, storeId: centralLocationStoreId ?? '' },
    {
      ...options,
      enabled: !isVirtualMenu && !!centralLocationStoreId,
    },
  );

  return isVirtualMenu ? merchantQuery : locationQuery;
};
