import React from 'react';
import { MarketButton } from '@market/react-bindings';
import { useI18n } from '@goparrot-dashboard/i18n';
import { IconCloseThin } from '@goparrot-dashboard/shared-ui';
import { FilterSettingsType } from './interface';

interface Props {
  disabled?: boolean;
  defaultFilters: FilterSettingsType[];
  setFilters: (filters: FilterSettingsType[]) => void;
  visible: boolean;
}

const ClearAllButton: React.FC<React.PropsWithChildren<Props>> = ({ disabled, setFilters, defaultFilters, visible }) => {
  const { getIntlString } = useI18n();
  return visible ? (
    <MarketButton
      role="button"
      rank="tertiary"
      aria-label={getIntlString('menu.list.filters.clearAll', { isRootPath: true })}
      disabled={disabled || undefined}
      onClick={() => {
        setFilters(defaultFilters);
      }}
    >
      <IconCloseThin className="tw-w-3 tw-h-3" />
      <span>{getIntlString('menu.list.filters.clearAll', { isRootPath: true })}</span>
    </MarketButton>
  ) : null;
};

export default ClearAllButton;
