import { useDispatch } from 'react-redux';

import aLocation from '../../../../modules/client/src/store/location/actions';
import { useStoreService } from './useStoreService';

type useStoreListRefetchResult = {
  (): void;
};

export const useStoreListRefetch = (): useStoreListRefetchResult => {
  const dispatch = useDispatch();
  const storeService = useStoreService();

  return () => {
    if (storeService.userStores) {
      dispatch(aLocation.requestGet({ stores: storeService.userStores }));
    } else {
      dispatch(aLocation.requestGet());
    }
  };
};
