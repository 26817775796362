import React from 'react';
import { SsoLinkState } from '../../../../homepages/src/hooks/useSsoConnectCallbackMutation';

interface SsoConnectCallbackSuccessProps {
  hideSsoLinkBanner?: () => void;
  ssoLinkState: SsoLinkState;
  setSsoLinkState: React.Dispatch<React.SetStateAction<SsoLinkState>>;
  clearParams: () => void;
}

export const ssoConnectCallbackSuccess = ({ hideSsoLinkBanner, ssoLinkState, setSsoLinkState, clearParams }: SsoConnectCallbackSuccessProps): void => {
  hideSsoLinkBanner?.();
  if (ssoLinkState !== SsoLinkState.SUCCESS) {
    setSsoLinkState(SsoLinkState.SUCCESS);
  }
  clearParams();
};
