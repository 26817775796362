import { axios } from '@goparrot-dashboard-api/shared';

export const squareFacade = {
  revokeSquareAuth: async (storeId: string): Promise<void> => {
    await axios.delete(`payment/square/api/merchant/${storeId}/config`);
  },
  /**
   * @todo remove after release
   * @deprecated
   * @see `paymentStoreFacade.getAuthData`
   */
  getSquareAuthUrl: async (storeId: string): Promise<{ value: string }> => {
    return (await axios.get(`payment/square/api/merchant/${storeId}/oauth`)).data;
  },
  getSquareLocations: async (storeId: string): Promise<{ value: { locations: any[] } }> => {
    return (await axios.get(`payment/square/api/merchant/${storeId}/locations`)).data;
  },
};
