import { select } from 'redux-saga/effects';
import { StoreByMerchantFacade } from '@goparrot/store-v2-sdk';

import { getConfig, getInterceptors } from 'helpers/request';
import { getMerchantId } from 'store/user/selector';

const facade = new StoreByMerchantFacade(getConfig('api/v2/store-v2'), getInterceptors());

export const get = (filters) =>
  function* () {
    return yield facade.list(yield select(getMerchantId), filters);
  };

export const create = (params) =>
  function* () {
    return yield facade.create(yield select(getMerchantId), params);
  };

export const update = (id, params) =>
  function* () {
    return yield facade.patch(yield select(getMerchantId), id, params);
  };

export const deleteByStoreId = (id) =>
  function* () {
    return yield facade.delete(yield select(getMerchantId), id);
  };
