import styled from 'styled-components';

export const TabsWrapper = (component) => styled(component)`
  &.ant-tabs {
    @media only print {
      .ant-tabs-content {
        display: block;
      }
      .ant-tabs-bar.ant-tabs-top-bar {
        display: none;
      }
    }
    .ant-tabs-bar {
      background-color: ${(props) => (props.bgColor ? props.bgColor : 'transparent')};
    }
    &:not(.ant-tabs-vertical) {
      > .ant-tabs-content-animated {
        display: flex;
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        @media only screen and (max-width: 1024px) {
          font-size: 13px;
        }

        .anticon:not(.anticon-close) {
          margin: 0 8px 0 0;

          &.anticon-close {
            right: 2px;
            left: inherit;
          }
        }
      }
    }

    .ant-tabs-tab-prev {
      left: 0;
      right: inherit;
      transform: rotate(0);
    }

    .ant-tabs-tab-next {
      left: inherit;
      right: 2px;
      transform: rotate(0);
    }

    &.ant-tabs-vertical {
      .ant-tabs-tab-prev,
      .ant-tabs-tab-next {
        transform: rotate(0);
      }
    }
  }
`;
