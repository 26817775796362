import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { Button, GenericTable as Table, IconArrowRightSmall, IconWarning, Modal } from '@goparrot-dashboard/shared-ui';
import React from 'react';
import { useGetGiftCardCollision } from '@goparrot-dashboard-api/orchestration';
import { DialogStateReturn, useDialogState } from 'reakit/Dialog';
import { useTable } from 'react-table';
import { ReadGiftCardReplicaDto, ReadGiftCardMappingDto } from '@goparrot/giftcard-orchestrator-sdk';
import { getIntlCurrency, IntlMessage, useI18n } from '@goparrot-dashboard/i18n';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { useGiftCardOrchestrationContext } from '../../services';

export const GiftCardCollisionList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    merchant: { merchantId, featuresFlags, shortTermFeatureFlags },
  } = useStoreService();
  const dialog = useDialogState({ animated: true });
  const { locations } = useGiftCardOrchestrationContext();
  const shouldCheckCollisions =
    featuresFlags?.multiEntityType === MultiEntityTypeEnum.FRANCHISE &&
    !!shortTermFeatureFlags?.firstPartyGiftCardOrchestrationEnabled &&
    locations.some((l) => l.franchiseLocation && !l.franchiseLocation.isCentralLocation);
  const { data } = useGetGiftCardCollision(merchantId, {
    enabled: shouldCheckCollisions,
  });

  return shouldCheckCollisions && data?.rows.length ? (
    <>
      <div data-testid="franchise-management-gift-card-collision" className="tw-flex tw-items-start tw-rounded tw-bg-orange-50 tw-p-4 tw-mt-8 tw-space-x-4">
        <IconWarning className="tw-text-orange-500" width={24} height={22} />
        <div className="tw-flex">
          <IntlMessage
            id="page.merchant.franchise.giftCards.collision.text"
            values={{
              button: (chunk: string) => (
                <Button kind="clean" color="link" onClick={dialog.show} className="tw-inline-flex">
                  <div className="tw-flex tw-items-center tw-space-x-0.5">
                    <span>{chunk}</span>
                    <IconArrowRightSmall height={11} width={9} />
                  </div>
                </Button>
              ),
            }}
          />
        </div>
      </div>
      <Modal animated aria-label="Dialog" dialog={dialog} maxWidth="tw-max-w-screen-sm">
        {dialog.visible && <CollisionModal data={data.rows} dialog={dialog} />}
      </Modal>
    </>
  ) : null;
};

const formatGanNumber = (gan: string) => gan.match(/.{1,4}/g)?.join(' ');

type CollisionModalProps = {
  data: ReadGiftCardMappingDto[];
  dialog: DialogStateReturn;
};

const CollisionModal: React.FC<React.PropsWithChildren<CollisionModalProps>> = ({ data, dialog }) => {
  const { getIntlString } = useI18n();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Gift Card Number',
        accessor: 'gan',
        Cell: ({ value }: { value: string }) => (
          <div data-testid={`collision-gan-${value}`} className="tw-absolute tw-top-6">
            {formatGanNumber(value)}
          </div>
        ),
        className: 'tw-relative tw-w-56',
      },
      {
        Header: 'Collisions',
        accessor: 'replicas',
        Cell: ({ value: replicas, row: { original } }: { value: ReadGiftCardReplicaDto[]; row: { original: ReadGiftCardMappingDto } }) => (
          <div data-testid={`collision-details-${original.gan}`} className="tw-inline-flex tw-flex-col">
            {replicas.map((r) => (
              <div key={r.id} className="tw-inline-block tw-border tw-rounded tw-my-1.5 tw-py-2 tw-px-3">
                <div>
                  <span className="tw-text-gray-400">ExternalMerchantId:&nbsp;</span>
                  <span>{r.externalMerchantId}</span>
                </div>
                <div>
                  <span className="tw-text-gray-400">{getIntlString('giftCards.collision.card.status')}:&nbsp;</span>
                  <span>{r.entity.state}</span>
                </div>
                <div>
                  <span className="tw-text-gray-400">{getIntlString('giftCards.collision.card.balance')}:&nbsp;</span>
                  <span>{getIntlCurrency(((r.entity.balanceMoney?.amount as unknown) as number) / 100, { currency: r.entity.balanceMoney?.currency })}</span>
                </div>
              </div>
            ))}
          </div>
        ),
      },
    ],
    [getIntlString],
  );

  const props = useTable({
    data,
    columns,
  });
  return (
    <>
      <Modal.ModalHeaderWithCloseButton onClose={dialog.hide}>{getIntlString('giftCards.collision.modal.title')}</Modal.ModalHeaderWithCloseButton>
      <Modal.ModalContent>
        <div className="tw-max-h-144 tw-overflow-y-auto">
          <Table {...props} fixedHeader={true} />
        </div>
      </Modal.ModalContent>
    </>
  );
};
