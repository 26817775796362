import { CreateItemBaseDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseFacade } from '../../../facades';

export type MerchantCreateItemBaseParams = {
  merchantId: string;
  data: CreateItemBaseDto;
};

export const useMerchantCreateItemBase = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, MerchantCreateItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, MerchantCreateItemBaseParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantItemBaseFacade.create(merchantId, data), options);
};
