import React, { FC } from 'react';
import { useI18n } from '@goparrot-dashboard/i18n';

export const SynchronizationLabel: FC<React.PropsWithChildren<{ value: boolean }>> = ({ value }) => {
  const { getIntlString } = useI18n();
  return (
    <span>
      {getIntlString('page.merchant.franchise.menu.syncLabelPrefix', { isRootPath: true })}{' '}
      {value ? (
        <b>{getIntlString('page.merchant.franchise.menu.syncLabelOn', { isRootPath: true })}</b>
      ) : (
        <b>{getIntlString('page.merchant.franchise.menu.syncLabelOff', { isRootPath: true })}</b>
      )}
    </span>
  );
};
