import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';

import { franchiseUserFacade } from '../facades';

type DeleteFranchiseInviteParams = {
  uuid: string;
};

export const useDeleteFranchiseInvite = (
  options?: UseMutationOptions<void, unknown, DeleteFranchiseInviteParams>,
): UseMutationResult<void, unknown, DeleteFranchiseInviteParams> => {
  return useMutation(({ uuid }) => franchiseUserFacade.deleteFranchiseInvite(uuid), options);
};
