import { ICloneRequest, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationCloneStoreItemParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useLocationCloneStoreItem = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, LocationCloneStoreItemParams>,
): UseMutationResult<ReadStoreItemDto, unknown, LocationCloneStoreItemParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemFacade.clone(merchantId, storeId, uniqueName, data), options);
};
