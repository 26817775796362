import { ReadItemOptionsDto, PatchItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type LocationUpdateItemOptionsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchItemOptionsDto;
};

export const useLocationUpdateItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, LocationUpdateItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, LocationUpdateItemOptionsParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemOptionsFacade.patch(merchantId, storeId, uniqueName, data),
    options,
  );
};
