import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { appLocale } from '../../appLocale';
import { DEFAULT_LOCALE } from '../../constants';
import { I18nService } from '../I18nService';

interface Props {
  locale?: string;
  messages?: Record<string, string>;
  prefix: string;
}

export const IntlModuleProvider: React.FC<React.PropsWithChildren<Props>> = ({ locale = DEFAULT_LOCALE, messages, prefix, children }) => {
  const currentAppLocale = appLocale[locale];

  return (
    <IntlProvider locale={locale} messages={{ ...currentAppLocale.messages, ...messages }}>
      <I18nService prefix={prefix}>{children}</I18nService>
    </IntlProvider>
  );
};
