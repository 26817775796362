import { createIntl, createIntlCache } from 'react-intl';
import { appLocale } from './appLocale';
import { DEFAULT_LOCALE } from './constants';

let lang = DEFAULT_LOCALE;

// switch from directly 'store' import because of circular dependencies
// @ts-ignore store
export const setLocaleMiddleware = (store) => (next) => (action) => {
  const { locale } = store.getState().root.app;

  // can check LOCAL__APP__SET_LOCALE action if locale switcher will be added
  if (locale !== lang) {
    lang = locale;
  }

  return next(action);
};

const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: lang,
    messages: appLocale[lang].messages,
  },
  cache,
);
