import { IStoreFilterParams, ItemListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { locationStoreCombinedItemsFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<IStoreFilterParams> & {
  storeId: string;
};

export const useLocationStoreCombinedItemsList = (
  { merchantId, storeId, pagination, params }: QueryParams,
  options?: UseQueryOptions<ItemListResponseDto>,
): UseQueryResult<ItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.LOCATION_STORE_COMBINED_ITEMS_LIST, merchantId, storeId, pagination, params],
    async () => await locationStoreCombinedItemsFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
