import styled from 'styled-components';

import { palette } from 'styles/constants';
import { transition } from 'styles/util';

export const ButtonWrapper = (component) => styled(component)`
  &.ant-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.5;
    padding: 0 25px;
    font-size: 14px;
    height: 36px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    background-color: ${palette.secondary[12]};
    color: ${palette.secondary[13]};
    border: none;
    ${transition()};
  }

  &:hover {
    background-color: ${palette.secondary[14]};
  }

  &:active {
    background-color: ${palette.secondary[15]};
  }

  > .anticon + span,
  > span + .anticon {
    margin: 0 0 0 0.5em;
  }

  .anticon-right,
  .anticon-left {
    transform: rotate(0);
  }

  &.ant-btn-icon-only {
    padding: 0;
    min-width: 36px;
  }

  &.ant-btn-primary {
    background-color: ${palette.primary[19]};
    color: ${palette.color[20]};

    &:hover {
      background-color: ${palette.primary[20]};
    }

    &:active {
      background-color: ${palette.primary[21]};
    }
  }

  &.ant-btn-sm {
    padding: 0 15px;
    height: 28px;
    font-size: 12px;

    &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
      padding: 0 15px 0 24px;
      .anticon {
        margin: 0 0 0 -17px;
      }
    }
  }

  &.ant-btn-lg {
    padding: 0 35px;
    font-size: 14px;
    height: 42px;
  }

  &.ant-btn-dashed {
    border-style: dashed;
    border-color: ${palette.border[1]};

    &:hover {
      color: ${palette.primary[0]};
      border-color: ${palette.primary[0]};
    }
  }

  &.ant-btn-danger {
    background-color: ${palette.error[0]};
    border-color: ${palette.error[0]};
    color: #ffffff;

    &:hover {
      background-color: ${palette.error[2]};
      border-color: ${palette.error[2]};
    }

    &.ant-btn-background-ghost {
      color: ${palette.error[0]};
      background-color: transparent;
      border-color: ${palette.error[0]};

      &:hover {
        color: ${palette.error[2]};
        border-color: ${palette.error[2]};
      }
    }
  }

  &.ant-btn-circle,
  &.ant-btn-circle-outline {
    width: 35px;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
    height: 35px;

    &.ant-btn-sm {
      padding: 0;
      height: 28px;
      width: 28px;
      font-size: 12px;
    }

    &.ant-btn-lg {
      padding: 0;
      font-size: 14px;
      height: 42px;
      width: 42px;
    }
  }

  &.ant-btn.disabled,
  &.ant-btn[disabled],
  &.ant-btn.disabled:hover,
  &.ant-btn[disabled]:hover,
  &.ant-btn.disabled:focus,
  &.ant-btn[disabled]:focus,
  &.ant-btn.disabled:active,
  &.ant-btn[disabled]:active,
  &.ant-btn.disabled.active,
  &.ant-btn[disabled].active {
    background-color: ${palette.primary[22]};
    color: ${palette.primary[23]};
    cursor: not-allowed;
    border: none;
  }

  &.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) .anticon {
    margin: 0 0 0 -14px;
  }
  .ant-btn-group {
    margin-left: -1px !important;
    margin-right: 0 !important;
  }
`;

export const ButtonGroupWrapper = (component) => styled(component)`
  &.ant-btn-group {
    .ant-btn {
      margin: 0;
      margin-right: 0;
      display: inline-block;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      border-color: ${palette.border[1]};
      white-space: nowrap;
      line-height: 1.5;
      padding: 0 8px;
      font-size: 14px;
      border-radius: 4px;
      height: 36px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      ${transition()};

      &:hover {
        border-color: ${palette.primary[0]};
      }

      &.ant-btn-dashed {
        border-style: dashed;

        &:hover {
          border-color: ${palette.primary[0]};
        }
      }

      &.ant-btn-primary {
        border-color: ${palette.primary[0]};

        &:hover {
          border-color: ${palette.primary[10]};
        }
      }
    }

    > .ant-btn:first-child:not(:last-child) {
      border-radius: 4px 0 0 4px;
    }
    > .ant-btn:last-child:not(:first-child) {
      border-radius: 0 4px 4px 0;
    }

    > .ant-btn:not(:first-child),
    > .ant-btn:not(:first-child) {
      border-radius: 0;
    }

    .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-primary + .ant-btn-primary {
      border-left-color: ${palette.primary[2]};
      border-right-color: ${palette.primary[0]};
    }

    .ant-btn-primary:first-child:not(:last-child) {
      border-left-color: ${palette.primary[0]};
      border-right-color: ${palette.primary[2]};
    }

    .ant-btn + .ant-btn,
    + .ant-btn {
      margin-left: -1px !important;
      margin-right: 0 !important;
    }

    &.ant-btn-group-lg {
      > .ant-btn {
        padding: 0 35px;
        font-size: 14px;
        height: 42px;
      }
    }

    &.ant-btn-group-sm {
      > .ant-btn {
        padding: 0 15px;
        height: 28px;
        font-size: 12px;
      }
    }
  }

  &.ant-btn-group + &.ant-btn-group {
    margin-left: -1px !important;
    margin-right: 0 !important;
  }
`;
