import { get, assign } from 'lodash';
import {
  MerchantDto as MerchantModel,
  StoreDto as StoreModel,
  DefaultStoreDto as DefaultStoreModel,
  AbstractStoreScheduleDto as ScheduleModel,
  StoreScheduleDto as StoreScheduleModel,
  DefaultStoreScheduleDto as DefaultStoreScheduleModel,
  StoreContactInfoDto as StoreContactInfoModel,
  StoreManagerContactInfoDto as StoreManagerContactInfoModel,
  DefaultStoreContactInfoDto as DefaultStoreContactInfoModel,
  StorePickupDto as StorePickupModel,
  DefaultStorePickupDto as DefaultStorePickupModel,
  StoreDeliveryAreaDto as StoreDeliveryAreaModel,
  StoreDeliveryDto as StoreDeliveryModel,
  DefaultStoreDeliveryDto as DefaultStoreDeliveryModel,
  StoreDeliveryAreaCircleDto as StoreDeliveryAreaCircleModel,
  AbstractStoreCoordinatesDto as CoordinatesModel,
  PatchStoreDto as PatchStoreModel,
  PatchDefaultStoreDto as PatchDefaultStoreConfigurationsModel,
  StorePaymentDto as StorePaymentModel,
  PatchStoreFeaturesFlagsDto as StoreFeaturesFlagsModel,
  PatchDefaultStoreFeaturesFlagsDto as PatchDefaultStoreFeaturesFlagsModel,
  StoreDeliveryAreaTypeEnum,
  AbstractStoreDelayedOrdersDto as AbstractDelayedOrdersModel,
  AbstractStoreDelayedOrdersDelayRangeConfigDto as DelayedOrdersDelayRangeConfigModel,
  StorePushOrdersToNextDayByValueDto as StorePushOrdersToNextDayByValueModel,
  StoreGeoLocationDto as StoreGeoLocationModel,
  StoreIntervalSettingsDto as StoreIntervalSettingsModel,
  StoreTargetEnum,
  StoreSpecificTypeEnum,
  PatchStoreOrderDto,
  PatchDefaultStoreOrderDto,
} from '@goparrot/store-v2-sdk';
import { SupportedCurrencyEnum } from '@goparrot/common';
import { PatchWebStoreMetadataDto as PatchWebStoreMetadataModel } from '@goparrot/webstore-sdk';
import { plainToClass } from 'class-transformer';

MerchantModel.prototype.init = function (options = {}) {
  assign(this, new DefaultStoreModel().init(options));
  return this;
};

PatchWebStoreMetadataModel.prototype.init = function (options = {}) {
  assign(this, plainToClass(PatchWebStoreMetadataModel, options, { groups: ['patch'], excludeExtraneousValues: true }));

  return this;
};

PatchStoreModel.prototype.init = function (options = {}) {
  assign(this, plainToClass(PatchStoreModel, options, { groups: ['patch'], excludeExtraneousValues: true }));
  return this;
};

DefaultStoreModel.prototype.init = function (options = {}) {
  this.name = get(options, 'name', '');
  this.title = get(options, 'title', '');
  this.subtitle = get(options, 'subtitle', '');
  this.shortName = get(options, 'shortName', '');
  return this;
};
StoreModel.prototype.init = function (options = {}) {
  assign(this, new DefaultStoreModel().init(options));
  this.defaultStoreId = get(options, 'defaultStoreId', null);
  this.targetType = get(options, 'targetType', StoreTargetEnum.ONLINE);
  this.contactInfo = new StoreContactInfoModel().init(options.contactInfo);
  this.managerContactInfo = new StoreManagerContactInfoModel().init(options.managerContactInfo);
  this.location = new StoreGeoLocationModel().init(options.location);
  this.pickup = new StorePickupModel().init(options.pickup);
  this.payment = new StorePaymentModel().init(options.payment);
  this.specificType = get(options, 'specificType', StoreSpecificTypeEnum.REGULAR);

  if (options.specificCustomType !== undefined) {
    this.specificCustomType = get(options, 'specificCustomType', null);
  }

  if (options.posProvider !== undefined) {
    this.posProvider = options?.posProvider ?? '';
  }
  return this;
};

StoreGeoLocationModel.prototype.init = function (options = {}) {
  this.city = get(options, 'city', '');
  this.country = get(options, 'country', '');
  this.countryCode = get(options, 'countryCode', '');
  this.formattedAddress = get(options, 'formattedAddress', '');
  this.houseNumber = get(options, 'houseNumber', '');
  this.latitude = get(options, 'latitude');
  this.longitude = get(options, 'longitude');
  this.placeId = get(options, 'placeId', '');
  this.postalCode = get(options, 'postalCode', '');
  this.provider = get(options, 'provider');
  this.state = get(options, 'state', '');
  this.stateCode = get(options, 'stateCode', '');
  this.streetName = get(options, 'streetName', '');
  return this;
};

StoreContactInfoModel.prototype.init = function (options = {}) {
  this.phone = get(options, 'phone', '');
  this.email = get(options, 'email', '');
  return this;
};

StoreManagerContactInfoModel.prototype.init = function (options = {}) {
  this.phone = options?.phone ?? '';
  this.email = options?.email ?? '';
  return this;
};

DefaultStoreContactInfoModel.prototype.init = StoreContactInfoModel.prototype.init;

StoreFeaturesFlagsModel.prototype.init = function (options = {}) {
  assign(this, plainToClass(StoreFeaturesFlagsModel, options, { groups: ['patch'], excludeExtraneousValues: true }));
  return this;
};

PatchDefaultStoreFeaturesFlagsModel.prototype.init = function (options = {}) {
  assign(this, plainToClass(PatchDefaultStoreFeaturesFlagsModel, options, { groups: ['patch'], excludeExtraneousValues: true }));
  return this;
};

ScheduleModel.prototype.init = function (options = {}) {
  const setValue = (field) => {
    const value = get(options, field, []);
    if (null === value) {
      this[field] = null;
    } else if (value) {
      this[field] = value;
    }
  };
  setValue('mon');
  setValue('tue');
  setValue('wed');
  setValue('thu');
  setValue('fri');
  setValue('sat');
  setValue('sun');
  return this;
};

StoreScheduleModel.prototype.init = function (options = {}) {
  this.weekly = new ScheduleModel().init(options.weekly);
  this.dineIn = new ScheduleModel().init(options.dineIn);
  this.haveDineInHours = get(options, 'haveDineInHours', false);
  if (options.specialDays) {
    this.specialDays = options.specialDays;
  } else {
    this.specialDays = [];
  }
  return this;
};
DefaultStoreScheduleModel.prototype.init = StoreScheduleModel.prototype.init;

StorePickupModel.prototype.init = function (options = {}) {
  this.isEnabled = get(options, 'isEnabled', false);
  this.time = get(options, 'time', 0);
  if (options.address) {
    this.address = options.address;
  }
  this.isTipRequired = get(options, 'isTipRequired', false);
  this.isUsingStoreLocationAddress = options?.isUsingStoreLocationAddress ?? false;
  this.minOrderTotal = get(options, 'minOrderTotal', 0);
  this.maxOrderTotal = get(options, 'maxOrderTotal', 0);
  this.delayedOrders = new AbstractDelayedOrdersModel().init(options.delayedOrders);
  return this;
};

DefaultStorePickupModel.prototype.init = StorePickupModel.prototype.init;

CoordinatesModel.prototype.init = function (options = {}) {
  this.lat = get(options, 'lat', 0);
  this.lng = get(options, 'lng', 0);
  return this;
};
StoreDeliveryAreaCircleModel.prototype.init = function (options = {}) {
  this.radius = get(options, 'radius', 0);
  this.center = new CoordinatesModel().init(options.center);
  return this;
};

StoreDeliveryAreaModel.prototype.init = function (options = {}) {
  this.name = get(options, 'name', '');
  this.type = get(options, 'type', StoreDeliveryAreaTypeEnum.POLYGON);
  this.dataPolygon = get(options, 'dataPolygon', []);
  this.dataCircle = new StoreDeliveryAreaCircleModel().init(options.dataCircle);
  return this;
};
StoreDeliveryModel.prototype.init = function (options = {}) {
  this.isEnabled = get(options, 'isEnabled', false);
  this.isTipRequired = get(options, 'isTipRequired', false);
  this.time = get(options, 'time', 0);
  this.minOrderTotal = get(options, 'minOrderTotal', 0);
  this.maxOrderTotal = get(options, 'maxOrderTotal', 0);
  this.haveCustomSchedule = get(options, 'haveCustomSchedule', false);
  this.weeklySchedule = new ScheduleModel().init(options.weeklySchedule);
  this.delayedOrders = new AbstractDelayedOrdersModel().init(options.delayedOrders);
  return this;
};
DefaultStoreDeliveryModel.prototype.init = StoreDeliveryModel.prototype.init;

StoreIntervalSettingsModel.prototype.init = function (options = {}) {
  this.duration = options?.duration ?? 0;
  this.separator = options?.separator ?? '-';
  this.format = options?.format;

  return this;
};

AbstractDelayedOrdersModel.prototype.init = function (options = {}) {
  this.isEnabledDelayedOrder = get(options, 'isEnabledDelayedOrder', false);
  this.delayStepSize = get(options, 'delayStepSize', 30);
  this.maxDelay = get(options, 'maxDelay', 4320);
  this.minDelay = get(options, 'minDelay', 240);
  this.isDisabledASAP = get(options, 'isDisabledASAP', false);
  this.preFulfillmentMinutes = get(options, 'preFulfillmentMinutes', 60);
  this.extraPosPrintingMinutes = get(options, 'extraPosPrintingMinutes', 0);
  this.openingHoursDelay = options.openingHoursDelay ?? 0;
  this.delayRangeConfig = new DelayedOrdersDelayRangeConfigModel().init(options.delayRangeConfig);
  this.pushOrdersToNextDayByValue = new StorePushOrdersToNextDayByValueModel().init(options.pushOrdersToNextDayByValue);
  this.intervalSettings = new StoreIntervalSettingsModel().init(options?.intervalSettings);
  return this;
};

DelayedOrdersDelayRangeConfigModel.prototype.init = function (options = {}) {
  this.minMinutes = get(options, 'minMinutes', 60);
  this.maxMinutes = get(options, 'maxMinutes', 60 * 24 * 7);
  return this;
};

StorePushOrdersToNextDayByValueModel.prototype.init = function (options = {}) {
  this.isEnabled = options.isEnabled ?? false;
  this.startingTime = options.startingTime ?? '00:00';
  this.daysDelay = options.daysDelay ?? 0;
  return this;
};

PatchDefaultStoreConfigurationsModel.prototype.init = function (options = {}) {
  this.featuresFlags = new PatchDefaultStoreFeaturesFlagsModel().init(options.featuresFlags);
  return this;
};

StorePaymentModel.prototype.init = function (options = {}) {
  this.currency = get(options, 'currency', SupportedCurrencyEnum.USD);
  this.provider = get(options, 'provider', '');
  this.merchantTitle = get(options, 'merchantTitle', '');
  return this;
};

class StoreOrderModel extends PatchStoreOrderDto {
  init(options = {}) {
    Object.assign(this, plainToClass(PatchStoreOrderDto, options, { groups: ['patch'], excludeExtraneousValues: true }));
    return this;
  }
}

class DefaultStoreOrderModel extends PatchDefaultStoreOrderDto {
  init(options = {}) {
    Object.assign(this, plainToClass(PatchDefaultStoreOrderDto, options, { groups: ['patch'] }));
    return this;
  }
}

export {
  MerchantModel,
  StoreModel,
  DefaultStoreModel,
  ScheduleModel,
  StoreScheduleModel,
  DefaultStoreScheduleModel,
  StoreContactInfoModel,
  DefaultStoreContactInfoModel,
  StorePickupModel,
  DefaultStorePickupModel,
  StoreDeliveryAreaModel,
  StoreDeliveryModel,
  DefaultStoreDeliveryModel,
  StorePaymentModel,
  StoreFeaturesFlagsModel,
  PatchDefaultStoreFeaturesFlagsModel,
  StoreGeoLocationModel,
  PatchWebStoreMetadataModel,
  PatchDefaultStoreConfigurationsModel,
  PatchStoreModel,
  StoreOrderModel,
  DefaultStoreOrderModel,
};
