import { IStoreItemBundleMod, ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemBundleFacade } from '../../../facades';

type PatchComboGroupModParams = {
  merchantId: string;
  uniqueName: string;
  data: IStoreItemBundleMod;
};

export const usePatchComboGroupMod = (
  options?: UseMutationOptions<ReadStoreItemBundleDto, unknown, PatchComboGroupModParams>,
): UseMutationResult<ReadStoreItemBundleDto, unknown, PatchComboGroupModParams> => {
  return useMutation(({ merchantId, uniqueName, data }) => merchantStoreItemBundleFacade.updateMods(merchantId, uniqueName, data), options);
};
