import { combineReducers } from 'redux';

import list from './reducers/list';
import selected from './reducers/selected';
import customer from './reducers/customer';
import transaction from './reducers/transaction';
import transition from './reducers/transition';
import delivery from './reducers/delivery';
import throttling from './reducers/throttling';

export default combineReducers({
  list,
  selected,
  customer,
  transaction,
  transition,
  delivery,
  throttling,
});
