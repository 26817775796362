import { PatchSelectionTypeDto, ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantSelectionTypesFacade } from '../../../facades';

export type MerchantUpdateSelectionTypeParams = {
  merchantId: string;
  data: PatchSelectionTypeDto;
};

export const useMerchantUpdateSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, MerchantUpdateSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, MerchantUpdateSelectionTypeParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantSelectionTypesFacade.patch(merchantId, data), options);
};
