export enum LDFeatureFlags {
  FRANCHISE_TOTAL_SALES_REPORT_NEW = 'franchise-total-sales-report-new', // fully GA, but still used in tests
  FRANCHISE_CATEGORIES_SALES_REPORT_NEW = 'franchise-categories-sales-report-new',
  FRANCHISE_HOME_PAGE_TILES_NEW = 'franchise-home-page-tiles-new',
  FRANCHISE_HOME_PAGE_ITEMS_TABLE_NEW = 'franchise-home-page-items-table-new',
  FRANCHISE_HOME_PAGE_ITEMS_TABLE_OLD = 'franchise-home-page-items-table-old',
  FRANCHISE_HOME_PAGE_LOCATIONS_TABLE_NEW = 'franchise-home-page-locations-table-new',
  FRANCHISE_HOME_PAGE_PAST_THIRTY_DAYS_OLD = 'franchise-home-page-past-thirty-days-old',
  FRANCHISE_ITEMS_SALES_REPORT_NEW = 'franchise-items-sales-report-new',
  FRANCHISE_MODIFIER_SALES_REPORT_NEW = 'franchise-modifier-sales-report-new',
  FRANCHISE_REPORTING_BANNER_NEW = 'franchise-reporting-banner-new',
  FRANCHISE_ROYALTY_FEES = 'franchise-royalty-fees',
  FRANCHISE_SALES_TRENDS = 'franchise-sales-trends',
  FRANCHISE_LOYALTY_REPORT = 'franchise-loyalty-report',
}
