import { useDispatch } from 'react-redux';
import type { ReadMerchantDto } from '@goparrot/store-v2-sdk';
import aMerchant from '../../../../modules/client/src/store/merchant/actions';

type UseAfterUpdateMerchantResult = {
  (merchant: ReadMerchantDto): void;
};

export const useAfterMerchantUpdate = (): UseAfterUpdateMerchantResult => {
  const dispatch = useDispatch();
  const onAfterUpdate = (merchantData: ReadMerchantDto) => {
    dispatch(aMerchant.successUpdate(merchantData));
  };

  return onAfterUpdate;
};
