import { LocationStatusEnum } from '@goparrot/menu-orchestrator-sdk';
import { BADGE_STATUSES } from '../components/sync-status-badge';

export const getMenuOrchestratorLocationBadgeStatus = (status: LocationStatusEnum | undefined): BADGE_STATUSES => {
  switch (status) {
    case LocationStatusEnum.DONE:
      return BADGE_STATUSES.ACTIVE;
    case LocationStatusEnum.COPYING:
    case LocationStatusEnum.UPDATING:
      return BADGE_STATUSES.PENDING;
    case LocationStatusEnum.DRAFT:
    case LocationStatusEnum.QUEUED_TO_WORKER:
    case LocationStatusEnum.QUEUED_COPY:
      return BADGE_STATUSES.QUEUED;
    default:
      return BADGE_STATUSES.INACTIVE;
  }
};
