import React, { FC } from 'react';
import { MarketSelect, MarketList, MarketRow, MarketRadio } from '@market/react-bindings';
import { ORDERABILITY_OPTIONS, ORDERABILITY_VALUES } from './constants';

type Props = {
  initialValue: boolean;
  disabled: boolean;
  handleChange: (val: ORDERABILITY_VALUES) => void;
};

export const SelectReplicaOrderable: FC<React.PropsWithChildren<Props>> = React.memo(({ initialValue, disabled, handleChange }) => {
  const [value, setValue] = React.useState(initialValue ? ORDERABILITY_VALUES.ORDERABLE : ORDERABILITY_VALUES.NON_ORDERABLE);

  // if the initialValue is changed external, sync it up with out data
  React.useEffect(() => {
    setValue(initialValue ? ORDERABILITY_VALUES.ORDERABLE : ORDERABILITY_VALUES.NON_ORDERABLE);
  }, [initialValue]);

  return (
    <MarketSelect
      className="tw-h-10 tw-w-full"
      name="orderable"
      value={value}
      disabled={disabled || undefined}
      onMarketSelectValueDidChange={(e) => {
        const value = e?.target?.value as ORDERABILITY_VALUES;
        setValue(value);
        handleChange(value);
      }}
    >
      <MarketList slot="list">
        {ORDERABILITY_OPTIONS.map((option) => (
          <MarketRow key={option.value} value={option.value}>
            {option.label}
            <MarketRadio slot="control" />
          </MarketRow>
        ))}
      </MarketList>
    </MarketSelect>
  );
});
