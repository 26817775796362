import { CreateMenuConceptDto, ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantMenuConceptsFacade } from '../../../facades';

export type CreateMenuConceptParams = {
  merchantId: string;
  data: CreateMenuConceptDto;
};
export const useCreateMenuConcept = (
  options: UseMutationOptions<ReadMenuConceptDto, unknown, CreateMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, CreateMenuConceptParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantMenuConceptsFacade.create(merchantId, data), options);
};
