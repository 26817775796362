import { ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantMenuConceptsFacade } from '../../../facades';

export type DeleteMenuConceptParams = {
  merchantId: string;
  uniqueName: string;
};

export const useDeleteMenuConcept = (
  options?: UseMutationOptions<ReadMenuConceptDto, unknown, DeleteMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, DeleteMenuConceptParams> => {
  return useMutation(async ({ merchantId, uniqueName }) => await merchantMenuConceptsFacade.delete(merchantId, uniqueName), options);
};
