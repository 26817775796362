import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import franchiseActions from 'store/franchise/actions';

import { useGetMenuLocationList } from '@goparrot-dashboard-api/orchestration';
import type { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { StoreWithLocation } from '../types';
import { assignStoresToLocations } from '../utils';

const initialData: ReadLocationDto[] = [];

export function useLocations(
  merchantId: string,
  stores: ReadStoreDto[],
  enabled = true,
): { locations: StoreWithLocation[]; fetchLocations: () => void; isLoading: boolean; isFetched: boolean } {
  const dispatch = useDispatch();
  const { notifyError } = useNotifications();
  const { data: locations, isFetching, refetch, isFetched } = useGetMenuLocationList(merchantId, {
    initialData,
    enabled: enabled && !!merchantId && !!stores.length,
    retry: 1,
    onSuccess: (data) => dispatch(franchiseActions.successLocationsListGet(data)),
    onError: () =>
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.location.fetch.error.message'),
      }),
  });

  const assignedStoresToLocations = useMemo<StoreWithLocation[]>(() => {
    if (!locations?.length) return (initialData as unknown) as StoreWithLocation[];

    return assignStoresToLocations(stores, locations);
  }, [locations, stores]);

  return { locations: assignedStoresToLocations, fetchLocations: refetch, isLoading: isFetching, isFetched };
}
