import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MerchantFeaturesFlagsDto, ReadDefaultStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import * as React from 'react';

export type SidebarContextType = {
  isMerchant: boolean;
  selectedStore: string | null;
  merchantId: string;
  selectedStoreId?: ReadStoreDto | ReadDefaultStoreDto;
  featuresFlags?: MerchantFeaturesFlagsDto;
  shortTermFeatureFlags?: Record<string, unknown>;
};

const SidebarContext = React.createContext<SidebarContextType | undefined>(undefined);

export const SidebarProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    stores,
    selectedStoreId: selectedStore,
    defaultStores,
    merchant: { merchantId, featuresFlags, shortTermFeatureFlags },
    isMerchant,
  } = useStoreService();

  const selectedStoreId = selectedStore
    ? stores.find((item) => item.storeId === selectedStore) || defaultStores.find((item) => item.storeId === selectedStore)
    : undefined;

  const value: SidebarContextType = {
    isMerchant,
    selectedStore,
    merchantId,
    selectedStoreId,
    featuresFlags,
    shortTermFeatureFlags,
  };

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export const useSidebarContext = (): SidebarContextType => {
  const context = React.useContext(SidebarContext);

  if (undefined === context) {
    throw new Error('useSidebarContext must be used within a SidebarProvider');
  }

  return context;
};
