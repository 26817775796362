import { PatchAbstractModsDto, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type PatchCategoryModsParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchAbstractModsDto;
};

export const usePatchCategoryMods = (
  options: UseMutationOptions<ReadCategoryDto, unknown, PatchCategoryModsParams>,
): UseMutationResult<ReadCategoryDto, unknown, PatchCategoryModsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantCategoryFacade.patchMod(merchantId, uniqueName, data), options);
};
