import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { locationStoreItemComboFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useGetLocationComboChildrenQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<IReadStoreItemDto[], Error>,
): UseQueryResult<IReadStoreItemDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_COMBOS_GET_CHILDREN, merchantId, storeId, uniqueName],
    () => locationStoreItemComboFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
