import * as React from 'react';
import { createRoot } from 'react-dom/client';
import 'reflect-metadata';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import DashApp from 'components/containers/dash-app';
import { DD_LOGS } from './helpers/DD_LOGS';
import 'tailwind.css'; // eslint-disable-line
import { initialize as initDatadogRum } from './datadog';

DD_LOGS();

const LDProvider = withLDProvider({
  clientSideID: window._env_.LAUNCH_DARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(DashApp);

const root = document.getElementById('root');
const reactRoot = createRoot(root);

reactRoot.render(<LDProvider />);

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('components/containers/dash-app', () => {
    const NextApp = require('components/containers/dash-app').default;
    reactRoot.render(<NextApp />);
  });
}

initDatadogRum();
