import type {
  IReadStoreItemBundleDto,
  IReadStoreItemGroupDto,
  ReadMenuConceptDto,
  IReadStoreItemDto,
  IReadCategoryDto,
  IReadItemOptionsDto,
  IReadItemBaseDto,
  IReadItemSetDto,
  IReadItemBaseGroupDto,
  IItemOptionsMetadata,
  IItemBaseGroupMetadata,
  ItemGroupingTypeEnum,
  IReadStoreItemComboDto,
} from '@goparrot/storeitems-sdk';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { GetRelativesQueryBaseParams } from '@goparrot-dashboard-api/menu-management';
import { ISyncJobEvent } from '@goparrot/menu-sync-sdk';

export type ComboItem = (IReadStoreItemDto | IReadCategoryDto) & {
  quantity: number;
  showOrder: number;
};

export interface ItemSelected {
  errors: string[];
  data: ItemSelectedData;
  children: Item[];
  fetching: boolean;
  updateFetching: boolean;
  uniqueName: string;
  parents: Item[];
  parentData?: Item;
  options?: Item[];
  modifierGroups?: Item[];
}

export interface ItemSelectedData {
  title: string;
  uniqueName: string;
  merchantId: string;
  storeId: string;
  type: StoreItemTypeEnum;
  itemSet: string[];
  metadata?: IItemOptionsMetadata | IItemBaseGroupMetadata;
  groupingType?: ItemGroupingTypeEnum;
}

export type Item =
  | IReadCategoryDto
  | IReadStoreItemGroupDto
  | IReadStoreItemDto
  | IReadItemOptionsDto
  | IReadItemBaseDto
  | IReadItemSetDto
  | IReadItemBaseGroupDto
  | IReadStoreItemBundleDto
  | IReadStoreItemComboDto;
export type ConnectionItem = Item | ReadMenuConceptDto;

export type GetRelativesParams = GetRelativesQueryBaseParams & {
  storeId: string;
  isMerchant: boolean;
};

export interface WebsocketJobStore {
  data: ISyncJobEvent | Record<string, unknown>;
  fetching: boolean;
  error: boolean;
}

export type MenuManagementMatchParams = {
  id?: string;
  itemType?: StoreItemTypeEnum;
  itemStoreId?: string;
  uniqueName?: string;
  storeItemType?: VariationStoreItemTypeEnum;
  variationId?: string;
};

export enum VariationStoreItemTypeEnum {
  STORE_ITEM_VARIATION_GROUP = 'itemVariationGroup',
  STORE_ITEM_VARIATION = 'itemVariation',
}
