import { ReadPlacePickerTemplateDto } from '@goparrot/webstore-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { WEBSTORE_QUERIES_ENUM } from '../enums';
import { placePickerTemplateFacade } from '../facades';

export const useGetPlacePickerTemplate = (
  merchantId: string,
  options?: UseQueryOptions<ReadPlacePickerTemplateDto>,
): UseQueryResult<ReadPlacePickerTemplateDto> => {
  return useQuery([WEBSTORE_QUERIES_ENUM.PLACEPICKER_TEMPLATE, merchantId], () => placePickerTemplateFacade.get(merchantId), options);
};
