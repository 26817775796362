import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import type { ReadSettlementConfigurationDto, CreateSettlementConfigurationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { giftCardSettlementConfigurationFacade } from '../facades';

export type CreateGiftCardSettlementConfigParams = {
  merchantId: string;
  data: CreateSettlementConfigurationDto;
};

export const useCreateGiftCardSettlementConfig = (
  options?: UseMutationOptions<ReadSettlementConfigurationDto, unknown, CreateGiftCardSettlementConfigParams, unknown>,
): UseMutationResult<ReadSettlementConfigurationDto, unknown, CreateGiftCardSettlementConfigParams, unknown> => {
  return useMutation(({ merchantId, data }) => giftCardSettlementConfigurationFacade.create(merchantId, data), options);
};
