import * as React from 'react';
import { UseQueryResult } from 'react-query';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';
import { EntityFormParams, EntityFormResult, useEntityForm } from '@goparrot-dashboard/entity-form';

export type GenericMenuFormContextType<T, QueryType> = {
  form: EntityFormResult<T>;
  merchantId: string;
  storeId?: string;
  uniqueName?: string;
  isMerchant: boolean;
  selectedStoreId: string;
  isMerchantLevelItem: boolean;
  isCreating: boolean;
  query?: UseQueryResult<QueryType>;
  updateQueryData?(data: QueryType): void;
  isFranchise?: boolean;
  franchisePermissions?: FranchisePermissions | null;
};

export const GenericEditFormContext = React.createContext<unknown>({} as unknown);

const getContext = <T,>(): React.Context<T> => GenericEditFormContext as React.Context<T>;

export type GenericMenuFormProvider<T, QueryType> = {
  entityFormParams: EntityFormParams<T>;
  children: React.ReactNode | React.ReactNode[];
  merchantId: string;
  storeId?: string;
  uniqueName?: string;
  isMerchant: boolean;
  selectedStoreId: string;
  isMerchantLevelItem: boolean;
  isCreating: boolean;
  updateQueryData?(data: QueryType): void;
  query?: UseQueryResult<QueryType>;
  allowReset?: boolean;
  isFranchise?: boolean;
  franchisePermissions?: FranchisePermissions | null;
};

export const GenericMenuFormProvider = <T, QueryType = unknown>(props: GenericMenuFormProvider<T, QueryType>) => {
  const { children, entityFormParams, allowReset = true, ...rest } = props;
  const form = useEntityForm<T>(entityFormParams);
  const Context = getContext<GenericMenuFormContextType<T, QueryType>>();

  React.useEffect(() => {
    if (!allowReset) return;

    form.reset();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [entityFormParams]);

  return <Context.Provider value={{ form, ...rest }}>{children}</Context.Provider>;
};

export const useMenuFormContext = <T, QueryType = unknown>(): GenericMenuFormContextType<T, QueryType> => {
  const contextValue = React.useContext(GenericEditFormContext) as GenericMenuFormContextType<T, QueryType>;

  if (contextValue === undefined) {
    console.error('useMenuFormContext must be used within a GenericEditFormContext');
  }
  return contextValue;
};
