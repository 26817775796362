import { useInputProps } from '@goparrot-dashboard/entity-form';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { Button, IconQuestion, InputNumber, Tooltip } from '@goparrot-dashboard/shared-ui';
import React from 'react';
import { useMenuFormContext } from '../../context';

type Props = {
  field?: string;
  disabled?: boolean;
};

// TODO: move back to MM module if it's specific for category only
export const ShowOrderInput: React.FC<React.PropsWithChildren<Props>> = ({ field = 'metadata.show_order', disabled }) => {
  const { form } = useMenuFormContext();
  const bindInputProps = useInputProps(form);
  const inputProps = bindInputProps(field);

  return (
    <div className="tw-mr-9 last:tw-mr-0">
      <div className="tw-flex tw-items-center tw-mb-1">
        <h4 className="tw-blue-gray-900 tw-mb-0 tw-font-bold">{getIntlString('menu.form.showOrder')}</h4>
        <Tooltip tooltipProps={{ placement: 'top' }} tooltip={<div className="tw-max-w-xs">{getIntlString('menu.form.showOrder.tooltip')}</div>}>
          <Button kind="clean" color="clean" className="tw-w-4 tw-h-4 tw-outline-none tw-ring-transparent tw-ml-2">
            <IconQuestion className="tw-h-5" />
          </Button>
        </Tooltip>
      </div>
      <InputNumber
        {...inputProps}
        onChange={(value: number) => inputProps.onChange(value ?? -Infinity)}
        disabled={disabled}
        className="tw-w-24 tw-py-2"
        variant="outlined"
        decimalScale={0}
        min={1}
        allowNegative={false}
      />
    </div>
  );
};
