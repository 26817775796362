import { ICloneRequest, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseFacade } from '../../../facades';

export type MerchantCloneItemBaseParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneItemBaseSet = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, MerchantCloneItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, MerchantCloneItemBaseParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseFacade.clone(merchantId, uniqueName, data), options);
};
