import { PatchAbstractModsDto, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantPatchStoreItemModsParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchAbstractModsDto;
};

export const useMerchantPatchStoreItemMods = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, MerchantPatchStoreItemModsParams>,
): UseMutationResult<ReadStoreItemDto, unknown, MerchantPatchStoreItemModsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemFacade.patchMod(merchantId, uniqueName, data), options);
};
