import { PatchItemSetDto, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemSetFacade } from '../../../facades';

export type MerchantUpdateItemSetParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchItemSetDto;
};
export const useMerchantUpdateItemSet = (
  options: UseMutationOptions<ReadItemSetDto, unknown, MerchantUpdateItemSetParams>,
): UseMutationResult<ReadItemSetDto, unknown, MerchantUpdateItemSetParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemSetFacade.patch(merchantId, uniqueName, data), options);
};
