import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AuthResponseDto } from '@goparrot/dashboard-gateway-sdk';
import { AxiosError } from 'axios';
import { SsoCallbackParams } from '../interface';
import { squareSsoLoginCallback } from '../utils/squareSsoLoginCallback';

export const useSsoLoginCallback = (
  options?: UseMutationOptions<AuthResponseDto, AxiosError, SsoCallbackParams>,
): UseMutationResult<AuthResponseDto, AxiosError, SsoCallbackParams> => {
  return useMutation(squareSsoLoginCallback, options);
};
