import { useEffect } from 'react';
import { hasPermission, hasSomePermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useMenuFormContext } from '../context';

type Params = {
  isItemReplica: boolean;
};

export const useDisableFormByRestrictions = ({ isItemReplica }: Params): void => {
  const {
    isMerchantLevelItem,
    isCreating,
    form: { setIsDisabledForm },
  } = useMenuFormContext();
  const { isMerchant } = useStoreService();
  const { roleV2 } = useUserService();

  useEffect(() => {
    if (isCreating) return;

    const isModsMod = isMerchantLevelItem && !isMerchant;
    const canEditEntity = hasSomePermission(
      roleV2,
      isMerchant
        ? [PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_UPDATE]
        : [PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_UPDATE, PermissionsV2.STORE_MENU_MANAGEMENT.API_UPDATE],
    );
    const canEditFranchise = hasPermission(roleV2, PermissionsV2.FRANCHISE_MANAGEMENT.MENU_WRITE);

    if (isModsMod || !canEditEntity || (isItemReplica && !canEditFranchise)) {
      setIsDisabledForm(true);
    }
  }, [isMerchant, isMerchantLevelItem, roleV2, isItemReplica, setIsDisabledForm, isCreating]);
};
