import { assign } from 'lodash/fp';

import a from './actions';

const initialState = {
  selected: '',
  isMerchant: true,
  parentUrl: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.LOCAL__LEVEL__SELECT:
      return assign(state, {
        selected: action.payload.selected,
        isMerchant: action.payload.isMerchant,
        parentUrl: action.payload.parentUrl,
      });

    default:
      return state;
  }
}
