import { ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemBundleFacade } from '../../../facades';
import { ComboGroupCreateMutationParams } from '../../../interface';

export type LocationComboGroupCreateParams = ComboGroupCreateMutationParams & {
  storeId: string;
};

export const useCreateLocationComboGroup = (
  options?: UseMutationOptions<ReadStoreItemBundleDto, unknown, LocationComboGroupCreateParams>,
): UseMutationResult<ReadStoreItemBundleDto, unknown, LocationComboGroupCreateParams> => {
  return useMutation(({ merchantId, storeId, data }) => locationStoreItemBundleFacade.create(merchantId, storeId, data), options);
};
