import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Modal, InputNumber, FormField, Input } from '@goparrot-dashboard/shared-ui';
import { IItemOptions, IStoreItemPropertiesItemBaseOverrides } from '@goparrot/storeitems-sdk';
import { useI18n } from '@goparrot-dashboard/i18n';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { UseQueryResult } from 'react-query';
import isEqual from 'lodash/isEqual';
import { hasPermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { EditModifiersTable } from './EditModifiersTable';
import { OverrideFieldToUpdate, SubmitParams, ListItemType } from './interface';
import { InvalidSettingsInfoSection } from './InvalidSettingsInfoSection';

type Props = {
  editingModifierGroup: IItemOptions;
  mappedEditingModifier: ListItemType;
  closeModal: () => void;
  showingForm: boolean;
  itemOptionChildrensQuery: UseQueryResult<ReadItemBaseDto[]>;
  itemBaseOverrides: IStoreItemPropertiesItemBaseOverrides[];
  onSubmit: (payload: SubmitParams) => void;
  isEditingItemReplica: boolean;
};

export const EditModifiersContent: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  editingModifierGroup,
  mappedEditingModifier,
  closeModal,
  showingForm,
  itemOptionChildrensQuery,
  itemBaseOverrides,
  isEditingItemReplica,
}) => {
  const { getIntlString } = useI18n();
  const {
    metadata: { selections_max, selections_min },
  } = editingModifierGroup;
  const { roleV2 } = useUserService();
  const initialMaxSelections = mappedEditingModifier.maxSelections ?? selections_max;
  const initialMinSelections = mappedEditingModifier.minSelections ?? selections_min;
  const [selectionsMax, setSelectionsMax] = useState<number>(initialMaxSelections);
  const [selectionsMin, setSelectionsMin] = useState<number>(initialMinSelections);
  const [localOverrides, setLocalOverrides] = useState<IStoreItemPropertiesItemBaseOverrides[]>(itemBaseOverrides);
  const canEditModifiers = hasPermission(roleV2, PermissionsV2.FRANCHISE_MANAGEMENT.MENU_WRITE);

  const modifiersChanged = useMemo(() => !isEqual(itemBaseOverrides, localOverrides), [itemBaseOverrides, localOverrides]);
  const minOrMaxChanged = useMemo(() => selectionsMax !== initialMaxSelections || selectionsMin !== initialMinSelections, [
    selectionsMax,
    selectionsMin,
    initialMaxSelections,
    initialMinSelections,
  ]);
  const [customErrorMax, setCustomErrorMax] = React.useState<string>();
  const [customErrorMin, setCustomErrorMin] = React.useState<string>();

  const preselectedCount = useMemo(
    () =>
      localOverrides.reduce((counter, override) => {
        return override.ds?.selected ? counter + 1 : counter;
      }, 0),

    [localOverrides],
  );
  const enabledItemsCount = useMemo(
    () =>
      localOverrides.reduce((counter, override) => {
        return !override.isDisabled ? counter + 1 : counter;
      }, 0),
    [localOverrides],
  );
  const invalidMinItems = useMemo(() => enabledItemsCount < selectionsMin, [enabledItemsCount, selectionsMin]);
  const invalidMaxItems = useMemo(() => {
    if (0 === selectionsMax) {
      return false;
    }
    return preselectedCount > selectionsMax;
  }, [preselectedCount, selectionsMax]);

  const isDisabledSubmit = (!modifiersChanged && !minOrMaxChanged) || !!customErrorMax || !!customErrorMin || invalidMinItems || invalidMaxItems;

  useEffect(() => {
    if (0 !== selectionsMax) {
      const notPositiveNumber = !selectionsMax || selectionsMax < 0;
      const notBiggerThanMin = selectionsMax < selectionsMin;

      if (notPositiveNumber || notBiggerThanMin) {
        if (notPositiveNumber) {
          setCustomErrorMax(getIntlString('validations.max.positive-number'));
        } else if (notBiggerThanMin) {
          setCustomErrorMax(getIntlString('validations.max.compare-to-min'));
        }
      } else {
        setCustomErrorMax(undefined);
      }
    } else {
      setCustomErrorMax(undefined);
    }

    if (0 !== selectionsMin) {
      const notPositiveNumber = !selectionsMin || selectionsMin < 0;
      const notSmallerThanMax = selectionsMin > selectionsMax && 0 !== selectionsMax;

      if (notPositiveNumber || notSmallerThanMax) {
        if (notPositiveNumber) {
          setCustomErrorMax(getIntlString('validations.min.positive-number'));
        } else if (notSmallerThanMax) {
          setCustomErrorMax(getIntlString('validations.min.compare-to-max'));
        }
      } else {
        setCustomErrorMin(undefined);
      }
    } else {
      setCustomErrorMin(undefined);
    }
  }, [getIntlString, selectionsMax, selectionsMin]);

  const getUpdatedOverride = (override: IStoreItemPropertiesItemBaseOverrides, field: OverrideFieldToUpdate, newValue: boolean) => {
    if (OverrideFieldToUpdate.SELECTED === field) {
      return { ...override, isDisabled: false, ds: { ...override.ds, selected: newValue } };
    }
    return { ...override, isDisabled: newValue, ds: { ...override.ds, selected: false } };
  };
  const updateModifierOverride = useCallback(
    (itemBaseOverride: IStoreItemPropertiesItemBaseOverrides, newValue: boolean, field: OverrideFieldToUpdate) => {
      const existingOverride = localOverrides.find((override) => override.uid === itemBaseOverride.uid);

      if (existingOverride) {
        const updatedOverrides = localOverrides.map((override) => {
          if (override.uid === itemBaseOverride.uid) {
            const updatedOverride = getUpdatedOverride(override, field, newValue);
            return updatedOverride;
          }
          return override;
        });
        setLocalOverrides(updatedOverrides);
      } else {
        const updatedOverride = getUpdatedOverride(itemBaseOverride, field, newValue);
        const updatedOverrides = [...localOverrides, updatedOverride];
        setLocalOverrides(updatedOverrides);
      }
    },
    [localOverrides],
  );

  return (
    <>
      <Modal.ModalContent className="tw-max-h-144 tw-overflow-y-auto">
        {showingForm && (
          <div className="tw-space-y-3">
            {(invalidMinItems || invalidMaxItems) && <InvalidSettingsInfoSection />}
            <div>
              <FormField
                hasError={!!customErrorMax}
                errorMessage={customErrorMax}
                label={getIntlString('max-preselected')}
                labelSize={'64'}
                withValuePadding={false}
                input={
                  <InputNumber
                    className="tw-px-4"
                    disabled={isEditingItemReplica && !canEditModifiers}
                    value={selectionsMax}
                    onChange={setSelectionsMax}
                    name="selectionsMax"
                    customInput={Input}
                    placeholder={getIntlString('placeholder.enter-a-number', { isRootPath: true })}
                    isNumericString
                  />
                }
              />
              <FormField
                hasError={!!customErrorMin}
                errorMessage={customErrorMin}
                label={getIntlString('min-enabled')}
                labelSize={'64'}
                withValuePadding={false}
                input={
                  <InputNumber
                    className="tw-px-4"
                    disabled={isEditingItemReplica && !canEditModifiers}
                    value={selectionsMin}
                    onChange={setSelectionsMin}
                    name="selectionsMin"
                    customInput={Input}
                    placeholder={getIntlString('placeholder.enter-a-number', { isRootPath: true })}
                    isNumericString
                  />
                }
              />
            </div>

            <EditModifiersTable
              itemBaseOverrides={localOverrides}
              itemBaseList={itemOptionChildrensQuery.data || []}
              updateModifierOverride={updateModifierOverride}
              minEnabledItems={selectionsMin}
              maxPreselectedItems={selectionsMax}
              preselectedCount={preselectedCount}
              enabledItemsCount={enabledItemsCount}
              isEditingItemReplica={isEditingItemReplica}
            />
          </div>
        )}
      </Modal.ModalContent>
      <Modal.ModalFooter>
        <div className="tw-flex tw-justify-end tw-pt-3">
          <Button color="secondary" onClick={closeModal}>
            {getIntlString('button.cancel', { isRootPath: true })}
          </Button>

          <Button
            disabled={isDisabledSubmit || (isEditingItemReplica && !canEditModifiers)}
            className="tw-ml-3"
            onClick={() => onSubmit({ newOverrides: localOverrides, selectionsMax, selectionsMin })}
          >
            {getIntlString('button.save', { isRootPath: true })}
          </Button>
        </div>
      </Modal.ModalFooter>
    </>
  );
};
