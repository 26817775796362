import { ReadTypeConfigDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { merchantSelectionTypesFacade } from '../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';

type MerchantGetSelectionTypeListParams = { merchantId: string };

export const useMerchantGetSelectionTypeList = (
  { merchantId }: MerchantGetSelectionTypeListParams,
  options?: UseQueryOptions<ReadTypeConfigDto[]>,
): UseQueryResult<ReadTypeConfigDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_GET_SELECTION_TYPE, merchantId],
    async () => await merchantSelectionTypesFacade.list(merchantId),
    options,
  );
};
