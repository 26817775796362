const c = {
  LOCAL__LEVEL__SELECT: 'LOCAL__LEVEL__SELECT',
};

/**
 *
 * @type {{select: (function(*, boolean=, string|null=): {payload: {parentUrl: null, isMerchant: boolean, selected: *}, type: string})}}
 */
const a = {
  select: (selected, isMerchant = true, parentUrl = null) => ({
    type: c.LOCAL__LEVEL__SELECT,
    payload: {
      selected,
      isMerchant,
      parentUrl,
    },
  }),
};

export default { ...c, ...a };
