import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { locationItemBaseFacade } from '../../../facades';

type ItemBaseGetParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationItemBaseGet = (
  { merchantId, storeId, uniqueName }: ItemBaseGetParams,
  options?: UseQueryOptions<ReadItemBaseDto>,
): UseQueryResult<ReadItemBaseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_GET, merchantId, storeId, uniqueName],
    async () => await locationItemBaseFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
