import React, { useEffect, useState, useMemo } from 'react';
import { Divider, InModal } from '@goparrot-dashboard/shared-ui';
import isEqual from 'lodash/isEqual';
import { MarketButton, MarketHeader, MarketModalPartial } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { Search } from '@goparrot-dashboard/shared-utils';
import { ConnectionItem } from '../../../interface';
import { useConnectionsContext } from '../context';
import { isArchived, isMenuConcept } from '../../../utils';
import { ModalItemList } from './ModalItemList';
import { ModalSelectedItems } from './ModalSelectedItems';
import { ModalItemPreview } from './preview';

type Props = {
  title: string;
  filterAddConnectionsListBySearchQuery: <T extends ConnectionItem>(list: T[], searchQuery: string) => T[];
};

const FIRST_ITEMS = 100;

export const AddConnectionsModal: React.FC<React.PropsWithChildren<Props>> = ({
  title,

  filterAddConnectionsListBySearchQuery,
}): React.ReactElement => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const {
    itemsList,
    selectedItems,
    onUpdateConnections,
    currentConnections,
    transformToState,
    selectedLevelStoreId,
    merchantId,
    storeId,
    setShowConnectionModal,
    isMerchantLevelItem,
  } = useConnectionsContext();
  const [items, setItems] = useState<ConnectionItem[]>(itemsList);
  const isMerchant = !selectedLevelStoreId || selectedLevelStoreId === merchantId;
  const filteredItems = useMemo(
    () =>
      (items || [])
        .filter((item) => isMenuConcept(item) || !isArchived(item, isMerchant, selectedLevelStoreId))
        .filter((item) => {
          // in case when user edit connections of the store level item from merchant level,
          // list of possible connections should contain only merchant and item's store items
          // e.g. editing item belongs to location 2, merchant level; at connection add modal user can see list of merchant and location 2 items
          if (isMerchant && !isMerchantLevelItem) {
            return item.storeId === storeId || item.storeId === merchantId;
          }
          return isMerchant ? true : item.storeId === selectedLevelStoreId || item.storeId === merchantId;
        })
        .slice(0, FIRST_ITEMS),
    [isMerchant, isMerchantLevelItem, items, merchantId, selectedLevelStoreId, storeId],
  );

  const connectionsChanged = useMemo(() => {
    const current = currentConnections.map(({ uniqueName }) => uniqueName).sort();
    const selected = selectedItems.map(({ uniqueName }) => uniqueName).sort();

    return !isEqual(current, selected);
  }, [currentConnections, selectedItems]);

  const [previewItem, setPreviewItem] = useState<ConnectionItem>(filteredItems[0]);

  const closeModal = () => {
    setShowConnectionModal(false);
  };

  const handleSubmit = () => {
    onUpdateConnections(transformToState ? selectedItems : selectedItems.map(({ uniqueName }) => uniqueName));
    setShowConnectionModal(false);
  };

  useEffect(() => {
    const filteredList = filterAddConnectionsListBySearchQuery(itemsList, searchTerm);

    setItems(filteredList);
  }, [itemsList, searchTerm, filterAddConnectionsListBySearchQuery]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.addEventListener('keydown', handleEscKey, false);
    };
  }, []);

  return (
    <InModal>
      <MarketModalPartial className="tw-max-w-screen-lg tw-h-screen tw-w-full tw-pb-0" onMarketDialogDismissed={closeModal} trapFocus>
        <MarketHeader className="tw-mb-0">
          <MarketButton rank="primary" slot="actions" onClick={handleSubmit} disabled={!connectionsChanged || undefined}>
            {getIntlString('button.add', { isRootPath: true })}
          </MarketButton>
        </MarketHeader>

        <main className="tw-px-0 tw-flex tw-flex-col tw-overflow-hidden">
          <div className="tw-px-8 tw-h-28">
            <h2>
              {title ? `${getIntlString('button.add', { isRootPath: true })} ${title}` : getIntlString('menu.connections.actions.add', { isRootPath: true })}
            </h2>
            <div className="tw-flex tw-mb-2 tw-mt-6">
              <div className="tw-w-96">
                <Search value={searchTerm} onSearch={setSearchTerm} placeholder={getIntlString('button.search')}></Search>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-1 tw-h-full tw-border-t tw-border-t-px tw-border-blue-gray-900 tw-border-opacity-10 tw-overflow-hidden">
            <div className="tw-border-r tw-border-r-px tw-border-blue-gray-900 tw-border-opacity-10 tw-w-4/12">
              <div className="tw-h-full tw-flex tw-flex-col">
                <div className="tw-flex-grow">
                  <ModalItemList items={filteredItems} previewItem={previewItem} setPreviewItem={setPreviewItem} />
                  <Divider />
                </div>
                <ModalSelectedItems />
              </div>
            </div>

            <div className="tw-w-8/12 tw-overflow-y-auto">
              <ModalItemPreview item={previewItem} />
            </div>
          </div>
        </main>
      </MarketModalPartial>
    </InModal>
  );
};
