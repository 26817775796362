import { ReadVendorDto, CreateVendorDto } from '@goparrot/vendor-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { vendorFacade } from '../facades';

type CreateVendorParams = {
  payload: CreateVendorDto;
};

export const useCreateVendor = (
  options?: UseMutationOptions<ReadVendorDto, unknown, CreateVendorParams, unknown>,
): UseMutationResult<ReadVendorDto, unknown, CreateVendorParams, unknown> => {
  return useMutation(({ payload }) => vendorFacade.create(payload), options);
};
