import { put, takeEvery, call } from 'redux-saga/effects';

import aApp from 'store/app/actions';

import * as api from './api';
import { getWebStoreActions, WebStoreActions } from './actions';

export function* handleGetWebStoreUrls({ payload }: ReturnType<typeof getWebStoreActions.requestGetWebStoreUrls>): Generator {
  try {
    const res = yield call(api.getWebStoreUrls(payload.storeId));
    yield put(getWebStoreActions.successGetWebStoreUrls(res));
  } catch (e) {
    yield put(getWebStoreActions.errorGetWebStoreUrls(e));
    yield put(aApp.pushNotificationWithError('payment-features.webstore-urls.error.message', 'payment-features.webstore-urls.error.description'));
  }
}

export default function* (): Generator {
  yield takeEvery(WebStoreActions.API__GET__WEB__STORE__URLS__REQUEST, handleGetWebStoreUrls);
}
