import { useCallback } from 'react';
import { ExcludeLocationsDto } from '@goparrot/giftcard-orchestrator-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { giftCardMerchantLocationFacade } from '../api';

export const useDeleteGiftCardLocations = (
  onSuccess: () => void = () => null,
): {
  deleteLocations(merchantId: string, storeIds: string[]): void;
} => {
  const { notifyError, notifySuccess } = useNotifications();
  const deleteLocations = useCallback(
    async (merchantId: string, storeIds: string[]) => {
      const payload: ExcludeLocationsDto = {
        storeIds,
      };
      try {
        await giftCardMerchantLocationFacade.excludeLocations(merchantId, payload);
        onSuccess();
        notifySuccess({
          text: getIntlString('page.merchant.franchise.notification.location.delete.success.message'),
        });
      } catch (e) {
        notifyError({
          text: getIntlString('page.merchant.franchise.notification.location.delete.error.message'),
        });
      }
    },
    [onSuccess],
  );

  return { deleteLocations };
};
