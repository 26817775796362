import * as React from 'react';

export const useInitialRender = () => {
  const firstRenderRef = React.useRef(true);

  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    }
  }, []);

  return { isFirstRender: firstRenderRef.current };
};
