import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { ReadMenuConceptDto, ReadTaxDto } from '@goparrot/storeitems-sdk';
import get from 'lodash/get';
import { IS_AVAILABLE } from '../constants';
import { Item } from '../interface';
import { findMod } from './findMod';
import { isMerchantLevel } from './isMerchantLevel';

interface IsGroupedVersion {
  isGroupedVersion?: boolean;
}

export const filterActions = {
  byTitle: (item: Item | ReadMenuConceptDto | ReadStoreDto, title: string): boolean => item?.title?.toLowerCase()?.includes(title?.trim().toLowerCase()),
  byName: (item: ReadTaxDto, name: string): boolean => item?.name?.toLowerCase()?.includes(name?.trim().toLowerCase()),
  byTitleEqual: (item: Item | ReadMenuConceptDto | ReadStoreDto, title: string): boolean => item?.title?.toLowerCase() === title?.trim().toLowerCase(),
  byInternalName: (item: Item, internalName: string): boolean => item?.internalName?.toLowerCase()?.includes(internalName?.toLowerCase()),
  byInternalNameEqual: (item: Item, internalName: string): boolean => item?.internalName?.toLowerCase() === internalName?.toLowerCase(),
  byPosGuid: (item: Item, posGuid: string): boolean =>
    (item?.metadata.pos || {}).pos_guid ? item?.metadata?.pos.pos_guid?.toLowerCase()?.includes(posGuid?.trim().toLowerCase()) : false,
  byPosGuidEqual: (item: Item, posGuid: string): boolean =>
    (item?.metadata.pos || {}).pos_guid ? item?.metadata?.pos.pos_guid?.toLowerCase() === posGuid?.trim().toLowerCase() : false,
  isArchived: (item: Item, toggleValue: boolean): boolean => item?.isArchived === toggleValue,
  isAvailable: (item: Item, toggleValue: boolean, isMerchant: boolean, selectedStoreId: string): boolean =>
    checkAvailability({ item, isMerchant, selectedStoreId }) === toggleValue,
  isOrderable: (item: Item, toggleValue: boolean): boolean => item?.isOrderable === toggleValue,
  isEnabled: (item: ReadMenuConceptDto, toggleValue: boolean): boolean => item?.isEnabled === toggleValue,
};

type CheckAvailabilityProps = { item: Item & IsGroupedVersion; isMerchant: boolean; selectedStoreId: string };
const checkAvailability = ({ item, isMerchant, selectedStoreId }: CheckAvailabilityProps) => {
  if (isMerchant || item.isGroupedVersion || item.type === StoreItemTypeEnum.ITEM_SET) {
    return item[IS_AVAILABLE];
  } else if (isMerchantLevel(item)) {
    if (!item[IS_AVAILABLE]) {
      return item[IS_AVAILABLE];
    }
    const foundMod = findMod(selectedStoreId, item.mods);
    return get(foundMod, IS_AVAILABLE, item[IS_AVAILABLE]);
  }
  return item[IS_AVAILABLE];
};
