/* eslint-disable max-len */
import { assign } from 'lodash/fp';

import c from './actions';

const initialState = {
  data: [],
  fetching: false,
  errors: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case c.API__LOCATION_DEFAULT__GET__REQUEST:
      return assign(initialState, {
        fetching: true,
      });

    case c.API__LOCATION_DEFAULT__CREATE__REQUEST:
    case c.API__LOCATION_DEFAULT__UPDATE__REQUEST:
    case c.API__LOCATION_DEFAULT__DELETE__REQUEST:
      return assign(state, { fetching: true });

    case c.API__LOCATION_DEFAULT__GET__SUCCESS:
      return assign(initialState, { data: action.payload });

    case c.API__LOCATION_DEFAULT__CREATE__SUCCESS:
      return assign(initialState, {
        data: state.data.concat(action.payload),
      });
    case c.API__LOCATION_DEFAULT__UPDATE__SUCCESS:
      return assign(initialState, {
        data: state.data.map((item) => {
          if (item.storeId === action.payload.storeId) {
            return action.payload;
          }
          return item;
        }),
      });

    case c.API__LOCATION_DEFAULT__DELETE__SUCCESS:
      return assign(initialState, {
        data: state.data.filter((item) => item.storeId !== action.payload),
      });

    case c.API__LOCATION_DEFAULT__CREATE__ERROR:
    case c.API__LOCATION_DEFAULT__UPDATE__ERROR:
    case c.API__LOCATION_DEFAULT__DELETE__ERROR:
      return assign(state, { fetching: false });

    case c.API__LOCATION_DEFAULT__GET__ERROR:
      return initialState;

    default:
      return state;
  }
}
