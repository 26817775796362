import { ReadTypeConfigDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationSelectionTypesFacade } from '../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';

type LocationGetSelectionTypeListParams = { merchantId: string; storeId: string };

export const useLocationGetSelectionTypeList = (
  { merchantId, storeId }: LocationGetSelectionTypeListParams,
  options?: UseQueryOptions<ReadTypeConfigDto[]>,
): UseQueryResult<ReadTypeConfigDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_GET_SELECTION_TYPE, merchantId, storeId],
    async () => await locationSelectionTypesFacade.list(merchantId, storeId),
    options,
  );
};
