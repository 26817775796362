import React, { useEffect, useState, FC } from 'react';
import classNames from 'classnames';
import { useRadioState } from 'reakit';
import { Select, Button, InlineSelect, BaseSelectComponents } from '@goparrot-dashboard/shared-ui';
import { hasProperty } from '@goparrot-dashboard/shared-utils';
import { SelectOptionType, SelectValueType } from '@goparrot-dashboard/shared-ui/src/components/select/types';
import { components, ValueContainerProps } from 'react-select';
import { getMenuFormIntlString } from '../utils';

const ValueContainer: FC<React.PropsWithChildren<unknown>> = ({ children, ...props }: ValueContainerProps<SelectOptionType, true | false>) => {
  if (props.isMulti && props.getValue()?.length > 0) {
    const { renderMultiValueLabel, selectedValuesMaxCount } = props?.selectProps;
    const [chips, element] = children as any;
    // this allows us to display a given {selectedValuesMaxCount} number of selected options
    const displayChips =
      selectedValuesMaxCount !== undefined && selectedValuesMaxCount < chips.length
        ? [chips.slice(chips.length - selectedValuesMaxCount, chips.length), element]
        : children;
    const hasMoreOptions = chips.length > selectedValuesMaxCount;
    return (
      <div className="tw-text-light-blue-400 tw-px-3 tw-flex-1">
        <div className="tw-border-b tw-border-cool-gray-200 tw-py-3 tw-mb-2">{renderMultiValueLabel(props.getValue())}</div>
        <components.ValueContainer {...props} className="tw-bg-transparent tw-pl-0 tw-pr-24 tw-pt-0">
          {displayChips}
          {hasMoreOptions && <span className="tw-text-blue-gray-900 tw-pt-2.5 tw-mr-32">...</span>}
        </components.ValueContainer>
      </div>
    );
  }
  return (
    <components.ValueContainer {...props} className="tw-bg-transparent tw-px-3 tw-text-light-blue-400">
      {children}
    </components.ValueContainer>
  );
};

interface Props {
  disabled?: boolean;
  onBlur: () => void;
  value: SelectValueType[];
  onChange: (value: SelectValueType[]) => void;
  availableDiningOptions: Record<SelectValueType, string>;
  allDiningOptions: Record<SelectValueType, string>;
  getSelectedLabel: (options: any[]) => string;
}

const ALL_OPTION = 'Select all';

const inlineSelectOptions = [
  { value: 0, label: getMenuFormIntlString('dining-options.radio.all') },
  { value: 1, label: getMenuFormIntlString('dining-options.radio.specific') },
];

export const ItemDiningOptionsSelect: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  onBlur,
  disabled,
  availableDiningOptions,
  allDiningOptions,
  getSelectedLabel,
}) => {
  const radioState = useRadioState({ state: 0 });
  const { setState } = radioState;

  const [allOptionSelected, setInlineSelectOption] = useState<number>(value?.length ? 1 : 0);

  const handleSelectChange = (selectedOptions: string[]) => {
    if (selectedOptions.includes(ALL_OPTION)) {
      return onChange(Object.keys(availableDiningOptions));
    }
    if (!selectedOptions.length) {
      setInlineSelectOption(0);
    }
    return onChange(selectedOptions as SelectValueType[]);
  };

  const handleSelectAllOptions = (values: number) => {
    if (values) {
      onChange([]);
    }
    setInlineSelectOption(values);
  };

  useEffect(() => {
    setState(allOptionSelected);
  }, [allOptionSelected, setState]);

  const hideAllOptionFromMenu = (options: SelectOptionType[]): SelectOptionType[] =>
    options.filter((option) => {
      if (Object.keys(availableDiningOptions).length === value?.length) return option.value !== ALL_OPTION;
      return true;
    });

  const options = [
    {
      value: ALL_OPTION,
      label: ALL_OPTION,
    },
    ...Object.keys(allDiningOptions).map((key) => {
      const isUnavailableOption = !hasProperty(availableDiningOptions, key);

      return {
        value: key,
        label: isUnavailableOption ? `${allDiningOptions[key]} (${getMenuFormIntlString('dining-options.option.not-available')})` : allDiningOptions[key],
        disabled: isUnavailableOption,
      };
    }),
  ];

  return (
    <div className="tw-relative tw-w-full">
      <div className="tw-absolute tw-z-1 tw-right-0 tw-bottom-2 tw-flex tw-items-center">
        {!!value?.length && (
          <Button disabled={disabled} color="accent" className="tw-h-8 tw-mr-2" onClick={() => handleSelectChange([])}>
            {getMenuFormIntlString('dining-options.clear-all')}
          </Button>
        )}
      </div>
      <div className={classNames(['tw-py-1.5 tw-px-4', allOptionSelected && 'tw-border-b tw-border-gray-200'])}>
        <InlineSelect disabled={disabled} options={inlineSelectOptions} ariaLabel="dining-options" state={radioState} onChange={handleSelectAllOptions} />
      </div>
      {!!allOptionSelected && (
        <Select
          overrideComponents={{
            ...BaseSelectComponents,
            ValueContainer,
          }}
          isMulti
          variant="base"
          disabled={disabled}
          menuPortalTarget={document.body}
          placeholder={getMenuFormIntlString('dining-options.select.placeholder')}
          value={value}
          onChange={handleSelectChange}
          onBlur={onBlur}
          options={hideAllOptionFromMenu(options)}
          isOptionDisabled={(option) => !!(option as { disabled?: boolean })?.disabled}
          menuShouldBlockScroll
          renderMultiValueLabel={getSelectedLabel}
          closeMenuOnSelect={false}
          isClearable={false}
          isSearchable={true}
          menuPosition="fixed"
        />
      )}
    </div>
  );
};
