import { Button, IconHide, IconShow } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import React from 'react';

type Props = {
  showArchived: boolean;
  onClick(status: boolean): void;
  numberOfArchived?: number;
  isFranchise?: boolean;
};

export const ShowArchivedConnectionsButton: React.FC<React.PropsWithChildren<Props>> = ({ showArchived, onClick, numberOfArchived, isFranchise }) => {
  return (
    <Button size="small" color="secondary" className="tw-bg-cool-gray-100 tw-p-1.5 tw-justify-center" onClick={() => onClick(!showArchived)}>
      <div className="tw-flex tw-text-sm tw-font-normal tw-text-cool-gray-700 tw-items-center tw-space-x-1">
        {showArchived ? <IconHide /> : <IconShow />}

        <span>{showArchived ? getIntlString('menu.form.archivedHide') : getIntlString('menu.form.archivedShow')}</span>
        {isFranchise && <span className="tw-ml-1">({numberOfArchived})</span>}
      </div>
    </Button>
  );
};
