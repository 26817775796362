import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, InputNumber } from '@goparrot-dashboard/shared-ui';
import { ReadItemBaseMetadataDto } from '@goparrot/storeitems-sdk';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString, preventMinusKey } from './utils';

export const ItemWeightAmount = <T extends { metadata: ReadItemBaseMetadataDto }>(): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();
  const { getFieldValue } = form;

  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);
  const amountProps = bindInputProps('metadata.weight.amount');
  const [amount, setAmount] = React.useState<number | undefined>(amountProps.value);
  const onAmountChange = (value: number | undefined) => {
    setAmount(value);
    amountProps.onChange(value);
  };

  return (
    <FormField
      {...bindFormFieldProps('metadata.weight.amount')}
      label={getMenuFormIntlString('amount')}
      input={
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full">
          <InputNumber
            {...amountProps}
            value={amount}
            onChange={onAmountChange}
            onKeyDown={preventMinusKey}
            name="metadata.weight.amount"
            className="tw-pr-2"
            decimalScale={2}
          />
          <span className="tw-leading-none">{getFieldValue('metadata.weight.unit')}</span>
        </div>
      }
    />
  );
};
