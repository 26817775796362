import styled from 'styled-components';

import { Alert as AntAlert } from '../feedback';

export const Alert = styled<any>(AntAlert)`
  &.ant-alert {
    width: 80%;
    max-width: 800px;
    margin-left: 40px;
    margin-top: 40px;

    &.ant-alert-with-description {
      .ant-alert-message {
        font-size: 20px;
      }

      .ant-alert-description {
        font-size: 15px;
      }
    }
  }
`;
