import { ICategory, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type MerchantCreateCategoryParams = {
  merchantId: string;
  data: Partial<ICategory>;
};

export const useMerchantCreateCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, MerchantCreateCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, MerchantCreateCategoryParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantCategoryFacade.create(merchantId, data), options);
};
