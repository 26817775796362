import { Matches } from 'class-validator';
import { Match } from '../validators/Match';

export class PasswordWithConfirmationModel {
  @Matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/, {
    message: 'Your password must be at least eight characters long and use a combination of uppercase letters, lowercase letters, and numbers',
  })
  password: string;

  @Match('password', {
    message: 'Passwords do not match',
  })
  repeatPassword: string;

  init(options: Partial<this>): this {
    Object.assign(this, options);
    return this;
  }
}
