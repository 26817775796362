import React, { useCallback, useMemo } from 'react';
import { Toggle, GenericTable, TableCellText } from '@goparrot-dashboard/shared-ui';
import { useSortBy, useTable } from 'react-table';
import { useI18n } from '@goparrot-dashboard/i18n';
import { IStoreItemPropertiesItemBaseOverrides, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { OverrideFieldToUpdate } from './interface';

interface Props {
  itemBaseOverrides: IStoreItemPropertiesItemBaseOverrides[];
  itemBaseList: ReadItemBaseDto[];
  updateModifierOverride: (itemBaseOverride: IStoreItemPropertiesItemBaseOverrides, value: boolean, field: OverrideFieldToUpdate) => void;
  minEnabledItems: number;
  maxPreselectedItems: number;
  enabledItemsCount: number;
  preselectedCount: number;
  isEditingItemReplica: boolean;
}

const DEFAULT_OVERRIDE: IStoreItemPropertiesItemBaseOverrides = {
  uid: null,
  isDisabled: false,
  ds: { selected: false },
};

export const EditModifiersTable: React.FC<React.PropsWithChildren<Props>> = ({
  itemBaseOverrides,
  itemBaseList,
  updateModifierOverride,
  minEnabledItems,
  maxPreselectedItems,
  preselectedCount,
  enabledItemsCount,
  isEditingItemReplica,
}) => {
  const { getIntlString } = useI18n();
  const dataSource = useMemo(() => {
    const data = itemBaseList
      .filter((item) => !item.isArchived)
      .map((itemBase) => {
        const { uniqueName } = itemBase;
        const override = itemBaseOverrides.find((item) => item.uid === uniqueName);
        return override ?? { ...DEFAULT_OVERRIDE, uid: uniqueName };
      });
    return data;
  }, [itemBaseList, itemBaseOverrides]);

  const getModifierName = useCallback((uid: string): string => itemBaseList.find((itemBase) => uid === itemBase.uniqueName)?.title, [itemBaseList]);

  const hasPreselectedMaxItems = useMemo(() => {
    if (0 === maxPreselectedItems) {
      return false;
    }
    return preselectedCount >= maxPreselectedItems;
  }, [maxPreselectedItems, preselectedCount]);

  const hasMinItemsEnabled = useMemo(() => enabledItemsCount <= minEnabledItems, [enabledItemsCount, minEnabledItems]);

  const columns = React.useMemo(
    () => [
      {
        Header: getIntlString('table.title'),
        accessor: 'uid',
        key: 'title',
        Cell: ({ value }: { value: string }) => (
          <TableCellText>
            <div className="tw-inline">{getModifierName(value)}</div>
          </TableCellText>
        ),
        sorting: true,
        className: 'tw-w-1/5',
      },
      {
        Header: getIntlString('table.preselected'),
        accessor: 'ds.selected',
        key: 'preselected',
        className: 'tw-w-1/6',
        Cell: ({ value, row: { original } }: { value: boolean; row: { original: IStoreItemPropertiesItemBaseOverrides } }) => (
          <div className="tw-w-24 tw-inline-block">
            <Toggle
              disabled={(hasPreselectedMaxItems && !value) || isEditingItemReplica}
              checked={value}
              checkedLabel={getIntlString('variant.yes', { isRootPath: true })}
              uncheckedLabel={getIntlString('variant.no', { isRootPath: true })}
              onChange={(_value) => updateModifierOverride(original, _value, OverrideFieldToUpdate.SELECTED)}
            />
          </div>
        ),
      },
      {
        Header: getIntlString('table.disabled'),
        accessor: 'isDisabled',
        key: 'disabled',
        className: 'tw-w-1/6',
        Cell: ({ value, row: { original } }: { value: boolean; row: { original: IStoreItemPropertiesItemBaseOverrides } }) => (
          <div className="tw-w-24 tw-inline-block">
            <Toggle
              disabled={(hasMinItemsEnabled && !value) || isEditingItemReplica}
              checked={value}
              checkedLabel={getIntlString('variant.yes', { isRootPath: true })}
              uncheckedLabel={getIntlString('variant.no', { isRootPath: true })}
              onChange={(_value) => updateModifierOverride(original, _value, OverrideFieldToUpdate.HIDDEN)}
            />
          </div>
        ),
      },
    ],
    [getIntlString, getModifierName, hasPreselectedMaxItems, isEditingItemReplica, updateModifierOverride, hasMinItemsEnabled],
  );
  const tableProps = useTable({ columns, data: dataSource }, useSortBy);

  return <GenericTable {...tableProps} />;
};
