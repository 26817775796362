import { UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdateStoreItemGroupChildrenOrderParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export const useLocationUpdateStoreItemGroupChildrenOrder = (
  options: UseMutationOptions<UpdateShowOrderResponseDto, unknown, LocationUpdateStoreItemGroupChildrenOrderParams>,
): UseMutationResult<UpdateShowOrderResponseDto, unknown, LocationUpdateStoreItemGroupChildrenOrderParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemGroupFacade.updatePropertiesShowOrder(merchantId, storeId, uniqueName, data),
    options,
  );
};
