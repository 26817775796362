import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadVendorDto } from '@goparrot/vendor-sdk';

import { VENDOR_QUERIES_ENUM } from '../enums';
import { vendorFacade } from '../facades';

export type VendorByStoreIdResult = UseQueryResult<ReadVendorDto>;

export const useVendorByStoreId = (storeId: string, options?: UseQueryOptions<ReadVendorDto>): VendorByStoreIdResult => {
  return useQuery([VENDOR_QUERIES_ENUM.VENDOR_BY_STORE_ID, storeId], () => vendorFacade.get(storeId), options);
};
