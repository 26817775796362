import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { StoreItemGroupListResponseDto, IMerchantFilterParams } from '@goparrot/storeitems-sdk';
import { merchantStoreItemGroupFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { ListQueryBaseParams } from '../../../interface';

export const useMerchantGetStoreItemGroupListQuery = (
  { merchantId, pagination, params }: ListQueryBaseParams<IMerchantFilterParams>,
  options?: UseQueryOptions<StoreItemGroupListResponseDto>,
): UseQueryResult<StoreItemGroupListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GROUP_LIST, merchantId, pagination, params],
    () => merchantStoreItemGroupFacade.list(merchantId, pagination, params),
    options,
  );
};
