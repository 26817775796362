import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { UseMutationResult } from 'react-query';

import {
  useLocationCreateItemBase,
  useMerchantCreateItemBase,
  LocationCreateItemBaseParams,
  MerchantCreateItemBaseParams,
} from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS } from '../constants';

type Props = {
  isMerchant: boolean;
  successCallback?: (result: ReadItemBaseDto) => void;
  hideNotificationSuccess?: boolean;
};

type QueryParams = LocationCreateItemBaseParams | MerchantCreateItemBaseParams;

export const useCreateItemBaseQuery = ({
  isMerchant,
  hideNotificationSuccess = false,
  successCallback,
}: Props): UseMutationResult<ReadItemBaseDto, unknown, QueryParams> => {
  const { notifySuccess, notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.create.error'),
      });
    },
    onSuccess: (result) => {
      hideNotificationSuccess &&
        notifySuccess({
          text: getIntlString('menu.notifications.create.success', { itemType: MENU_ELEMENTS.ITEM_BASE }),
        });
      successCallback && successCallback(result);
    },
  };

  const locationQuery = useLocationCreateItemBase({
    ...options,
  });

  const merchantQuery = useMerchantCreateItemBase({
    ...options,
  });

  return isMerchant ? merchantQuery : locationQuery;
};
