import type { ReadWebRedirectDto } from '@goparrot/webstore-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { WEBSTORE_QUERIES_ENUM } from '../enums';
import { webstoreRedirectFacade } from '../facades';

export const useGetWebstoreRedirectListByMerchant = (
  merchantId: string,
  options?: UseQueryOptions<ReadWebRedirectDto[]>,
): UseQueryResult<ReadWebRedirectDto[]> => {
  return useQuery([WEBSTORE_QUERIES_ENUM.WEBSTORES_REDIRECT_MERCHANT, merchantId], () => webstoreRedirectFacade.list(merchantId), options);
};
