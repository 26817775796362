import React, { useEffect, useState, FC } from 'react';
import sortBy from 'lodash/sortBy';
import classNames from 'classnames';
import { useRadioState } from 'reakit';
import { Select, Button, InlineSelect, BaseSelectComponents } from '@goparrot-dashboard/shared-ui';
// @ts-ignore export these types from root of select
import { SelectOptionType, SelectOptionGroupType, SelectValueType } from '@goparrot-dashboard/shared-ui/src/components/select/types';
import { IReadStoreDto, IReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { components, ValueContainerProps } from 'react-select';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import { getIntlString, IntlMessage } from '@goparrot-dashboard/i18n';
import { getLocationOptions } from './utils';
import { ALL_OPTION } from './constants';

const ValueContainer: FC<React.PropsWithChildren<ValueContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => {
  if (props.isMulti && props.getValue()?.length > 0) {
    const { renderMultiValueLabel, selectedValuesMaxCount, noContainerPadding } = props?.selectProps;
    const [chips, element] = children as any;
    // this allows us to display a given {selectedValuesMaxCount} number of selected options
    const displayChips =
      selectedValuesMaxCount !== undefined && selectedValuesMaxCount < chips.length
        ? [chips.slice(chips.length - selectedValuesMaxCount, chips.length), element]
        : children;
    const hasMoreStores = chips.length > selectedValuesMaxCount;

    return (
      <div className="tw-text-light-blue-400 tw-px-3 tw-flex-1">
        <div className="tw-border-b tw-border-cool-gray-200 tw-py-3 tw-mb-2">{renderMultiValueLabel(props.getValue())}</div>
        <components.ValueContainer {...props} className={classNames('tw-bg-transparent tw-pl-0 tw-pt-0', !noContainerPadding && 'tw-pr-24')}>
          {displayChips}
          {hasMoreStores && <span className="tw-text-blue-gray-900 tw-pt-2.5 tw-mr-32">...</span>}
        </components.ValueContainer>
      </div>
    );
  }
  return (
    <components.ValueContainer {...props} className="tw-bg-transparent tw-px-3 tw-text-light-blue-400">
      {children}
    </components.ValueContainer>
  );
};

interface Props {
  locations: (IReadStoreDto | IReadDefaultStoreDto)[];
  disabled?: boolean;
  showNumberOfStores?: number;
  onBlur?: () => void;
  value?: SelectValueType[];
  onChange: (value: SelectValueType[] | null | string[]) => void;
  hasAllStoresOption?: boolean;
  noContainerPadding?: boolean;
  isSearchable?: boolean;
  posAuthorizedStores?: Record<string, ReadPaymentStoreDto>;
  squareVendorList?: ReadVendorDto[];
  isFranchiseSelect?: boolean;
  groupOptions?: SelectOptionGroupType[];
  readOnly?: boolean;
  clearOutside?: boolean;
}

const selectStoresOptions = [
  { value: 0, label: 'All Stores' },
  { value: 1, label: 'Specific Stores' },
];

const getSelectedLabel = (options: unknown[]) => getIntlString('storeSelector.multiSelection.label', { count: options?.length ?? 0 });

export const StoreSelect: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  locations,
  onBlur,
  disabled,
  showNumberOfStores = 3,
  noContainerPadding = false,
  hasAllStoresOption,
  isSearchable,
  isFranchiseSelect = false,
  squareVendorList,
  posAuthorizedStores,
  groupOptions,
  readOnly = false,
  clearOutside = false,
}) => {
  const radioState = useRadioState({ state: 0 });
  const { setState } = radioState;

  const [selectAllStores, setSelectAllstores] = useState<number>(0);

  const sortedLocations: (IReadStoreDto | IReadDefaultStoreDto)[] = React.useMemo(() => sortBy(locations, ['type']), [locations]);

  const handleSelectChange: (selectedLocations: string[]) => void = (selectedLocations: string[]) => {
    if (selectedLocations.includes(ALL_OPTION)) {
      return onChange(sortedLocations?.map(({ storeId }) => storeId) as SelectValueType[]);
    }
    return onChange(selectedLocations as SelectValueType[]);
  };

  const handleSelectAllStores = (values: number) => {
    if (!values) {
      onChange(null);
    } else {
      onChange([]);
    }
    setSelectAllstores(values);
  };

  useEffect(() => {
    setState(selectAllStores);
  }, [selectAllStores, setState]);

  useEffect(() => {
    setSelectAllstores(!hasAllStoresOption || value ? 1 : 0);
  }, [hasAllStoresOption, value]);

  const hideAllOptionFromMenu = (options: SelectOptionType[]): SelectOptionType[] =>
    options.filter((option) => {
      if (locations?.length === value?.length) {
        return option.value !== ALL_OPTION;
      }
      return true;
    });

  const locationOptions = React.useMemo(
    () => getLocationOptions(isFranchiseSelect, sortedLocations, squareVendorList, posAuthorizedStores as Record<string, ReadPaymentStoreDto>),
    [posAuthorizedStores, sortedLocations, squareVendorList, isFranchiseSelect],
  );

  const disableSelectAll = isFranchiseSelect && locationOptions.some((option) => !!(option as { disabled?: boolean }).disabled);
  const options = groupOptions
    ? groupOptions
    : [
        {
          value: ALL_OPTION,
          label: ALL_OPTION,
          disabled: disableSelectAll,
        },
        ...locationOptions,
      ];

  const clearButton = (
    <Button color="accent" className="tw-h-8" onClick={() => handleSelectChange([])}>
      <IntlMessage id="store.selector.clear" />
    </Button>
  );

  return (
    <>
      <div data-testid="store-select-container" className="tw-relative tw-w-full tw-z-30">
        {!!value?.length && !disabled && !readOnly && !clearOutside && (
          <div className="tw-absolute tw-z-1 tw-flex tw-items-center tw-right-4 tw-bottom-2">{clearButton}</div>
        )}
        {hasAllStoresOption && !disableSelectAll && (
          <div className={classNames(['tw-py-1.5 tw-px-4', selectAllStores && 'tw-border-b tw-border-gray-200'])}>
            <InlineSelect options={selectStoresOptions} ariaLabel="stores" state={radioState} onChange={handleSelectAllStores} />
          </div>
        )}
        {!!selectAllStores && (
          <Select
            overrideComponents={{
              ...BaseSelectComponents,
              ValueContainer,
            }}
            noContainerPadding={noContainerPadding}
            selectedValuesMaxCount={showNumberOfStores}
            isMulti
            variant="base"
            disabled={disabled}
            placeholder={<IntlMessage id="store.selector.placeholder" />}
            value={value}
            onChange={handleSelectChange}
            onBlur={onBlur}
            options={hideAllOptionFromMenu(options as SelectOptionType[])}
            isOptionDisabled={(option) => !!(option as { disabled?: boolean })?.disabled}
            menuShouldBlockScroll
            renderMultiValueLabel={getSelectedLabel}
            closeMenuOnSelect={false}
            isClearable={false}
            isSearchable={isSearchable}
            menuPosition="absolute"
            groupOptions={groupOptions}
            readOnly={readOnly}
          />
        )}
      </div>
      {!!value?.length && !disabled && !readOnly && clearOutside && <div className={'tw-mt-1 tw-pb-2'}>{clearButton}</div>}
    </>
  );
};
