import { Input } from 'antd';

import { InputTextareaWrapper, InputWrapper, InputSearchWrapper, InputGroupWrapper } from './style/input.style';

export const Textarea = InputTextareaWrapper(Input.TextArea);

const AntInput = InputWrapper(Input);
// @ts-ignore-next-line
AntInput.Group = InputGroupWrapper(Input.Group);
// @ts-ignore-next-line
AntInput.Search = InputSearchWrapper(Input.Search);

export default AntInput;
