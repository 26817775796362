import React, { FC } from 'react';
import { useI18n } from '@goparrot-dashboard/i18n';
import { ParrotTips } from '../../../../client/src/components/commons/uielements/parrot-tips';

export const FranchiseTips: FC<React.PropsWithChildren<unknown>> = () => {
  const { getIntlString, getIntlMessage } = useI18n();

  return (
    <ParrotTips
      testId="franchise-tips"
      showAllLabel={getIntlString('central.helper.showItems')}
      hideAllLabel={getIntlString('central.helper.hideItems')}
      items={[
        {
          title: getIntlString('central.helper.step1.title'),
          description: getIntlMessage('central.helper.step1.description', {
            values: {
              bold1: <b>{getIntlString('central.helper.step1.bold1')}</b>,
              bold2: <b>{getIntlString('central.helper.step1.bold1')}</b>,
            },
          }),
          key: 'step-1',
        },
        {
          title: getIntlString('central.helper.step2.title'),
          description: getIntlMessage('central.helper.step2.description', {
            values: {
              bold1: <b>{getIntlString('central.helper.step2.bold1')}</b>,
              bold2: <b>{getIntlString('central.helper.step2.bold1')}</b>,
            },
          }),
          key: 'step-2',
        },
        {
          title: getIntlString('central.helper.step3.title'),
          description: getIntlMessage('central.helper.step3.description', {
            values: {
              bold1: <b>{getIntlString('central.helper.step3.bold1')}</b>,
              bold2: <b>{getIntlString('central.helper.step3.bold1')}</b>,
            },
          }),
          key: 'step-3',
        },
      ]}
    />
  );
};
