import { CreateStoreItemDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationAddStoreItemFirstVariationParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: CreateStoreItemDto;
};

export const useLocationAddStoreItemFirstVariation = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, LocationAddStoreItemFirstVariationParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, LocationAddStoreItemFirstVariationParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemFacade.addVariation(merchantId, storeId, uniqueName, data),
    options,
  );
};
