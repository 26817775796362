import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { locationItemBaseGroupFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useGetLocationItemBaseGroupChildrenQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemBaseDto[], Error>,
): UseQueryResult<ReadItemBaseDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_BASE_GROUP_GET_CHILDREN, merchantId, storeId, uniqueName],
    () => locationItemBaseGroupFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
