import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { CreateStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { storeByMerchantFacade } from '../facades';

type CreateStoreParams = {
  merchantId: string;
  data: CreateStoreDto;
};

export const useCreateStore = (
  options?: UseMutationOptions<ReadStoreDto, unknown, CreateStoreParams, unknown>,
): UseMutationResult<ReadStoreDto, unknown, CreateStoreParams, unknown> => {
  return useMutation(({ merchantId, data }) => storeByMerchantFacade.create(merchantId, data), options);
};
