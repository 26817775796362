import React, { FC, useState, useEffect } from 'react';
import { Button } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';

type Props = {
  countRef: React.MutableRefObject<number>;
  onSubmit: () => void;
};

export const UpdateButton: FC<React.PropsWithChildren<Props>> = React.memo(({ countRef, onSubmit }) => {
  const { getIntlString } = useI18n();
  const [count, setCount] = useState(countRef.current);

  useEffect(() => {
    const interval = setInterval(function () {
      setCount(countRef.current);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [countRef]);

  return (
    <Button disabled={!count} onClick={onSubmit}>
      {getIntlString('menu.form.section.displaySettings.storeSpecific.modal.update', { isRootPath: true, values: { count: count } })}
    </Button>
  );
});
