import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export function useIsMobileView(): boolean {
  const [isMobile, setIsMobile] = useState<boolean | null>(window.innerWidth < 640);

  const handleWidthChange = debounce(() => setIsMobile(window.innerWidth < 640), 300);

  useEffect(() => {
    window.addEventListener('resize', handleWidthChange);
    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  }, [handleWidthChange]);

  return Boolean(isMobile);
}
