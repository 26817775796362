import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { DeleteConfirmModal, DialogStateReturn, InModal, Spinner } from '@goparrot-dashboard/shared-ui';
import { getIntlString, IntlMessage } from '@goparrot-dashboard/i18n';
import uniqBy from 'lodash/uniqBy';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MarketButton, MarketHeader, MarketModalPartial } from '@market/react-bindings';
import { getStoreTitle } from '../../utils';
import { ActionData, ACTIONS_VALUES, StoreSpecificItem } from './constants';
import { LocationGroupItem, MULTIPLICITY, TEXT_TYPES, getDeleteDialogTextId } from './utils';
import { getGroupedOptions } from './helpers';
import { ActionInputReplicaLocations } from './ActionInputReplicaLocations';
import { ActionInputReplicaPrice } from './ActionInputReplicaPrice';
import { ActionInputReplicaOrderable } from './ActionInputReplicaOrderable';
import { ActionInputReplicaTitle } from './ActionInputReplicaTitle';

export type ActionModalProps = {
  dialog: DialogStateReturn;
  handleUpdate: () => void;
  onCancel: () => void;
  actionData: ActionData;
  setActionData: Dispatch<SetStateAction<ActionData>>;
  selectedItem: StoreSpecificItem;
  selectedReplicas: StoreSpecificItem[];
  isLoading: boolean;
  locationGroupsWithReplicasSorted: LocationGroupItem[];
};

export const ActionsModal: FC<React.PropsWithChildren<ActionModalProps>> = ({
  dialog,
  handleUpdate,
  onCancel,
  actionData,
  locationGroupsWithReplicasSorted,
  setActionData,
  selectedItem,
  selectedReplicas,
  isLoading,
}) => {
  const { stores, merchant, isVirtualMenu } = useStoreService();
  const storeIds = uniqBy(selectedReplicas, 'storeId').map((item) => item.storeId);

  const getHeaderTitle = () => {
    switch (actionData.action) {
      case ACTIONS_VALUES.UPDATE_PRICE:
        return getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions.price', { isRootPath: true });
      case ACTIONS_VALUES.UPDATE_TITLE:
        return getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions.title', { isRootPath: true });
      case ACTIONS_VALUES.UPDATE_ORDERABLE:
        return getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions.orderable', { isRootPath: true });
      case ACTIONS_VALUES.REVERT_SETTINGS:
        return getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions.revert', { isRootPath: true });
    }
  };

  const priceUpdateOptions = getGroupedOptions(locationGroupsWithReplicasSorted, storeIds, selectedReplicas, stores);

  const infoMessageId = useMemo(() => {
    switch (actionData.action) {
      case ACTIONS_VALUES.UPDATE_PRICE:
        return 'menu.form.section.displaySettings.storeSpecific.modal.actions.price.info';
      case ACTIONS_VALUES.UPDATE_TITLE:
        return 'menu.form.section.displaySettings.storeSpecific.modal.actions.title.info';
      case ACTIONS_VALUES.UPDATE_ORDERABLE:
        return 'menu.form.section.displaySettings.storeSpecific.modal.actions.orderable.info';
      case ACTIONS_VALUES.REVERT_SETTINGS:
        return 'menu.form.section.displaySettings.storeSpecific.modal.actions.revert.info';
    }
  }, [actionData.action]);

  const disableInputs = actionData.action === ACTIONS_VALUES.REVERT_SETTINGS;
  const priceIsEmpty = actionData.price === undefined;
  const confirmDisabled =
    (actionData.action === ACTIONS_VALUES.UPDATE_PRICE && priceIsEmpty) ||
    (actionData.action === ACTIONS_VALUES.UPDATE_TITLE && !actionData.title) ||
    isLoading;
  const isDeleteAction = ACTIONS_VALUES.DELETE === actionData.action;
  const multiplicity = storeIds.length > 1 ? MULTIPLICITY.plural : MULTIPLICITY.single;

  if (!dialog.visible) {
    return null;
  }

  return isDeleteAction ? (
    <DeleteConfirmModal
      dialog={dialog}
      handleDelete={handleUpdate}
      onCancel={onCancel}
      deleteText={getIntlString(getDeleteDialogTextId({ multiplicity, textType: TEXT_TYPES.confirm }), { isRootPath: true })}
      cancelText={getIntlString('button.cancel', { isRootPath: true })}
      title={getIntlString(getDeleteDialogTextId({ multiplicity, textType: TEXT_TYPES.title }), { isRootPath: true })}
      dialogText={
        <IntlMessage
          id={getDeleteDialogTextId({ multiplicity, textType: TEXT_TYPES.description })}
          values={{
            itemName: selectedItem.title,
            storeName: getStoreTitle({ locations: stores, storeId: storeIds.length ? storeIds[0] : '', merchantId: merchant.storeId, isVirtualMenu }),
            storesCount: storeIds.length,
            b: (chunks) => <b>{chunks}</b>,
          }}
        />
      }
    />
  ) : (
    <InModal>
      <MarketModalPartial
        onMarketDialogDismissed={() => {
          dialog.dismiss();
        }}
        trapFocus
      >
        <Spinner spinning={isLoading}>
          <MarketHeader>
            <h2>{getHeaderTitle()}</h2>
            <MarketButton rank="secondary" slot="actions" onClick={onCancel}>
              {getIntlString('button.cancel', { isRootPath: true })}
            </MarketButton>
            <MarketButton rank="primary" slot="actions" onClick={handleUpdate} disabled={confirmDisabled || undefined}>
              {getIntlString('button.save', { isRootPath: true })}
            </MarketButton>
          </MarketHeader>
          <main>
            {
              <div className="tw-space-y-4">
                <div className="tw-text-base">
                  {infoMessageId ? (
                    <IntlMessage
                      id={infoMessageId}
                      values={{
                        itemName: selectedItem.title,
                        storesCount: storeIds.length,
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  ) : null}
                </div>
                <div className="tw-space-y-4">
                  <ActionInputReplicaLocations storeIds={storeIds} priceUpdateOptions={priceUpdateOptions} />

                  {[ACTIONS_VALUES.UPDATE_PRICE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) ? (
                    <ActionInputReplicaPrice priceIsEmpty={priceIsEmpty} disableInputs={disableInputs} actionData={actionData} setActionData={setActionData} />
                  ) : null}
                  {[ACTIONS_VALUES.UPDATE_ORDERABLE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) ? (
                    <ActionInputReplicaOrderable disableInputs={disableInputs} actionData={actionData} setActionData={setActionData} />
                  ) : null}
                  {[ACTIONS_VALUES.UPDATE_TITLE, ACTIONS_VALUES.REVERT_SETTINGS].includes(actionData.action) ? (
                    <ActionInputReplicaTitle disableInputs={disableInputs} actionData={actionData} setActionData={setActionData} />
                  ) : null}
                </div>
              </div>
            }
          </main>
        </Spinner>
      </MarketModalPartial>
    </InModal>
  );
};
