import { createAction } from 'helpers/create-action';

const a = {
  API__ORDER__GET__REQUEST: 'API__ORDER__GET__REQUEST',
  API__ORDER__GET__SUCCESS: 'API__ORDER__GET__SUCCESS',
  API__ORDER__GET__ERROR: 'API__ORDER__GET__ERROR',

  API__ORDERS__GET__REQUEST: 'API__ORDERS__GET__REQUEST',
  API__ORDERS__GET__SUCCESS: 'API__ORDERS__GET__SUCCESS',
  API__ORDERS__GET__ERROR: 'API__ORDERS__GET__ERROR',

  API__NEW_ORDERS__GET__REQUEST: 'API__NEW_ORDERS__GET__REQUEST',
  API__NEW_ORDERS__GET__SUCCESS: 'API__NEW_ORDERS__GET__SUCCESS',

  API__ORDERS__POLL__REQUEST: 'API__ORDERS__POLL__REQUEST',
  API__ORDERS__POLL__SUCCESS: 'API__ORDERS__POLL__SUCCESS',

  API__ORDER__UPDATE__REQUEST: 'API__ORDER__UPDATE__REQUEST',
  API__ORDER__UPDATE__SUCCESS: 'API__ORDER__UPDATE__SUCCESS',
  API__ORDER__UPDATE__ERROR: 'API__ORDER__UPDATE__ERROR',

  API__ORDER__SELECTED__UPDATE__REQUEST: 'API__ORDER__SELECTED__UPDATE__REQUEST',
  API__ORDER__SELECTED__UPDATE__SUCCESS: 'API__ORDER__SELECTED__UPDATE__SUCCESS',
  API__ORDER__SELECTED__UPDATE__ERROR: 'API__ORDER__SELECTED__UPDATE__ERROR',

  API__ORDER__RUN__ACTIONS__REQUEST: 'API__ORDER__RUN__ACTIONS__REQUEST',
  API__ORDER__RUN__ACTIONS__SUCCESS: 'API__ORDER__RUN__ACTIONS__SUCCESS',
  API__ORDER__RUN__ACTIONS__ERROR: 'API__ORDER__RUN__ACTIONS__ERROR',
  API__ORDER__RESET__DATA: 'API__ORDER__RESET__DATA',

  API__ORDER__TRANSACTION__GET__REQUEST: 'API__ORDER__TRANSACTION__GET__REQUEST',
  API__ORDER__TRANSACTION__GET__SUCCESS: 'API__ORDER__TRANSACTION__GET__SUCCESS',
  API__ORDER__TRANSACTION__GET__ERROR: 'API__ORDER__TRANSACTION__GET__ERROR',

  API__ORDER__CUSTOMER__GET__REQUEST: 'API__ORDER__CUSTOMER__GET__REQUEST',
  API__ORDER__CUSTOMER__GET__SUCCESS: 'API__ORDER__CUSTOMER__GET__SUCCESS',
  API__ORDER__CUSTOMER__GET__ERROR: 'API__ORDER__CUSTOMER__GET__ERROR',

  API__ORDER__REFUND__GET__REQUEST: 'API__ORDER__REFUND__GET__REQUEST',
  API__ORDER__REFUND__GET__SUCCESS: 'API__ORDER__REFUND__GET__SUCCESS',
  API__ORDER__REFUND__GET__ERROR: 'API__ORDER__REFUND__GET__ERROR',

  API__ORDER__THROTTLING__CREATE__REQUEST: 'API__ORDER__THROTTLING__CREATE__REQUEST',
  API__ORDER__THROTTLING__CREATE__SUCCESS: 'API__ORDER__THROTTLING__CREATE__SUCCESS',
  API__ORDER__THROTTLING__CREATE__ERROR: 'API__ORDER__THROTTLING__CREATE__ERROR',

  API__GET__THROTTLING__CONFIG__REQUEST: 'API__GET__THROTTLING__CONFIG__REQUEST',
  API__GET__THROTTLING__CONFIG__SUCCESS: 'API__GET__THROTTLING__CONFIG__SUCCESS',
  API__GET__THROTTLING__CONFIG__ERROR: 'API__GET__THROTTLING__CONFIG__ERROR',

  API__THROTTLING__CONFIG__RESET: 'API__THROTTLING__CONFIG__RESET',

  API__UPDATE__THROTTLING__CONFIG__REQUEST: 'API__UPDATE__THROTTLING__CONFIG__REQUEST',
  API__UPDATE__THROTTLING__CONFIG__SUCCESS: 'API__UPDATE__THROTTLING__CONFIG__SUCCESS',
  API__UPDATE__THROTTLING__CONFIG__ERROR: 'API__UPDATE__THROTTLING__CONFIG__ERROR',

  LOCAL__ORDER__SET__SELECTED: 'LOCAL__ORDER__SET__SELECTED',
  LOCAL__ORDER__RESET__SELECTED: 'LOCAL__ORDER__RESET__SELECTED',
  LOCAL__ORDER__REFUND__CLEAR__ERROR: 'LOCAL__ORDER__REFUND__CLEAR__ERROR',
  LOCAL__ORDER__CUSTOMER__CLEAR: 'LOCAL__ORDER__CUSTOMER__CLEAR',

  API__ORDER__GET__DELIVERY__REQUEST: 'API__ORDER__GET__DELIVERY__REQUEST',
  API__ORDER__GET__DELIVERY__SUCCESS: 'API__ORDER__GET__DELIVERY__SUCCESS',
  API__ORDER__RESET__DELIVERY: 'API__ORDER__RESET__DELIVERY',
  API__ORDER__GET__DELIVERY__ERROR: 'API__ORDER__GET__DELIVERY__ERROR',
};

const c = {
  requestThrottlingUpdate: createAction(a.API__UPDATE__THROTTLING__CONFIG__REQUEST),
  successThrottlingUpdate: createAction(a.API__UPDATE__THROTTLING__CONFIG__SUCCESS),
  errorThrottlingUpdate: createAction(a.API__UPDATE__THROTTLING__CONFIG__ERROR),

  getThrottlingConfig: createAction(a.API__GET__THROTTLING__CONFIG__REQUEST),
  successGetThrottlingConfig: createAction(a.API__GET__THROTTLING__CONFIG__SUCCESS),
  errorGetThrottlingConfig: createAction(a.API__GET__THROTTLING__CONFIG__ERROR),

  resetThrottlingConfig: createAction(a.API__THROTTLING__CONFIG__RESET),

  requestThrottlingCreate: createAction(a.API__ORDER__THROTTLING__CREATE__REQUEST),
  successThrottlingCreate: createAction(a.API__ORDER__THROTTLING__CREATE__SUCCESS),
  errorThrottlingCreate: createAction(a.API__ORDER__THROTTLING__CREATE__ERROR),

  requestGetOrder: createAction(a.API__ORDER__GET__REQUEST),
  successGetOrder: createAction(a.API__ORDER__GET__SUCCESS),
  errorGetOrder: createAction(a.API__ORDER__GET__ERROR),

  requestGet: createAction(a.API__ORDERS__GET__REQUEST),
  successGet: createAction(a.API__ORDERS__GET__SUCCESS),
  errorGet: createAction(a.API__ORDERS__GET__ERROR),

  requestGetNew: createAction(a.API__NEW_ORDERS__GET__REQUEST),
  successGetNew: createAction(a.API__NEW_ORDERS__GET__SUCCESS),

  requestPoll: createAction(a.API__ORDERS__POLL__REQUEST),
  successPoll: createAction(a.API__ORDERS__POLL__SUCCESS),

  requestUpdate: createAction(a.API__ORDER__UPDATE__REQUEST),
  successUpdate: createAction(a.API__ORDER__UPDATE__SUCCESS),
  errorUpdate: createAction(a.API__ORDER__UPDATE__ERROR),

  requestSelectedUpdate: createAction(a.API__ORDER__SELECTED__UPDATE__REQUEST),
  successSelectedUpdate: createAction(a.API__ORDER__SELECTED__UPDATE__SUCCESS),
  errorSelectedUpdate: createAction(a.API__ORDER__SELECTED__UPDATE__ERROR),

  runHandleActions: createAction(a.API__ORDER__RUN__ACTIONS__REQUEST),
  successRunActions: createAction(a.API__ORDER__RUN__ACTIONS__SUCCESS),
  errorRunActions: createAction(a.API__ORDER__RUN__ACTIONS__ERROR),
  resetOrderData: createAction(a.API__ORDER__RESET__DATA),

  requestGetTransaction: createAction(a.API__ORDER__TRANSACTION__GET__REQUEST),
  successGetTransaction: createAction(a.API__ORDER__TRANSACTION__GET__SUCCESS),
  errorGetTransaction: createAction(a.API__ORDER__TRANSACTION__GET__ERROR),

  requestGetCustomer: createAction(a.API__ORDER__CUSTOMER__GET__REQUEST),
  successGetCustomer: createAction(a.API__ORDER__CUSTOMER__GET__SUCCESS),
  errorGetCustomer: createAction(a.API__ORDER__CUSTOMER__GET__ERROR),

  requestGetRefund: createAction(a.API__ORDER__REFUND__GET__REQUEST),
  successGetRefund: createAction(a.API__ORDER__REFUND__GET__SUCCESS),
  errorGetRefund: createAction(a.API__ORDER__REFUND__GET__ERROR),

  setSelected: createAction(a.LOCAL__ORDER__SET__SELECTED),
  resetSelected: createAction(a.LOCAL__ORDER__RESET__SELECTED),

  clearCustomer: createAction(a.LOCAL__ORDER__CUSTOMER__CLEAR),
  clearRefundError: createAction(a.LOCAL__ORDER__REFUND__CLEAR__ERROR),

  requestGetDelivery: createAction(a.API__ORDER__GET__DELIVERY__REQUEST),
  successGetDelivery: createAction(a.API__ORDER__GET__DELIVERY__SUCCESS),
  resetDeliveryData: createAction(a.API__ORDER__RESET__DELIVERY),
  errorGetDelivery: createAction(a.API__ORDER__GET__DELIVERY__ERROR),
};

export default { ...a, ...c };
