import { useDialogState } from 'reakit';
import { ConfirmModal, Select, FormField, LabelWithTooltip, Spinner, PanelTitle } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import franchiseActions from 'store/franchise/actions';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useGiftCardOrchestrationContext } from '../../services';
import { useCreateGiftCardCentralLocation, useGiftCardCentralLocation } from '../../hooks';
import { SyncStatusBadge } from '../sync-status-badge/SyncStatusBadge';
import { getGiftCardLocationBadgeStatus } from '../../utils';

export const GiftCardCentralLocation: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    stores,
    merchant: { merchantId },
  } = useStoreService();

  const dispatch = useDispatch();

  const { fetchLocations } = useGiftCardOrchestrationContext();
  const { getIntlString } = useI18n();

  // fetch central location
  const { centralLocation, refetch, loading } = useGiftCardCentralLocation(merchantId, stores);
  const confirmCentralLocationDialog = useDialogState({ animated: true });

  // create central location
  const onCreateCentralLocationSuccess = useCallback(() => {
    refetch();
    fetchLocations();
    confirmCentralLocationDialog.hide();
    dispatch(franchiseActions.requestLocationsListGet({ merchantId }));
  }, [refetch, fetchLocations, confirmCentralLocationDialog, dispatch, merchantId]);
  const { createCentralLocation } = useCreateGiftCardCentralLocation(onCreateCentralLocationSuccess);
  const setCentralLocation = (storeId: string) => createCentralLocation(merchantId, storeId);

  const [centralLocationCandidate, setCentralLocationCandidate] = useState<string | null>(null);

  const confirmSetupCentralLocation = (storeId: string) => {
    setCentralLocationCandidate(storeId);
    confirmCentralLocationDialog.show();
  };

  const dismissCentralLocation = () => {
    confirmCentralLocationDialog.hide();
  };

  React.useEffect(() => {
    if (!confirmCentralLocationDialog.visible) {
      setCentralLocationCandidate(null);
    }
  }, [confirmCentralLocationDialog.visible]);

  return (
    <Spinner isMarketComponent spinning={loading}>
      <div data-testid="franchise-management-gift-card-central-location">
        <div className="tw-flex tw-justify-between">
          <PanelTitle>{getIntlString('page.merchant.orchestration.central.title', { isRootPath: true })}</PanelTitle>
          {centralLocation && centralLocation.franchiseLocation?.syncStatus && (
            <div className="tw-flex tw-items-center tw-space-x-1 tw-mr-2">
              <span>{getIntlString('page.merchant.orchestration.locations.table.importStatusHeader', { isRootPath: true })}:</span>
              <SyncStatusBadge status={getGiftCardLocationBadgeStatus(centralLocation.franchiseLocation.syncStatus.import)} />
            </div>
          )}
        </div>
        <div>
          <FormField
            withValuePadding={false}
            disabled={!!centralLocation}
            label={
              <LabelWithTooltip tooltip={getIntlString('central.selectTooltip')}>
                {getIntlString('page.merchant.orchestration.central.selectLabel', { isRootPath: true })}
              </LabelWithTooltip>
            }
            input={
              <Select
                placeholder={getIntlString('page.merchant.orchestration.central.selectPlaceholder', { isRootPath: true })}
                value={centralLocationCandidate || centralLocation?.storeId}
                options={stores.map((l) => ({ value: l.storeId, label: l.title }))}
                onChange={confirmSetupCentralLocation}
                disabled={!!centralLocation}
                menuPortalTarget={document.body}
              />
            }
          />
        </div>
      </div>
      {centralLocationCandidate && (
        <ConfirmModal
          dialog={confirmCentralLocationDialog}
          header={getIntlString('page.merchant.orchestration.central.selectModalTitle', { isRootPath: true })}
          content={
            <div>
              <p className="tw-text-lg tw-text-cool-gray-900">
                {getIntlString('page.merchant.orchestration.central.selectModalChoosedLocation', { isRootPath: true })}{' '}
                <b>{stores.find((l) => l.storeId === centralLocationCandidate)?.name}</b>.<br />
                {getIntlString('page.merchant.orchestration.central.selectModalConfirmation', { isRootPath: true })}
              </p>
            </div>
          }
          onConfirm={() => setCentralLocation(centralLocationCandidate)}
          onCancel={dismissCentralLocation}
          confirmText={getIntlString('page.merchant.orchestration.central.selectModalYes', { isRootPath: true })}
          cancelText={getIntlString('page.merchant.orchestration.central.selectModalNo', { isRootPath: true })}
        />
      )}
    </Spinner>
  );
};
