import type { ResetPasswordUserDto } from '@goparrot/users-v2-sdk';
import type { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { accountFacade } from '../facades';

export const useForgotPassword = (
  options?: UseMutationOptions<{ ok?: boolean }, AxiosError, ResetPasswordUserDto>,
): UseMutationResult<{ ok?: boolean }, AxiosError, ResetPasswordUserDto> => {
  return useMutation(
    (data) =>
      accountFacade.resetPassword(data).then(() => ({
        ok: true,
      })),
    options,
  );
};
