import { ItemFacade, MenuSyncConfigFacade, TaxFacade } from '@goparrot/menu-sync-sdk';

import { getConfig, getInterceptors } from 'helpers/request';

const BASE_PATH_MENU_SYNC = 'menuSync';

const itemFacade = new ItemFacade(getConfig(BASE_PATH_MENU_SYNC), getInterceptors());

const menuSyncConfigFacade = new MenuSyncConfigFacade(getConfig(BASE_PATH_MENU_SYNC), getInterceptors());

const taxFacade = new TaxFacade(getConfig(BASE_PATH_MENU_SYNC), getInterceptors());

export const getItemsFromPOSByQuery = (merchantId, storeId, query, type) =>
  function* () {
    return yield itemFacade.search(merchantId, storeId, query, type);
  };

/**
 * Sync item with POS
 */

export const syncItemChildrenConnections = (merchantId, storeId, type, uniqueName) =>
  function* () {
    return yield itemFacade.syncChildren(merchantId, storeId, type, uniqueName);
  };

export const registerSyncJob = (merchantId, storeId, type, uniqueName) =>
  function* () {
    return yield itemFacade.registerSyncJob(merchantId, storeId, type, uniqueName);
  };

/**
 * Sync configurations request
 */

export const listCategorySyncConfig = (merchantId) =>
  function* () {
    return yield menuSyncConfigFacade.list(merchantId);
  };

export const patchCategorySyncConfig = (merchantId, uuid, data) =>
  function* () {
    return yield menuSyncConfigFacade.patch(merchantId, uuid, data);
  };

/**
 * Search tax request
 */

export const getTaxFromPosByQuery = (storeId, query) =>
  function* () {
    return yield taxFacade.search(storeId, query);
  };
