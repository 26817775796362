import type { ReadDefaultStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { useContext } from 'react';
import { useRouteMatch } from 'react-router';
import type { StoreServiceContextType } from '../contexts/StoreServiceContext';
import { StoreServiceContext } from '../contexts/StoreServiceContext';

export type UseStoreServiceResult = StoreServiceContextType & {
  selectedStore?: ReadStoreDto | ReadDefaultStoreDto;
};

export const useStoreService = (): UseStoreServiceResult => {
  const contextValue = useContext(StoreServiceContext);
  const match = useRouteMatch<{ id?: string }>();

  if (contextValue === undefined) {
    throw new Error('[useStoreService] used outside of StoreServiceContextType');
  }

  const lookupId = match?.params?.id || contextValue.selectedStoreId;

  return {
    ...contextValue,
    selectedStore: [...contextValue.stores, ...contextValue.defaultStores].find(({ storeId }) => storeId === lookupId),
  };
};
