import React from 'react';
import { BaseSelectComponents, FormField, Select } from '@goparrot-dashboard/shared-ui';
import { DiningOptionEnum } from '@goparrot/common';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { components, ActionMeta, OptionTypeBase } from 'react-select';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

const DINING_DEFAULT_OPTIONS = [
  {
    value: DiningOptionEnum.DELIVERY,
    label: getIntlString('menu.form.dining-options.option.DELIVERY'),
  },
  {
    value: DiningOptionEnum.DINE_IN,
    label: getIntlString('menu.form.dining-options.option.DINE_IN'),
  },
  {
    value: DiningOptionEnum.CURBSIDE,
    label: getIntlString('menu.form.dining-options.option.CURBSIDE'),
  },
  {
    value: DiningOptionEnum.TAKE_OUT,
    label: getIntlString('menu.form.dining-options.option.TAKE_OUT'),
  },
];

const SELECT_ALL = { value: 'All', label: 'All Options' };

type BaseEntity = {
  storeId: string;
  metadata: {
    diningOptions?: DiningOptionEnum[];
  };
};

export const DiningOptions = <T extends BaseEntity>(): React.ReactElement | null => {
  const { isMerchant, stores, selectedStoreId, defaultStores } = useStoreService();
  const { form, isMerchantLevelItem } = useMenuFormContext<T>();
  const bindInputProps = useInputProps(form);
  const bindFormFieldProps = useFormFieldProps(form);
  const { value, onChange } = bindInputProps('metadata.diningOptions');
  const definedOptions = React.useMemo(() => {
    let options;

    if (isMerchant || isMerchantLevelItem) {
      options = defaultStores.flatMap((s) => s.featuresFlags.availableDiningOptions ?? []);
    } else {
      const storeDiningOptionsConfig = stores.find((store) => store.storeId === (form.state.storeId || selectedStoreId))?.diningOptionsConfig || {};
      options = Object.keys(storeDiningOptionsConfig);
    }

    return options.reduce((acc, key) => {
      const option = DINING_DEFAULT_OPTIONS.find((o) => o.value === key);
      if (option) {
        acc.push(option);
      }

      return acc;
    }, []);
  }, [defaultStores, form.state.storeId, isMerchant, isMerchantLevelItem, selectedStoreId, stores]);

  const getOptions = () => {
    if (value?.length === definedOptions.length) return definedOptions;

    return [SELECT_ALL, ...definedOptions];
  };
  const handleSelect = (list: string[], event?: ActionMeta<OptionTypeBase>) => {
    if (event?.action === 'select-option' && event.option.value === SELECT_ALL.value) {
      onChange(definedOptions.map((o) => o.value));
      return;
    }

    if (event?.action === 'deselect-option' && event.option.value === SELECT_ALL.value) {
      onChange([]);
      return;
    }

    if (event?.action === 'deselect-option' && list.includes(SELECT_ALL.value)) {
      onChange(list.filter((l) => l !== SELECT_ALL.label));
      return;
    }

    onChange(list);
  };

  return (
    <FormField
      {...bindFormFieldProps('metadata.diningOptions')}
      label={getMenuFormIntlString('diningOptions')}
      withValuePadding={false}
      disabled={form.isDisabledForm}
      input={
        <Select
          isMulti
          overrideComponents={{ ...BaseSelectComponents, ValueContainer: components.ValueContainer }}
          isSearchable={false}
          isClearable={false}
          value={value}
          options={getOptions()}
          onChange={handleSelect}
          disabled={form.isDisabledForm}
        />
      }
    />
  );
};
