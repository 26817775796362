import { IsEmail } from 'class-validator';

export class ForgotPasswordModel {
  @IsEmail()
  email: string;

  init(options: Partial<this>): this {
    Object.assign(this, options);
    return this;
  }
}
