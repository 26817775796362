import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, InputNumber } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { Item } from '../../interface';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString, preventMinusKey } from './utils';

export const ItemPrice = <T extends { price: number }>(): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { getFieldValue } = form;
  const { roleV2 } = useUserService();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const inputProps = bindInputProps('price');
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditPrice
    : franchisePermissions?.canEditPrice;

  return (
    <FormField
      {...bindFormFieldProps('price')}
      withValuePadding={false}
      label={getMenuFormIntlString('price')}
      disabled={isItemReplica ? !hasFranchisePermission : inputProps.disabled}
      input={
        <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full tw-px-4">
          <InputNumber
            {...inputProps}
            disabled={isItemReplica ? !hasFranchisePermission : inputProps.disabled}
            name="price"
            className="tw-pr-2"
            decimalScale={2}
            onKeyDown={preventMinusKey}
          />
          <span className="tw-leading-none">{getFieldValue('currency')}</span>
        </div>
      }
    />
  );
};
