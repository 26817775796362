import { ConnectionItem } from '../interface';
import { isMenuConcept } from './isMenuConceptGuard';

type Props = {
  item: ConnectionItem;
  selectedStoreId: string;
  isMerchant: boolean;
};

export const canLinkMenuItem = ({ item, selectedStoreId, isMerchant }: Props): boolean => {
  if (isMenuConcept(item)) {
    return false;
  }
  return item.storeId === selectedStoreId || item.storeId === item.merchantId || isMerchant;
};
