import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { PaymentProviderEnum } from '@goparrot/common';
import { PAYMENT_QUERIES_ENUM } from '../enums';
import { paymentStoreFacade } from '../facades';

type Params = {
  merchantId: string;
  provider?: PaymentProviderEnum;
  options?: UseQueryOptions<ReadPaymentStoreDto[]>;
};

export const usePaymentStoresList = ({ merchantId, provider = PaymentProviderEnum.SQUARE, options }: Params): UseQueryResult<ReadPaymentStoreDto[]> => {
  return useQuery([PAYMENT_QUERIES_ENUM.PAYMENT_STORES_LIST, merchantId, provider], () => paymentStoreFacade.getPaymentStores(merchantId, provider), options);
};
