import { UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type MerchantUpdateCategoryChildrenOrderParams = {
  merchantId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export const useMerchantUpdateCategoryChildrenOrder = (
  options: UseMutationOptions<UpdateShowOrderResponseDto, unknown, MerchantUpdateCategoryChildrenOrderParams>,
): UseMutationResult<UpdateShowOrderResponseDto, unknown, MerchantUpdateCategoryChildrenOrderParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantCategoryFacade.updateChildrenShowOrder(merchantId, uniqueName, data), options);
};
