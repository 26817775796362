import { IMerchantFilterParams, IResultsListResponse, IStoreFilterParams, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ListQueryBaseParams, useMerchantItemOptionsList, useLocationItemOptionsList } from '@goparrot-dashboard-api/menu-management';
import { UseQueryResult } from 'react-query';
import { MENU_ELEMENTS_PLURAL, removeNullOrUndefinedObjectProperties } from '@goparrot-dashboard/shared-utils';

type Props = ListQueryBaseParams<IStoreFilterParams | IMerchantFilterParams> & {
  storeId: string;
  isMerchant: boolean;
  filters?: IStoreFilterParams | IMerchantFilterParams;
  isCreating?: boolean;
  enabled?: boolean;
};

export const useModifierGroupListQuery = ({
  merchantId,
  storeId,
  pagination,
  filters,
  isMerchant,
  isCreating,
  enabled = true,
}: Props): UseQueryResult<IResultsListResponse<ReadItemOptionsDto>> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.ITEM_OPTIONS }),
      });
    },
  };

  const locationQuery = useLocationItemOptionsList(
    { merchantId, storeId, pagination, params: filters ? removeNullOrUndefinedObjectProperties(filters) : (filters as IStoreFilterParams) },
    {
      ...options,
      enabled: !isMerchant && !isCreating && enabled,
    },
  );

  const merchantQuery = useMerchantItemOptionsList(
    {
      merchantId,
      pagination,
      params: filters ? (removeNullOrUndefinedObjectProperties(filters) as IMerchantFilterParams) : (filters as IMerchantFilterParams),
    },
    {
      ...options,
      enabled: isMerchant && !isCreating && enabled,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
