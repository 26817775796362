import { getConfig, getInterceptors, STORE_ITEMS_BASE_PATH } from '@goparrot-dashboard-api/shared';
import {
  MerchantMenuConceptFacade,
  MerchantItemSetFacade,
  MerchantItemOptionsFacade,
  MerchantItemBaseFacade,
  MerchantStoreItemGroupFacade,
  LocationItemSetFacade,
  LocationMenuConceptFacade,
  LocationItemOptionsFacade,
  LocationItemBaseFacade,
  LocationStoreItemGroupFacade,
  MerchantStoreItemBundleFacade,
  LocationStoreItemBundleFacade,
  LocationItemFacade,
  MerchantCategoryFacade,
  LocationCategoryFacade,
  MerchantItemFacade,
  MerchantItemBaseGroupFacade,
  LocationItemBaseGroupFacade,
  MerchantSelectionTypesFacade,
  LocationSelectionTypesFacade,
  BatchItemsFacade,
  MerchantStoreItemComboFacade,
  LocationStoreItemComboFacade,
} from '@goparrot/storeitems-sdk';

import { ComboItemFacade } from '@goparrot/dashboard-gateway-sdk';
import { StoreItemMappingFacade } from '@goparrot/menu-orchestrator-sdk';
import { MenuSyncConfigFacade } from '@goparrot/menu-sync-sdk';

const BASE_PATH_MENU_SYNC = 'menuSync';

export const merchantSelectionTypesFacade = new MerchantSelectionTypesFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationSelectionTypesFacade = new LocationSelectionTypesFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantMenuConceptsFacade = new MerchantMenuConceptFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationMenuConceptsFacade = new LocationMenuConceptFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantItemOptionsFacade = new MerchantItemOptionsFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemOptionsFacade = new LocationItemOptionsFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantStoreItemGroupFacade = new MerchantStoreItemGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationStoreItemGroupFacade = new LocationStoreItemGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantItemSetFacade = new MerchantItemSetFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemSetFacade = new LocationItemSetFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantCategoryFacade = new MerchantCategoryFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationCategoryFacade = new LocationCategoryFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const storeItemMappingFacade = new StoreItemMappingFacade(getConfig('menuOrchestrator'), getInterceptors());

export const merchantStoreItemComboFacade = new MerchantStoreItemComboFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationStoreItemComboFacade = new LocationStoreItemComboFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantStoreItemBundleFacade = new MerchantStoreItemBundleFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationStoreItemBundleFacade = new LocationStoreItemBundleFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantItemBaseFacade = new MerchantItemBaseFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemBaseFacade = new LocationItemBaseFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantItemBaseGroupFacade = new MerchantItemBaseGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemBaseGroupFacade = new LocationItemBaseGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantItemFacade = new MerchantItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemFacade = new LocationItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
// TODO-combos: remove this when old combo integration will be removed, since new combos don't have promotions
export const comboItemFacade = new ComboItemFacade(getConfig(), getInterceptors());
export const menuSyncConfigFacade = new MenuSyncConfigFacade(getConfig(BASE_PATH_MENU_SYNC), getInterceptors());

export const batchItemsFacade = new BatchItemsFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
