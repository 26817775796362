import { PatchItemBaseGroupDto, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseGroupFacade } from '../../../facades';

export type LocationUpdateItemBaseGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchItemBaseGroupDto;
};
export const useLocationUpdateItemBaseGroup = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, LocationUpdateItemBaseGroupParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, LocationUpdateItemBaseGroupParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseGroupFacade.patch(merchantId, storeId, uniqueName, data),
    options,
  );
};
