/**
 * @description Defines the behaviour for first-time field validation in useEntityForm hook
 */
export enum ValidationStrategyEnum {
  /**
   * When selected `onChange`, the field will be validated instantly, after first change of the field value
   */
  onChange = 'onChange',
  /**
   * When selected `onBlur`, the first field validation will be triggered after the blur event (proper handler has to be added).
   * After first validation, the input will be validated in the same way as for "onChange"
   */
  onBlur = 'onBlur',
  /**
   * When selected `onSave`, the first field validation will be triggered after the click on Submit button.
   * After first validation, the input will be validated in the same way as for "onChange"
   */
  onSave = 'onSave',
}
