import React, { Context, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useWakeTime } from '@goparrot-dashboard/shared-utils';
import { TokenUtility } from '@goparrot-dashboard-api/shared';
import { Socket } from 'socket.io-client';
import { createWSConnection } from '../utils/createWSConnection';
import { WebsocketContextType } from '../types';

type WebsocketServiceProps = {
  Context: Context<WebsocketContextType>;
  namespace: string;
  onConnected?(socket: Socket);
};

export const WebsocketService: FC<React.PropsWithChildren<WebsocketServiceProps>> = ({ children, Context, namespace, onConnected }) => {
  const socketRef = useRef<Socket>(null);
  const [socket, setSocket] = useState<Socket>(null);
  const onConnect = useCallback(() => {
    onConnected(socketRef.current);
  }, [onConnected]);

  const initSocket = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    const socketInstance = createWSConnection(TokenUtility.get().token, namespace);
    socketInstance.on('connect', onConnect);

    socketRef.current = socketInstance;
    setSocket(socketInstance);
  }, [namespace, onConnect]);

  useEffect(() => {
    initSocket();
    return () => {
      socketRef.current.disconnect();
      socketRef.current = null;
      setSocket(null);
    };
  }, [initSocket]);

  // window offline/online handling
  const handleOnlineStatus = useCallback(() => {
    if (!socketRef.current || (socketRef.current && !socketRef.current.connected)) {
      initSocket();
    }
  }, [initSocket]);

  useEffect(() => {
    window.addEventListener('online', handleOnlineStatus);
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
    };
  });

  useWakeTime(handleOnlineStatus);

  return (
    <Context.Provider
      value={{
        socket,
      }}
    >
      {children}
    </Context.Provider>
  );
};
