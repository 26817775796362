import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ReadLocationGroupDto, PatchLocationGroupDto } from '@goparrot/location-group-sdk';
import { locationGroupFacade } from '../facades';

export type UpdateLocationGroupParams = {
  merchantId: string;
  uuid: string;
  data: PatchLocationGroupDto;
};

export const useUpdateLocationGroup = (
  options?: UseMutationOptions<ReadLocationGroupDto, unknown, UpdateLocationGroupParams, unknown>,
): UseMutationResult<ReadLocationGroupDto, unknown, UpdateLocationGroupParams, unknown> => {
  return useMutation(({ merchantId, uuid, data }) => locationGroupFacade.update(merchantId, uuid, data), options);
};
