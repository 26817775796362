import { getIntlString } from '@goparrot-dashboard/i18n';

// button text; will be shown at SQ dashboard side
export const returnToCompleteStr = getIntlString('page.merchant.franchise.giftCards.pooling.setupAccount.returnToComplete');

export const REDIRECT_FROM = 'redirectFrom';

export const RETURN_TO_URL_PARAMS = {
  [REDIRECT_FROM]: 'SQChecking',
};
