import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, InputNumber, LabelWithTooltip } from '@goparrot-dashboard/shared-ui';
import { ReadStoreItemMetadataDto } from '@goparrot/storeitems-sdk';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useMenuFormContext } from '../../context';
import { preventMinusKey } from './utils';

export const ItemAttributes = <T extends { metadata: ReadStoreItemMetadataDto }>(): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);
  const minQuantity = bindInputProps('metadata.minQuantity');
  const maxQuantity = bindInputProps('metadata.maxQuantity');
  const maxQuantityFormField = bindFormFieldProps('metadata.maxQuantity');

  return (
    <div>
      <FormField
        {...bindFormFieldProps('metadata.minQuantity')}
        label={
          <LabelWithTooltip tooltip={getIntlString('page.merchant.menu.item.minQuantity.tooltip', { isRootPath: true })}>
            {getIntlString('page.merchant.menu.item.minQuantity', { isRootPath: true })}
          </LabelWithTooltip>
        }
        input={
          <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full">
            <InputNumber {...minQuantity} onKeyDown={preventMinusKey} name="metadata.minQuantity" className="tw-pr-2" min={1} />
          </div>
        }
      />
      <FormField
        {...maxQuantityFormField}
        label={
          <LabelWithTooltip tooltip={getIntlString('page.merchant.menu.item.maxQuantity.tooltip', { isRootPath: true })}>
            {getIntlString('page.merchant.menu.item.maxQuantity', { isRootPath: true })}
          </LabelWithTooltip>
        }
        input={
          <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-h-full">
            <InputNumber {...maxQuantity} onKeyDown={preventMinusKey} name="metadata.maxQuantity" className="tw-pr-2" min={1} />
          </div>
        }
      />
    </div>
  );
};
