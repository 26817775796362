import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemOptionsFacade } from '../../facades';

type ItemOptionsGetParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationItemOptionsGet = (
  { merchantId, storeId, uniqueName }: ItemOptionsGetParams,
  options?: UseQueryOptions<ReadItemOptionsDto>,
): UseQueryResult<ReadItemOptionsDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_OPTION_GET, merchantId, storeId, uniqueName],
    async () => await locationItemOptionsFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
