import type { ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemBundleFacade } from '../../../facades';
import { ComboGroupUpdateMutationParams } from '../../../interface';

export type LocationComboGroupUpdateParams = ComboGroupUpdateMutationParams & {
  storeId: string;
};

export const useUpdateLocationComboGroup = (
  options?: UseMutationOptions<ReadStoreItemBundleDto, unknown, LocationComboGroupUpdateParams>,
): UseMutationResult<ReadStoreItemBundleDto, unknown, LocationComboGroupUpdateParams> => {
  return useMutation(({ merchantId, uniqueName, storeId, data }) => locationStoreItemBundleFacade.patch(merchantId, storeId, uniqueName, data), options);
};
