import { MenuOrchestrationContext, useWebsocket, WebsocketListener } from '@goparrot-dashboard/websocket';
import React, { FC, useCallback, useMemo } from 'react';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MenuOrchestratorEventEnum } from '@goparrot/websocket-sdk';
import { useMenuOrchestrationContext } from '../menu-orchestration-service/MenuOrchestrationService';

/**
 * @description This component should be wrapped within these components:
 * MenuOrchestrationService
 * WebsocketService
 */
export const WSListenerService: FC<React.PropsWithChildren<unknown>> = () => {
  const { fetchLocations: fetchMenuLocations } = useMenuOrchestrationContext();
  const { notifySuccess } = useNotifications();

  const onMenuSynced = useCallback(
    (e: any) => {
      if (e.error) {
        return;
      }
      fetchMenuLocations();
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.menu.synced.success.message'),
      });
    },
    [fetchMenuLocations],
  );

  const menuListeners: WebsocketListener[] = useMemo(
    () => [
      [MenuOrchestratorEventEnum.MENU_DELETED, fetchMenuLocations],
      [MenuOrchestratorEventEnum.MENU_SYNCHRONIZED, onMenuSynced],
    ],
    [fetchMenuLocations, onMenuSynced],
  );

  useWebsocket(MenuOrchestrationContext, menuListeners);

  return <div />;
};
