import { useCallback, useState } from 'react';

import { UseDisabledFormResult, SetBooleanFieldValue, MethodWithPathOrField } from '../../types';

import { useBooleanFormState } from './useFormBooleanState';

export function useFormDisabled<T>(): UseDisabledFormResult<T> {
  const [isDisabledForm, setIsDisabledForm] = useState<boolean>(false);
  const [disabledState, setDisabledState, getDisabledFieldValue, setDisabledFieldValue] = useBooleanFormState<T>();

  const setDisabledField: SetBooleanFieldValue<T> = useCallback((path, disabledState = true) => setDisabledFieldValue(path, disabledState), [
    setDisabledFieldValue,
  ]);

  const isDisabledField: MethodWithPathOrField<T, boolean> = useCallback(
    (path) => {
      if (isDisabledForm) {
        return true;
      }
      return getDisabledFieldValue(path);
    },
    [getDisabledFieldValue, isDisabledForm],
  );

  return {
    isDisabledForm,
    disabledFieldsState: disabledState,
    setIsDisabledForm,
    setDisabledField,
    isDisabledField,
    setDisabledState,
  };
}
