import { ReadDefaultStoreDto, ReadMerchantDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import type { RootState } from '@goparrot-dashboard/shared-utils';
import { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { MultiEntityTypeEnum } from '@goparrot/common';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { WebStoreUrlsDto } from '@goparrot/webstore-sdk';
import { createSelector } from 'reselect';
import type { StoreServiceContextType } from '../contexts/StoreServiceContext';
import { StoreServiceContext } from '../contexts/StoreServiceContext';

interface ReduxProps {
  merchant: ReadMerchantDto;
  isMerchantFetching: boolean;
  stores: ReadStoreDto[];
  defaultStores: ReadDefaultStoreDto[];
  selectedStoreId: string;
  userStores: string[] | null;
  locationsFetching: boolean;
  locationDefaultFetching: boolean;
  isMerchant: boolean;
  levelParentUrl: string;
  timeZone: string;
  webStoreUrls: WebStoreUrlsDto;
}

interface FranchiseReduxProps {
  isFranchise: boolean;
  isMultiEntityType: boolean;
  franchiseLocations: ReadLocationDto[] | null;
  franchiseLocationsLoading: boolean;
}

const getReduxData = createSelector<
  RootState,
  ReadMerchantDto,
  boolean,
  ReadStoreDto[],
  ReadDefaultStoreDto[],
  string,
  string[],
  boolean,
  boolean,
  boolean,
  string,
  string,
  WebStoreUrlsDto,
  ReduxProps
>(
  ({ root }) => root.merchant.data,
  ({ root }) => root.merchant.fetching,
  ({ root }) => root.location.data ?? [],
  ({ root }) => root.locationDefault.data,
  ({ root }) => root.level.selected,
  ({ root }) => root.user.info.data.stores,
  ({ root }) => root.location.fetching,
  ({ root }) => root.locationDefault.fetching,
  ({ root }) => root.level.isMerchant,
  ({ root }) => root.level.parentUrl,
  ({ root }) => root.zone.timeZone,
  ({ root }) => root.webstore.webStoreUrls,
  (
    merchant,
    isMerchantFetching,
    stores,
    defaultStores,
    selectedStoreId,
    userStores,
    fetching,
    locationDefaultFetching,
    isMerchant,
    levelParentUrl,
    timeZone,
    webStoreUrls,
  ) => ({
    merchant,
    isMerchantFetching,
    stores,
    defaultStores,
    selectedStoreId,
    userStores,
    locationsFetching: fetching,
    locationDefaultFetching,
    isMerchant,
    levelParentUrl,
    timeZone,
    webStoreUrls,
  }),
);

const getReduxFranchiseData = createSelector<RootState, boolean, boolean, ReadLocationDto[] | null, boolean, FranchiseReduxProps>(
  ({ root }) => MultiEntityTypeEnum.FRANCHISE === root.merchant?.data?.featuresFlags?.multiEntityType,
  ({ root }) => !!root.merchant?.data?.featuresFlags?.multiEntityType,
  ({ root }) => root.franchise.locations,
  ({ root }) => root.franchise.loading,
  (isFranchise, isMultiEntityType, franchiseLocations, franchiseLocationsLoading) => ({
    isFranchise,
    isMultiEntityType,
    franchiseLocations,
    franchiseLocationsLoading,
  }),
);

export const StoreServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    stores,
    merchant,
    isMerchantFetching,
    defaultStores,
    selectedStoreId,
    userStores,
    locationsFetching,
    locationDefaultFetching,
    isMerchant,
    levelParentUrl,
    timeZone,
    webStoreUrls,
  } = useSelector(getReduxData);
  const { isFranchise, isMultiEntityType, franchiseLocations, franchiseLocationsLoading } = useSelector(getReduxFranchiseData);

  const providerValue: StoreServiceContextType = {
    isVirtualMenu: isFranchise && !!merchant.featuresFlags?.isVirtualMenuEnabled,
    stores,
    merchant,
    isMerchantFetching,
    defaultStores,
    selectedStoreId,
    userStores: userStores,
    locationsFetching,
    locationDefaultFetching,
    isMerchant,
    isFranchise,
    isMultiEntityType,
    franchiseLocations,
    franchiseLocationsLoading,
    levelParentUrl,
    timeZone,
    webStoreUrls,
  };

  return <StoreServiceContext.Provider value={providerValue}>{children}</StoreServiceContext.Provider>;
};
