import { useStoreService } from '@goparrot-dashboard-core/store-service';
import React, { FC, CSSProperties } from 'react';
import classNames from 'classnames';
import { useI18n, I18nService } from '@goparrot-dashboard/i18n';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useDispatch } from 'react-redux';
import { Button } from '@goparrot-dashboard/shared-ui';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import aAuth from '../../../client/src/store/auth/actions';
import { useGlobalAppContext } from '../GlobalAppProvider';

import { SidebarItemList } from './components/SidebarItemList';
import { SidebarProvider } from './context';

import './Sidebar.css';

const SidebarModule: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const { getIntlString } = useI18n();
  const { isMerchant } = useStoreService();
  const { isSidebarVisibleOnMobile, setIsSidebarVisibleOnMobile } = useGlobalAppContext();

  const renderView = ({ style, ...props }: { style: CSSProperties }) => {
    const viewStyle: CSSProperties = {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'auto',
    };
    return <div className="box tw-pl-3 tw--mb-1 xl:tw-mb-0" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const logout = () => {
    dispatch(aAuth.logout());
  };

  return (
    <>
      {isSidebarVisibleOnMobile && (
        <div
          onClick={() => setIsSidebarVisibleOnMobile(false)}
          className={'tw-w-screen tw-bg-black tw-bg-opacity-50 tw-fixed tw-inset-0 tw-z-40 xl:tw-hidden'}
        ></div>
      )}
      <aside
        className={classNames([
          isSidebarVisibleOnMobile ? 'tw-translate-x-0' : 'tw--translate-x-full',
          'xl:tw-flex xl:tw-flex-col tw-border tw-border-cool-gray-200',
          'tw-w-94 xl:tw-w-56 tw-fixed xl:tw-relative tw-inset-y-0 tw-left-0 xl:tw--mr-px tw-pb-14 xl:tw-pb-0 tw-pt-14 xl:tw-pt-16',
          'tw-z-51 tw-bg-white tw-transform xl:tw-translate-x-0 tw-overflow-auto tw-transition-all',
        ])}
      >
        <Scrollbars
          renderTrackHorizontal={(props: Record<string, any>) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
          renderView={renderView}
          className="sidebar-scroll"
          hideTracksWhenNotNeeded={true}
        >
          <TransitionGroup>
            <CSSTransition key={isMerchant ? 'slide' : 'slide-rev'} classNames={isMerchant ? 'slide' : 'slide-rev'} timeout={{ enter: 350, exit: 350 }}>
              <div key={isMerchant ? 'slide' : 'slide-rev'} className="tw-absolute tw-w-full">
                <ul className="tw-list-none tw-pb-0.5 xl:tw-pb-0 tw-pl-0 tw-pr-4 tw-pt-7">{<SidebarItemList />}</ul>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Scrollbars>
        <div className={'tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-w-full tw-bg-white tw-py-4 tw-px-5 xl:tw-hidden'}>
          <div className="tw-bg-gradient-to-t tw-from-white tw-via-white/75 tw-h-5 tw-absolute tw--top-5 tw-left-0 tw-right-2"></div>
          <Button color="secondary" className="tw-w-full" onClick={logout}>
            {getIntlString('button.logout', { isRootPath: true })}
          </Button>
        </div>
      </aside>
    </>
  );
};

export const Sidebar: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <I18nService prefix="sidebar">
      <SidebarProvider>
        <SidebarModule />
      </SidebarProvider>
    </I18nService>
  );
};
