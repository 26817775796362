export enum ORCHESTRATION_QUERIES_ENUM {
  MENU_CENTRAL_LOCATION = 'MENU_CENTRAL_LOCATION',
  LINK_TO_CENTRAL_LOCATION = 'LINK_TO_CENTRAL_LOCATION',
  MENU_LOCATION_LIST = 'MENU_LOCATION_LIST',
  MENU_LOCATION_LIST_GROUPED = 'MENU_LOCATION_LIST_GROUPED',
  GIFT_CARD_GET_COLLISIONS = 'GIFT_CARD_GET_COLLISIONS',
  GIFT_CARD_LOCATION_LIST = 'GIFT_CARD_LOCATION_LIST',
  GIFT_CARD_SETTLEMENT_CONFIG = 'GIFT_CARD_SETTLEMENT_CONFIG',
  LOCATION_EXTERNAL_REFERENCE_LIST = 'LOCATION_EXTERNAL_REFERENCE_LIST',
  POS_GRID_GET_STATUS_LOCATIONS = 'POS_GRID_GET_STATUS_LOCATIONS',
}
