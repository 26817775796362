import { CreateMenuConceptDto, ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationMenuConceptsFacade } from '../../../facades';

export type LocationCreateMenuConceptParams = {
  merchantId: string;
  storeId: string;
  data: CreateMenuConceptDto;
};
export const useCreateLocationMenuConcept = (
  options: UseMutationOptions<ReadMenuConceptDto, unknown, LocationCreateMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, LocationCreateMenuConceptParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationMenuConceptsFacade.create(merchantId, storeId, data), options);
};
