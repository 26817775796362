import { StoreItemListResponseDto, IPagination, IStoreFilterParams } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type LocationStoreItemListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IStoreFilterParams };

export const useLocationStoreItemList = (
  { merchantId, storeId, pagination, params }: LocationStoreItemListParams,
  options?: UseQueryOptions<StoreItemListResponseDto>,
): UseQueryResult<StoreItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_LIST, merchantId, storeId, pagination, params],
    async () => await locationStoreItemFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
