import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { getIntlString } from '@goparrot-dashboard/i18n';

type Props = {
  locations: ReadStoreDto[];
  storeId: string;
  merchantId: string;
  isVirtualMenu: boolean;
};

export const getStoreTitle = ({ locations, storeId, merchantId, isVirtualMenu }: Props): string => {
  if (merchantId === storeId) {
    return isVirtualMenu ? getIntlString('page.merchant.menu.centralMenu') : getIntlString('menu.connections.table.merchantName');
  }
  return locations.find((location: ReadStoreDto) => location.storeId === storeId)?.title || storeId;
};
