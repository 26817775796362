import { useDispatch } from 'react-redux';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useNotifications } from '@goparrot-dashboard/shared-ui';

import aLocation from '../../../../modules/client/src/store/location/actions';

type UseAfterDeleteStoreResult = {
  (storeId: string): void;
};

export const useAfterStoreDelete = (): UseAfterDeleteStoreResult => {
  const dispatch = useDispatch();
  const { getIntlString } = useI18n();
  const { notifySuccess } = useNotifications();

  const onAfterDelete = (storeId: string) => {
    dispatch(aLocation.successDelete(storeId));
    notifySuccess({
      text: getIntlString('notification.location.delete.success.description', { isRootPath: true }),
    });
  };

  return onAfterDelete;
};
