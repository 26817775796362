import * as React from 'react';
import { ItemToggle } from './ItemToggle';
import { getMenuFormIntlString } from './utils';

type Props = {
  labelString?: string;
};

export const ItemIsOrderable = <T extends { isOrderable: boolean }>({ labelString }: Props): React.ReactElement | null => {
  return (
    <ItemToggle<T>
      field="isOrderable"
      formLabel={getMenuFormIntlString(labelString || 'orderable')}
      checkedLabel={getMenuFormIntlString('orderable.checkedLabel')}
      uncheckedLabel={getMenuFormIntlString('orderable.uncheckedLabel')}
      franchiseEditPermissionField="canEditOrderable"
    />
  );
};
