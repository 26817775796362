import React, { FC } from 'react';
import { MarketTag } from '@market/react-bindings';
import { useI18n } from '@goparrot-dashboard/i18n';
import { flushSync } from 'react-dom';
import { ConnectionItem } from '../../../interface';
import { useConnectionsContext } from '../context';
import { isArchived, isMenuConcept } from '../../../utils';

export const ModalSelectedItems: FC<React.PropsWithChildren<unknown>> = () => {
  const { handleRemoveSelection, selectedItems, itemsList, selectedLevelStoreId, merchantId } = useConnectionsContext();
  const { getIntlString } = useI18n();
  const isMerchant = !selectedLevelStoreId || selectedLevelStoreId === merchantId;

  const filteredByLimitations = React.useMemo(
    () =>
      selectedItems.reduce<{ uniqueName: string; title?: string }[]>((acc, { uniqueName }) => {
        const item = itemsList.find((item: ConnectionItem) => item.uniqueName === uniqueName);

        if (!isMenuConcept(item) && isArchived(item, isMerchant, selectedLevelStoreId)) {
          return acc;
        }

        acc.push({
          uniqueName,
          title: item?.title,
        });

        return acc;
      }, []),
    [isMerchant, itemsList, selectedItems, selectedLevelStoreId],
  );

  return (
    <div className="tw-pr-5 tw-pl-10 tw-pt-3 tw-pb-4 tw-space-y-2 tw-h-48 tw-overflow-y-auto tw-max-w-96">
      <div className="tw-font-bold tw-text-sm tw-text-blue-gray-900">{getIntlString('modal.selected')}</div>
      <div className="tw-w-full tw-space-y-2 tw-space-x-1">
        {filteredByLimitations.length ? (
          filteredByLimitations.map(({ uniqueName, title }) => (
            <MarketTag
              key={uniqueName}
              onMarketTagDismissed={() => {
                flushSync(() => {
                  handleRemoveSelection(uniqueName);
                });
              }}
            >
              <div>{title}</div>
            </MarketTag>
          ))
        ) : (
          <div className="tw-text-sm tw-text-blue-gray-700">{getIntlString('modal.selected.empty')}</div>
        )}
      </div>
    </div>
  );
};
