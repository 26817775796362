import { PatchItemOptionsDto, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

export type MerchantUpdateItemOptionsParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchItemOptionsDto;
};

export const useMerchantUpdateItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, MerchantUpdateItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, MerchantUpdateItemOptionsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.patch(merchantId, uniqueName, data), options);
};
