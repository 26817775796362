import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { LinkToLocationDto, ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { ORCHESTRATION_QUERIES_ENUM } from '../enums';
import { locationFacade } from '../facades';

export const useLinkStoreToCentralMenu = (
  { merchantId, data }: { merchantId: string; data: LinkToLocationDto },
  options?: UseQueryOptions<ReadLocationDto>,
): UseQueryResult<ReadLocationDto> => {
  return useQuery([ORCHESTRATION_QUERIES_ENUM.LINK_TO_CENTRAL_LOCATION, merchantId], async () => locationFacade.linkToLocation(merchantId, data), options);
};
