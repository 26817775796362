import React, { FC } from 'react';
import classNames from 'classnames';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketButton, MarketButtonDropdown, MarketList, MarketRow } from '@market/react-bindings';
import { ACTIONS_OPTIONS, ACTIONS_VALUES } from './constants';

type Props = {
  selectAction: (action: string) => void;
  disabled: boolean;
};

export const ReplicasTableActions: FC<React.PropsWithChildren<Props>> = ({ selectAction, disabled }) => {
  return (
    <MarketButtonDropdown interaction="click">
      <MarketButton slot="trigger" disabled={disabled || undefined} color="secondary">
        <span>{getIntlString('menu.form.section.displaySettings.storeSpecific.modal.actions')}</span>
      </MarketButton>
      <MarketList slot="content" transient={true}>
        {ACTIONS_OPTIONS.map((item) => (
          <MarketRow
            value={item.value}
            key={`${item.label}-${item.value}`}
            onClick={() => {
              selectAction(item.value);
            }}
          >
            <div className="tw-flex tw-justify-between tw-items-start">
              <div
                className={classNames(
                  'tw-font-medium tw-break-words tw-w-40',
                  ACTIONS_VALUES.DELETE === item.value ? 'tw-text-red-400' : 'tw-text-blue-gray-900',
                )}
              >
                {item.label}
              </div>
            </div>
          </MarketRow>
        ))}
      </MarketList>
    </MarketButtonDropdown>
  );
};
