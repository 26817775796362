import { PatchWebMerchantDto as PatchWebMerchantModel, WebMerchantExposeGroup } from '@goparrot/webstore-sdk';
import { plainToClass } from 'class-transformer';
import assign from 'lodash/assign';

// @ts-ignore: workaround
PatchWebMerchantModel.prototype.init = function (options: Partial<PatchWebMerchantModel> = {}) {
  assign(this, plainToClass(PatchWebMerchantModel, options, { groups: [WebMerchantExposeGroup.PATCH], excludeExtraneousValues: true }));
  return this;
};

export { PatchWebMerchantModel };
