import { BaseInputBindingsOptions } from '@goparrot-dashboard/entity-form';
import { IAbstractItemMods } from '@goparrot/storeitems-sdk';
import { hasProperty } from '@goparrot-dashboard/shared-utils';
import { findMod } from '../utils';
import { useMenuFormContext } from '../context';
import { Item } from '../interface';

type ModsOptions = {
  path: string;
  transformOptions?: BaseInputBindingsOptions;
};

export const useModsProps = <T extends (path: string, options?: BaseInputBindingsOptions) => any>(bindProps: T, options: ModsOptions): ReturnType<T> => {
  const {
    form: { state, setFieldValue },
    selectedStoreId,
  } = useMenuFormContext<Item>();
  const { path, transformOptions } = options;

  const inputProps = bindProps(path, transformOptions);
  // for isOrderable & isAvailable fields when in mods we have null value
  const hasCheckedProperty = hasProperty(inputProps, 'checked');

  return {
    ...inputProps,
    ...(hasCheckedProperty && { checked: inputProps.checked ?? true }),
    onChange: (checked: boolean) => {
      const hasMod = findMod(selectedStoreId, state.mods as IAbstractItemMods[]);

      if (hasMod) {
        inputProps.onChange(checked);
      } else {
        const field = path.split('.').pop();
        setFieldValue('mods', [...state.mods, { storeId: selectedStoreId, [field]: checked }]);
      }
    },
  };
};
