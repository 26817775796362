import { ConfirmModal, FormField, Spinner, PanelTitle } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { StoreSelect } from '@goparrot-dashboard/shared-utils';
import { MenuOrchestratorEventEnum } from '@goparrot/websocket-sdk';
import { MenuOrchestrationContext, useWebsocket } from '@goparrot-dashboard/websocket';
import { useGetVendorList } from '@goparrot-dashboard-api/vendor';
import { DialogStateReturn } from 'reakit/Dialog';

import { VendorProviderEnum } from '@goparrot/common';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { useMenuOrchestrationContext } from '../../services';
import { useCreateManyLocations, useItemSetsListQuery, usePosAuthorizedStores } from '../../hooks';
import { MenuSelect } from '../menu-select/MenuSelect';

import { MenuLocationsTable } from './MenuLocationsTable';

type MenuLocationsProps = {
  addLocationsDialog: DialogStateReturn;
  hideSyncButton?: boolean;
  searchFilter?: string;
};

export const MenuLocations: FC<React.PropsWithChildren<MenuLocationsProps>> = ({ addLocationsDialog, hideSyncButton = false, searchFilter }) => {
  const {
    merchant: { merchantId, featuresFlags },
    stores,
  } = useStoreService();

  const { fetchLocations, locations, isLoading: isLoadingLocations } = useMenuOrchestrationContext();

  const selectedStoresId = React.useMemo(
    () => locations.filter((store) => store.franchiseLocation && !store.franchiseLocation.isCentralLocation).map((item) => item.storeId),
    [locations],
  );
  const isMultiMenu = featuresFlags?.isMultiMenuEnabled;

  const filteredLocations = locations ? locations.filter((store) => !store.franchiseLocation?.isCentralLocation && !store.franchiseLocation) : [];
  const [deleteSyncingLocations, setDeleteSyncingLocations] = useState<string[]>([]);
  const { data } = useItemSetsListQuery({ merchantId });

  // add multiple location
  const [locationsToAdd, setLocationsToAdd] = useState<string[]>([]);
  const [itemsSetsToAdd, setItemsSetsToAdd] = useState<string[]>([]);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const locationsHasError = locationsToAdd !== null && !locationsToAdd?.length;
  const menusHasError = isMultiMenu && !itemsSetsToAdd.length;
  const allLocationsStoreId = filteredLocations.map((item) => item.storeId);
  const [resetLocationsToAdd, setResetLocationsToAdd] = useState(false);
  const locationToAddFiltered = locationsToAdd?.filter((val) => !selectedStoresId.includes(val));
  const allLocationsStoreIdFiltered = allLocationsStoreId
    ?.filter((val) => !selectedStoresId.includes(val))
    .map((item) => ({
      storeId: item,
    }));

  const onLocationsCreatedsuccessful = useCallback(() => {
    fetchLocations();
    addLocationsDialog.hide();
    setTimeout(() => setLocationsToAdd([]), 200);
  }, [addLocationsDialog, fetchLocations]);

  const handleOnCancel = () => {
    addLocationsDialog.hide();
    setLocationsToAdd([]);
    setItemsSetsToAdd([]);
    setFormTouched(false);
  };

  const { createMany } = useCreateManyLocations(onLocationsCreatedsuccessful);

  const submitAddLocations = () => {
    if (!locationsToAdd) {
      createMany(
        merchantId,
        allLocationsStoreIdFiltered.map((el) => ({ ...el, itemSets: itemsSetsToAdd })),
      );
      addLocationsDialog.hide();
    }

    if (locationsToAdd?.length && locationToAddFiltered) {
      createMany(
        merchantId,
        locationToAddFiltered.map((storeId) => ({ storeId, itemSets: itemsSetsToAdd })),
      );
    } else {
      addLocationsDialog.hide();
    }
    setTimeout(() => {
      setResetLocationsToAdd(!resetLocationsToAdd);
      setItemsSetsToAdd([]);
    }, 1000);
  };

  const onMenuSynced = useCallback(
    (e: any) => {
      if (e.error) {
        fetchLocations();
      }
    },
    [fetchLocations],
  );

  const onLocationDeleted = useCallback(
    (e: any) => {
      if (e.error) {
        fetchLocations();
      }
      const newDeleteSyncLocations = deleteSyncingLocations.filter((locationId) => !e.storeIds?.some((storeId: string) => storeId === locationId));
      setDeleteSyncingLocations(newDeleteSyncLocations);
    },
    [deleteSyncingLocations, fetchLocations],
  );

  useWebsocket(MenuOrchestrationContext, [[MenuOrchestratorEventEnum.MENU_SYNCHRONIZED, onMenuSynced]]);
  useWebsocket(MenuOrchestrationContext, [[MenuOrchestratorEventEnum.MENU_DELETED, onLocationDeleted]]);

  const vendorList = useGetVendorList({ merchantId }, { retry: 3 });
  const posAuthorizedStoresQuery = usePosAuthorizedStores();

  const posAuthorizedStores = useMemo(() => {
    if (posAuthorizedStoresQuery.data) {
      return posAuthorizedStoresQuery.data.reduce<Record<string, ReadPaymentStoreDto>>((acc, item) => {
        acc[item.storeId] = item;

        return acc;
      }, {});
    }
    return {};
  }, [posAuthorizedStoresQuery]);

  const squareVendorList = React.useMemo(
    () =>
      vendorList.data?.rows?.filter((vendor) => vendor.provider === VendorProviderEnum.SQUARE && stores.some((item) => item.storeId === vendor.storeId)) || [],
    [stores, vendorList.data?.rows],
  );

  const trySubmit = () => {
    if (menusHasError || locationsHasError) {
      setFormTouched(true);
    } else {
      submitAddLocations();
    }
  };

  return (
    <div>
      <ConfirmModal
        key="addLocationsModal"
        dialog={addLocationsDialog}
        header={getIntlString('page.merchant.franchise.locations.addLocationModal.title')}
        cancelText={getIntlString('page.merchant.franchise.locations.addLocationModal.cancel')}
        confirmText={getIntlString('page.merchant.franchise.locations.addLocationModal.okText')}
        onConfirm={trySubmit}
        onCancel={handleOnCancel}
        maxWidth="tw-max-w-screen-lg"
        content={
          <div>
            <PanelTitle hasMargin={false}>{getIntlString('page.merchant.franchise.locations.addLocationModal.franchisee.title')}</PanelTitle>
            <div className="tw-text-black tw-mb-3">{getIntlString('page.merchant.franchise.locations.addLocationModal.franchisee.description')}</div>
            <div className="tw-mb-8">
              <Spinner spinning={posAuthorizedStoresQuery.isLoading || vendorList.isLoading}>
                <FormField
                  label={getIntlString('page.merchant.franchise.locations.addLocationModal.franchisee.label')}
                  withValuePadding
                  hasError={formTouched && locationsHasError}
                  errorMessage={getIntlString('page.merchant.franchise.locations.addLocationModal.location.required')}
                  input={
                    <StoreSelect
                      isFranchiseSelect
                      posAuthorizedStores={posAuthorizedStores}
                      squareVendorList={squareVendorList}
                      value={locationsToAdd}
                      onChange={(locations) => setLocationsToAdd(locations as string[])}
                      locations={filteredLocations}
                      showNumberOfStores={Infinity}
                      hasAllStoresOption
                    />
                  }
                />
              </Spinner>
            </div>
            {isMultiMenu && (
              <>
                <PanelTitle hasMargin={false}>{getIntlString('page.merchant.franchise.locations.addLocationModal.menu.title')}</PanelTitle>
                <div className="tw-text-black tw-mb-3">{getIntlString('page.merchant.franchise.locations.addLocationModal.menu.description')}</div>
                <div>
                  <FormField
                    label={getIntlString('page.merchant.franchise.locations.addLocationModal.menu.label')}
                    withValuePadding
                    hasError={formTouched && menusHasError}
                    errorMessage={getIntlString('page.merchant.franchise.locations.addLocationModal.menu.required')}
                    input={<MenuSelect value={itemsSetsToAdd} onChange={(itemSets: string[]) => setItemsSetsToAdd(itemSets)} itemSets={data?.data} />}
                  />
                </div>
              </>
            )}
          </div>
        }
      />
      <Spinner spinning={isLoadingLocations}>
        <MenuLocationsTable
          hideSyncButton={hideSyncButton}
          searchFilter={searchFilter}
          deleteSyncingLocations={deleteSyncingLocations}
          setDeleteSyncingLocations={setDeleteSyncingLocations}
        />
      </Spinner>
    </div>
  );
};
