import { getConfig, getInterceptors } from '@goparrot-dashboard-api/shared';
import { LocationFacade, GridLayoutFacade } from '@goparrot/menu-orchestrator-sdk';
import { GiftCardCollisionsFacade, SettlementConfigurationFacade } from '@goparrot/giftcard-orchestrator-sdk';
import { MerchantLocationFacade } from '@goparrot/giftcard-orchestrator-sdk';
import { FranchiseStoreFacade } from '@goparrot/store-v2-sdk';

export const locationFacade = new LocationFacade(getConfig('menuOrchestrator'), getInterceptors());
export const gridLayoutFacade = new GridLayoutFacade(getConfig('menuOrchestrator'), getInterceptors());

export const giftCardCollisionFacade = new GiftCardCollisionsFacade(getConfig('giftcard-orchestrator'), getInterceptors());
export const giftCardSettlementConfigurationFacade = new SettlementConfigurationFacade(getConfig('giftcard-orchestrator'), getInterceptors());

export const giftCardMerchantLocationFacade = new MerchantLocationFacade(getConfig('api/v2/giftcard-orchestrator'), getInterceptors());

export const franchiseStoreFacade = new FranchiseStoreFacade(getConfig('api/v2/store-v2'), getInterceptors());
