import React, { FC } from 'react';
import { useI18n } from '@goparrot-dashboard/i18n';
import { PanelTitle, FormField, Spinner } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MenuSelect } from '../menu-select/MenuSelect';
import { useItemSetsListQuery } from '../../hooks';

type FranchiseMenuSettingsProps = {
  itemSets?: string[];
  onChange: (itemSets: string[]) => void;
  loading: boolean;
};

export const FranchiseMenuSettings: FC<React.PropsWithChildren<FranchiseMenuSettingsProps>> = ({ itemSets, onChange, loading }) => {
  const { getIntlString } = useI18n();
  const {
    merchant: { merchantId },
  } = useStoreService();
  const { data } = useItemSetsListQuery({ merchantId });

  return (
    <Spinner spinning={loading}>
      <PanelTitle>{getIntlString('locations.menuSettings')}</PanelTitle>
      <div>
        <FormField
          label={getIntlString('locations.addLocationModal.menu.label')}
          withValuePadding
          input={<MenuSelect value={itemSets} onChange={onChange} itemSets={data?.data} />}
        />
      </div>
    </Spinner>
  );
};
