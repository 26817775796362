import { ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationMenuConceptsFacade } from '../../../facades';

export type LocationDeleteMenuConceptParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
};

export const useDeleteLocationMenuConcept = (
  options?: UseMutationOptions<ReadMenuConceptDto, unknown, LocationDeleteMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, LocationDeleteMenuConceptParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName }) => await locationMenuConceptsFacade.delete(merchantId, storeId, uniqueName), options);
};
