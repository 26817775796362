import { ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantMenuConceptsFacade } from '../../facades';

type MenuConceptGetParams = { merchantId: string; uniqueName: string };

export const useMerchantMenuConceptGet = (
  { merchantId, uniqueName }: MenuConceptGetParams,
  options?: UseQueryOptions<ReadMenuConceptDto>,
): UseQueryResult<ReadMenuConceptDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_MENU_CONCEPT_GET, merchantId, uniqueName],
    async () => await merchantMenuConceptsFacade.get(merchantId, uniqueName),
    options,
  );
};
