import { ReadCategoryDto, IUpdateShowOrderResponse, UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemSetFacade } from '../../../facades';

export type LocationUpdateItemSetCategoriesShowOrderParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export type LocationUpdateItemSetCategoriesShowOrderResult = UseMutationResult<
  UpdateShowOrderResponseDto,
  unknown,
  LocationUpdateItemSetCategoriesShowOrderParams
>;

export const useUpdateLocationItemSetCategoriesShowOrder = (
  options: UseMutationOptions<IUpdateShowOrderResponse<ReadCategoryDto>, unknown, LocationUpdateItemSetCategoriesShowOrderParams>,
): LocationUpdateItemSetCategoriesShowOrderResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemSetFacade.updateCategoriesShowOrder(merchantId, storeId, uniqueName, data),
    options,
  );
};
