import { CreateSelectionTypeDto, ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationSelectionTypesFacade } from '../../../facades';

export type LocationCreateSelectionTypeParams = {
  merchantId: string;
  storeId: string;
  data: CreateSelectionTypeDto;
};

export const useLocationCreateSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, LocationCreateSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, LocationCreateSelectionTypeParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationSelectionTypesFacade.create(merchantId, storeId, data), options);
};
