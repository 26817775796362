import React from 'react';
import { useLocation } from 'react-router';

export const ScrollIntoView = () => {
  const location = useLocation<{ scroll?: boolean }>();
  const connectionsRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (location.state?.scroll && connectionsRef.current) {
      connectionsRef.current?.scrollIntoView();
    }
  }, [location.state?.scroll]);

  return <span ref={connectionsRef} />;
};
