import React from 'react';
import { Button, IconArrowRightSmall } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useMenuFormContext } from '../../context';
import { ActionButton, Actions } from '../Actions';
import { FranchiseTag } from './FranchiseTag';

export type DetailsHeaderProps = {
  actions?: ActionButton[];
  title?: string;
  showFranchiseTag?: boolean;
  onBack: () => void;
  dataTestId?: string;
};

export const DetailsHeader: React.FC<React.PropsWithChildren<DetailsHeaderProps>> = ({ actions, title, showFranchiseTag = false, onBack, dataTestId }) => {
  const { isMerchantLevelItem, isCreating, isMerchant } = useMenuFormContext();
  const { getIntlString } = useI18n();

  return (
    <div data-testid={dataTestId} className="tw-flex tw-justify-between tw-items-center">
      <div className="tw-flex tw-space-x-2 tw-items-stretch">
        <Button kind="clean" color="clean" className="tw-bg-white hover:tw-bg-blue-gray-100" onClick={onBack}>
          <IconArrowRightSmall className="tw-w-6 tw-h-6 tw-transform tw-rotate-180 tw-text-cool-gray-900" />
        </Button>
        <div className="tw-flex tw-space-x-1 tw-items-center tw-max-w-lg">
          {showFranchiseTag && <FranchiseTag />}
          <div className="tw-space-x-1">
            <div className="tw-text-2xl tw-text-blue-gray-900 tw-font-medium tw-break-all tw-pl-1.5">{title}</div>
            {isMerchantLevelItem && !isCreating && isMerchant && (
              <div className="tw-bg-orange-50 tw-py-1 tw-px-2 tw-text-cool-gray-700 tw-text-sm tw-font-normal">
                {getIntlString('page.merchant.menu.alert.description', { isRootPath: true })}
              </div>
            )}
          </div>
        </div>
      </div>
      <Actions actions={actions} />
    </div>
  );
};
