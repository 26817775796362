import { ReadItemOptionsDto, PatchAbstractModsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

type PatchItemOptionsModParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchAbstractModsDto;
};

export const usePatchItemOptionsMod = (
  options?: UseMutationOptions<ReadItemOptionsDto, unknown, PatchItemOptionsModParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, PatchItemOptionsModParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.patchMod(merchantId, uniqueName, data), options);
};
