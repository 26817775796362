import { ISyncJobEvent } from '@goparrot/menu-sync-sdk';
import { WebsocketJobStore } from '@goparrot-dashboard/menu-management-shared';
import a from '../actions';

interface Action {
  type: string;
  payload: ISyncJobEvent;
}

const initialState: WebsocketJobStore = {
  data: {},
  error: false,
  fetching: false,
};

export default function (state: WebsocketJobStore = initialState, action: Action) {
  switch (action.type) {
    case a.ITEM__SYNC__JOB__EMIT:
      return {
        ...state,
        fetching: true,
        error: false,
      };

    case a.ITEM__SYNC__JOB__SUCCESS:
      return {
        data: action.payload,
        fetching: false,
        error: false,
      };

    case a.ITEM__SYNC__JOB__SUBSCRIBE:
      return initialState;

    case a.ITEM__SYNC__JOB__ERROR:
      return {
        ...state,
        error: true,
        fetching: false,
      };

    default:
      return state;
  }
}
