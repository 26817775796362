export enum DashboardUniqueName {
  // CAMPAIGNS
  CAMPAIGN_REPORTS = 'campaign-reports',
  CAMPAIGN_PROMO_REPORTS = 'campaign-promo-reports',

  // COHORTS
  COHORT_VISUALIZATION = 'cohort-visualization',

  // MAIN
  CUSTOMER_ANALYSIS = 'customers-analysis',
  DISCOUNTS_COMPARISON = 'discounts-comparison',
  GIFT_CARDS_BALANCE = 'gift-cards-balance',
  ITEMS_PLANNING = 'items-planning',
  ITEMS_SALES = 'items-sales',
  ITEMS_SALES_COMPARISON = 'items-sales-comparison',
  LOYALTY_COMPARISON = 'loyalty-comparison',
  LOYALTY_POINTS = 'loyalty-points',
  LOYALTY_REPORT = 'loyalty-report',
  MODIFIERS_PLANNING = 'modifiers-planning',
  MODIFIERS_SALES = 'modifiers-sales',
  MODIFIERS_SALES_COMPARISON = 'modifiers-sales-comparison',
  ORDERS_REGISTRY = 'orders-registry',
  REFUNDS = 'refunds',
  SALES_COMPARISON = 'sales-comparison',
  SALES_OVERVIEW = 'sales-overview',
  TIPS = 'tips',
  TOTAL_DISCOUNTS = 'total-discounts',
  YEARLY_REPORT = 'yearly-report',

  // FRANCHISE
  CATEGORIES_COMPARISON = 'categories-comparison',
  CATEGORIES_OVERVIEW = 'categories-overview',
  CATEGORIES_OVERVIEW_STORE = 'categories-overview-store',
  FRANCHISE_DISCOUNT_COMPARISON = 'franchise-discount-comparison',
  FRANCHISE_GIFT_CARDS = 'franchise-gift-cards',
  FRANCHISE_INVENTORY = 'franchise-inventory',
  FRANCHISE_ITEM_SALES_COMPARISON = 'franchise-item-sales-comparison',
  FRANCHISE_ROYALTIES_AND_FEES = 'franchise-royalties-and-fees',
  FRANCHISE_LOYALTY_POINTS = 'franchise-loyalty-points',
  FRANCHISE_PAYMENT_METHODS = 'franchise-payment-methods',
  FRANCHISE_SALES_SUMMARY_COMPARISON = 'franchise-sales-summary-comparison',
  FRANCHISE_STORE_SPECIFIC_ITEM_SALES = 'franchise-store-specific-item-sales',
  FRANCHISE_STORE_SPECIFIC_MODIFIER_SALES = 'franchise-store-specific-modifier-sales',
  FRANCHISE_TOTAL_DISCOUNTS = 'franchise-total-discounts',
  FRANCHISE_TOTAL_ITEM_SALES = 'franchise-total-item-sales',
  FRANCHISE_TOTAL_MODIFIER_SALES = 'franchise-total-modifier-sales',
  FRANCHISE_TOTAL_MODIFIER_SALES_COMPARISON = 'franchise-modifier-sales-comparison',
  FRANCHISE_TOTAL_SALES = 'franchise-total-sales',
  SOURCE_SALES_COMPARISON = 'source-sales-comparison',
  SOURCE_SALES_OVERVIEW = 'source-sales-overview',
  FRANCHISE_SALES_TRENDS = 'franchise-sales-trends',
  FRANCHISE_GC_AUTOMATED_SETTLEMENT = 'franchise-gc-automated-settlement',
}
