import type { AxiosError } from 'axios';
import { AuthByEmailRequestDto, AuthResponseDto } from '@goparrot/dashboard-gateway-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { authFacade } from '../facades';

export type LoginParams = {
  data: AuthByEmailRequestDto;
};

export const useLogin = (options?: UseMutationOptions<AuthResponseDto, AxiosError, LoginParams>): UseMutationResult<AuthResponseDto, AxiosError, LoginParams> =>
  useMutation(({ data }) => authFacade.login(data), options);
