import { BatchPatchRequestDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { batchItemsFacade } from '../../../facades';

export type MerchantBatchPatchItemsParams = {
  merchantId: string;
  data: BatchPatchRequestDto;
};

export const useMerchantBatchPatchItems = (
  options: UseMutationOptions<void, unknown, MerchantBatchPatchItemsParams>,
): UseMutationResult<void, unknown, MerchantBatchPatchItemsParams> => {
  return useMutation(async ({ merchantId, data }) => await batchItemsFacade.patch(merchantId, data), options);
};
