import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { merchantItemBaseGroupFacade } from '../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useGetMerchantItemBaseGroupChildrenQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadItemBaseDto[], Error>,
): UseQueryResult<ReadItemBaseDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_GROUP_GET_CHILDREN, merchantId, uniqueName],
    () => merchantItemBaseGroupFacade.getChildren(merchantId, uniqueName),
    options,
  );
};
