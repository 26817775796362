import { CreatePaymentStoreDto, ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { paymentStoreFacade } from '../facades';

type CreatePaymentStoreParams = CreatePaymentStoreDto;
type CreatePaymentStoreResult = ReadPaymentStoreDto;

export const useCreatePaymentStore = (
  options?: UseMutationOptions<CreatePaymentStoreResult, unknown, CreatePaymentStoreParams>,
): UseMutationResult<CreatePaymentStoreResult, unknown, CreatePaymentStoreParams> => {
  return useMutation((payload) => paymentStoreFacade.create(payload), options);
};
