import React from 'react';
import { FormField, InputNumber, LabelWithTooltip } from '@goparrot-dashboard/shared-ui';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { useMenuFormContext } from '../../context';
import { Item } from '../../interface';
import { ReturnValue } from './interface';
import { preventMinusKey } from './utils';

type Props<T> = {
  field: keyof T;
  label: string | React.ReactNode;
  placeholder: string;
  tooltipLabel?: string;
};

export const ItemNumber = <T extends Record<string, string>>({ field, label, placeholder, tooltipLabel }: Props<T>): ReturnValue => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();
  const bindFormFieldProps = useFormFieldProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const bindInputProps = useInputProps(form);
  const inputProps = bindInputProps(field);

  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditLabels
    : franchisePermissions?.canEditLabels;

  return (
    <FormField
      {...bindFormFieldProps(field)}
      label={tooltipLabel ? <LabelWithTooltip tooltip={tooltipLabel}>{label}</LabelWithTooltip> : label}
      withValuePadding={false}
      input={
        <InputNumber
          {...inputProps}
          fallbackValue={0}
          disabled={isItemReplica ? !hasFranchisePermission || inputProps.disabled : inputProps.disabled}
          name={field}
          className="tw-px-4"
          placeholder={placeholder}
          onKeyDown={preventMinusKey}
        />
      }
    />
  );
};
