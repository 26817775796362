import { IPagination, IStoreFilterParams, ItemSetListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemSetFacade } from '../../facades';

type LocationItemSetListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IStoreFilterParams };

export const useLocationItemSetList = (
  { merchantId, storeId, pagination, params }: LocationItemSetListParams,
  options?: UseQueryOptions<ItemSetListResponseDto>,
): UseQueryResult<ItemSetListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_SETS_LIST, merchantId, storeId, pagination, params],
    async () => await locationItemSetFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
