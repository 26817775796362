import { takeEvery, put, call } from 'redux-saga/effects';

import aApp from 'store/app/actions';

import a from './actions';
import * as api from './api';

export function* handleGet({ payload } = {}) {
  try {
    const result = yield call(api.get(payload));
    yield put(a.successGet(result));
    return result;
  } catch (e) {
    yield put(a.errorGet());
  }
}
/**
 * @deprecated
 */
function* handleUpdate({ payload }) {
  try {
    const result = yield call(api.update(payload));
    yield put(a.successUpdate(result));

    yield put(aApp.pushNotificationWithSuccess('merchant.update.success.message', 'merchant.update.success.description'));
  } catch (e) {
    yield put(a.errorUpdate());
    yield put(aApp.pushNotificationWithError('merchant.update.error.message', 'merchant.update.error.description'));
  }
}

export default function* () {
  yield takeEvery(a.API__MERCHANT__UPDATE__REQUEST, handleUpdate);
}
