import { ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemSetFacade } from '../../facades';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useItemSetChildren = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadCategoryDto[]>,
): UseQueryResult<ReadCategoryDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_SET_CHILDREN_LIST, merchantId, uniqueName],
    async () => await merchantItemSetFacade.getChildren(merchantId, uniqueName),
    options,
  );
};
