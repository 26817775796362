import React, { Dispatch, SetStateAction, FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketList, MarketRadio, MarketRow, MarketSelect } from '@market/react-bindings';
import { ActionData, ORDERABILITY_OPTIONS, ORDERABILITY_VALUES } from './constants';

type Props = {
  actionData: ActionData;
  setActionData: Dispatch<SetStateAction<ActionData>>;
  disableInputs: boolean;
};

export const ActionInputReplicaOrderable: FC<React.PropsWithChildren<Props>> = React.memo(({ actionData, setActionData, disableInputs }) => {
  return (
    <MarketSelect
      name="orderable"
      value={actionData.isOrderable}
      disabled={disableInputs || undefined}
      onMarketSelectValueDidChange={(e) => {
        const value = e?.detail?.value as ORDERABILITY_VALUES;
        setActionData({ ...actionData, isOrderable: value });
      }}
    >
      <label>{getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability', { isRootPath: true })}</label>
      <MarketList slot="list">
        {ORDERABILITY_OPTIONS.map((option) => (
          <MarketRow key={option.value} value={option.value}>
            {option.label}
            <MarketRadio slot="control" />
          </MarketRow>
        ))}
      </MarketList>
    </MarketSelect>
  );
});
