import { ConfirmModal, GenericTable, IconTrash } from '@goparrot-dashboard/shared-ui';
import { ButtonLinkToStore } from '@goparrot-dashboard/shared-utils';
import { useI18n } from '@goparrot-dashboard/i18n';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { useDialogState } from 'reakit/Dialog';

import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ReadLocationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { GiftCardOrchestrationWSContext, useWebsocket, WebsocketListener } from '@goparrot-dashboard/websocket';
import { GiftCardOrchestratorEventEnum, IGiftCardOrchestratorResponse } from '@goparrot/websocket-sdk';
import { MarketButton } from '@market/react-bindings';
import { useGiftCardOrchestrationContext } from '../../services';
import { SyncStatusBadge } from '../sync-status-badge/SyncStatusBadge';
import { useDeleteGiftCardLocations } from '../../hooks';
import { getGiftCardLocationBadgeStatus } from '../../utils';
import { StoreWithLocation } from '../../types';

export const GiftCardLocationsTable: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    merchant: { merchantId },
    locationsFetching,
    locationDefaultFetching,
    defaultStores,
    selectedStoreId,
  } = useStoreService();

  const { getIntlString } = useI18n();

  const { fetchLocations, locations, updateLocationStatus } = useGiftCardOrchestrationContext();
  const giftCardListeners: WebsocketListener[] = useMemo(
    () => [
      [
        GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT,
        (payload: IGiftCardOrchestratorResponse) => updateLocationStatus(GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT, payload),
      ],
      [
        GiftCardOrchestratorEventEnum.GIFTCARDS_REPLICATION,
        (payload: IGiftCardOrchestratorResponse) => updateLocationStatus(GiftCardOrchestratorEventEnum.GIFTCARDS_REPLICATION, payload),
      ],
    ],
    [updateLocationStatus],
  );

  useWebsocket(GiftCardOrchestrationWSContext, giftCardListeners);

  // delete location area
  const [storeToDelete, setStoreToDelete] = useState<string | null>(null);
  const deleteDialogState = useDialogState({ animated: true });
  const { deleteLocations } = useDeleteGiftCardLocations(fetchLocations);
  const data = useMemo(() => locations.filter((store) => store.franchiseLocation && !store.franchiseLocation.isCentralLocation), [locations]);
  const hasSyncStatus = !!data[0]?.franchiseLocation?.syncStatus;

  const onDelete = useCallback(
    async (storeId: string | null) => {
      if (storeId) {
        deleteLocations(merchantId, [storeId]);
        deleteDialogState.hide();
        setStoreToDelete(null);
      }
    },
    [deleteLocations, merchantId, deleteDialogState],
  );

  const confirmDelete = useCallback((storeId: string) => {
    setStoreToDelete(storeId);
    deleteDialogState.show();
    // dialog state causes infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        className: 'tw-w-1/2',
        Header: getIntlString('page.merchant.orchestration.locations.table.nameHeader', { isRootPath: true }),
        Cell: ({ value }: { value: string }) => <span className="tw-text-light-blue-500 tw-max-w-200px xl:tw-max-w-full tw-inline-block">{value}</span>,
      },
      ...(hasSyncStatus
        ? [
            {
              Header: getIntlString('page.merchant.orchestration.locations.table.importStatusHeader', { isRootPath: true }),
              className: 'tw-text-center',
              accessor: 'import',
              Cell: ({ row: { original } }: { row: { original: StoreWithLocation<ReadLocationDto> } }) => (
                <SyncStatusBadge status={getGiftCardLocationBadgeStatus(original.franchiseLocation?.syncStatus?.import)} />
              ),
            },
            {
              Header: getIntlString('page.merchant.orchestration.locations.table.replicationStatusHeader', { isRootPath: true }),
              accessor: 'replication',
              className: 'tw-text-center',
              Cell: ({ row: { original } }: { row: { original: StoreWithLocation<ReadLocationDto> } }) => (
                <SyncStatusBadge status={getGiftCardLocationBadgeStatus(original.franchiseLocation?.syncStatus?.replication)} />
              ),
            },
          ]
        : []),
      {
        Header: '',
        accessor: 'storeId',
        Cell: ({ value: storeId }: { value: string }) => (
          <div className="tw-flex tw-items-center tw-justify-end">
            <ButtonLinkToStore
              storeId={storeId}
              locationsLoading={locationsFetching || locationDefaultFetching}
              defaultStores={defaultStores}
              selectedStoreId={selectedStoreId}
            ></ButtonLinkToStore>
            <MarketButton className="tw-ml-2" size="small" rank="tertiary" variant="destructive" onClick={() => confirmDelete(storeId)}>
              <IconTrash width={18} height={18} />
            </MarketButton>
          </div>
        ),
      },
    ],
    [confirmDelete, defaultStores, getIntlString, hasSyncStatus, locationDefaultFetching, locationsFetching, selectedStoreId],
  );

  const table = useTable({
    data,
    columns,
  });

  return (
    <>
      <GenericTable placeholder={getIntlString('locations.table.placeholder')} rowClassName="odd:tw-bg-cool-gray-50 tw-border-none" {...table} />
      <ConfirmModal
        dialog={deleteDialogState}
        header={getIntlString('page.merchant.orchestration.locations.deleteOneHeader', { isRootPath: true })}
        content={
          <p className="tw-text-lg">
            {getIntlString('page.merchant.orchestration.locations.deleteOnePrefix', { isRootPath: true })}&nbsp;
            <b>{locations?.find((l) => l.storeId === storeToDelete)?.name}&nbsp;</b>
            {getIntlString('page.merchant.orchestration.locations.deleteOneSuffix', { isRootPath: true })}
            <br />
            {getIntlString('page.merchant.orchestration.locations.deleteOneConfirm', { isRootPath: true })}
          </p>
        }
        onConfirm={() => onDelete(storeToDelete)}
      />
    </>
  );
};
