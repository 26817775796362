import * as React from 'react';
import styled from 'styled-components';

import Icon from './icon';

// @ts-ignore-next-line
const Spinner = styled(Icon)`
  font-size: 34px;
  color: #4482ff;
`;

const SpinnerGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => (
  <SpinnerGroup>
    <Spinner type="loading" spin />
  </SpinnerGroup>
);
