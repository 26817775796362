import { hasProperty } from '@goparrot-dashboard/shared-utils';
import { ReadLocationGroupDto } from '@goparrot/location-group-sdk';
import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { StoreSpecificItem } from './constants';
import { ReplicasUpdateData } from './StoreSpecificDisplaySettingsModal';

export const replicasCustomSort: any = (rowA: any, rowB: any, column) => {
  const valueA = rowA.original?.[column];
  const valueB = rowB.original?.[column];

  if (valueB && !valueA) {
    return 1;
  }
  if (!valueB && valueA) {
    return -1;
  }
  return 0;
};

export const INIT_TABLE_DATA = [];
export type LocationGroupItem = {
  locationGroupData: ReadLocationGroupDto;
  replicas: StoreSpecificItem[];
};
export type LocationGroupWithReplicasType = StoreSpecificItem | LocationGroupItem;
export const isLocationGroupItem = (item: LocationGroupWithReplicasType): boolean => {
  return hasProperty(item, 'locationGroupData');
};

export const sortLocationGroupsWithReplicas = (locationGroupsWithReplicas: LocationGroupWithReplicasType[]): LocationGroupWithReplicasType[] =>
  locationGroupsWithReplicas.sort((a, b) => {
    if (isLocationGroupItem(a) && !isLocationGroupItem(b)) {
      return -1;
    }
    if (!isLocationGroupItem(a) && isLocationGroupItem(b)) {
      return 1;
    }
    return 0;
  });

export const mapLocationGroupsWithReplicas = ({
  locationGroupList,
  filteredReplicasList,
}: {
  locationGroupList: ReadLocationGroupDto[];
  filteredReplicasList: StoreSpecificItem[];
}): LocationGroupWithReplicasType[] => {
  const locationGroups = locationGroupList;
  if (locationGroups && filteredReplicasList.length) {
    return filteredReplicasList.reduce((result, replica) => {
      const locationGroup = locationGroups.find((group) => (group.stores || []).find((storeId) => storeId === replica.storeId));
      if (locationGroup) {
        const existingLocationGroup = result.find((el) => el.locationGroupData && el.locationGroupData?.uuid === locationGroup.uuid);
        if (existingLocationGroup) {
          // add replica to existing location group item
          return result.map((item) => {
            if (isLocationGroupItem(item)) {
              if ((item as LocationGroupItem).locationGroupData.uuid === locationGroup.uuid) {
                return { ...item, replicas: [...item.replicas, replica] };
              }
            }
            return item;
          });
        }
        // initialize location group item
        return [...result, { locationGroupData: locationGroup, replicas: [replica] }];
      }
      return [...result, replica];
    }, []);
  }
  return [];
};

export const getTableItem = ({
  original,
  replicasUpdateData,
}: {
  original: ReadStoreItemDto;
  replicasUpdateData: React.MutableRefObject<ReplicasUpdateData>;
}): ReadStoreItemDto => {
  if (replicasUpdateData.current) {
    const updatedItem = replicasUpdateData.current[original.uniqueName];
    if (updatedItem) {
      return updatedItem as ReadStoreItemDto;
    }
  }
  return original;
};

export enum TEXT_TYPES {
  title = 'title',
  description = 'description',
  confirm = 'confirm',
}

export enum MULTIPLICITY {
  single = 'single',
  plural = 'plural',
}

export const getDeleteDialogTextId = ({ textType, multiplicity }: { textType: TEXT_TYPES; multiplicity: MULTIPLICITY }): string => {
  switch (textType) {
    case TEXT_TYPES.title:
      return `menu.form.section.displaySettings.storeSpecific.modal.actions.delete.dialog.title.${multiplicity}`;
    case TEXT_TYPES.description:
      return `menu.form.section.displaySettings.storeSpecific.modal.actions.delete.dialog.description.${multiplicity}`;
    case TEXT_TYPES.confirm:
      return `menu.form.section.displaySettings.storeSpecific.modal.actions.delete.dialog.confirm.${multiplicity}`;
    default:
      return '';
  }
};
