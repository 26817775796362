import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type LocationStoreItemGetParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationStoreItemGet = (
  { merchantId, storeId, uniqueName }: LocationStoreItemGetParams,
  options?: UseQueryOptions<ReadStoreItemDto>,
): UseQueryResult<ReadStoreItemDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GET, merchantId, storeId, uniqueName],
    async () => await locationStoreItemFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
