import type { PatchStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import storeActions from '../../../../modules/client/src/store/location/actions';
import { storeByMerchantFacade } from '../facades';

type PatchStoreByMerchantParams = {
  storeId: string;
  merchantId: string;
  payload: PatchStoreDto;
};
type PatchStoreByMerchantResult = ReadStoreDto;

export const usePatchStoreByMerchant = (
  options?: UseMutationOptions<PatchStoreByMerchantResult, unknown, PatchStoreByMerchantParams>,
): UseMutationResult<PatchStoreByMerchantResult, unknown, PatchStoreByMerchantParams> => {
  const dispatch = useDispatch();
  return useMutation(async ({ merchantId, storeId, payload }) => {
    const store = await storeByMerchantFacade.patch(merchantId, storeId, payload);
    dispatch(storeActions.successUpdate(store));
    return store;
  }, options);
};
