import { PatchMenuConceptDto, ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationMenuConceptsFacade } from '../../../facades';

export type LocationUpdateMenuConceptParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchMenuConceptDto;
};
export const useUpdateLocationMenuConcept = (
  options: UseMutationOptions<ReadMenuConceptDto, unknown, LocationUpdateMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, LocationUpdateMenuConceptParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationMenuConceptsFacade.patch(merchantId, storeId, uniqueName, data),
    options,
  );
};
