import { ExplicitFranchiseLocationOptions } from '../types';

const canAddNewItems = (config: ExplicitFranchiseLocationOptions): boolean => config.menu && config.menuSpecific;

const replicaCheck = (field: keyof ExplicitFranchiseLocationOptions) => (config: ExplicitFranchiseLocationOptions) => config.menuReplica && config[field];

type FranchisePermissionCheckType = (config: ExplicitFranchiseLocationOptions) => boolean | undefined;

const canEditConnections = replicaCheck('menuConnections');
const canEditLabels = replicaCheck('menuUpdateLabels');
const canEditArchived = replicaCheck('menuUpdateArchived');
const canEditAvailability = replicaCheck('menuUpdateAvailability');
const canEditOrderable = replicaCheck('menuUpdateOrderable');
const canEditPrice = replicaCheck('menuUpdatePrice');
const canEditSku = replicaCheck('menuUpdateSku');
const canEditExternalImageUrl = replicaCheck('menuUpdateExternalImageUrl');
const isCentralLocation = (config: ExplicitFranchiseLocationOptions): boolean => config.isCentralLocation;
const canEditItems = (config: ExplicitFranchiseLocationOptions): boolean | undefined =>
  canEditLabels(config) ||
  canEditArchived(config) ||
  canEditAvailability(config) ||
  canEditPrice(config) ||
  canEditOrderable(config) ||
  canEditConnections(config) ||
  canEditSku(config) ||
  canEditExternalImageUrl(config);

export const franchisePermissionCheck: Record<string, FranchisePermissionCheckType> = {
  isCentralLocation,
  canEditConnections,
  canEditLabels,
  canEditArchived,
  canEditAvailability,
  canEditOrderable,
  canEditPrice,
  canEditSku,
  canEditExternalImageUrl,
  canAddNewItems,
  // menu replica enabled, but without any of these options has zero effect
  canEditItems,
  hasNoPermissions: (config: ExplicitFranchiseLocationOptions): boolean =>
    0 ===
    [
      canEditConnections,
      canEditLabels,
      canEditArchived,
      canEditAvailability,
      canEditOrderable,
      canEditPrice,
      canAddNewItems,
      canEditItems,
      canEditSku,
      canEditExternalImageUrl,
    ]
      .map((method) => method(config))
      .filter(Boolean).length,
};
