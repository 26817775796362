import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadTemplateEnrollmentDto } from '@goparrot/store-v2-sdk/types/saas/generated-dto';
import { STORE_QUERIES_ENUM } from '../enums';
import { saasFacade } from '../facades';

export const useGetTemplateEnrollment = (
  merchantId: string,
  templateId: string,
  options?: UseQueryOptions<ReadTemplateEnrollmentDto, unknown, ReadTemplateEnrollmentDto>,
): UseQueryResult<ReadTemplateEnrollmentDto> => {
  return useQuery(
    [STORE_QUERIES_ENUM.GET_TEMPLATE_ENROLLMENT_BY_MERCHANT_ID, merchantId, templateId],
    () => saasFacade.getTemplate(merchantId, templateId),
    options,
  );
};
