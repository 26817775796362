import { createAction } from 'helpers/create-action';

export const ACTION_TYPES = {
  API__FRANCHISE__LOCATIONS_LIST__GET_REQUEST: 'API__FRANCHISE__LOCATIONS_LIST__GET_REQUEST',
  API__FRANCHISE__LOCATIONS_LIST__GET_SUCCESS: 'API__FRANCHISE__LOCATIONS_LIST__GET_SUCCESS',
  API__FRANCHISE__LOCATIONS_LIST__GET_ERROR: 'API__FRANCHISE__LOCATIONS_LIST__GET_ERROR',
};

export const ACTION_CREATORS = {
  requestLocationsListGet: createAction<{ merchantId: string }>(ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_REQUEST),
  successLocationsListGet: createAction(ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_SUCCESS),
  errorLocationsListGet: createAction(ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_ERROR),
};

export default {
  ...ACTION_TYPES,
  ...ACTION_CREATORS,
};
