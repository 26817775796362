import React, { FC } from 'react';
import { Button, Tooltip } from '@goparrot-dashboard/shared-ui';
import classNames from 'classnames';
import { getIntlString } from '@goparrot-dashboard/i18n';

type FranchiseTagProps = {
  className?: string;
  disableTooltip?: boolean;
};

export const FranchiseTag: FC<React.PropsWithChildren<FranchiseTagProps>> = ({ className, disableTooltip }) => (
  <div className={classNames([className, 'tw-inline-block'])}>
    <Tooltip tooltip={disableTooltip ? null : getIntlString('menu.list.table.columns.franchise-replica')}>
      <Button kind="clean" color="clean" size="clean" className="tw-px-2 tw-py-1 tw-rounded-md tw-bg-yellow-400 tw-text-white tw-text-sm tw-font-medium">
        F
      </Button>
    </Tooltip>
  </div>
);
