import React, { FC } from 'react';
import { hasProperty } from '@goparrot-dashboard/shared-utils';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ConnectionItem, Item } from '../../../../interface';
import { getStoreTitle } from '../../../../utils';
import { useConnectionsContext } from '../../context';

type ModalPreviewHeaderProps = {
  item: ConnectionItem;
};

export const ModalPreviewHeader: FC<React.PropsWithChildren<ModalPreviewHeaderProps>> = ({ item }) => {
  const { getIntlString } = useI18n();
  const { stores, merchantId } = useConnectionsContext();
  const { isVirtualMenu } = useStoreService();
  const storeName = React.useMemo(() => getStoreTitle({ locations: stores, storeId: item.storeId, merchantId, isVirtualMenu }), [
    stores,
    item.storeId,
    merchantId,
    isVirtualMenu,
  ]);
  const showPosGuid = hasProperty({ ...item }, 'metadata');
  const { metadata } = item as Item;
  const posGuidValue = metadata?.pos?.pos_guid || 'n/a';

  return (
    <div className="tw-space-y-2">
      <div className="tw-font-medium tw-text-2xl tw-text-blue-gray-900 tw-break-all">{item.title}</div>
      {showPosGuid && (
        <div className="tw-flex tw-space-x-0.5 tw-text-sm tw-text-blue-gray-900">
          <span className="tw-font-bold">{getIntlString('modal.preview.header.posGuid')}</span>
          <span>{posGuidValue}</span>
        </div>
      )}
      <div className="tw-flex tw-space-x-0.5 tw-text-sm tw-text-blue-gray-900">
        <span className="tw-font-bold">{getIntlString('modal.preview.header.store')}</span>
        <span>{storeName}</span>
      </div>
    </div>
  );
};
