import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { InviteEmailUserDto, ReadUserDto } from '@goparrot/users-v2-sdk';

import { userFacade } from '../facades';

type InviteUserParams = {
  data: InviteEmailUserDto;
};

export const useInviteUser = (
  options?: UseMutationOptions<ReadUserDto, unknown, InviteUserParams>,
): UseMutationResult<ReadUserDto, unknown, InviteUserParams> => {
  return useMutation(({ data }) => userFacade.invite(data), options);
};
