import { ICloneRequest, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseGroupFacade } from '../../../facades';

export type LocationCloneItemBaseGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useLocationCloneItemBaseGroup = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, LocationCloneItemBaseGroupParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, LocationCloneItemBaseGroupParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseGroupFacade.clone(merchantId, storeId, uniqueName, data),
    options,
  );
};
