import { round } from '@goparrot/order-sdk';
import { UseInputPropsResult } from '@goparrot-dashboard/entity-form';

export const onChangeTaxValue = (
  value: number | undefined,
  inputProps: UseInputPropsResult,
  setTaxVal: React.Dispatch<React.SetStateAction<number | null>>,
): void => {
  if (Number.isNaN(value)) {
    return;
  }
  if (value === undefined) {
    setTaxVal(null);
    inputProps.onChange(null);
    return;
  }
  setTaxVal(value);
  inputProps.onChange(round(value / 100));
};
