import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { LocationSyncStatusEnum, ReadLocationDto } from '@goparrot/giftcard-orchestrator-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GiftCardOrchestrationWSContext, useWebsocket, WebsocketListener } from '@goparrot-dashboard/websocket';
import { GiftCardOrchestratorEventEnum, IGiftCardOrchestratorResponse } from '@goparrot/websocket-sdk';
import cloneDeep from 'lodash/cloneDeep';
import { StoreWithLocation } from '../types';
import { giftCardCentralLocationFacade } from '../api';

export const useGiftCardCentralLocation = (
  merchantId: string,
  stores: ReadStoreDto[],
): {
  centralLocation: StoreWithLocation<ReadLocationDto> | null;
  refetch(): void;
  loading: boolean;
} => {
  const [centralLocation, setCentralLocation] = useState<StoreWithLocation<ReadLocationDto> | null>(null);
  const [loading, setLoading] = useState(false);

  const updateLocationStatus = useCallback((eventType: GiftCardOrchestratorEventEnum, status: IGiftCardOrchestratorResponse): void => {
    setCentralLocation((prev) => {
      const cloned = cloneDeep(prev);

      if (cloned?.franchiseLocation?.syncStatus && status.storeIds.includes(cloned.storeId)) {
        if (eventType === GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT) {
          cloned.franchiseLocation.syncStatus.import = (status.state as unknown) as LocationSyncStatusEnum;
        } else {
          cloned.franchiseLocation.syncStatus.replication = (status.state as unknown) as LocationSyncStatusEnum;
        }

        return cloned;
      }

      return prev;
    });
  }, []);
  const fetchCentralLocation = useCallback(async () => {
    setLoading(true);
    try {
      const data = await giftCardCentralLocationFacade.get(merchantId);
      const foundStore = stores?.find((store) => store.storeId === data.storeId);

      if (foundStore) {
        setCentralLocation({
          ...foundStore,
          franchiseLocation: data,
        });
      }
    } catch (e) {
      // central location not created yet
    }
    setLoading(false);
  }, [merchantId, stores]);

  useEffect(() => {
    if (merchantId && stores?.length) {
      fetchCentralLocation();
    }
  }, [fetchCentralLocation, merchantId, stores]);

  const giftCardListeners: WebsocketListener[] = useMemo(
    () => [
      [
        GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT,
        (payload: IGiftCardOrchestratorResponse) => updateLocationStatus(GiftCardOrchestratorEventEnum.GIFTCARDS_IMPORT, payload),
      ],
      [
        GiftCardOrchestratorEventEnum.GIFTCARDS_REPLICATION,
        (payload: IGiftCardOrchestratorResponse) => updateLocationStatus(GiftCardOrchestratorEventEnum.GIFTCARDS_REPLICATION, payload),
      ],
    ],
    [updateLocationStatus],
  );

  useWebsocket(GiftCardOrchestrationWSContext, giftCardListeners);

  return { centralLocation, refetch: fetchCentralLocation, loading };
};
