import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { locationStoreItemGroupFacade } from '../../../facades';
import { LocationGetRelativesQueryParams, StoreItemGroupChildrenType } from '../../../interface';

export const useLocationGetStoreItemGroupChildrenQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<StoreItemGroupChildrenType[]>,
): UseQueryResult<StoreItemGroupChildrenType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GROUP_GET_CHILDREN, merchantId, storeId, uniqueName],
    () => locationStoreItemGroupFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
