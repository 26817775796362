import { matchPath } from 'react-router';
import { RouteParams } from '../interface';
import { MENU_MANAGEMENT_MATCH_URL } from '../../constants';

export const getLastItemFromPathName = (pathName: string): string | null => {
  const match = matchPath<RouteParams>(pathName, {
    path: MENU_MANAGEMENT_MATCH_URL,
    exact: true,
    strict: false,
  });

  if (null !== match) {
    const { itemType } = match.params;
    return itemType;
  }

  return null;
};
