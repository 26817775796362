import type { IUpdateRelationsResponse } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemBundleFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

type UpdateLocationComboGroupItemSetsParams = UpdateConnectionMutationBaseParams & {
  storeId: string;
};

export const useUpdateLocationComboGroupItemSets = (
  options?: UseMutationOptions<IUpdateRelationsResponse, unknown, UpdateLocationComboGroupItemSetsParams>,
): UseMutationResult<IUpdateRelationsResponse, unknown, UpdateLocationComboGroupItemSetsParams> => {
  return useMutation(
    ({ merchantId, uniqueName, storeId, payload }) => locationStoreItemBundleFacade.updateItemSets(merchantId, storeId, uniqueName, payload),
    options,
  );
};
