import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  error: false,
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDER__GET__DELIVERY__REQUEST:
      return assign(state, { fetching: true });
    case a.API__ORDER__GET__DELIVERY__SUCCESS:
      return assign(initialState, { data: action.payload, fetching: false });
    case a.API__ORDER__GET__DELIVERY__ERROR:
      return assign(initialState, { fetching: false, error: true });
    case a.API__ORDER__RESET__DELIVERY:
      return initialState;

    default:
      return state;
  }
}
