import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { CategoryListResponseDto, IMerchantFilterParams } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { merchantCategoryFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<IMerchantFilterParams>;

export const useMerchantCategoryList = (
  { merchantId, pagination, params }: QueryParams,
  options?: UseQueryOptions<CategoryListResponseDto>,
): UseQueryResult<CategoryListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.MERCHANT_CATEGORY_LIST, merchantId, pagination, params],
    async () => await merchantCategoryFacade.list(merchantId, pagination, params),
    options,
  );
};
