import { DateTimeRangeType } from '../interface';

export const getRangeBetweenDates = (days: DateTimeRangeType[]): Date[] => {
  let dates: Date[] = [];
  days.map((item) => {
    const theDate = new Date(item.from.toJSDate());
    while (theDate <= item.to.toJSDate()) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    return dates;
  });
  return dates;
};
