import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IMerchantFilterParams, IPagination, StoreItemBundleListResponseDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  pagination?: IPagination;
  params?: IMerchantFilterParams;
};

export const useMerchantComboGroupListQuery = (
  { merchantId, pagination, params }: GetStoreItemsParams,
  options?: UseQueryOptions<StoreItemBundleListResponseDto, Error>,
): UseQueryResult<StoreItemBundleListResponseDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_COMBO_GROUP_LIST, merchantId, pagination, params],
    async () => merchantStoreItemBundleFacade.list(merchantId, pagination, params),
    options,
  );
};
