import { CreateItemOptionsDto, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

export type MerchantCreateItemOptionsParams = {
  merchantId: string;
  data: CreateItemOptionsDto;
};

export const useMerchantCreateItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, MerchantCreateItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, MerchantCreateItemOptionsParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantItemOptionsFacade.create(merchantId, data), options);
};
