import { IUpdateRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseGroupFacade } from '../../../facades';

export type LocationUpdateItemBaseGroupSelectionsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};
export const useLocationUpdateItemBaseGroupSelections = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateItemBaseGroupSelectionsParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateItemBaseGroupSelectionsParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseGroupFacade.updateItemOptionSelections(merchantId, storeId, uniqueName, data),
    options,
  );
};
