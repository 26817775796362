import { CreateLocationGroupDto as CreateLocationGroupModel } from '@goparrot/location-group-sdk';

(CreateLocationGroupModel as any).prototype.init = function (options: Partial<CreateLocationGroupModel> = {}) {
  this.merchantId = options.merchantId;
  this.name = options.name ?? '';
  this.description = options.description ?? '';
  this.parent = options.parent;
  this.stores = options.stores ?? null;
  return this;
};

export { CreateLocationGroupModel };
