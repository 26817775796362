import * as React from 'react';
import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/es/input-number';
import classNames from 'classnames';

import styles from './styles/input-number.module.scss';

const AntInputNumber: React.FC<React.PropsWithChildren<InputNumberProps>> = ({ className, ...props }: InputNumberProps) => (
  <InputNumber
    className={classNames({
      [styles.container]: true,
      [className]: !!className,
    })}
    {...props}
  />
);

export default AntInputNumber;
