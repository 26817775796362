import isObject from 'lodash/isObject';
import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';

/* eslint-disable no-param-reassign */
export const diff = (gObject: Record<string, any>, gBase: Record<string, any>, options = { ignore: [] }): Record<string, any> => {
  const changes = (lObject, lBase) =>
    transform(lObject, (result, value, key) => {
      if (!isEqual(value, lBase[key]) || options.ignore.includes(key)) {
        if (Array.isArray(value) && lBase[key]) {
          result[key] = value;
        } else {
          result[key] = isObject(value) && isObject(lBase[key]) ? changes(value, lBase[key]) : value;
        }
      }
    });
  return changes(gObject, gBase);
};
/* eslint-enable no-param-reassign */
