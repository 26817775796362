import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemSetFacade } from '../../facades';

type ItemSetGetParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationItemSetGet = (
  { merchantId, storeId, uniqueName }: ItemSetGetParams,
  options?: UseQueryOptions<ReadItemSetDto>,
): UseQueryResult<ReadItemSetDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_SET_GET, merchantId, storeId, uniqueName],
    async () => await locationItemSetFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
