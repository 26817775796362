import React from 'react';
import { hasPermission, hasSomePermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { FranchisePermissions } from '@goparrot-dashboard/orchestration';

export const useIsAddButtonDisabled = (franchisePermissions: FranchisePermissions | null): boolean => {
  const { roleV2 } = useUserService();
  const { isFranchise, isMerchant, merchant } = useStoreService();

  return React.useMemo(() => {
    // need to replace when mods permissions will be added
    const isStoreOperationsRole = hasPermission(roleV2, PermissionsV2.ROLE_PERMISSION.STORE_OPERATIONS);

    if (isStoreOperationsRole) return false;

    const userCanAddItems = hasSomePermission(
      roleV2,
      isMerchant
        ? [PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_CREATE]
        : [PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_CREATE, PermissionsV2.STORE_MENU_MANAGEMENT.API_CREATE],
    );

    if (!isFranchise) return !userCanAddItems;

    if (isMerchant && !merchant.featuresFlags.isVirtualMenuEnabled) return true;

    const isRestrictedByFranchisePermissions = franchisePermissions ? !franchisePermissions.canAddNewItems : false;

    return !userCanAddItems || isRestrictedByFranchisePermissions;
  }, [franchisePermissions, isFranchise, isMerchant, merchant.featuresFlags.isVirtualMenuEnabled, roleV2]);
};
