import { assign } from 'lodash/fp';

import a from './actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__MERCHANT__GET__REQUEST:
    case a.API__MERCHANT__UPDATE__REQUEST:
      return assign(state, { fetching: true });

    case a.API__MERCHANT__GET__SUCCESS:
    case a.API__MERCHANT__CREATE__SUCCESS:
    case a.API__MERCHANT__UPDATE__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.API__MERCHANT__UPDATE__ERROR:
      return initialState;

    default:
      return state;
  }
}
