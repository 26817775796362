import type { IUpdateRelationsResponse } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemComboFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

type UpdateLocationComboCategoriesParams = UpdateConnectionMutationBaseParams & {
  storeId: string;
};

export const useUpdateLocationComboCategories = (
  options?: UseMutationOptions<IUpdateRelationsResponse, unknown, UpdateLocationComboCategoriesParams>,
): UseMutationResult<IUpdateRelationsResponse, unknown, UpdateLocationComboCategoriesParams> => {
  return useMutation(
    ({ merchantId, uniqueName, storeId, payload }) => locationStoreItemComboFacade.updateCategories(merchantId, storeId, uniqueName, payload),
    options,
  );
};
