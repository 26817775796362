import { PatchCategoryDto, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationPatchCategoryParams = {
  merchantId: string;
  uniqueName: string;
  storeId: string;
  data: PatchCategoryDto;
};

export const useLocationPatchCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, LocationPatchCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, LocationPatchCategoryParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationCategoryFacade.patch(merchantId, storeId, uniqueName, data), options);
};
