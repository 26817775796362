import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { SyncRequestDto, ReadStatusDto } from '@goparrot/menu-orchestrator-sdk';
import { gridLayoutFacade } from '../facades';

export type UseSyncGridLayoutParams = {
  merchantId: string;
  payload: SyncRequestDto;
};

export const useSyncGridLayout = (
  options?: UseMutationOptions<ReadStatusDto[], unknown, UseSyncGridLayoutParams, unknown>,
): UseMutationResult<ReadStatusDto[], unknown, UseSyncGridLayoutParams, unknown> => {
  return useMutation(({ merchantId, payload }) => gridLayoutFacade.sync(merchantId, payload), options);
};
