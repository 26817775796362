import { ACTION_TYPES } from './actions';

const initialState = {
  loading: false,
  locations: null,
  error: null,
};

export default function (state = initialState, action: { type: keyof typeof ACTION_TYPES; payload?: any }): typeof initialState {
  switch (action.type) {
    case ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload,
      };
    case ACTION_TYPES.API__FRANCHISE__LOCATIONS_LIST__GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
