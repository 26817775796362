import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemBaseFacade } from '../../facades';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useMerchantItemBaseParents = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadItemOptionsDto[]>,
): UseQueryResult<ReadItemOptionsDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_GET_PARENTS, merchantId, uniqueName],
    async () => await merchantItemBaseFacade.getParents(merchantId, uniqueName),
    options,
  );
};
