import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IMerchantFilterParams, StoreItemListResponseDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { merchantStoreItemFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<IMerchantFilterParams>;

export const useMerchantStoreItemList = (
  { merchantId, pagination, params }: QueryParams,
  options?: UseQueryOptions<StoreItemListResponseDto>,
): UseQueryResult<StoreItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.MERCHANT_STORE_ITEM_LIST, merchantId, pagination, params],
    async () => await merchantStoreItemFacade.list(merchantId, pagination, params),
    options,
  );
};
