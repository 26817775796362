import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { UseQueryResult } from 'react-query';
import { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { useLinkStoreToCentralMenu } from '@goparrot-dashboard-api/orchestration';

type Props = {
  onSuccess: () => void;
  merchantId: string;
  storeId: string | null;
  enabled: boolean;
};

export const useLinkCentralMenu = ({ onSuccess = () => null, merchantId, storeId, enabled }: Props): UseQueryResult<ReadLocationDto> => {
  const { notifyError, notifySuccess } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.central-menu.create.error.message'),
      });
    },
    onSuccess: () => {
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.central-menu.create.success.message'),
      });

      onSuccess();
    },
  };

  return useLinkStoreToCentralMenu(
    {
      merchantId,
      data: {
        storeId: storeId ?? '',
      },
    },
    {
      ...options,
      enabled: enabled && !!storeId,
      retry: 1,
    },
  );
};
