import { IMerchantFilterParams, IStoreFilterParams } from '@goparrot/storeitems-sdk';
import { FilterSettingsType } from '@goparrot-dashboard/shared-utils';

export const getFormattedFilters = (filters: FilterSettingsType[]): IStoreFilterParams | IMerchantFilterParams => {
  return filters.reduce((acc, item) => {
    if (['isAvailable', 'isOrderable', 'isArchived', 'isReplica'].includes(item.field)) {
      if (item.selectedItems.length === 1) {
        return { ...acc, [item.field]: item.selectedItems[0].value };
      }
      return { ...acc, [item.field]: null };
    }
    return { ...acc, [item.field]: item.selectedItems.length ? item.selectedItems.map((el) => el.value) : null };
  }, {});
};
