import { PatchItemBaseDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseFacade } from '../../../facades';

export type LocationUpdateItemBaseParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchItemBaseDto;
};

export const useLocationUpdateItemBase = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, LocationUpdateItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, LocationUpdateItemBaseParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseFacade.patch(merchantId, storeId, uniqueName, data), options);
};
