import { FilterSettingsItem, FilterSettingsType } from './interface';
import {
  ITEM_SETS_FILTER_KEY,
  CATEGORIES_FILTER_KEY,
  IS_ARCHIVED_FILTER_KEY,
  IS_AVAILABLE_FILTER_KEY,
  IS_ORDERABLE_FILTER_KEY,
  IS_REPLICA_FILTER_KEY,
  MM_FILTERS_CACHE_KEY,
  IS_ENABLED_FILTER_KEY,
  LOCATIONS_FILTER_KEY,
} from './constants';

const getCachedFilterKeys = (): FilterSettingsType[] | null => {
  const sessionStorageString = sessionStorage.getItem(MM_FILTERS_CACHE_KEY);
  let cached: FilterSettingsType[] | null = null;

  if (sessionStorageString) {
    cached = JSON.parse(sessionStorageString);
  }

  return cached;
};

export const cacheFilters = (filters: FilterSettingsType[]): void => {
  const cached = getCachedFilterKeys();

  if (Array.isArray(cached)) {
    const modifiedItems = cached.map((cachedItem) => {
      const findItem = filters.find((filterItem) => filterItem.field === cachedItem.field);

      if (findItem) {
        return findItem;
      }

      return cachedItem;
    });
    const newItemsToAdd = filters.filter((filterItem) => !cached?.some((cachedItem) => filterItem.field === cachedItem.field));
    const isNewItem = !!newItemsToAdd.length;
    const allFilteredItems = isNewItem ? [...modifiedItems, ...newItemsToAdd] : modifiedItems;
    sessionStorage.setItem(MM_FILTERS_CACHE_KEY, JSON.stringify(allFilteredItems));
  } else {
    sessionStorage.setItem(MM_FILTERS_CACHE_KEY, JSON.stringify(filters));
  }
};

export const getCachedFilterSelectedItems = (field: string): FilterSettingsItem[] => {
  const cached = getCachedFilterKeys();

  if (Array.isArray(cached)) {
    const foundFilter = cached.find((filter) => filter.field === field);
    return foundFilter ? foundFilter.selectedItems : [];
  }
  return [];
};

export const getCachedFilters = (filters: FilterSettingsType[]) => {
  const cached = getCachedFilterKeys();
  const isCashedArray = Array.isArray(cached);

  return filters.map((filter) => {
    switch (filter.field) {
      case ITEM_SETS_FILTER_KEY:
        return { ...filter, selectedItems: getCachedFilterSelectedItems(ITEM_SETS_FILTER_KEY) };
      case CATEGORIES_FILTER_KEY:
        return { ...filter, selectedItems: getCachedFilterSelectedItems(CATEGORIES_FILTER_KEY) };
      case LOCATIONS_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(LOCATIONS_FILTER_KEY) };
      case IS_ARCHIVED_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(IS_ARCHIVED_FILTER_KEY) };
      case IS_AVAILABLE_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(IS_AVAILABLE_FILTER_KEY) };
      case IS_ORDERABLE_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(IS_ORDERABLE_FILTER_KEY) };
      case IS_REPLICA_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(IS_REPLICA_FILTER_KEY) };
      case IS_ENABLED_FILTER_KEY:
        return { ...filter, selectedItems: !isCashedArray ? filter.selectedItems : getCachedFilterSelectedItems(IS_ENABLED_FILTER_KEY) };
      default:
        return filter;
    }
  });
};
