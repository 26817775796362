import type { ReadMerchantDto } from '@goparrot/store-v2-sdk';
import type { ReadUserDto } from '@goparrot/users-v2-sdk';
import { useGetUser } from '@goparrot-dashboard-api/users';
import { batch, useDispatch } from 'react-redux';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import aLevel from '../../../../modules/client/src/store/level/actions';
import aMerchant from '../../../../modules/client/src/store/merchant/actions';
import aUser from '../../../../modules/client/src/store/user/actions';
import a from '../../../../modules/client/src/store/auth/actions';

type UseAfterCreateMerchantResult = {
  (merchant: ReadMerchantDto, user: ReadUserDto): void;
};

export const useAfterMerchantCreate = (user: ReadUserDto): UseAfterCreateMerchantResult => {
  const dispatch = useDispatch();
  const { notifyError } = useNotifications();

  const updatedUser = useGetUser(user.uuid, {
    onSuccess: (data) => {
      dispatch(a.successLogin(data));
    },
    onError: () => {
      notifyError({
        text: getIntlString('notification.getUser.error.description'),
      });
    },
    retry: 2,
    enabled: false,
  });

  const onAfterCreate = (merchantData: ReadMerchantDto) => {
    batch(() => {
      dispatch(aMerchant.successCreate(merchantData));
      dispatch(aUser.setStoreId(merchantData.storeId));
      dispatch(aLevel.select(merchantData.storeId));

      updatedUser.refetch();
    });
  };

  return onAfterCreate;
};
