import { getIntlString } from '@goparrot-dashboard/i18n';

export const DEFAULT_FILTERS = [
  {
    field: 'isEnabled',
    label: 'Enabled',
    selectedItems: [],
    items: [
      { value: true, label: getIntlString('page.merchant.menu.filters.enabled.only') },
      { value: false, label: getIntlString('page.merchant.menu.filters.enabled.onlyNot') },
    ],
  },
];

export const MIN_FILTERS_FOR_SEARCH = 5;

export const ITEM_SETS_FILTER_KEY = 'itemSets';
export const LOCATIONS_FILTER_KEY = 'storeIds';
export const CATEGORIES_FILTER_KEY = 'categories';
export const IS_ARCHIVED_FILTER_KEY = 'isArchived';
export const IS_AVAILABLE_FILTER_KEY = 'isAvailable';
export const IS_ORDERABLE_FILTER_KEY = 'isOrderable';
export const IS_REPLICA_FILTER_KEY = 'isReplica';
export const IS_ENABLED_FILTER_KEY = 'isEnabled';
export const MM_FILTERS_CACHE_KEY = 'mm-filters';
export const UNCATEGORIZED = 'uncategorized';

export const DEFAULT_UNCATEGORIZED_FILTER = { value: UNCATEGORIZED, label: getIntlString('menu.list.filters.uncategorized') };
