import { NumberFormatValues } from 'react-number-format';

import { getValueTransformers } from '../../utils/valueTransformers';
import { BaseInputBindingsOptions, EntityFormResult, InputBindingsMethod } from '../../types';

export type UseNumberFormatPropsResult = {
  value: any;
  disabled: boolean;
  onValueChange(values: NumberFormatValues): void;
  onBlur(): void;
};
export type UseNumberFormatPropsOptions = BaseInputBindingsOptions;

export function useNumberFormatProps<T>(
  hook: EntityFormResult<T>,
  options?: UseNumberFormatPropsOptions,
): InputBindingsMethod<T, BaseInputBindingsOptions, UseNumberFormatPropsResult> {
  const useNumberFormatPropsForPath: InputBindingsMethod<T, BaseInputBindingsOptions, UseNumberFormatPropsResult> = (path, inputOptions) => {
    const valueTransformers = getValueTransformers<T>(hook, inputOptions ?? options);
    return {
      value: valueTransformers.toInput(path),
      hasError: hook.isDirtyField(path) && hook.hasError(path),
      onValueChange: (values: NumberFormatValues) => valueTransformers.toState(path, values.floatValue ?? values.value),
      onBlur: () => hook.onFieldBlur(path),
      disabled: hook.isDisabledField(path),
    };
  };

  return useNumberFormatPropsForPath;
}
