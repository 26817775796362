import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ListVendorDto, IListVendorRequest } from '@goparrot/vendor-sdk';

import { VENDOR_QUERIES_ENUM } from '../enums';
import { vendorFacade } from '../facades';

export const useGetVendorList = (params: IListVendorRequest, options?: UseQueryOptions<ListVendorDto, unknown>): UseQueryResult<ListVendorDto> => {
  params.limit ?? (params.limit = 500);
  return useQuery([VENDOR_QUERIES_ENUM.VENDOR_LIST, params], () => vendorFacade.list(params), options);
};
