import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
export const ReactQueryContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  // @ts-ignore-next-line
  <QueryClientProvider client={client}>{children}</QueryClientProvider>
);
