import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemSetFacade } from '../../facades';

type ItemSetGetParams = { merchantId: string; uniqueName: string };

export const useMerchantItemSetGet = (
  { merchantId, uniqueName }: ItemSetGetParams,
  options?: UseQueryOptions<ReadItemSetDto>,
): UseQueryResult<ReadItemSetDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_SET_GET, merchantId, uniqueName],
    async () => await merchantItemSetFacade.get(merchantId, uniqueName),
    options,
  );
};
