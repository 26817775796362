import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemOptionsFacade } from '../../facades';

type LocationItemOptionChildrenParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationItemOptionChildren = (
  { merchantId, storeId, uniqueName }: LocationItemOptionChildrenParams,
  options?: UseQueryOptions<ReadItemBaseDto[]>,
): UseQueryResult<ReadItemBaseDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_OPTION_CHILDREN_LIST, merchantId, storeId, uniqueName],
    async () => await locationItemOptionsFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
