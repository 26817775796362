import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import type { SettlementConfigurationResponse } from '@goparrot/giftcard-orchestrator-sdk';
import { ORCHESTRATION_QUERIES_ENUM } from '../enums';
import { giftCardSettlementConfigurationFacade } from '../facades';

export const useGetGiftSettlementConfig = (
  merchantId: string,
  options?: UseQueryOptions<SettlementConfigurationResponse>,
): UseQueryResult<SettlementConfigurationResponse> => {
  return useQuery([ORCHESTRATION_QUERIES_ENUM.GIFT_CARD_SETTLEMENT_CONFIG, merchantId], () => giftCardSettlementConfigurationFacade.get(merchantId), options);
};
