import React from 'react';
import { List } from 'react-virtualized';

type ReturnType = {
  listRef: React.MutableRefObject<List>;
  updateScrollToValue: (scrollTop: number) => void;
  scrollToListPosition: (position?: number) => void;
  lastScrollTopRef: React.MutableRefObject<number | null>;
};

export const useScrollToEditItem = (): ReturnType => {
  const listRef = React.useRef<null | List>(null);
  const lastScrollTopRef = React.useRef<null | number>(null);

  const updateScrollToValue = (scrollTop: number) => {
    lastScrollTopRef.current = scrollTop;
  };

  const scrollToListPosition = (position?: number) => {
    if (position || 0 === position) {
      listRef.current?.scrollToPosition(position);
      return;
    }
    listRef.current?.scrollToPosition(lastScrollTopRef.current - 1);
  };

  return { listRef, updateScrollToValue, scrollToListPosition, lastScrollTopRef };
};
