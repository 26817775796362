import { JsonPatchOperationDto, PatchOperationEnum } from '@goparrot/common';
import { ReadWebMerchantDto } from '@goparrot/webstore-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { webMerchantFacade } from '../facades';

type UpdateWebMerchantSlugParams = {
  merchantId: string;
  slug: string;
};

export const useUpdateWebMerchantSlug = (
  options?: UseMutationOptions<ReadWebMerchantDto, unknown, UpdateWebMerchantSlugParams, unknown>,
): UseMutationResult<ReadWebMerchantDto, unknown, UpdateWebMerchantSlugParams, unknown> => {
  return useMutation(({ merchantId, slug }) => {
    const operation: JsonPatchOperationDto[] = [
      {
        op: PatchOperationEnum.REPLACE,
        path: '/slug',
        value: slug,
      },
    ];
    return webMerchantFacade.patch(merchantId, operation);
  }, options);
};
