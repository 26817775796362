import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
  newOrders: {
    list: [],
    total: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDERS__GET__REQUEST:
      return assign(state, { fetching: true });
    case a.API__ORDERS__GET__SUCCESS:
      return assign(initialState, { data: action.payload, newOrders: state.newOrders });

    case a.API__ORDER__UPDATE__SUCCESS:
      return state;

    case a.API__NEW_ORDERS__GET__SUCCESS: {
      const { orders, total } = action.payload;
      const newOrders = {
        list: orders,
        total,
      };
      return assign(state, { newOrders });
    }

    case a.API__ORDERS__POLL__SUCCESS:
      return assign(initialState, { data: action.payload, newOrders: state.newOrders });

    default:
      return state;
  }
}
