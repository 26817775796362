export enum MENU_MANAGEMENT_SHARED_QUERIES_ENUM {
  LOCATION_CATEGORY_LIST = 'LOCATION_CATEGORY_LIST',
  LOCATION_CATEGORY_LIST_INFINITE = 'LOCATION_CATEGORY_LIST_INFINITE',
  LOCATION_COMBOS_LIST_INFINITE = 'LOCATION_COMBOS_LIST_INFINITE',
  LOCATION_ITEM_BASE_GROUP_LIST = 'LOCATION_ITEM_BASE_GROUP_LIST',
  LOCATION_STORE_COMBINED_ITEMS_LIST = 'LOCATION_STORE_COMBINED_ITEMS_LIST',
  LOCATION_STORE_ITEM_LIST = 'LOCATION_STORE_ITEM_LIST',
  LOCATION_STORE_ITEM_LIST_INFINITE = 'LOCATION_STORE_ITEM_LIST_INFINITE',

  MERCHANT_CATEGORY_LIST = 'MERCHANT_CATEGORY_LIST',
  MERCHANT_CATEGORY_LIST_INFINITE = 'MERCHANT_CATEGORY_LIST_INFINITE',
  MERCHANT_COMBOS_LIST_INFINITE = 'MERCHANT_COMBOS_LIST_INFINITE',
  MERCHANT_ITEM_BASE_GROUP_LIST = 'MERCHANT_ITEM_BASE_GROUP_LIST',
  MERCHANT_STORE_COMBINED_ITEMS_LIST = 'MERCHANT_STORE_COMBINED_ITEMS_LIST',
  MERCHANT_STORE_ITEM_LIST = 'MERCHANT_STORE_ITEM_LIST',
  MERCHANT_STORE_ITEM_LIST_INFINITE = 'MERCHANT_STORE_ITEM_LIST_INFINITE',
}
