import { useGetUserRolesV2 } from '@goparrot-dashboard-api/role-v2';
import type { ReadUserDto } from '@goparrot/users-v2-sdk';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import type { RootState } from '@goparrot-dashboard/shared-utils';
import type { UserServiceContextType } from '../contexts/UserServiceContext';
import { UserServiceContext } from '../contexts/UserServiceContext';

export interface ReduxProps {
  user: ReadUserDto;
}

const getReduxData = createSelector<RootState, ReadUserDto, ReduxProps>(
  ({ root }) => root.user.info.data,
  (user) => ({
    user,
  }),
);

export const UserServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useSelector(getReduxData);

  const getUserRolesV2 = useGetUserRolesV2(user?.merchantId ?? '', user?.roleV2Uuid ?? '', {
    enabled: !isEmpty(user?.roleV2Uuid) && !isEmpty(user?.merchantId),
  });

  const contextValue: UserServiceContextType = {
    user,
    roleV2: getUserRolesV2?.data,
    roleV2QueryResult: getUserRolesV2,
  };

  return <UserServiceContext.Provider value={contextValue}>{children}</UserServiceContext.Provider>;
};
