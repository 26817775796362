import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import { palette } from 'styles/constants';
import { transition } from 'styles/util';

export const SelectWrapper: any = styled(Select)`
  &.ant-select {
    width: 100%;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    color: ${palette.text[1]};
    font-size: 13px;

    .ant-select-selection {
      border-radius: 4px;
      border: 1px solid ${palette.border[0]};
      ${transition()};

      &.ant-select-selection--single {
        height: 35px;
        position: relative;
        cursor: pointer;
      }

      .ant-select-selection__rendered {
        margin-left: 10px;

        .ant-select-selection-selected-value {
          float: left;
          padding: 0 14px 0 0;
        }
      }

      .ant-select-arrow {
        right: 5px;
        left: inherit;
      }

      &:hover {
        border-color: ${palette.primary[0]};
      }
    }

    &.ant-select-focused {
      .ant-select-selection {
        &:focus,
        &:active {
          border-color: ${palette.primary[0]};
          outline: 0;
          box-shadow: 0 0 0 2px ${palette.primary[3]};
        }
      }
    }

    &.ant-select-open {
      .ant-select-selection {
        border-color: ${palette.primary[0]};
        outline: 0;
        box-shadow: 0 0 0 2px ${palette.primary[3]};
      }
    }

    .ant-select-selection--multiple > ul > li,
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      margin-top: 4px;
      height: 26px;
      line-height: 26px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
      background-color: ${palette.grayscale[4]};
      color: ${palette.text[1]};
    }

    .ant-select-tree li a {
      font-size: 13px;
      color: ${palette.text[1]};
    }
  }
`;

export const SelectOptionWrapper: any = styled(Select.Option)`
  color: #000000;
  .ant-select-dropdown-menu-item {
    color: ${palette.text[1]};
  }
`;

const AntSelect = (props) => <SelectWrapper {...props} getPopupContainer={(trigger) => trigger.parentNode} />;
AntSelect.Option = SelectOptionWrapper;

export default AntSelect;
