export const removeNullOrUndefinedObjectProperties = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((acc: any, current: string) => {
    if (![null, undefined].includes(obj[current])) {
      acc[current] = obj[current];
    }
    return acc;
  }, {});
};

export const removeNullObjectProperties = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((acc: any, current: string) => {
    if (![null].includes(obj[current])) {
      acc[current] = obj[current];
    }
    return acc;
  }, {});
};
