import { CreateStoreItemDto, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type LocationCreateStoreItemParams = {
  merchantId: string;
  storeId: string;
  data: CreateStoreItemDto;
};

export const useLocationCreateStoreItem = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, LocationCreateStoreItemParams>,
): UseMutationResult<ReadStoreItemDto, unknown, LocationCreateStoreItemParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationStoreItemFacade.create(merchantId, storeId, data), options);
};
