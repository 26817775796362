import { CreateStoreItemDto, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantCreateStoreItemParams = {
  merchantId: string;
  data: CreateStoreItemDto;
};

export const useMerchantCreateStoreItem = (
  options: UseMutationOptions<ReadStoreItemDto, unknown, MerchantCreateStoreItemParams>,
): UseMutationResult<ReadStoreItemDto, unknown, MerchantCreateStoreItemParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantStoreItemFacade.create(merchantId, data), options);
};
