import type { UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

export const useUpdateMerchantComboGroupItemSets = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, UpdateConnectionMutationBaseParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, UpdateConnectionMutationBaseParams> => {
  return useMutation(({ merchantId, uniqueName, payload }) => merchantStoreItemBundleFacade.updateItemSets(merchantId, uniqueName, payload), options);
};
