import React, { FC } from 'react';
import classNames from 'classnames';
import { NavBarProps } from '../types';

export const NavBarWrapper: FC<React.PropsWithChildren<Pick<NavBarProps, 'className'>>> = ({ children, className }) => (
  <div
    className={classNames([
      className,
      'tw-fixed tw-top-0 tw-right-0 tw-left-0 tw-w-full tw-pl-5 tw-pr-5 xl:tw-pr-6 tw-py-2.5 tw-z-52',
      'tw-flex tw-items-center tw-border-b tw-border-blue-gray-900 tw-border-opacity-10 tw-bg-white',
    ])}
  >
    {children}
  </div>
);
