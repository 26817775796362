import type { IUpdateRelationsResponse, IUpdateRelationsRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemSetFacade } from '../../../facades';

type LocationUpdateItemSetCategoriesParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest;
};

export const useLocationUpdateItemSetCategories = (
  options?: UseMutationOptions<IUpdateRelationsResponse, unknown, LocationUpdateItemSetCategoriesParams>,
): UseMutationResult<IUpdateRelationsResponse, unknown, LocationUpdateItemSetCategoriesParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemSetFacade.updateCategories(merchantId, storeId, uniqueName, data),
    options,
  );
};
