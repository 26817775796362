import React from 'react';

export const LoyaltyModule = React.lazy(() => import('@goparrot-dashboard/loyalty').then((module) => ({ default: module.LoyaltyModule })));
export const NotificationsModule = React.lazy(() => import('@goparrot-dashboard/notifications').then((module) => ({ default: module.NotificationsModule })));
export const BusinessHours = React.lazy(() => import('@goparrot-dashboard/business-hours').then((module) => ({ default: module.BusinessHoursModule })));
export const DiningOptions = React.lazy(() => import('@goparrot-dashboard/dining-options').then((module) => ({ default: module.DiningOptionsModule })));
export const Pickup = React.lazy(() => import('@goparrot-dashboard/pickup').then((module) => ({ default: module.PickupModule })));
export const CustomersModule = React.lazy(() => import('@goparrot-dashboard/customers').then((module) => ({ default: module.CustomersModule })));
export const ReportsModule = React.lazy(() => import('@goparrot-dashboard/reports').then((module) => ({ default: module.ReportsModule })));
export const CampaignModule = React.lazy(() => import('@goparrot-dashboard/campaign').then((module) => ({ default: module.CampaignModule })));
export const FranchiseMarketingModule = React.lazy(() =>
  import('@goparrot-dashboard/franchise-marketing').then((module) => ({ default: module.FranchiseMarketingModule })),
);
export const ServiceChargesModule = React.lazy(() =>
  import('@goparrot-dashboard/service-charges').then((module) => ({ default: module.ServiceChargesModule })),
);
export const CohortModule = React.lazy(() => import('@goparrot-dashboard/cohort').then((module) => ({ default: module.CohortModule })));
export const PromotionModule = React.lazy(() => import('@goparrot-dashboard/promotion').then((module) => ({ default: module.PromotionModule })));
export const DeliveryModule = React.lazy(() => import('@goparrot-dashboard/delivery').then((module) => ({ default: module.DeliveryModule })));
export const LocationsModule = React.lazy(() => import('@goparrot-dashboard/locations').then((module) => ({ default: module.LocationsModule })));
export const LocationGroupModule = React.lazy(() => import('@goparrot-dashboard/location-group').then((module) => ({ default: module.LocationGroupModule })));
export const PaymentModule = React.lazy(() => import('@goparrot-dashboard/payment').then((module) => ({ default: module.PaymentModule })));
export const FranchiseModule = React.lazy(() => import('@goparrot-dashboard/franchise-management').then((module) => ({ default: module.RootPage })));
export const FranchiseMenuSettingsModule = React.lazy(() =>
  import('@goparrot-dashboard/franchise-menu-settings').then((module) => ({ default: module.FranchiseMenuSettingsModule })),
);
export const MultiBrandModule = React.lazy(() => import('@goparrot-dashboard/multi-brand-management').then((module) => ({ default: module.RootPage })));
export const GiftCardModule = React.lazy(() => import('@goparrot-dashboard/gift-cards').then((module) => ({ default: module.GiftCardModule })));
export const UsersModule = React.lazy(() => import('@goparrot-dashboard/users').then((module) => ({ default: module.UsersModule })));
export const OrderConfigModule = React.lazy(() => import('@goparrot-dashboard/order-config').then((module) => ({ default: module.OrderConfigModule })));
export const MerchantModule = React.lazy(() => import('@goparrot-dashboard/merchant').then((module) => ({ default: module.MerchantModule })));
export const MerchantWizardModule = React.lazy(() =>
  import('@goparrot-dashboard/merchant-wizard').then((module) => ({ default: module.MerchantWizardModule })),
);
export const ItemSetConceptsModule = React.lazy(() =>
  import('@goparrot-dashboard/item-set-concepts').then((module) => ({ default: module.ItemSetConceptsModule })),
);
export const ItemSetModule = React.lazy(() => import('@goparrot-dashboard/item-set').then((module) => ({ default: module.ItemSetModule })));
export const ItemBaseModule = React.lazy(() => import('@goparrot-dashboard/item-base').then((module) => ({ default: module.ItemBaseModule })));
export const ItemBaseGroupModule = React.lazy(() => import('@goparrot-dashboard/item-base-group').then((module) => ({ default: module.ItemBaseGroupModule })));
export const ItemOptionsModule = React.lazy(() => import('@goparrot-dashboard/item-options').then((module) => ({ default: module.ItemOptionsModule })));
export const CategoryModule = React.lazy(() => import('@goparrot-dashboard/category').then((module) => ({ default: module.CategoryModule })));
export const StoreItemModule = React.lazy(() => import('@goparrot-dashboard/store-item').then((module) => ({ default: module.StoreItemModule })));
export const MerchantFlagsModule = React.lazy(() => import('@goparrot-dashboard/merchant-flags').then((module) => ({ default: module.MerchantFlagsModule })));
export const AuthModule = React.lazy(() => import('@goparrot-dashboard/auth').then((module) => ({ default: module.AuthModule })));
export const OnboardingFranchiseModule = React.lazy(() =>
  import('@goparrot-dashboard/onboarding-franchise').then((module) => ({ default: module.OnboardingFranchiseModule })),
);
export const VendorModule = React.lazy(() => import('@goparrot-dashboard/vendor').then((module) => ({ default: module.VendorRoot })));
export const HomePageModule = React.lazy(() => import('@goparrot-dashboard/homepages').then((module) => ({ default: module.HomePageModule })));
export const ComboGroupModule = React.lazy(() => import('@goparrot-dashboard/combo-group').then((module) => ({ default: module.ComboGroupModule })));
export const CombosModule = React.lazy(() => import('@goparrot-dashboard/combos').then((module) => ({ default: module.CombosModule })));
export const TaxesModule = React.lazy(() => import('@goparrot-dashboard/taxes').then((module) => ({ default: module.TaxesModule })));
export const DomainModule = React.lazy(() => import('@goparrot-dashboard/domain').then((module) => ({ default: module.DomainModule })));
export const StorePrintableTagModule = React.lazy(() =>
  import('@goparrot-dashboard/store-printable-tag').then((module) => ({ default: module.StorePrintableTagModule })),
);
export const OrdersModule = React.lazy(() => import('@goparrot-dashboard/orders').then((module) => ({ default: module.OrdersModule })));
export const OrdersThrottlingModule = React.lazy(() =>
  import('@goparrot-dashboard/orders-throttling').then((module) => ({ default: module.OrdersThrottlingModule })),
);

export const MenuManagementRootPage = React.lazy(() => import('../components/pages/merchant/menu-management/menu-management'));

export const FranchiseDirectoryModule = React.lazy(() =>
  import('@goparrot-dashboard/franchise-directory').then((module) => ({ default: module.FranchiseDirectoryModule })),
);
