import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import type { ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemComboFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  uniqueName: string;
};

export const useMerchantGetComboQuery = (
  { merchantId, uniqueName }: GetStoreItemsParams,
  options?: UseQueryOptions<ReadStoreItemComboDto, Error>,
): UseQueryResult<ReadStoreItemComboDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_COMBOS_GET, merchantId, uniqueName],
    () => merchantStoreItemComboFacade.get(merchantId, uniqueName),
    options,
  );
};
