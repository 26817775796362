export const getReactNodeTextContent = (elem: React.ReactElement | string): string => {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }

  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getReactNodeTextContent).join('');
  }
  return getReactNodeTextContent(children);
};
