import { ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantSelectionTypesFacade } from '../../../facades';

export type MerchantDeleteSelectionTypeParams = {
  merchantId: string;
};

export const useMerchantDeleteSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, MerchantDeleteSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, MerchantDeleteSelectionTypeParams> => {
  return useMutation(async ({ merchantId }) => await merchantSelectionTypesFacade.delete(merchantId), options);
};
