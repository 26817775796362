import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ChildrenEntitiesDtoType } from '@goparrot/storeitems-sdk';
import { locationCategoryFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { LocationGetRelativesQueryParams } from '../../interface';

export const useLocationGetCategoryChildrenQuery = (
  { merchantId, storeId, uniqueName, params }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ChildrenEntitiesDtoType[]>,
): UseQueryResult<ChildrenEntitiesDtoType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_CATEGORY_GET_CHILDREN, merchantId, storeId, uniqueName, params],
    async () => await locationCategoryFacade.getChildren(merchantId, storeId, uniqueName, params),
    options,
  );
};
