import { IUpdateRelationsResponse, IItemOptionsMetadataSelections, IRewriteRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';
export type MerchantUpdateItemOptionSelectionsResult = UseMutationResult<IUpdateRelationsResponse, unknown, merchantUpdateItemOptionSelectionsParams, unknown>;

export type merchantUpdateItemOptionSelectionsParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IItemOptionsMetadataSelections>;
};

export const useMerchantUpdateItemOptionSelections = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, merchantUpdateItemOptionSelectionsParams, unknown>,
): MerchantUpdateItemOptionSelectionsResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.updateSelections(merchantId, uniqueName, data), options);
};
