import { CreateSelectionTypeDto, ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantSelectionTypesFacade } from '../../../facades';

export type MerchantCreateSelectionTypeParams = {
  merchantId: string;
  data: CreateSelectionTypeDto;
};

export const useMerchantCreateSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, MerchantCreateSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, MerchantCreateSelectionTypeParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantSelectionTypesFacade.create(merchantId, data), options);
};
