import { StoreItemReplicaFacade, ItemBaseReplicaFacade } from '@goparrot/dashboard-gateway-sdk';
import {
  LocationCategoryFacade,
  LocationItemFacade,
  LocationStoreItemFacade,
  LocationStoreItemGroupFacade,
  MerchantCategoryFacade,
  MerchantItemFacade,
  MerchantStoreItemFacade,
  MerchantStoreItemGroupFacade,
  MerchantItemBaseGroupFacade,
  LocationItemBaseGroupFacade,
  MerchantStoreItemComboFacade,
  LocationStoreItemComboFacade,
} from '@goparrot/storeitems-sdk';
import { getConfig, getInterceptors } from '../../request';

export const STORE_ITEMS_BASE_PATH = 'storeItems';

export const locationStoreItemFacade = new LocationStoreItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const merchantStoreItemFacade = new MerchantStoreItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const locationStoreItemReplicaFacade = new StoreItemReplicaFacade(getConfig(), getInterceptors());
export const locationStoreItemGroupFacade = new LocationStoreItemGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const merchantStoreItemGroupFacade = new MerchantStoreItemGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const locationCategoryFacade = new LocationCategoryFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const merchantCategoryFacade = new MerchantCategoryFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantStoreCombinedItemsFacade = new MerchantItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationStoreCombinedItemsFacade = new LocationItemFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const locationItemBaseReplicaFacade = new ItemBaseReplicaFacade(getConfig(), getInterceptors());
export const merchantItemBaseGroupFacade = new MerchantItemBaseGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationItemBaseGroupFacade = new LocationItemBaseGroupFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());

export const merchantStoreItemComboFacade = new MerchantStoreItemComboFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
export const locationStoreItemComboFacade = new LocationStoreItemComboFacade(getConfig(STORE_ITEMS_BASE_PATH), getInterceptors());
