import type { IUpdateRelationsResponse, IStoreItemBundleProperty } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemBundleFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

type UpdateLocationComboGroupPropertiesParams = UpdateConnectionMutationBaseParams<IStoreItemBundleProperty> & {
  storeId: string;
};

export const useUpdateLocationComboGroupProperties = (
  options?: UseMutationOptions<IUpdateRelationsResponse, unknown, UpdateLocationComboGroupPropertiesParams>,
): UseMutationResult<IUpdateRelationsResponse, unknown, UpdateLocationComboGroupPropertiesParams> => {
  return useMutation(
    ({ merchantId, uniqueName, storeId, payload }) => locationStoreItemBundleFacade.updateProperties(merchantId, storeId, uniqueName, payload),
    options,
  );
};
