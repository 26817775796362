import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useState } from 'react';
import { locationFacade } from '../api';

type Props = {
  onSuccess: (storeId: string) => void;
};

export function useCreateCentralLocation({
  onSuccess = () => null,
}: Props): {
  createCentralLocation: (merchantId: string, storeId: string) => void;
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState(false);
  const { notifyError, notifySuccess } = useNotifications();
  const createCentralLocation = async (merchantId: string, storeId: string) => {
    setIsLoading(true);
    try {
      await locationFacade.create(merchantId, {
        storeId,
        isCentralLocation: true,
      });

      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.central.create.success.message'),
      });

      onSuccess(storeId);
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.central.create.error.message'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { createCentralLocation, isLoading };
}
