import React, { FC } from 'react';

export type PanelWhiteProps = {
  style?: React.CSSProperties;
  className?: string;
};

export const PanelWhite: FC<React.PropsWithChildren<PanelWhiteProps>> = ({ style, children, className = '' }) => (
  <div className={`tw-py-4.5 tw-bg-white tw-rounded-md tw-shadow-sm ${className}`} style={style}>
    {children}
  </div>
);

export const PanelWhiteSeparator: FC<React.PropsWithChildren<unknown>> = () => <div className="tw--mx-8 tw-my-3 tw-opacity-10 tw-h-px tw-bg-black" />;
