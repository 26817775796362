import React, { FC } from 'react';
import { PanelTitle, IconPlusOutlined } from '@goparrot-dashboard/shared-ui';
import { MarketButton } from '@market/react-bindings';
import { useI18n } from '@goparrot-dashboard/i18n';
import { Search } from '@goparrot-dashboard/shared-utils';

type LocationsActionsProps = {
  onAddClick: () => void;
  onClearAllClick?: () => void;
  locationsNumber?: number;
  onSearchChange?: (text: string) => void;
  searchValue?: string;
};

export const LocationsActions: FC<React.PropsWithChildren<LocationsActionsProps>> = (props) => {
  const { getIntlString } = useI18n();

  const totalCountText = props.locationsNumber ? `${props.locationsNumber} location${props.locationsNumber > 1 ? 's' : ''}` : '';

  return (
    <div>
      <PanelTitle hasMargin={false}>
        <span>{getIntlString('locations.title')}</span>
        <span className="tw-text-blue-gray-400 tw-font-normal"> {totalCountText}</span>
      </PanelTitle>
      <div className="tw-flex tw-align-center tw-justify-between tw-mt-4">
        {props.onSearchChange && (
          <div className="tw-w-64">
            <Search value={props.searchValue} onSearch={props.onSearchChange} placeholder={getIntlString('locations.search.placeholder')} />
          </div>
        )}
        <div className="tw-flex tw-items-center tw-justify-between">
          <MarketButton onClick={props.onAddClick}>
            <IconPlusOutlined className="tw-w-4 tw-h-4 tw-mr-0.5 tw--mt-0.5" />
            {getIntlString('page.merchant.orchestration.locations.addLabel', { isRootPath: true })}
          </MarketButton>
        </div>
      </div>
    </div>
  );
};
