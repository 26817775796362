import { useCallback } from 'react';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { locationFacade } from '../api/location';

export function useClearAllLocations(onSuccess: () => void = () => null): [(merchantId: string) => void] {
  const { notifyError, notifySuccess } = useNotifications();

  const clearAll = useCallback(
    async (merchantId: string) => {
      try {
        await locationFacade.deleteByMerchant(merchantId);
        notifySuccess({
          text: getIntlString('page.merchant.franchise.notification.location.clearAll.success.message'),
        });
        onSuccess();
      } catch (e) {
        notifyError({
          text: getIntlString('page.merchant.franchise.notification.location.clearAll.error.message'),
        });
      }
    },
    [onSuccess],
  );
  return [clearAll];
}
