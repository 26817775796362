import { IUpdateRelationsRequest, IUpdateRelationsResponse } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type useLocationUpdateStoreItemPropertiesParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};

export const useLocationUpdateStoreItemProperties = (
  options: UseMutationOptions<IUpdateRelationsResponse, unknown, useLocationUpdateStoreItemPropertiesParams, unknown>,
): UseMutationResult<IUpdateRelationsResponse, unknown, useLocationUpdateStoreItemPropertiesParams, unknown> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemOptionsFacade.updateStoreItemProperties(merchantId, storeId, uniqueName, data),
    options,
  );
};
