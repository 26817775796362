import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemOptionsFacade } from '../../facades';

type MerchantItemOptionChildrenParams = { merchantId: string; uniqueName: string };

export const useMerchantItemOptionChildren = (
  { merchantId, uniqueName }: MerchantItemOptionChildrenParams,
  options?: UseQueryOptions<ReadItemBaseDto[]>,
): UseQueryResult<ReadItemBaseDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_OPTION_CHILDREN_LIST, merchantId, uniqueName],
    async () => await merchantItemOptionsFacade.getChildren(merchantId, uniqueName),
    options,
  );
};
