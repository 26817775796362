import type { ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemComboFacade } from '../../../facades';
import { ComboUpdateMutationParams } from '../../../interface';

export type LocationComboUpdateParams = ComboUpdateMutationParams & {
  storeId: string;
};

export const useUpdateLocationCombo = (
  options?: UseMutationOptions<ReadStoreItemComboDto, unknown, LocationComboUpdateParams>,
): UseMutationResult<ReadStoreItemComboDto, unknown, LocationComboUpdateParams> => {
  return useMutation(({ merchantId, uniqueName, storeId, data }) => locationStoreItemComboFacade.patch(merchantId, storeId, uniqueName, data), options);
};
