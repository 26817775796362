import type { PutWebRedirectDto, ReadWebRedirectDto } from '@goparrot/webstore-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { webstoreRedirectFacade } from '../facades';

type RedirectParams = {
  data: PutWebRedirectDto;
  merchantId: string;
};

export const useUpdateOrCreateWebstoreRedirect = (
  options?: UseMutationOptions<ReadWebRedirectDto, unknown, RedirectParams, unknown>,
): UseMutationResult<ReadWebRedirectDto, unknown, RedirectParams, unknown> => {
  return useMutation(({ merchantId, data }) => webstoreRedirectFacade.updateOrCreate(merchantId, data), options);
};
