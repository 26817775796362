import React, { useMemo } from 'react';
import { useCheckboxProps, UseCheckboxPropsResult, useInputProps, UseInputPropsResult } from '@goparrot-dashboard/entity-form';
import { Button, IconQuestion, Toggle, Tooltip } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { availableAtDefaultDateAndTime } from '@goparrot-dashboard/shared-utils';
import { MultiEntityTypeEnum } from '@goparrot/common';
import { findMod, getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { Item } from '../../interface';
import { useMenuFormContext } from '../../context';
import { IsAvailableAt } from '../IsAvailableAt';

export const ItemAvailabilityWithAvailableAt = <T extends { isAvailable: boolean }>({
  uncheckedLabel,
  checkedLabel,
  title,
  tooltipTitle,
  isModsMode = false,
  isAvailableModProps,
  isAvailableAtModProps,
  forcedDisable,
}: {
  uncheckedLabel?: string;
  checkedLabel?: string;
  title?: string;
  tooltipTitle?: string;
  isModsMode?: boolean;
  isAvailableModProps?: UseCheckboxPropsResult;
  isAvailableAtModProps?: UseInputPropsResult;
  forcedDisable?: boolean;
}): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations, merchant, selectedStore, selectedStoreId, timeZone } = useStoreService();
  const isFranchiseEntityType = merchant.featuresFlags.multiEntityType === MultiEntityTypeEnum.FRANCHISE;
  const { roleV2 } = useUserService();
  const bindInputProps = useInputProps(form);
  const bindCheckedProps = useCheckboxProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const modsProps = bindInputProps('mods');
  const isAvailableProps = bindCheckedProps('isAvailable');
  const isAvailableAtProps = bindInputProps('isAvailableAt');
  const isDisabledOnMerchant = isModsMode && !isAvailableProps.checked;
  const toggleProps = isModsMode ? isAvailableModProps : isAvailableProps;
  const availableAtProps = isModsMode ? isAvailableAtModProps : isAvailableAtProps;
  const isAvailableAtEnabled: boolean = isMerchant ? merchant?.featuresFlags?.isAvailableAtEnabled : selectedStore?.featuresFlags?.isAvailableAtEnabled;
  const isAvailableValue = useMemo(() => (isModsMode ? findMod(selectedStoreId, modsProps.value)?.isAvailable : isAvailableProps.checked), [
    isAvailableProps.checked,
    isModsMode,
    modsProps.value,
    selectedStoreId,
  ]);

  const isAvailableAtValue = useMemo(
    () => (isModsMode ? findMod(selectedStoreId, modsProps.value)?.isAvailableAt || isAvailableAtProps.value : isAvailableAtProps.value),
    [isAvailableAtProps.value, isModsMode, modsProps.value, selectedStoreId],
  );

  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditAvailability
    : franchisePermissions?.canEditAvailability;

  const toggleDisabled = isItemReplica ? !hasFranchisePermission : isAvailableProps.disabled;
  const isDisabled = forcedDisable || isFranchiseEntityType || (isModsMode ? isDisabledOnMerchant : toggleDisabled);
  const handleIsAvailableChange = (isAvailable: boolean) => toggleProps.onChange(isAvailable);

  React.useEffect(() => {
    if (isAvailableAtEnabled && !availableAtProps.value) {
      availableAtProps.onChange(toggleProps.checked ? null : availableAtDefaultDateAndTime(timeZone));
    }
    // availableAtProps.onChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailableAtEnabled, timeZone, toggleProps.checked]);

  return (
    <div key={isModsMode ? isAvailableAtModProps.value : isAvailableAtProps.value} className="tw-relative tw-mr-9 last:tw-mr-0">
      {tooltipTitle ? (
        <div className="tw-flex tw-items-center tw-mb-1">
          <h4 className="tw-blue-gray-900 tw-mb-0 tw-font-bold">{title}</h4>
          <Tooltip tooltipProps={{ placement: 'top' }} tooltip={<div className="tw-max-w-xs">{tooltipTitle}</div>}>
            <Button kind="clean" color="clean" className="tw-w-4 tw-h-4 tw-outline-none tw-ring-transparent tw-ml-2">
              <IconQuestion className="tw-h-5" />
            </Button>
          </Tooltip>
        </div>
      ) : (
        <h4 className="tw-blue-gray-900 tw-mb-1 tw-font-bold">{title}</h4>
      )}
      <Tooltip tooltip={isDisabledOnMerchant ? getIntlString('page.merchant.menu.table.disabledOnMerchant.tooltip', { isRootPath: true }) : null}>
        <div className="tw-w-0">
          <Toggle
            {...toggleProps}
            isCritical
            disabled={isDisabled}
            onChange={handleIsAvailableChange}
            uncheckedLabel={uncheckedLabel || getIntlString('variant.no')}
            checkedLabel={checkedLabel || getIntlString('variant.yes')}
          />
        </div>
      </Tooltip>
      {isAvailableAtEnabled && !(isAvailableValue ?? isAvailableProps.checked) && (
        <IsAvailableAt
          disabled={isDisabled}
          timeZone={timeZone}
          isAvailableAt={isAvailableAtValue}
          handleUpdate={isModsMode ? isAvailableAtModProps.onChange : isAvailableAtProps.onChange}
        />
      )}
    </div>
  );
};
