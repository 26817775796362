import { ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemComboFacade } from '../../../facades';
import { ComboCreateMutationParams } from '../../../interface';

export type LocationComboCreateParams = ComboCreateMutationParams & {
  storeId: string | undefined;
};
export type LocationComboCreateMutationResult = UseMutationResult<ReadStoreItemComboDto, unknown, LocationComboCreateParams>;

export const useCreateLocationCombo = (
  options?: UseMutationOptions<ReadStoreItemComboDto, unknown, LocationComboCreateParams>,
): LocationComboCreateMutationResult => {
  return useMutation(({ merchantId, storeId, data }) => locationStoreItemComboFacade.create(merchantId, storeId, data), options);
};
