import { LOYALTY_MODULE_READ_PERMISSIONS, PROMOTION_MODULE_READ_PERMISSIONS } from '@goparrot-dashboard/shared-utils';
import { hasSomePermission, IReadRoleV2Dto, PermissionsV2, PermissionsV2ValueType } from '@goparrot/users-v2-sdk';
import { ROUTE_BASE } from '../constants';
import { checkForPermissions } from '../../sidebar/utils';

type RedirectConfigType = {
  priority: number;
  permissions: (PermissionsV2ValueType | PermissionsV2ValueType[])[];
  url: string;
};

const merchantRedirects = (merchantId: string): Array<RedirectConfigType> => [
  {
    priority: 0,
    permissions: [PermissionsV2.MERCHANT.HOME_PAGE_VIEW],
    url: `${ROUTE_BASE}`,
  },
  {
    priority: 1,
    permissions: [[PermissionsV2.MERCHANT.SETTINGS_PAGE_VIEW, PermissionsV2.MERCHANT.API_READ]],
    url: `${ROUTE_BASE}/merchant`,
  },
  {
    priority: 2,
    permissions: [[PermissionsV2.MERCHANT.FEATURES_SETUP_PAGE_VIEW, PermissionsV2.MERCHANT.API_UPDATE]],
    url: `${ROUTE_BASE}/features-setup`,
  },
  {
    priority: 3,
    permissions: [[PermissionsV2.MERCHANT.LOCATIONS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
    url: `${ROUTE_BASE}/locations`,
  },
  {
    priority: 4,
    permissions: [[PermissionsV2.MERCHANT.TEAMS_PAGE_VIEW, PermissionsV2.USER_V2.API_READ]],
    url: `${ROUTE_BASE}/users`,
  },
  {
    priority: 5,
    permissions: [
      PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_READ,
      PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_CREATE,
      PermissionsV2.MERCHANT_MENU_MANAGEMENT.WEB_UPDATE,
    ],
    url: `${ROUTE_BASE}/menu-management/${merchantId}`,
  },
  {
    priority: 6,
    permissions: [[PermissionsV2.MERCHANT_ORDER.API_READ, PermissionsV2.MERCHANT_ORDER.API_UPDATE]],
    url: `${ROUTE_BASE}/orders`,
  },
  {
    priority: 7,
    permissions: [PermissionsV2.MERCHANT.REPORTS_PAGE_VIEW],
    url: `${ROUTE_BASE}/reports/:reportName?`,
  },
  {
    priority: 8,
    permissions: [PermissionsV2.ROLE_PERMISSION.GOPARROT_SUPER_ADMIN, PermissionsV2.ROLE_PERMISSION.MERCHANT_SUPER_ADMIN, PermissionsV2.CUSTOMER.WEB_READ],
    url: `${ROUTE_BASE}/customers`,
  },

  {
    priority: 9,
    permissions: [PermissionsV2.GIFT_CARDS.API_READ],
    url: `${ROUTE_BASE}/gift-cards`,
  },
  {
    priority: 10,
    permissions: [
      [
        PermissionsV2.PROMOTION.API_READ,
        PermissionsV2.CUSTOMER.API_READ,
        PermissionsV2.MERCHANT_MENU_MANAGEMENT.API_READ,
        PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW,
      ],
    ],
    url: `${ROUTE_BASE}/promotions`,
  },
  {
    priority: 11,
    permissions: [LOYALTY_MODULE_READ_PERMISSIONS],
    url: `${ROUTE_BASE}/loyalty`,
  },
  {
    priority: 12,
    permissions: [PermissionsV2.LOYALTY.WEB_READ, PermissionsV2.LOYALTY.WEB_CREATE, PermissionsV2.LOYALTY.WEB_UPDATE],
    url: `${ROUTE_BASE}/audiences`,
  },
];

export const getMerchantLevelRedirectUrl = (merchantId: string, roleV2: IReadRoleV2Dto): string | undefined => {
  if (hasSomePermission(roleV2, [PermissionsV2.MERCHANT.HOME_PAGE_VIEW])) {
    return '/dashboard';
  }

  return merchantRedirects(merchantId)
    .sort((r1, r2) => (r1.priority > r2.priority ? 1 : -1))
    .find((redirect) => redirect.permissions.some((permission) => checkForPermissions(permission, roleV2)))?.url;
};

const storeRedirects = (storeId: string): Array<RedirectConfigType> => {
  const commonPrefix = `${ROUTE_BASE}/${storeId}`;
  return [
    {
      priority: 0,
      permissions: [PermissionsV2.STORE.HOME_PAGE_VIEW],
      url: `${commonPrefix}/home`,
    },
    {
      priority: 1,
      permissions: [[PermissionsV2.STORE.GENERAL_SETTINGS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
      url: `${commonPrefix}/general`,
    },
    {
      priority: 2,
      permissions: [[PermissionsV2.STORE.POS_SETTINGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ]],
      url: `${commonPrefix}/pos`,
    },
    {
      priority: 3,
      permissions: [PermissionsV2.STORE.TEAMS_PAGE_VIEW],
      url: `${commonPrefix}/users`,
    },
    {
      priority: 4,
      permissions: [[PermissionsV2.STORE.PRINT_TAGS_PAGE_VIEW, PermissionsV2.VENDOR.API_READ, PermissionsV2.STORE.API_READ]],
      url: `${ROUTE_BASE}/print-tags`,
    },
    {
      priority: 5,
      permissions: [
        PermissionsV2.STORE_MENU_MANAGEMENT.WEB_CREATE,
        PermissionsV2.STORE_MENU_MANAGEMENT.WEB_UPDATE,
        PermissionsV2.STORE_MENU_MANAGEMENT.WEB_READ,
      ],
      url: `${ROUTE_BASE}/menu-management/${storeId}`,
    },
    {
      priority: 6,
      permissions: [[PermissionsV2.STORE_ORDER.API_READ, PermissionsV2.STORE_ORDER.API_UPDATE]],
      url: `${commonPrefix}/orders`,
    },
    {
      priority: 7,
      permissions: [PermissionsV2.STORE_REPORTS.PAGE_VIEW],
      url: `${commonPrefix}/reports`,
    },
    {
      priority: 6,
      permissions: [[PermissionsV2.STORE_DINING_OPTIONS.API_READ, PermissionsV2.STORE.API_READ]],
      url: `${commonPrefix}/dining-options`,
    },
    {
      priority: 9,
      permissions: [
        [
          PermissionsV2.STORE.PREPARATION_TIME_PAGE_VIEW,
          PermissionsV2.VENDOR.API_READ,
          PermissionsV2.STORE.API_READ,
          PermissionsV2.STORE_ORDER_CONFIG.API_READ,
        ],
      ],
      url: `${commonPrefix}/order-config`,
    },
    {
      priority: 10,
      permissions: [PermissionsV2.STORE.WEB_CREATE, PermissionsV2.STORE.WEB_UPDATE, PermissionsV2.STORE.WEB_READ],
      url: `${commonPrefix}/delivery/settings`,
    },
    {
      priority: 11,
      permissions: [PermissionsV2.STORE.WEB_CREATE, PermissionsV2.STORE.WEB_UPDATE, PermissionsV2.STORE.WEB_READ],
      url: `${commonPrefix}/pickup`,
    },
    {
      priority: 12,
      permissions: [[PermissionsV2.STORE.BUSINESS_HOURS_PAGE_VIEW, PermissionsV2.STORE.API_READ]],
      url: `${commonPrefix}/business-hours`,
    },
    {
      priority: 13,
      permissions: [PermissionsV2.STORE_NOTIFICATIONS.WEB_CREATE, PermissionsV2.STORE_NOTIFICATIONS.WEB_UPDATE, PermissionsV2.STORE_NOTIFICATIONS.WEB_READ],
      url: `${commonPrefix}/notifications`,
    },
    {
      priority: 14,
      permissions: [PermissionsV2.STORE_PAYMENT.WEB_CREATE, PermissionsV2.STORE_PAYMENT.WEB_UPDATE, PermissionsV2.STORE_PAYMENT.WEB_READ],
      url: `${commonPrefix}/payment`,
    },
    {
      priority: 15,
      permissions: [
        [
          PermissionsV2.STORE_SERVICE_CHARGES.API_READ,
          PermissionsV2.VENDOR.API_READ,
          PermissionsV2.STORE.API_READ,
          PermissionsV2.STORE_MENU_MANAGEMENT.API_READ,
        ],
      ],
      url: `${commonPrefix}/service-charges`,
    },
    {
      priority: 16,
      permissions: [
        [...PROMOTION_MODULE_READ_PERMISSIONS, PermissionsV2.MERCHANT.PROMOTION_PAGE_VIEW],
        [...PROMOTION_MODULE_READ_PERMISSIONS, PermissionsV2.STORE_PROMOTION.PAGE_VIEW],
      ],
      url: `${commonPrefix}/promotions`,
    },
  ];
};

export const getStoreLevelRedirectUrl = (storeId: string, roleV2: IReadRoleV2Dto): string | undefined => {
  return storeRedirects(storeId)
    .sort((r1, r2) => (r1.priority > r2.priority ? 1 : -1))
    .find((redirect) => redirect.permissions.some((permission) => checkForPermissions(permission, roleV2)))?.url;
};
