import { Checkbox, Toggle, FormField } from '@goparrot-dashboard/shared-ui';
import { useI18n, getIntlString as getIntlStringBase } from '@goparrot-dashboard/i18n';
import React, { FC } from 'react';

import { ExplicitFranchiseLocationOptions } from '../../types';
import { InputLabeled } from '../input-labeled/InputLabeled';
import { SynchronizationLabel } from '../synchronization-label/SynchronizationLabel';

type MenuManagementProps = {
  options: ExplicitFranchiseLocationOptions;
  onChange: (optionName: keyof ExplicitFranchiseLocationOptions, value: boolean) => any;
  showSynchronizationStatus?: boolean;
};

export const MenuManagement: FC<React.PropsWithChildren<MenuManagementProps>> = (props) => {
  const { getIntlString } = useI18n();
  const getItemProps = (key: keyof ExplicitFranchiseLocationOptions) => ({
    checked: (props?.options && props.options[key]) || false,
    onChange: (value: boolean) => props.onChange(key, value),
  });

  return (
    <FormField
      label={
        <div className="tw-flex tw-flex-col">
          <span>{getIntlString('menu.title')}</span>
          {props.showSynchronizationStatus && <SynchronizationLabel value={props.options?.menu} />}
        </div>
      }
      input={
        <div className="tw-w-full">
          <div className="tw-border-b tw-border-cool-gray-200 tw-py-3 tw-mb-2">
            <Toggle
              onChange={() => null}
              checkedLabel={getIntlStringBase('variant.enabled')}
              uncheckedLabel={getIntlStringBase('variant.disabled')}
              checked
              disabled
            />
          </div>
          <div className="tw-pt-1">
            <InputLabeled label={getIntlString('menu.optionLabel.menuSpecific')} input={<Checkbox {...getItemProps('menuSpecific')} />} />
            <InputLabeled label={getIntlString('menu.optionLabel.menuReplica')} input={<Checkbox {...getItemProps('menuReplica')} />}>
              {props.options?.menuReplica && (
                <div className="tw-pt-1 tw-ml-6">
                  <InputLabeled label={getIntlString('menu.optionLabel.menuUpdateLabels')} input={<Checkbox {...getItemProps('menuUpdateLabels')} />} />
                  <InputLabeled label={getIntlString('menu.optionLabel.menuUpdatePrice')} input={<Checkbox {...getItemProps('menuUpdatePrice')} />} />
                  <InputLabeled label={getIntlString('menu.optionLabel.menuUpdateSku')} input={<Checkbox {...getItemProps('menuUpdateSku')} />} />
                  <InputLabeled
                    label={getIntlString('menu.optionLabel.menuUpdateExternalImageUrl')}
                    input={<Checkbox {...getItemProps('menuUpdateExternalImageUrl')} />}
                  />
                  <InputLabeled label={getIntlString('menu.optionLabel.menuConnections')} input={<Checkbox {...getItemProps('menuConnections')} />} />
                  <InputLabeled label={getIntlString('menu.optionLabel.menuRemoveNonOrderable')} input={<Checkbox {...getItemProps('menuUpdateArchived')} />} />
                  <InputLabeled
                    label={getIntlString('menu.optionLabel.menuUpdateOutOfStock')}
                    input={<Checkbox {...getItemProps('menuUpdateAvailability')} />}
                  />
                  <InputLabeled label={getIntlString('menu.optionLabel.menuUpdateOrderable')} input={<Checkbox {...getItemProps('menuUpdateOrderable')} />} />
                </div>
              )}
            </InputLabeled>
          </div>
        </div>
      }
    />
  );
};
