import React, { FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useConnectionsContext } from '../../context';
import { ConnectionItem } from '../../../../interface';
import { PreviewConnection } from './interface';
import { ModalPreviewConnection } from './ModalPreviewConnection';
import { ModalPreviewHeader } from './ModalPreviewHeader';

type ModalItemPreviewProps = {
  item: ConnectionItem;
};

export const ModalItemPreview: FC<React.PropsWithChildren<ModalItemPreviewProps>> = ({ item }) => {
  const { previewConnections, merchantId, previewConnectionsComponent: CustomPreview, typedPreviewConnections } = useConnectionsContext();
  const previews = typedPreviewConnections ? previewConnections.filter((conn) => conn.filterCondition(item)) : previewConnections;

  return item ? (
    <div className="tw-pt-8 tw-pb-2 tw-px-7 tw-space-y-8">
      <ModalPreviewHeader item={item} />
      {previews?.length ? (
        <>
          {previews.map((connection: PreviewConnection, index: number) => {
            const Component = connection?.component ? connection?.component : CustomPreview ?? ModalPreviewConnection;

            return <Component key={`${connection.name}-${index}`} connection={connection} item={item} merchantId={merchantId} />;
          })}
        </>
      ) : (
        <>{CustomPreview ? <CustomPreview item={item} merchantId={merchantId} /> : <EmptyPreview />}</>
      )}
    </div>
  ) : (
    <EmptyPreview />
  );
};

const EmptyPreview = () => (
  <div className="tw-p-6">
    <div className="tw-font-normal tw-text-blue-gray-900">{getIntlString('menu.connections.modal.preview.empty')}</div>
  </div>
);
