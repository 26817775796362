import { DialogStateReturn } from 'reakit/src/Dialog/DialogState';
import * as React from 'react';
import { ConfirmModal } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

type Props = {
  dialog: DialogStateReturn;
  connectionType?: string;
  itemName: string;
  onConfirm(): void;
  isLoading?: boolean;
};

export const RemoveConnectionConfirm: React.FC<React.PropsWithChildren<Props>> = ({ dialog, onConfirm, connectionType, itemName, isLoading }) => (
  <ConfirmModal
    dialog={dialog}
    confirmColor="danger"
    confirmText={getIntlString('button.delete')}
    header={getIntlString('menu.connections.modal.remove')}
    content={<div>{getIntlString('menu.connections.modal.remove.text', { itemName, type: connectionType, strong: (chunk) => <strong>{chunk}</strong> })}</div>}
    onConfirm={onConfirm}
    confirmDisabled={isLoading}
  />
);
