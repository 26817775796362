import { EntityFormResult, BaseInputBindingsOptions, InputBindingsMethod } from '../../types';
import { getValueTransformers } from '../../utils/valueTransformers';

export type UseCheckboxPropsResult = {
  checked: boolean;
  onChange(value: any): void;
  disabled: boolean;
};
export type UseCheckboxPropsOptions = BaseInputBindingsOptions;

/**
 * @description Use this hook only when `onChange` is called with expected value, not an event
 */
export function useCheckboxProps<T>(
  hook: EntityFormResult<T>,
  options?: UseCheckboxPropsOptions,
  keepOriginalValue?: boolean,
): InputBindingsMethod<T, BaseInputBindingsOptions, UseCheckboxPropsResult> {
  const useCheckboxPropsForPath: InputBindingsMethod<T, BaseInputBindingsOptions, UseCheckboxPropsResult> = (path, inputOptions) => {
    const valueTransformers = getValueTransformers<T>(hook, inputOptions ?? options);
    return {
      checked: keepOriginalValue ? valueTransformers.toInput(path) : !!valueTransformers.toInput(path),
      onChange: (value) => {
        valueTransformers.toState(path, value);
        // checkbox elements don't trigger onBlur event
        // so we have to simulate this to make the field dirty
        hook.onFieldBlur(path);
      },
      disabled: hook.isDisabledField(path),
    };
  };

  return useCheckboxPropsForPath;
}
