import { IDateRange } from '@goparrot/store-v2-sdk';
import { DateTime } from 'luxon';
import { DateTimeRangeType } from '../interface';

export const convertRangeToJSDate = (range: IDateRange): DateTimeRangeType => {
  return {
    from: DateTime.fromISO(range.from, { setZone: true }),
    to: DateTime.fromISO(range.to, { setZone: true }),
  };
};
