import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IStoreFilterParams, StoreItemListResponseDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_SHARED_QUERIES_ENUM } from '../../enums';
import { locationStoreItemFacade } from '../../facades';
import { ListQueryBaseParams } from '../../interface';

type QueryParams = ListQueryBaseParams<IStoreFilterParams> & {
  storeId: string;
};

export const useLocationStoreItemList = (
  { merchantId, storeId, pagination, params }: QueryParams,
  options?: UseQueryOptions<StoreItemListResponseDto>,
): UseQueryResult<StoreItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_SHARED_QUERIES_ENUM.LOCATION_STORE_ITEM_LIST, merchantId, storeId, pagination, params],
    async () => await locationStoreItemFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
