import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { PatchStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { storeByMerchantFacade } from '../facades';
import type { UpdateStoreParams } from '../interface';

export const useUpdateStore = (
  options?: UseMutationOptions<ReadStoreDto, unknown, UpdateStoreParams<PatchStoreDto>, unknown>,
): UseMutationResult<ReadStoreDto, unknown, UpdateStoreParams<PatchStoreDto>, unknown> => {
  return useMutation(({ merchantId, storeId, data }) => storeByMerchantFacade.patch(merchantId, storeId, data), options);
};
