import { AvailableAtType } from '@goparrot/common';
import { StoreItemsExposeGroup, IAbstractItemMods } from '@goparrot/storeitems-sdk';
import { Expose, Type } from 'class-transformer';
import { IsBoolean, IsDateString, IsOptional, IsString } from 'class-validator';

export class ModsModel implements IAbstractItemMods {
  @Expose()
  @IsOptional({ groups: [StoreItemsExposeGroup.PATCH, StoreItemsExposeGroup.BATCH_PATCH] })
  @IsString({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  storeId: string;

  @Expose()
  @IsOptional({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  @IsBoolean({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  isAvailable?: boolean;

  @Expose()
  @IsOptional({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  @IsDateString({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  isAvailableAt?: AvailableAtType;

  @Expose()
  @IsOptional({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  @IsBoolean({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  isOrderable?: boolean;

  @Expose()
  @IsOptional({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  @IsBoolean({
    groups: [
      StoreItemsExposeGroup.CREATE,
      StoreItemsExposeGroup.UPDATE,
      StoreItemsExposeGroup.PATCH,
      StoreItemsExposeGroup.BATCH_PATCH,
      StoreItemsExposeGroup.BATCH_CREATE,
    ],
  })
  @Type(() => Boolean)
  isArchived?: boolean;

  init(options: Partial<ModsModel> = {}) {
    this.storeId = options.storeId ?? '';
    this.isArchived = options.isArchived;
    this.isAvailable = options.isAvailable;
    this.isAvailableAt = options.isAvailableAt;
    this.isOrderable = options.isOrderable;

    return this;
  }
}
