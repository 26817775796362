import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { Item } from '../interface';
import { filterActions } from './filterActions';

export const filterListByStoreTitle = <T extends Item>(list: T[] = [], searchQuery: string, stores: ReadStoreDto[]): T[] => {
  let filteredItems = list;
  if (searchQuery && stores) {
    filteredItems = list.filter((item) => {
      const store = stores.find((el) => el.storeId === item.storeId);
      if (!store) {
        return false;
      }
      return filterActions.byTitle(store, searchQuery);
    });
  }

  return filteredItems;
};
