import { useEffect, useRef } from 'react';

export function usePrevious<T>(prop: T) {
  const ref = useRef(prop);

  useEffect(() => {
    ref.current = prop;
  });

  return ref.current;
}
