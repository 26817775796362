import { Alert } from 'antd';
import styled from 'styled-components';

import { palette } from 'styles/constants';

export const AlertWrapper = (component: typeof Alert) => styled(component)`
  && {
    color: ${palette.text[1]};
    font-size: 12px;
    margin-bottom: 16px;
  }

  &&.ant-alert-success {
    .ant-alert-icon {
      color: ${palette.success};
    }
  }

  &&.ant-alert-error {
    .ant-alert-icon {
      color: ${palette.error[0]};
    }
  }

  &&.ant-alert-with-description {
    padding: 16px 16px 16px 60px;
    .ant-alert-message {
      font-weight: 500;
      color: ${palette.text[0]};
    }

    .ant-alert-description {
      color: ${palette.text[1]};
      font-size: 12px;
    }

    .ant-alert-icon {
      top: 20px;
      left: 20px;
      right: inherit;
    }
    &&.ant-alert-no-icon {
      padding: 12px 48px 12px 16px;
    }
    .ant-alert-close-icon {
      right: 16px;
      left: inherit;
    }
  }
  &&.ant-alert-no-icon {
    padding: 12px 48px 12px 16px;
  }
  .ant-alert-close-icon {
    right: 16px;
    left: inherit;
  }
`;
