import { CreateStoreItemDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantAddStoreItemFirstVariationParams = {
  merchantId: string;
  uniqueName: string;
  data: CreateStoreItemDto;
};

export const useMerchantAddStoreItemFirstVariation = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantAddStoreItemFirstVariationParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantAddStoreItemFirstVariationParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemFacade.addVariation(merchantId, uniqueName, data), options);
};
