import * as React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';

export const InsufficientPermissions: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-py-10 tw-px-0 tw-flex-1">
      <h2>{getIntlString('permission.error')}</h2>
    </div>
  );
};
