import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ReadWebMerchantDto } from '@goparrot/webstore-sdk';
import { JsonPatchOperationDto } from '@goparrot/common';
import { webMerchantFacade } from '../facades';

type UpdateWebMerchantParams = {
  merchantId: string;
  data: JsonPatchOperationDto[];
};
type PatchWebMerchantResult = ReadWebMerchantDto;

export const useUpdateWebMerchant = (
  options?: UseMutationOptions<PatchWebMerchantResult, unknown, UpdateWebMerchantParams>,
): UseMutationResult<PatchWebMerchantResult, unknown, UpdateWebMerchantParams> => {
  return useMutation(({ merchantId, data }) => webMerchantFacade.patch(merchantId, data), options);
};
