import { ICloneRequest, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type LocationCloneItemOptionsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useLocationCloneItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, LocationCloneItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, LocationCloneItemOptionsParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemOptionsFacade.clone(merchantId, storeId, uniqueName, data),
    options,
  );
};
