import { datadogRum } from '@datadog/browser-rum';

const APPLICATION_ID = 'd7c1ca55-2388-45c1-b4f2-333cba404763';
const CLIENT_TOKEN = 'pub6412bc84595744901b38fdb6f739c857';
const SERVICE = 'fs-dashboard';
const SITE = 'datadoghq.com';
const STAGING_HOST = 'dashboard-v2-staging.ci.goparrot.ai';
const PROD_HOST = 'dashboardv2.goparrot.ai';

const DatadogEnvironment = {
  PRODUCTION: 'prod',
  STAGING: 'staging',
};

function isStaging() {
  return window._env_.NODE_ENV === 'production' && window.location.host === STAGING_HOST;
}

function isProd() {
  return window._env_.NODE_ENV === 'production' && window.location.host === PROD_HOST;
}

export function initialize(): void {
  if (isProd() || isStaging()) {
    datadogRum.init({
      applicationId: APPLICATION_ID,
      clientToken: CLIENT_TOKEN,
      env: isStaging() ? DatadogEnvironment.STAGING : DatadogEnvironment.PRODUCTION,
      service: SERVICE,
      site: SITE,
      // The trackUserInteractions parameter enables the automatic collection of user clicks in your application.
      // Sensitive and private data contained on your pages may be included to identify the elements interacted with.
      trackUserInteractions: true,
    });
  }
}

export function addDDRumContext(userId: string, merchantId: string): void {
  if ((isProd() || isStaging()) && merchantId && userId) {
    try {
      datadogRum.setUser({
        id: userId,
        merchantId,
      });
    } catch {
      console.warn('failed to add dd context');
    }
  }
}
