import { assign } from 'lodash/fp';

import a from './actions';

const initialState = {
  timeZone: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.LOCAL__TIME__ZONE__SELECT:
      return assign(state, {
        timeZone: action.payload.timeZone,
      });

    default:
      return state;
  }
}
