import React, { FC } from 'react';
import styled from 'styled-components';

export type ContentWrapperProps = {
  style?: React.CSSProperties;
};

export const ContentWrapper: FC<React.PropsWithChildren<ContentWrapperProps>> = ({ style, children }) => (
  <ContentStyled style={style}>{children}</ContentStyled>
);

const ContentStyled = styled.main`
  margin: 10px 20px 20px;
`;
