import { createAction } from 'helpers/create-action';

const c = {
  API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__REQUEST: 'API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__REQUEST',
  API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__SUCCESS: 'API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__SUCCESS',
  API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__ERROR: 'API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__ERROR',

  API__VENDOR__SYNC__ITEM__FROM__POS__REQUEST: 'API__VENDOR__SYNC__ITEM__FROM__POS__REQUEST',
  API__VENDOR__SYNC__ITEM__FROM__POS__SUCCESS: 'API__VENDOR__SYNC__ITEM__FROM__POS__SUCCESS',
  API__VENDOR__SYNC__ITEM__FROM__POS__ERROR: 'API__VENDOR__SYNC__ITEM__FROM__POS__ERROR',

  API__CATEGORY__GET__SYNC__CONFIG__REQUEST: 'API__CATEGORY__GET__SYNC__CONFIG__REQUEST',
  API__CATEGORY__GET__SYNC__CONFIG__SUCCESS: 'API__CATEGORY__GET__SYNC__CONFIG__UCCESS',
  API__CATEGORY__GET__SYNC__CONFIG__ERROR: 'API__CATEGORY__GET__SYNC__CONFIG__ERROR',

  API__CATEGORY__PATCH__SYNC__CONFIG__REQUEST: 'API__CATEGORY__PATCH__SYNC__CONFIG__REQUEST',
  API__CATEGORY__PATCH__SYNC__CONFIG__SUCCESS: 'API__CATEGORY__PATCH__SYNC__CONFIG__SUCCESS',
  API__CATEGORY__PATCH__SYNC__CONFIG__ERROR: 'API__CATEGORY__PATCH__SYNC__CONFIG__ERROR',

  API__GET__TAX__FROM__POS__BY__QUERY__REQUEST: 'API__GET__TAX__FROM__POS__BY__QUERY__REQUEST',
  API__GET__TAX__FROM__POS__BY__QUERY__SUCCESS: 'API__GET__TAX__FROM__POS__BY__QUERY__SUCCESS',
  API__GET__TAX__FROM__POS__BY__QUERY__ERROR: 'API__GET__TAX__FROM__POS__BY__QUERY__ERROR',

  POS__GUID__IS__CHANGED: 'POS__GUID__IS__CHANGED',
  CAN__SAVE__AND__SYNC: 'CAN__SAVE__AND__SYNC',
};

const a = {
  requestGetItemsFromPOSByQuery: createAction(c.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__REQUEST),
  successGetItemsFromPOSByQuery: createAction(c.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__SUCCESS),
  errorGetItemsFromPOSByQuery: createAction(c.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__ERROR),

  requestSyncItemsFromPOS: createAction(c.API__VENDOR__SYNC__ITEM__FROM__POS__REQUEST),
  successSyncItemsFromPOS: createAction(c.API__VENDOR__SYNC__ITEM__FROM__POS__SUCCESS),
  errorSyncItemsFromPOS: createAction(c.API__VENDOR__SYNC__ITEM__FROM__POS__ERROR),

  requestCategoryGetSyncConfig: createAction(c.API__CATEGORY__GET__SYNC__CONFIG__REQUEST),
  successCategoryGetSyncConfig: createAction(c.API__CATEGORY__GET__SYNC__CONFIG__SUCCESS),
  errorCategoryGetSyncConfig: createAction(c.API__CATEGORY__GET__SYNC__CONFIG__ERROR),

  requestCategoryPatchSyncConfig: createAction(c.API__CATEGORY__PATCH__SYNC__CONFIG__REQUEST),
  successCategoryPatchSyncConfig: createAction(c.API__CATEGORY__PATCH__SYNC__CONFIG__SUCCESS),
  errorCategoryPatchSyncConfig: createAction(c.API__CATEGORY__PATCH__SYNC__CONFIG__ERROR),

  requestGetTaxesFromPOSByQuery: createAction(c.API__GET__TAX__FROM__POS__BY__QUERY__REQUEST),
  successGetTaxesFromPOSByQuery: createAction(c.API__GET__TAX__FROM__POS__BY__QUERY__SUCCESS),
  errorGetTaxesFromPOSByQuery: createAction(c.API__GET__TAX__FROM__POS__BY__QUERY__ERROR),

  posGuidIsChanged: (posGuidFlag) => ({
    type: c.POS__GUID__IS__CHANGED,
    payload: posGuidFlag,
  }),

  canSaveAndSync: (canSaveAndSyncFlag) => ({
    type: c.CAN__SAVE__AND__SYNC,
    payload: canSaveAndSyncFlag,
  }),
};

export default { ...c, ...a };
