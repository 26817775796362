import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDER__TRANSACTION__GET__REQUEST:
    case a.API__ORDER__REFUND__GET__REQUEST:
      return assign(state, { fetching: true });

    case a.API__ORDER__TRANSACTION__GET__SUCCESS:
    case a.API__ORDER__REFUND__GET__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.API__ORDER__REFUND__GET__ERROR:
      return assign(state, { fetching: false, errors: [...action.payload] });

    case a.LOCAL__ORDER__REFUND__CLEAR__ERROR:
      return assign(state, { errors: [] });

    case a.API__ORDER__TRANSACTION__GET__ERROR:
    case a.LOCAL__ORDER__RESET__SELECTED:
      return initialState;

    default:
      return state;
  }
}
