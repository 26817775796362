import uniq from 'lodash/uniq';

export const setListOfUniqueName = (uniqueName: string) => {
  let uniqueNameList = [];
  const data = JSON.parse(sessionStorage.getItem('uniqueNameList'));
  if (data?.length) {
    uniqueNameList = data;
  }
  const clearedFromDuplicate = uniq([...uniqueNameList, uniqueName]);
  sessionStorage.setItem('uniqueNameList', JSON.stringify(clearedFromDuplicate));
  if (10 < uniqueNameList.length) {
    clearedFromDuplicate.shift();
    sessionStorage.setItem('uniqueNameList', JSON.stringify(clearedFromDuplicate));
  }
};
