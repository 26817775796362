import { FranchiseLocationDto, IncludeLocationsDto } from '@goparrot/giftcard-orchestrator-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { giftCardMerchantLocationFacade } from '../api';

export const useCreateGiftCardLocations = (
  onSuccess: () => void = () => null,
): {
  createLocations(merchantId: string, stores: FranchiseLocationDto[]): void;
} => {
  const { notifyError, notifySuccess } = useNotifications();
  const createLocations = async (merchantId: string, stores: FranchiseLocationDto[]) => {
    const payload: IncludeLocationsDto = {
      stores,
    };
    try {
      await giftCardMerchantLocationFacade.includeLocations(merchantId, payload);
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.location.createMany.success.message'),
      });
      onSuccess();
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.location.createMany.error.message'),
      });
    }
  };
  return { createLocations };
};
