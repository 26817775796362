import { useDialogState } from 'reakit';
import { ConfirmModal, Select, FormField, LabelWithTooltip, Spinner, PanelTitle } from '@goparrot-dashboard/shared-ui';
import React, { FC, useCallback, useState } from 'react';
import { usePatchStoreByMerchant } from '@goparrot-dashboard-api/store';
import { StoreSpecificTypeEnum } from '@goparrot/store-v2-sdk';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useDispatch } from 'react-redux';

import { useStoreService } from '@goparrot-dashboard-core/store-service';
import franchiseActions from '../../../../client/src/store/franchise/actions';
import { useMenuOrchestrationContext } from '../../services';
import { useCentralLocation, useCreateCentralLocation } from '../../hooks';

export const CentralLocation: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const {
    merchant: { merchantId },
    stores,
  } = useStoreService();
  const patchStoreByMerchant = usePatchStoreByMerchant({});
  const { fetchLocations } = useMenuOrchestrationContext();
  const { getIntlString } = useI18n();

  // fetch central location
  const { centralLocation, refetch, loading } = useCentralLocation(merchantId, stores);
  const confirmCentralLocationDialog = useDialogState({ animated: true });
  const options = React.useMemo(() => stores.map((l) => ({ value: l.storeId, label: l.title })), [stores]);

  // create central location
  const onCreateCentralLocationSuccess = useCallback(
    (centralLocationStoreId: any) => {
      refetch();
      fetchLocations();
      confirmCentralLocationDialog.hide();
      dispatch(franchiseActions.requestLocationsListGet({ merchantId }));
      const payload = {
        specificType: StoreSpecificTypeEnum.FRANCHISE_CENTRAL,
      };
      patchStoreByMerchant.mutate({ merchantId, storeId: centralLocationStoreId, payload });
    },
    [refetch, fetchLocations, confirmCentralLocationDialog, dispatch, merchantId, patchStoreByMerchant],
  );
  const { createCentralLocation, isLoading: isCreatingCentralLocation } = useCreateCentralLocation({ onSuccess: onCreateCentralLocationSuccess });
  const setCentralLocation = (storeId: string) => createCentralLocation(merchantId, storeId);

  const [centralLocationCandidate, setCentralLocationCandidate] = useState<string | null>(null);

  const confirmSetupCentralLocation = (storeId: string) => {
    setCentralLocationCandidate(storeId);
    confirmCentralLocationDialog.show();
  };

  const dismissCentralLocation = () => {
    confirmCentralLocationDialog.hide();
    setTimeout(() => setCentralLocationCandidate(null), 200);
  };

  return (
    <Spinner spinning={loading}>
      <PanelTitle hasMargin={false}>{getIntlString('page.merchant.orchestration.central.title', { isRootPath: true })}</PanelTitle>
      <div>
        <FormField
          withValuePadding={false}
          disabled={loading || !!centralLocation}
          label={
            <LabelWithTooltip tooltip={getIntlString('central.selectTooltip')}>
              {getIntlString('page.merchant.orchestration.central.selectLabel', { isRootPath: true })}
            </LabelWithTooltip>
          }
          input={
            <Select
              placeholder={getIntlString('page.merchant.orchestration.central.selectPlaceholder', { isRootPath: true })}
              value={centralLocationCandidate || centralLocation?.storeId}
              options={options}
              onChange={confirmSetupCentralLocation}
              disabled={loading || !!centralLocation}
              menuPortalTarget={document.body}
            />
          }
        />
      </div>
      {centralLocationCandidate && (
        <ConfirmModal
          dialog={confirmCentralLocationDialog}
          confirmDisabled={isCreatingCentralLocation}
          header={getIntlString('page.merchant.orchestration.central.selectModalTitle', { isRootPath: true })}
          content={
            <div>
              <p className="tw-text-lg tw-text-cool-gray-900">
                {getIntlString('page.merchant.orchestration.central.selectModalChoosedLocation', { isRootPath: true })}{' '}
                <b>{stores.find((l) => l.storeId === centralLocationCandidate)?.name}</b>.<br />
                {getIntlString('page.merchant.orchestration.central.selectModalConfirmation', { isRootPath: true })}
              </p>
            </div>
          }
          onConfirm={() => setCentralLocation(centralLocationCandidate)}
          onCancel={dismissCentralLocation}
          confirmText={getIntlString('page.merchant.orchestration.central.selectModalYes', { isRootPath: true })}
          cancelText={getIntlString('page.merchant.orchestration.central.selectModalNo', { isRootPath: true })}
        />
      )}
    </Spinner>
  );
};
