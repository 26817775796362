import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { comboItemFacade } from '../facades';

type ChangeComboPromoParams = {
  uniqueName: string;
  promotionUuid: string;
};

export const useChangeComboPromo = (
  options: UseMutationOptions<void, unknown, ChangeComboPromoParams>,
): UseMutationResult<void, unknown, ChangeComboPromoParams> => {
  return useMutation(({ uniqueName, promotionUuid }) => comboItemFacade.linkPromotion(uniqueName, { promotionUuid }), options);
};
