import * as React from 'react';
import { ReadDefaultStoreDto, ReadStoreDto, ReadMerchantDto } from '@goparrot/store-v2-sdk';
import { WebStoreUrlsDto } from '@goparrot/webstore-sdk';
import { ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';

export type StoreServiceContextType = {
  selectedStoreId: string | null;
  merchant: ReadMerchantDto;
  isMerchantFetching: boolean;
  stores: ReadStoreDto[];
  userStores: string[] | null;
  defaultStores: ReadDefaultStoreDto[];
  locationsFetching: boolean;
  locationDefaultFetching: boolean;
  isMerchant: boolean;
  isMultiEntityType: boolean;
  franchiseLocations: ReadLocationDto[] | null;
  franchiseLocationsLoading: boolean;
  levelParentUrl: string;
  timeZone: string;
  isFranchise: boolean;
  webStoreUrls: WebStoreUrlsDto;
  isVirtualMenu: boolean;
};

export const StoreServiceContext = React.createContext<StoreServiceContextType | undefined>(undefined);
