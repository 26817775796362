import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import { DropResult } from 'react-beautiful-dnd';
import { ConnectionItem, Item } from '../interface';

export const reorderArray: (items: ConnectionItem[], destination: DropResult['destination'], source: DropResult['source']) => ConnectionItem[] = (
  items,
  destination,
  source,
) => {
  if (!items.length) {
    return [];
  }
  if (source.index > items.length - 1 || destination.index > items.length - 1) {
    return items;
  }
  const cloned = cloneDeep(items);
  const { currentPosition, proposedPosition, target } = reorderAssets(cloned, source, destination);
  // remove item from it's position
  cloned.splice(currentPosition, 1);
  // move item to new position considering archived items
  cloned.splice(proposedPosition, 0, target);
  return cloned;
};

export const reorderAssets = (items, source, destination) => {
  const filtered = items.filter((item) => !(item as Item).isArchived);
  const destinationItem = filtered[destination.index];
  const proposedPosition = findIndex(items, ['uniqueName', destinationItem.uniqueName]);
  const target = filtered[source.index];
  const currentPosition = findIndex(items, ['uniqueName', target.uniqueName]);

  return { currentPosition, proposedPosition, target };
};
