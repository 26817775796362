import React, { FC, useMemo } from 'react';
import { IconMoreVertical, Menu } from '@goparrot-dashboard/shared-ui';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { ButtonLinkToStore, getStoreTime } from '@goparrot-dashboard/shared-utils';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { getIntlString } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { ReadDefaultStoreDto, ReadMerchantDto, ReadStoreDto } from '@goparrot/store-v2-sdk';

import { MarketButton } from '@market/react-bindings';
import aAuth from '../../../../client/src/store/auth/actions';
import { ButtonLinkToSite } from './ButtonLinkToSite';

type NavbarUserProps = {
  selectedItemTitlte: string;
  isSidebarVisibleOnMobile: boolean;
  centralLocationStoreId?: string;
  selectedStore?: ReadStoreDto | ReadDefaultStoreDto | ReadMerchantDto;
  locationsLoading: boolean;
  showButtonLinkToStore?: boolean;
  currentTime: string;
};

export const NavbarUser: FC<React.PropsWithChildren<NavbarUserProps>> = ({
  selectedItemTitlte,
  isSidebarVisibleOnMobile,
  centralLocationStoreId,
  selectedStore,
  locationsLoading,
  showButtonLinkToStore,
  currentTime,
}) => {
  const { roleV2, user } = useUserService();
  const { defaultStores, merchant, isMerchant } = useStoreService();
  const dispatch = useDispatch();
  const capitalizeWord = (str: string) => str[0].toUpperCase() + str.slice(1).toLowerCase();
  const userRoleCapitalized = useMemo(() => (user ? roleV2?.name?.replace(new RegExp('_', 'g'), ' ').split(' ').map(capitalizeWord).join(' ') : ''), [
    roleV2?.name,
    user,
  ]);

  const logout = () => {
    dispatch(aAuth.logout());
  };

  const isCentralLocation = centralLocationStoreId === selectedStore?.storeId;

  return (
    <>
      <div className="xl:tw-flex tw-items-center xl:tw-order-1 tw-hidden tw-ml-auto tw-relative tw-group tw-min-h-45" data-testid="log-out-dropdown">
        <Menu
          menuProps={{ 'aria-label': 'User menu' }}
          className="tw-z-1 tw-left-auto tw-right-0"
          ariaLabel="Navbar Dropdown"
          disclosure={
            <div className="tw-flex tw-items-center tw-h-full">
              <span className="tw-flex-1 tw-whitespace-nowrap tw-text-cool-gray-700">{user?.email}</span>
              <IconMoreVertical className="tw-ml-3.5 tw-hidden xl:tw-block" />
              {userRoleCapitalized && (
                <span className="tw-absolute tw-bottom-0 tw-text-xxs tw-text-cool-gray-400 tw-opacity-0 group-hover:tw-opacity-100">{userRoleCapitalized}</span>
              )}
            </div>
          }
          menuItems={[
            <div className="tw-bg-white" key="1">
              <MarketButton rank="tertiary" onClick={logout} className="tw-bg-opacity-100" data-testid="log-out-button">
                {getIntlString('button.logout')}
              </MarketButton>
            </div>,
          ]}
        />
      </div>
      <div
        className={classNames([
          isSidebarVisibleOnMobile ? 'tw-transform tw-translate-x-full tw-opacity-0' : 'tw-transform tw-translate-x-0 tw-opacity-100',
          'tw-transition-all tw-block xl:tw-hidden xl:tw-mr-0 tw-flex-1 tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden',
        ])}
      >
        <div className="tw-flex tw-justify-center tw-tw-items-center">
          <div className="tw-block">
            <div className="tw-flex tw-items-baseline tw-justify-center tw-space-x-2">
              <span
                className={classNames([
                  'tw-block tw-text-center tw-text-sm tw-leading-5 tw-whitespace-nowrap tw-overflow-ellipsis',
                  'tw-overflow-hidden text-gray-700 sm:tw-max-w-36 md:tw-max-w-sm',
                ])}
              >
                {selectedItemTitlte}
                {isCentralLocation && getIntlString('topbar.centralLocation')}
              </span>
              <span className="tw-leading-4 tw-text-cool-gray-400 tw-text-xs">{getStoreTime(selectedStore, currentTime)}</span>
            </div>
            {userRoleCapitalized && (
              <span className="tw-block tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden tw-text-center tw-text-xxs tw-text-cool-gray-400">
                {user?.email}
              </span>
            )}
          </div>
          {showButtonLinkToStore && (
            <ButtonLinkToStore
              showOnMobile
              locationsLoading={locationsLoading}
              defaultStores={defaultStores}
              selectedStoreId={selectedStore?.storeId ?? ''}
              className="tw-flex"
            />
          )}
          {isMerchant && <ButtonLinkToSite showOnMobile className="tw-flex" merchantId={merchant.merchantId} />}
        </div>
      </div>
    </>
  );
};
