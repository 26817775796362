/* eslint-disable */
/* export */ function ObjectID() {
  return function () {};
}

exports.ObjectID = ObjectID;

/* export */ function ObjectId() {
  return function () {};
}
exports.ObjectId = ObjectId;
