import React, { useEffect, useCallback, useMemo } from 'react';
import { Modal, Spinner } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import {
  IItemOptions,
  IStoreItemPropertiesItemBaseOverrides,
  IStoreItemProperties,
  IStoreItemGroupModifierGroup,
  ReadStoreItemGroupDto,
  ReadStoreItemDto,
} from '@goparrot/storeitems-sdk';
import { I18nService } from '@goparrot-dashboard/i18n';
import {
  LocationUpdateStoreItemPropertiesParams,
  MerchantUpdateStoreItemPropertiesParams,
  LocationUpdateStoreItemGroupOptionsParams,
  MerchantUpdateStoreItemGroupOptionsParams,
} from '@goparrot-dashboard-api/menu-management';
import { DialogStateReturn } from 'reakit/Dialog';
import { isMerchantLevel } from '../../../utils/isMerchantLevel';
import { useItemOptionChildrensListQuery, useUpdateStoreItemPropertiesQuery, useUpdateStoreItemGroupOptionsQuery } from './hooks';
import { EditModifiersContent } from './EditModifiersContent';
import { SubmitParams, ListItemType } from './interface';

type Props = {
  currentItems: ListItemType[];
  updateLocalList: (payload: ListItemType[]) => void;
  dialog: DialogStateReturn;
  editingModifierGroup: IItemOptions;
  selectedData: ReadStoreItemGroupDto | ReadStoreItemDto;
  selectedIsFetching: boolean;
  onCancel: () => void;
  showingForm: boolean;
  isVariationGroupOptions: boolean;
  isEditingItemReplica: boolean;
};

const EditModifiersModalComponent: React.FC<React.PropsWithChildren<Props>> = ({
  dialog,
  editingModifierGroup,
  onCancel,
  showingForm,
  updateLocalList,
  currentItems,
  selectedData,
  isVariationGroupOptions,
  selectedIsFetching,
  isEditingItemReplica,
}) => {
  const { visible } = dialog;
  const selectedItem = selectedData;
  const isMerchantLevelSelectedItem = isMerchantLevel(selectedItem);
  const isMerchantLevelModifierGroup = isMerchantLevel(editingModifierGroup);
  const {
    merchant: { merchantId },
  } = useStoreService();
  const itemOptionChildrensQuery = useItemOptionChildrensListQuery({
    merchantId,
    storeId: editingModifierGroup.storeId,
    uniqueName: editingModifierGroup.uniqueName,
    isMerchant: isMerchantLevelModifierGroup,
  });

  const onSuccess = (data: ListItemType[]) => {
    const mappedProperties = data;
    updateLocalList(mappedProperties);
    closeModal();
  };

  const { merchantQuery: updatePropertiesMerchantQuery, locationQuery: updatePropertiesLocationQuery } = useUpdateStoreItemPropertiesQuery({
    successCallback: (variables) => {
      onSuccess(variables.data.data);
    },
  });

  const { merchantQuery: updateOptionsMerchantQuery, locationQuery: updateOptionsLocationQuery } = useUpdateStoreItemGroupOptionsQuery({
    successCallback: (variables) => {
      onSuccess(variables.data.data);
    },
  });

  const mappedEditingModifier = useMemo(() => currentItems?.find((item) => item.uid === editingModifierGroup.uniqueName), [
    currentItems,
    editingModifierGroup.uniqueName,
  ]);

  const itemBaseOverrides = useMemo(() => {
    const existingOverrides = currentItems?.find((item) => item.uid === editingModifierGroup.uniqueName)?.itemBaseOverrides;
    if (existingOverrides) {
      return existingOverrides;
    }
    const initialOverrides = (editingModifierGroup.metadata.selections || []).map(
      (selection) => ({ uid: selection.uid, isDisabled: false, ds: { selected: !!selection.ds?.selected } } as IStoreItemPropertiesItemBaseOverrides),
    );
    return initialOverrides;
  }, [currentItems, editingModifierGroup.metadata.selections, editingModifierGroup.uniqueName]);

  const updateItemProperties = useCallback(
    (newItems: IStoreItemProperties[]) => {
      if (isMerchantLevelSelectedItem) {
        const params: MerchantUpdateStoreItemPropertiesParams = { merchantId, uniqueName: selectedItem?.uniqueName, data: { data: newItems } };
        updatePropertiesMerchantQuery.mutate(params);
      } else {
        const params: LocationUpdateStoreItemPropertiesParams = {
          merchantId,
          storeId: selectedItem?.storeId,
          uniqueName: selectedItem?.uniqueName,
          data: { data: newItems },
        };
        updatePropertiesLocationQuery.mutate(params);
      }
    },
    [isMerchantLevelSelectedItem, merchantId, selectedItem?.storeId, selectedItem?.uniqueName, updatePropertiesLocationQuery, updatePropertiesMerchantQuery],
  );

  const updateItemGroupOptions = useCallback(
    (newItems: IStoreItemGroupModifierGroup[]) => {
      if (isMerchantLevelSelectedItem) {
        const params: MerchantUpdateStoreItemGroupOptionsParams = { merchantId, uniqueName: selectedItem?.uniqueName, data: { data: newItems } };
        updateOptionsMerchantQuery.mutate(params);
      } else {
        const params: LocationUpdateStoreItemGroupOptionsParams = {
          merchantId,
          storeId: selectedItem?.storeId,
          uniqueName: selectedItem?.uniqueName,
          data: { data: newItems },
        };
        updateOptionsLocationQuery.mutate(params);
      }
    },
    [isMerchantLevelSelectedItem, merchantId, selectedItem?.storeId, selectedItem?.uniqueName, updateOptionsLocationQuery, updateOptionsMerchantQuery],
  );

  const onSubmit = useCallback(
    (payload: SubmitParams) => {
      const { newOverrides, selectionsMax, selectionsMin } = payload;

      const newItems = currentItems.map((property) => {
        if (property.uid === editingModifierGroup.uniqueName) {
          let newProperty = { ...property };
          newProperty = { ...newProperty, itemBaseOverrides: newOverrides, minSelections: selectionsMin, maxSelections: selectionsMax };

          return newProperty;
        }
        return property;
      });

      if (isVariationGroupOptions) {
        updateItemGroupOptions(newItems);
      } else {
        updateItemProperties(newItems);
      }
    },
    [editingModifierGroup, currentItems, isVariationGroupOptions, updateItemGroupOptions, updateItemProperties],
  );
  useEffect(() => {
    if (!visible) {
      setTimeout(onCancel, 200);
    }
  }, [visible, onCancel]);

  const closeModal = () => {
    dialog.hide();
  };

  const isLoading =
    selectedIsFetching ||
    !editingModifierGroup ||
    itemOptionChildrensQuery.isFetching ||
    updatePropertiesMerchantQuery.isLoading ||
    updatePropertiesLocationQuery.isLoading;

  return (
    <Modal aria-label="Dialog Edit Modifiers" hideOnClickOutside={false} dialog={dialog} maxWidth="tw-max-w-screen-md">
      <Modal.ModalHeaderWithCloseButton onClose={closeModal}>
        <div className="tw-text-lg tw-font-semibold">{editingModifierGroup.title}</div>
      </Modal.ModalHeaderWithCloseButton>
      <Spinner spinning={isLoading}>
        <EditModifiersContent
          mappedEditingModifier={mappedEditingModifier}
          showingForm={showingForm}
          editingModifierGroup={editingModifierGroup}
          closeModal={closeModal}
          itemOptionChildrensQuery={itemOptionChildrensQuery}
          itemBaseOverrides={itemBaseOverrides}
          onSubmit={onSubmit}
          isEditingItemReplica={isEditingItemReplica}
        />
      </Spinner>
    </Modal>
  );
};

export const EditModifiersModal: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <I18nService prefix="page.merchant.menu.item.edit-modifiers.modal">
    <EditModifiersModalComponent {...props} />
  </I18nService>
);
