import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { PAYMENT_QUERIES_ENUM } from '../enums';
import { squareFacade } from '../facades';

export type SquareLocationsListResult = {
  value: {
    locations: SquareLocationType[];
  };
};

export type SquareLocationType = {
  [x: string]: any;
  id: string;
  name: string;
};

export const useSquareLocationsList = (storeId: string, options?: UseQueryOptions<SquareLocationsListResult>): UseQueryResult<SquareLocationsListResult> => {
  return useQuery([PAYMENT_QUERIES_ENUM.SQUARE_LOCATIONS_LIST, storeId], async () => squareFacade.getSquareLocations(storeId), options);
};
