import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { InternalName } from './InternalName';

type Props = {
  internalName: string | React.ReactNode;
  linkTo?: string;
  title: string;
  openNewTab?: boolean;
};

export const TableTitleWrapper: FC<React.PropsWithChildren<Props>> = ({ internalName, linkTo, title, openNewTab }) => {
  return (
    <div className="tw-min-h-9 tw-flex tw-flex-col tw-justify-center">
      {linkTo ? (
        <Link to={linkTo} className="tw-text-light-blue-400 hover:tw-text-blue-gray-900" target={openNewTab ? '_blank' : '_self'}>
          <span className="tw-w-max tw-break-all">{title}</span>
        </Link>
      ) : (
        <span>{title}</span>
      )}
      <InternalName internalName={internalName} />
    </div>
  );
};
