import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { ReadItemBaseMetadataDto } from '@goparrot/storeitems-sdk';
import { FormField, Select } from '@goparrot-dashboard/shared-ui';
import { WeightUnitEnum } from '@goparrot/common';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

export const ItemWeightUnit = <T extends { metadata: ReadItemBaseMetadataDto }>(): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);

  return (
    <FormField
      {...bindFormFieldProps('metadata.weight.unit')}
      label={getMenuFormIntlString('unit')}
      withValuePadding={false}
      input={
        <Select
          {...bindInputProps('metadata.weight.unit')}
          isSearchable={false}
          options={[
            { value: WeightUnitEnum.OZ, label: 'oz' },
            { value: WeightUnitEnum.LB, label: 'lb' },
            { value: WeightUnitEnum.KG, label: 'kg' },
            { value: WeightUnitEnum.G, label: 'grams' },
            { value: WeightUnitEnum.MG, label: 'milligrams' },
          ]}
        />
      }
    />
  );
};
