import type { ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemComboFacade } from '../../../facades';
import { ComboUpdateMutationParams } from '../../../interface';

export const useUpdateMerchantCombo = (
  options?: UseMutationOptions<ReadStoreItemComboDto, unknown, ComboUpdateMutationParams>,
): UseMutationResult<ReadStoreItemComboDto, unknown, ComboUpdateMutationParams> => {
  return useMutation(({ merchantId, uniqueName, data }) => merchantStoreItemComboFacade.patch(merchantId, uniqueName, data), options);
};
