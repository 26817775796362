import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { MarketCheckbox } from '@market/react-bindings';
import { LocationGroupItem } from './utils';
import { StoreSpecificItem } from './constants';

type Props = {
  toggleSelectAllReplica: (replica: StoreSpecificItem[]) => void;
  item: LocationGroupItem;
  selectedReplicas: StoreSpecificItem[];
};

export const LocationGroupCheckbox: FC<React.PropsWithChildren<Props>> = React.memo(({ toggleSelectAllReplica, item, selectedReplicas }) => {
  const [allSelectedState, setAllSelectedState] = useState<boolean | 'indeterminate'>(false);
  const hasSelectedReplicas = item.replicas.some((replica) => selectedReplicas.some((el) => el.storeId === replica.storeId));
  const allReplicasSelected = useMemo(() => {
    const replicaIds = item.replicas.map((replica) => replica.uniqueName);
    const selectedReplicaIds = selectedReplicas.map((selected) => selected.uniqueName);
    return replicaIds.every((el) => selectedReplicaIds.includes(el));
  }, [item.replicas, selectedReplicas]);

  const selectAllOption = useCallback(() => {
    toggleSelectAllReplica(item.replicas as StoreSpecificItem[]);
  }, [item.replicas, toggleSelectAllReplica]);

  useEffect(() => {
    if (allReplicasSelected) {
      setAllSelectedState(true);
    } else if (hasSelectedReplicas) {
      setAllSelectedState('indeterminate');
    } else {
      setAllSelectedState(false);
    }
  }, [allReplicasSelected, hasSelectedReplicas, selectedReplicas.length]);

  return (
    <div className="tw-flex tw-align-center tw-justify-center tw-relative">
      <MarketCheckbox
        indeterminate={allSelectedState === 'indeterminate' || undefined}
        checked={!!allSelectedState || undefined}
        onMarketCheckboxValueChange={selectAllOption}
      />
    </div>
  );
});
