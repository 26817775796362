import { UseQueryResult } from 'react-query';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMerchantItemOptionChildren, useLocationItemOptionChildren } from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';

type Props = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  isMerchant: boolean;
};

export const useItemOptionChildrensListQuery = ({ merchantId, storeId, uniqueName, isMerchant }: Props): UseQueryResult<ReadItemBaseDto[]> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.ITEM_BASE }),
      });
    },
  };

  const locationQuery = useLocationItemOptionChildren(
    { merchantId, storeId, uniqueName },
    {
      ...options,
      enabled: uniqueName && !isMerchant,
      retry: 3,
    },
  );

  const merchantQuery = useMerchantItemOptionChildren(
    { merchantId, uniqueName },
    {
      ...options,
      enabled: uniqueName && isMerchant,
      retry: 3,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
