import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { plainToClass } from 'class-transformer';
import { PatchStoreDto } from '@goparrot/store-v2-sdk';

import aApp from 'store/app/actions';
import { getStores } from 'store/user/selector';

import a from './actions';
import * as api from './api';

function* handleGet({ payload }) {
  try {
    const result = yield call(api.get({ storeIds: payload.stores }));
    yield put(a.successGet(result));
  } catch (e) {
    yield put(a.errorGet());
  }
}

/**
 * @deprecated
 */
function* handleCreate({ payload }) {
  try {
    const store = yield call(api.create(payload));
    yield put(a.successCreate(store));
    const stores = yield select(getStores);
    if (stores) {
      yield put(a.requestGet({ stores }));
    } else {
      yield put(a.requestGet());
    }
    yield put(push('/dashboard/locations/'));
    yield put(aApp.pushNotificationWithSuccess('location.create.success.message', 'location.create.success.description'));
  } catch (e) {
    yield put(a.errorCreate());
  }
}

function* handleUpdate({ payload }) {
  try {
    const data = plainToClass(PatchStoreDto, payload.data, { groups: ['patch'], excludeExtraneousValues: true });
    const result = yield call(api.update(payload.storeId, data));
    yield put(a.successUpdate(result));

    yield put(aApp.pushNotificationWithSuccess('location.update.success.message', 'location.update.success.description'));
  } catch (e) {
    payload.onErrorCallback && payload.onErrorCallback();
    yield put(a.errorUpdate());
    yield put(aApp.pushNotificationWithError('location.update.error.message', 'location.update.error.description'));
  }
}

function* handleDelete({ payload }) {
  try {
    yield call(api.deleteByStoreId(payload));
    yield put(a.successDelete(payload));
    yield put(aApp.pushNotificationWithSuccess('location.delete.success.message', 'location.delete.success.description'));
  } catch (e) {
    yield put(a.errorDelete());
    yield put(aApp.pushNotificationWithError('location.delete.error.message', 'location.delete.error.description'));
  }
}

export default function* () {
  yield takeLatest(a.API__LOCATION__GET__REQUEST, handleGet);
  yield takeLatest(a.API__LOCATION__CREATE__REQUEST, handleCreate);
  yield takeLatest(a.API__LOCATION__UPDATE__REQUEST, handleUpdate);
  yield takeLatest(a.API__LOCATION__DELETE__REQUEST, handleDelete);
}
