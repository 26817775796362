import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { GetRelativesQueryBaseParams } from '../../../interface';

export const useMerchantGetStoreItemParentsQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadCategoryDto[]>,
): UseQueryResult<ReadCategoryDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_STORE_ITEM_GET_PARENTS, merchantId, uniqueName],
    async () => await merchantStoreItemFacade.getParents(merchantId, uniqueName),
    options,
  );
};
