import { PatchMenuConceptDto, ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantMenuConceptsFacade } from '../../../facades';

export type UpdateMenuConceptParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchMenuConceptDto;
};
export const useUpdateMenuConcept = (
  options: UseMutationOptions<ReadMenuConceptDto, unknown, UpdateMenuConceptParams>,
): UseMutationResult<ReadMenuConceptDto, unknown, UpdateMenuConceptParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantMenuConceptsFacade.patch(merchantId, uniqueName, data), options);
};
