import { IRewriteRelationsRequest, UpdateRelationsResponseDto, IStoreItemGroupProperties } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdatePropertiesStoreItemGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemGroupProperties>;
};

export type LocationUpdatePropertiesStoreItemGroupResult = UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdatePropertiesStoreItemGroupParams>;

export const useLocationUpdatePropertiesStoreItemGroup = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdatePropertiesStoreItemGroupParams>,
): LocationUpdatePropertiesStoreItemGroupResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationStoreItemGroupFacade.updateProperties(merchantId, storeId, uniqueName, data),
    options,
  );
};
