import { WebStoreFacade } from '@goparrot/webstore-sdk';

import { getInterceptors, getConfig } from '@goparrot-dashboard-api/shared';

const facade = new WebStoreFacade(getConfig('webstore'), getInterceptors());

export const getWebStoreUrls = (storeId: string) =>
  function* () {
    return yield facade.urls(storeId);
  };
