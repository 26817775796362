import set from 'lodash/set';
import merge from 'lodash/merge';

export const errorReducer = (errStack, { path, children, ...errorItem }) => {
  const errors = merge(errStack, set({}, path, errorItem));
  if (children?.length) {
    return merge(errors, { [path]: children.reduce((acc, child) => errorReducer(acc, child), {}) });
  }
  return errors;
};
