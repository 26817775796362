import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IPagination, IStoreFilterParams, StoreItemComboListResponseDto } from '@goparrot/storeitems-sdk';
import { locationStoreItemComboFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  storeId: string;
  pagination?: IPagination;
  params?: IStoreFilterParams;
};

export const useLocationComboListQuery = (
  { merchantId, storeId, pagination, params }: GetStoreItemsParams,
  options?: UseQueryOptions<StoreItemComboListResponseDto, Error>,
): UseQueryResult<StoreItemComboListResponseDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_COMBOS_LIST, merchantId, storeId, pagination, params],
    () => locationStoreItemComboFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
