import { ICloneRequest, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseFacade } from '../../../facades';

export type LocationCloneItemBaseParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useLocationCloneItemBaseSet = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, LocationCloneItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, LocationCloneItemBaseParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseFacade.clone(merchantId, storeId, uniqueName, data), options);
};
