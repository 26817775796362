import React, { useRef, useState } from 'react';
import { CanCopyAndShowTooltip, CopyToClipboard } from 'components/commons/utility/CopyToClipboard';
import { getIntlString } from '@goparrot-dashboard/i18n';
import {
  Button,
  IconCloseThin,
  IconCopyOutlined,
  ImageUploadMenuSmall,
  useNotifications,
  Select,
  Spinner,
  OutlineSelectComponents,
} from '@goparrot-dashboard/shared-ui';
import classNames from 'classnames';
import { getOptimizedImageUrl, getRectImageConfig } from '@goparrot-dashboard/shared-utils';
import { components, ControlProps, IndicatorProps, SingleValueProps } from 'react-select';
import type { SelectOptionType } from '@goparrot-dashboard/shared-ui/src/components/select/types';
import { onDefaultImageUpload } from 'helpers/utility';

import { useMenuFormContext } from '../../../context';
import { ReturnValue } from '../interface';
import './SelectImageUploader.css';

const imageParams = getRectImageConfig(2048, 2048, 37);

export type DataProps = {
  disabled: boolean;
  title: string;
  folder: string;
  field: string;
  info: string;
  imgUrl: string;
};

type Props = {
  data: DataProps[];
  description?: string;
  folder: string;
  disabled?: boolean;
};

const Control: React.FC<React.PropsWithChildren<ControlProps<SelectOptionType, true | false>>> = ({ children, isFocused, isDisabled, ...props }) => {
  return (
    <components.Control
      {...props}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={classNames([
        'tw-h-9 tw-min-h-full tw-outline-none tw-w-full tw-border-none tw-rounded-md tw-shadow-none tw-text-white',
        isDisabled ? 'tw-bg-light-blue-300' : 'tw-bg-light-blue-400',
      ])}
    >
      {children}
    </components.Control>
  );
};

const DropdownIndicator: React.FC<React.PropsWithChildren<IndicatorProps<SelectOptionType, true | false>>> = (props) => {
  return (
    <components.DropdownIndicator {...props} className="tw-border-l tw-border-white-100 tw-px-4">
      <div>
        <svg className="tw-text-white" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.6" d="M1 1L4.49138 4L7.75 1" stroke="currentColor" strokeWidth="1.5" />
        </svg>
      </div>
    </components.DropdownIndicator>
  );
};

const SingleValue: React.FC<React.PropsWithChildren<unknown>> = ({ children, ...props }: SingleValueProps<SelectOptionType>) => (
  <components.SingleValue {...props} className="tw-text-white tw-font-medium tw-h-full tw-flex tw-items-center">
    {children}
  </components.SingleValue>
);

export const SelectImageUploader = <T,>({ description, disabled = false, data }: Props): ReturnValue => {
  const copyToClipboardRef = useRef<CanCopyAndShowTooltip>();
  const [isLoadingUpload, toggleLoading] = useState<boolean>(false);
  const selectImageOptions = data?.map((item) => ({ value: item.title, label: item.title })) || [];
  const { form, merchantId, storeId } = useMenuFormContext<T>();
  const { notifyError, notifySuccess } = useNotifications();

  const handleDelete = (fieldName: string) => {
    form.setFieldValue(fieldName, undefined);
  };

  const onSelectChange = async (value) => {
    const selectItem = data.find((item) => item.title === value);
    toggleLoading(true);
    try {
      // @ts-ignore cloudinary widget types
      const { files, widget, isCloseEvent } = await onDefaultImageUpload(imageParams, selectItem.folder, merchantId, storeId);
      if (isCloseEvent && !files) {
        return;
      }
      notifySuccess({ text: getIntlString('image.upload.success') });
      widget.destroy();

      const [{ uploadInfo }] = files;
      const optimizedImageUrl = getOptimizedImageUrl(uploadInfo?.secure_url);
      form.setFieldValue(selectItem.field, optimizedImageUrl);
    } catch {
      notifyError({ text: getIntlString('image.upload.error') });
    } finally {
      toggleLoading(false);
    }
  };

  React.useEffect(() => {
    if (window.cloudinary) {
      window.cloudinary.setCloudName(window._env_.CLOUDINARY_CLOUD_NAME);
    }
  }, []);

  const handleCopy = (content: string) => copyToClipboardRef?.current?.copyAndShowTooltip(content);

  return (
    <div className="tw-w-full">
      <Spinner spinning={isLoadingUpload} className="tw-border tw-border-cool-gray-200 tw-rounded-md tw-py-6 tw-px-4">
        {description && <p className="tw-text-cool-gray-700 tw-font-medium">{description}</p>}
        <div className={classNames(['tw-w-28 tw-mb-4', disabled && 'tw-cursor-not-allowed'])}>
          <Select
            overrideComponents={{
              ...OutlineSelectComponents,
              Control,
              DropdownIndicator,
              SingleValue,
            }}
            isSearchable={false}
            className="tw-border-0 tw-rounded-md"
            value="Upload"
            options={selectImageOptions}
            onChange={onSelectChange}
            disabled={disabled}
          />
        </div>
        <div className="tw-flex">
          {data?.map((item) => {
            return (
              <div className="tw-mr-3 last:tw-mr-0" key={item.title}>
                <ImageUploadMenuSmall className="tw-w-16" title={item.title} imgUrl={item.imgUrl}>
                  <div className="tw-flex tw-space-x-1 tw-absolute tw--top-2 tw--right-2.5">
                    <CopyToClipboard ref={copyToClipboardRef}>
                      <Button
                        color="link"
                        rounded="full"
                        kind="clean"
                        className="tw-p-1 tw-w-5 tw-shadow-sm tw-bg-gray-100 focus:tw-ring-opacity-0"
                        onClick={() => handleCopy(item.imgUrl)}
                      >
                        <IconCopyOutlined width={10} height={12} className="tw-text-blue-gray-900" />
                      </Button>
                    </CopyToClipboard>

                    <Button
                      color="link"
                      rounded="full"
                      kind="clean"
                      className="tw-p-1 tw-w-5 tw-shadow-sm tw-bg-gray-100 focus:tw-ring-opacity-0"
                      onClick={() => handleDelete(item.field)}
                      disabled={disabled}
                    >
                      <IconCloseThin width={8} height={8} className="tw-text-blue-gray-900" />
                    </Button>
                  </div>
                </ImageUploadMenuSmall>
              </div>
            );
          })}
        </div>
      </Spinner>
    </div>
  );
};
