import type { UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemComboFacade } from '../../../facades';
import { UpdateConnectionMutationBaseParams } from '../../../interface';

export const useUpdateMerchantComboItemSets = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, UpdateConnectionMutationBaseParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, UpdateConnectionMutationBaseParams> => {
  return useMutation(({ merchantId, uniqueName, payload }) => merchantStoreItemComboFacade.updateItemSets(merchantId, uniqueName, payload), options);
};
