import * as React from 'react';
import { Layout } from 'antd';
import classNames from 'classnames';
import { Sidebar, GlobalAppProvider, useGlobalAppContext, NavBar } from '@goparrot-dashboard/app';
import { MarketContextProvider, Spinner } from '@goparrot-dashboard/shared-ui';
import { setDocTitle } from '@goparrot-dashboard/shared-utils';
import { StoreServiceProvider, useStoreService } from '@goparrot-dashboard-core/store-service';
import { UserServiceProvider } from '@goparrot-dashboard-core/user-service';
import { MerchantSetupProvider } from '@goparrot-dashboard-shared/merchant-setup';
import styles from './styles/app.module.scss';

const { Content } = Layout;

const App = ({ children }) => {
  const { isSidebarVisible } = useGlobalAppContext();
  const { isMerchantFetching } = useStoreService();

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || screen.width <= 768) {
      window.scrollTo(0, 0);
    }

    if (window.cloudinary) {
      window.cloudinary.setCloudName(window._env_.CLOUDINARY_CLOUD_NAME);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Layout className={classNames(['tw-h-screen tw-fixed tw-left-0 tw-right-0 tw-top-0 tw-bottom-0', styles['app-layout']])}>
        <NavBar />
        <Layout className="tw-flex-row">
          {isSidebarVisible && <Sidebar />}
          <Layout className={classNames(['tw-h-screen tw-flex-1', styles['main-layout'], isSidebarVisible ? 'tw-overflow-auto' : ''])}>
            <Content className={classNames([styles.content, !isSidebarVisible && 'tw-flex tw-justify-center'])}>
              {isMerchantFetching ? <Spinner isMarketComponent spinning size="small" className="tw-pt-8" backgroundTransparency="transparent" /> : children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export const AppWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  React.useEffect(() => {
    setDocTitle('Dashboard');
  }, []);

  return (
    <StoreServiceProvider>
      <UserServiceProvider>
        <MerchantSetupProvider>
          <GlobalAppProvider>
            <MarketContextProvider>
              <App>{children}</App>
            </MarketContextProvider>
          </GlobalAppProvider>
        </MerchantSetupProvider>
      </UserServiceProvider>
    </StoreServiceProvider>
  );
};
