import { WebStoreUrlsDto } from '@goparrot/webstore-sdk';
import { AnyAction } from 'redux';

import { WebStoreActions } from './actions';

export interface WebstoreState {
  webStoreUrls: WebStoreUrlsDto | null;
  fetching: boolean;
  error: Error | null;
}

const initialState: WebstoreState = {
  webStoreUrls: null,
  fetching: false,
  error: null,
};

export default function (state: WebstoreState = initialState, action: AnyAction): WebstoreState {
  switch (action.type) {
    case WebStoreActions.API__GET__WEB__STORE__URLS__REQUEST:
      return { ...state, fetching: true, webStoreUrls: null };
    case WebStoreActions.API__GET__WEB__STORE__URLS__SUCCESS:
      return { ...state, webStoreUrls: action.payload, fetching: false };
    case WebStoreActions.API__GET__WEB__STORE__URLS__ERROR:
      return { ...state, error: new Error(action.payload), fetching: false };
    default:
      return state;
  }
}
