import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { storeByMerchantFacade } from '../facades';

type DeleteStoreParams = {
  merchantId: string;
  storeId: string;
};

export const useDeleteStore = (
  options?: UseMutationOptions<void, unknown, DeleteStoreParams, unknown>,
): UseMutationResult<void, unknown, DeleteStoreParams, unknown> => {
  return useMutation(({ merchantId, storeId }) => storeByMerchantFacade.delete(merchantId, storeId), options);
};
