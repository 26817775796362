import { UpdateMenuConceptItemSetsDto, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationMenuConceptsFacade } from '../../../facades';

type UpdateLocationMenuConceptItemSetsParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: UpdateMenuConceptItemSetsDto[];
};
export const useUpdateLocationMenuConceptItemSets = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, UpdateLocationMenuConceptItemSetsParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, UpdateLocationMenuConceptItemSetsParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationMenuConceptsFacade.updateItemSets(merchantId, storeId, uniqueName, data),
    options,
  );
};
