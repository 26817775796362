import { SupportedCurrencyEnum } from '@goparrot/common';
import { classToPlain } from 'class-transformer';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ValidationStrategyEnum } from '../../enums';
import { ChangeFieldMethod, EntityFormResult, MethodWithPathOrField, RecursivePartial } from '../../types';
import { errorReducer, getValidationOptions, isProductionEnv, transformAndValidateEntitySync, diff } from '../../utils';
import { useFormDirty } from './useFormDirty';
import { useFormDisabled } from './useFormDisabled';
import { useFormErrors } from './useFormErrors';

const getTransformed = <T>(data: RecursivePartial<T>, Entity: any, validationOptions, extraFields: FormExtraFields): RecursivePartial<T> => {
  if (!isEmpty(extraFields)) {
    return classToPlain(new Entity().init({ ...data, extraFields }), validationOptions.validator) as RecursivePartial<T>;
  }
  return classToPlain(new Entity().init(data), validationOptions.validator) as RecursivePartial<T>;
};
export type FormExtraFields = {
  isMultiEntityType?: boolean;
  currency?: SupportedCurrencyEnum;
};

export type EntityFormParams<T> = {
  initialState: RecursivePartial<T>;
  model: Record<string, any>;
  validationGroups?: string[];
  validationStrategy?: ValidationStrategyEnum;
  preventPatchValidation?: boolean;
  extraFields?: FormExtraFields;
  customCheckCallback?: (updatedFields: Record<string, any>, state: RecursivePartial<T>) => Record<string, any>;
};

export function useEntityForm<T>({
  initialState,
  model,
  validationGroups,
  validationStrategy = ValidationStrategyEnum.onChange,
  preventPatchValidation = false,
  extraFields,
  customCheckCallback,
}: EntityFormParams<T>): EntityFormResult<T> {
  // extract validation options
  const validationOptions = useMemo(() => getValidationOptions(validationGroups), [validationGroups]);
  const initialStateTransformed = useMemo(() => getTransformed(initialState, model, validationOptions, extraFields), [
    initialState,
    model,
    validationOptions,
    extraFields,
  ]);

  // main state
  const [state, setState] = useState<RecursivePartial<T>>(initialStateTransformed);
  const getFieldValue: MethodWithPathOrField<T, any> = useCallback((path) => get(state, path), [state]);

  // error operations
  const errorsUtils = useFormErrors<T>();

  // dirty states
  const dirtyUtils = useFormDirty<T>();

  // disabled utils
  const disabledUtils = useFormDisabled<T>();

  // validate the form and set all the necessary errors
  const validateForm = useCallback(
    (data: RecursivePartial<T>): boolean => {
      try {
        if (validationGroups?.includes('patch') && !preventPatchValidation) {
          let updatedFields = diff(data, initialStateTransformed);
          if (customCheckCallback) {
            updatedFields = customCheckCallback(updatedFields, state);
          }
          transformAndValidateEntitySync(model, updatedFields, validationOptions);
        } else {
          transformAndValidateEntitySync(model, data, validationOptions);
        }
        errorsUtils.resetErrors();
        return true;
      } catch (errors) {
        if (!Array.isArray(errors)) {
          if (!isProductionEnv()) {
            console.warn('Unhandled errors: ', errors);
          }
        } else {
          const parsedError = errors.reduce(errorReducer, {});
          errorsUtils.setErrorState(parsedError);
        }
        return false;
      }
    },
    [errorsUtils, initialStateTransformed, model, preventPatchValidation, validationGroups, validationOptions],
  );

  const setFieldValue: ChangeFieldMethod<T> = useCallback(
    (path, value): void => {
      setState((prevState) => {
        const updatedState: typeof state = set(cloneDeep(prevState), path, value);
        const transformedState = getTransformed(updatedState, model, validationOptions, extraFields);
        switch (validationStrategy) {
          case ValidationStrategyEnum.onChange: {
            dirtyUtils.setDirtyField(path);
            validateForm(updatedState);
            break;
          }
          case ValidationStrategyEnum.onBlur:
          case ValidationStrategyEnum.onSave: {
            if (dirtyUtils.isDirtyForm) {
              validateForm(updatedState);
            }
            break;
          }
        }
        return transformedState;
      });
    },
    [dirtyUtils, model, validateForm, validationOptions, validationStrategy, extraFields],
  );

  const onFieldBlur: MethodWithPathOrField<T, void> = useCallback(
    (path) => {
      if (validationStrategy === ValidationStrategyEnum.onBlur) {
        dirtyUtils.setDirtyField(path);
        validateForm(state);
        return;
      }
      if (validationStrategy === ValidationStrategyEnum.onSave && dirtyUtils.isDirtyField(path)) {
        validateForm(state);
      }
    },
    [dirtyUtils, validateForm, validationStrategy, state],
  );

  // reset the form to initial state
  const reset = useCallback((): void => {
    setState(initialStateTransformed);
    dirtyUtils.setIsDirtyForm(false);
    dirtyUtils.setDirtyState({});
  }, [dirtyUtils, initialStateTransformed]);

  // submit the form asynchronous
  const submitOrFail = useCallback((): T | never => {
    dirtyUtils.setIsDirtyForm(true);
    if (validateForm(state)) {
      if (validationGroups?.includes('patch') && !preventPatchValidation) {
        let updatedFields = diff(state, initialStateTransformed);
        if (customCheckCallback) {
          updatedFields = customCheckCallback(updatedFields, state);
        }
        transformAndValidateEntitySync(model, updatedFields, validationOptions) as unknown;
        return state as T;
      } else {
        return (transformAndValidateEntitySync(model, state, validationOptions) as unknown) as T;
      }
    }
    throw new Error();
  }, [dirtyUtils, initialStateTransformed, model, preventPatchValidation, state, validateForm, validationGroups, validationOptions]);

  // submit the form
  const onSubmit = useCallback(
    (onSuccess: (payload: T) => void = () => null): void => {
      try {
        const data = submitOrFail();
        onSuccess(data);
      } catch (e) {
        if (!isProductionEnv()) {
          console.warn('Unhandled errors: ', e);
        }
      }
    },
    [submitOrFail],
  );

  useEffect(() => {
    validateForm(state);
    // we need to re-validate form only when state changes
    // also adding validateForm causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getTransformedWrapped = useCallback((state: any, model: any) => getTransformed(state, model, validationOptions, extraFields), [
    validationOptions,
    extraFields,
  ]);

  return {
    // main
    state,
    initialState,
    initialStateTransformed,
    isFormValid: !Object.keys(errorsUtils.errors).length,
    isFormTouched: !isEqual(initialStateTransformed, state),
    getFieldValue,
    setState,
    onSubmit,
    submitOrFail,
    setFieldValue,
    onFieldChange: setFieldValue,
    onFieldBlur,
    reset,
    getTransformed: getTransformedWrapped,
    // disabled state
    isDisabledForm: disabledUtils.isDisabledForm,
    disabledFields: disabledUtils.disabledFieldsState,
    isDisabledField: disabledUtils.isDisabledField,
    setIsDisabledForm: disabledUtils.setIsDisabledForm,
    setDisabledState: disabledUtils.setDisabledState,
    setDisabledField: disabledUtils.setDisabledField,
    // dirty state
    isDirtyForm: dirtyUtils.isDirtyForm,
    dirtyFields: dirtyUtils.dirtyFieldsState,
    isDirtyField: dirtyUtils.isDirtyField,
    setIsDirtyForm: dirtyUtils.setIsDirtyForm,
    setDirtyState: dirtyUtils.setDirtyState,
    setDirtyField: dirtyUtils.setDirtyField,
    // errors state
    errors: errorsUtils.errors,
    getError: errorsUtils.getError,
    hasError: errorsUtils.hasError,
    setErrorState: errorsUtils.setErrorState,
    resetErrors: errorsUtils.resetErrors,
    setErrorField: errorsUtils.setErrorField,
  };
}
