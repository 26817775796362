import { LocationGroupRequestPaginationDto, ListLocationGroupsDto } from '@goparrot/location-group-sdk';
import { useQuery, UseQueryOptions, UseQueryResult, QueryKey } from 'react-query';
import { LOCATION_GROUP_QUERIES_ENUM } from '../enums';
import { locationGroupFacade } from '../facades';

export const useLocationGroupList = (
  merchantId: string,
  pagination: LocationGroupRequestPaginationDto,
  options?: UseQueryOptions<ListLocationGroupsDto[], unknown, ListLocationGroupsDto, QueryKey>,
): UseQueryResult<ListLocationGroupsDto> => {
  return useQuery([LOCATION_GROUP_QUERIES_ENUM.LOCATION_GROUP_LIST, merchantId, pagination], () => locationGroupFacade.list(merchantId, pagination), options);
};
