import React, { FC } from 'react';
import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { Select, Button, BaseSelectComponents } from '@goparrot-dashboard/shared-ui';
import type { SelectOptionType, SelectValueType } from '@goparrot-dashboard/shared-ui/src/components/select/types';
import { components, ValueContainerProps } from 'react-select';
import { getIntlString } from '@goparrot-dashboard/i18n';

interface Props {
  itemSets?: ReadItemSetDto[];
  disabled?: boolean;
  onBlur?: () => void;
  value?: SelectValueType[];
  onChange: (value: string[]) => void;
  isSearchable?: boolean;
}

const getSelectedLabel = (options: SelectOptionType[]) => getIntlString('menuSelector.multiSelection.label', { count: options?.length ?? 0 });

export const MenuSelect: React.FC<React.PropsWithChildren<Props>> = ({ value, onChange, itemSets = [], onBlur, disabled, isSearchable }) => {
  const handleSelectChange = (selectedLocations: string[]) => {
    return onChange(selectedLocations);
  };

  const options = itemSets.map((itemSet: ReadItemSetDto) => ({
    value: itemSet.uniqueName,
    label: itemSet.title,
  }));

  return (
    <div className="tw-relative tw-w-full">
      <div className="tw-absolute tw-z-1 tw-right-0 tw-bottom-2 tw-flex tw-items-center">
        {!!value?.length && !disabled && (
          <Button color="accent" className="tw-h-8 tw-mr-2" onClick={() => handleSelectChange([])}>
            {getIntlString('store.selector.clear')}
          </Button>
        )}
      </div>
      <Select
        overrideComponents={{
          ...BaseSelectComponents,
          ValueContainer,
        }}
        isMulti
        variant="base"
        disabled={disabled}
        placeholder={getIntlString('menuSelector.placeholder')}
        value={value}
        onChange={handleSelectChange}
        onBlur={onBlur}
        options={options}
        menuShouldBlockScroll
        renderMultiValueLabel={getSelectedLabel}
        closeMenuOnSelect={false}
        isClearable={false}
        isSearchable={isSearchable}
        menuPosition="fixed"
      />
    </div>
  );
};

const ValueContainer: FC<React.PropsWithChildren<ValueContainerProps<SelectOptionType, true | false>>> = ({ children, ...props }) => {
  if (props.isMulti && props.getValue()?.length > 0) {
    const { renderMultiValueLabel, selectedValuesMaxCount } = props?.selectProps;
    const [chips, element] = children as any;
    // this allows us to display a given {selectedValuesMaxCount} number of selected options
    const displayChips =
      selectedValuesMaxCount !== undefined && selectedValuesMaxCount < chips.length
        ? [chips.slice(chips.length - selectedValuesMaxCount, chips.length), element]
        : children;
    const hasMoreStores = chips.length > selectedValuesMaxCount;
    return (
      <div className="tw-text-light-blue-400 tw-px-3 tw-flex-1">
        <div className="tw-border-b tw-border-cool-gray-200 tw-py-3 tw-mb-2">{renderMultiValueLabel(props.getValue())}</div>
        <components.ValueContainer {...props} className="tw-bg-transparent tw-pl-0 tw-pr-24 tw-pt-0">
          {displayChips}
          {hasMoreStores && <span className="tw-text-blue-gray-900 tw-pt-2.5 tw-mr-32">...</span>}
        </components.ValueContainer>
      </div>
    );
  }
  return (
    <components.ValueContainer {...props} className="tw-bg-transparent tw-px-3 tw-text-light-blue-400">
      {children}
    </components.ValueContainer>
  );
};
