import { IUpdateRelationsResponse, IUpdateRelationsRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseFacade } from '../../../facades';

type LocationUpdateItemBaseOptionSelectionParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};

export const useLocationUpdateItemBaseOptionSelection = (
  options?: UseMutationOptions<IUpdateRelationsResponse, unknown, LocationUpdateItemBaseOptionSelectionParams>,
): UseMutationResult<IUpdateRelationsResponse, unknown, LocationUpdateItemBaseOptionSelectionParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemBaseFacade.updateItemOptionsSelections(merchantId, storeId, uniqueName, data),
    options,
  );
};
