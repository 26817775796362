import a from '../actions';

const initialState = {
  data: {},
  error: false,
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDER__THROTTLING__CREATE__REQUEST:
    case a.API__GET__THROTTLING__CONFIG__REQUEST:
    case a.API__UPDATE__THROTTLING__CONFIG__REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case a.API__ORDER__THROTTLING__CREATE__SUCCESS:
    case a.API__GET__THROTTLING__CONFIG__SUCCESS:
    case a.API__UPDATE__THROTTLING__CONFIG__SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
      };

    case a.API__ORDER__THROTTLING__CREATE__ERROR:
    case a.API__GET__THROTTLING__CONFIG__ERROR:
    case a.API__UPDATE__THROTTLING__CONFIG__ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
      };

    case a.API__THROTTLING__CONFIG__RESET:
      return initialState;

    default:
      return state;
  }
}
