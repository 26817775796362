import { ReadLocationGroupDto } from '@goparrot/location-group-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { LOCATION_GROUP_QUERIES_ENUM } from '../enums';
import { locationGroupFacade } from '../facades';

export const useGetLocationGroup = (
  merchantId: string,
  uuid: string,
  options?: UseQueryOptions<ReadLocationGroupDto, unknown, ReadLocationGroupDto>,
): UseQueryResult<ReadLocationGroupDto> => {
  return useQuery([LOCATION_GROUP_QUERIES_ENUM.LOCATION_GROUP, merchantId, uuid], () => locationGroupFacade.get(merchantId, uuid), options);
};
