import * as React from 'react';
import { useFormFieldProps, useNativeInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, Input } from '@goparrot-dashboard/shared-ui';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

type Props = {
  label?: string | React.ReactNode;
  placeholder?: string;
};

export const ItemInternalName = <T extends { internalName: string }>({ placeholder, label }: Props): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindNativeInputProps = useNativeInputProps(form);

  return (
    <FormField
      {...bindFormFieldProps('internalName')}
      label={label ?? getMenuFormIntlString('internalName')}
      withValuePadding={false}
      input={
        <Input
          {...bindNativeInputProps('internalName')}
          name="internal name"
          className="tw-px-4"
          placeholder={placeholder ?? getMenuFormIntlString('internalName.placeholder')}
        />
      }
    />
  );
};
