import React, { FC } from 'react';
import { MarketInputText } from '@market/react-bindings';
import { getIntlString } from '@goparrot-dashboard/i18n';

type Props = {
  initialValue: string;
  handleChange: (val: string) => void;
};

export const InputReplicaTitle: FC<React.PropsWithChildren<Props>> = React.memo(({ initialValue, handleChange }) => {
  const [value, setValue] = React.useState(initialValue);

  // if the initialValue is changed external, sync it up with out data
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <MarketInputText
      value={value}
      invalid={!value?.length || undefined}
      placeholder={getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.title.placeholder')}
      onMarketInputValueChange={(e) => {
        setValue(e.detail.value);
        handleChange(e.detail.value);
      }}
    />
  );
});
