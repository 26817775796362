import { PatchAbstractModsDto, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type PatchItemBaseGroupModsParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchAbstractModsDto;
};

export const usePatchItemBaseGroupMods = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, PatchItemBaseGroupModsParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, PatchItemBaseGroupModsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemBaseGroupFacade.patchMod(merchantId, uniqueName, data), options);
};
