import { createAction } from 'helpers/create-action';

const c = {
  API__USER__CREATE__SUCCESS: 'API__USER__CREATE__SUCCESS',

  API__USER__UPDATE__REQUEST: 'API__USER__UPDATE__REQUEST',
  API__USER__UPDATE__SUCCESS: 'API__USER__UPDATE__SUCCESS',
  API__USER__UPDATE__ERROR: 'API__USER__UPDATE__ERROR',

  API__USER__GET__PERMISSIONS__REQUEST: 'API__USER__GET__PERMISSIONS__REQUEST',
  API__USER__GET__PERMISSIONS__SUCCESS: 'API__USER__GET__PERMISSIONS__SUCCESS',
  API__USER__GET__PERMISSIONS__ERROR: 'API__USER__GET__PERMISSIONS__ERROR',

  API__USER__LIST_BY_MERCHANT__REQUEST: 'API__USER__LIST_BY_MERCHANT__REQUEST',
  API__USER__LIST_BY_MERCHANT__SUCCESS: 'API__USER__LIST_BY_MERCHANT__SUCCESS',
  API__USER__LIST_BY_MERCHANT__ERROR: 'API__USER__LIST_BY_MERCHANT__ERROR',

  API__USER__GET__ROLE__V2__REQUEST: 'API__USER__GET__ROLE__V2__REQUEST',
  API__USER__GET__ROLE__V2__SUCCESS: 'API__USER__GET__ROLE__V2__SUCCESS',
  API__USER__GET__ROLE__V2__ERROR: 'API__USER__GET__ROLE__V2__ERROR',

  LOCAL__USER__CLEAR: 'LOCAL__USER__CLEAR',
  LOCAL__USER__SET_STORE_ID: 'LOCAL__USER__SET_STORE_ID',
};

const a = {
  successCreate: createAction(c.API__USER__CREATE__SUCCESS),

  requestUpdate: createAction(c.API__USER__UPDATE__REQUEST),
  successUpdate: createAction(c.API__USER__UPDATE__SUCCESS),
  errorUpdate: createAction(c.API__USER__UPDATE__ERROR),

  requestPermissions: createAction(c.API__USER__GET__PERMISSIONS__REQUEST),
  successPermissions: createAction(c.API__USER__GET__PERMISSIONS__SUCCESS),
  errorPermissions: createAction(c.API__USER__GET__PERMISSIONS__ERROR),

  requestListByMerchant: createAction(c.API__USER__LIST_BY_MERCHANT__REQUEST),
  successListByMerchant: createAction(c.API__USER__LIST_BY_MERCHANT__SUCCESS),
  errorListByMerchant: createAction(c.API__USER__LIST_BY_MERCHANT__ERROR),

  clearState: createAction(c.LOCAL__USER__CLEAR),
  setStoreId: createAction(c.LOCAL__USER__SET_STORE_ID),

  requestRoleV2: createAction(c.API__USER__GET__ROLE__V2__REQUEST),
  successRoleV2: createAction(c.API__USER__GET__ROLE__V2__SUCCESS),
  errorRoleV2: createAction(c.API__USER__GET__ROLE__V2__ERROR),
};

export default { ...c, ...a };
