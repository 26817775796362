import { ChildrenEntitiesDtoType } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemOptionsFacade } from '../../facades';

type LocationItemOptionParentParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationItemOptionParents = (
  { merchantId, storeId, uniqueName }: LocationItemOptionParentParams,
  options?: UseQueryOptions<ChildrenEntitiesDtoType[]>,
): UseQueryResult<ChildrenEntitiesDtoType[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_OPTION_PARENTS_LIST, merchantId, storeId, uniqueName],
    async () => await locationItemOptionsFacade.getParents(merchantId, storeId, uniqueName),
    options,
  );
};
