import React, { useState, useEffect, ReactElement } from 'react';
import { Button, IconPlusOutlined } from '@goparrot-dashboard/shared-ui';
import { I18nService, useI18n } from '@goparrot-dashboard/i18n';
import { PanelTitleWithAction } from '@goparrot-dashboard/shared-ui/src/components';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { DropResult } from 'react-beautiful-dnd';
import { ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { UseQueryResult } from 'react-query';
import { StoreItemGroupChildrenType } from '@goparrot-dashboard-api/menu-management';
import { MarketButton } from '@market/react-bindings';
import { ConnectionItem, Item } from '../../interface';
import { UpdateCategoryActionsType } from '../../hooks';
import { filterAddConnectionsListBySearchQuery, isArchived } from '../../utils';
import { AddConnectionsModal, PreviewConnectionComponentType } from './AddConnectionsModal';
import type { PreviewConnection } from './AddConnectionsModal/preview/interface';
import { ConnectionsTable } from './ConnectionsTable';
import { ConnectionsContextType, ConnectionsProvider } from './context';
import { BaseSelectedConnectionType, ConnectionsTableComponentType, StoreSpecificSettingsOpenerType } from './interface';
import { ShowArchivedConnectionsButton } from './ShowArchivedConnectionsButton';

type Props<T, S> = {
  isMerchantLevelItem: boolean;
  /** storeSpecificSettingsOpener is used to show the price editor **/
  storeSpecificSettingsOpener?: StoreSpecificSettingsOpenerType | null;
  selectedLevelStoreId: string;
  isLoadingTable: boolean;
  disabled: boolean;
  addConnectionTitle?: string;
  title?: string;
  subtitle?: React.ReactNode | string;
  storeId: string;
  merchantId: string;
  data: ConnectionItem[];
  itemsList: ConnectionItem[];
  previewConnections?: PreviewConnection[];
  isUpdatingConnection?: boolean;
  onUpdateConnections: (connections: (T | string)[]) => void;
  onChangeConnectionsTable?: (connections: (S | Partial<S>)[]) => void;
  previewConnectionsComponent?: PreviewConnectionComponentType;
  connectionsTableComponent?: ConnectionsTableComponentType<S>;
  showType?: boolean;
  emptyTablePlaceholder?: string;
  transformToState?(connection: ConnectionItem): T;
  isDraggable?: boolean;
  handleDragAndDrop?: (result: DropResult) => void;
  isItemReplicaConnections?: boolean;
  canEditFranchiseConnections?: boolean;
  showConnectionSecondButton?: boolean;
  hideConnectionMainButton?: boolean;
  connectionSecondButtonTitle?: string;
  handleSecondButtonAction?: () => void;
  isSecondButtonDisabled?: boolean;
  typedPreviewConnections?: boolean;
  actions?: ReactElement[] | ReactElement;
  hideButtonDelete?: boolean;
  /** If true, the table will be hidden and only one button will be shown, when there are no connections **/
  emptyTableToSingleButton?: boolean;
  /** Sets the tilte on the button that will be shown when  emptyTableToSingleButton prop is true **/
  emptyTableToSingleButtonTilte?: string;
  viewArchived?: boolean;
  updateCategoryActions?: UpdateCategoryActionsType;
  variationParentQuery?: UseQueryResult<ReadStoreItemGroupDto[]>;
  variationsListQuery?: UseQueryResult<StoreItemGroupChildrenType[]>;
};

const defaultTransform = ({ uniqueName }: ConnectionItem) => ({ uniqueName });

const ConnectionsComponent = <T extends BaseSelectedConnectionType, S>({
  title = '',
  subtitle,
  selectedLevelStoreId,
  isLoadingTable,
  data,
  itemsList,
  previewConnections,
  onUpdateConnections,
  onChangeConnectionsTable,
  disabled,
  merchantId,
  storeId,
  showType,
  emptyTablePlaceholder,
  previewConnectionsComponent,
  connectionsTableComponent: CustomConnectionsTable,
  transformToState,
  addConnectionTitle,
  storeSpecificSettingsOpener = null,
  isDraggable = false,
  isItemReplicaConnections = false,
  canEditFranchiseConnections = false,
  showConnectionSecondButton = false,
  hideConnectionMainButton = isItemReplicaConnections ? !canEditFranchiseConnections : disabled,
  connectionSecondButtonTitle = 'Create and Add',
  handleDragAndDrop,
  handleSecondButtonAction,
  isMerchantLevelItem,
  hideButtonDelete,
  actions,
  emptyTableToSingleButton = false,
  emptyTableToSingleButtonTilte = 'Connect',
  typedPreviewConnections = false,
  isUpdatingConnection,
  viewArchived,
  updateCategoryActions,
  variationParentQuery,
  variationsListQuery,
}: Props<T, S>) => {
  const { getIntlString } = useI18n();
  const { stores, isMerchant } = useStoreService();
  const [showArchived, setShowArchived] = React.useState(false);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const handleTransformToState = transformToState ?? defaultTransform;
  const [showConnectionModal, setShowConnectionModal] = React.useState<boolean>(false);
  const isAddDisabled = isItemReplicaConnections ? !canEditFranchiseConnections : disabled;
  const hasArchived = React.useMemo(() => data?.some((c) => isArchived(c as Item, isMerchant, selectedLevelStoreId)), [data, isMerchant, selectedLevelStoreId]);

  useEffect(() => {
    const initialConnections = data?.map((item) => handleTransformToState(item));

    setSelectedItems(initialConnections as T[]);
  }, [data, handleTransformToState]);

  const handleUpdateSelection = (selected: ConnectionItem) => {
    const alreadySelected = !!selectedItems.find(({ uniqueName }) => uniqueName === selected.uniqueName);
    let updatedSelections = selectedItems;
    if (alreadySelected) {
      updatedSelections = updatedSelections.filter(({ uniqueName }) => uniqueName !== selected.uniqueName);
    } else {
      updatedSelections = [...updatedSelections, handleTransformToState(selected)] as T[];
    }
    setSelectedItems(updatedSelections);
  };

  const handleRemoveSelection = (uid: string) =>
    setSelectedItems((prev) => {
      return prev.filter(({ uniqueName }) => uniqueName !== uid);
    });

  const connectionsContextValue: ConnectionsContextType<T> = {
    title,
    isMerchantLevelItem,
    currentConnections: data,
    selectedLevelStoreId,
    itemsList,
    selectedItems,
    previewConnections,
    emptyTablePlaceholder,
    handleUpdateSelection,
    handleRemoveSelection,
    isUpdatingConnection,
    onUpdateConnections,
    disabled,
    merchantId,
    storeId,
    previewConnectionsComponent,
    showType,
    transformToState,
    isDraggable,
    handleDragAndDrop,
    stores,
    setShowConnectionModal,
    showConnectionModal,
    typedPreviewConnections,
    updateCategoryActions,
    showArchived,
    // @ts-ignore-next-line
    storeSpecificSettingsOpener,
  };

  return (
    <ConnectionsProvider {...connectionsContextValue}>
      {emptyTableToSingleButton && !data.length ? (
        <div className="tw-flex-1 tw-mr-4 last:tw-mr-0">
          <MarketButton disabled={disabled || undefined} rank="secondary" onClick={() => setShowConnectionModal(true)} className="tw-bg-opacity-100 tw-w-full">
            {emptyTableToSingleButtonTilte}
          </MarketButton>
          {showConnectionModal && <AddConnectionsModal title={title} filterAddConnectionsListBySearchQuery={filterAddConnectionsListBySearchQuery} />}
        </div>
      ) : (
        <div className="tw-mb-8 tw-space-y-2.5 last:tw-mb-0">
          <PanelTitleWithAction
            title={title}
            subtitle={subtitle}
            action={[
              <div key="1" className="tw-flex tw-space-x-3">
                {viewArchived && hasArchived && <ShowArchivedConnectionsButton showArchived={showArchived} onClick={setShowArchived} />}
                {!hideConnectionMainButton && (
                  <Button
                    size="small"
                    color="secondary"
                    className="tw-ml-3"
                    onClick={() => setShowConnectionModal(true)}
                    disabled={isLoadingTable || isAddDisabled}
                  >
                    <div className="tw-flex tw-text-sm tw-font-normal tw-items-center tw-space-x-1">
                      <IconPlusOutlined className="tw-w-4 tw-h-4" />
                      <span>{addConnectionTitle ?? getIntlString('actions.add')}</span>
                    </div>
                  </Button>
                )}
                {actions}
                {showConnectionSecondButton && (
                  <Button size="small" color="secondary" onClick={handleSecondButtonAction}>
                    <div className="tw-flex tw-text-sm tw-font-normal tw-items-center tw-space-x-1">
                      <span>{connectionSecondButtonTitle}</span>
                    </div>
                  </Button>
                )}
              </div>,
            ]}
          />
          <div className="tw-max-h-144.5 tw-overflow-y-auto">
            {CustomConnectionsTable ? (
              <CustomConnectionsTable
                storeSpecificSettingsOpener={storeSpecificSettingsOpener}
                onChange={onChangeConnectionsTable}
                disabled={disabled}
                currentConnections={data}
                isLoading={isLoadingTable || updateCategoryActions?.isLoading}
                handleDragAndDrop={handleDragAndDrop}
                showArchived={showArchived}
                isDraggable={isDraggable}
                variationParentQuery={variationParentQuery}
                variationsListQuery={variationsListQuery}
                hideButtonDelete={hideButtonDelete}
              />
            ) : (
              <ConnectionsTable
                isLoadingTable={isLoadingTable || updateCategoryActions?.isLoading}
                isItemReplicaConnections={isItemReplicaConnections}
                canEditFranchiseConnections={canEditFranchiseConnections}
                hideButtonDelete={hideButtonDelete}
              />
            )}
          </div>
          {showConnectionModal && <AddConnectionsModal title={title} filterAddConnectionsListBySearchQuery={filterAddConnectionsListBySearchQuery} />}
        </div>
      )}
    </ConnectionsProvider>
  );
};

export const Connections = <T extends BaseSelectedConnectionType, S = T>(props: Props<T, S>): JSX.Element => (
  <I18nService prefix="menu.connections">
    <ConnectionsComponent<T, S> {...props} />
  </I18nService>
);
