import { useCallback, useState } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { UseFormErrorsResult, RecursivePartialOfType, MethodWithPathOrField, ErrorStructure, ChangeFieldMethod } from '../../types';

export function useFormErrors<T>(): UseFormErrorsResult<T> {
  const [errors, setErrors] = useState<RecursivePartialOfType<T, ErrorStructure>>({});

  const resetErrors = useCallback(() => setErrors({}), [setErrors]);
  const getError: MethodWithPathOrField<T, ErrorStructure> = useCallback((path) => get(errors, path) as ErrorStructure, [errors]);
  const hasError: MethodWithPathOrField<T, boolean> = useCallback((path) => !!getError(path), [getError]);
  const setErrorField: ChangeFieldMethod<ErrorStructure> = useCallback((path, value) => setErrors((errors) => set(cloneDeep(errors), path, value)), []);

  return {
    errors,
    getError,
    setErrorField,
    hasError,
    resetErrors,
    setErrorState: setErrors,
  };
}
