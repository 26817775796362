import { EntityFormResult } from '../../types';

export type UseFormFieldHookResult<T, U> = {
  (field: keyof T, humanReadableName?: string): U;
  (path: string, humanReadableName?: string): U;
};

export type UseFormFieldResult = {
  disabled: boolean;
  hasError?: boolean;
  errorMessage?: string;
};

export function useFormFieldProps<T>(hook: EntityFormResult<T>): UseFormFieldHookResult<T, UseFormFieldResult> {
  const useFormFieldPropsForPath: UseFormFieldHookResult<T, UseFormFieldResult> = (path, humanReadableName) => {
    const result = {
      disabled: hook.isDisabledField(path),
    };
    if (hook.hasError(path) && hook.isDirtyField(path)) {
      const textToReplace = path.split('.').pop();
      const help = hook.getError(path)?.message;
      return {
        ...result,
        hasError: true,
        errorMessage: humanReadableName ? help.replace(textToReplace, humanReadableName) : help,
      };
    }
    return result;
  };

  return useFormFieldPropsForPath;
}
