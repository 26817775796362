import * as React from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField, InlineSelect } from '@goparrot-dashboard/shared-ui';
import { TargetEnum } from '@goparrot/common';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

export const ItemTarget = <T extends { target: TargetEnum }>(): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);

  return (
    <FormField
      {...bindFormFieldProps('target')}
      label={getMenuFormIntlString('target')}
      input={
        <InlineSelect
          {...bindInputProps('target')}
          ariaLabel="Select target"
          options={[
            { value: TargetEnum.IN_STORE, label: 'Offline' },
            { value: TargetEnum.ONLINE, label: 'Online' },
            { value: TargetEnum.BOTH, label: 'Both' },
          ]}
        />
      }
    />
  );
};
