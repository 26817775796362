import { IRewriteRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdateStoreItemGroupCategoriesParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<string>;
};
export type MerchantUpdateStoreItemGroupCategoriesResult = UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupCategoriesParams>;

export const useMerchantStoreItemGroupUpdateCategories = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateStoreItemGroupCategoriesParams>,
): MerchantUpdateStoreItemGroupCategoriesResult => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.updateStoreItemGroupCategories(merchantId, uniqueName, data),
    options,
  );
};
