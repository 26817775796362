import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { ReadOauthDto } from '@goparrot/payment-sdk';
import { userFacade } from '../facades';

type FranchiseConnectOrganizationParams = {
  uuid: string;
};

export const useFranchiseConnectOrganization = (
  options?: UseMutationOptions<ReadOauthDto, unknown, FranchiseConnectOrganizationParams>,
): UseMutationResult<ReadOauthDto, unknown, FranchiseConnectOrganizationParams> => {
  return useMutation(({ uuid }) => userFacade.franchiseConnectOrganization(uuid), options);
};
