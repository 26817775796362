import { PatchStoreItemGroupModsDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantPatchStoreItemGroupModsParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchStoreItemGroupModsDto;
};
export type MerchantPatchStoreItemGroupModsResult = UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantPatchStoreItemGroupModsParams>;

export const useMerchantPatchStoreItemGroupMods = (
  options?: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantPatchStoreItemGroupModsParams>,
): MerchantPatchStoreItemGroupModsResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.patchMod(merchantId, uniqueName, data), options);
};
