import { IUpdateRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

export type useMerchantUpdateStoreItemPropertiesParams = {
  merchantId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};

export const useMerchantUpdateStoreItemProperties = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, useMerchantUpdateStoreItemPropertiesParams, unknown>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, useMerchantUpdateStoreItemPropertiesParams, unknown> => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.updateStoreItemProperties(merchantId, uniqueName, data),
    options,
  );
};
