import { UseQueryResult } from 'react-query';
import { IMerchantFilterParams, IResultsListResponse, IStoreFilterParams, ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ListQueryBaseParams } from '@goparrot-dashboard-api/menu-management';
import { useLocationStoreItemList, useMerchantStoreItemList } from '@goparrot-dashboard-api/shared';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';

type Props = ListQueryBaseParams<IStoreFilterParams | IMerchantFilterParams> & {
  storeId: string;
  isMerchant: boolean;
  enabled?: boolean;
};

export const useStoreItemListQuery = ({
  merchantId,
  storeId,
  pagination,
  params,
  isMerchant,
  enabled = true,
}: Props): UseQueryResult<IResultsListResponse<ReadStoreItemDto>> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.STORE_ITEM }),
      });
    },
  };

  const locationQuery = useLocationStoreItemList(
    { merchantId, storeId, pagination, params: params as IStoreFilterParams },
    {
      ...options,
      enabled: !isMerchant && enabled,
    },
  );

  const merchantQuery = useMerchantStoreItemList(
    { merchantId, pagination, params: params as IMerchantFilterParams },
    {
      ...options,
      enabled: isMerchant && enabled,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
