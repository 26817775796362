import { useState, useCallback } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { RecursivePartialOfType, SetBooleanFieldValue, MethodWithPathOrField } from '../../types';

export type UseBooleanFormParams = {
  fallbackValue?: boolean;
};
export type UseBooleanFormResult<T> = [
  state: RecursivePartialOfType<T, boolean>,
  setState: React.Dispatch<React.SetStateAction<RecursivePartialOfType<T, boolean>>>,
  getFieldValue: MethodWithPathOrField<T, boolean>,
  setFieldValue: SetBooleanFieldValue<T>,
];

export const useBooleanFormState: <T>(options?: UseBooleanFormParams) => UseBooleanFormResult<T> = <T>({
  fallbackValue = false,
}: UseBooleanFormParams = {}) => {
  const [state, setState] = useState<RecursivePartialOfType<T, boolean>>({});

  const setFieldValue: SetBooleanFieldValue<T> = useCallback((path, value) => setState((prevState) => set(cloneDeep(prevState), path, value)), []);
  const getFieldValue: MethodWithPathOrField<T, boolean> = useCallback((path) => (get(state, path) as boolean) ?? fallbackValue, [fallbackValue, state]);

  return [state, setState, getFieldValue, setFieldValue];
};
