import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IStoreFilterParams, IPagination, ItemOptionsListResponseDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationItemOptionsListCallback } from './utils/locationItemOptionsListCallback';

type LocationItemOptionsListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IStoreFilterParams };

export const useLocationItemOptionsList = (
  { merchantId, storeId, pagination, params }: LocationItemOptionsListParams,
  options?: UseQueryOptions<ItemOptionsListResponseDto>,
): UseQueryResult<ItemOptionsListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_ITEM_OPTION_LIST, merchantId, storeId, pagination, params],
    async () => await locationItemOptionsListCallback({ merchantId, storeId, pagination, params }),

    options,
  );
};
