import React, { FC, useState } from 'react';
import { Button, Row, Col } from 'antd';
import styled from 'styled-components';

import { palette } from '../../../styles/constants';

type ParrotTipItem = {
  key: string;
  title?: string | React.ReactNode;
  description: string | React.ReactNode;
};

type TipItemProps = {
  item: ParrotTipItem;
  order: number;
  totalItems: number;
};
// Bit later will move it in shared-ui
const TipItem: FC<React.PropsWithChildren<TipItemProps>> = ({ item, order, totalItems }) => (
  <TipItemWrapper>
    <Row type="flex" justify="space-between" align="top">
      <Col span={20}>
        {item.title && <Title>{item.title}</Title>}
        <Description>{item.description}</Description>
      </Col>
      <Col span={4}>
        <TipPagination>
          {order} of {totalItems}
        </TipPagination>
      </Col>
    </Row>
  </TipItemWrapper>
);

export type ParrotTipsProps = {
  items: ParrotTipItem[];
  showAllLabel: string | React.ReactNode;
  hideAllLabel: string | React.ReactNode;
  showCallout?: boolean;
  buttonTextColor?: string;
  testId?: string;
};

export const ParrotTips: FC<React.PropsWithChildren<ParrotTipsProps>> = ({
  items,
  showAllLabel,
  hideAllLabel,
  buttonTextColor,
  showCallout = true,
  testId = 'parrot-tips',
}) => {
  const [firstItem, ...restItems] = items;
  const [showAll, setShowAll] = useState<boolean>(false);
  return (
    <Container data-testid={testId}>
      <Wrapper $showcallout={showCallout} className="tw-bg-light-blue-50">
        <TipItem item={firstItem} order={1} totalItems={items.length} />
        {showAll && restItems.map((item, index) => <TipItem item={item} order={index + 2} totalItems={items.length} key={item.key} />)}
        {!!restItems.length && showCallout && (
          <Callout $buttonTextColor={buttonTextColor}>
            <Button data-testid="button" type="link" onClick={() => setShowAll(!showAll)}>
              {showAll ? hideAllLabel : showAllLabel}
            </Button>
          </Callout>
        )}
      </Wrapper>
    </Container>
  );
};

const TipItemWrapper = styled.div`
  padding: 10px 0 15px;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

const Container = styled.div`
  img {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div<{ $showcallout: boolean }>`
  position: relative;
  background: ${palette.color[20]};
  border-radius: 4px;
  color: ${palette.text[5]};
  ${($showcallout) =>
    $showcallout
      ? `
  padding: 10px 13px 20px 16px;
  &:after {
    content: ' ';
  }
  `
      : `
  padding: 10px 13px 10px 16px;
      `}
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
  white-space: break-spaces;
`;

const Callout = styled.div<{ $buttonTextColor: string }>`
  .ant-btn {
    padding: 0;
    height: 20px;
    margin-top: 6px;
    ${({ $buttonTextColor }) =>
      $buttonTextColor &&
      `
      color: ${$buttonTextColor};
      &:hover {
        opacity: 0.7;
      }
    `}
  }
`;
const TipPagination = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${palette.text[5]};
  opacity: 0.5;
  text-align: right;
`;
