import { PaymentProviderEnum } from '@goparrot/common';
import { ReadOauthLinkDto } from '@goparrot/payment-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { paymentStoreFacade } from '../facades';

type PaymentAuthDataStoreParams = {
  storeId: string;
  provider: PaymentProviderEnum;
};
type PaymentAuthDataStoreResult = ReadOauthLinkDto;

export const usePaymentAuthDataStore = (
  options?: UseMutationOptions<PaymentAuthDataStoreResult, unknown, PaymentAuthDataStoreParams>,
): UseMutationResult<PaymentAuthDataStoreResult, unknown, PaymentAuthDataStoreParams> => {
  return useMutation(({ storeId, provider }) => paymentStoreFacade.getAuthData(storeId, provider), options);
};
