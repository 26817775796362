import type { PermissionsV2ValueType } from '@goparrot/users-v2-sdk';
import { hasPermission, hasSomePermission } from '@goparrot/users-v2-sdk';
import { useUserService } from './useUserService';

/**
 * @param permissions Array of required permissions. Subarray-yed permissions are optional
 * @example
 * role.permissions = ['STORE_READ']
 * usePermmission('STORE_READ') => true
 * usePermmission('STORE_CREATE') => false
 * usePermmission('STORE_CREATE', 'STORE_READ') => false // both permissions are required, but found only one
 * usePermmission(['STORE_CREATE', 'STORE_READ']) => true // one of these two permissions is required, found STORE_READ
 */
export const usePermission = (...permissions: Array<PermissionsV2ValueType | PermissionsV2ValueType[]>): boolean => {
  const { roleV2 } = useUserService();

  if (undefined === roleV2) {
    return false;
  }

  return permissions.every((permissionOrPermissionsArray) => {
    if (Array.isArray(permissionOrPermissionsArray)) {
      return hasSomePermission(roleV2, permissionOrPermissionsArray);
    }

    return hasPermission(roleV2, permissionOrPermissionsArray);
  });
};
