import styled from 'styled-components';

import { palette } from 'styles/constants';
import { transition, borderRadius } from 'styles/util';

export const DatePickerWrapper = (component) => styled(component)`
  height: 36px;
  .ant-input {
    padding: 9px 10px;
    width: 100%;
    height: 100%;
    cursor: text;
    font-size: 13px;
    line-height: 1.5;
    color: ${palette.text[1]};
    background-image: none;
    border: 1px solid ${palette.border[0]};
    ${borderRadius('4px')};
    ${transition()};

    &:focus {
      border-color: ${palette.primary[0]};
    }

    &::-webkit-input-placeholder {
      color: ${palette.grayscale[0]};
    }

    &:-moz-placeholder {
      color: ${palette.grayscale[0]};
    }

    &::-moz-placeholder {
      color: ${palette.grayscale[0]};
    }
    &:-ms-input-placeholder {
      color: ${palette.grayscale[0]};
    }
  }
`;
