import { IRewriteRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';

export type MerchantUpdateCategoriesStoreItemParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<string>;
};

export const useMerchantUpdateCategoriesStoreItem = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateCategoriesStoreItemParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateCategoriesStoreItemParams> => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantStoreItemFacade.updateStoreItemCategories(merchantId, uniqueName, data),
    options,
  );
};
