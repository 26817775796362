import { ReadMerchantDto } from '@goparrot/store-v2-sdk';
import { DashboardPermission } from '../interfaces';
import { DashboardPermissionContext } from '../enums';

export const isDashboardAvailable = (merchant: ReadMerchantDto, permission?: DashboardPermission): boolean => {
  if (!permission) return true;

  const { merchantId, featuresFlags, shortTermFeatureFlags } = merchant;
  const { name, context, target } = permission;

  if (context === DashboardPermissionContext.MERCHANT) {
    return merchantId === target.stringValue;
  }

  const source = DashboardPermissionContext.FEATURE_FLAG === context ? featuresFlags : shortTermFeatureFlags;

  return (target.booleanValue ?? target.stringValue) === (source as any)?.[name as string];
};
