import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconArrowTopRight, IconSend } from '@goparrot-dashboard/shared-ui';
import { ReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { getIntlMessage as commonIntlMessage, getIntlString } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import { MarketButton, MarketRow } from '@market/react-bindings';
import { openURL } from '../utils';

import { getWebStoreActions } from '../../../client/src/store/webstore/actions';

type ButtonsLinkToStoreProps = {
  storeId?: string | null;
  className?: string;
  locationsLoading: boolean;
  defaultStores: ReadDefaultStoreDto[];
  selectedStoreId: string | null;
  showOnMobile?: boolean;
  isMarketRow?: boolean;
  marketRowText?: string;
};

const selectProps = createSelector(
  ({ root }) => root.webstore.webStoreUrls?.menu,
  (menu) => {
    return {
      menu,
    };
  },
);

const baseIntlPath = 'page.locations';
const getIntlMessage = commonIntlMessage(baseIntlPath);

export const ButtonLinkToStore: React.FC<React.PropsWithChildren<ButtonsLinkToStoreProps>> = React.memo(
  ({
    storeId,
    className,
    defaultStores,
    selectedStoreId,
    locationsLoading,
    showOnMobile,
    isMarketRow,
    marketRowText = getIntlMessage('table.actions.goToStore'),
  }) => {
    const dispatch = useDispatch();
    // @ts-ignore // reselect type causing issues
    const { menu } = useSelector(selectProps);
    const isDefaultStore = defaultStores[0]?.storeId === selectedStoreId;
    const disabled = locationsLoading;

    const [isClicked, setClickState] = useState(false);

    useEffect(() => {
      if (menu && isClicked) {
        setClickState(false);
        openURL(menu);
      }
    }, [menu, isClicked]);

    const fetchUrlAndRedirect = (storeId: string) => {
      const currentStoreId = storeId || selectedStoreId;
      dispatch(getWebStoreActions.requestGetWebStoreUrls({ storeId: currentStoreId as string }));
      setClickState(true);
    };

    if (isDefaultStore) return null;

    return (
      <>
        {isMarketRow ? (
          <MarketRow className="tw-text-left" onClick={() => fetchUrlAndRedirect(storeId ?? '')}>
            <label slot="label">{marketRowText}</label>
          </MarketRow>
        ) : (
          <MarketButton
            rank="secondary"
            size="small"
            aria-label={getIntlString('page.locations.actions.redirect')}
            className={classNames([className, 'tw-ml-1'])}
            disabled={disabled || undefined}
            onClick={() => fetchUrlAndRedirect(storeId ?? '')}
          >
            {showOnMobile ? (
              <IconSend />
            ) : (
              <>
                <span>{getIntlMessage('actions.redirect')}</span>
                <IconArrowTopRight />
              </>
            )}
          </MarketButton>
        )}
      </>
    );
  },
);
