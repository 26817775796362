import React, { FC } from 'react';
import { PageTitle } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';

const StyledSeparator: FC<React.PropsWithChildren<unknown>> = () => <div role="separator" className="tw-mb-3 tw--mt-1 tw-h-px tw-bg-black tw-opacity-10" />;

export type PageHeadingProps = {
  className?: string;
};

const PageHeading: FC<React.PropsWithChildren<PageHeadingProps>> & { Separator: typeof StyledSeparator } = ({ children, className = '' }) => {
  const { getIntlString } = useI18n();
  return (
    <PageTitle className={classNames([className, 'tw-mb-3'])} hasMargin={false} seoTitle={getIntlString('main.title')}>
      {children}
    </PageTitle>
  );
};

PageHeading.Separator = StyledSeparator;

export { PageHeading };
