import { useContext, useEffect, Context } from 'react';
import { Socket } from 'socket.io-client';
import { WebsocketContextType, WebsocketListener } from '../types';

type UseWeboscketContextParams = [context: Context<WebsocketContextType>, listeners?: WebsocketListener[]];

type UseWeboscketContextResult = {
  socket: Socket;
};

export const useWebsocket = (...args: UseWeboscketContextParams): UseWeboscketContextResult => {
  const [Context, listeners] = args;
  const contextValue = useContext(Context);

  useEffect(() => {
    if (contextValue.socket) {
      listeners.forEach(([event, handler]) => contextValue.socket.on(event, handler));
    }
    return () => {
      if (contextValue.socket) {
        listeners.forEach(([event, handler]) => contextValue.socket.off(event, handler));
      }
    };
  }, [contextValue.socket, listeners]);

  return { socket: contextValue.socket };
};
