import { IMerchantFilterParams, IPagination, MenuConceptListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantMenuConceptsFacade } from '../../facades';

type MenuConceptListParams = { merchantId: string; pagination?: IPagination; params?: IMerchantFilterParams };

export const useMenuConceptsList = (
  { merchantId, pagination, params }: MenuConceptListParams,
  options?: UseQueryOptions<MenuConceptListResponseDto>,
): UseQueryResult<MenuConceptListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_MENU_CONCEPTS_LIST, merchantId, pagination, params],
    async () => await merchantMenuConceptsFacade.list(merchantId, pagination, params),
    options,
  );
};
