import React from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { IReadStoreItemComboDto, ReadItemSetDto, ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { IsAvailableAtToggle } from '../../IsAvailableAtToggle';
import { IsOrderableToggle } from '../../IsOrderableToggle';
import { useMenuFormContext } from '../../../context';
import { useConnectionsContext } from '../context';
import { canLinkMenuItem, isArchived, isMerchantLevel } from '../../../utils';
import { Item } from '../../../interface';

type TableRecordType = Exclude<Item, ReadItemSetDto | ReadStoreItemBundleDto | IReadStoreItemComboDto>;

export const useConnectionAvailability = (): null | Record<string, unknown>[] => {
  const { franchisePermissions } = useMenuFormContext();
  const {
    selectedStoreId,
    merchant: { storeId: merchantId },
    isMerchant,
    isFranchise,
  } = useStoreService();
  const { updateCategoryActions } = useConnectionsContext();
  const { updateMods, merchantLevelUpdate, storeLevelUpdate } = updateCategoryActions ?? {};
  const hasAvailabilityColumns = updateCategoryActions !== undefined;

  const handleUpdate = React.useCallback(
    (payload: any, item: any) => {
      if (!hasAvailabilityColumns || !updateMods || !merchantLevelUpdate || !storeLevelUpdate) {
        return console.error('Please provide update actions');
      }

      const isMerchantItem = isMerchantLevel(item);

      if (!isMerchant && isMerchantItem) {
        updateMods.mutate({
          merchantId,
          uniqueName: item.uniqueName,
          data: {
            ...payload,
            storeId: selectedStoreId,
          },
        });
        return;
      }

      if (isMerchant && isMerchantItem) {
        merchantLevelUpdate.mutate({
          merchantId,
          uniqueName: item.uniqueName,
          data: payload,
        });
        return;
      }

      storeLevelUpdate.mutate({
        merchantId,
        uniqueName: item.uniqueName,
        data: payload,
        storeId: item.storeId,
      });
    },
    [hasAvailabilityColumns, isMerchant, merchantId, merchantLevelUpdate, selectedStoreId, storeLevelUpdate, updateMods],
  );
  const isToggleDisabled = React.useCallback(
    (item: any) => !canLinkMenuItem({ item, isMerchant, selectedStoreId }) || isArchived(item, isMerchant, selectedStoreId) || item.isArchived,
    [isMerchant, selectedStoreId],
  );

  return React.useMemo(
    () =>
      hasAvailabilityColumns
        ? [
            {
              Header: getIntlString('menu.list.table.columns.available'),
              accessor: 'isAvailable',
              className: 'tw-w-20',
              Cell: ({ value, row: { original } }: { value: boolean; row: { original: TableRecordType } }) => (
                <IsAvailableAtToggle
                  isAvailableAtEnabled
                  original={original}
                  value={value}
                  franchisePermissions={franchisePermissions}
                  onUpdate={handleUpdate}
                  customDisableToggle={isFranchise || isToggleDisabled(original)}
                />
              ),
            },
            {
              Header: getIntlString('menu.list.table.columns.orderable'),
              accessor: 'isOrderable',
              className: 'tw-w-20',
              Cell: ({ value, row: { original } }: { value: boolean; row: { original: TableRecordType } }) => (
                <IsOrderableToggle
                  original={original}
                  value={value}
                  franchisePermissions={franchisePermissions}
                  onUpdate={handleUpdate}
                  isStoreOperationsRole={false}
                  customDisableToggle={isToggleDisabled(original)}
                />
              ),
            },
          ]
        : null,
    [franchisePermissions, handleUpdate, hasAvailabilityColumns, isFranchise, isToggleDisabled],
  );
};
