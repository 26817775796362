import { ImageConfig, CloudinaryPresetNameEnum } from '../../interface';

export function getRectImageConfig(maxWidth = 2865, maxHeight = 1500, minImageHeight = null): ImageConfig {
  return {
    upload_preset: window._env_.CLOUDINARY_RECTANGLE_PRESET_NAME as CloudinaryPresetNameEnum.RECTANGLE,
    max_image_width: maxWidth,
    max_image_height: maxHeight,
    min_image_height: minImageHeight,
  };
}

export function getSquareImageConfig(maxWidth = 1500, maxHeight = 1500, minImageHeight = null): ImageConfig {
  return {
    ...getRectImageConfig(maxWidth, maxHeight, minImageHeight),
    upload_preset: window._env_.CLOUDINARY_SQUARE_PRESET_NAME as CloudinaryPresetNameEnum.SQUARE,
  };
}
