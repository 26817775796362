import { FC } from 'react';
import AntButton, { ButtonProps as AntButtonProps } from 'antd/es/button';

import { ButtonWrapper, ButtonGroupWrapper } from './style/button.style';

export type ButtonProps = AntButtonProps;

const ButtonGroupWrapped = ButtonGroupWrapper(AntButton.Group);
const Button: FC<React.PropsWithChildren<ButtonProps>> & { Group: typeof ButtonGroupWrapped } = ButtonWrapper(AntButton) as any;
Button.Group = ButtonGroupWrapped;

export { Button };
