import React from 'react';
import { Button, Divider, Modal } from '@goparrot-dashboard/shared-ui';
import { DialogStateReturn } from 'reakit/Dialog';
import { useI18n } from '@goparrot-dashboard/i18n';
import { ConnectionItem } from '../../interface';
import { PreviewConnection, ModalPreviewConnection } from './AddConnectionsModal';

type Props = {
  dialog: DialogStateReturn;
  item: ConnectionItem;
  previewConnections: PreviewConnection[];
  merchantId: string;
};

export const ListItemPreviewConnectionsModal: React.FC<React.PropsWithChildren<Props>> = ({
  dialog,
  item,
  previewConnections,
  merchantId,
}): React.ReactElement => {
  const { visible } = dialog;
  const { getIntlString } = useI18n();

  const closeModal = () => {
    dialog.hide();
  };

  return (
    <Modal hideOnClickOutside={false} animated aria-label="Connections Modal" dialog={dialog} maxWidth="tw-max-w-3xl">
      <Modal.ModalHeaderWithCloseButton onClose={dialog.hide}>
        {getIntlString('menu.form.connections.title', { isRootPath: true, values: { itemType: item?.title || '' } })}
      </Modal.ModalHeaderWithCloseButton>
      {visible && item ? (
        <div className="tw-py-8 tw-px-7 tw-space-y-8 tw-max-h-144.5 tw-overflow-y-auto">
          {previewConnections.map((connection: PreviewConnection, index: number) => {
            const Component = connection?.component ? connection?.component : ModalPreviewConnection;
            return <Component key={`${connection.name}-${index}`} connection={connection} item={item} merchantId={merchantId} />;
          })}
        </div>
      ) : (
        <div className="tw-py-8 tw-font-normal tw-text-blue-gray-900">{getIntlString('menu.connections.modal.preview.empty', { isRootPath: true })}</div>
      )}
      <Modal.ModalFooter>
        <Divider />
        <div className="tw-flex tw-justify-end tw-pt-3">
          <Button color="secondary" onClick={closeModal}>
            {getIntlString('button.cancel', { isRootPath: true })}
          </Button>
        </div>
      </Modal.ModalFooter>
    </Modal>
  );
};
