import React, { FC } from 'react';
import { hasProperty } from '@goparrot-dashboard/shared-utils';
import { MarketCheckbox } from '@market/react-bindings';
import { useI18n } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';
import { ItemGroupingTypeEnum, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { ConnectionItem, Item } from '../../../interface';
import { useConnectionsContext } from '../context';
import { MENU_ELEMENTS_BY_TYPE } from '../../../constants';
import { getStoreTitle, isMenuConcept } from '../../../utils';

type ModalItemProps = {
  item: ConnectionItem;
  isViewed: boolean;
  checked: boolean;
  setPreviewItem: React.Dispatch<React.SetStateAction<ConnectionItem>>;
};

export const ModalItem: FC<React.PropsWithChildren<ModalItemProps>> = ({ item, isViewed, setPreviewItem, checked }) => {
  const { getIntlString } = useI18n();
  const { handleUpdateSelection, showType, stores, merchantId } = useConnectionsContext();
  const { isVirtualMenu } = useStoreService();
  const showPosGuid = hasProperty(item, 'metadata');
  const posGuidValue = (item as Item).metadata?.pos?.pos_guid || 'n/a';
  const storeName = React.useMemo(() => getStoreTitle({ locations: stores, storeId: item.storeId, merchantId, isVirtualMenu }), [
    stores,
    item.storeId,
    merchantId,
    isVirtualMenu,
  ]);
  const descriptionTextClass = 'tw-flex tw-space-x-0.5 tw-max-w-72 tw-items-center tw-font-normal tw-text-xs tw-text-blue-gray-400 tw-whitespace-pre';

  return (
    <div className="tw-pr-2 tw-py-2 tw-border-b tw-border-b-px tw-border-blue-gray-900 tw-border-opacity-10">
      <div
        className={classNames([
          'tw-flex tw-space-x-2 tw-p-2 tw-items-center tw-transform tw-transition-colors tw-duration-200 tw-cursor-pointer',
          'tw-rounded-md',
          isViewed && 'tw-bg-light-blue-100',
        ])}
      >
        <MarketCheckbox
          checked={!!checked || undefined}
          onMarketCheckboxValueChange={() => {
            handleUpdateSelection(item);
          }}
        />

        <div className="tw--mt-1 tw-flex-1" onClick={() => setPreviewItem(item)}>
          <div className="tw-font-bold tw-text-sm tw-text-blue-gray-900 tw-break-all">{item.title}</div>
          {showPosGuid && (
            <div className={classNames([descriptionTextClass])}>
              <span>{getIntlString('modal.items.posGuid')}</span>
              <span className="tw-truncate" title={posGuidValue}>
                {posGuidValue}
              </span>
            </div>
          )}
          <div className={classNames([descriptionTextClass])}>{storeName}</div>
          {showType && !isMenuConcept(item) && (
            <div className={classNames([descriptionTextClass])}>
              <span>{getIntlString('modal.items.type')}</span>
              <span className="tw-truncate">
                {(item as ReadStoreItemGroupDto).groupingType === ItemGroupingTypeEnum.VARIATION
                  ? MENU_ELEMENTS_BY_TYPE.storeItem
                  : MENU_ELEMENTS_BY_TYPE[item.type]}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
