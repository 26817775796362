export const palette = {
  primary: [
    '#4482FF', // 0: Default
    '#3A78F5', // 1: Darken 4%
    '#3775F2', // 2: Darken 5%
    'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
    '#4C8AFF', // 4: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
    '#6AA8FF', // 6: Lighten 15%
    '#63A1FF', // 7: Lighten 12%
    '#3F7DFA', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#5896FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#236cfe', // 12: darken 10%
    '#4d88ff', // 13: Lighten 5%
    '#e6f7ff', // 14: Light blue
    'var(--core-blue-text-color)', // 15: Blue
    'var(--core-blue-10-color)', // 16: Blue Lighten 5%
    'var(--core-blue-20-color)', // 17
    '#EDF7FF', // 18: Blue background
    'var(--button-normal-variant-primary-rank-normal-state-background-color)', // 19: Market button background color
    'var(--button-normal-variant-primary-rank-hover-state-background-color)', // 20: Market button background hover color
    'var(--button-normal-variant-primary-rank-pressed-state-background-color)', // 21: Market button background active color
    'var(--button-normal-variant-primary-rank-disabled-state-background-color)', // 22: Market button background disabled color
    'var(--button-normal-variant-primary-rank-disabled-state-label-color)', // 23: Market button label disabled color
    'var(--button-destructive-variant-primary-rank-normal-state-background-color)', // 24: Market button destructive background color
    'var(--choice-button-unselected-value-normal-state-label-color)', // 25: Market choice button label color
    'var(--choice-button-selected-value-normal-state-background-color)', // 26: Market choice button selected background color
    'var(--core-blue-fill-color)', // 27: Market blue fill color
    'var(--core-fill-30-light-mode-color)', // 28: Market gray fill color
  ],
  secondary: [
    '#2d3446', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F6F8FB', // 8: LighterBluish Lighten 2%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
    '#375b74', // 11: Sidebar submenu select
    'var(--button-normal-variant-secondary-rank-normal-state-background-color)', // 12: Market secondary button background color
    'var(--button-normal-variant-secondary-rank-normal-state-label-color)', // 13: Market secondary button label color
    'var(--button-normal-variant-secondary-rank-hover-state-background-color)', // 14: Market secondary/choice button hover background color
    'var(--button-normal-variant-secondary-rank-pressed-state-background-color)', // 15: Market secondary/choice button active background color
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fcfcfc', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
    '#fafafa', // 12: orders-heders-bg
    '#47505A', // 13: pos-search-card-price
    '#9E9E9E', // 14: table-view-input-color
    '#808D93', // 15: new order notifications popup background
    '#dddddd', // 16:
    '#F6F6F6', // 17: light gray
    '#d9d9d9', // 18:
    '#7F8389', // 19: tabel header campaign list
    '#929292', // 20:
    '#efefef', // 21:
    '#DCDCDC', // 22:
    '#e8e8e8', // 23:
    '#c4c7c9', // 24:
    '#50555C', // 25: gray text
    '#e5e7eb', // 26 border
    '#EFF1F5', // 27: modal header
    'var(--field-normal-state-normal-validity-border-color)', // 28: Market input border color
  ],
  color: [
    '#FF9500', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    'var(--core-green-fill-light-mode-color)', // 3: Market green fill color
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
    '#000000', // 14: Black
    '#EC3D3A', // 15: red
    '#7ED321', // 16: green
    '#00AE9A', // 17: Light green
    '#EA1C2D', // 18: Dangerous red
    '#FF9500', // 19: Light Orange
    '#FFFFFF', // 20: White
    '#FFB500', // 21: OrangeLight
    'var(--core-green-text-color)', // 22: Market green text color
  ],
  hover: '#40a9ff', // blue
  warning: '#ffbf00',
  sucess: '#00b16a',
  error: [
    '#f64744', // 0: Error
    'var(--core-red-fill-light-mode-color)', // 1: Market red fill color
    '#FF5B58', // 2: Lighten 8%
    'var(--core-critical-text-light-mode-color)', // 3: Market critical text color
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#50555C', // 5: Light Black
    '#00AE9A', // 6: Success
    '#3A78FF', // 7: Blue
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
  ],
  calendar: [
    '#FAF8F3', // 0: background frame
    '#FF9500', // 1: text color
    '#00C6D4', // 2 text special day
    '#EDFAFF', // 3 day today
  ],
  tags: [
    '#659BE0', // Havelock Blue
    '#fea527', // Base orange
    '#EFF9FC', // 2: tag bg: item_based promo type
    '#FCF0EF', // 3: tag bg: check_based promo type
    '#F1E8F3', // 4: tag bg: isCumulative
    '#e8f3ec', // 5: tag bg: loyalty reward
    '#edf3e8', // 6: tag bg: campaign
  ],
  orderColors: {
    new: '#E7505A',
    received: '#C49F47',
    ready: '#32c5d2',
    complete: '#375b74',
    canceled: '#375b74',
    cleared: '#375b74',
    test: '#375b74',
    refunded: '#375b74',
    delayed: '#fea527',
  },
};

export const fonts = {
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export const logo = 'https://res.cloudinary.com/druhu3xb6/image/upload/q_auto:low/v1569834020/logo/89132bf0-7c74-47ac-ba5f-d2cb5a5310c2.png';
