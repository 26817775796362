import * as React from 'react';
import { useNativeInputProps, useFormFieldProps } from '@goparrot-dashboard/entity-form';
import { FormField, Input } from '@goparrot-dashboard/shared-ui';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { getMenuFranchisePermissions, hasFranchiseTag } from '../../utils';
import { Item } from '../../interface';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

type Props<T> = {
  field?: keyof T;
  label?: string | React.ReactNode;
  placeholder?: string;
};
const SKU = 'sku';

export const ItemSku = <T extends Record<string, string>>({ field = SKU, label, placeholder }: Props<T>): React.ReactElement | null => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindNativeInputProps = useNativeInputProps(form);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const inputProps = bindNativeInputProps(field);
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditSku
    : franchisePermissions?.canEditSku;
  const isDisabled = isItemReplica ? !hasFranchisePermission : inputProps.disabled;

  return (
    <FormField
      {...bindFormFieldProps(field)}
      withValuePadding={false}
      label={label || getMenuFormIntlString(SKU)}
      disabled={isDisabled}
      input={
        <Input {...inputProps} placeholder={placeholder || getMenuFormIntlString('sku.placeholder')} disabled={isDisabled} name={SKU} className="tw-px-4" />
      }
    />
  );
};
