import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import type { PatchUserDto, ReadUserDto } from '@goparrot/users-v2-sdk';

import { userFacade } from '../facades';

type UpdateUserParams = {
  uuid: string;
  data: PatchUserDto;
};

export const useUpdateUser = (
  options?: UseMutationOptions<ReadUserDto, unknown, UpdateUserParams, unknown>,
): UseMutationResult<ReadUserDto, unknown, UpdateUserParams, unknown> => {
  return useMutation(async ({ uuid, data }) => userFacade.patch(uuid, data), options);
};
