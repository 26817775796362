import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { merchantCategoryFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useMerchantGetCategoryParentsQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadItemSetDto[]>,
): UseQueryResult<ReadItemSetDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_CATEGORY_GET_PARENTS, merchantId, uniqueName],
    async () => await merchantCategoryFacade.getParents(merchantId, uniqueName),
    options,
  );
};
