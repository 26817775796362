import type { IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ROLE_V2_QUERIES_ENUM } from '../enums';
import { roleV2ByMerchantFacade } from '../facades';

export type RolesQueryType = UseQueryResult<IReadRoleV2Dto[]>;

export const useGetAllRolesByMerchant = (merchantId: string, options?: UseQueryOptions<IReadRoleV2Dto[]>): RolesQueryType => {
  return useQuery([ROLE_V2_QUERIES_ENUM.GET_BY_MERCHANT_ID, merchantId], () => roleV2ByMerchantFacade.list(merchantId), options);
};
