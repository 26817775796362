import { useDialogState } from 'reakit';
import { PatchAbstractModsDto } from '@goparrot/storeitems-sdk';
import { DialogStateReturn } from 'reakit/Dialog';
import { useMenuFormContext } from '../../context';
import { useArchiveButtonActions } from './useArchiveButtonActions';
import { AbstractItemType } from './interface';
import { useArchiveRequestOptions } from './useArchiveRequestOptions';

type PatchBaseParams<T = { isArchived: boolean }> = {
  merchantId: string;
  storeId?: string;
  uniqueName: string;
  data: T;
};

type ReturnData = ReturnType<typeof useArchiveRequestOptions> & {
  handleChangeArchiveStatus(onUpdate: (params: PatchBaseParams) => void, onUpdateMods: (params: PatchBaseParams<PatchAbstractModsDto>) => void): () => void;
  archiveDialog: DialogStateReturn;
  getArchiveButtonConfig: ReturnType<typeof useArchiveButtonActions>;
};

export function useArchiveAssets<T, TUpdate extends AbstractItemType>(model: T, itemType: string): ReturnData {
  const archiveDialog = useDialogState({ animated: true });
  const { merchantId, isMerchant, uniqueName, selectedStoreId, isMerchantLevelItem, storeId } = useMenuFormContext<T>();
  const getArchiveButtonConfig = useArchiveButtonActions<T>(archiveDialog);
  const { isItemArchived, ...mutationOptions } = useArchiveRequestOptions<T, TUpdate>(archiveDialog, model, itemType);

  const handleChangeArchiveStatus = (
    onUpdate: (params: PatchBaseParams) => void,
    onUpdateMods: (params: PatchBaseParams<PatchAbstractModsDto>) => void,
  ) => (): void => {
    if (!isMerchant && isMerchantLevelItem) {
      onUpdateMods({ merchantId, uniqueName, data: { storeId: selectedStoreId, isArchived: !isItemArchived } });
      return;
    }

    const params = {
      merchantId,
      data: { isArchived: !isItemArchived },
      uniqueName,
      ...(!isMerchantLevelItem && { storeId }),
    };

    onUpdate(params);
  };

  return {
    ...mutationOptions,
    isItemArchived,
    archiveDialog,
    getArchiveButtonConfig,
    handleChangeArchiveStatus,
  };
}
