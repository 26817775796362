import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { RootState } from '@goparrot-dashboard/shared-utils';
import { StoreServiceProvider } from '@goparrot-dashboard-core/store-service';
import { ReadDefaultStoreDto } from '@goparrot/store-v2-sdk';
import { Spinner } from '@goparrot-dashboard/shared-ui';
import { RoleRoute as Route, PrivateRoutes } from './components';
import { AuthModule, OnboardingFranchiseModule } from './loadable-modules';
import { RouterProps } from './types';

const getStore = (storeId: string, locations: ReadDefaultStoreDto[]) => locations.find((location) => location.storeId === storeId);

const Router = ({
  history,
  authenticated,
  authenticating,
  featuresFlags,
  merchantId,
  merchant,
  store,
  isMerchant,
  locations,
  locationsFetching,
  initialLocationsFetchMade,
  roleV2,
  user,
}) => (
  // @ts-ignore-next-line
  <ConnectedRouter history={history}>
    <React.Suspense fallback={<Spinner isMarketComponent />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/auth" />} />
        <Route path="/auth" render={() => <AuthModule />} />
        <Route
          path="/franchise-invite"
          render={() => (
            <StoreServiceProvider>
              <OnboardingFranchiseModule />
            </StoreServiceProvider>
          )}
        />

        <PrivateRoutes
          authenticated={authenticated}
          authenticating={authenticating}
          featuresFlags={featuresFlags}
          merchant={merchant}
          merchantId={merchantId}
          isMerchant={isMerchant}
          store={store}
          locations={locations}
          locationsFetching={locationsFetching}
          initialLocationsFetchMade={initialLocationsFetchMade}
          roleV2={roleV2}
          user={user}
        />
        <Route path="*" component={() => <Redirect to="/auth" />} />
      </Switch>
    </React.Suspense>
  </ConnectedRouter>
);

export default connect<RouterProps, unknown, unknown, RootState>(({ root }) => ({
  authenticating: root.auth.fetching,
  authenticated: root.auth.authenticated,
  featuresFlags: root.merchant.data.featuresFlags,
  store: getStore(root.level.selected, root.locationDefault.data),
  merchant: root.merchant.data,
  merchantId: root.user.info?.data?.merchantId,
  isMerchant: root.level.isMerchant,
  locations: root.location.data,
  locationsFetching: root.location.fetching,
  initialLocationsFetchMade: root.location.initialFetchMade,
  roleV2: root.user.roleV2.data,
  user: root.user.info.data,
}))(Router);
