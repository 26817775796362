import * as React from 'react';
import { getIntlString, IntlMessage } from '@goparrot-dashboard/i18n';
import { FormField, InputNumber } from '@goparrot-dashboard/shared-ui';
import { round } from '@goparrot/order-sdk';
import { useSelector } from 'react-redux';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { RootState } from '@goparrot-dashboard/shared-utils';
import { IAbstractItemMods, ReadItemBaseDto as ItemBaseModel } from '@goparrot/storeitems-sdk';
import { useCheckboxProps, useInputProps, useFormFieldProps } from '@goparrot-dashboard/entity-form';
import { useMenuFormContext } from '../../../context';
import { useModsProps } from '../../../hooks';
import { FormSection } from '../FormSection';
import { findModIndex } from '../../../utils';
import { ItemAvailabilityWithAvailableAt } from '../ItemAvailabilityWithAvailableAt';
import { preventMinusKey } from '../utils';
import { ModsFields } from './interface';
import { ItemMod } from './ItemMod';

const getModFieldPath = (field: ModsFields, modIdx: number) => `mods[${modIdx}].${field}`;

type modsProps = {
  isAvailable?: boolean;
  isOrderable?: boolean;
  showPriceField?: boolean;
  showTaxField?: boolean;
};
export const Mods: React.FC<React.PropsWithChildren<modsProps>> = ({
  isAvailable = false,
  isOrderable = false,
  showPriceField = false,
  showTaxField = false,
}) => {
  const { form, selectedStoreId } = useMenuFormContext<ItemBaseModel>();
  const location = useSelector<RootState, ReadStoreDto>(({ root }) => root.location.data.find((l) => l.storeId === selectedStoreId));
  const { getFieldValue, state } = form;
  const idx = findModIndex(selectedStoreId, state.mods as IAbstractItemMods[]);
  const modIndex = idx === -1 ? state.mods.length : idx;
  const bindCheckedProps = useCheckboxProps(form, undefined, true);
  const bindInputProps = useInputProps(form);
  const bindFormFieldProps = useFormFieldProps(form);

  const getToggleTransformOptions = (field: string) => {
    return {
      transformToInput: (checked = true) => getFieldValue(field) && checked,
    };
  };
  const getIsDisabledOnMerchant = React.useCallback((field: any) => !bindCheckedProps(field).checked, [bindCheckedProps]);

  const isAvailableModProps = useModsProps<typeof bindCheckedProps>(bindCheckedProps, {
    path: getModFieldPath('isAvailable', modIndex),
    transformOptions: getToggleTransformOptions('isAvailable'),
  });
  const isAvailableAtModProps = useModsProps<typeof bindInputProps>(bindInputProps, {
    path: getModFieldPath('isAvailableAt', modIndex),
    transformOptions: getToggleTransformOptions('isAvailableAt'),
  });
  const isOrderableProps = useModsProps<typeof bindCheckedProps>(bindCheckedProps, {
    path: getModFieldPath('isOrderable', modIndex),
    transformOptions: getToggleTransformOptions('isOrderable'),
  });

  const priceProps = useModsProps<typeof bindInputProps>(bindInputProps, {
    path: getModFieldPath('price', modIndex),
    transformOptions: {
      transformToInput: (price?: number) => {
        return price;
      },
    },
  });

  const taxProps = useModsProps<typeof bindInputProps>(bindInputProps, {
    path: getModFieldPath('tax_percentage', modIndex),
    transformOptions: {
      transformToInput: (tax_percentage?: number) => {
        return tax_percentage;
      },
    },
  });
  const [price, setPrice] = React.useState<number | undefined>(getFieldValue(getModFieldPath('price', modIndex)) || null);
  const onPriceChange = (value: number | undefined) => {
    setPrice(value);
    priceProps.onChange(value);
  };
  const [taxVal, setTaxVal] = React.useState(taxProps.value * 100);
  const onTaxChange = (value) => {
    if (Number.isNaN(value)) {
      return;
    }
    if (value === undefined) {
      setTaxVal(null);
      taxProps.onChange(null);
      return;
    }
    setTaxVal(value);
    taxProps.onChange(round(value / 100));
  };

  const orderableToolTip = getIntlString('menu.form.orderable.tooltip', { isRootPath: true });
  const availabilityToolTip = getIntlString('menu.form.availableForPurchase.tooltip', { isRootPath: true });

  return (
    <>
      <FormSection
        title={
          <IntlMessage
            id="menu.form.section.mods"
            values={{
              storeTitle: location?.title,
              span: (chunks) => <span className="tw-text-gray-400">{chunks}</span>,
            }}
          />
        }
        subtitle={
          <IntlMessage
            id="menu.form.section.mods.subtitle"
            values={{
              storeTitle: location?.title,
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        }
        subtitleClassName="tw-inline-block tw-bg-orange-50 tw-text-xs tw-mt-1 tw-mb-0 tw-px-1.5 tw-py-1"
      >
        <div className="tw-flex-1 tw-rounded-r-inherit tw-px-4 tw-py-6 tw-border tw-rounded-md tw-border-blue-gray-200">
          <div className="tw-flex tw-items-center">
            {isOrderable && (
              <ItemMod
                title={getIntlString('menu.form.orderable', { isRootPath: true })}
                tooltipTitle={orderableToolTip}
                bindProps={isOrderableProps}
                disabled={!getFieldValue('isOrderable')}
                isDisabledOnMerchant={getIsDisabledOnMerchant('isOrderable')}
              />
            )}
            {isAvailable && (
              <ItemAvailabilityWithAvailableAt
                title={getIntlString('menu.form.availableForPurchase', { isRootPath: true })}
                tooltipTitle={availabilityToolTip}
                isModsMode={true}
                isAvailableModProps={isAvailableModProps}
                isAvailableAtModProps={isAvailableAtModProps}
              />
            )}
          </div>

          <div className="tw-mt-8">
            {showPriceField && (
              <FormField
                {...bindFormFieldProps(getModFieldPath('price', modIndex))}
                disabled={false}
                label={getIntlString('menu.form.price')}
                input={
                  <div className="tw-flex tw-justify-start tw-items-center tw-w-20 tw-h-full">
                    <InputNumber
                      {...priceProps}
                      value={price}
                      onChange={onPriceChange}
                      disabled={false}
                      onKeyDown={preventMinusKey}
                      name="price"
                      className="tw-pr-2"
                      placeholder="0"
                      decimalScale={2}
                    />
                    <span className="tw-leading-none">{getFieldValue('currency')}</span>
                  </div>
                }
              />
            )}
            {showTaxField && (
              <FormField
                {...bindFormFieldProps(getModFieldPath('tax_percentage', modIndex))}
                disabled={false}
                label={getIntlString('menu.form.tax-percentage')}
                input={
                  <div className="tw-flex tw-justify-start tw-items-center tw-w-20 tw-h-full">
                    <InputNumber
                      {...taxProps}
                      value={taxVal}
                      onChange={onTaxChange}
                      disabled={false}
                      name="tax_percentage"
                      className="tw-pr-2"
                      placeholder="0"
                      decimalScale={2}
                      onKeyDown={preventMinusKey}
                    />
                    <span className="tw-leading-none">%</span>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </FormSection>
    </>
  );
};
