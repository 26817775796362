import { CreateStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationCreateStoreItemGroupParams = {
  merchantId: string;
  storeId: string;
  data: CreateStoreItemGroupDto;
};

export const useLocationCreateStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, LocationCreateStoreItemGroupParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, LocationCreateStoreItemGroupParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationStoreItemGroupFacade.create(merchantId, storeId, data), options);
};
