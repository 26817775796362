import { useEffect, useRef } from 'react';

const TIMEOUT = 20 * 1000;

// This util will allow you to detect when device is back online after being in sleep mode
export const useWakeTime = (onWakeUp: () => void, timeOut = TIMEOUT): void => {
  const time = useRef(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(function () {
      const currentTime = new Date().getTime();
      if (currentTime > time.current + timeOut + 2000) {
        onWakeUp();
      }
      time.current = currentTime;
    }, timeOut);

    return () => {
      clearInterval(interval);
    };
  }, [onWakeUp, timeOut]);
};
