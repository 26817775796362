import * as React from 'react';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { DropResult } from 'react-beautiful-dnd';
import { PreviewConnection, PreviewConnectionComponentType } from '../AddConnectionsModal/preview/interface';
import { ConnectionItem } from '../../../interface';
import { BaseSelectedConnectionType } from '../interface';
import { UpdateCategoryActionsType } from '../../../hooks';

interface IConnectionsProviderProps<T> {
  title?: string;
  storeSpecificSettingsOpener?: React.ReactNode | null;
  selectedLevelStoreId: string;
  isMerchantLevelItem: boolean;
  currentConnections: ConnectionItem[];
  itemsList: ConnectionItem[];
  selectedItems: T[];
  handleUpdateSelection: (selection: ConnectionItem) => void;
  handleRemoveSelection: (uid: string) => void;
  previewConnections?: PreviewConnection[];
  isUpdatingConnection?: boolean;
  emptyTablePlaceholder?: string;
  onUpdateConnections: (connections: (T | string)[]) => void;
  disabled: boolean;
  storeId: string;
  merchantId: string;
  previewConnectionsComponent?: PreviewConnectionComponentType;
  showType?: boolean;
  transformToState?(connection: ConnectionItem): T;
  isDraggable: boolean;
  handleDragAndDrop?: (result: DropResult) => void;
  stores: ReadStoreDto[];
  children: React.ReactNode;
  showConnectionModal?: boolean;
  typedPreviewConnections?: boolean;
  setShowConnectionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  updateCategoryActions?: UpdateCategoryActionsType;
  showArchived?: boolean;
}

export type ConnectionsContextType<S> = Omit<IConnectionsProviderProps<S>, 'children'>;

const ConnectionsContext = React.createContext(undefined);

export const ConnectionsProvider = <T extends BaseSelectedConnectionType>({ children, ...props }: IConnectionsProviderProps<T>) => {
  const value: ConnectionsContextType<T> = { ...props };

  return <ConnectionsContext.Provider value={value}>{children}</ConnectionsContext.Provider>;
};

export const useConnectionsContext = <T extends BaseSelectedConnectionType>(): ConnectionsContextType<T> => {
  const context = React.useContext<ConnectionsContextType<T> | undefined>(ConnectionsContext);

  if (undefined === context) {
    console.error('useConnectionsContext must be used within a ConnectionsProvider');
  }
  return context;
};
