import { IRewriteRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdateStoreItemGroupCategoriesParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<string>;
};
export type LocationUpdateStoreItemGroupCategoriesResult = UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupCategoriesParams>;

export const useLocationStoreItemGroupUpdateCategories = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupCategoriesParams>,
): LocationUpdateStoreItemGroupCategoriesResult => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) =>
      await locationStoreItemGroupFacade.updateStoreItemGroupCategories(merchantId, storeId, uniqueName, data),
    options,
  );
};
