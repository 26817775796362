import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { ReadDefaultStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import React from 'react';
import { MENU_ITEMS } from '../../constants';
import { LocationItem, Menu, MerchantItem } from '../../interface';
import { filterItemOnSidebar } from '../utils';
import { BackToMerchantLevel } from './BackToMerchantLevel';
import { SidebarGroup } from './SidebarGroup';
import { SidebarItem } from './SidebarItem';

type Props = {
  selectedStore?: ReadStoreDto | ReadDefaultStoreDto;
  vendor?: ReadVendorDto;
  canAccessMerchantStore: boolean;
  onBack(): void;
  onChangeNavLink(navLinkName: string): void;
};

export const ListElements = React.memo<Props>(({ selectedStore, vendor, canAccessMerchantStore, onBack, onChangeNavLink }) => {
  const {
    selectedStoreId,
    merchant: { merchantId, featuresFlags, shortTermFeatureFlags },
    isMerchant,
  } = useStoreService();
  const { roleV2 } = useUserService();
  const menuItems: Menu<MerchantItem | LocationItem>[] = isMerchant ? MENU_ITEMS.MERCHANT : MENU_ITEMS.LOCATION;
  const storeId = selectedStoreId || merchantId;
  const featuresFlagList = { ...featuresFlags, ...shortTermFeatureFlags };

  return (
    <>
      {menuItems.map((element) => {
        const subItems = element.items.filter((item) => roleV2 && filterItemOnSidebar(item, roleV2, featuresFlagList, selectedStore, vendor));

        return subItems.length ? (
          <li key={element.categoryName} className="tw-mb-6 last:tw-mb-0">
            <SidebarGroup className="tw-pr-1.5 tw-mb-5" title={element.categoryName} menuItems={subItems}>
              {subItems.map((item) => {
                return (
                  <div key={item.label}>
                    {!isMerchant && canAccessMerchantStore && item.label === 'sidebar.merchant.home' && <BackToMerchantLevel onClick={onBack} />}
                    <SidebarItem
                      item={item}
                      storeId={storeId}
                      onClick={onChangeNavLink}
                      isMenuSettingsPageEnabled={!!shortTermFeatureFlags?.isMenuSettingsPageEnabled}
                    />
                  </div>
                );
              })}
            </SidebarGroup>
          </li>
        ) : null;
      })}
    </>
  );
});
