import { getIntlString } from '@goparrot-dashboard/i18n';
import { ReadStoreItemDto, ReadStoreItemGroupDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';

export enum ORDERABILITY_VALUES {
  ORDERABLE = 'ORDERABLE',
  NON_ORDERABLE = 'NON_ORDERABLE',
}
export const SHOW_STORE_SPECIFIC_MODAL_PARAM = 'showStoreSpecificModal';
export const SELECTED_MODIFIER_PARAM = 'selectedModifier';

export type StoreSpecificItem = ReadStoreItemDto | ReadStoreItemGroupDto | ReadItemBaseDto;
export type ReplicaUpdateDataFields = { price?: number; isOrderable?: boolean; title?: string };
export type ReplicasUpdateData = Record<string, ReadStoreItemDto>;

export const ORDERABILITY_OPTIONS = [
  {
    value: ORDERABILITY_VALUES.ORDERABLE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability.orderable`),
  },
  {
    value: ORDERABILITY_VALUES.NON_ORDERABLE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability.nonOrderable`),
  },
];

export enum ACTIONS_VALUES {
  UPDATE_PRICE = 'UPDATE_PRICE',
  UPDATE_TITLE = 'UPDATE_TITLE',
  UPDATE_ORDERABLE = 'UPDATE_ORDERABLE',
  REVERT_SETTINGS = 'REVERT_SETTINGS',
  DELETE = 'DELETE',
}

export type ActionData = {
  action: ACTIONS_VALUES | null;
  isArchived: boolean;
  title: string;
  price: number;
  isOrderable: ORDERABILITY_VALUES;
};

export const ACTIONS_OPTIONS = [
  {
    value: ACTIONS_VALUES.UPDATE_PRICE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.actions.price`),
  },
  {
    value: ACTIONS_VALUES.UPDATE_TITLE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.actions.title`),
  },
  {
    value: ACTIONS_VALUES.UPDATE_ORDERABLE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.actions.orderable`),
  },
  {
    value: ACTIONS_VALUES.REVERT_SETTINGS,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.actions.revert`),
  },
  {
    value: ACTIONS_VALUES.DELETE,
    label: getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.actions.delete`),
  },
];
