import { getIntlString } from '@goparrot-dashboard/i18n';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { ALWAYS_EDITABLE_PROPERTIES, EditablePropertiesEnum } from '@goparrot/menu-orchestrator-sdk';
import { CATEGORIES_FILTER_KEY, FilterSettingsType, ITEM_SETS_FILTER_KEY, LOCATIONS_FILTER_KEY } from '@goparrot-dashboard/shared-utils';
import Left_Active from './assets/Left_Active.svg';
import Left_Inactive from './assets/Left_Inactive.svg';
import Right_Active from './assets/Right_Active.svg';
import Rigth_Inactive from './assets/Rigth_Inactive.svg';
import Whole_Active from './assets/Whole_Active.svg';
import Whole_Inactive from './assets/Whole_Inactive.svg';
import Slice_Active from './assets/Slice_Active.svg';
import Slice_Inactive from './assets/Slice_Inactive.svg';

export const SCHEDULING_ALWAYS_EDIATBLE = ALWAYS_EDITABLE_PROPERTIES?.includes(EditablePropertiesEnum.METADATA_CRITERIA);
export const SHOW_ORDER_ALWAYS_EDIATBLE = ALWAYS_EDITABLE_PROPERTIES?.includes(EditablePropertiesEnum.SHOW_ORDER);
export const TAX_PERCENTAGE_ALWAYS_EDIATBLE = ALWAYS_EDITABLE_PROPERTIES?.includes(EditablePropertiesEnum.TAX_PERCENTAGE);
export const BASE_MENU_PATH = '/dashboard/menu-management';

export const MENU_ELEMENTS = {
  ITEM_SET: getIntlString('menu.item.type.itemSet'),
  ITEM_SET_CONCEPT: getIntlString('menu.item.type.menuConcept'),
  STORE_ITEM_GROUP: getIntlString('menu.item.type.storeItemGroup'),
  STORE_ITEM_BUNDLE: getIntlString('menu.item.type.comboGroup'),
  CATEGORY: getIntlString('menu.item.type.category'),
  STORE_ITEM: getIntlString('menu.item.type.storeItem'),
  ITEM_OPTIONS: getIntlString('menu.item.type.itemOptions'),
  ITEM_BASE: getIntlString('menu.item.type.itemBase'),
  ITEM_BASE_GROUP: getIntlString('menu.item.type.itemBaseGroup'),
  TAXES: 'Tax',
  VARIATION: 'Item Variation',
  VARIATION_GROUP: 'Item Variation Group',
  COMBO_GROUP: getIntlString('menu.item.type.comboGroup'),
  COMBO: getIntlString('menu.item.type.combo'),
};

export const MENU_SHORTCUTS = {
  COMBO: 'combo',
  COMBO_GROUP: 'comboGroup',
  ITEM_SET_CONCEPT: 'itemSetConcept',
};

export const MENU_ELEMENTS_BY_TYPE = {
  [StoreItemTypeEnum.STORE_ITEM_BUNDLE]: MENU_ELEMENTS.COMBO_GROUP,
  [StoreItemTypeEnum.COMBO]: MENU_ELEMENTS.COMBO,
  [StoreItemTypeEnum.STORE_ITEM]: MENU_ELEMENTS.STORE_ITEM,
  [StoreItemTypeEnum.CATEGORY]: MENU_ELEMENTS.CATEGORY,
  [StoreItemTypeEnum.STORE_ITEM_GROUP]: MENU_ELEMENTS.STORE_ITEM_GROUP,
  [StoreItemTypeEnum.ITEM_OPTIONS]: MENU_ELEMENTS.ITEM_OPTIONS,
};
export const TAXES = 'taxes';
export const TAX = 'tax';

export const AVAILABLE_AT_TIME_FORMAT = `h:00 a`;
export const FORMAT_DATE = 'YYYY-MM-DD';
export const AVAILABLE_AT_DATE_TIME_FORMAT = `MMM d, ${AVAILABLE_AT_TIME_FORMAT}`;
export const IS_AVAILABLE = 'isAvailable';

export const MENU_MANAGEMENT_DEFAULT_ICONS = [
  {
    name: 'left half',
    selectedIcon: `${Left_Active}`,
    icon: `${Left_Inactive}`,
    default: true,
  },
  {
    name: 'right half',
    selectedIcon: `${Right_Active}`,
    icon: `${Rigth_Inactive}`,
    default: true,
  },
  {
    name: 'whole',
    selectedIcon: `${Whole_Active}`,
    icon: `${Whole_Inactive}`,
    default: true,
  },
  {
    name: 'slice',
    selectedIcon: `${Slice_Active}`,
    icon: `${Slice_Inactive}`,
    default: true,
  },
];

export const PAGINATION = { skip: 0, take: 100000 };

export const MM_DEFAULT_FILTERS = [
  {
    field: 'isArchived',
    label: 'Archived',
    selectedItems: [{ value: false, label: getIntlString('page.merchant.menu.filters.archived.onlyNot') }],
    items: [
      { value: true, label: getIntlString('page.merchant.menu.filters.archived.only') },
      { value: false, label: getIntlString('page.merchant.menu.filters.archived.onlyNot') },
    ],
  },
  {
    field: 'isAvailable',
    label: 'Available',
    selectedItems: [],
    items: [
      { value: true, label: getIntlString('page.merchant.menu.filters.available.only') },
      { value: false, label: getIntlString('page.merchant.menu.filters.available.onlyNot') },
    ],
  },
  {
    field: 'isOrderable',
    label: 'Orderable',
    selectedItems: [],
    items: [
      { value: true, label: getIntlString('page.merchant.menu.filters.orderable.only') },
      { value: false, label: getIntlString('page.merchant.menu.filters.orderable.onlyNot') },
    ],
  },
];

export const POS_GUID_LENGTH = 24;

export const MENU_FILTER: FilterSettingsType = {
  field: ITEM_SETS_FILTER_KEY,
  label: getIntlString('menu.item.type.itemSet'),
  selectedItems: [],
  items: [],
  hasSearch: true,
};

export const LOCATIONS_FILTER: FilterSettingsType = {
  field: LOCATIONS_FILTER_KEY,
  label: getIntlString('page.merchant.menu.location'),
  selectAllLabel: getIntlString('page.merchant.menu.item.type.allLocation'),
  selectedItems: [],
  items: [],
  hasSearch: true,
};

export const CATEGORY_FILTER: FilterSettingsType = {
  field: CATEGORIES_FILTER_KEY,
  label: getIntlString('menu.item.type.category'),
  selectedItems: [],
  items: [],
  hasSearch: true,
};

export const LOCATION_GROUPS_FILTER: FilterSettingsType = {
  field: 'isLocationGroup',
  selectAllLabel: getIntlString('page.merchant.menu.item.type.allLocation'),
  label: getIntlString('page.merchant.menu.item.type.allLocation'),
  selectedItems: [],
  items: [],
  hasSearch: true,
};

export const IS_REPLICA_FILTER: FilterSettingsType = {
  field: 'isReplica',
  label: getIntlString('page.merchant.menu.item.type.replica'),
  selectedItems: [],
  items: [
    { value: true, label: getIntlString('page.merchant.menu.item.type.replica.filter.check') },
    { value: false, label: getIntlString('page.merchant.menu.item.type.replica.filter.uncheck') },
  ],
};
