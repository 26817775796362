import { UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemSetFacade } from '../../../facades';

export type MerchantUpdateItemSetCategoriesShowOrderParams = {
  merchantId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export type MerchantUpdateItemSetCategoriesShowOrderResult = UseMutationResult<
  UpdateShowOrderResponseDto,
  unknown,
  MerchantUpdateItemSetCategoriesShowOrderParams
>;

export const useUpdateMerchantItemSetCategoriesShowOrder = (
  options: UseMutationOptions<UpdateShowOrderResponseDto, unknown, MerchantUpdateItemSetCategoriesShowOrderParams>,
): MerchantUpdateItemSetCategoriesShowOrderResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemSetFacade.updateCategoriesShowOrder(merchantId, uniqueName, data), options);
};
