import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { squareFacade } from '../facades';

type RevokeSquareAuthParams = {
  storeId: string;
};
type RevokeSquareAuthResult = void;

export const useRevokeSquareAuth = (
  options?: UseMutationOptions<RevokeSquareAuthResult, unknown, RevokeSquareAuthParams>,
): UseMutationResult<RevokeSquareAuthResult, unknown, RevokeSquareAuthParams> => {
  return useMutation(({ storeId }) => squareFacade.revokeSquareAuth(storeId), options);
};
