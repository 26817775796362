const c = {
  LOCAL__TIME__ZONE__SELECT: 'LOCAL__TIME__ZONE__SELECT',
};
const a = {
  setTimeZone: (timeZone = '') => ({
    type: c.LOCAL__TIME__ZONE__SELECT,
    payload: {
      timeZone,
    },
  }),
};

export default { ...c, ...a };
