import { PatchCategoryDto, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationUpdateCategoryParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchCategoryDto;
};
export const useLocationUpdateCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, LocationUpdateCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, LocationUpdateCategoryParams> => {
  return useMutation(async ({ merchantId, storeId, uniqueName, data }) => await locationCategoryFacade.patch(merchantId, storeId, uniqueName, data), options);
};
