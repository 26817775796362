import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useI18n } from '@goparrot-dashboard/i18n';
import { IconBack } from '@goparrot-dashboard/shared-ui';
import classNames from 'classnames';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import { MERCHANT_HOME_PATH } from '../constants';
import { getMerchantLevelRedirectUrl } from '../../navbar';

type BackToMerchantLevelProps = {
  onClick: () => void;
};

export const BackToMerchantLevel: FC<React.PropsWithChildren<BackToMerchantLevelProps>> = ({ onClick }) => {
  const { getIntlString } = useI18n();
  const { merchant } = useStoreService();
  const { roleV2 } = useUserService();

  return (
    <NavLink
      exact
      key={'back-to-merchant'}
      activeClassName="tw-text-cyan-500 tw-bg-cyan-100"
      className={classNames([
        'tw-flex tw-items-start tw-px-1.5 tw-py-3 tw-whitespace-normal',
        'tw-text-black tw-leading-4 lg:hover:tw-text-blue-500 lg:hover:tw-opacity-100',
        'tw-transform paragraph-20 tw-rounded-lg',
      ])}
      to={getMerchantLevelRedirectUrl(merchant?.merchantId, roleV2 as IReadRoleV2Dto) ?? MERCHANT_HOME_PATH}
      onClick={onClick}
    >
      <span className={'tw-flex tw-mr-2 tw-pt-0.5'}>
        <IconBack />
      </span>
      <span className="tw-inline-block semibold-30 tw-leading-5">{getIntlString('button.backToMerchant', { isRootPath: true })}</span>
    </NavLink>
  );
};
