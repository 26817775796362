/* eslint-disable camelcase */
import React from 'react';
import { SuccessCloudinaryResponseDto, CloudinaryRequestDto } from '@goparrot/dashboard-gateway-sdk';
import { CloudinaryPresetNameEnum, ImageConfig, isStore } from '@goparrot-dashboard/shared-utils';

import type { MarketNotifier } from '@goparrot-dashboard/shared-ui';
import { signCloudinaryParams } from 'helpers/api';
import { IntlMessage } from 'components/commons';

export * from './isProductionEnv';

export const isValidDate: (date: any) => date is Date = (date: any): date is Date => date instanceof Date && !Number.isNaN(date);

export const getSelected = (props, router) => {
  const { id } = router.match.params;
  let isFetching = false;
  if (props.merchant && props.merchant.data.storeId === id) {
    isFetching = props.merchant.fetching;
    return { fetching: isFetching, data: props.merchant.data };
  }
  if (props.location) {
    isFetching = props.location.fetching;
    const selected = props.location.data.find((item) => item.storeId === id);
    if (selected || isFetching) return { fetching: isFetching, data: selected };
  }
  if (props.locationDefault) {
    isFetching = props.locationDefault.fetching;
    const selected = props.locationDefault.data.find((item) => item.storeId === id);
    if (selected) return { fetching: isFetching, data: selected };
  }
  if (props.users) {
    isFetching = props.users.fetching;
    const selected = props.users.data.find((item) => item.uuid === id);
    if (selected) return { fetching: isFetching, data: selected };
  }
  if (props.promotions) {
    isFetching = props.promotions.fetching;
    const selected = props.promotions.data.find((item) => item.uuid === id); // eslint-disable-line
    if (selected) return { fetching: isFetching, data: selected };
  }
  return { fetching: isFetching };
};

export const getLocationLevelProps = (state, router) => {
  const selected = getSelected(state.root, router);
  let defaultForSelected;
  if (selected.data && isStore(selected.data)) {
    defaultForSelected = state.root.locationDefault.data.find((item) => item.storeId === selected.data.defaultStoreId);
  }

  return {
    selected,
    defaultForSelected,
  };
};

export function getImageOrFallback(url: string, fallback: string): Promise<unknown> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      resolve(url);
    } else {
      img.onload = () => resolve(url);

      img.onerror = () => {
        reject(`image not found for url ${url}`);
      };
    }
  }).catch(() => {
    return fallback;
  });
}

export function getFaviconImageConfig(maxWidth = 500, maxHeight = 500): ImageConfig {
  return {
    upload_preset: window._env_.CLOUDINARY_FAVICONS_PRESET_NAME as CloudinaryPresetNameEnum.FAVICONS,
    max_image_width: maxWidth,
    max_image_height: maxHeight,
  };
}

export function getEmailConstructorImageConfig(maxWidth = 5000, maxHeight = 5000): ImageConfig {
  return {
    upload_preset: window._env_.CLOUDINARY_EMAIL_CONSTRUCTOR_PRESET_NAME as CloudinaryPresetNameEnum.CONSTRUCTOR,
    max_image_width: maxWidth,
    max_image_height: maxHeight,
  };
}

export async function uploadSignature(cb, paramsToSign: CloudinaryRequestDto) {
  const params: SuccessCloudinaryResponseDto = await signCloudinaryParams(paramsToSign);
  return cb(params.signature);
}

export const getCloudinaryImageUploadConfig = (merchantId: string, folderPath: string, imageParams: ImageConfig) => ({
  ...imageParams,
  upload_signature: uploadSignature,
  api_key: window._env_.CLOUDINARY_API_KEY,
  multiple: false,
  cropping: true,
  sources: ['local', 'url'],
  croppingCoordinatesMode: 'custom',
  max_file_size: 2097152,
  folder: `${merchantId}/${folderPath}/`,
  resource_type: 'image',
  clientAllowedFormats: ['png', 'gif', 'jpeg', 'jpg', 'webp'],
  cropping_validate_dimensions: true,
  show_powered_by: false,
  cropping_show_dimensions: true,
  ...(imageParams.upload_preset === CloudinaryPresetNameEnum.SQUARE && { cropping_aspect_ratio: 1 }),
  cropping_default_selection_ratio: 1,
});

export type WidgetUploadResultType = {
  files: Record<string, any>[];
  isCloseEvent: boolean;
  widget: {
    destroy(): void;
  };
};

export const handleCloudinaryImageUpload = (imageOptions): Promise<WidgetUploadResultType> =>
  new Promise((resolve, reject) => {
    const widget = window.cloudinary.openUploadWidget(imageOptions, (error, result) => {
      const { files } = result.data?.info ?? {};
      const isCloseEvent = 'close' === result.event;

      if (!error && (files || isCloseEvent)) {
        resolve({ files, widget, isCloseEvent });
      }
      if (error) {
        reject();
      }
    });
  });

// TODO: provide single function for upload handling
export const onImageUploadEmailContructor = (params: ImageConfig, folderPath: string, merchantId: string): Promise<any> =>
  new Promise((resolve) => {
    const options = {
      ...params,
      upload_signature: uploadSignature,
      api_key: window._env_.CLOUDINARY_API_KEY,
      cloud_name: window._env_.CLOUDINARY_CLOUD_NAME,
      multiple: false,
      cropping: true,
      croppingCoordinatesMode: 'custom',
      max_file_size: 5242880,
      folder: `${merchantId}/${folderPath}/`,
      resource_type: 'image',
      client_allowed_formats: ['png', 'gif', 'jpeg', 'jpg', 'webp'],
      cropping_validate_dimensions: true,
      show_powered_by: false,
      cropping_show_dimensions: true,
      cropping_default_selection_ratio: 1,
    };
    window.cloudinary.openUploadWidget(options, (error, result) => {
      const { files } = result.data?.info ?? {};
      if (!error && Array.isArray(files)) {
        resolve(files);
      }
    });
  });

export const ALLOWED_FAVICONS = [
  { name: 'favicon-16x16.png', humanReadableSize: '16x16px' },
  { name: 'favicon-32x32.png', humanReadableSize: '32x32px' },
  { name: 'apple-touch-icon.png', humanReadableSize: '180x180px' },
];

export const onFaviconsUpload = (params: ImageConfig, folderPath: string, merchantId: string, storeId: string, notifyError: MarketNotifier): Promise<any> =>
  new Promise((resolve, reject) => {
    const options = {
      ...params,
      upload_signature: uploadSignature,
      api_key: window._env_.CLOUDINARY_API_KEY,
      cloud_name: window._env_.CLOUDINARY_CLOUD_NAME,
      max_file_size: 2097152,
      folder: `${merchantId}/${storeId}/${folderPath}/`,
      resource_type: 'image',
      client_allowed_formats: ['png'],
      show_powered_by: false,
      multiple: true,
      maxFiles: 3,
      preBatch: (cb, data) => {
        if (data.files.length !== 3) {
          notifyError({ text: 'Please upload all 3 images' });
          cb({ cancel: true });
        }
        const validFileNames = data.files.map((file) => !!ALLOWED_FAVICONS.find((item) => item.name === file.name));

        if (validFileNames.includes(false)) {
          notifyError({ text: 'There are files with invalid names. Please check the tooltip for Favicons Upload' });
          cb({ cancel: true });
        } else {
          cb();
        }
      },
    };
    const widget = window.cloudinary.openUploadWidget(options, (error, result) => {
      const { files } = result.data?.info ?? {};
      const isCloseEvent = 'close' === result.event;

      if (!error && (files || isCloseEvent)) {
        resolve({ files, widget, isCloseEvent });
      }
      if (error) {
        reject();
      }
    });
  });

export const onWebstoreFontsUpload = (folderPath: string, merchantId: string) =>
  new Promise((resolve, reject) => {
    const options = {
      upload_preset: window._env_.CLOUDINARY_RAW_FILES_PRESET_NAME as CloudinaryPresetNameEnum.RAW_FILES,
      upload_signature: uploadSignature,
      api_key: window._env_.CLOUDINARY_API_KEY,
      cloud_name: window._env_.CLOUDINARY_CLOUD_NAME,
      multiple: false,
      cropping: false,
      max_file_size: 2097152,
      folder: `${merchantId}/${folderPath}/`,
      resource_type: 'raw',
      client_allowed_formats: ['otf', 'ttf', 'woff', 'woff2'],
      show_powered_by: false,
    };

    const widget = window.cloudinary.openUploadWidget(options, (error, result) => {
      const { files } = result.data?.info ?? {};
      const isCloseEvent = 'close' === result.event;

      if (!error && (files || isCloseEvent)) {
        resolve({ files, widget, isCloseEvent });
      }
      if (error) {
        reject();
      }
    });
  });

export const onDefaultImageUpload = (params: ImageConfig, folderPath: string, merchantId: string, storeId?: string) =>
  new Promise((resolve, reject) => {
    const options = {
      ...params,
      upload_signature: uploadSignature,
      api_key: window._env_.CLOUDINARY_API_KEY,
      multiple: false,
      cropping: true,
      sources: ['local', 'url'],
      croppingCoordinatesMode: 'custom',
      max_file_size: 4194304,
      folder: storeId ? `${merchantId}/${storeId}/${folderPath}/` : `${merchantId}/${folderPath}/`,
      resource_type: 'image',
      clientAllowedFormats: ['png', 'gif', 'jpeg', 'jpg', 'webp'],
      cropping_validate_dimensions: true,
      show_powered_by: false,
      cropping_show_dimensions: true,
      ...(params.upload_preset === CloudinaryPresetNameEnum.SQUARE && { cropping_aspect_ratio: 1 }),
      cropping_default_selection_ratio: 1,
    };
    const widget = window.cloudinary.openUploadWidget(options, (error, result) => {
      const { files } = result.data?.info ?? {};
      const isCloseEvent = 'close' === result.event;

      if (!error && (files || isCloseEvent)) {
        resolve({ files, widget, isCloseEvent });
      }
      if (error) {
        reject();
      }
    });
  });

export const getIntlMessage = (basePath: string) => (path = '') => <IntlMessage id={`${basePath}.${path}`} />;

export const truncateText = (text: string, words = 50): string => {
  if (!text) return '';
  if (text.length >= words) {
    return `${text.substring(0, words)}...`;
  }
  return text;
};
