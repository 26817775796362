import { IRewriteRelationsRequest, UpdateRelationsResponseDto, IStoreItemGroupProperties } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdatePropertiesStoreItemGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: IRewriteRelationsRequest<IStoreItemGroupProperties>;
};

export type MerchantUpdatePropertiesStoreItemGroupResult = UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdatePropertiesStoreItemGroupParams>;

export const useMerchantUpdatePropertiesStoreItemGroup = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdatePropertiesStoreItemGroupParams>,
): MerchantUpdatePropertiesStoreItemGroupResult => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.updateProperties(merchantId, uniqueName, data), options);
};
