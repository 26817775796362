import React from 'react';
import { FormField, Input, LabelWithTooltip } from '@goparrot-dashboard/shared-ui';
import { useFormFieldProps, useNativeInputProps } from '@goparrot-dashboard/entity-form';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useMenuFormContext } from '../../context';
import { hasFranchiseTag, getMenuFranchisePermissions } from '../../utils';
import { Item } from '../../interface';
import { InputProps, ReturnValue } from './interface';
import { getMenuFormIntlString } from './utils';

type DescriptionFieldType = 'description' | 'subtitle' | 'metadata.additional_info';

type Props = InputProps & {
  field?: DescriptionFieldType;
  tooltipLabel?: string;
};

export const ItemDescription = <T extends Record<DescriptionFieldType, string>>({
  field = 'description',
  label,
  placeholder,
  tooltipLabel,
}: Props): ReturnValue => {
  const { form, query, isFranchise, franchisePermissions, isMerchant } = useMenuFormContext<T, Item>();
  const { franchiseLocations } = useStoreService();
  const { roleV2 } = useUserService();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindNativeInputProps = useNativeInputProps(form);
  const descriptionProps = bindNativeInputProps(field);
  const isItemReplica = isFranchise && hasFranchiseTag(query?.data);
  const hasFranchisePermission = isMerchant
    ? getMenuFranchisePermissions({ isFranchise, storeId: query?.data?.storeId, franchiseLocations, userRole: roleV2 })?.canEditLabels
    : franchisePermissions?.canEditLabels;

  return (
    <FormField
      {...bindFormFieldProps(field)}
      label={
        tooltipLabel ? (
          <LabelWithTooltip tooltip={tooltipLabel}>{label || getMenuFormIntlString('description')}</LabelWithTooltip>
        ) : (
          label || getMenuFormIntlString('description')
        )
      }
      withValuePadding={false}
      disabled={isItemReplica ? !hasFranchisePermission : descriptionProps.disabled}
      input={
        <div className="tw-pt-3 tw-w-full">
          <Input
            as="textarea"
            {...descriptionProps}
            disabled={isItemReplica ? !hasFranchisePermission : descriptionProps.disabled}
            className="tw-outline-none tw-w-full tw-px-4"
            placeholder={placeholder || getMenuFormIntlString('description.placeholder')}
            rows={4}
          />
        </div>
      }
    />
  );
};
