import { ConfirmModal, FormField, Select } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DialogStateReturn } from 'reakit/Dialog';
import { useStoreService } from '@goparrot-dashboard-core/store-service';

import { useGiftCardOrchestrationContext } from '../../services';
import { useCreateGiftCardLocations } from '../../hooks';

import { GiftCardLocationsTable } from './GiftCardLocationsTable';

type GiftCardLocationsProps = {
  addLocationsDialog: DialogStateReturn;
};

export const GiftCardLocations: FC<React.PropsWithChildren<GiftCardLocationsProps>> = ({ addLocationsDialog }) => {
  const {
    merchant: { merchantId },
  } = useStoreService();

  const { getIntlString } = useI18n();

  const { fetchLocations, locations } = useGiftCardOrchestrationContext();

  // add multiple location
  const [locationsToAdd, setLocationsToAdd] = useState<string[]>([]);

  const onLocationsCreatedsuccessful = useCallback(() => {
    fetchLocations();
    addLocationsDialog.hide();
  }, [addLocationsDialog, fetchLocations]);

  React.useEffect(() => {
    if (!addLocationsDialog.visible) {
      setLocationsToAdd([]);
    }
  }, [addLocationsDialog.visible]);

  const { createLocations } = useCreateGiftCardLocations(onLocationsCreatedsuccessful);

  const submitAddLocations = () => {
    if (locationsToAdd.length) {
      createLocations(
        merchantId,
        locationsToAdd.map((storeId) => ({ storeId })),
      );
    } else {
      addLocationsDialog.hide();
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const locationsSelectOptions = useMemo(
    () =>
      locations
        .filter((store) => !store.franchiseLocation)
        .map((location) => ({
          label: location.name ?? location.title,
          value: location.storeId,
        })),
    [locations],
  );
  useEffect(() => {
    if (!addLocationsDialog.visible) {
      setLocationsToAdd([]);
    }
  }, [addLocationsDialog.visible]);

  return (
    <>
      <ConfirmModal
        key="addLocationsModal"
        testId="franchise-management-gift-card-add-location-modal"
        dialog={addLocationsDialog}
        header={getIntlString('locations.addLocationModal.title')}
        cancelText={getIntlString('page.merchant.orchestration.locations.modalCancelText', { isRootPath: true })}
        confirmText={getIntlString('page.merchant.orchestration.locations.modalOkText', { isRootPath: true })}
        onConfirm={submitAddLocations}
        content={
          <FormField
            testId="franchise-management-gift-card-location-selector"
            label={getIntlString('locations.chooseLabel')}
            withValuePadding={false}
            input={
              <Select
                isMulti
                options={locationsSelectOptions}
                value={locationsToAdd}
                onChange={(locations: string[]) => setLocationsToAdd(locations)}
                closeMenuOnSelect={false}
                renderMultiValueLabel={(count) => `${count?.length || 0} stores selected`}
                selectedValuesMaxCount={3}
                menuPortalTarget={document.body}
              />
            }
          />
        }
      />
      <GiftCardLocationsTable />
    </>
  );
};
