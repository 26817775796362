import { StoreItemListResponseDto, IPagination, IMerchantFilterParams } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { merchantStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type MerchantStoreItemListParams = { merchantId: string; pagination?: IPagination; params?: IMerchantFilterParams };

export const useMerchantStoreItemList = (
  { merchantId, pagination, params }: MerchantStoreItemListParams,
  options?: UseQueryOptions<StoreItemListResponseDto>,
): UseQueryResult<StoreItemListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_LIST, merchantId, pagination, params],
    async () => await merchantStoreItemFacade.list(merchantId, pagination, params),
    options,
  );
};
