import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { merchantItemBaseGroupFacade } from '../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { GetRelativesQueryBaseParams } from '../../interface';

export const useGetMerchantItemBaseGroupParentsQuery = (
  { merchantId, uniqueName }: GetRelativesQueryBaseParams,
  options?: UseQueryOptions<ReadItemOptionsDto[], Error>,
): UseQueryResult<ReadItemOptionsDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_GROUP_GET_PARENTS, merchantId, uniqueName],
    () => merchantItemBaseGroupFacade.getParents(merchantId, uniqueName),
    options,
  );
};
