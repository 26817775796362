import type { ReadStoreItemBundleDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemBundleFacade } from '../../../facades';
import { ComboGroupUpdateMutationParams } from '../../../interface';

export const useUpdateMerchantComboGroup = (
  options?: UseMutationOptions<ReadStoreItemBundleDto, unknown, ComboGroupUpdateMutationParams>,
): UseMutationResult<ReadStoreItemBundleDto, unknown, ComboGroupUpdateMutationParams> => {
  return useMutation(({ merchantId, uniqueName, data }) => merchantStoreItemBundleFacade.patch(merchantId, uniqueName, data), options);
};
