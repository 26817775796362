import { UpdateShowOrderDto, UpdateShowOrderResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantUpdateStoreItemGroupChildrenOrderParams = {
  merchantId: string;
  uniqueName: string;
  data: UpdateShowOrderDto;
};

export const useMerchantUpdateStoreItemGroupChildrenOrder = (
  options: UseMutationOptions<UpdateShowOrderResponseDto, unknown, MerchantUpdateStoreItemGroupChildrenOrderParams>,
): UseMutationResult<UpdateShowOrderResponseDto, unknown, MerchantUpdateStoreItemGroupChildrenOrderParams> => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.updatePropertiesShowOrder(merchantId, uniqueName, data),
    options,
  );
};
