import type { UpdateRelationsResponseDto, IUpdateRelationsRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseFacade } from '../../../facades';

type MerchantUpdateItemBaseOptionSelectionParams = {
  merchantId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest<string>;
};

export const useMerchantUpdateItemBaseOptionSelection = (
  options?: UseMutationOptions<UpdateRelationsResponseDto, unknown, MerchantUpdateItemBaseOptionSelectionParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, MerchantUpdateItemBaseOptionSelectionParams> => {
  return useMutation(
    async ({ merchantId, uniqueName, data }) => await merchantItemBaseFacade.updateItemOptionsSelections(merchantId, uniqueName, data),
    options,
  );
};
