import { CreateLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { locationFacade } from '../api/location';

export function useCreateManyLocations(
  onSuccess: (locationStoreIds: string[]) => void = () => null,
): {
  createMany(merchantId: string, data: CreateLocationDto[], syncGridLayout?: boolean): void;
} {
  const { notifyError, notifySuccess } = useNotifications();
  const createMany = async (merchantId: string, data: CreateLocationDto[], syncGridLayout?: boolean) => {
    try {
      await locationFacade.createMany(merchantId, data, syncGridLayout);
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.location.createMany.success.message'),
      });
      onSuccess(data.map((location) => location.storeId));
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.location.createMany.error.message'),
      });
    }
  };
  return { createMany };
}
