import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { vendorFacade } from '../facades';

type DeleteVendoStoreParams = {
  storeId: string;
};
type DeleteVendoStoreResult = void;

export const useDeleteVendor = (
  options?: UseMutationOptions<DeleteVendoStoreResult, unknown, DeleteVendoStoreParams>,
): UseMutationResult<DeleteVendoStoreResult, unknown, DeleteVendoStoreParams> => {
  return useMutation(({ storeId }) => vendorFacade.delete(storeId), options);
};
