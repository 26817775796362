import { ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationSelectionTypesFacade } from '../../../facades';

export type LocationDeleteSelectionTypeParams = {
  merchantId: string;
  storeId: string;
};

export const useLocationDeleteSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, LocationDeleteSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, LocationDeleteSelectionTypeParams> => {
  return useMutation(async ({ merchantId, storeId }) => await locationSelectionTypesFacade.delete(merchantId, storeId), options);
};
