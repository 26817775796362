import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';

import { giftCardCentralLocationFacade } from '../api';

export const useCreateGiftCardCentralLocation = (
  onSuccess: () => void = () => null,
): {
  createCentralLocation: (merchantId: string, storeId: string) => void;
} => {
  const { notifyError, notifySuccess } = useNotifications();
  const createCentralLocation = async (merchantId: string, storeId: string) => {
    try {
      await giftCardCentralLocationFacade.set(merchantId, {
        storeId,
      });
      notifySuccess({
        text: getIntlString('page.merchant.franchise.notification.central.create.success.message'),
      });
      onSuccess();
    } catch (e) {
      notifyError({
        text: getIntlString('page.merchant.franchise.notification.central.create.error.message'),
      });
    }
  };

  return { createCentralLocation };
};
