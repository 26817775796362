import { getValueTransformers } from '../../utils/valueTransformers';
import { EntityFormResult, BaseInputBindingsOptions, InputBindingsMethod } from '../../types';

export type UseInputPropsResult = {
  value: any;
  hasError: boolean;
  disabled: boolean;
  onChange(value?: any): void;
  onBlur(): void;
};
export type UseInputPropsOptions = BaseInputBindingsOptions;

/**
 * @description Use this hook only when `onChange` is called with expected value, not an event
 */
export function useInputProps<T>(
  hook: EntityFormResult<T>,
  options?: UseInputPropsOptions,
): InputBindingsMethod<T, BaseInputBindingsOptions, UseInputPropsResult> {
  const useInputPropsForPath: InputBindingsMethod<T, BaseInputBindingsOptions, UseInputPropsResult> = (path, inputOptions) => {
    const valueTransformers = getValueTransformers<T>(hook, inputOptions || options);
    return {
      value: valueTransformers.toInput(path),
      hasError: hook.hasError(path) && hook.isDirtyField(path),
      onChange: (value) => valueTransformers.toState(path, value),
      onBlur: () => hook.onFieldBlur(path),
      disabled: hook.isDisabledField(path),
    };
  };
  return useInputPropsForPath;
}
