import { CreateUserDto as CreateUserModel, InviteEmailUserDto as InviteUserModel, PatchUserDto as PatchUserModel } from '@goparrot/users-v2-sdk';

(InviteUserModel.prototype as any).init = function (options: Partial<InviteUserModel>) {
  this.merchantId = options?.merchantId;
  this.roleV2Uuid = options?.roleV2Uuid ?? null;
  this.stores = options?.stores ?? null;
  this.email = options?.email ?? '';
  return this;
};

(CreateUserModel.prototype as any).init = function (options: Partial<CreateUserModel>) {
  this.email = options?.email ?? '';
  this.password = options?.password ?? '';
  return this;
};

(PatchUserModel.prototype as any).init = function (options: Partial<PatchUserModel>) {
  this.roleV2Uuid = options?.roleV2Uuid ?? null;
  this.stores = options?.stores ?? null;
  return this;
};

export { InviteUserModel, CreateUserModel, PatchUserModel };
