import { DEFAULT_LOCALE } from '@goparrot-dashboard/i18n';
import { assign } from 'lodash/fp';
import a from './actions';

const initState = {
  openDrawer: true,
  openKeys: [],
  current: '',
  locale: DEFAULT_LOCALE,
};
export default function (state = initState, action) {
  switch (action.type) {
    case a.LOCAL__APP__COLLAPSE_OPEN_DRAWER:
      return assign(state, {
        openDrawer: !state.openDrawer,
      });
    case a.LOCAL__APP__CHANGE_OPEN_KEYS:
      return assign(state, {
        openKeys: action.openKeys,
      });
    case a.LOCAL__APP__CHANGE_CURRENT:
      return assign(state, {
        current: action.payload.current,
      });
    case a.LOCAL__APP__SET_LOCALE:
      return assign(state, {
        locale: action.payload,
      });

    default:
      return state;
  }
}
