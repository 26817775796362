import { useDispatch } from 'react-redux';
import type { AuthResponseDto } from '@goparrot/dashboard-gateway-sdk';
import aAuth from '../../../../modules/client/src/store/auth/actions';

export type UseAfterLoginResult = {
  (payload: AuthResponseDto): void;
};
export const useAfterLogin = (): UseAfterLoginResult => {
  const dispatch = useDispatch();
  const afterLogin = (payload: AuthResponseDto) => {
    dispatch(aAuth.afterSuccessLogin(payload));
  };

  return afterLogin;
};
