import React, { Dispatch, SetStateAction, FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketInputText } from '@market/react-bindings';
import { ActionData } from './constants';

type Props = {
  actionData: ActionData;
  setActionData: Dispatch<SetStateAction<ActionData>>;
  disableInputs: boolean;
};

export const ActionInputReplicaTitle: FC<React.PropsWithChildren<Props>> = React.memo(({ actionData, setActionData, disableInputs }) => {
  return (
    <MarketInputText
      disabled={disableInputs || undefined}
      placeholder={getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.title.placeholder', { isRootPath: true })}
      name="title"
      invalid={!actionData.title.length || undefined}
      value={actionData.title}
      onMarketInputValueChange={(e) => {
        setActionData({ ...actionData, title: e.detail.value });
      }}
    >
      <label>{getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.title', { isRootPath: true })}</label>
    </MarketInputText>
  );
});
