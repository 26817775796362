import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { FranchiseInfoDto } from '@goparrot/dashboard-gateway-sdk';

import { storeFranchiseFacade } from '../facades';
import { STORE_QUERIES_ENUM } from '../enums';

export const useInviteInfoFranchise = (
  emailValidationToken: string,
  token: string,
  options?: UseQueryOptions<FranchiseInfoDto>,
): UseQueryResult<FranchiseInfoDto> => {
  return useQuery(
    [STORE_QUERIES_ENUM.GET_INFO_FRANCHISE, emailValidationToken, token],
    () => storeFranchiseFacade.inviteInfo(emailValidationToken, token),
    options,
  );
};
