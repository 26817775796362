import { UserFacade, RoleV2ByMerchantFacade } from '@goparrot/users-v2-sdk';

import { getConfig, getInterceptors } from '@goparrot-dashboard-api/shared';

const facadeOptions = [getConfig('api/v2/users-v2'), getInterceptors()];
const userFacade = new UserFacade(...facadeOptions);
const roleV2ByMerchantFacade = new RoleV2ByMerchantFacade(...facadeOptions);

export const create = (params) =>
  function* () {
    return yield userFacade.create(params);
  };

export const getByUUID = (uuid) =>
  function* () {
    return yield userFacade.findOne(uuid);
  };

export const list = (params) =>
  function* () {
    return yield userFacade.list(params);
  };

export const deleteByUUID = (params) =>
  function* () {
    return yield userFacade.delete(params);
  };

export const update = (uuid, params) =>
  function* () {
    return yield userFacade.patch(uuid, params);
  };

export const invite = (params) =>
  function* () {
    return yield userFacade.invite(params);
  };

export const inviteConfirm = (token, params) =>
  function* () {
    return yield userFacade.inviteConfirm(token, params);
  };

export const getRoleV2 = ({ merchantId, roleV2Uuid }) =>
  function* () {
    return yield roleV2ByMerchantFacade.get(merchantId, roleV2Uuid);
  };
