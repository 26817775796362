import { useContext } from 'react';
import type { UserServiceContextType } from '../contexts/UserServiceContext';
import { UserServiceContext } from '../contexts/UserServiceContext';

export const useUserService = (): UserServiceContextType => {
  const contextValue = useContext(UserServiceContext);

  if (contextValue === undefined) {
    throw Error('useUserService must be used within UserServiceContext');
  }

  return contextValue;
};
