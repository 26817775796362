import React, { FocusEventHandler } from 'react';
import { sortBy } from 'lodash';
import { IReadStoreDto, IReadDefaultStoreDto } from '@goparrot/store-v2-sdk';

import { IntlMessage } from '@goparrot-dashboard/i18n';
import { Select } from '../uielements/index';

interface BaseProps<TValue> {
  value: TValue;
  onChange: (v: TValue) => void;
  onBlur?: ((field: Array<string> | string) => void) | FocusEventHandler<HTMLInputElement>;
}

interface Props extends BaseProps<string[]> {
  locations: (IReadStoreDto | IReadDefaultStoreDto)[];
  onBlur?: () => void;
  disabled?: boolean;
  customProps?: CustomProps;
}

interface SelectOptionProps {
  name?: string;
}

interface CustomProps {
  maxTagCount: number;
  maxTagTextLength: number;
}

export const StoreSelector: React.FC<React.PropsWithChildren<Props>> = ({ value, onChange, locations, onBlur, disabled, customProps }: Props) => {
  const sortedLocations: (IReadStoreDto | IReadDefaultStoreDto)[] = React.useMemo(() => sortBy(locations, ['name']), [locations]);
  const handleSelectChange: (selectedLocations: string[]) => void = (selectedLocations: string[]) => {
    if (selectedLocations.includes('all')) {
      return onChange(sortedLocations.map(({ storeId }: IReadStoreDto | IReadDefaultStoreDto) => storeId));
    }
    return onChange(selectedLocations);
  };
  const handleFilterOption: (value: string, { props }: { props: SelectOptionProps }) => boolean = (value: string, { props }: { props: SelectOptionProps }) =>
    props.name?.toLowerCase().includes(value.toLowerCase());
  return (
    <Select
      showSearch
      allowClear
      mode="multiple"
      placeholder={<IntlMessage id="store.selector.placeholder" />}
      value={value}
      onChange={handleSelectChange}
      filterOption={handleFilterOption}
      onBlur={onBlur}
      disabled={disabled}
      {...customProps}
    >
      <Select.Option key="all" value="all">
        <IntlMessage id="store.selector.all" />
      </Select.Option>
      {sortedLocations.map(({ storeId, title }: IReadStoreDto | IReadDefaultStoreDto) => (
        <Select.Option key={storeId} value={storeId} name={title}>
          {title}
        </Select.Option>
      ))}
    </Select>
  );
};
