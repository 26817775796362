import { usePaymentStoresList } from '@goparrot-dashboard-api/payment';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { UseQueryResult } from 'react-query';

export const usePosAuthorizedStores = (): UseQueryResult<ReadPaymentStoreDto[]> => {
  const {
    merchant: { merchantId },
  } = useStoreService();
  const { notifyError } = useNotifications();
  const posAuthorizedStoresQuery = usePaymentStoresList({
    merchantId,
    options: {
      enabled: !!merchantId,
      retry: 3,
      initialData: [],
      onError: () => notifyError({ text: 'Some error occurred while getting merchant stores' }),
    },
  });

  return posAuthorizedStoresQuery;
};
