import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getIntlMessage } from '../utils/getIntlMessage';
import { I18nPageContext, I18nPageContextType } from './i18n-context';

type I18nServiceProps = {
  prefix: string;
};

export const I18nService: FC<React.PropsWithChildren<I18nServiceProps>> = ({ children, prefix }) => {
  const intl = useIntl();

  const providerValue = useMemo<I18nPageContextType>(
    () => ({
      getIntlMessage: getIntlMessage(prefix),
      getIntlString: (key: string, { values, isRootPath } = {}) => intl.formatMessage({ id: isRootPath ? key : `${prefix}.${key}` }, values),
      getIntlNumber: (number, options) => intl.formatNumber(number, options),
    }),
    [intl, prefix],
  );
  return <I18nPageContext.Provider value={providerValue}>{children}</I18nPageContext.Provider>;
};
