import { IJsonPatchOperation } from '@goparrot/common';
import { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { paymentStoreFacade } from '../facades';

type PatchPaymentStoreParams = {
  storeId: string;
  payload: IJsonPatchOperation[];
};
type PatchPaymentStoreResult = ReadPaymentStoreDto;

export const usePatchPaymentStore = (
  options: UseMutationOptions<PatchPaymentStoreResult, unknown, PatchPaymentStoreParams>,
): UseMutationResult<PatchPaymentStoreResult, unknown, PatchPaymentStoreParams> => {
  return useMutation(({ storeId, payload }) => paymentStoreFacade.patch(storeId, payload), options);
};
