import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Button, useNotifications, Spinner } from '@goparrot-dashboard/shared-ui';
import { ReadLocationDto, ReadStoreItemMappingDto } from '@goparrot/menu-orchestrator-sdk';
import { useCentralLocationItemByReplicaGet } from '@goparrot-dashboard-api/menu-management';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MenuManagementMatchParams, VariationStoreItemTypeEnum } from '../../interface';

type Props = {
  centralLocation: ReadLocationDto;
  params: MenuManagementMatchParams;
  forOldVersion?: boolean;
};

export const LinkToCentralLocationItem: FC<React.PropsWithChildren<Props>> = ({ centralLocation, params, forOldVersion = false }) => {
  const { notifyError } = useNotifications();
  const [fetchingItem, setFetchingItem] = useState<boolean>(false);
  const [fetchingParent, setFetchingParent] = useState<boolean>(false);
  const [itemMapping, setItemMapping] = useState<ReadStoreItemMappingDto | null>(null);
  const [parentMapping, setParentMapping] = useState<ReadStoreItemMappingDto | null>(null);
  const { storeItemType, itemType, itemStoreId, uniqueName, variationId } = params;
  const isSomeVariation = !!storeItemType;
  const isVariationGroup = storeItemType === VariationStoreItemTypeEnum.STORE_ITEM_VARIATION_GROUP;
  const isItemVariation = storeItemType === VariationStoreItemTypeEnum.STORE_ITEM_VARIATION;
  const alreadyFetched = useMemo(() => (isItemVariation ? !!itemMapping && !!parentMapping : !!itemMapping), [isItemVariation, itemMapping, parentMapping]);

  const redirectToCentralLocation = useCallback(() => {
    const baseUrl = `/dashboard/menu-management/${centralLocation?.storeId}/${itemType}/${centralLocation?.storeId}/edit`;
    let nextPath = `${baseUrl}/${itemMapping?.centralUniqueName}`;
    if (isSomeVariation) {
      if (isVariationGroup) {
        nextPath = `${baseUrl}/${itemMapping?.centralUniqueName}/${VariationStoreItemTypeEnum.STORE_ITEM_VARIATION_GROUP}`;
      } else {
        nextPath = `${baseUrl}/${parentMapping?.centralUniqueName}/${VariationStoreItemTypeEnum.STORE_ITEM_VARIATION}/${itemMapping?.centralUniqueName}`;
      }
    }
    window.open(nextPath, '_blank');
  }, [centralLocation?.storeId, isSomeVariation, isVariationGroup, itemMapping?.centralUniqueName, itemType, parentMapping?.centralUniqueName]);

  useEffect(() => {
    if (alreadyFetched) {
      redirectToCentralLocation();
    }
  }, [alreadyFetched]);

  const idToGetItemByReplica = isItemVariation ? variationId : uniqueName;

  const itemQuery = useCentralLocationItemByReplicaGet(
    { storeId: itemStoreId, replicaUniqueName: idToGetItemByReplica },
    {
      onSuccess: (item: ReadStoreItemMappingDto) => {
        setItemMapping(item);
      },
      onError: () => {
        notifyError({
          text: getIntlString('page.merchant.menu.notifications.central.location.item.get.error'),
        });
      },
      onSettled: () => {
        setFetchingItem(false);
      },
      enabled: fetchingItem,
    },
  );

  // for item variation we need to find also the variation group on central location
  const parentQuery = useCentralLocationItemByReplicaGet(
    { storeId: itemStoreId, replicaUniqueName: uniqueName },
    {
      onSuccess: (item: ReadStoreItemMappingDto) => {
        setParentMapping(item);
      },
      onError: () => {
        notifyError({
          text: getIntlString('page.merchant.menu.notifications.central.location.item.get.error'),
        });
      },
      onSettled: () => {
        setFetchingParent(false);
      },
      enabled: fetchingParent,
    },
  );

  const getCentralItem = () => {
    if (!alreadyFetched) {
      setFetchingItem(true);

      if (isItemVariation) {
        setFetchingParent(true);
      }
    } else {
      redirectToCentralLocation();
    }
  };

  const isLoading = fetchingItem || itemQuery.isFetching || parentQuery.isFetching;
  return (
    <Spinner spinning={isLoading}>
      <Button disabled={isLoading} kind={forOldVersion ? 'clean' : 'button'} color="primary" onClick={getCentralItem} className="tw-py-2 tw-px-3">
        {getIntlString('page.merchant.menu.view-on-central-location')}
      </Button>
    </Spinner>
  );
};
