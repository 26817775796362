import React, { FC, useCallback } from 'react';
import { Button } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useParams } from 'react-router';
import { hasPermission, PermissionsV2 } from '@goparrot/users-v2-sdk';
import { useUserService } from '@goparrot-dashboard-core/user-service';

type Props = { storeItemType: string; customLink?: string };

export const LinkToMerchantLevelItem: FC<React.PropsWithChildren<Props>> = ({ storeItemType, customLink }) => {
  const { uniqueName } = useParams<{ uniqueName: string }>();
  const {
    merchant: { merchantId },
  } = useStoreService();
  const { roleV2 } = useUserService();

  const { getIntlString } = useI18n();
  const hasAccessToMerchant = hasPermission(roleV2, PermissionsV2.MERCHANT.NAVBAR_VIEW);

  const redirectToMerchantLevel = useCallback(() => {
    const url = `/dashboard/menu-management/${merchantId}/${storeItemType}/${merchantId}/edit/${uniqueName}`;
    window.open(customLink ? customLink : url, '_blank');
  }, [merchantId, storeItemType, customLink, uniqueName]);

  return (
    <Button disabled={!hasAccessToMerchant} onClick={redirectToMerchantLevel}>
      {getIntlString('page.merchant.menu.view-on-merchant-level', { isRootPath: true })}
    </Button>
  );
};
