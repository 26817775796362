import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { UpdateRelationsResponseDto, IRewriteRelationsRequest, IStoreItemGroupProperties } from '@goparrot/storeitems-sdk';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdateStoreItemGroupChildrenParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  payload: IRewriteRelationsRequest<IStoreItemGroupProperties>;
};

export const useLocationUpdateStoreItemGroupChildren = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupChildrenParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateStoreItemGroupChildrenParams> => {
  return useMutation(
    ({ merchantId, uniqueName, storeId, payload }) => locationStoreItemGroupFacade.updateProperties(merchantId, storeId, uniqueName, payload),
    options,
  );
};
