import { combineReducers } from 'redux';

import order from './order/reducer';
import merchant from './merchant/reducer';
import location from './location/reducer';
import locationDefault from './location-default/reducer';
import level from './level/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import webstore from './webstore/reducer';
import app from './app/reducer';
import pos from './pos/reducer';
import zone from './zone/reducer';
import websocket from './websocket/reducer';
import franchise from './franchise/reducer';
import aAuth from './auth/actions';

export const appReducer = combineReducers({
  order,
  merchant,
  location,
  locationDefault,
  webstore,
  level,
  auth,
  user,
  app,
  pos,
  zone,
  websocket,
  franchise,
});

const rootReducer = (state, action) => {
  if (action.type === aAuth.LOCAL__AUTH__LOGOUT) {
    state = undefined; /* eslint no-param-reassign: off */
  }
  return appReducer(state, action);
};

export default rootReducer;
