import { getConfig, getInterceptors } from '@goparrot-dashboard-api/shared';
import { AuthV2Facade } from '@goparrot/dashboard-gateway-sdk';

const authFacade = new AuthV2Facade(getConfig(), getInterceptors());

export const login = (params) =>
  function* () {
    return yield authFacade.login(params);
  };
export const loginByToken = () =>
  function* () {
    return yield authFacade.refreshToken();
  };
