import * as React from 'react';
import { takeEvery, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { I18nProvider, intl, IntlMessage } from '@goparrot-dashboard/i18n';
import { notification } from 'components/commons/feedback';

import a from './actions';
import { getLocale } from './selector';

function* createNotification({ type, message, description, isCustom }, options = {}) {
  const locale = yield select(getLocale);
  const getIntlMessage = (path) => (
    <I18nProvider locale={locale}>
      <IntlMessage id={`notification.${path}`} {...options} />
    </I18nProvider>
  );
  notification[type]({
    message: isCustom || !description ? getIntlMessage(message) : undefined, // no need to display generic message text unless description is custom
    description: isCustom ? description : getIntlMessage(description),
  });
}

const dragAndDropNotification = ({ payload }) => {
  notification.success({
    message: intl.formatMessage({ id: 'page.merchant.menu.table.dragAndDropSuccess' }),
    description: !payload.conflicts?.length
      ? intl.formatMessage({ id: 'page.merchant.menu.table.dragAndDropSuccess' })
      : `${intl.formatMessage({ id: 'page.merchant.menu.table.dragAndDropDescription1' })}
            ${payload.conflicts.length}
            ${intl.formatMessage({ id: 'page.merchant.menu.table.dragAndDropDescription2' })}`,
  });
};

const menuSyncNotificationSuccess = ({ payload }) => {
  const { title, type } = payload;
  notification.success({
    message: intl.formatMessage({ id: 'notification.menu.sync.success.message' }),
    description: `${intl.formatMessage({ id: 'notification.menu.sync.success.imported.description-part1' })}
        ${intl.formatMessage({ id: `page.merchant.menu.item.type.${type}` })} ${title}
        ${intl.formatMessage({ id: 'notification.menu.sync.success.imported.description-part2' })}`,
  });
};

const menuSyncNotificationInfo = ({ payload }) => {
  const { title, type } = payload;
  notification.info({
    message: intl.formatMessage({ id: 'notification.menu.sync.success.message' }),
    description: `${intl.formatMessage({ id: `page.merchant.menu.item.type.${type}` })}
        ${title} ${intl.formatMessage({ id: 'notification.menu.sync.success.import.description' })}`,
  });
};

const campaignNotificationError = ({ payload }) => {
  const { message } = payload;
  if (!message) return;
  const errors = 'string' === typeof message ? [{ message }] : message;
  const description = (
    <>
      {errors.map((error) => (
        <li key={error.message}>{error.message}</li>
      ))}
    </>
  );
  notification.error({
    message: intl.formatMessage({ id: 'notification.campaign.start.error.message' }),
    description,
  });
};

function* handleChangeCurrent({ payload }) {
  if (true === payload.redirect) {
    yield put(push(payload.current));
  }
}
function* handlePushNotificationWithSuccess({ payload }) {
  yield createNotification({ type: 'success', ...payload });
}
function* handlePushNotificationWithWarning({ payload }) {
  yield createNotification({ type: 'warning', ...payload });
}
function* handlePushNotificationWithError({ payload }) {
  yield createNotification({ type: 'error', ...payload });
}
function* handleDragAndDropNotification({ payload }) {
  yield dragAndDropNotification({ payload });
}

function* handleMenuSyncNotificationSuccess({ payload }) {
  yield menuSyncNotificationSuccess({ payload });
}

function* handleMenuSyncNotificationInfo({ payload }) {
  yield menuSyncNotificationInfo({ payload });
}

function* handleCampaignNotificationError({ payload }) {
  yield campaignNotificationError({ payload });
}

export default function* () {
  yield takeEvery(a.LOCAL__APP__CHANGE_CURRENT, handleChangeCurrent);
  yield takeEvery(a.LOCAL__APP__PUSH_NOTIFICATION__SUCCESS, handlePushNotificationWithSuccess);
  yield takeEvery(a.LOCAL__APP__PUSH_NOTIFICATION__ERROR, handlePushNotificationWithError);
  yield takeEvery(a.LOCAL__APP__PUSH_NOTIFICATION__WARNING, handlePushNotificationWithWarning);
  yield takeEvery(a.LOCAL__APP__PUSH_DRAGANDDROP__NOTIFICATION__SUCCESS, handleDragAndDropNotification);
  yield takeEvery(a.LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__SUCCESS, handleMenuSyncNotificationSuccess);
  yield takeEvery(a.LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__INFO, handleMenuSyncNotificationInfo);
  yield takeEvery(a.LOCAL__APP__CAMPAIGN__NOTIFICATION__ERROR, handleCampaignNotificationError);
}
