import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { locationCategoryFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { LocationGetRelativesQueryParams } from '../../interface';

export const useLocationGetCategoryParentsQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemSetDto[]>,
): UseQueryResult<ReadItemSetDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_CATEGORY_GET_PARENTS, merchantId, storeId, uniqueName],
    async () => await locationCategoryFacade.getParents(merchantId, storeId, uniqueName),
    options,
  );
};
