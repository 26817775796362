import {
  IMerchantFilterParams,
  ItemListResponseDto,
  IResultsListResponse,
  IStoreFilterParams,
  ReadStoreItemDto,
  ReadStoreItemGroupDto,
} from '@goparrot/storeitems-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { ListQueryBaseParams, useLocationStoreCombinedItemList, useMerchantStoreCombinedItemList } from '@goparrot-dashboard-api/menu-management';
import { UseQueryResult } from 'react-query';
import { MENU_ELEMENTS_PLURAL, removeNullOrUndefinedObjectProperties } from '@goparrot-dashboard/shared-utils';

type Props = ListQueryBaseParams<IStoreFilterParams | IMerchantFilterParams> & {
  storeId: string;
  isMerchant: boolean;
  filters?: IStoreFilterParams | IMerchantFilterParams;
  isCreating?: boolean;
  enabled?: boolean;
};

export const useStoreCombinedItemListQuery = ({
  merchantId,
  storeId,
  pagination,
  isMerchant,
  filters,
  enabled = true,
}: Props): UseQueryResult<IResultsListResponse<ReadStoreItemDto | ReadStoreItemGroupDto>> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.STORE_ITEM }),
      });
    },
  };

  const locationQuery = useLocationStoreCombinedItemList(
    {
      merchantId,
      storeId,
      pagination,
      params: filters ? (removeNullOrUndefinedObjectProperties(filters) as ItemListResponseDto & IStoreFilterParams) : undefined,
    },
    {
      ...options,
      enabled: !isMerchant && enabled,
    },
  );

  const merchantQuery = useMerchantStoreCombinedItemList(
    {
      merchantId,
      pagination,
      params: filters ? (removeNullOrUndefinedObjectProperties(filters) as ItemListResponseDto & IMerchantFilterParams) : undefined,
    },
    {
      ...options,
      enabled: isMerchant && enabled,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
