import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadStoreItemMappingDto } from '@goparrot/menu-orchestrator-sdk';

import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { storeItemMappingFacade } from '../../facades';

type LocationMenuConceptGetParams = { storeId: string; replicaUniqueName: string };

export const useCentralLocationItemByReplicaGet = (
  { storeId, replicaUniqueName }: LocationMenuConceptGetParams,
  options?: UseQueryOptions<ReadStoreItemMappingDto>,
): UseQueryResult<ReadStoreItemMappingDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.CENTRAL_LOCATION_ITEM_BY_REPLICA_GET, storeId, replicaUniqueName],
    () => storeItemMappingFacade.get(storeId, replicaUniqueName),
    options,
  );
};
