import { SQUARE_ROUTE_BASE } from '@goparrot-dashboard/shared-utils';
import { RETURN_TO_URL_PARAMS, returnToCompleteStr } from '../constants';

export const getEncodedCheckingAccountUrl = (): string => {
  const returnTo = `${window.location.host}/dashboard/franchise/giftcards?${formatUrlSearchParams(RETURN_TO_URL_PARAMS)}`;
  const params = {
    returnToComplete: encodeURIComponent(returnToCompleteStr),
    returnTo: encodeURIComponent(returnTo),
  };

  return `${SQUARE_ROUTE_BASE}balances/checking?${formatUrlSearchParams(params)}`;
};

export const formatUrlSearchParams = (paramObj: Record<string, string>): string =>
  Object.entries(paramObj)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
