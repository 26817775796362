import styled from 'styled-components';

import { Icon as CommonIcon } from 'components/commons/uielements';
import { Modal as CommonModal } from 'components/commons/feedback';
import { palette } from 'styles/constants';

import { Button as CommonButton } from '../../uielements';

export const Wrapper = styled.div`
  display: inline-block;
  text-align: center;
  max-width: 230px;
  min-width: 150px;
`;

export const ImageUpload = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const ImageActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 7px;
`;

// @ts-ignore-next-line
export const Icon = styled(CommonIcon)`
  font-size: 16px;
  padding: 0 7px;
  margin-left: 10px;

  &:last-child {
    margin-left: 0;
  }
`;

export const Help = styled.small`
  @media only screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ItemDetailImage = styled.div`
  width: 100%;

  i.anticon {
    font-size: 128px;
  }
  img {
    width: 100%;

    i.anticon {
        font-size: 128px;
    }
    img {
        width: 100%;
        margin: 0 auto;
    }
`;

export const ImageContainer = styled.div<{ width?: string }>`
  background: conic-gradient(
      ${palette.grayscale[4]} 0deg,
      ${palette.grayscale[4]} 90deg,
      ${palette.grayscale[1]} 90deg,
      ${palette.grayscale[1]} 180deg,
      ${palette.grayscale[4]} 180deg,
      ${palette.grayscale[4]} 270deg,
      ${palette.grayscale[1]} 270deg,
      ${palette.grayscale[1]} 360deg
    )
    0 0/10% 10%;
  width: ${({ width }) => width ?? 'auto'};
`;

export const Button = styled(CommonButton)`
  &.ant-btn {
    margin-left: 5px;
  }
`;

export const UploadButton = styled(CommonButton)`
  &.ant-btn {
    max-width: 120px;
  }
`;

export const Modal = styled(CommonModal)`
    .ant-modal-close {
        color: rgba(255, 255, 255, 0.7);
        right: -40px;

        &:hover {
            color: ${palette.color[20]};
        }
    }
    .ant-modal-close-x {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 18px;
    }
  }
  .ant-modal-close-x {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 18px;
  }
`;
