import React, { FC, useState, useEffect } from 'react';
import { DeleteConfirmModal, DialogStateReturn } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import type { Location } from 'history';
import { useHistory, Prompt } from 'react-router';

type Props = {
  countRef: React.MutableRefObject<number>;
  onClose: () => void;
  leavePageModal: DialogStateReturn;
};

export const CloseWithoutSavingModal: FC<React.PropsWithChildren<Props>> = React.memo(({ countRef, leavePageModal, onClose }) => {
  const { getIntlString } = useI18n();
  const [count, setCount] = useState(countRef.current);

  useEffect(() => {
    const interval = setInterval(function () {
      // countRef is the number of modified replicas
      setCount(countRef.current);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [countRef]);

  const history = useHistory();
  // this is custom promt when user click on diferent tab(module) and want to leave this module
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const handleBlockedNavigation = React.useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        leavePageModal.show();
        return false;
      }
      return true;
    },
    [confirmedNavigation, leavePageModal],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, modalVisible, lastLocation]);

  const onDiscardChanges = React.useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    leavePageModal.dismiss();
    setTimeout(() => {
      onClose();
    }, 300);
  }, [leavePageModal, onClose]);

  return (
    <>
      <Prompt when={!!count} message={handleBlockedNavigation} />
      {leavePageModal.visible ? (
        <DeleteConfirmModal
          dialog={leavePageModal}
          handleDelete={onDiscardChanges}
          onCancel={leavePageModal.dismiss}
          deleteText={getIntlString('button.close', { isRootPath: true })}
          cancelText={getIntlString('button.back', { isRootPath: true })}
          title={getIntlString('menu.form.section.displaySettings.storeSpecific.closeWithoutSaving.content.title', { isRootPath: true })}
          dialogText={getIntlString('menu.form.section.displaySettings.storeSpecific.closeWithoutSaving.content.description', { isRootPath: true })}
        />
      ) : null}
    </>
  );
});
