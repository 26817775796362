import React, { useCallback } from 'react';

import { IconArrowTopRight, IconSend } from '@goparrot-dashboard/shared-ui';

import { useGetPlacePickerTemplate, useGetWebMerchant } from '@goparrot-dashboard-api/webstore';
import { getIntlString } from '@goparrot-dashboard/i18n';
import classNames from 'classnames';
import { MarketButton } from '@market/react-bindings';
import { GOPARROT_SUBDOMAIN } from '../constants';

type ButtonLinkToSiteProps = {
  className?: string;
  showOnMobile?: boolean;
  merchantId: string;
};

export const ButtonLinkToSite: React.FC<React.PropsWithChildren<ButtonLinkToSiteProps>> = React.memo(({ className, showOnMobile, merchantId }) => {
  const { data: webMerchant, isFetching, isLoading } = useGetWebMerchant(merchantId, { enabled: !!merchantId });
  const { data: placepickerTemplate } = useGetPlacePickerTemplate(merchantId, {
    enabled: !!merchantId,
  });

  const redirectToSite = useCallback(() => {
    const path = `https://${!webMerchant?.hostname ? GOPARROT_SUBDOMAIN : webMerchant?.hostname}/locations/${webMerchant?.slug}`;
    window.open(path, '_blank');
  }, [webMerchant]);

  if (!placepickerTemplate) {
    return null;
  }

  return (
    <MarketButton
      rank="secondary"
      size="small"
      aria-label={getIntlString('page.locations.actions.redirect.toSite')}
      className={classNames([className, 'tw-ml-1'])}
      disabled={isLoading || isFetching || undefined}
      onClick={redirectToSite}
    >
      {showOnMobile ? (
        <IconSend />
      ) : (
        <>
          <span>{getIntlString('page.locations.actions.redirect.toSite')}</span>
          <IconArrowTopRight />
        </>
      )}
    </MarketButton>
  );
});
