import React from 'react';
import { ReadItemSetMetadataCriteriaDto } from '@goparrot/storeitems-sdk';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useFormFieldProps, useCheckboxProps } from '@goparrot-dashboard/entity-form';
import { FormField, Toggle } from '@goparrot-dashboard/shared-ui';
import { useMenuFormContext } from '../../context';
import { getMenuFormIntlString } from './utils';

type Props = {
  formLabel?: string | React.ReactNode;
  checkedLabel?: string | React.ReactNode;
  uncheckedLabel?: string | React.ReactNode;
};

export const ItemSetDefault = <T extends { criteria: ReadItemSetMetadataCriteriaDto }>({
  formLabel,
  checkedLabel,
  uncheckedLabel,
}: Props): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();

  const bindFormFieldProps = useFormFieldProps(form);
  const bindCheckedProps = useCheckboxProps(form);

  return (
    <FormField
      {...bindFormFieldProps('metadata.criteria.default')}
      label={formLabel || getMenuFormIntlString('default')}
      input={
        <Toggle
          {...bindCheckedProps('metadata.criteria.default')}
          checkedLabel={checkedLabel || getIntlString('variant.yes')}
          uncheckedLabel={uncheckedLabel || getIntlString('variant.no')}
        />
      }
    />
  );
};
