import { eventChannel } from 'redux-saga';
import { ISyncJobEvent } from '@goparrot/menu-sync-sdk';

import a from '../actions';

export function socketChannel(createSocketConnection, id) {
  const uniqueNameList: string[] = JSON.parse(sessionStorage.getItem('uniqueNameList'));
  return eventChannel((emit) => {
    const update = (event: ISyncJobEvent): void => {
      emit(a.successItemSyncJob(event));
    };
    const unsubscribe = (): void => {
      createSocketConnection.off(`ITEM_SYNC_COMPLETED/${id}`);
    };
    if (uniqueNameList?.length) {
      uniqueNameList.forEach((uniqueName: string) => {
        const data = JSON.parse(sessionStorage.getItem(uniqueName));
        if (data?.jobId) {
          createSocketConnection.on(`ITEM_SYNC_COMPLETED/${data.jobId}`, update);
        }
      });
    }
    createSocketConnection.on(`ITEM_SYNC_COMPLETED/${id}`, update);

    return () => unsubscribe;
  });
}
