import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { SsoCallbackParams } from '../interface';
import { squareSsoConnectCallback } from '../utils/squareSsoConnectCallback';

export const useSsoConnectCallback = (
  options?: UseMutationOptions<void, AxiosError, SsoCallbackParams>,
): UseMutationResult<void, AxiosError, SsoCallbackParams> => {
  return useMutation(squareSsoConnectCallback, options);
};
