import React, { FC } from 'react';
import { IconAlertOutlined } from '@goparrot-dashboard/shared-ui';
import classNames from 'classnames';

type AlertProps = {
  title: string;
  className?: string;
};

export const Alert: FC<React.PropsWithChildren<AlertProps>> = ({ title, className }) => {
  return (
    <div
      className={classNames([
        className,
        'tw-bg-yellow-50 tw-rounded-sm tw-border tw-border-px tw-border-yellow-200 tw-py-4.5 tw-px-4.5 tw-flex tw-items-center tw-space-x-2',
      ])}
    >
      <IconAlertOutlined className="tw-text-yellow-400 tw-w-7 tw-h-7" />
      <div className="tw-text-blue-gray-900 tw-text-sm tw-w-full">{title}</div>
    </div>
  );
};
