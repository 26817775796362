import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MM_SEARCH_CACHE_KEY } from '../../../constants';

export const useSearchQuery = (): { searchQuery: string; setSearchQuery: Dispatch<SetStateAction<string>> } => {
  const cachedSearch = sessionStorage.getItem(MM_SEARCH_CACHE_KEY) || '';
  const [searchQuery, setSearchQuery] = useState(cachedSearch);

  useEffect(() => {
    setSearchQuery(cachedSearch);
  }, [cachedSearch]);

  useEffect(() => {
    if (searchQuery !== null) {
      sessionStorage.setItem(MM_SEARCH_CACHE_KEY, searchQuery);
    }
  }, [searchQuery]);

  return { searchQuery, setSearchQuery };
};
