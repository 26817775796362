import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { BatchPatchLocationsDto, ReadLocationDto } from '@goparrot/menu-orchestrator-sdk';
import { locationFacade } from '../facades';

export type BatchPatchLocationsParams = {
  merchantId: string;
  data: BatchPatchLocationsDto;
};

export const useBatchPatchLocations = (
  options?: UseMutationOptions<ReadLocationDto[], unknown, BatchPatchLocationsParams, unknown>,
): UseMutationResult<ReadLocationDto[], unknown, BatchPatchLocationsParams, unknown> => {
  return useMutation(({ merchantId, data }) => locationFacade.batchPatch(merchantId, data), options);
};
