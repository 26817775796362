import { createAction } from 'helpers/create-action';

const c = {
  API__LOCATION_DEFAULT__GET__REQUEST: 'API__LOCATION_DEFAULT__GET__REQUEST',
  API__LOCATION_DEFAULT__GET__SUCCESS: 'API__LOCATION_DEFAULT__GET__SUCCESS',
  API__LOCATION_DEFAULT__GET__ERROR: 'API__LOCATION_DEFAULT__GET__ERROR',

  API__LOCATION_DEFAULT__UPDATE__REQUEST: 'API__LOCATION_DEFAULT__UPDATE__REQUEST',
  API__LOCATION_DEFAULT__UPDATE__SUCCESS: 'API__LOCATION_DEFAULT__UPDATE__SUCCESS',
  API__LOCATION_DEFAULT__UPDATE__ERROR: 'API__LOCATION_DEFAULT__UPDATE__ERROR',

  API__LOCATION_DEFAULT__CREATE__REQUEST: 'API__LOCATION_DEFAULT__CREATE__REQUEST',
  API__LOCATION_DEFAULT__CREATE__SUCCESS: 'API__LOCATION_DEFAULT__CREATE__SUCCESS',
  API__LOCATION_DEFAULT__CREATE__ERROR: 'API__LOCATION_DEFAULT__CREATE__ERROR',

  API__LOCATION_DEFAULT__DELETE__REQUEST: 'API__LOCATION_DEFAULT__DELETE__REQUEST',
  API__LOCATION_DEFAULT__DELETE__SUCCESS: 'API__LOCATION_DEFAULT__DELETE__SUCCESS',
  API__LOCATION_DEFAULT__DELETE__ERROR: 'API__LOCATION_DEFAULT__DELETE__ERROR',
};

const a = {
  requestGet: createAction(c.API__LOCATION_DEFAULT__GET__REQUEST),
  successGet: createAction(c.API__LOCATION_DEFAULT__GET__SUCCESS),
  errorGet: createAction(c.API__LOCATION_DEFAULT__GET__ERROR),

  requestCreate: createAction(c.API__LOCATION_DEFAULT__CREATE__REQUEST),
  successCreate: createAction(c.API__LOCATION_DEFAULT__CREATE__SUCCESS),
  errorCreate: createAction(c.API__LOCATION_DEFAULT__CREATE__ERROR),

  requestUpdate: createAction(c.API__LOCATION_DEFAULT__UPDATE__REQUEST),
  successUpdate: createAction(c.API__LOCATION_DEFAULT__UPDATE__SUCCESS),
  errorUpdate: createAction(c.API__LOCATION_DEFAULT__UPDATE__ERROR),

  requestDelete: createAction(c.API__LOCATION_DEFAULT__DELETE__REQUEST),
  successDelete: createAction(c.API__LOCATION_DEFAULT__DELETE__SUCCESS),
  errorDelete: createAction(c.API__LOCATION_DEFAULT__DELETE__ERROR),
};

export default { ...c, ...a };
