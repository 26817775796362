import { UpdateItemOptionSelectionsRequestDto, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type LocationItemOptionsUpdateItemBasesParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: UpdateItemOptionSelectionsRequestDto;
};

export const useLocationItemOptionsUpdateItemBases = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, LocationItemOptionsUpdateItemBasesParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, LocationItemOptionsUpdateItemBasesParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationItemOptionsFacade.updateItemBases(merchantId, storeId, uniqueName, data),
    options,
  );
};
