import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { locationStoreItemFacade } from '@goparrot-dashboard-api/shared';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useLocationGetStoreItemChildrenQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadItemOptionsDto[]>,
): UseQueryResult<ReadItemOptionsDto[]> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_GET_CHILDREN, merchantId, storeId, uniqueName],
    async () => await locationStoreItemFacade.getChildren(merchantId, storeId, uniqueName),
    options,
  );
};
