import {
  useLocationStoreItemGroupUpdateOptions,
  useMerchantStoreItemGroupUpdateOptions,
  LocationUpdateStoreItemGroupOptionsResult,
  MerchantUpdateStoreItemGroupOptionsResult,
  LocationUpdateStoreItemGroupOptionsParams,
  MerchantUpdateStoreItemGroupOptionsParams,
} from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS } from '../../../../constants';

type Props = {
  successCallback?: (variables: QueryParams) => void;
};

type Result = {
  merchantQuery: MerchantUpdateStoreItemGroupOptionsResult;
  locationQuery: LocationUpdateStoreItemGroupOptionsResult;
};
type QueryParams = LocationUpdateStoreItemGroupOptionsParams | MerchantUpdateStoreItemGroupOptionsParams;

export const useUpdateStoreItemGroupOptionsQuery = ({ successCallback }: Props): Result => {
  const { notifyError, notifySuccess } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.update.error'),
      });
    },
    onSuccess: (_, variables) => {
      notifySuccess({
        text: getIntlString('menu.notifications.update.success', { itemType: MENU_ELEMENTS.STORE_ITEM_GROUP }),
      });
      successCallback && successCallback(variables);
    },
  };

  const locationQuery = useLocationStoreItemGroupUpdateOptions(options);

  const merchantQuery = useMerchantStoreItemGroupUpdateOptions(options);

  return { merchantQuery, locationQuery };
};
