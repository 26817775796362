import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationGroupFacade } from '../facades';

type DeleteLocationGroupParams = {
  merchantId: string;
  uuid: string;
};
type DeleteLocationGroupResult = void;

export const useDeleteLocationGroup = (
  options?: UseMutationOptions<DeleteLocationGroupResult, unknown, DeleteLocationGroupParams>,
): UseMutationResult<DeleteLocationGroupResult, unknown, DeleteLocationGroupParams> => {
  return useMutation(({ merchantId, uuid }) => locationGroupFacade.delete(merchantId, uuid), options);
};
