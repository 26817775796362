import { assign } from 'lodash/fp';

import a from '../actions';

const initialState = {
  data: {},
  errors: [],
  fetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__ORDER__RUN__ACTIONS__REQUEST:
      return assign(state, { fetching: true });

    case a.API__ORDER__RUN__ACTIONS__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.API__ORDER__RUN__ACTIONS__ERROR:
    case a.API__ORDER__RESET__DATA:
      return initialState;

    default:
      return state;
  }
}
