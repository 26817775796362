import { useEffect, useState, useMemo } from 'react';
import { useInitialRender } from '@goparrot-dashboard/shared-utils/src/hooks/useInitialRender';
import { useSsoConnectCallback } from '../../../../api/auth';
import { getSsoUrlParams } from '../../../../api/auth/src/utils/getSsoUrlParams';
import { ssoConnectCallbackSuccess } from '../../../auth/src/components/helpers/ssoConnectCallbackSuccess';

export const enum SsoLinkState {
  'NONE',
  'SUCCESS',
  'FAILURE',
}

export const enum SsoErrorType {
  'NONE',
  'CONFLICT',
  'GENERAL',
}

export const useSsoConnectCallbackMutation = ({ hideSsoLinkBanner = undefined }: { hideSsoLinkBanner?: () => void } = {}): {
  ssoLinkState: SsoLinkState;
  ssoErrorType: SsoErrorType;
} => {
  const ssoUrlParams = useMemo(() => getSsoUrlParams(), []);
  const [ssoLinkState, setSsoLinkState] = useState(SsoLinkState.NONE);
  const [ssoErrorType, setSsoErrorType] = useState(SsoErrorType.NONE);
  const { isFirstRender: isInitialRender } = useInitialRender();

  const ssoConnectCallback = useSsoConnectCallback({
    onSuccess: () => {
      ssoConnectCallbackSuccess({ hideSsoLinkBanner, ssoLinkState, setSsoLinkState, clearParams: ssoUrlParams.clearParams });
      setSsoErrorType(SsoErrorType.NONE);
    },
    onError: (err) => {
      setSsoLinkState(SsoLinkState.FAILURE);
      setSsoErrorType(err?.response?.status === 409 ? SsoErrorType.CONFLICT : SsoErrorType.GENERAL);
    },
  });

  useEffect(() => {
    if (isInitialRender && ssoUrlParams && ssoUrlParams.code && ssoUrlParams.state) {
      ssoConnectCallback.mutate(ssoUrlParams);
    }
  }, [isInitialRender, ssoConnectCallback, ssoUrlParams]);

  return { ssoLinkState, ssoErrorType };
};
