import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { IMerchantFilterParams, IPagination, StoreItemComboListResponseDto } from '@goparrot/storeitems-sdk';
import { merchantStoreItemComboFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

type GetStoreItemsParams = {
  merchantId: string;
  pagination?: IPagination;
  params?: IMerchantFilterParams;
};

export const useMerchantComboListQuery = (
  { merchantId, pagination, params }: GetStoreItemsParams,
  options?: UseQueryOptions<StoreItemComboListResponseDto, Error>,
): UseQueryResult<StoreItemComboListResponseDto, Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_COMBOS_LIST, merchantId, pagination, params],
    async () => merchantStoreItemComboFacade.list(merchantId, pagination, params),
    options,
  );
};
