import { ReadCategoryDto, ICloneRequest } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type MerchantCloneCategoryParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, MerchantCloneCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, MerchantCloneCategoryParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantCategoryFacade.clone(merchantId, uniqueName, data), options);
};
