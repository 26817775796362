import React, { FC } from 'react';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { Tooltip } from '@goparrot-dashboard/shared-ui';
import { MarketPill } from '@market/react-bindings';
import { MarketPillVariant } from '@market/web-components/dist/types/utils/pill-variant';
import { StatusEnum } from '@goparrot/menu-orchestrator-sdk';

export enum BADGE_STATUSES {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
  NOT_SYNCED = 'NOT_SYNCED',
}

const humanReadableStatuses = {
  [BADGE_STATUSES.ACTIVE]: getIntlString('status.synced'),
  [BADGE_STATUSES.QUEUED]: getIntlString('status.queued'),
  [BADGE_STATUSES.INACTIVE]: getIntlString('status.failed'),
  [(BADGE_STATUSES || StatusEnum).PENDING]: getIntlString('status.pending'),
  [(BADGE_STATUSES || StatusEnum).IN_PROGRESS]: getIntlString('status.inProcess'),
  [(BADGE_STATUSES || StatusEnum).DONE]: getIntlString('status.synced'),
  [(BADGE_STATUSES || StatusEnum).FAILED]: getIntlString('status.error'),
  [BADGE_STATUSES.NOT_SYNCED]: getIntlString('status.notSynced'),
  [StatusEnum.ARCHIVED]: getIntlString('status.archived'),
};

export type SyncStatusBadgeProps = {
  status: BADGE_STATUSES | StatusEnum;
  isMarketComponent?: boolean;
};

export const SyncStatusBadge: FC<React.PropsWithChildren<SyncStatusBadgeProps>> = ({ status, isMarketComponent = false }) => {
  let colorClassName = 'tw-bg-green-400';
  let variantStatus = 'success' as MarketPillVariant;

  switch (status) {
    case BADGE_STATUSES.PENDING:
      colorClassName = 'tw-bg-orange-400';
      variantStatus = 'normal';
      break;
    case BADGE_STATUSES.IN_PROGRESS:
      colorClassName = 'tw-bg-orange-400';
      variantStatus = 'normal';
      break;
    case BADGE_STATUSES.NOT_SYNCED:
      colorClassName = 'tw-bg-orange-400';
      variantStatus = 'normal';
      break;
    case BADGE_STATUSES.QUEUED:
      colorClassName = 'tw-bg-yellow-400';
      variantStatus = 'warning';
      break;
    case BADGE_STATUSES.INACTIVE:
      colorClassName = 'tw-bg-red-500';
      variantStatus = 'critical';
      break;
    case BADGE_STATUSES.FAILED:
      colorClassName = 'tw-bg-red-500';
      variantStatus = 'critical';
      break;
  }

  return (
    <>
      {isMarketComponent ? (
        <MarketPill size="small" variant={variantStatus}>
          {humanReadableStatuses[status]}
        </MarketPill>
      ) : (
        <Tooltip tooltipClassName={colorClassName} tooltip={humanReadableStatuses[status]}>
          <span className={`tw-h-3 tw-min-w-3 tw-inline-block tw-rounded-full ${colorClassName}`} />
        </Tooltip>
      )}
    </>
  );
};
