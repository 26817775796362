import { IPagination, ItemBaseListResponseDto, IMerchantFilterParams } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemBaseFacade } from '../../facades';

type ItemBaseListParams = { merchantId: string; pagination?: IPagination; params?: IMerchantFilterParams };

export const useMerchantItemBaseList = (
  { merchantId, pagination, params }: ItemBaseListParams,
  options?: UseQueryOptions<ItemBaseListResponseDto>,
): UseQueryResult<ItemBaseListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_LIST, merchantId, pagination, params],
    async () => await merchantItemBaseFacade.list(merchantId, pagination, params),
    options,
  );
};
