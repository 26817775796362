import { CreateItemOptionsDto, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemOptionsFacade } from '../../../facades';

export type LocationCreateItemOptionsParams = {
  merchantId: string;
  storeId: string;
  data: CreateItemOptionsDto;
};

export const useLocationCreateItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, LocationCreateItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, LocationCreateItemOptionsParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationItemOptionsFacade.create(merchantId, storeId, data), options);
};
