import { IStoreItemBundleMod, ReadStoreItemComboDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemComboFacade } from '../../../facades';

type PatchComboModParams = {
  merchantId: string;
  uniqueName: string;
  data?: IStoreItemBundleMod;
};

export const usePatchComboMod = (
  options?: UseMutationOptions<ReadStoreItemComboDto, unknown, PatchComboModParams>,
): UseMutationResult<ReadStoreItemComboDto, unknown, PatchComboModParams> => {
  return useMutation(({ merchantId, uniqueName, data }) => merchantStoreItemComboFacade.updateMods(merchantId, uniqueName, data), options);
};
