import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { LocationGroupItem } from '../utils';
import { StoreSpecificItem } from '../constants';

export type GroupedOption = {
  label: string;
  options: { label: string; value: string }[];
};

export const getGroupedOptions = (
  locationGroup: LocationGroupItem[],
  storeIds: string[],
  selectedReplicas: StoreSpecificItem[],
  stores: ReadStoreDto[],
): GroupedOption[] => {
  return locationGroup
    .map((item) => {
      const hasLocationSelectedWithReplicas = item.replicas?.some((store) => storeIds.includes(store.storeId));

      if (hasLocationSelectedWithReplicas) {
        return {
          label: item.locationGroupData.name,
          options: item.replicas
            .map((replica) => {
              const selectedStoreId = storeIds.find((store) => replica.storeId === store);
              const filteredStores = stores.find((store) => store.storeId === selectedStoreId);

              if (filteredStores) {
                return { label: filteredStores.name, value: filteredStores.storeId };
              }
            })
            .filter(Boolean),
        };
      }

      const hasLocationSelected = selectedReplicas.find((selectedReplica) => selectedReplica.storeId === ((item as unknown) as ReadStoreItemDto).storeId);
      if (hasLocationSelected) {
        const filteredStores = stores.find((store) => store.storeId === hasLocationSelected.storeId);
        return { label: '', options: [{ label: filteredStores.name, value: filteredStores.storeId }] };
      }
    })
    .filter(Boolean);
};
