import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { locationStoreItemBundleFacade } from '../../../facades';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';
import { LocationGetRelativesQueryParams } from '../../../interface';

export const useGetLocationComboGroupParentsQuery = (
  { merchantId, storeId, uniqueName }: LocationGetRelativesQueryParams,
  options?: UseQueryOptions<ReadCategoryDto[], Error>,
): UseQueryResult<ReadCategoryDto[], Error> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_COMBO_GROUP_GET_PARENTS, merchantId, storeId, uniqueName],
    () => locationStoreItemBundleFacade.getParents(merchantId, storeId, uniqueName),
    options,
  );
};
