import React, { FC } from 'react';
import { InputNumber } from '@goparrot-dashboard/shared-ui';
import { MarketInputText } from '@market/react-bindings';

type Props = {
  initialValue: number;
  handleChange: (val: number) => void;
};

export const InputReplicaPrice: FC<React.PropsWithChildren<Props>> = React.memo(({ initialValue, handleChange }) => {
  const [value, setValue] = React.useState(initialValue);

  // if the initialValue is changed external, sync it up with out data
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="tw-h-10 tw-w-max">
      <MarketInputText value={value ? value.toString() : undefined}>
        <InputNumber
          slot="input"
          allowNegative={false}
          min={0}
          decimalScale={2}
          prefix="$ "
          value={value}
          onChange={(val) => {
            setValue(val);
            handleChange(val);
          }}
        />
      </MarketInputText>
    </div>
  );
});
