import { BatchCreateItemBaseDto, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemBaseFacade } from '../../../facades';

export type LocationCreateItemBaseParams = {
  merchantId: string;
  storeId: string;
  data: BatchCreateItemBaseDto;
};

export const useLocationCreateItemBase = (
  options: UseMutationOptions<ReadItemBaseDto, unknown, LocationCreateItemBaseParams>,
): UseMutationResult<ReadItemBaseDto, unknown, LocationCreateItemBaseParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationItemBaseFacade.create(merchantId, storeId, data), options);
};
