import { useI18n } from '@goparrot-dashboard/i18n';
import type { ComponentProps } from 'react';
import { SettlementConfigurationStatusEnum } from '@goparrot/giftcard-orchestrator-sdk';
import { isSettlementActive } from '../utils';
import type { ActionModal } from '../components';

export type GetModalParamsType = {
  isSQCheckingAccountEnabled: boolean;
  status?: SettlementConfigurationStatusEnum;
  onClick(): void;
  isLoading: boolean;
};

export const useGetModalConfig = ({
  isSQCheckingAccountEnabled,
  status = SettlementConfigurationStatusEnum.INACTIVE,
  onClick,
  isLoading,
}: GetModalParamsType): (() => Omit<ComponentProps<typeof ActionModal>, 'dialog'>) => {
  const { getIntlString } = useI18n();

  return () => {
    if (!isSQCheckingAccountEnabled) {
      return {
        title: getIntlString('giftCards.pooling.setupAccount.title'),
        text: getIntlString('giftCards.pooling.setupAccount.text'),
        actionConfig: {
          onClick,
          text: getIntlString('giftCards.pooling.setupAccount.button'),
          variant: 'regular' as const,
          isLoading,
        },
      };
    }

    if (isSettlementActive(status)) {
      return {
        title: getIntlString('giftCards.pooling.disable.title'),
        text: getIntlString('giftCards.pooling.disable.text'),
        actionConfig: {
          onClick,
          text: getIntlString('giftCards.pooling.disable.button'),
          variant: 'destructive' as const,
          isLoading,
        },
      };
    }

    return {
      title: getIntlString('giftCards.pooling.enable.title'),
      text: getIntlString('giftCards.pooling.enable.text'),
      actionConfig: {
        onClick,
        text: getIntlString('giftCards.pooling.enable.button'),
        variant: 'regular' as const,
        isLoading,
      },
    };
  };
};
