import { IMerchantFilterParams, IPagination, IResultsListResponse, IStoreFilterParams, ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { UseQueryResult } from 'react-query';
import { useLocationsItemBaseList, useMerchantItemBaseList } from '@goparrot-dashboard-api/menu-management';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { MENU_ELEMENTS_PLURAL, removeNullOrUndefinedObjectProperties } from '@goparrot-dashboard/shared-utils';
import isEmpty from 'lodash/isEmpty';

type Props = {
  merchantId: string;
  storeId: string;
  pagination?: IPagination;
  filters?: IStoreFilterParams | IMerchantFilterParams;
  isMerchant: boolean;
  enabled?: boolean;
};

export const useItemBaseListQuery = ({
  merchantId,
  storeId,
  pagination,
  isMerchant,
  filters,
  enabled = true,
}: Props): UseQueryResult<IResultsListResponse<ReadItemBaseDto>> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.list.fetch.error', { itemType: MENU_ELEMENTS_PLURAL.ITEM_BASE }),
      });
    },
  };

  const locationQuery = useLocationsItemBaseList(
    {
      merchantId,
      storeId,
      pagination,
      params: !isEmpty(filters) && (removeNullOrUndefinedObjectProperties(filters) as IStoreFilterParams),
    },
    {
      ...options,
      enabled: !isMerchant && enabled,
    },
  );

  const merchantQuery = useMerchantItemBaseList(
    {
      merchantId,
      pagination,
      params: !isEmpty(filters) && (removeNullOrUndefinedObjectProperties(filters) as IMerchantFilterParams),
    },
    {
      ...options,
      enabled: isMerchant && enabled,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
