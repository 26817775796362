import React, { useMemo } from 'react';
import { useFormFieldProps, useInputProps } from '@goparrot-dashboard/entity-form';
import { FormField } from '@goparrot-dashboard/shared-ui';
import { DiningOptionEnum } from '@goparrot/common';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { hasProperty, removeDuplicate } from '@goparrot-dashboard/shared-utils';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useMenuFormContext } from '../../../context';
import { getMenuFormIntlString } from '../utils';
import { ItemDiningOptionsSelect } from './ItemDiningOptionsSelect';
type ItemEntity = {
  metadata: {
    diningOptions?: DiningOptionEnum[];
  };
  storeId: string;
  merchantId: string;
};

const DINING_OPTIONS_MAPPING = {
  [DiningOptionEnum.TAKE_OUT]: getMenuFormIntlString('dining-options.option.TAKE_OUT'),
  [DiningOptionEnum.DINE_IN]: getMenuFormIntlString('dining-options.option.DINE_IN'),
  [DiningOptionEnum.CURBSIDE]: getMenuFormIntlString('dining-options.option.CURBSIDE'),
  [DiningOptionEnum.DELIVERY]: getMenuFormIntlString('dining-options.option.DELIVERY'),
};

export const ItemDiningOptionsAvailability = <T extends ItemEntity>(): React.ReactElement | null => {
  const { form, isMerchantLevelItem, isCreating, storeId } = useMenuFormContext<T>();
  const { isMerchant, stores, defaultStores } = useStoreService();
  const bindFormFieldProps = useFormFieldProps(form);
  const bindInputProps = useInputProps(form);

  const merchantDiningOptions = removeDuplicate(
    defaultStores.reduce((result, store) => {
      const options = store.featuresFlags.availableDiningOptions || [];
      return [...result, ...options];
    }, []),
  );
  const merchantDiningOptionsMapped = merchantDiningOptions.reduce(
    (result, option) => ({ ...result, [option]: getMenuFormIntlString(`dining-options.option.${option}`) }),
    {},
  );

  const getSelectedLabel = (options) =>
    getIntlString('menu.form.dining-options.select.counter', { count: options?.length ?? 0, max: Object.keys(availableDiningOptions).length });

  const availableDiningOptions = useMemo(() => {
    if ((isCreating && isMerchant) || (!isCreating && isMerchantLevelItem)) {
      return merchantDiningOptionsMapped;
    }

    const storeDiningOptionsConfig = stores.find((store) => store.storeId === storeId)?.diningOptionsConfig || {};
    const allDiningOptions = { ...DINING_OPTIONS_MAPPING };
    Object.keys(allDiningOptions).forEach((key) => {
      const hasKey = hasProperty(storeDiningOptionsConfig, key);
      if (!hasKey) {
        delete allDiningOptions[key];
      }
    });
    return allDiningOptions;
  }, [isCreating, isMerchant, isMerchantLevelItem, stores, merchantDiningOptionsMapped, storeId]);

  return (
    <FormField
      {...bindFormFieldProps('metadata.diningOptions')}
      label={getMenuFormIntlString('dining-options.title')}
      withValuePadding={false}
      input={
        <ItemDiningOptionsSelect
          {...bindInputProps('metadata.diningOptions')}
          getSelectedLabel={getSelectedLabel}
          availableDiningOptions={availableDiningOptions}
          allDiningOptions={DINING_OPTIONS_MAPPING}
        />
      }
    />
  );
};
