import { CountryISOCodeEnum, SupportedCurrencyEnum } from '@goparrot/common';

export const getDefaultCurrencyByCountryCode = (countryCode?: CountryISOCodeEnum): SupportedCurrencyEnum => {
  switch (countryCode) {
    case CountryISOCodeEnum.Australia:
      return SupportedCurrencyEnum.AUD;
    case CountryISOCodeEnum.Canada:
      return SupportedCurrencyEnum.CAD;
    default:
      return SupportedCurrencyEnum.USD;
  }
};
