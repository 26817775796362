import { CreateItemBaseGroupDto, ReadItemBaseGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemBaseGroupFacade } from '../../../facades';

export type MerchantCreateItemBaseGroupParams = {
  merchantId: string;
  data: CreateItemBaseGroupDto;
};

export const useMerchantCreateItemBaseGroup = (
  options: UseMutationOptions<ReadItemBaseGroupDto, unknown, MerchantCreateItemBaseGroupParams>,
): UseMutationResult<ReadItemBaseGroupDto, unknown, MerchantCreateItemBaseGroupParams> => {
  return useMutation(async ({ merchantId, data }) => await merchantItemBaseGroupFacade.create(merchantId, data), options);
};
