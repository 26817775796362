import { all, fork } from 'redux-saga/effects';

import watcherApp from './app/saga';
import watcherAuth from './auth/saga';
import watcherUser from './user/saga';
import watcherMerchant from './merchant/saga';
import watcherLocation from './location/saga';
import watcherLocationDefault from './location-default/saga';
import watcherOrder from './order/saga';
import watcherWebstore from './webstore/saga';
import watcherPos from './pos/saga';
import watcherWebsocket from './websocket/saga';
import watcherFranchise from './franchise/saga';

export default function* rootSaga() {
  yield all([
    fork(watcherApp),
    fork(watcherAuth),
    fork(watcherUser),
    fork(watcherMerchant),
    fork(watcherLocation),
    fork(watcherLocationDefault),
    fork(watcherOrder),
    fork(watcherWebstore),
    fork(watcherPos),
    fork(watcherWebsocket),
    fork(watcherFranchise),
  ]);
}
