import {
  usePatchItemBaseMod,
  useLocationUpdateItemBase,
  useMerchantUpdateItemBase,
  LocationUpdateItemBaseParams,
  PatchItemBaseModParams,
  MerchantUpdateItemBaseParams,
} from '@goparrot-dashboard-api/menu-management';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { UseMutationResult } from 'react-query';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { MENU_ELEMENTS } from '../../constants';

type Props = {
  successCallback?: (result: ReadItemBaseDto) => void;
};

export type UpdateItemBaseActionsType = {
  updateMods: UseMutationResult<ReadItemBaseDto, unknown, PatchItemBaseModParams>;
  merchantLevelUpdate: UseMutationResult<ReadItemBaseDto, unknown, MerchantUpdateItemBaseParams>;
  storeLevelUpdate: UseMutationResult<ReadItemBaseDto, unknown, LocationUpdateItemBaseParams>;
  isLoading: boolean;
};

export const useUpdateItemBaseActions = ({ successCallback }: Props): UpdateItemBaseActionsType => {
  const { notifyError, notifySuccess } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.update.error'),
      });
    },
    onSuccess: (result) => {
      notifySuccess({
        text: getIntlString('menu.notifications.update.success', { itemType: MENU_ELEMENTS.ITEM_BASE }),
      });
      successCallback && successCallback(result);
    },
  };
  const updateMods = usePatchItemBaseMod(options);
  const storeLevelUpdate = useLocationUpdateItemBase(options);
  const merchantLevelUpdate = useMerchantUpdateItemBase(options);

  return {
    updateMods,
    merchantLevelUpdate,
    storeLevelUpdate,
    isLoading: updateMods.isLoading || merchantLevelUpdate.isLoading || storeLevelUpdate.isLoading,
  };
};
