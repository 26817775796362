import React, { FC } from 'react';
import { ExplicitFranchiseLocationOptions } from '../../types';
import { MenuManagement } from '../menu-management/MenuManagement';
import { GiftCardManagement } from '../gift-card-management/GiftCardManagement';

type MenuManagementWrapperProps = {
  showSynchronizationStatus?: boolean;
  changedMenuOptions: any;
  changeOption: (key: keyof ExplicitFranchiseLocationOptions, value: boolean) => void;
  isMenuSettingsPageEnabled?: boolean;
};

export const MenuManagementWrapper: FC<React.PropsWithChildren<MenuManagementWrapperProps>> = (props) => {
  return (
    <div data-testid="franchise-management-settings-content">
      {!props.isMenuSettingsPageEnabled && (
        <MenuManagement showSynchronizationStatus={props.showSynchronizationStatus} options={props.changedMenuOptions} onChange={props.changeOption} />
      )}
      <GiftCardManagement showSynchronizationStatus={props.showSynchronizationStatus} />
    </div>
  );
};
