import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemBaseFacade } from '../../facades';

type ItemBaseGetParams = { merchantId: string; uniqueName: string };

export const useMerchantItemBaseGet = (
  { merchantId, uniqueName }: ItemBaseGetParams,
  options?: UseQueryOptions<ReadItemBaseDto>,
): UseQueryResult<ReadItemBaseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_BASE_GET, merchantId, uniqueName],
    async () => await merchantItemBaseFacade.get(merchantId, uniqueName),
    options,
  );
};
