import { IUpdateRelationsRequest, UpdateRelationsResponseDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationUpdateCategoryChildrensParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: IUpdateRelationsRequest;
};

export const useLocationUpdateCategoryChildrens = (
  options: UseMutationOptions<UpdateRelationsResponseDto, unknown, LocationUpdateCategoryChildrensParams>,
): UseMutationResult<UpdateRelationsResponseDto, unknown, LocationUpdateCategoryChildrensParams> => {
  return useMutation(
    async ({ merchantId, storeId, uniqueName, data }) => await locationCategoryFacade.updateChildren(merchantId, storeId, uniqueName, data),
    options,
  );
};
