import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { useI18n } from '@goparrot-dashboard/i18n';
import {
  GetRelativesQueryBaseParams,
  StoreItemGroupChildrenType,
  useLocationGetStoreItemGroupChildrenQuery,
  useMerchantGetStoreItemGroupChildrenQuery,
} from '@goparrot-dashboard-api/menu-management';
import { UseQueryResult } from 'react-query';
import { MENU_ELEMENTS } from '../constants';

type Props = GetRelativesQueryBaseParams & {
  storeId: string;
  isMerchant: boolean;
  enabled?: boolean;
};

export const useGetStoreItemGroupChildrensQuery = ({
  merchantId,
  storeId,
  uniqueName,
  isMerchant,
  enabled = true,
}: Props): UseQueryResult<StoreItemGroupChildrenType[]> => {
  const { notifyError } = useNotifications();
  const { getIntlString } = useI18n();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.connections.modal.preview.get.error', {
          values: { itemName: MENU_ELEMENTS.STORE_ITEM_GROUP },
          isRootPath: true,
        }),
      });
    },
  };

  const locationQuery = useLocationGetStoreItemGroupChildrenQuery(
    { merchantId, storeId, uniqueName },
    {
      ...options,
      enabled: enabled && !isMerchant,
    },
  );

  const merchantQuery = useMerchantGetStoreItemGroupChildrenQuery(
    { merchantId, uniqueName },
    {
      ...options,
      enabled: enabled && isMerchant,
    },
  );

  return isMerchant ? merchantQuery : locationQuery;
};
