import { resolve } from 'url';
import { IFacadeInterceptors, ACCESS_TOKEN_HEADER } from '@goparrot/common';
import type { AxiosInstance } from 'axios';
import axios from 'axios';
import { TokenUtility } from './token-utility';

export const requestProvideTokenInterceptor = (config) => {
  const next = config;
  const { token } = TokenUtility.get() || {};
  if (token) {
    next.headers[ACCESS_TOKEN_HEADER] = token;
  }
  return next;
};

export const getConfig = (postfix = '') => ({
  axiosConfig: {
    baseURL: resolve(window._env_.GOPARROT_PLATFORM_SERVICE_GATEWAY, postfix),
    timeout: 60000,
  },
});

const logoutInterceptor = {
  onFulfilled: (data) => data,
  async onRejected(error) {
    if (error && error.response) {
      const { response } = error;
      if (
        401 === response.status &&
        response.data &&
        'Unauthorized' === response.data.error &&
        // Token expired
        ('jwt expired' === response.data.message ||
          // Token expired and cleared from within another tab
          'header "x-access-token" must be provided' === response.data.message)
      ) {
        // todo: rethink this
        const { store } = await import('../../../modules/client/src/store/index');
        const aAuth = (await import('../../../modules/client/src/store/auth/actions')).default;
        store.dispatch({ type: aAuth.LOCAL__AUTH__LOGOUT });
      }
    }
    return Promise.reject(error);
  },
};

export const getInterceptors = (): IFacadeInterceptors => ({
  request: [
    // @ts-ignore todo
    requestProvideTokenInterceptor,
    {
      onFulfilled: requestProvideTokenInterceptor, // for axios from SDK
    },
  ],
  response: [logoutInterceptor],
});

const axiosInstance: AxiosInstance = axios.create({
  baseURL: window._env_.GOPARROT_PLATFORM_SERVICE_GATEWAY,
  timeout: 30000,
});

axiosInstance.interceptors.request.use(requestProvideTokenInterceptor);
axiosInstance.interceptors.response.use(
  (res) => {
    const oldTokenObj = TokenUtility.get();
    if (null !== oldTokenObj) {
      const newToken = res.headers[ACCESS_TOKEN_HEADER];
      if (newToken !== undefined && oldTokenObj.token !== newToken) {
        TokenUtility.set(newToken);
      }
    }
    return res;
  },
  (err) => Promise.reject(err),
);

export { axiosInstance as axios };
