import * as React from 'react';
import { ItemToggle } from './ItemToggle';
import { getMenuFormIntlString } from './utils';

export const ItemAvailability = <T extends { isAvailable: boolean }>(): React.ReactElement | null => (
  <ItemToggle<T>
    field="isAvailable"
    formLabel={getMenuFormIntlString('availability')}
    checkedLabel={getMenuFormIntlString('availability.checkedLabel')}
    uncheckedLabel={getMenuFormIntlString('availability.uncheckedLabel')}
    franchiseEditPermissionField="canEditAvailability"
  />
);
