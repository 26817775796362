import { IPagination, IStoreMenuConceptFilterParams, MenuConceptListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationMenuConceptsFacade } from '../../facades';

type LocationMenuConceptListParams = { merchantId: string; storeId: string; pagination?: IPagination; params?: IStoreMenuConceptFilterParams };

export const useLocationMenuConceptsList = (
  { merchantId, storeId, pagination, params }: LocationMenuConceptListParams,
  options?: UseQueryOptions<MenuConceptListResponseDto>,
): UseQueryResult<MenuConceptListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_MENU_CONCEPTS_LIST, merchantId, storeId, pagination, params],
    async () => await locationMenuConceptsFacade.list(merchantId, storeId, pagination, params),
    options,
  );
};
