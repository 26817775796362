import { ReadMenuConceptDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { locationMenuConceptsFacade } from '../../facades';

type LocationMenuConceptGetParams = { merchantId: string; storeId: string; uniqueName: string };

export const useLocationMenuConceptGet = (
  { merchantId, storeId, uniqueName }: LocationMenuConceptGetParams,
  options?: UseQueryOptions<ReadMenuConceptDto>,
): UseQueryResult<ReadMenuConceptDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_MENU_CONCEPT_GET, merchantId, storeId, uniqueName],
    async () => await locationMenuConceptsFacade.get(merchantId, storeId, uniqueName),
    options,
  );
};
