import { ICloneRequest, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemSetFacade } from '../../../facades';

export type MerchantCloneItemSetParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneItemSet = (
  options: UseMutationOptions<ReadItemSetDto, unknown, MerchantCloneItemSetParams>,
): UseMutationResult<ReadItemSetDto, unknown, MerchantCloneItemSetParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemSetFacade.clone(merchantId, uniqueName, data), options);
};
