import * as React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/es/switch';
import classNames from 'classnames';

const AntSwitch: React.FC<React.PropsWithChildren<SwitchProps>> = ({ className, ...props }) => (
  <Switch
    className={classNames({
      [className]: !!className,
    })}
    {...props}
  />
);

export default AntSwitch;
