import { ICloneRequest, ReadItemOptionsDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantItemOptionsFacade } from '../../../facades';

export type MerchantCloneItemOptionsParams = {
  merchantId: string;
  uniqueName: string;
  data: ICloneRequest;
};

export const useMerchantCloneItemOptions = (
  options: UseMutationOptions<ReadItemOptionsDto, unknown, MerchantCloneItemOptionsParams>,
): UseMutationResult<ReadItemOptionsDto, unknown, MerchantCloneItemOptionsParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantItemOptionsFacade.clone(merchantId, uniqueName, data), options);
};
