import aAuth from './auth/actions';

import { store, history } from './';

export default () =>
  new Promise(() => {
    const isOnUnauthorizedRoutes = history.location.pathname.startsWith('/auth');
    if (!isOnUnauthorizedRoutes) {
      store.dispatch(aAuth.requestLoginByToken());
    }
  });
