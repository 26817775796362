import { UseQueryResult } from 'react-query';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { useLocationStoreItemReplicasGet } from '@goparrot-dashboard-api/menu-management';
import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';

type Props = {
  uniqueName: string;
  enabled: boolean;
};

export const useGetStoreItemReplicas = ({ uniqueName, enabled }: Props): UseQueryResult<ReadStoreItemDto[]> => {
  const { notifyError } = useNotifications();

  const options = {
    onError: () => {
      notifyError({
        text: getIntlString('menu.notifications.get.error', {
          itemType: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.store-item.loading'),
        }),
      });
    },
  };

  const query = useLocationStoreItemReplicasGet(
    { uniqueName },
    {
      ...options,
      enabled: !!uniqueName && enabled,
      retry: 2,
    },
  );

  return query;
};
