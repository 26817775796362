import { ROUTE_BASE } from '../constants';
import { checkIfRouteExists } from './checkIfRouteExists';

export const getNextRoute = (storeId: string | null, defaultPath = '', isMerchant = false): string => {
  const pathname = location.pathname;
  const route = [ROUTE_BASE, storeId].filter(Boolean);
  const navSection = pathname.split('/').filter(Boolean).pop();
  const nextRoute = [...route, navSection].join('/');

  return checkIfRouteExists(nextRoute, isMerchant) ? nextRoute : [...route, defaultPath].join('/');
};
