import { PatchStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationStoreItemGroupFacade } from '../../../facades';

export type LocationUpdateStoreItemGroupParams = {
  merchantId: string;
  storeId: string;
  uniqueName: string;
  data: PatchStoreItemGroupDto;
};
export type LocationUpdateStoreItemGroupResult = UseMutationResult<ReadStoreItemGroupDto, unknown, LocationUpdateStoreItemGroupParams>;

export const useLocationUpdateStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, LocationUpdateStoreItemGroupParams>,
): LocationUpdateStoreItemGroupResult => {
  return useMutation(({ merchantId, storeId, uniqueName, data }) => locationStoreItemGroupFacade.patch(merchantId, storeId, uniqueName, data), options);
};
