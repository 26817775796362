import { IMerchantFilterParams, IPagination, ItemOptionsListResponseDto } from '@goparrot/storeitems-sdk';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../enums';
import { merchantItemOptionsListCallback } from './utils/merchantItemOptionsListCallback';

type MerchantItemOtionsListParams = { merchantId: string; pagination?: IPagination; params?: IMerchantFilterParams };

export const useMerchantItemOptionsList = (
  { merchantId, pagination, params }: MerchantItemOtionsListParams,
  options?: UseQueryOptions<ItemOptionsListResponseDto>,
): UseQueryResult<ItemOptionsListResponseDto> => {
  return useQuery(
    [MENU_MANAGEMENT_QUERIES_ENUM.MERCHANT_ITEM_OPTION_LIST, merchantId, pagination, params],
    async () => await merchantItemOptionsListCallback({ merchantId, pagination, params }),
    options,
  );
};
