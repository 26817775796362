import { getFlatValidationErrors } from '@goparrot/common';
import { transformAndValidateSync } from 'class-transformer-validator';

export function transformAndValidateEntitySync(classType, object, options) {
  try {
    return transformAndValidateSync(classType, object, options);
  } catch (errors) {
    throw getFlatValidationErrors(errors);
  }
}
