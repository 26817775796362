import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { locationItemBaseReplicaFacade } from '@goparrot-dashboard-api/shared';
import { ReadItemBaseDto } from '@goparrot/storeitems-sdk';
import { MENU_MANAGEMENT_QUERIES_ENUM } from '../../../enums';

export type LocationItemBaseReplicasGetParams = { uniqueName: string };

export const useLocationItemBaseReplicasGet = (
  { uniqueName }: LocationItemBaseReplicasGetParams,
  options?: UseQueryOptions<ReadItemBaseDto[]>,
): UseQueryResult<ReadItemBaseDto[]> =>
  useQuery([MENU_MANAGEMENT_QUERIES_ENUM.LOCATION_STORE_ITEM_REPLICAS_GET, uniqueName], () => locationItemBaseReplicaFacade.list(uniqueName), options);
