import { takeEvery, put, call } from 'redux-saga/effects';

import aApp from 'store/app/actions';

import aWs from '../websocket/actions';
import { setListOfUniqueName } from './utils';

import a from './actions';
import * as api from './api';

function* handleGetItemsFromPosByQuery({ payload }) {
  try {
    const { merchantId, storeId, query, type } = payload;
    const res = yield call(api.getItemsFromPOSByQuery(merchantId, storeId, query, type));
    yield put(a.successGetItemsFromPOSByQuery(res));
  } catch (e) {
    yield put(aApp.pushNotificationWithError('menu.form.posGuid.error.message', 'menu.form.posGuid.error.description'));
    yield put(a.errorGetItemsFromPOSByQuery(e));
  }
}

/**
 * Sync item with POS
 */

function* handleSyncItemFromPOS({ payload }) {
  const { merchantId, storeId, uniqueName, type, title } = payload;
  try {
    const result = yield call(api.registerSyncJob(merchantId, storeId, type, uniqueName));
    const { jobId } = result;
    yield setListOfUniqueName(uniqueName);
    yield put(aWs.emitItemSyncJob({ jobId }));
    sessionStorage.setItem(uniqueName, JSON.stringify({ jobId, title }));
    yield put(a.successSyncItemsFromPOS(result));
    yield put(aApp.pushMenuSyncNotificationWithInfo({ ...payload }));
  } catch (e) {
    yield put(a.errorSyncItemsFromPOS(e));
    yield put(aApp.pushNotificationWithError('menu.sync.error.message', 'menu.sync.error.description'));
  }
}

function* handleGetCategorySyncConfig({ payload }) {
  const { merchantId } = payload;
  try {
    const result = yield call(api.listCategorySyncConfig(merchantId));
    yield put(a.successCategoryGetSyncConfig(result));
  } catch (e) {
    yield put(aApp.pushNotificationWithError('menu.auto-sync.error.message', 'menu.auto-sync.error.description'));
  }
}

function* handlePatchCategorySyncConfig({ payload }) {
  const { merchantId, uuid, data } = payload;
  try {
    const result = yield call(api.patchCategorySyncConfig(merchantId, uuid, data));

    yield put(a.successCategoryPatchSyncConfig([result]));
    yield put(aApp.pushNotificationWithSuccess('menu.auto-sync.success.message', 'menu.auto-sync.success.description'));
  } catch (e) {
    a.errorCategoryGetSyncConfig(e);
    yield put(aApp.pushNotificationWithError('menu.auto-sync.error.message', 'menu.auto-sync.error.description'));
  }
}

function* handleGetTaxesFromPosByQuery({ payload }) {
  try {
    const { storeId, query } = payload;
    const result = yield call(api.getTaxFromPosByQuery(storeId, query));
    yield put(a.successGetTaxesFromPOSByQuery(result));
  } catch (e) {
    yield put(aApp.pushNotificationWithError('menu.form.posGuid.error.message', 'menu.form.posGuid.error.description'));
    yield put(a.errorGetItemsFromPOSByQuery(e));
  }
}

export default function* () {
  yield takeEvery(a.API__VENDOR__GET__ITEM__FROM__POS__BY__QUERY__REQUEST, handleGetItemsFromPosByQuery);
  yield takeEvery(a.API__VENDOR__SYNC__ITEM__FROM__POS__REQUEST, handleSyncItemFromPOS);
  yield takeEvery(a.API__CATEGORY__GET__SYNC__CONFIG__REQUEST, handleGetCategorySyncConfig);
  yield takeEvery(a.API__CATEGORY__PATCH__SYNC__CONFIG__REQUEST, handlePatchCategorySyncConfig);
  yield takeEvery(a.API__GET__TAX__FROM__POS__BY__QUERY__REQUEST, handleGetTaxesFromPosByQuery);
}
