import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { ISearchGiftCardMappingsDto } from '@goparrot/giftcard-orchestrator-sdk';
import { giftCardCollisionFacade } from '../facades';
import { ORCHESTRATION_QUERIES_ENUM } from '../enums';

export const useGetGiftCardCollision = (
  merchantId: string,
  options?: UseQueryOptions<ISearchGiftCardMappingsDto>,
): UseQueryResult<ISearchGiftCardMappingsDto> => {
  return useQuery([ORCHESTRATION_QUERIES_ENUM.GIFT_CARD_GET_COLLISIONS, merchantId], () => giftCardCollisionFacade.list(merchantId), options);
};
