const c = {
  LOCAL__APP__COLLAPSE_OPEN_DRAWER: 'LOCAL__APP__COLLAPSE_OPEN_DRAWER',
  LOCAL__APP__CHANGE_OPEN_KEYS: 'LOCAL__APP__CHANGE_OPEN_KEYS',
  LOCAL__APP__CHANGE_CURRENT: 'LOCAL__APP__CHANGE_CURRENT',
  LOCAL__APP__SET_LOCALE: 'LOCAL__APP__SET_LOCALE',
  LOCAL__APP__PUSH_NOTIFICATION__SUCCESS: 'LOCAL__APP__PUSH_NOTIFICATION__SUCCESS',
  LOCAL__APP__PUSH_NOTIFICATION__ERROR: 'LOCAL__APP__PUSH_NOTIFICATION__ERROR',
  LOCAL__APP__PUSH_NOTIFICATION__WARNING: 'LOCAL__APP__PUSH_NOTIFICATION__WARNING',
  LOCAL__APP__PUSH_DRAGANDDROP__NOTIFICATION__SUCCESS: 'LOCAL__APP__PUSH_DRAGANDDROP__NOTIFICATION__SUCCESS',
  LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__SUCCESS: 'LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__SUCCESS',
  LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__INFO: 'LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__INOF',
  LOCAL__APP__CAMPAIGN__NOTIFICATION__ERROR: 'LOCAL__APP__CAMPAIGN__NOTIFICATION__ERROR',
};
const a = {
  toggleOpenDrawer: () => ({
    type: c.LOCAL__APP__COLLAPSE_OPEN_DRAWER,
  }),
  changeOpenKeys: (openKeys) => ({
    type: c.LOCAL__APP__CHANGE_OPEN_KEYS,
    openKeys,
  }),
  changeCurrent: (current, redirect = false) => ({
    type: c.LOCAL__APP__CHANGE_CURRENT,
    payload: { current, redirect },
  }),
  pushNotificationWithSuccess: (message, description = null) => ({
    type: c.LOCAL__APP__PUSH_NOTIFICATION__SUCCESS,
    payload: { message, description },
  }),
  pushNotificationWithError: (message, description = null, isCustom = false) => ({
    type: c.LOCAL__APP__PUSH_NOTIFICATION__ERROR,
    payload: { message, description, isCustom },
  }),
  pushNotificationWithWarning: (message, description = null) => ({
    type: c.LOCAL__APP__PUSH_NOTIFICATION__WARNING,
    payload: { message, description },
  }),
  setLocale: (payload) => ({
    type: c.LOCAL__APP__SET_LOCALE,
    payload,
  }),
  pushDragAndDropNotificationWithSuccess: (payload) => ({
    type: c.LOCAL__APP__PUSH_DRAGANDDROP__NOTIFICATION__SUCCESS,
    payload,
  }),
  pushMenuSyncNotificationWithSuccess: (payload) => ({
    type: c.LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__SUCCESS,
    payload,
  }),

  pushMenuSyncNotificationWithInfo: (payload) => ({
    type: c.LOCAL__APP__PUSH_MENUSYNC__NOTIFICATION__INFO,
    payload,
  }),

  pushCampaignNotificationWithError: (payload) => ({
    type: c.LOCAL__APP__CAMPAIGN__NOTIFICATION__ERROR,
    payload,
  }),
};
export default { ...c, ...a };
