import React, { useCallback } from 'react';
import { GenericTable, MarketIconDualRestore, MarketIconTrash, Spinner, TableCellText } from '@goparrot-dashboard/shared-ui';
import '../../style.scss';
import { useTable } from 'react-table';
import { MarketCheckbox, MarketButton } from '@market/react-bindings';
import { getIntlCurrency, useI18n } from '@goparrot-dashboard/i18n';
import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { MENU_ELEMENTS_PLURAL } from '@goparrot-dashboard/shared-utils';
import { getCentralLocation } from '@goparrot-dashboard/orchestration';
import { ReadStoreItemDto } from '@goparrot/storeitems-sdk';
import { getTaxPercentageValue, getStoreTitle } from '../../utils';
import { ORDERABILITY_VALUES, ReplicaUpdateDataFields, StoreSpecificItem } from './constants';
import { InputReplicaPrice } from './InputReplicaPrice';
import { SelectReplicaOrderable } from './SelectReplicaOrderable';
import { INIT_TABLE_DATA, getTableItem } from './utils';
import { InputReplicaTitle } from './InputReplicaTitle';
import { ReplicasUpdateData } from './StoreSpecificDisplaySettingsModal';

type Props = {
  replicasUpdateData: React.MutableRefObject<ReplicasUpdateData>;
  dataSource: StoreSpecificItem[];
  selectedReplicas: StoreSpecificItem[];
  toggleSelectReplica: (replica: StoreSpecificItem) => void;
  toggleReplicaArchived: (replica: StoreSpecificItem) => void;
  handleUpdateReplicaData: ({ uniqueName, data }: { uniqueName: string; data: ReplicaUpdateDataFields }) => void;
};

export const TableReplicas: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ replicasUpdateData, dataSource, selectedReplicas, toggleSelectReplica, toggleReplicaArchived, handleUpdateReplicaData }) => {
    const { getIntlString } = useI18n();
    const {
      stores,
      merchant: { merchantId },
      franchiseLocations,
      isVirtualMenu,
    } = useStoreService();
    const getStoreName = React.useCallback((storeId: string) => getStoreTitle({ locations: stores, storeId, merchantId: merchantId, isVirtualMenu }), [
      stores,
      merchantId,
      isVirtualMenu,
    ]);
    const centralLocation = getCentralLocation(franchiseLocations);
    const getIsCentralLocationItem = useCallback(
      (original: StoreSpecificItem) => {
        return isVirtualMenu ? original.storeId === merchantId : original.storeId === centralLocation?.storeId;
      },
      [centralLocation?.storeId, isVirtualMenu, merchantId],
    );

    const handleChange = useCallback(
      ({ uniqueName, value, field }: any) => {
        handleUpdateReplicaData({ uniqueName, data: { [field]: value } });
      },
      [handleUpdateReplicaData],
    );

    const getReplicaIsSelected = useCallback(
      (replicaId: string) => {
        return selectedReplicas.some((item) => item.uniqueName === replicaId);
      },
      [selectedReplicas],
    );

    const columns = React.useMemo(
      () => [
        {
          Header: '',
          accessor: 'type',
          className: 'tw-w-5',
          disableSortBy: true,
          Cell: () => null,
        },
        {
          Header: '',
          accessor: 'uniqueName',
          className: 'tw-w-0',
          disableSortBy: true,
          Cell: ({ row: { original } }: { row: { original: StoreSpecificItem } }) => (
            <div className="tw-flex tw-align-center tw-justify-center tw-relative">
              <MarketCheckbox
                checked={getReplicaIsSelected(original.uniqueName) || undefined}
                onMarketCheckboxValueChange={() => {
                  toggleSelectReplica(original);
                }}
              />
            </div>
          ),
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.location', { isRootPath: true }),
          accessor: 'storeId',
          disableSortBy: true,
          className: 'tw-w-2/12',
          Cell: ({ value, row: { original } }: { value: string; row: { original: StoreSpecificItem } }) => (
            <TableCellText>
              <div>
                <span className="tw-w-max tw-text-blue-gray-900 tw-font-medium tw-text-sm tw-break-all">{getStoreName(value)}</span>
                {original.isArchived && (
                  <div className="tw-text-blue-gray-400 tw-text-xs tw-mt-1">
                    {getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.archivedItem', { isRootPath: true })}
                  </div>
                )}
              </div>
            </TableCellText>
          ),
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.title', { isRootPath: true }),
          disableSortBy: true,
          accessor: 'title',
          className: 'tw-w-2/12',
          Cell: ({ row: { original } }: { row: { original: StoreSpecificItem } }) => {
            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.title;

            return (
              <TableCellText>
                {getIsCentralLocationItem(original) ? (
                  <div>{value}</div>
                ) : (
                  <InputReplicaTitle
                    initialValue={value}
                    handleChange={(val) => handleChange({ uniqueName: original.uniqueName, value: val, field: 'title' })}
                  />
                )}
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability', { isRootPath: true }),
          disableSortBy: true,
          accessor: 'isOrderable',
          className: 'tw-w-2/12',
          Cell: ({ row: { original } }: { row: { original: StoreSpecificItem } }) => {
            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.isOrderable;

            return (
              <TableCellText>
                {getIsCentralLocationItem(original) ? (
                  <div>
                    {getIntlString(`menu.form.section.displaySettings.storeSpecific.modal.table.columns.orderability.${value ? 'orderable' : 'nonOrderable'}`, {
                      isRootPath: true,
                    })}
                  </div>
                ) : (
                  <SelectReplicaOrderable
                    initialValue={value}
                    disabled={original.isArchived}
                    handleChange={(val) =>
                      handleChange({ uniqueName: original.uniqueName, value: val === ORDERABILITY_VALUES.ORDERABLE, field: 'isOrderable' })
                    }
                  />
                )}
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.price', { isRootPath: true }),
          accessor: 'price',
          disableSortBy: true,
          className: 'tw-w-20',
          Cell: ({ row: { original } }: { row: { original: StoreSpecificItem } }) => {
            const item = getTableItem({ original: original as ReadStoreItemDto, replicasUpdateData });
            const value = item.price;

            return (
              <TableCellText>
                {getIsCentralLocationItem(original) ? (
                  <div className="tw-w-20">{getIntlCurrency(value)}</div>
                ) : (
                  <div className="tw-w-20">
                    <InputReplicaPrice
                      initialValue={value}
                      handleChange={(val) => handleChange({ uniqueName: original.uniqueName, value: val ?? 0, field: 'price' })}
                    />
                  </div>
                )}
              </TableCellText>
            );
          },
        },
        {
          Header: getIntlString('menu.form.section.displaySettings.storeSpecific.modal.table.columns.tax', { isRootPath: true }),
          accessor: 'tax_percentage',
          disableSortBy: true,
          className: 'tw-w-1/12',
          Cell: ({ value }: { value: number }) => {
            return <TableCellText>{value ? <span>{`${getTaxPercentageValue(value)} %`}</span> : '-'}</TableCellText>;
          },
        },
        {
          key: 'more',
          Header: '',
          accessor: 'isArchived',
          disableSortBy: true,
          className: 'tw-w-2/12',
          Cell: ({ value, row: { original } }: { value: number; row: { original: StoreSpecificItem } }) => {
            return getIsCentralLocationItem(original) ? null : (
              <div className="tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
                <MarketButton variant={value ? 'regular' : 'destructive'} rank="tertiary" size="small" onClick={() => toggleReplicaArchived(original)}>
                  {value ? (
                    <>
                      <MarketIconDualRestore />
                      {getIntlString('button.restore', { isRootPath: true })}
                    </>
                  ) : (
                    <>
                      <MarketIconTrash />
                      {getIntlString('button.delete', { isRootPath: true })}
                    </>
                  )}
                </MarketButton>
              </div>
            );
          },
        },
      ],
      [
        getIntlString,
        getReplicaIsSelected,
        toggleSelectReplica,
        getStoreName,
        replicasUpdateData,
        getIsCentralLocationItem,
        handleChange,
        toggleReplicaArchived,
      ],
    );

    const tableProps = useTable({ columns, data: dataSource ?? INIT_TABLE_DATA });

    return (
      <Spinner spinning={false} className="tw-w-full">
        <GenericTable
          {...tableProps}
          isTableVirtualized={false}
          placeholder={getIntlString('menu.list.table.empty', { values: { itemType: MENU_ELEMENTS_PLURAL.STORE_ITEM }, isRootPath: true })}
        />
      </Spinner>
    );
  },
);
