import { assign } from 'lodash/fp';

import aAuth from 'store/auth/actions';

import a from '../actions';

const initialState = {
  data: {},
  fetching: false,
  error: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case a.API__USER__UPDATE__REQUEST:
      return assign(state, { fetching: true });

    case aAuth.API__AUTH__LOGIN__SUCCESS:
    case a.API__USER__CREATE__SUCCESS:
    case a.API__USER__UPDATE__SUCCESS:
    case aAuth.API__AUTH__CONFIRM_EMAIL__VALIDATE_TOKEN__SUCCESS:
    case aAuth.API__AUTH__RESET_PASSWORD__CONFIRM__SUCCESS:
      return assign(initialState, { data: action.payload });

    case a.LOCAL__USER__SET_STORE_ID:
      return assign(state, {
        storeId: action.payload,
      });

    case a.LOCAL__USER__CLEAR:
      return initialState;

    default:
      return state;
  }
}
