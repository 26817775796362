import { CreateCategoryDto, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationCategoryFacade } from '../../../facades';

export type LocationCreateCategoryParams = {
  merchantId: string;
  storeId: string;
  data: Partial<CreateCategoryDto>;
};

export const useLocationCreateCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, LocationCreateCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, LocationCreateCategoryParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationCategoryFacade.create(merchantId, storeId, data), options);
};
