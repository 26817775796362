import { CreateItemSetDto, ReadItemSetDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationItemSetFacade } from '../../../facades';

export type LocationCreateItemSetParams = {
  merchantId: string;
  storeId: string;
  data: CreateItemSetDto;
};

export const useLocationCreateItemSet = (
  options: UseMutationOptions<ReadItemSetDto, unknown, LocationCreateItemSetParams>,
): UseMutationResult<ReadItemSetDto, unknown, LocationCreateItemSetParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationItemSetFacade.create(merchantId, storeId, data), options);
};
