import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useI18n, getIntlString as getIntlStringBase } from '@goparrot-dashboard/i18n';
import { FormField, MultiSelectTextInput, PanelTitle, Toggle } from '@goparrot-dashboard/shared-ui';
import { PatchNotificationDto } from '@goparrot/menu-orchestrator-sdk';
import { isEmail } from 'class-validator';
import uniq from 'lodash/uniq';
import { StoreWithLocation } from '../../types';

type MenuNotificationProps = {
  onChangeNotificationsSettings: (v: PatchNotificationDto) => void;
  setCanSaveSettings: Dispatch<SetStateAction<boolean>>;
  location: StoreWithLocation | null;
  disabled?: boolean;
};

export const MenuNotificationSettings: FC<React.PropsWithChildren<MenuNotificationProps>> = ({
  onChangeNotificationsSettings,
  location,
  disabled,
  setCanSaveSettings,
}) => {
  const { getIntlString } = useI18n();
  const [isValidEmail, setValidEmail] = useState(true);
  const [menuNotification, setMenuNotifications] = useState<PatchNotificationDto | undefined>(undefined);

  const handleEnableNotification = (value: boolean) => {
    if (!menuNotification?.emails.length) {
      setValidEmail(true);
    }
    setMenuNotifications((state) => ({
      isEnabled: value,
      emails: state?.emails || [],
    }));
  };

  const handleChangeEmail = (value: string[]) => {
    setValidEmail(value.every((email) => isEmail(email)));
    setMenuNotifications(
      (state) =>
        ({
          ...state,
          emails: uniq(value),
        } as PatchNotificationDto),
    );
  };

  useEffect(() => {
    setCanSaveSettings((state) => state || !isValidEmail);
    if (!isValidEmail || !menuNotification) {
      return;
    }
    onChangeNotificationsSettings(menuNotification);
  }, [isValidEmail, menuNotification, onChangeNotificationsSettings, setCanSaveSettings]);

  useEffect(() => {
    if (location?.franchiseLocation?.notification) {
      setMenuNotifications(location.franchiseLocation.notification);
    }
  }, [location, setMenuNotifications]);

  return (
    <div className="tw-mb-4">
      <PanelTitle>{getIntlString('menu.notification.settings.title')}</PanelTitle>
      <div>
        <FormField
          label={
            <div className="tw-flex tw-flex-col">
              <span>{getIntlString('menu.notification.settings.toggle')}</span>
            </div>
          }
          input={
            <Toggle
              onChange={handleEnableNotification}
              checkedLabel={getIntlStringBase('variant.enabled')}
              uncheckedLabel={getIntlStringBase('variant.disabled')}
              checked={!disabled && menuNotification?.isEnabled}
              disabled={disabled}
            />
          }
        />
        {!disabled && menuNotification?.isEnabled && (
          <FormField
            withValuePadding={false}
            hasError={!isValidEmail}
            errorMessage={getIntlString('menu.notification.settings.error')}
            label={
              <div className="tw-flex tw-flex-col">
                <span>{getIntlString('menu.notification.settings.input')}</span>
              </div>
            }
            input={
              <MultiSelectTextInput
                value={menuNotification?.emails || []}
                onChange={handleChangeEmail}
                placeholder={getIntlString('menu.notification.settings.placeholder')}
              />
            }
          />
        )}
      </div>
    </div>
  );
};
