import { useStoreService } from '@goparrot-dashboard-core/store-service';
import React, { createContext, FC, useCallback, useContext, useEffect } from 'react';

import { useClearAllLocations, useLocations } from '../../hooks';
import { StoreWithLocation } from '../../types';

const defaultContext: {
  locations: StoreWithLocation[];
  clearAll(): void;
  fetchLocations(): void;
  isLoading: boolean;
} = {
  locations: [],
  fetchLocations: () => null,
  clearAll: () => null,
  isLoading: false,
};

type MenuOrchestrationContextType = typeof defaultContext;

export const MenuOrchestrationContext = createContext(defaultContext);

export const useMenuOrchestrationContext = (): MenuOrchestrationContextType => {
  const context = useContext(MenuOrchestrationContext);
  return context;
};

export const MenuOrchestrationService: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const {
    stores,
    merchant: { merchantId },
  } = useStoreService();

  const { locations, fetchLocations, isLoading } = useLocations(merchantId, stores);
  const [clearAll] = useClearAllLocations(fetchLocations);

  const clearAllLocations = useCallback(() => {
    clearAll(merchantId);
    fetchLocations();
  }, [clearAll, fetchLocations, merchantId]);

  const contextValue: MenuOrchestrationContextType = {
    locations,
    fetchLocations,
    isLoading,
    clearAll: clearAllLocations,
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  return <MenuOrchestrationContext.Provider value={contextValue}>{children}</MenuOrchestrationContext.Provider>;
};
