import { ReadStoreDto } from '@goparrot/store-v2-sdk';
import { useGetMenuCentralLocation } from '@goparrot-dashboard-api/orchestration';
import { useMemo } from 'react';
import { StoreWithLocation } from '../types';

export function useCentralLocation(
  merchantId: string,
  stores: ReadStoreDto[],
): {
  centralLocation: StoreWithLocation | null;
  refetch(): void;
  loading: boolean;
} {
  const hasStores = !!stores?.length;
  const { data: centralLocation, isLoading, refetch } = useGetMenuCentralLocation(merchantId, {
    enabled: hasStores,
    retry: false,
  });

  return useMemo(() => {
    // for virtual menu foundStore will always be undefined as for central location storeId equals to merchantId
    // possible solution add 'store.storeId === centralLocation?.linkStoreId' to condition
    const foundStore = stores.find((store) => store.storeId === centralLocation?.storeId);

    return {
      centralLocation: centralLocation
        ? (({
            ...foundStore,
            franchiseLocation: centralLocation,
          } as unknown) as StoreWithLocation) // check comment above
        : null,
      refetch: refetch,
      loading: isLoading,
    };
  }, [centralLocation, isLoading, refetch, stores]);
}
