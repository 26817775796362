import { PatchStoreItemGroupDto, ReadStoreItemGroupDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantStoreItemGroupFacade } from '../../../facades';

export type MerchantPatchStoreItemGroupParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchStoreItemGroupDto;
};

export const useMerchantPatchStoreItemGroup = (
  options: UseMutationOptions<ReadStoreItemGroupDto, unknown, MerchantPatchStoreItemGroupParams>,
): UseMutationResult<ReadStoreItemGroupDto, unknown, MerchantPatchStoreItemGroupParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantStoreItemGroupFacade.patch(merchantId, uniqueName, data), options);
};
