import React from 'react';
import { IconCloseSmall } from '@goparrot-dashboard/shared-ui';
import { MarketButton } from '@market/react-bindings';
import { useI18n } from '@goparrot-dashboard/i18n';
import { UpdateButton } from './UpdateButton';

type Props = {
  closeModal: () => void;
  updateCount: React.MutableRefObject<number>;
  onSubmit: () => void;
};

export const SettingsModalHeader: React.FC<React.PropsWithChildren<Props>> = ({ closeModal, updateCount, onSubmit }) => {
  const { getIntlString } = useI18n();

  return (
    <div className="tw-w-full tw-pt-6 tw-z-52 tw-flex tw-items-center tw-bg-white tw-justify-between">
      <MarketButton className="tw-text-blue-gray-900" onClick={closeModal} rank="secondary" size="medium" role="button">
        <IconCloseSmall className="tw-w-4 tw-h-4" />
      </MarketButton>
      <div className="tw-flex tw-space-x-3">
        <MarketButton onClick={closeModal} rank="secondary" size="medium" role="button">
          {getIntlString('button.cancel', { isRootPath: true })}
        </MarketButton>
        <UpdateButton countRef={updateCount} onSubmit={onSubmit} />
      </div>
    </div>
  );
};
