import { PatchCategoryDto, ReadCategoryDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { merchantCategoryFacade } from '../../../facades';

export type MerchantUpdateCategoryParams = {
  merchantId: string;
  uniqueName: string;
  data: PatchCategoryDto;
};
export const useMerchantUpdateCategory = (
  options: UseMutationOptions<ReadCategoryDto, unknown, MerchantUpdateCategoryParams>,
): UseMutationResult<ReadCategoryDto, unknown, MerchantUpdateCategoryParams> => {
  return useMutation(async ({ merchantId, uniqueName, data }) => await merchantCategoryFacade.patch(merchantId, uniqueName, data), options);
};
