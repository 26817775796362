import React, { FC, useState, useEffect } from 'react';
import { ConfirmModal } from '@goparrot-dashboard/shared-ui';
import { DialogStateReturn } from 'reakit/ts';
import { useHistory, Prompt } from 'react-router';
import type { Location } from 'history';
import { getIntlString } from '@goparrot-dashboard/i18n';

type UnsavedModalConfirmationProps = {
  dialog: DialogStateReturn;
  onConfirm: () => void;
  onSecondConfirm?: () => void;
  goBack: () => void;
  isFormTouched: boolean;
  canUpdate?: boolean;
  hasErrors?: boolean;
};

export const UnsavedModalConfirmation: FC<React.PropsWithChildren<UnsavedModalConfirmationProps>> = ({
  dialog,
  onConfirm,
  onSecondConfirm,
  goBack,
  isFormTouched,
  canUpdate = false,
  hasErrors = false,
}) => {
  const history = useHistory();
  // this is custom promt when user click on diferent tab(module) and want to leave this module
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const handleBlockedNavigation = React.useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        dialog.show();
        return false;
      }
      return true;
    },
    [confirmedNavigation, dialog],
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, modalVisible, lastLocation]);

  const onDiscardChanges = React.useCallback(() => {
    onSecondConfirm ? onSecondConfirm : setModalVisible(false);
    setConfirmedNavigation(true);
    if (modalVisible) {
      setModalVisible(false);
    } else {
      goBack();
    }
    dialog.hide();
  }, [dialog, goBack, modalVisible, onSecondConfirm]);

  const onSaveChanges = React.useCallback(() => {
    onConfirm();
    setConfirmedNavigation(true);

    if (modalVisible) {
      setModalVisible(false);
    } else {
      goBack();
    }
    dialog.hide();
  }, [dialog, goBack, modalVisible, onConfirm]);

  return (
    <>
      <Prompt when={isFormTouched || canUpdate} message={handleBlockedNavigation} />
      <ConfirmModal
        dialog={dialog}
        cancelText={getIntlString('button.cancel', { isRootPath: true })}
        confirmText={getIntlString('menu.form.modal.leavePage.button.save', { isRootPath: true })}
        header={getIntlString('modal.leavePage.title', { isRootPath: true })}
        content={<div>{getIntlString('modal.leavePage.description', { isRootPath: true })}</div>}
        showSecondSaveButton={true}
        secondConfirmText={getIntlString('button.discard', { isRootPath: true })}
        onCancelClassName="tw-mr-auto"
        onConfirm={onSaveChanges}
        onCancel={() => dialog.hide()}
        onSecondConfirm={onDiscardChanges}
        hideSaveButton={hasErrors}
      />
    </>
  );
};
