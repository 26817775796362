import { IReadRoleV2Dto } from '@goparrot/users-v2-sdk';
import { RouterChildContext } from 'react-router';
import { getLastItemFromPathName } from './getLastItemFromPathName';
import { getMerchantLevelRedirectUrl } from './redirectHelpers';

export const redirectToMerchantLevel = (
  key: string,
  roleV2: IReadRoleV2Dto,
  isMerchantSelected: boolean,
  history: RouterChildContext['router']['history'],
  pathname: string,
) => {
  if (pathname.includes('/dashboard/menu-management/') && isMerchantSelected) {
    const itemType = getLastItemFromPathName(pathname);
    if (itemType) {
      history.push(`/dashboard/menu-management/${key}/${itemType}`);
    } else {
      history.push(getMerchantLevelRedirectUrl(key, roleV2) as string);
    }
  } else {
    history.push(getMerchantLevelRedirectUrl(key, roleV2) as string);
  }
};
