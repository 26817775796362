export enum DashboardCategories {
  SALES = 'Sales',
  SALES_TRENDS = 'Sales trends',
  ROYALTIES_AND_FEES = 'Royalties and fees',
  PAYMENTS = 'Payments',
  CUSTOMERS = 'Customers',
  ITEMS = 'Items',
  CATEGORIES = 'Categories',
  MODIFIERS = 'Modifiers',
  DISCOUNTS = 'Discounts',
  ORDERS = 'Orders',
  LOYALTY = 'Loyalty',
  GIFT_CARDS = 'Gift Cards',
  TAXES = 'Taxes',
}
