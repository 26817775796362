import { PatchMerchantDto as PatchMerchantModel, MerchantExposeGroup } from '@goparrot/store-v2-sdk';
import { plainToClass } from 'class-transformer';

(PatchMerchantModel as any).prototype.init = function (options: Partial<PatchMerchantModel> = {}) {
  Object.assign(
    this,
    plainToClass(PatchMerchantModel, options, {
      excludeExtraneousValues: true,
      groups: [MerchantExposeGroup.PATCH],
    }),
  );
  if (this.featuresFlags && this.featuresFlags?.multiEntityType === undefined) {
    this.featuresFlags.multiEntityType = null;
  }
  return this;
};

export { PatchMerchantModel };
