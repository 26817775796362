import { PatchSelectionTypeDto, ReadSelectionTypeDto } from '@goparrot/storeitems-sdk';
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { locationSelectionTypesFacade } from '../../../facades';

export type LocationUpdateSelectionTypeParams = {
  merchantId: string;
  storeId: string;
  data: PatchSelectionTypeDto;
};

export const useLocationUpdateSelectionType = (
  options: UseMutationOptions<ReadSelectionTypeDto, unknown, LocationUpdateSelectionTypeParams>,
): UseMutationResult<ReadSelectionTypeDto, unknown, LocationUpdateSelectionTypeParams> => {
  return useMutation(async ({ merchantId, storeId, data }) => await locationSelectionTypesFacade.patch(merchantId, storeId, data), options);
};
