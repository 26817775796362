import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Popover } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { AvailableAtType } from '@goparrot/common';
import { availableAtDefaultDateAndTime, substractHoursFromDate } from '@goparrot-dashboard/shared-utils';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { usePopoverState } from 'reakit/Popover';
import { AVAILABLE_AT_DATE_TIME_FORMAT } from '../constants';

type Props = {
  disabled: boolean;
  isAvailableAt: AvailableAtType;
  timeZone: string;
  handleUpdate(newVal: string): void;
  centerMode?: boolean;
};

const INITIAL_DATE_FORMAT = 'yyyy-MM-dd h:mm a';
const TIMEZONE_OFFSET = 'Z';

export const IsAvailableAt: FC<React.PropsWithChildren<Props>> = ({ disabled, isAvailableAt, timeZone, handleUpdate, centerMode = false }) => {
  const dateToHumanReadable = useCallback(
    (date: string) => {
      return DateTime.fromISO(date, { zone: timeZone }).toFormat(AVAILABLE_AT_DATE_TIME_FORMAT);
    },
    [timeZone],
  );

  const isAvailableAtDefaultValue = useMemo(() => {
    return isAvailableAt ? isAvailableAt.toString() : availableAtDefaultDateAndTime(timeZone);
  }, [isAvailableAt, timeZone]);

  const currentValue = useMemo(() => {
    return dateToHumanReadable(isAvailableAtDefaultValue);
  }, [dateToHumanReadable, isAvailableAtDefaultValue]);
  const initialDate = DateTime.fromISO(isAvailableAtDefaultValue, { zone: timeZone });
  const storeOffset = initialDate.toFormat(TIMEZONE_OFFSET);
  const localOffset = DateTime.local().toFormat(TIMEZONE_OFFSET);
  const timeDiff = Number(storeOffset) - Number(localOffset);
  const currentDate = new Date(initialDate.toFormat(INITIAL_DATE_FORMAT).replace(/-/g, '/'));
  const [date, setDate] = useState<Date>(currentDate);
  const popoverState = usePopoverState();

  const parseDateToDBFormat = useCallback(
    (dateObj: any) => {
      const nextDate = substractHoursFromDate({ hours: timeDiff, date: dateObj });
      return nextDate.toISOString();
    },
    [timeDiff],
  );

  const nextValue = useMemo(() => {
    const parsed = parseDateToDBFormat(date);
    return dateToHumanReadable(parsed);
  }, [date, dateToHumanReadable, parseDateToDBFormat]);

  const startUpdate = () => {
    const parsedDate = parseDateToDBFormat(date);
    handleUpdate(parsedDate);
  };

  return (
    <div
      className={classNames([
        'tw-absolute tw-whitespace-nowrap tw-flex tw-space-x-1',
        centerMode && 'tw-transform tw--translate-x-2/4 tw-translate-y-0 tw-left-1/5',
        popoverState.visible ? 'tw-z-10' : 'tw-z-1',
      ])}
    >
      <span className="tw-text-gray-400 tw-text-sm">{getIntlString('page.merchant.menu.until')}</span>
      <div>
        <Popover
          ariaLabel="Available at time"
          baseId="button"
          disabled={disabled}
          noPadding
          content={
            <div key="date-time-picker" className={classNames(['tw-bg-white tw-space-y-3'])}>
              <div className="tw-text-sm tw-text-blue-gray-900 tw-pl-4 tw-pt-2">{nextValue}</div>
              <DatePicker
                className={classNames([
                  'tw-p-3 tw-border tw-border-cool-gray-200 tw-rounded-md',
                  'tw-text-blue-gray-900 tw-max-w-6xl tw-w-full tw-py-3 tw-bg-transparent tw-outline-none',
                  disabled && 'tw-bg-cool-gray-100',
                ])}
                selected={date}
                onChange={(date: Date) => setDate(date)}
                disabled={disabled}
                showTimeSelect
                timeFormat="h a"
                timeIntervals={60}
                minDate={new Date()}
                inline
                calendarClassName="tw-flex tw-w-full tw-pl-2.5 tw-pt-2.5 tw-border-0 tw-rounded-md tw-text-xs tw-text-red-100 tw-shadow tw-bg-white"
              />
              <div className="tw-flex tw-justify-end tw-pr-2 tw-pb-2">
                <Button disabled={disabled} onClick={startUpdate}>
                  {getIntlString('button.update')}
                </Button>
              </div>
            </div>
          }
          popoverState={popoverState}
        >
          <Button
            disabled={disabled}
            kind="clean"
            className={classNames(['tw-text-sm', disabled ? 'tw-text-gray-400' : 'tw-text-light-blue-400'])}
            color="clean"
            noFocusOutline
          >
            {isAvailableAt ? currentValue : getIntlString('page.merchant.menu.selectTime')}
          </Button>
        </Popover>
      </div>
    </div>
  );
};
