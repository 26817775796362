import { StatusTagEnum } from '../interface';

export const getStatusTagColor = (value: string) => {
  if (value === StatusTagEnum.ACTIVE) {
    return 'secondary';
  }
  if (value === StatusTagEnum.CREATED) {
    return 'primary';
  }
};
